export default `
  query {
    organization(id: :id) {
      children(years: [:year]) {
        id
        name
        entity_type
        dist_number
        sch_number
        sch_cd
        sch_type
        organization_data(years: [:year]) {
          year
          school_level
        }
      }
    }
  }
`;
