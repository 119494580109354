import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import sumBy from 'lodash/sumBy';
import slice from 'lodash/slice';

import template from '../../../template';
import conditional from '../../../conditional';
import { yearlyOrgScores } from '../../../scoresHelpers';
import numberFormatter from '../../../numberFormatter';

const adapter = (options) => {
  const { node, scores, organization } = options;
  const scoringOptions = node.scoring_options || {};
  const trendLines = conditional(scoringOptions.trend_lines, {}, options);
  const yearlyScores = yearlyOrgScores(node, scores, organization);
  const uniqueScores = slice(uniqBy(yearlyScores, score => (score.start_at + score.end_at)), -6);

  const FORMATTER = {
    money: v => v / 100,
    proportion: v => v * 100,
    identity: v => v,
    comma_delimited: v => numberFormatter(v, 'number'),
    percent: v => numberFormatter(v, 'percent'),
    twoDigits: v => v.toFixed(2)
  };

  const format = FORMATTER[scoringOptions.type || 'identity'];
  /* eslint-disable no-useless-escape */
  const lines = trendLines && sortBy(map(trendLines, value => (
    {
      name: template(get(value, 'name'), options),
      label: template(get(value, 'name'), options),
      showLine: get(value, 'show_line'),
      showPoints: get(value, 'show_points'),
      colorIndex: get(value, 'colorIndex'),
      symbol: 'circle',
      values: map(uniqueScores, score => (
        {
          x: new Date(get(score, 'start_at').replace(/-/g, '\/')),
          y: value.keys ? sumBy(map(value.keys, key =>  format(get(score.value, key, 0)))) : score.value,
          title: node.name
        }
      )),
      index: get(value, 'index')
    }
  )), ['index']);
  return lines;
  /* eslint-enable no-useless-escape */
};

export default adapter;
