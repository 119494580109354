import React from 'react';
import filter from 'lodash/filter';

import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import { BlockTitle } from 'cui';

import getWhyThisMatters from './getWhyThisMatters';
import DataPointBlock from '../DataPointBlock';
import DataPointBlocks from './DataPointBlocks';
import when from '../../helpers/when';
import { filterByMetadata, isPublicOrWithClarityUserPermissions } from '../../helpers/nodeHelpers';

const ROLLUP_LEVELS = ['school', 'district', 'state'];

const DataPoints = (props) => {
  const {
    additionalChartAdapters,
    additionalChartTypes,
    additionalOrganizations,
    baseUrl,
    dataPoints,
    dateTemplate,
    dateTemplateDates,
    entities,
    entityScores,
    innerChartViewingOptions,
    isPreviewSite,
    isLoading,
    loadingWhen,
    node,
    selectedViewingItem,
    subjectView,
    organization,
    showViewOtherData,
    selectedTimeSlice,
    showScoreDates,
    userRole,
    whyThisMatters,
    viewOtherData,
    scores: allScores
  } = props;

  if (dataPoints) {
    const entityType = (organization.entity_type || organization.entityType || '');
    const schoolLevel = (organization.school_level || organization.schoolLevel || '');
    const entityLevel = ROLLUP_LEVELS.indexOf(entityType.toLowerCase());

    return dataPoints
      .filter(dataPoint => filterByMetadata(dataPoint, { currentEntity: entityType, currentSchoolLevel: schoolLevel, isPreviewSite }))
      .filter(item => isPublicOrWithClarityUserPermissions(item, userRole))
      .map((dataPoint) => {
        const rollupLevel = ROLLUP_LEVELS.indexOf(dataPoint.rollup_level);
        const entityInRollupLevel = rollupLevel === -1 || entityLevel <= rollupLevel;
        const renderSection = isEmpty(innerChartViewingOptions) || dataPoint.node_path === selectedViewingItem;
        const showBlock = renderSection && (!dataPoint.when || when(dataPoint.when, props)) && entityInRollupLevel;

        const multiScore = dataPoint?.scoring_options?.multiScore;

        const scores = (multiScore) ? allScores : (filter(allScores, { framework_tree_node: { node_path: dataPoint.node_path } }) || allScores);

        const previousYearScores = mapValues(
          props.previousYearScores,
          currentScores => filter(currentScores, { framework_tree_node: { node_path: dataPoint.node_path } })
        );

        return (
          <div className="data-points" key={dataPoint.slug}>
            {showBlock && <DataPointBlock
              dateTemplate={dateTemplate}
              dateTemplateDates={dateTemplateDates}
              showScoreDates={showScoreDates}
              loading={isLoading}
              node={dataPoint}
              scores={scores}
              previousYearScores={previousYearScores}
              allScores={allScores}
              organization={organization}
              additionalOrganizations={additionalOrganizations}
              selectedTimeSlice={selectedTimeSlice}
              tabs={dataPoint.tabs || [
                { name: 'Chart' }
              ]}
              key={dataPoint.slug}
              viewingOptions={innerChartViewingOptions}
              whyThisMatters={getWhyThisMatters(dataPoint.node_path, whyThisMatters)}
              baseUrl={baseUrl}
              additionalChartAdapters={additionalChartAdapters}
              additionalChartTypes={additionalChartTypes}
              entities={entities}
              entityScores={entityScores}
              loadingWhen={loadingWhen}
              showViewOtherData={showViewOtherData}
              viewOtherData={viewOtherData}
            />}
          </div>
        );
      });
  } else {
    return (
      node && node.items.map((i) => {
        const renderSection = isEmpty(subjectView) || i.node_path === selectedViewingItem;

        if (!renderSection) return null;

        return (
          <section key={i.slug}>
            {i.items.map((v) => {
              const name = i.items.length > 1 ? `${i.name}: ${v.name}` : i.name;
              return (
                <div key={v.slug}>
                  <BlockTitle title={name} />
                  <DataPointBlocks
                    additionalChartAdapters={additionalChartAdapters}
                    additionalChartTypes={additionalChartTypes}
                    baseUrl={baseUrl}
                    items={v.items}
                    organization={organization}
                    scores={props.scores}
                    entities={entities}
                    entityScores={entityScores}
                    loadingWhen={loadingWhen}
                    showScoreDates={showScoreDates}
                    dateTemplate={dateTemplate}
                    isLoading={isLoading}
                    additionalOrganizations={additionalOrganizations}
                    selectedTimeSlice={selectedTimeSlice}
                    whyThisMatters={whyThisMatters}
                  />
                </div>
              );
            })}
          </section>
        );
      })
    );
  }
};

export default DataPoints;
