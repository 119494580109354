import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './Link.module.scss';

const Link = ({ children, icon, href }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    {children || href}
    {
      icon && (
        <span className={styles.icon}>
          <Icon name={icon} />
        </span>
      )
    }
  </a>
);

Link.propTypes = {
  children: PropTypes.string,
  icon: PropTypes.string,
  href: PropTypes.string.isRequired
};

Link.defaultProps = {
  children: null,
  icon: null
};

export default Link;
