/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import Field from 'cui/lib/components/Form/Field';

import HeaderBlockMap from '../../components/HeaderBlockMap';
import OrgPanelUI from '../../components/OrgPanelUI';
import ClassificationDetails from '../../components/OrgProfile/ClassificationDetails';
import Title1Status from '../../components/OrgProfile/Title1Status';
import StarRatingPanel from '../../components/StarRatingPanel';
import PublicOrWithPermissions from '../../components/PublicOrWithPermissions';
import { ACCOUNTABILITY_DOMAIN_NODE_PATH } from '../../constants';

import BoardMembers from './BoardMembers';
import Address from './Address';
import SocialIcons from './SocialIcons';
import Summary from './Summary';

const CLASSIFICATION_DESCRIPTION = 'open popover for more information about classification.';
const TITLE1_DESCRIPTION = 'open popover for more information about title 1 status.';

const OrgPanels = ({
  address,
  classification,
  boardMembers,
  featureFlags,
  federalClassification,
  framework,
  schoolStat,
  organization,
  orgType,
  orgName,
  summary,
  schoolYear,
  title1,
  websiteUrl
}) => (
  <div>
    <OrgPanelUI>
      <OrgPanelUI.First>
        <PublicOrWithPermissions nodePath={ACCOUNTABILITY_DOMAIN_NODE_PATH}>
          <StarRatingPanel
            featureFlags={featureFlags}
            federalClassification={federalClassification}
            framework={framework}
            schoolStat={schoolStat(organization)}
            organization={organization}
            year={schoolYear}
            headingLevel={3}
          />
        </PublicOrWithPermissions>
      </OrgPanelUI.First>
      <OrgPanelUI.Second>
        <Summary summary={summary} headingLevel={3} />
        {classification && <ClassificationDetails title={classification} description={CLASSIFICATION_DESCRIPTION} />}
        {title1 && <Title1Status title={title1} description={TITLE1_DESCRIPTION} />}
        <Field label="">
          <BoardMembers boardMembers={boardMembers} orgType={orgType} />
        </Field>
      </OrgPanelUI.Second>
      <OrgPanelUI.Sidebar>
        {
          !window.Cypress && <HeaderBlockMap organization={organization} mapZoom={10} mapOptions={{}} />
        }
        <Address address={address} orgType={orgType} />
        <SocialIcons currentOrganization={orgName} websiteUrl={websiteUrl} />
      </OrgPanelUI.Sidebar>
    </OrgPanelUI>
  </div>
);

OrgPanels.propTypes = {
  address: PropTypes.object,
  classification: PropTypes.string,
  boardMembers: PropTypes.arrayOf(PropTypes.string),
  federalClassification: PropTypes.object,
  organization: PropTypes.object,
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  orgType: PropTypes.string,
  orgName: PropTypes.string,
  summary: PropTypes.arrayOf(PropTypes.object),
  schoolYear: PropTypes.number,
  title1: PropTypes.string,
  websiteUrl: PropTypes.string,
  schoolStat: PropTypes.func
};

export default OrgPanels;
