import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'cui/lib/components/Icon';
import { SUPPORTED_YEARS } from '../../constants';
import styles from './index.module.scss';

const NoDataForYearInfo = ({ year }) => {
  const yearInfo = SUPPORTED_YEARS.find((item) => item.value === year);
  const message = `There are no data for this organization during the ${yearInfo.text} year.`;

  return (
    <div className={styles.container}>
      <Icon className={styles.icon} size="xLarge" name="bb-circle-information" />
      {message}
    </div>
  );
};

NoDataForYearInfo.propTypes = {
  year: PropTypes.number.isRequired
};

export default NoDataForYearInfo;
