import React from 'react';
/* eslint-disable import/no-named-as-default */
import IconTitleFormatter from '../../components/formatters/IconTitleFormatter';
import ColorDotFormatter from '../../components/formatters/ColorDotFormatter';
import DDDColorDotFormatter from '../../components/formatters/DDDColorDotFormatter';
import DefaultFormatter from '../../components/formatters/DefaultFormatter';
import NumberFormatter from '../../components/formatters/NumberFormatter';
import CostFormatter from '../../components/formatters/CostFormatter';
import RatingFormatter from '../../components/formatters/RatingFormatter';
import PercentFormatter from '../../components/formatters/PercentFormatter';
import ColorDotWithProgressFormatter from '../../components/formatters/ColorDotWithProgressFormatter';
import MostImpactFormatter from '../../components/formatters/MostImpactFormatter';
import SegmentFormatter from '../../components/formatters/SegmentFormatter';
import TagFormatter from '../../components/formatters/TagFormatter';
import MixedTagFormatter from '../../components/formatters/MixedTagFormatter';
import TruncateFormatter from '../../components/formatters/TruncateFormatter';
import RoundNumberFormatter from '../../components/formatters/RoundNumberFormatter';
import HighlightFormatter from '../../components/formatters/HighlightFormatter';
import SliceFormatter from '../../components/formatters/SliceFormatter';
import SbcssColorDotFormatter from '../../components/formatters/SbcssColorDotFormatter';
import IconFormatter from '../../components/formatters/IconFormatter';
import ImLink from '../../components/ImLink';

const components = {
  '': DefaultFormatter,
  colorDot: ColorDotFormatter,
  dddColorDot: DDDColorDotFormatter,
  colorDotWithProgress: ColorDotWithProgressFormatter,
  mostImpactFormatter: MostImpactFormatter,
  number: NumberFormatter,
  cost: CostFormatter,
  percent: PercentFormatter,
  rating: RatingFormatter,
  segmentFormatter: SegmentFormatter,
  tagFormatter: TagFormatter,
  mixedTagFormatter: MixedTagFormatter,
  title: IconTitleFormatter,
  truncateFormatter: TruncateFormatter,
  roundNumber: RoundNumberFormatter,
  highlightFormatter: HighlightFormatter,
  sliceFormatter: SliceFormatter,
  sbcssColorDot: SbcssColorDotFormatter,
  iconFormatter: IconFormatter,
  imLink: ImLink
};

const dynamicFormatter = (key, props) => {
  let DynamicComponent = components[(key || '')];
  if (DynamicComponent === undefined) {
    console.warn(`WARNING: component '${key}' is unrecognized.`);
    DynamicComponent = components[''];
  }
  return <DynamicComponent {...props} />;
};

export default dynamicFormatter;
