import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import pick from 'lodash/pick';
import map from 'lodash/map';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import compact from 'lodash/compact';

import Table from 'cui/lib/components/Table';
import BlockTitle from 'cui/lib/components/BlockTitle';
import Panel from 'cui/lib/components/Panel';
import FlexBar from 'cui/lib/components/FlexBar';
import { findAnyDimensionNodes } from '../helpers/frameworkHelpers';
import { filterByMetadata, isNodeVisible, isPublicOrWithClarityUserPermissions } from '../helpers/nodeHelpers';
import when from '../helpers/when';
import { kdeCurrentSchoolYear } from '../helpers/portals';

import DomainIcon from '../components/DomainIcon';
import NarrativeModal from '../components/NarrativeModal';
import ApprovalStatus from '../components/ApprovalStatus';
import ApprovalWrapper from '../components/ApprovalWrapper';
import ApprovalHeader from '../components/ApprovalHeader';
import MainContentAnchor from '../components/MainContentAnchor';
import WithPredicate from '../components/WithPredicate';
import SuccessIndicatorStatusTable from '../components/SuccessIndicatorStatusTable';
import Redirect from '../components/Link/Redirect';
import buildLoadFrameworkAction from '../actions/framework';
import { basename } from '../store';

import styles from './Status.module.scss';

import { LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS } from './OrgReportCardPage/actions';
import { canSeeApprovalTab, canSeeDomain } from '../helpers/approvalVisibilityHelpers';
import { isApprovableDomain, supportsNarrative } from '../helpers/approvalHelpers';

const statusRowRenderer = (currentOrganization, currentSchoolYear) => (row, i, columns, parentRow) => {
  if (parentRow) {
    return (
      <tr key={i} className={styles.domainDetailsRow}>
        <td colSpan={5}>
          <SuccessIndicatorStatusTable successIndicators={row} />
        </td>
      </tr>
    );
  }

  return (
    <tr key={i} className={styles.domainRow}>
      <td>
        <FlexBar justify="left">
          <FlexBar.Item>
            <DomainIcon domain={row.node} format="svg" />
          </FlexBar.Item>
          <FlexBar.Item>
            {get(row, 'node.name')}
            {
              supportsNarrative(row.node, currentOrganization) && <NarrativeModal />
            }
          </FlexBar.Item>
        </FlexBar>
      </td>
      <td>
        {
          isApprovableDomain(row.node, currentOrganization) && (
            <ApprovalStatus
              status={row.status.status}
              nodePath={row.node.node_path}
              fiscalYear={currentSchoolYear}
            />
          )
        }
      </td>
      <td>
        {row.status?.updated_at && format(new Date(parseInt(row.status.updated_at)), 'MMMM d, yyyy')}
      </td>
      <td>
        {row.status && row.status.updated_by}
      </td>
    </tr>
  );
};

const statusesFor = ({ sectionStatuses, framework, currentOrganization, user, featureFlags, isPreviewSite }) =>
  compact(map(get(framework, 'items', []), (domain) => {
    if (canSeeDomain(user, domain.slug, featureFlags)) {
      const whenProps = { development: process.env.NODE_ENV === 'development' };
      const firstVisibleChild = find(findAnyDimensionNodes(domain, 'variable'), node => !node.when || when(node.when, whenProps));
      const slugPath = map(pick(get(firstVisibleChild, 'address', []), ['domain', 'success_indicator', 'variable']), value => value.slug).join('/');

      const availableSuccessIndicators = domain.items
        .filter(node => isNodeVisible(node) && filterByMetadata(node, {
          currentEntity: currentOrganization.entity_type,
          currentSchoolLevel: currentOrganization.school_level,
          isPreviewSite
        }));

      const childRows = availableSuccessIndicators.map(el => ({
        node: el,
        childStatuses: sectionStatuses.filter(
          record => record.section_slug !== el.node_path && record.section_slug.startsWith(el.node_path)
        )
      }));

      const hasFlaggedDatapoints = [].concat(...childRows.map(el => el.childStatuses))
        .find(el => el.status === 'flagged');

      const domainStatus = find(sectionStatuses, { clarity_organization_id: currentOrganization.id, section_slug: domain.node_path });
      const status = {
        ...domainStatus,
        status: hasFlaggedDatapoints ? 'flagged' : get(domainStatus, 'status')
      };

      return {
        node: domain,
        orgId: currentOrganization.id,
        href: isApprovableDomain(domain, currentOrganization) ? `${basename}/organization/${currentOrganization.id}/${slugPath}` : null,
        linkableText: isApprovableDomain(domain, currentOrganization) ? `${domain.name} Domain` : null,
        status,
        data: [childRows]
      };
    } else {
      return null;
    }
  }));

const StatusPage = ({
  currentOrganization,
  featureFlags,
  framework,
  pageContext,
  sectionStatuses,
  user
}) => {
  if (!pageContext || !currentOrganization || !user) return <div />;
  if (user.currentOrganization.id === 20 && currentOrganization.id === 20) return <Redirect to="/status/20/children" />;
  if (!canSeeApprovalTab(user, featureFlags)) return <Redirect to={`/status/${currentOrganization.id}/downloads`} />;
  const isApprovalPeriodOpen = !!featureFlags.KdeApprovalPeriodOpen;
  const isStateUser = user.currentOrganization.entityType === 'State';
  const isPreviewSite = pageContext.isPreviewSite;
  const shouldShowContent = isApprovalPeriodOpen || isStateUser;

  // Note: we only allow to change approval status for current school year
  /* eslint-disable no-undef */
  const currentSchoolYear = kdeCurrentSchoolYear();
  /* eslint-enable no-undef */
  const statuses = statusesFor({ sectionStatuses, framework, currentOrganization, user, featureFlags, isPreviewSite })
    .filter(status => isPublicOrWithClarityUserPermissions(status.node, user.currentModulePlatformAccessLevel));
  /* eslint-enable max-len */

  /* eslint-disable react/jsx-no-bind */
  return (
    <div className={styles.container}>
      <ApprovalHeader />
      <WithPredicate
        predicate={shouldShowContent}
        placeholder={
          <Panel>
            <Panel.Content>
              The Data Approval and Collection Period is not open at this time.
              Please contact the Kentucky State Department of Education for more information.
            </Panel.Content>
          </Panel>
        }
      >
        <div role="main">
          <MainContentAnchor />
          <BlockTitle title="Domains For Approval" />
          <Panel>
            <Panel.Content>
              <Table
                linkable
                expandable
                striped={false}
                data={statuses}
                renderRow={statusRowRenderer(currentOrganization, currentSchoolYear)}
                className={styles.table}
                LinkableComponent={({ href, children, ...props }) =>
                  <div className={!href && 'cui-visuallyHidden'}>
                    <a href={href} {...props}>{children}</a>
                  </div>
                }
              >
                <Table.ColumnHeader property="name">Name</Table.ColumnHeader>
                <Table.ColumnHeader property="status">Status</Table.ColumnHeader>
                <Table.ColumnHeader property="updated_on">Updated On</Table.ColumnHeader>
                <Table.ColumnHeader property="updated_by">Updated By</Table.ColumnHeader>
              </Table>
            </Panel.Content>
          </Panel>
        </div>
      </WithPredicate>
    </div>
  );
  /* eslint-enable react/jsx-no-bind */
};

StatusPage.propTypes = {
  user: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  sectionStatuses: PropTypes.arrayOf(PropTypes.object),
  childOrgs: PropTypes.arrayOf(PropTypes.object),
  framework: PropTypes.object,
  pageContext: PropTypes.object,
  featureFlags: PropTypes.object,
  currentOrganization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = state => ({
  pageContext: get(state, 'module.pageContext'),
  featureFlags: get(state, 'module.pageContext.featureFlags'),
  user: get(state, 'module.userContext'),
  framework: get(state, 'framework.kde'),
  currentOrganization: get(state, 'module.currentOrganization'),
  childOrgs: get(state, 'module.currentOrganization.children', []),
  sectionStatuses: get(state, 'module.sectionStatuses', []),
  scores: get(state, 'module.scores', [])
});
/* eslint-disable new-cap */
export default ApprovalWrapper(withRouter(connect(mapStateToProps)(StatusPage)));
/* eslint-enable new-cap */

export const config = {
  title: '{{name}} - Approval - Kentucky School Report Card Approval',
  mapStateToProps: state => ({
    name: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase())
  }),
  key: 'statuses',
  actions: [
    buildLoadFrameworkAction({
      actions: [
        {
          ...LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS,
          propMap: {
            id: 'params.id',
            year: 'module.schoolYear'
          },
          actions: [
            {
              type: 'GRAPH_LOAD',
              service: 'svcpd',
              query: `
              query {
                section_statuses(organization_id: [:organizationId], fiscal_year: :fiscalYear) {
                  id clarity_organization_id fiscal_year section_slug status flag_comments updated_by updated_at
                }
              }
              `,
              propMap: {
                fiscalYear: 'module.schoolYear',
                organizationId: 'params.id'
              },
              stateObjectMap: {
                sectionStatuses: 'section_statuses'
              }
            },
            {
              type: 'GRAPH_LOAD',
              service: 'svcpd',
              query: `
              query {
                narrative(organization_id: :organizationId, fiscal_year: :fiscalYear) {
                  id
                  body
                }
              }
              `,
              propMap: {
                fiscalYear: 'module.schoolYear',
                organizationId: 'params.id'
              },
              stateObjectMap: {
                narrative: 'narrative'
              }
            }
          ]
        }
      ]
    }),
    {
      type: 'FETCH_TOKEN',
      service: 'pdf_generator',
      slug: 'kde'
    }
  ]
};
