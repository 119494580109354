import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrgDataProvider from '../../helpers/OrgDataProvider';
import OrgReportCard from '../../components/OrgReportCard';
import { filterLeavesAndTheirAncestors } from '../../helpers/collectorHelpers';
import orgCanAccessCollectorDataPoint from '../../helpers/orgCanAccessCollectorDataPoint';
import useDistrictMessageQuery from '../../store/backend/queries/district_messages';
import SuperIntendentMessageModal from './SuperIntendentMessageModal';
import OrgPanels2022 from './OrgPanels2022';
import OrgPanels2022Top from './OrgPanels2022Top';

const OrgReportCardContent2022 = ({
  framework,
  currentOrganization,
  scoresData,
  featureFlags,
  isPreviewSite,
  schoolStat,
  year,
  isNotClarity
}) => {
  const districtId = currentOrganization?.parentDistrict?.id ?? currentOrganization?.id;
  const { data: districtMessage } = useDistrictMessageQuery(districtId, year);
  const [isSuperIntendentMessageModalOpen, setSuperIntendentMessageModalOpen] = useState(
    false
  );
  const researchLink = framework?.metadata?.researchLink ?? `/datasets?year=${year}`;

  return (
    <div>
      <section
        className="section section_tight"
        aria-labelledby="summary_section_aria_label"
      >
        <span id="summary_section_aria_label" hidden>
          Summary
        </span>
        <div className="container-constrained">
          <OrgDataProvider
            scores={scoresData.scores}
            isScoresLoading={scoresData.isLoading}
            schoolStat={schoolStat}
          >
            {/* Scores are considered only for schools*/}
            {isNotClarity && (orgData => <OrgPanels2022Top isPreviewSite={isPreviewSite} {...orgData} />)}
          </OrgDataProvider>
          <OrgDataProvider
            scores={scoresData.scores}
            isScoresLoading={scoresData.isLoading}
            schoolStat={schoolStat}
          >
            {/* Scores are considered only for schools*/}
            {orgData => <OrgPanels2022 {...orgData} researchLink={researchLink} districtMessage={districtMessage} />}
          </OrgDataProvider>
        </div>
      </section>
      <section
        className="section section_tight"
        aria-labelledby="key_topics_section_aria_label"
      >
        <span id="key_topics_section_aria_label" hidden>
          Key topics
        </span>
        <OrgReportCard
          awaitProps={['framework', 'currentOrganization']}
          featureFlags={featureFlags}
          isPreviewSite={isPreviewSite}
          framework={filterLeavesAndTheirAncestors(framework, node =>
            orgCanAccessCollectorDataPoint(node, currentOrganization)
          )}
          propMap={{
            currentOrganization: 'module.currentOrganization',
            isLoading: 'loading.isLoading',
            organizations: 'module.organizations',
            userRole: 'module.userContext.currentModulePlatformAccessLevel'
          }}
          scores={scoresData.scores}
        />
      </section>
      <SuperIntendentMessageModal
        isOpen={isSuperIntendentMessageModalOpen}
        onHide={() => setSuperIntendentMessageModalOpen(false)}
        districtMessage={districtMessage}
      />
    </div>
  );
};

OrgReportCardContent2022.propTypes = {
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  scoresData: PropTypes.shape({
    isLoading: PropTypes.bool,
    scores: PropTypes.arrayOf(PropTypes.object)
  }),
  schoolStat: PropTypes.func,
  year: PropTypes.number,
  isNotClarity: PropTypes.bool
};

export default OrgReportCardContent2022;
