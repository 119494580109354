export function valueToFixedPoint(value, precision = 2) {
  return Number.isFinite(value) ? parseFloat(value.toFixed(precision)) : value;
}

export function computePercent(numerator, denominator, defaultValue = '', precision = 1, showPercentSign = true) {
  const percent = numerator / denominator;
  if (Number.isNaN(percent)) {
    return defaultValue;
  }

  return `${valueToFixedPoint(percent * 100, precision)}${showPercentSign ? '%' : ''}`;
}

export function computeRatio(numerator, denominator, defaultValue = '', precision = 2) {
  const ratio = parseFloat(numerator) / parseFloat(denominator);
  return Number.isNaN(ratio) ? defaultValue : valueToFixedPoint(ratio, precision);
}

export default function valueToPrecision(value, precision = 2) {
  return Number.isFinite(value) ? parseFloat(value.toPrecision(precision)) : value;
}
