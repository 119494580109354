import React from 'react';
import FlexBar from 'cui/lib/components/FlexBar';
import cx from 'classnames';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const ListItem = ({ item }) => {
  if (item.type === 'GROUP') {
    return (
      <div>
        <h4 className="cui-margin-xSmall">{item.name}</h4>
        <div className="cui-indent">{item.items.map(element => <ListItem key={element.name} item={element} />)}</div>
      </div>
    );
  } else {
    return (
      <FlexBar className="cui-margin-xSmall">
        <FlexBar.Item className="cui-text_small">{item.name}</FlexBar.Item>
        <FlexBar.Item>
          {item.url ?
            <a
              className={cx(['cui-btn cui-btn_stroked cui-btn_secondary', styles.rounded])}
              href={item.url}
            >
              Download
            </a> :
            <span
              className={cx(['cui-btn cui-btn_stroked cui-btn_secondary', styles.rounded])}
              disabled
            >
              Download
            </span>}
        </FlexBar.Item>
      </FlexBar>
    );
  }
};

ListItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['GROUP', 'ITEM']),
    url: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array
  })
};

export default ListItem;
