import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import { KENTUCKY_ORG_ID } from '../../constants';
import Breadcrumbs from '../Breadcrumbs';
import addYearToUrl from '../Link/addYearToUrl';

const IndicatorBreadcrumbs = ({ location }) => {
  const year = new URLSearchParams(location.search).get('year');
  const crumbs = [
    {
      caption: 'Kentucky',
      linkRoute: addYearToUrl(`/organization/${KENTUCKY_ORG_ID}`, year)
    },
    {
      when: '{{!!organization.parentDistrict}}',
      caption: '{{organization.parentDistrict.name}}',
      linkRoute: addYearToUrl('/organization/{{organization.parentDistrict.id}}', year)
    },
    {
      when: '{{organization.id !== KENTUCKY_ORG_ID}}',
      caption: '{{organization.name}}',
      linkRoute: addYearToUrl('/organization/{{organization.id}}', year)
    }
  ];

  return (
    <Breadcrumbs
      crumbs={crumbs}
      awaitProps={['organization']}
      KENTUCKY_ORG_ID={20}
      propMap={{
        organization: 'module.currentOrganization'
      }}
    />
  );
};

IndicatorBreadcrumbs.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(IndicatorBreadcrumbs);
