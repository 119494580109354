import React from 'react';
import PropTypes from 'prop-types';

import reduce from 'lodash/reduce';

import ScoreDot from 'cui/lib/components/ScoreDot';

export const EntityComparisonDetailsPopover = (props) => {
  const { data, theme } = props;

  return (
    <div style={{ padding: '0.5rem' }}>
      <p>{data.label}</p>
      { reduce(data.value, (acc, datum) => {
        if (datum.hidden === undefined || !datum.hidden) {
          acc.push(
            <div key={datum.label}>
              <ScoreDot theme={theme} value={datum.colorIndex} />
              {parseInt(datum.value)} {datum.label}
            </div>
          );
        }
        return acc;
      }, [])
      }
    </div>
  );
};

EntityComparisonDetailsPopover.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string
};

EntityComparisonDetailsPopover.defaultProps = {
  theme: 'rainbow'
};

export default EntityComparisonDetailsPopover;
