export function valueToLocaleString(value) {
  const numbericValue = Number(value);
  return Number.isNaN(numbericValue) ? value : numbericValue.toLocaleString();
}

export function valueToPercentage(value) {
  if (value === '') return '';

  return Number.isNaN(Number(value)) ? value : `${value}%`;
}
