import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'cui';

import styles from './AccountabilityChangeLegend.module.scss';
import { Icon } from 'cui/lib/components';

const AccountabilityChangeLegend = ({
  data,
  label
}) => {
  const list = data ? data : [{ label: 'Declined Significantly' }, { label: 'Declined' }, { label: 'Maintained' }, { label: 'Increased' }, { label: 'Increased Significantly' }];
  const icons = ['bb-down-arrow', 'bb-down-right-arrow', 'bb-right-arrow', 'bb-up-right-arrow', 'bb-down-arrow']
  return (
    <div className={styles.legend}>
      <Label className={styles.label} value={label} position="inline" />
      <ul className="cui-list cui-list_inline">
        {list.length > 0 &&
          list.map((obj, i) => (

            <li key={`key${Math.random()}`}>
              <span className="cui-scoreDot cui-list-icon">
                <span className="cui-scoreDot-icon">
                  <Icon name={icons[i]} className={`theme-kde-accountability2-fill-${i} ${i==4?styles.revert:''}`}></Icon>
                </span>
              </span>
              <span className="cui-list-text"><strong>{obj.label}</strong> </span>
            </li>
          )
        )}
      </ul>
    </div>
  );
}

AccountabilityChangeLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string
}



AccountabilityChangeLegend.defaultProps = {
  label: "Change"
};

export default AccountabilityChangeLegend;
