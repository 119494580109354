import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'cui';
import { scoreInfoPropType } from '../../../helpers/collectorHelpers';
import CheckboxSet from '../fields/CheckboxSet';
import EmptyWrapper from '../EmptyWrapper';
import sectionHoc from './sectionHoc';

export const ProgramOverviewSection = ({ scores: { programAreaOfferings, link }, linkLabel }) => (
  <EmptyWrapper scoreInfos={{ programAreaOfferings, link }}>
    {() => (
      <section className="section" aria-labelledby="program_overview_section_aria_label">
        <h2 id="program_overview_section_aria_label">Program Overview</h2>
        <EmptyWrapper scoreInfo={programAreaOfferings}>
          {({ scoreInfo, notReported }) => (
            <CheckboxSet fieldLabel="Program Area Offerings" notReported={notReported} scoreInfo={scoreInfo} />
          )}
        </EmptyWrapper>
        <EmptyWrapper scoreInfo={link}>
          {({ scoreInfo, notReported }) => (
            <div>
              <h3 className="fieldLabel">{linkLabel}</h3>
              <div className="field">
                {notReported || (scoreInfo.value && (
                  <a href={scoreInfo.value.value || scoreInfo.value}>
                    <span className="cui-label_inline">{scoreInfo.value.label || scoreInfo.value.replace(/^(http|https):\/\//, '')}</span>
                    <Icon name="bb-arrow-box-top" />
                  </a>
                ))}
              </div>
            </div>
          )}
        </EmptyWrapper>
      </section>
    )}
  </EmptyWrapper>
);

ProgramOverviewSection.propTypes = {
  scores: PropTypes.shape({
    programAreaOfferings: scoreInfoPropType,
    link: scoreInfoPropType
  }),
  linkLabel: PropTypes.string
};

export default sectionHoc(ProgramOverviewSection);
