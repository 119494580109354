import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'cui';

import styles from './ScoreBlock.module.scss';

const Primary = ({ children, title }) => (
  <div className={styles.primary}>
    { title &&
      <Heading className={styles.subheader} level={3} size="xSmall" caps>{title}</Heading>
    }
    { children }
  </div>
);

Primary.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string
};

export default Primary;
