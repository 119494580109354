import React from 'react';
import { QueryErrorResetBoundary } from 'react-query';
import { ErrorBoundary } from 'react-error-boundary';
import cx from 'classnames';

import styles from './styles.module.scss';

const QueryErrorBoundary = ({ children }) => (
  <QueryErrorResetBoundary>
    {({ reset }) => (
      <ErrorBoundary
        onReset={reset}
        fallbackRender={({ resetErrorBoundary }) => (
          <div className={cx('cui-panel cui-text_center', styles.container)}>
            <div className="cui-panel-content">
              <h2>Page Load Error!</h2>
              <p>
                <span>
                  There is an issue loading this page. We have been notified and are
                  working on a resolution. Please contact{' '}
                </span>
                <a href="mailto:support@brightbytes.net" target="_blank" rel="noopener noreferrer">
                  Support
                </a>
                <span> with any questions/concerns.</span>
              </p>
            </div>
            <div className="cui-panel-footer">
              <button
                className="cui-btn cui-btn_solid cui-btn_primary"
                onClick={() => resetErrorBoundary()}
              >
                Try again
              </button>
            </div>
          </div>
        )}
      >
        {children}
      </ErrorBoundary>
    )}
  </QueryErrorResetBoundary>
);

export default QueryErrorBoundary;
