import demographicAdapterWrapper from './demographicAdapterWrapper';
import invertNestedObjectAdapter from './Table/invertNestedObjectAdapter';

// Bar Chart Adapters
import scoresAdapter from './BarChart/scoresAdapter';
import multiFrameworkAdapter from './BarChart/multiFrameworkAdapter';
import confidenceIntervalsBarChartAdapter from './BarChart/confidenceIntervalsBarChartAdapter';
import groupedBarChartAdapter from './BarChart/groupedBarChartAdapter';
import dddGroupedBarChartAdapter from './BarChart/dddGroupedBarChartAdapter';
import percentBarChartAdapter from './BarChart/percentBarChartAdapter';
import stackedBarChartAdapter from './BarChart/stackedBarChartAdapter';
import defaultBarChartAdapter from './BarChart/defaultBarChartAdapter';
import dddDynamicShapeBarChartAdapter from './BarChart/dddDynamicShapeBarChartAdapter';
import entityBarChartAdapter from './BarChart/entityBarChartAdapter';
import entityComparisonBarChartAdapter from './BarChart/entityComparisonBarChartAdapter';
import entityComparisonBarChartSingleValueAdapter from './BarChart/entityComparisonBarChartSingleValue';
import selfDescribedPercentAdapter from './BarChart/selfDescribedPercentAdapter';
import dddGenericBarChartAdapter from './BarChart/dddGenericBarChartAdapter';

// Trends Chart Adapters
import defaultTrendsChartAdapter from './TrendCharts/defaultTrendsChartAdapter';
import yearlyTrendsChartAdapter from './TrendCharts/yearlyTrendsChartAdapter';
import eiTrendsChartAdapter from './TrendCharts/eiTrendsChartAdapter';
import eiStudentTrendsChartAdapter from './TrendCharts/eiStudentTrendsChartAdapter';

// Donut Chart Adapters
import multiScoresDonutChart from './DonutChart/multiScoresDonutChartAdapter';
import genericScoreDonutChart from './DonutChart/genericMultiScoreDonutChartAdapter';
import defaultDonutChartAdapter from './DonutChart/defaultDonutChartAdapter';

// Progress Chart Adapters
import groupedScoreProgressChartAdapter from './ProgressChart/groupedScoreProgressChartAdapter';
import dddProgressBarChartAdapter from '../dddProgressBarChartAdapter';

// Icon Chart Adapters
import defaultIconChartAdapter from './IconChart/defaultIconChartAdapter';
import positiveIntegerIconChartAdapter from './IconChart/positiveIntegerIconChartAdapter';

// Table Chart Adapters
import defaultTableComparisonAdapter from './Table/defaultTableComparisonAdapter';
import selfDescribedTableAdapter from './Table/selfDescribedTableAdapter';

// Status and Change Adapters
import statusAndChangeCompareAdapter from './StatusAndChange/StatusAndChangeCompare';
import selfDescribedStatusAndChangeCompare from './StatusAndChange/selfDescribedStatusAndChangeCompare';

// MultiValueListChart Adapters
import checkboxQuestionAdapter from './MultiValueListChart/checkboxQuestionAdapter';
import svcpdListAdapter from './MultiValueListChart/svcpdListAdapter';

const adapterMap = {
  '': () => {},
  barChart: defaultBarChartAdapter,
  demographicBarChart: demographicAdapterWrapper(defaultBarChartAdapter),
  entityBarChart: entityBarChartAdapter,
  percentBarChart: percentBarChartAdapter,
  demographicPercentBarChart: demographicAdapterWrapper(percentBarChartAdapter),
  dateBarChart: percentBarChartAdapter,
  donutChart: defaultDonutChartAdapter,
  dddDynamicShapeBarChartAdapter,
  dddGroupedBarChartAdapter,
  demographicDonutChart: demographicAdapterWrapper(defaultDonutChartAdapter),
  confidenceIntervalsBarChart: confidenceIntervalsBarChartAdapter,
  groupedBarChart: groupedBarChartAdapter,
  trendsChart: defaultTrendsChartAdapter,
  eiTrendsChart: eiTrendsChartAdapter,
  iconChart: defaultIconChartAdapter,
  positiveIntegerIconChartAdapter,
  demographicIconChart: demographicAdapterWrapper(defaultIconChartAdapter),
  stackedBarChart: stackedBarChartAdapter,
  genericScoreDonutChart,
  dddGenericBarChartAdapter,
  multiScoresDonutChart,
  multiFrameworkBarChart: multiFrameworkAdapter,
  scoresBarChart: scoresAdapter,
  groupedScoreProgressChart: groupedScoreProgressChartAdapter,
  entityComparisonBarChart: entityComparisonBarChartAdapter,
  demographicEntityComparisonBarChart: demographicAdapterWrapper(entityComparisonBarChartAdapter),
  yearlyTrendsChart: yearlyTrendsChartAdapter,
  demographicYearlyTrendsChart: demographicAdapterWrapper(yearlyTrendsChartAdapter),
  demographicEntityComparisonFilteredBarChart: entityComparisonBarChartAdapter,
  entityComparisonBarChartSingleValue: demographicAdapterWrapper(entityComparisonBarChartSingleValueAdapter),
  selfDescribedPercentAdapter,
  defaultTableComparisonAdapter,
  selfDescribedTableAdapter,
  FilteredTableComparison: defaultTableComparisonAdapter,
  invertNestedObject: invertNestedObjectAdapter,
  demographicsTrendsAdapter: demographicAdapterWrapper(defaultTrendsChartAdapter),
  statusAndChangeCompareAdapter,
  demographicsStatusAndChangeCompareAdapter: demographicAdapterWrapper(statusAndChangeCompareAdapter),
  selfDescribedStatusAndChangeCompare,
  checkboxQuestionAdapter,
  svcpdListAdapter,
  dddProgressBarChartAdapter,
  eiStudentTrendsChartAdapter
};

const dataAdapter = (adapterType, dataOptions) => {
  if (!adapterType) return () => false;
  const localizedAdapterMap = Object.assign({}, adapterMap);

  if (dataOptions.additionalChartAdapters) {
    Object.assign(localizedAdapterMap, dataOptions.additionalChartAdapters);
  }

  return localizedAdapterMap[adapterType](dataOptions);
};

export default dataAdapter;
