// This is fork of the table chart in the common folder
// TODO add storybook documentation
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import snakeize from 'snakeize';

import Table from 'cui/lib/components/Table';
import Panel from 'cui/lib/components/Panel';
import BlockTitle from 'cui/lib/components/BlockTitle';

import chartDataAdapter from '../../helpers/chartDataAdapters';
import when from '../../helpers/when';
import { getValueFromScores } from '../../helpers/scoresHelpers';
import ChartEmptyState from '../../components/Chart/ChartEmptyState';
import { KENTUCKY_ORG_ID } from '../../constants';
import applyAdapters from '../../helpers/adapterHelpers';
import { isStringOrObjectWithTruthyWhen } from '../../helpers/chartAdapterUtils';
import TextCell from './../TableChart/TextCell';
import LabelCell from './../TableChart/LabelCell';
import styles from './index.module.scss';
import { isObject } from 'lodash';

const a11yAttrs = {
  tabIndex: '0'
};

function tableHeaders({ options }, columns = []) {
  let headers = options.headers;
  if (!headers) {
    headers = columns.map(column => column.header);
  } else if (options.hideEmptyColumns) {
    headers = headers.filter(header => columns.some(column => column.header === header));
  }

  return headers;
}

/* eslint-disable complexity */
export const TableChartQuestion = (props) => {
  const { config: chartConfig, scores, organization, node, additionalChartAdapters, viewingDropdownFilter, schoolYear } = props;
  const { options = {}, alwaysRender = false, useStateData = false } = chartConfig;
  const { hideNullRows = false, hideZeroRows = false, defaultScoreKey = '', defaultScore = {}, hideEmptyColumns = false } = options;
  const whenProperties = {
    viewingDropdownFilter: get(viewingDropdownFilter, '0')
  };

  let rows = options.rows;
  let columns = options.columns || [];
  columns = columns.filter(column => !column.when || isStringOrObjectWithTruthyWhen(column, whenProperties));

  if (isEmpty(scores) && !alwaysRender) return null;

  // Not using `directScore`/currentOrgScore here
  const valueForOrg = getValueFromScores(scores, organization.id, node);

  let currentScore = defaultScoreKey ? valueForOrg[defaultScoreKey] : valueForOrg;


  if (useStateData) {
    const stateScore = getValueFromScores(scores, KENTUCKY_ORG_ID, node);
    currentScore = stateScore;
  }

  // Skip empty check if node has `alwaysRender`
  if (isEmpty(currentScore) && !alwaysRender) {
    /* eslint-disable no-console */
    console.error(`No Score Found for organization ${organization.id}`);
    /* eslint-enable no-console */
    return (<ChartEmptyState />);
  }

  if (chartConfig.adapter) {
    let scoreFromAdapter;

    if (Array.isArray(chartConfig.adapter)) {
      // Use new adapter api
      scoreFromAdapter = applyAdapters(chartConfig.adapter, currentScore, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    } else {
      scoreFromAdapter = chartDataAdapter(chartConfig.adapter, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    }

    if (isEmpty(scoreFromAdapter)) {
      return (<ChartEmptyState />);
    }

    if (scoreFromAdapter.additionalRows && scoreFromAdapter.score) {
      currentScore = scoreFromAdapter.score;
      // Handle rows from adapter
      // usually because those rows are dynamically computed
      rows = rows ? rows.concat(scoreFromAdapter.additionalRows) : scoreFromAdapter.additionalRows;
    } else {
      currentScore = scoreFromAdapter;
    }
  }

  // Get keys of all scores to be loaded in column configs
  const scoreKeys = uniq(compact(map(columns, 'scoreKey')));
  currentScore = reduce(currentScore, (acc, entity, questIndex) => {
    let rowData = compact(map(entity, (value, key) => {
      const commonProps = {
        key,
        entityType: organization.entityType || organization.entity_type
      };
      // add order index so that we can sort later by ordering specified in rows
      if (rows) {
        commonProps.orderIndex = rows.indexOf(key);
      }
      // https://app.clubhouse.io/brightbytes/story/31140/when-showing-data-points-by-level-only-show-relevant-level-for-org
      // This allows for only showing scores we have data for, and removing rows that don't have data
      // Simpler than writing logic to handling configurable show/hiding based on a organization's level (i.e. elementary, middle, high schools)
      if (hideNullRows && isEmpty(value)) {
        return null;
      }
  
      if (isObject(value)) {
        let outValue = value;
        // Handle `defaultScore` directive
        // This also speeds up rendering time by preventing the <TextCell/> templates from catching
        //   and logging `ReferenceErrors` for properties that don't exist.
        if (!isEmpty(defaultScore)) {
          // Pre-fill individual data objects with default values and keys
          outValue = reduce(scoreKeys, (acc, scoreKey) => {
            acc[scoreKey] = { ...defaultScore, ...get(value, scoreKey, {}) };
            return acc;
          }, value);
        }
  
        // Unpack score values into the row data
        return { ...outValue, ...commonProps };
      }
      return { value, ...commonProps };
    }));

    acc[questIndex] = rowData;
    return acc;
  }, {});

  if (rows) {
    currentScore = reduce(currentScore, (acc, entity, value) => {
      const rowsToShow = new Set(rows);
      // Remove those that are not in the rows config
      entity = reduce(entity, (acc2, entity2, key) => {
        if (rowsToShow.has(entity2.key)) {
          acc2.push(entity2);
        }
        return acc2;
      }, []);
      acc[value] = entity;
      return acc;
    }, {});
  }

  const sortRows = get(chartConfig, 'options.sortRows');
  if (sortRows) {
    rowData = orderBy(rowData, [sortRows.key], [sortRows.order]);
  }
  // Columns config takes `type` which configures how the cell is displayed
  const COLUMN_TYPE_MAP = {
    label: LabelCell,
    text: TextCell
  };

  const cellModifier = get(chartConfig, 'options.cellModifier');
  const renderCell = (type, data, { when: columnCondition = 'true', ...colOptions }) => {
    const Cell = get(COLUMN_TYPE_MAP, type);
    let cellData = data;

    // With complete cell data, the scoreKey determines what sub-value object to display
    // i.e. if data is nested like:
    //   data := {
    //     foo: { value: 10 },
    //     bar: { value: 20 },
    //     baz: { value: 30 }
    //   }
    //   colOptions.scoreKey := 'bar'
    //   cellData := { value: 20 }
    if (colOptions.scoreKey) {
      cellData = data[colOptions.scoreKey];
    }

    if (colOptions.snakecaseKeys) {
      cellData = snakeize(cellData);
    }

    const percentRow = get(chartConfig, 'options.percentRow.destRow');
    if (percentRow && data.key === percentRow) {
      colOptions.alternateTextProp = get(chartConfig, 'options.percentRow.alternateTextKey');
    }

    const textAlign = type === 'text' ? get(chartConfig, 'textAlign', null) : null;
    const noWrap = type === 'text' ? get(chartConfig, 'noWrap', null) : null;
    const cellClasses = {
      [styles.lastSlantedDataColumn]: colOptions.isLastCol && chartConfig.slanted,
      [styles.lastDataColumn]: !chartConfig.slanted && colOptions.numOfColumns === 2 && colOptions.colIndex > 0,
      [styles.dataColumn]: colOptions.colIndex !== 0,
      [styles.noWrap]: noWrap
    };

    cellData = cellData ? cellData : '';

    return when(columnCondition, {
      ...cellData,
      ...whenProperties
    }) ? (<Cell item={{ ...cellData, cellData, rowKey: data.key }} cellModifier={cellModifier} textAlign={textAlign} {...colOptions} className={cx(cellClasses)} />) : '';
  };

  const renderRow = (row, rowIndex) => (
    <tr key={rowIndex}>
      {map(columns, ({ type, ...colOptions }, colIndex) => {
        if (colIndex === 0) {
          return (
            <th key={colIndex} className={cx(styles.rowHeader, { [styles.rowHeader_blue]: chartConfig.useBlueHeader })}>
              {renderCell(type, row, { ...colOptions, rowIndex, colIndex })}
            </th>
          );
        }

        return (
          <td key={colIndex}>
            {
              renderCell(
                type,
                row,
                {
                  ...colOptions,
                  rowIndex,
                  colIndex,
                  isLastCol: colIndex === columns.length - 1,
                  numOfColumns: columns.length
                }
              )
            }
          </td>
        );
      })}
    </tr>
  );

  const renderColumnHeader = (contentClassName, type, headerClassName, value, colIndex, numOfColumns) => {
    const content = contentClassName ? <div className={contentClassName}>{value}</div> : value;
    const dataColumnClass = chartConfig.slanted ? styles.slantedHeaderColumn : styles.dataColumn;

    const columnClass = {
      [dataColumnClass]: colIndex !== 0,
      [styles.lastDataColumn]: !chartConfig.slanted && numOfColumns === 2 && colIndex > 0
    };

    return (
      <Table.ColumnHeader
        className={headerClassName}
        key={value}
        property={value}
      >
        <div className={cx(columnClass)}>{content}</div>
      </Table.ColumnHeader>
    );
  };

  const className = cx('cui-margin-bottom-none', styles.table, { [styles.slantedTable]: chartConfig.slanted });
  const headers = tableHeaders(chartConfig, columns)
    .filter(header => isStringOrObjectWithTruthyWhen(header, whenProperties));

  return (
    <>
      {
        map(currentScore, (rowData, index) => {
          return <Panel key={`panel${index}`}>
            <Panel.Header>
              <BlockTitle
                title={rowData && rowData.length > 0 ? rowData[0].question_text : ""}
                className={styles.title}
              />
            </Panel.Header>
            <Panel.Content>
              <Table
                key={`table${index}`}
                data={rowData}
                renderRow={renderRow}
                className={className}
                description={get(node, 'name')}
                tbodyAttrs={a11yAttrs}
              >
                {
                  headers
                    .map((header, index) => renderColumnHeader(
                      chartConfig.slanted ? styles['header-content'] : '',
                      columns[index].type,
                      styles.header,
                      typeof header === 'object' ? header.value : header,
                      index,
                      headers.length
                    ))
                }
              </Table>
            </Panel.Content>
          </Panel>
        })
      }
    </>
  );
};

TableChartQuestion.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    entity_type: PropTypes.string,
    entityType: PropTypes.string
  }),

  // Score data
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      remote_organization_id: PropTypes.number.isRequired,
      value: PropTypes.object.isRequired
    })
  ),

  // Framework Tree Node
  node: PropTypes.object,

  // Chart Config object
  config: PropTypes.shape({
    options: PropTypes.shape({
      defaultScoreKey: PropTypes.string, // key of score object to select by default
      headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['label', 'text']).isRequired,
          when: PropTypes.string,
          labels: PropTypes.objectOf(PropTypes.string),
          tags: PropTypes.arrayOf(PropTypes.object),
          text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
          ]),
          icon: PropTypes.string,
          theme: PropTypes.string,
          scoreKey: PropTypes.string
        })
      ).isRequired
    })
  }),

  // Optionally extend the list of adapters this chart knows about
  additionalChartAdapters: PropTypes.object,
  viewingDropdownFilter: PropTypes.object,
  schoolYear: PropTypes.number
};

TableChartQuestion.defaultProps = {
  organization: {
    id: null
  },
  scores: [],
  config: {},
  viewingDropdownFilter: {}
};

export default TableChartQuestion;
/* eslint-enable complexity */
