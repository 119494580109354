import find from 'lodash/find';
import get from 'lodash/get';

const SCHOOL_LEVELS_MAP = {
  ES: 'elementary_school',
  MS: 'middle_school',
  HS: 'high_school'
};

// Make sure we display our data in this order
const SCHOOL_LEVELS = ['ES', 'MS', 'HS'];

export function getOrgType(organization) {
  return (get(organization, 'entity_type') || '').toLowerCase();
}

export function isElementarySchool(org) {
  return getOrgType(org) === 'school' && org.school_level === 'ES';
}

export function isMiddleSchool(org) {
  return getOrgType(org) === 'school' && org.school_level === 'MS';
}

export function isHighSchool(org) {
  return getOrgType(org) === 'school' && org.school_level === 'HS';
}

// @param org object, organization object
// @return levels for a school
export function levelsForSchool(org, year) {
  const orgType = getOrgType(org);
  let schoolLevel = org.school_level;

  if (year) {
    const orgData = find(org.organization_data, { year }); // try to get organization_data for specified year
    schoolLevel = get(orgData, 'school_level');
  }

  if (orgType === 'school' && !!schoolLevel) {
    return schoolLevel.trim().split(',').sort((a, b) => (SCHOOL_LEVELS.indexOf(a) - SCHOOL_LEVELS.indexOf(b)));
  }

  return [];
}

// @param levels {array} array of levels ['HS', 'MS', 'ES']
// @return score keys as ['elementary_school', 'middle_school', 'high_school']
export function levelsToScoreKeys(levels) {
  return levels.map(l => SCHOOL_LEVELS_MAP[l]).filter(l => !!l);
}

// @param org object, organization object
// @return school levels of the organization
// Note that an organization may have multiple levels
// the school_level value is commas separated in this case
export function getSchoolLevels(org, year) {
  const orgType = getOrgType(org);
  let levels = [];

  if (orgType === 'school') {
    levels = levelsForSchool(org, year);
  } else if (orgType === 'district' || orgType === 'state') {
    // return all levels for district and state
    levels = SCHOOL_LEVELS;
  }

  return levelsToScoreKeys(levels);
}
