import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import ViewingBlock from 'cui/lib/components/ViewingBlock';
import EmptyState from 'cui/lib/components/EmptyState';

import buildDropdownOptions from '../../helpers/buildDropdownOptions';

import { TrendsChart } from '../TrendsChart';
import styles from './filteredChart.module.scss';

export class FilteredTrendsChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selected: get(props.config, 'defaultSelection', '5') };
  }

  selectionChanged(item) {
    this.setState({ selected: item.key });
  }

  render() {
    const currentFilter = get(this.props.params, 'filters.demographic', 'All');
    const value = get(this.props, `directScore.value.${currentFilter}`, {});
    const options = buildDropdownOptions(Object.keys(value), get(this.props.config, 'filterOptions'));
    let selectedIndex = findIndex(options, o => o.key === this.state.selected);
    if (selectedIndex === -1) {
      selectedIndex = 0;
    }
    const selectedKey = options[selectedIndex].key;

    const dataPointHasOptions = !isEmpty(options);

    const scores = reduce(this.props.scores, (acc, score) => {
      const scoreValue = (get(score, `value.${currentFilter}.${selectedKey}`));
      if (scoreValue) {
        acc.push(
          {
            ...score,
            value: scoreValue
          });
      }
      return acc;
    }, []);

    return (
      <div>
        {
          !dataPointHasOptions && <EmptyState kind="text" title="There is no data available." />
        }
        {
          dataPointHasOptions &&
          <div>
            <ViewingBlock
              type="dropdown"
              items={options}
              initiallySelectedItem={selectedIndex}
              onSelectionChange={item => this.selectionChanged(item)}
              className={styles.viewingDropdown}
            />
            <TrendsChart {...this.props} scores={scores} />
          </div>
        }
      </div>
    );
  }
}

FilteredTrendsChart.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.shape({
    filterOptions: PropTypes.object,
    theme: PropTypes.string
  }),
  directScore: PropTypes.shape({
    value: PropTypes.object
  }),
  params: PropTypes.shape({
    filters: PropTypes.shape({
      demographic: PropTypes.string
    })
  })
};

export default FilteredTrendsChart;
