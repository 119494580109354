import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'cui/lib/components/Icon';
import Button from 'cui/lib/components/Button';

import HeaderBlockMap from '../../components/HeaderBlockMap';
import OrgPanelUI from '../../components/OrgPanelUI';
import ClassificationDetails from '../../components/OrgProfile/ClassificationDetails';
import Title1Status from '../../components/OrgProfile/Title1Status';
import FamilyInfoBanner from '../../components/FamilyInfoBanner';
import PanelHeader from '../../ui/PanelHeader';

import BoardMembers from './BoardMembers';
import Summary from './Summary';
import Address2022 from './Address2022';
import SuperIntendentMessageModal from './SuperIntendentMessageModal';
import { ORG_TYPES } from '../../constants';

const CLASSIFICATION_DESCRIPTION = 'open popover for more information about classification.';
const TITLE1_DESCRIPTION = 'open popover for more information about title 1 status.';

const OrgPanels2022 = ({
  address,
  classification,
  boardMembers,
  organization,
  orgType,
  orgName,
  summary,
  title1,
  websiteUrl,
  researchLink,
  districtMessage
}) => {
  const [isSuperIntendentMessageModalOpen, setSuperIntendentMessageModalOpen] = useState(
    false
  );
  return (
    <div>
      <OrgPanelUI>
        <OrgPanelUI.First>
          <Summary summary={summary} headingLevel={3} />
          {classification && (
            <ClassificationDetails
              title={classification}
              description={CLASSIFICATION_DESCRIPTION}
            />
          )}
          {title1 && <Title1Status title={title1} description={TITLE1_DESCRIPTION} />}
        </OrgPanelUI.First>
        <OrgPanelUI.Second>
          {!window.Cypress && (
            <HeaderBlockMap organization={organization} mapZoom={10} mapOptions={{}} />
          )}
          <Address2022
            address={address}
            orgType={orgType}
            orgName={orgName}
            websiteUrl={websiteUrl}
          />
        </OrgPanelUI.Second>
        <OrgPanelUI.Sidebar>
          <PanelHeader title="Resources" />
          <ul className="cui-list cui-list_spread kde-list_blue">
            {orgType !== ORG_TYPES.STATE && <li className="u-list-item">
              <Icon className="u-color-kde-text-tint-2 cui-list-icon u-icon-spacing" name="kde-message" />
              <span className="cui-list-text">
                <Button
                  kind="link"
                  onClick={() => setSuperIntendentMessageModalOpen(true)}
                >
                  A Message from the Superintendent
                </Button>
              </span>
            </li>}
            <li className="u-list-item"><BoardMembers boardMembers={boardMembers} orgType={orgType} /></li>
            <li className="u-list-item"><FamilyInfoBanner /></li>
            <li className="u-list-item">
              <Icon className="u-color-kde-text-tint-2 cui-list-icon u-icon-spacing" name="kde-a-plus" />
              <span className="cui-list-text">
                <a href={researchLink} target="_blank" rel="noopener noreferrer">Research Data <Icon name="bb-arrow-box-top" /></a><br />
                Data sets for analyzing Kentucky education data
              </span>
            </li>
            {websiteUrl && <li className="u-list-item">
              <Icon className="u-color-kde-text-tint-2 cui-list-icon u-icon-spacing" name="kde-globe" />
              <span className="cui-list-text">
                <a href={websiteUrl} title="Website" target="_blank" rel="noreferrer">{orgName} Website</a>
              </span>
            </li>
            }
          </ul>
        </OrgPanelUI.Sidebar>
      </OrgPanelUI>
      {orgType !== ORG_TYPES.STATE && <SuperIntendentMessageModal
        isOpen={isSuperIntendentMessageModalOpen}
        onHide={() => setSuperIntendentMessageModalOpen(false)}
        districtMessage={districtMessage}
      />}
    </div>
  );
};

OrgPanels2022.propTypes = {
  address: PropTypes.object,
  classification: PropTypes.string,
  boardMembers: PropTypes.arrayOf(PropTypes.string),
  federalClassification: PropTypes.object,
  organization: PropTypes.object,
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  orgType: PropTypes.string,
  orgName: PropTypes.string,
  summary: PropTypes.arrayOf(PropTypes.object),
  schoolYear: PropTypes.number,
  title1: PropTypes.string,
  websiteUrl: PropTypes.string,
  researchLink: PropTypes.string,
  districtMessage: PropTypes.string,
  schoolStat: PropTypes.func
};

export default OrgPanels2022;
