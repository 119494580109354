import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Field } from 'cui';
import ChartEmptyState from '../Chart/ChartEmptyState';
import emptyStateMessage from '../../helpers/emptyState';
import { gaugeScoreSelectorConfig } from '../../selectors/accountabilitySelector';
import Gauge from '../../ui/Gauge';
import AccountabilityLegend from '../../ui/AccountabilityLegend';

function AcctGaugeChart(props) {
  const {
    config,
    acctScores,
    node,
    organization,
    isLoading
  } = props;

  const scores = !acctScores ? [] : acctScores.filter(rec => get(rec, `rating.${config.scoreKey}`, '') !== '');
  const isDataEmpty = scores.length === 0;

  if (isDataEmpty) {
    return <ChartEmptyState emptyMessage={emptyStateMessage(node, organization)} />;
  }

  return (
    <div aria-hidden="true">
      <AccountabilityLegend />
      <small>
        <i>Indicator performance rating is one (1) of five (5) color-coded performance levels that
          aggregates all available state indicator data for performance status</i>
      </small>
      <br />
      <br />
      <div className="gatewayGrid-fullcharts">
        {
          scores.map(({ heading, rating }) => (
            <Field className="multiInputField  maxWidth300">
              <Gauge
                title={heading}
                score={get(rating, `${config.scoreKey}`)}
                isLoading={isLoading}
              />
            </Field>
          ))
        }
      </div>
    </div>
  );
}

function mapStateToProps(state, props) {
  const gaugeScoreSelector = gaugeScoreSelectorConfig(get(props, 'node.node_path'));

  return {
    acctScores: gaugeScoreSelector(state),
    framework: get(state, 'framework.kde')
  };
}

export default connect(mapStateToProps)(AcctGaugeChart);

AcctGaugeChart.propTypes = {
  config: PropTypes.object,
  acctScores: PropTypes.arrayOf(PropTypes.object),
  framework: PropTypes.object,
  node: PropTypes.object,
  organization: PropTypes.object,
  isLoading: PropTypes.bool
};
