// Apply the adapters in sequential order and pass score returned from previous adapter to the next adapter
// so that we can compose adapters for better reusability and don't have to create specific adapter for charts
import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';
import compact from 'lodash/compact';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import every from 'lodash/every';
import has from 'lodash/has';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';

import template from './template';
import when from './when';

const DATA_SUPPRESSION_SYMBOL = '*';

export function containMoreThan(data, keys, threshhold) {
  return threshhold < keys.filter(key => !isEmpty(get(data, key))).length;
}

export function isDataSuppressed(score, data) {
  if (data && Array.isArray(data) && data.length) {
    return every(data, (item) => {
      if (Array.isArray(item.value)) {
        return isDataSuppressed(score, item.value);
      }
      if (has(item, 'suppressed')) {
        return item.suppressed;
      }
      if (has(item, 'score.suppressed')) {
        return item.score.suppressed === 'Y';
      }
      return false;
    });
  }

  if (score === Object(score)) {
    return Object.values(score).every((value) => {
      if (value === Object(value)) {
        return isDataSuppressed(value);
      }
      return value === '*';
    });
  }

  return false;
}

// For bar chart, each data value must be number
export function isValidBarChartData(data) {
  if (Array.isArray(data) && data.length) {
    return every(data, (item) => {
      if (Array.isArray(item.value)) {
        return isValidBarChartData(item.value);
      }
      const value = isString(item.value) ? parseFloat(item.value) : item.value;
      return Number.isFinite(value) || item.suppressed;
    });
  }

  return true;
}

// check if score contains suppressions data
// @param score {object}, data score
// @param checks {array of string}, paths to check
// @return boolean
export function checkForDataSuppression(score, checks) {
  // Loop through the array of checks to see any of the path contain suppressed data
  return checks.some((pathToCheck) => {
    const value = get(score, pathToCheck);
    return isObject(value) ? value.suppressed === 'Y' : (value === DATA_SUPPRESSION_SYMBOL);
  });
}

// @param data { array of object or object }, data items
// @param props { object }, value mappings
// @return data with additional computed props
export function computeProps(data, props, context = {}) {
  if (props) {
    if (Array.isArray(data)) {
      // Process each array item and add computed props
      return data.map((item, index) => reduce(props, (memo, prop) => {
        memo[prop.key] = computeValueFromTemplate(prop.value, prop.format, { ...memo, ...context, index });
        return memo;
      }, { ...item }));
    } else if (!isEmpty(data)) {
      // Otherwise if data is object, process each key value pairs
      return reduce(data, (memo, item, key) => {
        memo[key] = reduce(props, (acc, prop) => {
          if (!prop.when || (prop.when && when(prop.when, { ...context, currentKey: key }))) {
            acc[prop.key] = computeValueFromTemplate(prop.value, prop.format, { ...acc, ...context });
          }

          return acc;
        }, { ...item });
        return memo;
      }, {});
    }
  }

  return data;
}

// Each adapter has argument signature as (score, options) where options is object of commonly used configs
// For example, function adapter(score, { node, currentOrganization, chartConfig, additionalChartAdapters, viewingDropdownFilter })
// @param adaptersFromConfig {string | Array of String } adapter from chartConfig
// @param score {object} current org score
// @param options {object} some common configurations
// @return {object} transformed score that is ready for chart to use
export default function applyAdapters(adaptersFromConfig, score, options) {
  // Normalize the adapters as array
  const adapters = Array.isArray(adaptersFromConfig) ? adaptersFromConfig : [adaptersFromConfig];
  // Lookup the adapter function and paritally apply options
  // In case of adapter is not found, log message to debugging purpose
  const adapterFuncs = compact(adapters.map((adapterName) => {
    let adapterFunc = get(options, ['additionalChartAdapters', adapterName]);
    if (adapterFunc) {
      adapterFunc = partialRight(adapterFunc, options);
    } else {
      console.log(`we could not find this adapter: ${adapterName}`);
    }

    return adapterFunc;
  }));

  return flow(...adapterFuncs)(score);
}

function computeValueFromTemplate(value, format, context) {
  const result = template(value, context);
  return format === 'number' ? parseFloat(result) : result;
}
