import get from 'lodash/get';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';

export default function resolveScoreDependencies(scores, currentNodes) {
  // first, get a list of all node paths that pull their score from a different
  // node
  const nodesWithDependencies = keyBy(filter(currentNodes, 'data_node_path'), 'node_path');

  // then filter out any existing scores for those nodes (which makes this
  // function isomorphic)
  scores = filter(scores, score => !nodesWithDependencies[get(score, 'framework_tree_node.node_path')]); // eslint-disable-line no-param-reassign

  // then group the remaining scores by their node path
  const scoresByNodePath = groupBy(scores, 'framework_tree_node.node_path');

  // then, for every node that has a dependency, duplicate all of the
  // dependency's scores but with the dependent node's node path, and that's it.
  return scores.concat(flatMap(nodesWithDependencies, node =>
    map(get(scoresByNodePath, [node.data_node_path], []), targetScore => ({
      ...targetScore,
      framework_tree_node: {
        node_path: node.node_path
      }
    }))
  ));
}
