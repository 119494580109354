import React from 'react';
import PropTypes from 'prop-types';

import multiSlot from 'cui/lib/components/shared/MultiSlot';

import Charts from './Charts';
import Footer from './Footer';
import Header from './Header';
import Links from './Links';

GatewayPanel.propTypes = {
  slots: PropTypes.shape({
    Charts: PropTypes.node,
    Footer: PropTypes.node,
    Header: PropTypes.node,
    Links: PropTypes.node
  })
};

function GatewayPanel({ slots }) {
  const displayChartsSlot = !!React.Children.count(slots.Charts);
  const displayFooterSlot = !!React.Children.count(slots.Footer);
  const displayLinksSlot = !!React.Children.count(slots.Links);
  const displayGrid = displayChartsSlot || displayFooterSlot || displayLinksSlot;

  return (
    <div>
      {slots.Header}
      {
        displayGrid && (
          <div className="gatewayGrid">
            {
              displayLinksSlot && (
                <div className="gatewayGrid-sidebar">
                  {slots.Links}
                </div>
              )
            }
            {displayChartsSlot && slots.Charts}
            {displayFooterSlot && slots.Footer}
          </div>
        )
      }
    </div>
  );
}

const GatewayPanelComponent = multiSlot(GatewayPanel);
GatewayPanelComponent.Charts = Charts;
GatewayPanelComponent.Footer = Footer;
GatewayPanelComponent.Header = Header;
GatewayPanelComponent.Links = Links;

export default GatewayPanelComponent;
