import { calculatePercentage } from '../../helpers/portalDataServiceHelpers';

// Takes as input a score that's otherwise already in the shape KdeTableChart
// expects and tacks on a percent column using the same calculatePercentage
// helper that most other adapters use. Use this adapter when you need
// literally no other logic except adding a percent column in.
//
// Takes percentageNumeratorKey and percentageDenumeratorKey options,
// specifying the keys to use for each row to calculate the percentage.
// Injects the resulting percentage into a column named "percentage".

export default function adapter({ currentOrgScore, chartConfig }) {
  return calculatePercentage(chartConfig, currentOrgScore);
}
