import * as Yup from 'yup';

export default Yup.object().shape({
  dataIssues: Yup.array()
    .min(1, 'Please add at least one data issue')
    .of(
      Yup.object().shape({
        errorType: Yup.string().required('This field is required'),
        comment: Yup.string().required('This field is required')
      })
    )
});
