import React from 'react';
import PropTypes from 'prop-types';

import styles from './Classification.module.scss';

const Classification = ({ classification, classificationDescription, classificationData }) => (
  <div className={styles.Classification}>
    { classification ?
      <div>
        <div className={styles.Classification_classification}>{classification}</div>
        { classificationData.length > 0 &&
          <div className={styles.Classification_reason}>
            <strong>Identification Reason(s): </strong>
            { classificationData.map((reason, i) => (
              <span className={styles.reason} key={i}>
                {reason.reason}
              </span>
            ))}
          </div>
        }
        { classificationData.length === 0 &&
          <small><em>None provided</em></small>
        }
        <div className={styles.Classification_description}>{classificationDescription}</div>
      </div>
      :
      <div>No classification</div>
    }
  </div>
);

Classification.propTypes = {
  classification: PropTypes.string.isRequired,
  classificationDescription: PropTypes.string,
  classificationData: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string
    })
  )
};

export default Classification;
