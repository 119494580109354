import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const SLIDER_WIDTH = 240;

const LineRangeSlider = ({ max, min, title, value }) => {
  if (value < min || value > max) {
    // eslint-disable-next-line no-console
    console.warn(`Value must be less then ${max} and more then ${min}. "${value}" does not meet these requirements.`);
    return null;
  }

  function getPositionForValue(val) {
    return (SLIDER_WIDTH * (val - min)) / (max - min);
  }

  const thumbPosition = getPositionForValue(value);
  const zeroPosition = getPositionForValue(0);
  const shouldRenderZero = min < 0 && max > 0;

  return (
    <div className={styles.rangeSlider}>
      {title && <div className={styles.title}>{title}</div>}
      {value && <div className={styles.value}>{value}</div>}
      <div className={styles.wrapper}>
        <div>
          <div className={styles.thumbRail}>
            <div
              className={styles.thumb}
              style={{
                left: `${thumbPosition}px`
              }}
            >
              <Icon name="bb-chart-triangle" size="small" />
            </div>
          </div>
          <div className={styles.rail}>
            {
              shouldRenderZero && (
                <div
                  className={styles.railIndicator}
                  style={{
                    left: `${zeroPosition}px`
                  }}
                />
              )
            }
          </div>
          <div className={styles.label}>
            <span>{min}</span>
            {
              shouldRenderZero && (
                <span
                  className={styles.labelZero}
                  style={{
                    left: `${zeroPosition}px`
                  }}
                >
                  0
                </span>
              )
            }
            <span>{max}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

LineRangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  title: PropTypes.string,
  value: PropTypes.number
};

LineRangeSlider.defaultProps = {
  min: -150,
  max: 150,
  title: null,
  value: null
};

export default LineRangeSlider;
