import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cx from 'classnames';
import toUpper from 'lodash/toUpper';
import memoize from 'lodash/memoize';

import { Autocomplete } from 'cui';

import connected from '../connected';
import template from '../../helpers/template';
import styles from './index.module.scss';
import localeCompare from '../../helpers/localeCompare';
import addYearToUrl from '../Link/addYearToUrl';

const buildAutocompleteOptions = memoize((organizations) => {
  if (!organizations) return [];

  return organizations
    .map(({
      id, name = '', address = '', zipcode = '', city = ''
    }) => ({
      // Modify next line to add additional fields/text to be able to search for (e.g. zip code, city, county, etc.)
      text: `${name} ${address} ${zipcode} ${city}`,
      name,
      city: toUpper(city),
      address,
      zipcode,
      id
    }))
    .sort(({ name: a }, { name: b }) => localeCompare(a, b));
});

const buildAutocompleteAttrs = memoize((autocompleteAttrs) => (
  {
    // Defaults
    className: 'cui-input_stroked',
    type: 'search',
    placeholder: 'Search by school or district',
    'aria-label': 'Search by School or District',
    // override with passed-in props
    ...autocompleteAttrs
  }
));

// eslint-disable-next-line react/prop-types
const renderAutocompleteItem = ({
  name, address, city, zipcode, searchQuery
}) => (
  <div>
    <div className="cui-autocomplete-item-label">
      <Autocomplete.HighlightedItem text={name} match={searchQuery} />
    </div>
    <div className="cui-autocomplete-item-details">
      <Autocomplete.HighlightedItem
        text={`${address}, ${city}, KY ${zipcode}`}
        match={searchQuery}
      />
    </div>
  </div>
);

export class OrgSearch extends React.PureComponent {
  handleAutocompleteClick = (organization) => {
    const {
      onClick, location, linkRoute, history
    } = this.props;
    if (onClick) {
      onClick(organization);
      return;
    }

    const year = new URLSearchParams(location.search).get('year');
    const path = addYearToUrl(template(linkRoute, { organization }), year);
    history.push(path);
  }

  render() {
    const autocompleteOptions = buildAutocompleteOptions(this.props.organizations);
    const autocompleteAttrs = buildAutocompleteAttrs(this.props.autocompleteAttrs);

    return (
      <Autocomplete
        className={cx(this.props.className, styles.search)}
        data={autocompleteOptions}
        itemRenderer={renderAutocompleteItem}
        inputAttrs={autocompleteAttrs}
        onClick={this.handleAutocompleteClick}
        minQueryLength={2}
        minQueryLengthMessage="Enter at least two characters"
      />
    );
  }
}

OrgSearch.propTypes = {
  className: PropTypes.string,
  autocompleteAttrs: PropTypes.object,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      address: PropTypes.string,
      zipcode: PropTypes.string,
      city: PropTypes.string
    })
  ),
  linkRoute: PropTypes.string,
  onClick: PropTypes.func
};

OrgSearch.defaultProps = {
  autocompleteAttrs: {},
  className: '',
  linkRoute: ''
};

export default withRouter(connected(OrgSearch));
