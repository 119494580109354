import cn from 'classnames';
import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const KdeGroupedBarChartLegendItem = (props) => {
  const {
    colorIndex,
    hidden,
    label,
    onClick,
    slug,
    theme
  } = props;
  const itemClassNames = cn({
    [styles.item]: true,
    [styles.itemSelected]: !hidden
  });
  const iconClassNames = cn({
    [`theme-${theme}-fill-${colorIndex}`]: !hidden
  });
  const handleOnClick = (event) => {
    event.preventDefault();
    onClick({ colorIndex, label, slug });
  };

  /* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/interactive-supports-focus, jsx-a11y/click-events-have-key-events */
  return (
    <li key={slug}>
      <div
        aria-checked={!hidden}
        className={itemClassNames}
        onClick={handleOnClick}
        role="checkbox"
      >
        <Icon
          className={iconClassNames}
          name="bb-circle-check"
          size="large"
        />
        <span className={styles.label}>
          {label}
        </span>
      </div>
    </li>
  );
  /* eslint-enable jsx-a11y/no-static-element-interactions */
};

KdeGroupedBarChartLegendItem.propTypes = {
  colorIndex: PropTypes.number,
  hidden: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  slug: PropTypes.string,
  theme: PropTypes.string
};

KdeGroupedBarChartLegendItem.defaultProps = {
  colorIndex: null,
  hidden: false,
  label: null,
  onClick: () => {},
  slug: null,
  theme: null
};

export default KdeGroupedBarChartLegendItem;
