import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

import currentOrgYearScores from '../../helpers/scoresHelpers';
import Link from './Link';
import textLinkAdapter from '../../chartDataAdapters/textLink';
import validUrl from '../../helpers/validUrl';

const KdeTextLink = (props) => {
  const { node, organization, scores } = props;
  const { label, value } = textLinkAdapter(currentOrgYearScores(scores, organization, node));
  const { options = {} } = get(node, 'charts.default', {});

  return (
    <div className="cui-margin-top-medium cui-text_small">
      {validUrl(value) ? <Link icon={options.linkIcon} href={value}>{label}</Link> : value}
    </div>
  );
};

KdeTextLink.propTypes = {
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  )
};

KdeTextLink.defaultProps = {
  node: {},
  organization: {},
  scores: []
};

export default KdeTextLink;
