import { connect } from 'react-redux';
import DataPointFlagModal from './DataPointFlagModal';
import updateDataPointStatusAction from './updateDataPointStatusAction';

const mapDispatchToProps = dispatch => ({
  saveFlag: async params =>
    dispatch(updateDataPointStatusAction({ ...params, status: 'flagged' })),
  deleteFlag: async params =>
    dispatch(updateDataPointStatusAction({ ...params, status: 'unreviewed', dataIssues: [] }))
});

const findDataPointStatus = (state, nodePath) => {
  const { dataPointStatuses = [] } = state.module;

  return dataPointStatuses.find(el => el.sectionSlug === nodePath);
};

const mapStateToProps = (state, { nodePath }) => {
  const record = findDataPointStatus(state, nodePath);

  const isFlagged = record ? record.status === 'flagged' : false;
  const dataIssues = record ? record.dataIssues : [];

  return { isFlagged, dataIssues };
};

export default connect(mapStateToProps, mapDispatchToProps)(DataPointFlagModal);
