import React from 'react';
import { FieldArray } from 'formik';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';
import Text from 'cui/lib/components/Form/Text';
import Field from 'cui/lib/components/Form/Field';
import Select from 'cui/lib/components/Form/Select';
import Spacer from 'cui/lib/components/Spacer';

import { DATA_POINT_ERROR_TYPES, DEFAULT_SECTION_STATUS } from './constants';

const FormFields = ({ dataIssues, setFieldValue }) => (
  <FieldArray
    name="dataIssues"
    render={arrayHelpers => (
      <div>
        {dataIssues.map((item, idx) => (
          <div key={idx}>
            <Field label="Type of flag">
              <Select
                id={`errorType_${idx}`}
                required
                clearable={false}
                className="cui-select_stroked"
                options={DATA_POINT_ERROR_TYPES}
                value={item.errorType}
                onChange={value => setFieldValue(`dataIssues.${idx}.errorType`, value)}
              />
            </Field>

            <Field label="Comment">
              <Text
                required
                id={`comment_${idx}`}
                className="cui-input_stroked"
                type="textarea"
                value={item.comment}
                onChange={value => setFieldValue(`dataIssues.${idx}.comment`, value)}
              />
            </Field>

            {dataIssues.length > 1 && (
              <Button type="button" title="Delete flag" kind="clear" theme="caution" onClick={() => arrayHelpers.remove(idx)}>
                Delete flag
              </Button>
            )}

            <Spacer size="xLarge" />
          </div>
        ))}

        <Button type="button" title="Add another error" kind="clear" theme="primary" onClick={() => arrayHelpers.push(DEFAULT_SECTION_STATUS)}>
          <Icon name="bb-plus" />
          &nbsp; Add another flag
        </Button>
      </div>
    )}
  />
);

FormFields.propTypes = {
  dataIssues: PropTypes.arrayOf(
    PropTypes.shape({
      errorType: PropTypes.string,
      comment: PropTypes.string
    })
  ).isRequired,
  setFieldValue: PropTypes.func.isRequired
};

export default FormFields;
