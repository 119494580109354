import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

export default function adapter(currentOrgScore, { chartConfig }) {
  const groupFields = get(chartConfig, 'options.groupFields.groups', []);
  const groupFielLabels = get(chartConfig, 'options.groupFields.labels', []);
  const valueProp = get(chartConfig, 'options.groupFields.valueProp', 'score');
  const chartData = [];
  if (!isEmpty(currentOrgScore)) {
    Object.keys(groupFields).forEach(() => { chartData.push({}); });
    const total = get(currentOrgScore.all_demographics, valueProp, 0);
    groupFields.forEach((subgroup, index) => {
      subgroup.forEach((value) => {
        chartData[index][value] = currentOrgScore[value];
        const val = get(currentOrgScore[value], valueProp, 0);
        chartData[index].percent = (chartData[index].percent ? chartData[index].percent : 0) + ((isNaN(val) ? 0 : val)  / total) * 100;
        chartData[index].legendValue = `${parseFloat(chartData[index].percent).toFixed(1)}%`;
        chartData[index].label = groupFielLabels[index];
      });
    });
    return chartData;
  }
  return chartData;
}
