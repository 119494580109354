/* eslint-disable  no-eval */
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import filter from 'lodash/filter';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';

import template from '../../helpers/template';
import when from '../../helpers/when';

// Captions are present on all crumbs except those that have headings.
// Headings are labels for grouped options (items) within the dropdown
// Refer to http://cui.bbinternal.net/latest/js/components/breadcrumbs.html#docutron-example-67
const validCrumb = crumb => crumb.caption || crumb.heading;

const formatedCrumbWithItems = (crumb, props) => {
  const filteredItems = filter(crumb.items, (item) => {
    if (!item.when) { return true; }
    return when(item.when, props);
  });
  const formatedItems = filteredItems.map(item =>
    ({ caption: item.caption, href: `${props.baseName}${template((item.href), props)}` })
  );
  return isEmpty(formatedItems) ? {} : { heading: crumb.heading, items: formatedItems };
};

const templateCrumb = (crumb, props) => {
  if (crumb.when && !when(crumb.when, props)) {
    return {};
  }
  let caption = template(crumb.caption || '', props);
  if (crumb.capitalize) caption = capitalize(caption);
  if (crumb.titleCase) caption = startCase(caption);
  if (crumb.captionPrefix) caption = `${crumb.captionPrefix}${caption}`;

  const crumbConfig = { caption, href: '#' };
  if (crumb.linkRoute) {
    const linkRoute = `${template((crumb.linkRoute), props)}`;
    return {
      ...crumbConfig,
      // `href` added here for accessibility, required for tab-focusing/navigation
      // FIXME: href can be removed when Cui.Breadcrumbs allows rendering `<ReactRouterDom.Link />`
      href: linkRoute,
      onClick: (e) => {
        e.preventDefault();
        props.history.push(linkRoute);
      }
    };
  }  else if (crumb.href) {
    const href = `${props.baseName}${template((crumb.href), props)}`;
    return { ...crumbConfig, href };
  }  else if (crumb.items) {
    // items are only present when dropdown has headings.
    return formatedCrumbWithItems(crumb, props);
  } else {
    return crumbConfig;
  }
};

const customCrumbs = (crumbs, props) => crumbs.reduce((acc, c) => {
  if (Array.isArray(c)) {
    acc.push(customCrumbs(c, props));
  } else {
    const crumb = templateCrumb(c, props);
    if (validCrumb(crumb)) {
      acc.push(crumb);
    }
  }
  return acc;
}, []);

const defaultProps = {
  crumbs: [],
  baseName: '',
  caption: '',
  capitalize: false,
  initiallySelectedItem: 0
};

const findNestedIndex = (crumbs, props) => {
  const { dataPointSlug } = props;
  const dropdownItems = Array.isArray(crumbs[crumbs.length - 1]) ? crumbs[crumbs.length - 1] : [];
  const filteredDropdownItems = reduce(dropdownItems, (acc, top) => {
    acc.push({
      ...top,
      items: filter(top.items, item => when(item.when, props))
    });
    return acc;
  }, []);
  let childIndex = -1;
  const parentIndex = filteredDropdownItems.findIndex((item) => {
    if (item.href && item.href.includes(dataPointSlug)) {
      return true;
    } else if (item.items) {
      childIndex = item.items.findIndex(subItem => subItem.href && subItem.href.includes(dataPointSlug));
      if (childIndex !== -1) {
        return true;
      }
    }
    return false;
  });

  if (parentIndex === -1) {
    return 0;
  } else if (childIndex === -1) {
    return parentIndex;
  } else {
    return [parentIndex, childIndex];
  }
};

export default function propMapper(props) {
  const mergedProps = { ...defaultProps, ...props };
  return {
    crumbs: customCrumbs(props.crumbs, mergedProps),
    initiallySelectedItem: findNestedIndex(props.crumbs, props)
  };
}
/* eslint-enable  no-eval */
