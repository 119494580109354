import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import FlexBar from 'cui/lib/components/FlexBar';
import Progress from 'cui/lib/components/Progress';
import Popover from 'cui/lib/components/Popover';
import Legend from 'cui/lib/components/Legend';
import Heading from 'cui/lib/components/Heading';

export const ProgressBarChart = (props) => {
  const { scores } = props;
  const title = get(props, 'node.title');
  const labelMap = get(props, 'node.scoring_options.labelMap') || {};

  const getDataForChart = (details) => {
    const chartData = details.map((detail, index) => Object.assign({},
      { percent: Math.round(detail.percent), label: startCase(detail.name), colorIndex: (details.length - 1 - index) }));
    return chartData;
  };

  const getLegendData = (details) => {
    const legendData = details.map((detail, index) => Object.assign({},
      {
        label: `${Math.round(detail.percent)}% ${startCase(detail.name)}, ${detail.student_count.toLocaleString()} students`,
        colorIndex: (details.length - 1 - index)
      }));
    return legendData;
  };

  return (
    <div className="cui-text_small cui-margin-bottom-xLarge">
      <FlexBar align="top">
        {title && (
          <Heading level={3} className="cui-margin-bottom-large theme-global-neutral-fg-7" size="small" caps>{title}</Heading>
        )}
      </FlexBar>
      {scores.map(singleScore =>
        (<FlexBar key={singleScore.subject} className="progressChart-wrapper cui-margin-xSmall">
          <FlexBar.Item className="progressChart-title cui-font-bold cui-text_muted">
            {labelMap[singleScore.subject] || startCase(singleScore.subject)}
          </FlexBar.Item>
          <FlexBar.Item grow>
            <Progress
              data={getDataForChart(singleScore.details)}
              theme="imm"
              size="small"
            />
          </FlexBar.Item>
          <FlexBar.Item>
            <Popover appendToBody={false}>
              <Popover.Toggle><span className="cui-btn cui-font-bold">Details</span></Popover.Toggle>
              <Popover.Content>
                <Legend theme="imm" kind="stacked" data={getLegendData(singleScore.details)} />
              </Popover.Content>
            </Popover>
          </FlexBar.Item>
        </FlexBar>))
      }
    </div>
  );
};

ProgressBarChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  scores: PropTypes.array,
  progressProps: PropTypes.shape({
    theme: PropTypes.string
  })
};

ProgressBarChart.defaultProps = {
  progressProps: {},
  scores: []
};

export default ProgressBarChart;
