import React from 'react';
import PropTypes from 'prop-types';
import InfoPopover from 'cui/lib/components/InfoPopover';
import Dropdown from 'cui/lib/components/Dropdown';
import Icon from 'cui/lib/components/Icon';

import styles from '../../ui/PanelHeader/PanelHeader.module.scss';

const Link = ({ name, title, url }) => (
  <a
    href={url}
    rel="noreferrer"
    target="_blank"
    title={title}
  >
    {name}
  </a>
);

const DownloadLink = ({ downloadItems, name, className }) => {
  const infoPopover = <InfoPopover
    className={styles.info}
    content="Dataset will include data for all organizations."
    label="Dataset will include data for all organizations."
  />;

  if (downloadItems?.length === 1) {
    const downloadItem = downloadItems[0];

    if (downloadItem.type === 'LINK') {
      return (
        <div className={className}>
          <Link
            name={downloadItem.name}
            title={downloadItem.name}
            url={downloadItem.url}
          />
        </div>);
    }

    return (
      <div className={className}>
        {infoPopover}
        &nbsp;
        <Link
          name={name}
          title="Dataset will include data for all organizations."
          url={downloadItem.url}
        />
      </div>
    );
  }

  if (downloadItems?.length > 1) {
    const selectableItems = downloadItems.map(item => ({
      href: item.url,
      text: item.name
    }));

    return (
      <div className={className}>
        {infoPopover}
        &nbsp;
        <Dropdown
          appendToBody={false}
          items={selectableItems}
          trigger="click"
        >
          <button className="cui-btn">
            {name}
            <Icon name="bb-down-carat" size="small" className="cui-dropdown-selectableTrigger-caret" />
          </button>
        </Dropdown>
      </div>
    );
  }

  return null;
};

DownloadLink.defaultProps = {
  name: 'Download'
};

DownloadLink.propTypes = {
  downloadItems: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  ),
  name: PropTypes.string
};

export default DownloadLink;
