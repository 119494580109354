import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import { currentOrgYearScores } from '../scoresHelpers';

const dataPointHasScores = (options) => {
  const { scores, entityScores, directScore, useEntityScores, node, organization, entities = [] } = options;
  if (!isEmpty(entityScores) && useEntityScores) {
    const entityScoresMap = reduce(entities, (acc, entity) =>  {
      const score = currentOrgYearScores(entityScores, entity, node);
      if (score) {
        acc.push(score);
      }
      return acc;
    }, []);
    return !!entityScoresMap.length;
  }

  const hasDirectScore = directScore && !isEmpty(directScore);

  const getScoreValue = () => {
    const score = currentOrgYearScores(scores, organization, node);
    return !!(score && score.value);
  };

  return !!(hasDirectScore ? directScore : getScoreValue());
};

export default dataPointHasScores;
