import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading, Icon } from 'cui';
import OrgSearch from '../OrgSearch/index';
import Link from '../Link';

import styles from './index.module.scss';

const orgSearchPropMap = {
  organizations: 'module.organizations'
};

function Intro(props) {
  return (
    <section className={styles.Intro} aria-labelledby="introduction_section_aria_label">
      <span id="introduction_section_aria_label" hidden>Introduction</span>
      <img
        className={styles.logo}
        src="//bb-production-assets.s3.amazonaws.com/kde/kde-logo-medium-8.png"
        alt="Kentucky Department of Education Logo"
      />
      <div className={cx('container', styles.group)}>
        <div className={styles.intro} role="main">
          <Heading level={1} size="xxxLarge" className={styles.heading1}>Welcome to Kentucky&rsquo;s School Report Card</Heading>
          <p className={styles.heading2}>
            The Kentucky School Report Card has students at its center&#8212;ensuring
            that students are well-rounded and prepared for life after graduation.
          </p>
        </div>
        <OrgSearch
          className={styles.search}
          linkRoute="/organization/{{ organization.id }}"
          propMap={orgSearchPropMap}
        />
        <Link className={styles.exploreLink} to={`/organization/${props.stateOrgId}`}>
          Explore Statewide Education Data
          <Icon name="bb-caret-right" />
        </Link>
      </div>
    </section>
  );
}

Intro.propTypes = {
  stateOrgId: PropTypes.number
};

export default Intro;
