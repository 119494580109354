import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import DataDropdown from './DataDropdown';
import DataMultiDropdown from './DataMultiDropdown';

const DataPointMenuAction = (props) => {
  const isMultiSelect = get(props.config, 'multi');

  return isMultiSelect
    ? <DataMultiDropdown {...props} />
    : <DataDropdown {...props} />;
};

DataPointMenuAction.propTypes = {
  config: PropTypes.shape({
    multi: PropTypes.bool
  })
};

export default DataPointMenuAction;
