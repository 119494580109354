import React from 'react';
import PropTypes from 'prop-types';

Second.propTypes = {
  children: PropTypes.node
};

function Second({ children }) {
  return (
    <div className="orgPanel-second">
      { children }
    </div>
  );
}

Second.defaultProps = {
  'data-slotName': 'Second'
};

export default Second;
