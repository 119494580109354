import filter from 'lodash/filter';
import get from 'lodash/get';

export function scoreByKey(score, filterKey) {
  return filterKey ? get(score, filterKey) : score;
}

export const filterZeroValues = scores => filter(scores, score => score.value !== 0);

export default { filterZeroValues };
