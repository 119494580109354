const RATING_REDUCTION_MSG = 'Star rating lowered due to achievement gap(s)';

// @param gap_reduction {string}, one of ['Y', 'N']
// @return message to display only when gap_reduction is Y
export default function starRatingMessage(gapReduction) {
  if (gapReduction === 'Y') {
    return RATING_REDUCTION_MSG;
  } else {
    return undefined;
  }
}
