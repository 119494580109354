import React from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import { scoreInfoPropType } from '../../helpers/collectorHelpers';

const getPropsFromInfo = (scoreInfo) => {
  if (!scoreInfo) {
    return null;
  } else if (scoreInfo.value) {
    return { scoreInfo };
  } else if (scoreInfo.required) {
    return { scoreInfo, notReported: <p><strong>Data Not Reported</strong></p> };
  } else {
    return null;
  }
};

/**
 * In the SPR, there are two kinds of empty values:
 * - Those which are not required for the given organization: these are not rendered in the report at all;
 * - Those which are required for the given organization but were not provided in the collector: the section is rendered and the value is displayed as
 *   "Data Not Reported".
 * This component accepts one or multiple score info objects and a rendering function. The rendering function will be called if some of the provided
 * scores have a value to render. If the section should be rendered, the child rendering function will be called and passed the scores. Scores which
 * do not have values but are required will be hydrated with a `notReported` property to render in place of its value.
 */
const EmptyWrapper = ({ scoreInfo, scoreInfos, children }) => {
  if (scoreInfos) {
    const props = mapValues(scoreInfos, getPropsFromInfo);
    if (some(props)) {
      // If at least some of the given scores have a value (or DNR), execute the renderer with the scores so the section will display.
      return children(props);
    } else {
      // None of the given scores have values and they are not required, so omit the entire section.
      return null;
    }
  } else {
    const props = getPropsFromInfo(scoreInfo);
    if (props) {
      return children(props);
    } else {
      return null;
    }
  }
};

EmptyWrapper.propTypes = {
  scoreInfo: scoreInfoPropType,
  scoreInfos: PropTypes.objectOf(scoreInfoPropType),
  children: PropTypes.func.isRequired
};

export default EmptyWrapper;
