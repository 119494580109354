import { useQuery, useMutation, useQueryClient } from 'react-query';

import { ENDPOINTS } from 'store/backend';
import request from 'helpers/request';
import useTokenQuery from 'store/backend/queries/token';

const requestParams = token => ({
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'omit'
});

const getDistrictMessage = (organizationId, year) => {
  const query = `query DistrictMessage($organizationId: ID!, $year: Int!) {
    district_message(organization_id: $organizationId, fiscal_year: $year) {
      organizationId: clarity_organization_id 
      year: fiscal_year 
      body
      author
      title
    }
  }`;

  const variables = { organizationId, year };

  return request(ENDPOINTS.svcpd, {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    credentials: 'omit'
  });
};

const updateDistrictMessage = (variables, token) => {
  const mutation = `mutation UpdateDistrictMessage(
     $organizationId: ID!
     $year: Int!
     $body: String
     $author: String
     $title: String
   ) {
    update_district_message(
      organization_id: $organizationId
      fiscal_year: $year
      body: $body
      author: $author
      title: $title
    ) {
      organizationId: clarity_organization_id 
      year: fiscal_year
      body
      author
      title
    }
  }`;

  return request(ENDPOINTS.svcpd, {
    method: 'POST',
    body: JSON.stringify({ query: mutation, variables }),
    ...requestParams(token)
  });
};

const deleteDistrictMessage = (variables, token) => {
  const mutation = `mutation DeleteDistrictMessage($organizationId: ID!, $year: Int!) {
    delete_district_message(organization_id: $organizationId, fiscal_year: $year) {
      organizationId: clarity_organization_id 
      year: fiscal_year
    }
  }`;

  return request(ENDPOINTS.svcpd, {
    method: 'POST',
    body: JSON.stringify({ query: mutation, variables }),
    ...requestParams(token)
  });
};

const queryKey = (orgId, year) => ['districtMessage', orgId.toString(), Number(year)];
const queryFn = async ctx => {
  const [_key, orgId, year] = ctx.queryKey;

  const {
    data: { district_message: districtMessage }
  } = await getDistrictMessage(orgId, year);

  return districtMessage;
};

const useDistrictMessageQuery = (orgId, year) =>
  useQuery({
    queryKey: queryKey(orgId, year),
    queryFn
  });

export default useDistrictMessageQuery;

export const useUpdateDistrictMessageMutation = () => {
  const { data: token } = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(variables => updateDistrictMessage(variables, token), {
    onSuccess(response) {
      const {
        data: { update_district_message: districtMessage }
      } = response;

      queryClient.setQueryData(
        queryKey(districtMessage.organizationId, districtMessage.year),
        districtMessage
      );
    }
  });
};

export const useDeleteDistrictMessageMutation = () => {
  const { data: token } = useTokenQuery();
  const queryClient = useQueryClient();

  return useMutation(variables => deleteDistrictMessage(variables, token), {
    onSuccess(response) {
      const {
        data: { delete_district_message: districtMessage }
      } = response;

      if (!districtMessage) return;

      queryClient.setQueryData(
        queryKey(districtMessage.organizationId, districtMessage.year),
        null
      );
    }
  });
};
