import React from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';

import CuiLegend from 'cui/lib/components/Legend';
import ScoreDot from 'cui/lib/components/ScoreDot';
import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';

import ChartOptionHelpers from '../../helpers/chartOptionHelpers';
import chartDataAdapters from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';

export const GroupedBarChart = (props) => {
  const { node, config, chartType, directScore } = props;
  const options = config.options || {};

  if (isEmpty(directScore)) {
    return null;
  }

  const dataOptions = {
    ...props,
    currentOrgScore: directScore
  };
  const chartData = chartDataAdapters(chartType, dataOptions);
  const renderLegendItem = (theme, item, i) => (
    <li key={i}>
      <ScoreDot theme={theme} value={item.colorIndex} className="cui-list-icon" />
      <span className="cui-list-text">{item.label} <strong>{item.description}</strong></span>
    </li>
  );

  const USAGES = ['Low Usage', 'Medium Usage', 'High Usage'];
  const { min, max } = dataMaxHelper[chartType](dataOptions, 0, options.high);
  let chartOptions = options && merge(options, {
    low: min,
    barWidthRatio: 0.8,
    high: max,
    xAxis: {
      showGrid: true,
      showLabel: true,
      showAxis: true
    },
    yAxis: {
      offset: 40,
      labelCount: 6,
      showLabel: true,
      showGrid: true,
      showAxis: true,
      labelDirection: 'btt'
    },
    valueLabel: true,
    detailsPopoverTitle: 'Group Details',
    /* eslint-disable react/prop-types */
    detailsPopoverRenderer({ data, theme }) {
      /* eslint-enable react/prop-types */
      const items = data.value.map((d, i) => ({
        colorIndex: d.colorIndex,
        label: `${USAGES[i]}:`,
        description: `${d.value} students`
      }));

      return (
        <div style={{ padding: '1rem' }}>
          <CuiLegend
            data={items}
            theme={theme}
            kind="stacked"
            renderLegendItem={renderLegendItem}
          />
        </div>
      );
    }
  });

  chartOptions = ChartOptionHelpers.gridFilter(chartOptions);

  return (
    <BarChartWithLegend
      chartProps={{
        data: chartData,
        chartOptions,
        theme: node.theme
      }}
    />
  );
};

GroupedBarChart.propTypes = {
  node: PropTypes.object,
  config: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  organization: PropTypes.object,
  directScore: PropTypes.object,
  chartType: PropTypes.string
};

GroupedBarChart.defaultProps = {
  node: {},
  config: {},
  scores: [],
  organization: {},
  directScore: {},
  chartType: ''
};

export default GroupedBarChart;
