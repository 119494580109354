import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import template from '../../../helpers/template';

export const IconTitleFormatter = ({ image, templateString, value, alt }) => {
  const compiledValue = template(templateString, { value });
  return (
    <div className="cui-media cui-media_center cui-media_nowrap cui-media_tight">
      <div className="cui-media-image">
        {
          !isEmpty(image) && <img alt={alt} src={image} style={{ height: '35px', width: '35px' }} />
        }
      </div>
      <div className="cui-media-content">
        <strong>{compiledValue}</strong>
      </div>
    </div>
  );
};

IconTitleFormatter.propTypes = {
  image: PropTypes.string,
  templateString: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  alt: PropTypes.string
};

IconTitleFormatter.defaultProps = {
  image: '',
  templateString: '{{value}}',
  value: 0,
  alt: 'image'
};

export default IconTitleFormatter;
