export default ({ organizationId, year, body }) => ({
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  stateObjectMap: {
    narrative: 'update_narrative'
  },
  query: `
    mutation ($organizationId: ID!, $year: Int!, $approved: Boolean!, $body: String!) {
      update_narrative(organization_id: $organizationId, fiscal_year: $year, approved: $approved, body: $body) {
        id
        clarity_organization_id
        body
      }
    }
  `,
  variables: {
    organizationId,
    year,
    body,
    approved: true
  }
});
