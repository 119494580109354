import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import OrgPicker from '../OrgPicker/index';

const OrgPickerLinkContainer = ({ org, allOrgs }) => (
  <div>
    {org.entity_type === 'State' &&
      <OrgPicker
        openText="View all districts"
        title="Choose a District"
        orgs={allOrgs.filter(o => o.entity_type === 'District')}
      />
    }
    {org.entity_type === 'District' &&
      <div className="flexRow">
        <OrgPicker
          openText="View all districts"
          title="Choose a District"
          orgs={allOrgs.filter(o => o.entity_type === 'District')}
        />
        <OrgPicker
          openText="View all schools in this district"
          title="Choose a School"
          orgs={get(org, 'children', [])}
        />
      </div>
    }
    {org.entity_type === 'School' &&
      <OrgPicker
        openText="View all schools in this district"
        title="Choose a School"
        orgs={get(org, 'parentDistrict.children', [])}
      />
    }
  </div>
);

OrgPickerLinkContainer.defaultProps = {
  org: {},
  allOrgs: []
};

OrgPickerLinkContainer.propTypes = {
  org: PropTypes.object,
  allOrgs: PropTypes.arrayOf(PropTypes.object)
};

export default OrgPickerLinkContainer;
