import get from 'lodash/get';
import currentOrgScores from '../../helpers/scoresHelpers';
import template from '../../helpers/template';
import applyAdapters from '../../helpers/adapterHelpers';

const KdeRangeSliderProvider = (props) => {
  if (!props.scores.length) return null;

  const {
    config: {
      max = 150,
      min = -150,
      titles = {},
      value: valueTemplate = '{{ value }}'
    },
    organization,
    scores,
    additionalChartAdapters
  } = {
    config: {},
    ...props
  };

  let score = get(currentOrgScores(scores, organization), 'value.dimensions');

  // Use adapter if defined in the framework node
  const adapterName = get(props.config, 'adapter');
  if (adapterName) {
    score = applyAdapters(adapterName, score, {
      chartConfig: props.config,
      additionalChartAdapters
    });
  }

  const keys = Object.keys(score);

  const data = keys.map((key) => {
    const value = score[key];

    return {
      key,
      max,
      min,
      // INFO: Only display the title if there's more than one chart
      title: keys.length > 1 && titles[key],
      value: parseFloat(template(valueTemplate, value))
    };
  }).filter(row => isFinite(row.value));

  return props.children(data);
};

export default KdeRangeSliderProvider;
