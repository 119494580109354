/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';

import { Divider, Heading, Donut } from 'cui';
import LoadingSpinner from 'cui/lib/components/LoadingSpinner';
import Classification from '../Classification';
import Gaps from '../Gaps';
import HeaderBlock from '../HeaderBlock';
import ScoresBlock from '../ScoresBlock';
import Score from '../Score';
import StarRating from '../StarRating';
import ChartWithLegend from '../../../components/ChartWithLegend';
import ReportLegend from '../../../components/ReportLegend';
import Footer from '../Footer';
import starRatingMessage from '../../../helpers/schoolStatHelpers';

import styles from './Page1.module.scss';

const DONUT_TEXT_FOR_HS_SCHOOL = '*Percentages listed are for schools with a standard configuration of grades 9-12 and rounded to the nearest hundredth.';
const DEFAULT_DONUT_TEXT = '*Percentages listed are for schools with a standard configuration of grades 3-5 and 6-8 and rounded to the nearest hundredth.';
const RATING_DONUT = {
  high_school: {
    donutData: [
      { percent: 46.88 },
      { percent: 15.62 },
      { percent: 6.25 },
      { percent: 31.25 }
    ],
    legendData: [
      {
        label: '46.88%',
        description: 'Proficiency'
      },
      {
        label: '15.62%',
        description: 'Separate Academic'
      },
      {
        label: '6.25%',
        description: 'Graduation Rate'
      },
      {
        label: '31.25%',
        description: 'Transition Readiness'
      }
    ]
  },
  other: {
    donutData: [
      { percent: 36.46 },
      { percent: 27.08 },
      { percent: 36.46 }
    ],
    legendData: [
      {
        label: '36.46%',
        description: 'Proficiency'
      },
      {
        label: '27.08%',
        description: 'Separate Academic'
      },
      {
        label: '36.46%',
        description: 'Growth'
      }
    ]
  }
};

function ratingDonutProps(schoolLevel) {
  const key = schoolLevel === 'high_school' ? schoolLevel : 'other';
  return RATING_DONUT[key];
}

const Page1 = ({
  schoolType,
  year,
  schoolName,
  address,
  phone,
  stars,
  gapReduction,
  classification,
  classificationDescription,
  classificationData,
  gapData,
  dataPoints,
  introCopy,
  showFooter,
  isLoading
}) => {
  const { donutData, legendData } = ratingDonutProps(schoolType);

  return (
    <div className={styles.Page}>
      <div className={styles.CoverHeader}>
        <Heading level={1} weight="medium" className={styles.CoverHeader_title}>
          {year} Kentucky School Report Card Summary
        </Heading>
        <Heading level={2} weight="bold" className={styles.CoverHeader_school}>
          {schoolName}
        </Heading>
        <div className={styles.Contact}>
          <address>{address} • {phone}</address>
          <a className={styles.MoreLink} href="https://kyschoolreportcard.com">
            <em>To learn more, visit</em>
            <span>kyschoolreportcard.com</span>
          </a>
        </div>

        <div className={styles.Meta}>
          <div className={styles.Meta_info}>
            <StarRating score={stars} message={starRatingMessage(gapReduction)} level={startCase(schoolType)} />
            <div className={styles.Meta_lists}>
              <HeaderBlock title="Federal Classification">
                <Classification
                  classification={classification}
                  classificationDescription={classificationDescription}
                  classificationData={classificationData}
                />
              </HeaderBlock>
              <HeaderBlock title="Significant Achievement Gaps">
                <Gaps gapData={gapData} />
              </HeaderBlock>
            </div>
          </div>
          <div className={styles.Meta_image}>
            { schoolType === 'high_school' ?
              <img role="presentation" src="https://bb-production-assets.s3.amazonaws.com/kde/src-pdf/highschool-cover.png" />
              :
              <img role="presentation" src="https://bb-production-assets.s3.amazonaws.com/kde/src-pdf/elementary-cover.png" />
            }
          </div>
        </div>
      </div>


      {
        isLoading ?
          <LoadingSpinner request={new Promise(() => {})} /> :
          <div className={styles.Page1Grid}>
            <div className={styles.Page1Grid_main}>
              <p>{introCopy} <a href="https://kyschoolreportcard.com">kyschoolreportcard.com</a></p>
              {dataPoints.map((section, i) => (
                <div key={i}>
                  <Divider />
                  <ScoresBlock title={section.title} subtitle={section.subtitle}>
                    {section.scores.map((score, n) => (
                      <Score
                        key={n} title={score.title} score={score.score} average={score.average}
                        percent={score.percent}
                      />
                    ))}
                  </ScoresBlock>
                </div>
              ))}
            </div>
            <div className={styles.Page1Grid_sidebar}>
              <img
                role="presentation"
                src="https://bb-production-assets.s3.amazonaws.com/kde/src-pdf/page1-sidebar.png"
                className={styles.Page1Grid_sidebar_image}
              />
              <Heading level={2} className={styles.Heading_sidebar1}>How Should You Use The School
              Report Card?</Heading>
              <p>Kentucky’s accountability system places students at its heart by ensuring they are
              well rounded, transition-ready, and prepared with the knowledge and skills needed to
              be successful at the next level. Use this information as a conversation starter in
              your community on how to improve academic performance on a local level.</p>
              <Heading level={3} className={styles.Heading_sidebar2}>How is the 5-Star Accountability
              Rating Calculated?</Heading>
              <ChartWithLegend data={donutData} chartAlignment="top">
                <Donut
                  data={donutData} theme="kde-multi" data-slotName="chart" width={150}
                  radiusRatio={0.4}
                />
                <ReportLegend data={legendData} theme="kde-multi" data-slotName="legend" />
              </ChartWithLegend>

              <p>Kentucky&rsquo;s accountability system provides an overall star rating for each
              school ranging from 1 (lowest) to 5 (highest). Calculations for how many stars a
              school or district receives are made based on the weights of the indicators shown
              above.</p>
              <p>
                <em>
                  {schoolType === 'high_school' ? DONUT_TEXT_FOR_HS_SCHOOL : DEFAULT_DONUT_TEXT}
                </em>
              </p>
            </div>
          </div>
      }
      {
        showFooter && <Footer />
      }
    </div>
  );
};

Page1.propTypes = {
  showFooter: PropTypes.bool,
  isLoading: PropTypes.bool,
  introCopy: PropTypes.string,
  schoolType: PropTypes.oneOf(['high_school', 'middle_school', 'elementary_school']),
  year: PropTypes.number,
  schoolName: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  stars: PropTypes.number,
  gapReduction: PropTypes.string,
  classification: PropTypes.string,
  classificationDescription: PropTypes.string,
  classificationData: PropTypes.arrayOf(
    PropTypes.shape({
      reason: PropTypes.string
    })
  ),
  gapData: PropTypes.arrayOf(
    PropTypes.shape({
      gap: PropTypes.string.isRequired,
      ctrlGroup: PropTypes.string.isRequired
    })
  ),
  dataPoints: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string,
      scores: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          score: PropTypes.number,
          average: PropTypes.number,
          percent: PropTypes.bool
        })
      )
    })
  )
};

Page1.defaultProps = {
  schoolType: 'ElementaryMid',
  showFooter: true
};

export default Page1;
