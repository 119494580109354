export function removeNode(element) {
  return document.body && document.body.removeChild && document.body.removeChild(element);
}

export function removeScript(scriptSrc) {
  for (let i = 0; i < document.scripts.length; i += 1) {
    const currentSrc = document.scripts[i].src || '';
    // remove the script element
    if (currentSrc.includes(scriptSrc)) {
      removeNode(document.scripts[i]);
      return true;
    }
  }

  return false;
}

export function addScript(scriptSrc) {
  const scriptEl = document.createElement('script');
  scriptEl.async = true;
  scriptEl.type = 'text/javascript';
  scriptEl.src = scriptSrc;
  document.body.appendChild(scriptEl);
}
