import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'cui/lib/components/Icon';

const SchoolProfileReportLink = ({ id, year }) => (
  <div className="u-bottom-text">
    View the
    <a
      className="u-indent tinyCaps"
      href={`/school-profile-report/${id}?year=${year}`}
    >
      SCHOOL PROFILE REPORT <Icon name="bb-arrow-box-top" />
    </a>
  </div>
);

SchoolProfileReportLink.propTypes = {
  id: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired
};

SchoolProfileReportLink.defaultProps = {
  id: null
};

export default SchoolProfileReportLink;
