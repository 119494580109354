/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';

import CuiModal from 'cui/lib/components/Modal';
import CuiButton from 'cui/lib/components/Button';
import CuiFlexBar from 'cui/lib/components/FlexBar';
import CuiTable from 'cui/lib/components/Table';
import CuiIcon from 'cui/lib/components/Icon';

export class OtherDataModal extends React.Component {
  static propTypes = {
    viewOtherData: PropTypes.arrayOf(
      PropTypes.object
    )
  }

  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };

    this.show = () => this.setState({ isOpen: true });
    this.hide = () => this.setState({ isOpen: false });
    this.tableProps = this.tableProps.bind(this);
  }

  // helper to build table props
  tableProps() {
    const { viewOtherData } = this.props;
    return {
      data: viewOtherData,
      renderRow: ({ type, percent, student_count: studentCount }, i) => (
        <tr key={i} className={i % 2 !== 0 ? 'theme-bg-4' : ''}>
          <td>{type}</td>
          <td><strong>{percent}%</strong>, {studentCount} Students</td>
        </tr>
      )
    };
  }

  render() {
    return (
      <div>
        <button className="cui-btn" onClick={this.show}>
          <CuiIcon name="fin-modal2" className="cui-btn-icon" />
          View details for all other major incident types
        </button>
        <CuiModal isOpen={this.state.isOpen} onHide={this.hide}>
          <CuiModal.Header title="Other Major Incidents" />
          <CuiModal.Body className="theme-global-neutral">
            <CuiTable {...this.tableProps()}>
              <CuiTable.ColumnHeader className="theme-bg-4">
                MAJOR INCIDENT TYPE
              </CuiTable.ColumnHeader>
              <CuiTable.ColumnHeader className="theme-bg-4">
                STUDENTS WITH AT LEAST ONE INCIDENT TYPE
              </CuiTable.ColumnHeader>
            </CuiTable>
          </CuiModal.Body>
          <CuiModal.Footer>
            <CuiFlexBar justify="right">
              <CuiFlexBar.Item>
                <CuiButton title="Close" kind="solid" theme="primary" onClick={this.hide}>
                  Close
                </CuiButton>
              </CuiFlexBar.Item>
            </CuiFlexBar>
          </CuiModal.Footer>
        </CuiModal>
      </div>
    );
  }
}

export default OtherDataModal;
