import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import { format } from 'date-fns';

import PercentBarChart from '../PercentBarChart';

const CALENDAR_YEAR_MONTHS = [
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
  'january',
  'february',
  'march',
  'april',
  'may',
  'june'
];

function DateBarChart({ node, startMonth, chartType, ...props }) {
  // default startMonth is current month.
  const monthToStart = startMonth || format(new Date(), 'MMMM').toLowerCase();
  // show all months up to and including current month in the northern hemisphere school academic year
  const monthsToShow = CALENDAR_YEAR_MONTHS.slice(0, 1 + CALENDAR_YEAR_MONTHS.indexOf(monthToStart));
  const dataPointNode = { ...node };
  dataPointNode.scoringOptions = get(dataPointNode, 'scoring_options', {});
  dataPointNode.scoringOptions.shape = monthsToShow.reduce((months, m) => {
    months[m] = { label: capitalize(m), colorIndex: '0' };
    return months;
  }, {});

  return <PercentBarChart node={dataPointNode} {...props} chartType={chartType} />;
}

DateBarChart.propTypes = {
  node: PropTypes.object,
  startMonth: PropTypes.string,
  chartType: PropTypes.string
};

export default DateBarChart;
