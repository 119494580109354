import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { withRouter } from 'react-router';
import get from 'lodash/get';

import { getUserContext } from '../helpers/backend_context';
import allDataPoints from '../helpers/allDataPoints';
import LOAD_CURRENT_ORG from '../queries/load_current_org';
import ProfileReport from '../components/ProfileReport';
import buildLoadFrameworkAction from '../actions/framework';
import {
  getDimensionsForOrg,
  getOrgIdFromOrganization
} from '../helpers/portalDataServiceHelpers';

const ProfileReportPage = () => (
  <ProfileReport
    awaitProps={['currentOrganization', 'scores', 'framework']}
    propMap={{
      currentOrganization: 'module.currentOrganization',
      scores: 'module.scoresFromPortalDataService',
      framework: 'framework.kde',
      schoolYear: 'module.schoolYear'
    }}
  />
);

const scoreLoadFromPortalDataService = {
  type: 'PORTAL_DATA_LOAD',
  query: (state) => {
    const framework = get(state, 'framework.kde');
    const dataQueries = [];
    const currentOrganization = get(state, 'module.currentOrganization');
    const organizationIds = [getOrgIdFromOrganization(currentOrganization)];
    const featureFlags = get(state, 'module.pageContext.featureFlags');
    const schoolYear = get(state, 'module.schoolYear');

    const dataPoints = allDataPoints(framework, { profileReport: true });

    const params = {
      organization_ids: organizationIds,
      data_points: dataPoints.map(node => ({
        slug: get(node, 'portal_data.slug', node.slug),
        key: node.node_path,
        dimensions: getDimensionsForOrg(node, currentOrganization)
      })),
      featureFlags,
      year: schoolYear
    };
    dataQueries.push(params);

    return dataQueries;
  },
  stateKey: 'scoresFromPortalDataService',
  loadingIndicator: () => 'portalDataScores'
};

const isPublicSite = isEmpty(getUserContext());

// Note: is there a reliable way to tell whether the script is running in the public site or secure site ?
// Possibly we can use environment variables
export const config = {
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        {
          type: 'FETCH_TOKEN',
          service: 'svcpd',
          slug: 'kde',
          actions: [
            {
              type: 'GRAPH_LOAD',
              service: 'svcpd',
              isPublic: isPublicSite,
              query: LOAD_CURRENT_ORG,
              stateObjectMap: {
                currentOrganization: isPublicSite ?  'data.organization' : 'organization'
              },
              propMap: {
                id: 'params.id',
                year: 'params.year',
                currentOrganization: 'module.currentOrganization'
              },
              actions: [
                scoreLoadFromPortalDataService
              ]
            }
          ]
        }
      ]
    })
  ],
  actionParams: ['id']
};

export default withRouter(ProfileReportPage);
