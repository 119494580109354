import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Button from 'cui/lib/components/Button';

import OtherField from './OtherField';
import { validationMeta } from '../../helpers/collectorHelpers';

class DynamicList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      inputs: isEmpty(props.values) ? [''] : props.values
    };

    this.previousInputs = null;
  }

  componentWillUpdate(nextProps, nextState) {
    const inputs = nextState.inputs
      .map(val => val.trim())
      .filter(val => val.length > 0);

    if (!isEqual(inputs, this.previousInputs)) {
      this.previousInputs = inputs;
      this.props.listChangeHandler(
        this.props.nodePath,
        this.props.sectionSlug,
        inputs
      );
    }
  }

  handleDelete = i => () => {
    const inputs = [
      ...this.state.inputs.slice(0, i),
      ...this.state.inputs.slice(i + 1)
    ];
    this.setState({ inputs });
  }

  handleText = i => (newVal) => {
    const inputs = [...this.state.inputs];
    inputs[i] = newVal;
    this.setState({ inputs });
  }

  addInput = () => {
    const inputs = [...this.state.inputs, ''];
    this.setState({ inputs });
  }

  render() {
    const validations = validationMeta(this.props.validations);

    return (
      <div>
        {
          this.state.inputs.map((input, index) => (
            <OtherField
              key={index}
              fieldId={`field:${this.props.nodePath}:${this.props.sectionSlug}:${input}`}
              value={input}
              onChange={this.handleText(index)}
              onDelete={this.handleDelete(index)}
              validationProps={validations.props}
              validationErrors={validations.errors}
            />
          ))
        }
        <Button
          type="button"
          kind="stroked"
          theme="secondary"
          onClick={this.addInput}
        >
          Add New Input
        </Button>
      </div>
    );
  }
}

DynamicList.propTypes = {
  validations: PropTypes.object,
  nodePath: PropTypes.string,
  sectionSlug: PropTypes.string,
  listChangeHandler: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.string
  )
};

DynamicList.defaultProps = {
  validations: {},
  values: []
};

export default DynamicList;
