import get from 'lodash/get';
import map from 'lodash/map';

const adapter = (options) => {
  const { node, currentOrgScore } = options;

  return currentOrgScore && map(currentOrgScore.value, (value, key) => (
    {
      label: get(node, `scoring_options.shape.${key}.label`, key),
      key,
      value: map(value, (scoreValue, scoreKey) => (
        {
          value: scoreValue,
          colorIndex: get(node, `scoring_options.valueColorIndex.${scoreKey}`, 1)
        }
      ))
    }
  ));
};

export default adapter;
