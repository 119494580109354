import React from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import Spacer from 'cui/lib/components/Spacer';

import { isPublicOrWithClarityUserPermissions } from '../../helpers/nodeHelpers';
import CollectionDataPointField from '../CollectionDataPointField';
import CollectionDataPointError from '../CollectionDataPointError';

const CollectionVariableSection = ({
  domain,
  si,
  currentOrganization,
  variable,
  scores,
  previousYearScores,
  errors,
  submissionValueGenerators,
  userRole
}) => {
  const editableDataPoints = filter(variable.items, { editable: true });
  const editableDataPointsWithClarityUserPermissions = editableDataPoints
    .filter(node => isPublicOrWithClarityUserPermissions(node, userRole));

  if (isEmpty(editableDataPointsWithClarityUserPermissions)) return null;

  return (
    <div>
      <h4>{domain.name} / {si.name} / {variable.name}</h4>
      {
        map(editableDataPointsWithClarityUserPermissions, dp => (
          <div key={dp.node_path}>
            <CollectionDataPointField
              dp={dp}
              currentOrganization={currentOrganization}
              submissionValueGenerators={submissionValueGenerators}
              scores={scores}
              previousYearScores={previousYearScores}
            />
            <CollectionDataPointError
              isDevelopment={process.env.NODE_ENV === 'development'}
              dp={dp}
              errors={errors}
            />
            <Spacer size="medium" />
          </div>
        ))
      }
    </div>
  );
};

CollectionVariableSection.propTypes = {
  domain: PropTypes.object,
  si: PropTypes.object,
  variable: PropTypes.object,
  currentOrganization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  submissionValueGenerators: PropTypes.objectOf(PropTypes.func),
  userRole: PropTypes.string
};

export default CollectionVariableSection;
