import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import get from 'lodash/get';

import Button from 'cui/lib/components/Button';
import ScoreDot from 'cui/lib/components/ScoreDot';

import FlagModal from './components/FlagModal';
import { canUserEdit } from '../../helpers/auth';

import styles from './index.module.scss';

const THEME_INDEXES = {
  approved: 0,
  flagged: 1,
  unreviewed: 2
};

export class ApprovalStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
    this.show = () => this.setState({ isOpen: true });
    this.hide = () => this.setState({ isOpen: false });
  }

  render() {
    const { auth, status, canEdit, nodePath, organizationId, onSave, isLoading, orgName, fiscalYear } = this.props;
    const handleSave = ({ ...args }) => onSave({ nodePath, organizationId, orgName, fiscalYear, ...args });

    if (!auth) return <div />;

    const isFlagged = status === 'flagged';

    const StatusComponent = canEdit && !isFlagged
      ? ({ children }) => (
        <Button
          title="View/change status"
          kind="stroked"
          theme="primary"
          size="small"
          onClick={this.show}
        >
          {children}
        </Button>
      )
      : 'div';

    return (
      <div>
        {!isLoading && (
          <StatusComponent>
            <ScoreDot theme="kdeApproval" value={THEME_INDEXES[status]} />
            <span className={styles.statusText}>{status || 'Unreviewed'}</span>
          </StatusComponent>
        )}
        {this.state.isOpen && (
          <FlagModal
            canEdit={canEdit}
            nodePath={nodePath}
            status={status}
            organizationId={organizationId}
            onSave={handleSave}
            hideModal={this.hide}
          />
        )}
      </div>
    );
  }
}

ApprovalStatus.propTypes = {
  status: PropTypes.string,
  auth: PropTypes.object,
  canEdit: PropTypes.bool,
  isLoading: PropTypes.bool,
  nodePath: PropTypes.string,
  organizationId: PropTypes.number,
  orgName: PropTypes.string,
  onSave: PropTypes.func.isRequired,
  fiscalYear: PropTypes.number
};

ApprovalStatus.defaultProps = {
  status: 'unreviewed'
};

export const mapStateToProps = (state) => {
  const params = get(state, 'params', {});
  const organizationId = parseInt(get(params, 'id'));
  const auth = get(state, 'module.userContext');
  const canEdit = canUserEdit(state, organizationId);
  const isLoading = get(state, 'loading.isLoading');

  return {
    organizationId,
    canEdit,
    auth,
    isLoading,
    orgName: get(state, 'module.currentOrganization.name')
  };
};

/* eslint-disable max-len */
const sectionStatusUpdate = (nodePath, orgId, status, comments, orgName, fiscalYear) => ({
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  stateObjectMap: {
    sectionStatuses: {
      dataKey: 'update_section_status',
      method: 'union',
      idKey: 'id'
    }
  },
  query: `
  mutation {
    update_section_status(
      organization_id: ${orgId}
      fiscal_year: ${fiscalYear}
      section_slug: "${nodePath}"
      status: "${status}"
      flag_comments: "${comments}"
    ) {
      id
      clarity_organization_id
      fiscal_year
      section_slug
      status
      flag_comments
      updated_by
      updated_at
    }
  }
  `
});
/* eslint-enable max-len */

export const mapDispatchToProps = dispatch => ({
  onSave: ({ nodePath, organizationId, orgName, status, flag_comments: flagComments, fiscalYear }) => {
    dispatch(sectionStatusUpdate(nodePath, organizationId, status, flagComments, orgName, fiscalYear));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalStatus);
