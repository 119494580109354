import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Toggle from './Toggle';

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openIndex: -1
    };

    this.toggleItem = this.toggleItem.bind(this);
  }

  toggleItem(itemIndex) {
    this.setState(prevState => ({
      openIndex: prevState.openIndex === itemIndex ? null : itemIndex
    }));
  }

  render() {
    const { items } = this.props;
    const { openIndex } = this.state;

    return (
      <div className="cui-accordionForm">
        <section className="cui-accordionForm-section">
          {
            items.map(({ title, content }, index) => (
              <div
                key={title}
                className={cx('cui-accordionForm-step', { 'is-open': openIndex === index })}
              >
                <Toggle
                  title={title}
                  label={`${openIndex === index ? 'expanded' : 'collapsed'} question ${title}`}
                  itemIndex={index}
                  onClick={this.toggleItem}
                  isOpen={openIndex === index}
                />
                <div className="cui-accordionForm-step-content">
                  {content}
                </div>
              </div>
            ))
          }
        </section>
      </div>
    );
  }
}

Accordion.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired
  }))
};

export default Accordion;
