import each from 'lodash/each';

import MarkdownIt from 'markdown-it';

const md = new MarkdownIt({
  breaks: true,
  html: true,
  linkify: true
})
  // Allows for specifing image sizes
  //   ![alt text](my-image.png =100x50)
  //   <img src="my-image.png" alt="alt text" width="100" height="50" />
  .use(require('markdown-it-imsize'))

  // Add custom className
  // Import `browser` version for ES5 compatability (i.e. Internet Explorer <= 11)
  // See: https://github.com/arve0/markdown-it-attrs/issues/43
  .use(require('markdown-it-attrs/markdown-it-attrs.browser.js'));

const defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
  return self.renderToken(tokens, idx, options);
};

const linkAttrs = {
  target: '_blank',
  rel: 'noopener noreferrer'
};

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  each(Object.keys(linkAttrs), (attr) => {
    const attrIndex = tokens[idx].attrIndex(attr);

    if (attrIndex < 0) {
      tokens[idx].attrPush([attr, linkAttrs[attr]]);
    } else {
      tokens[idx].attrs[attrIndex][1] = linkAttrs[attr];
    }
  });

  return defaultRender(tokens, idx, options, env, self);
};

const renderMarkdownContent = (bodyText, options = {}) => {
  let content;

  if (options.inline) {
    content = md.renderInline(bodyText);
  } else {
    content = md.render(bodyText);
  }

  return {
    __html: content
  };
};

export default renderMarkdownContent;
