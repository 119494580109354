import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cx from 'classnames';
import List from 'cui/lib/components/List';
import Modal from 'cui/lib/components/Modal';
import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';

import Link from '../Link';
import styles from './index.module.scss';

class OrgPicker extends React.PureComponent {
  state = { isOpen: false };

  showModal = () => this.setState({ isOpen: true });

  hideModal = () => this.setState({ isOpen: false });

  render() {
    const { openText, title, orgs } = this.props;

    return (
      <div>
        <Button onClick={this.showModal} className={cx('tinyCaps', 'cui-text_left', styles.OrgPickerButton)}>
          <Icon name="bb-school" className="icon" />{openText}
        </Button>
        <Modal isOpen={this.state.isOpen} onHide={this.hideModal}>
          <Modal.Header title={title} />

          <Modal.Body>
            <List>
              {orgs.sort((a, b) => a.name.localeCompare(b.name)).map(org => (
                <List.Item key={org.id}>
                  <Link onClick={this.hideModal} to={`/organization/${org.id}`}>{org.name}</Link>
                </List.Item>
              ))}
            </List>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

OrgPicker.defaultProps = {
  openText: '',
  title: '',
  orgs: []
};

OrgPicker.propTypes = {
  currentOrganization: PropTypes.object,
  openText: PropTypes.string,
  title: PropTypes.string,
  orgs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default withRouter(OrgPicker);
