import { isPreviewSite } from './previewSite';

const isApprovalSite = () => window.location.pathname.startsWith('/modules/kde');

/* eslint-disable import/prefer-default-export, no-undef */
export function kdeCurrentSchoolYear() {
  if (typeof KDE_PREVIEW_SCHOOL_YEAR !== 'undefined' && isPreviewSite()) {
    return parseInt(KDE_PREVIEW_SCHOOL_YEAR);
  }

  if (typeof KDE_APPROVAL_SCHOOL_YEAR !== 'undefined' && isApprovalSite()) {
    return parseInt(KDE_APPROVAL_SCHOOL_YEAR);
  }

  if (typeof KDE_CURRENT_SCHOOL_YEAR !== 'undefined') {
    return parseInt(KDE_CURRENT_SCHOOL_YEAR);
  }

  return undefined;
}
