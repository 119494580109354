import React from 'react';
import { get, has } from 'lodash';
import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import template from '../../helpers/template';

import { sortByExactOrders } from '../sortBy';
import { getParentData, getDataForOrganization } from '../../helpers/portalDataServiceHelpers';
import FILTERS_OPTIONS from '../../helpers/data_dropdown_helper/filtersOptions';

const DEFAULT_ORDER = [
  'school',
  'district',
  'state'
];

const EMPTY_DATA_PLACEHOLDER = 'No Data Reported';

const byStudentGroup = (chartDataByOrgType, scoreKey, filters = []) => {
  const studentGroups = flatten([filters[0]]);

  return studentGroups.map((studentGroup) => {
    let path = [];
    if (has(filters, '1')) {
      path = [scoreKey, 'dimensions', studentGroup, 'dimensions', filters[1][0]];
    } else {
      path = [scoreKey, 'dimensions', studentGroup];
    }
    const dimensionDataByOrgType = chartDataByOrgType.map(organizationData =>
      ({
        ...get(organizationData, path, organizationData[scoreKey]),
        key: organizationData.key
      })
    );

    return dimensionDataByOrgType.map(dimension => ({
      ...get(dimension, ['score']),
      key: dimension.key,
      filters: [studentGroup]
    }));
  });
};

const byStudentGroupAndPriorSetting = (chartDataByOrgType, scoreKey, filters) => {
  const studentGroups = flatten([filters[1]]);
  const priorSetting = filters[0];

  return studentGroups.map((studentGroup) => {
    const dimensionDataByOrgType = chartDataByOrgType.map(organizationData =>
      ({
        ...get(organizationData, [scoreKey, 'dimensions', studentGroup], organizationData[scoreKey]),
        key: organizationData.key
      })
    );

    return dimensionDataByOrgType.map(dimensionData => ({
      ...get(dimensionData, ['score', priorSetting], dimensionData.score),
      key: dimensionData.key,
      filters: [priorSetting, studentGroup]
    }));
  });
};

const FILTER_TYPES = {
  byStudentGroup,
  byStudentGroupAndPriorSetting
};

const filterByDropdownSelection = (chartData, filters, options) => {
  const scoreKey = get(options, 'node.slug');
  const { filterType } = get(options, 'chartConfig.options.compareChart', {});

  const chartDataByOrgType = Object.entries(chartData).map(([key, value]) => ({ key: String(key).toLowerCase(), ...value }));

  return FILTER_TYPES[filterType](chartDataByOrgType, scoreKey, filters);
};

const getChartData = (options) => {
  const {
    currentOrganization,
    additionalOrganizations = [],
    node,
    allScores
  } = options;

  const isCompare = additionalOrganizations.length > 0;

  if (isCompare) {
    return [currentOrganization, ...additionalOrganizations].reduce((acc, org) => {
      acc[org.name] = getDataForOrganization(allScores, node, org);
      return acc;
    }, {});
  } else {
    return {
      ...getParentData(allScores, currentOrganization, node),
      [currentOrganization.entity_type]: getDataForOrganization(allScores, node, currentOrganization)
    };
  }
};

export const getTopLabel = (value, denominator) => {
  if (value === undefined) return EMPTY_DATA_PLACEHOLDER;
  if (denominator === '*' || value === '*') return '*';
  return '';
};

export const getPopoverItem = (value, denominator, popoverLegendLabel, popoverLegendValue) => {
  const topLabel = getTopLabel(value, denominator);
  if (topLabel) return <span className="legend-label">{topLabel}</span>;
  if (denominator === '0') return <span className="legend-label">0 students</span>;

  return (
    <div>
      <span className="legend-value">
        {popoverLegendValue
          ? template(popoverLegendValue, { value, denominator })
          : `${value}%`}
      </span>
      {popoverLegendLabel && (
        <span className="legend-label">
          <em className="u-indent">
            {template(popoverLegendLabel, { value, denominator })}
          </em>
        </span>
      )}
    </div>
  );
};

const adapter = (currentOrgScore, options) => {
  const chartData = getChartData(options);
  const filters = options.viewingDropdownFilter;
  const {
    valueKey,
    denominatorKey,
    chartLabel,
    popoverLegendLabel,
    popoverLegendValue
  } = get(options, 'chartConfig.options.compareChart', {});

  const scoreDataByOrgType = filterByDropdownSelection(chartData, filters, options);

  return scoreDataByOrgType.map((scores) => {
    const sortedData = sortByExactOrders(scores, DEFAULT_ORDER, 'key');
    const data = sortedData.map((scoreData, index) => ({
      colorIndex: index,
      label: startCase(scoreData.key),
      topLabel: getTopLabel(scoreData[valueKey], scoreData[denominatorKey]),
      popoverItem: getPopoverItem(scoreData[valueKey], scoreData[denominatorKey], popoverLegendLabel, popoverLegendValue),
      value: isFinite(scoreData[valueKey]) ? Math.max(Number(scoreData[valueKey]), 0.5) : 0.5,
      denominator: scoreData[denominatorKey]
    }));

    return { label: template(chartLabel, { FILTERS_OPTIONS, filters: scores[0].filters }), value: data };
  });
};

export default adapter;
