import flow from 'lodash/flow';
import partialRight from 'lodash/partialRight';

import {
  selectScoreValue,
  subsetScoreValue,
  selectNestedGroup,
  objToArray,
  sortByProperty,
  calculatePercentages,
  calculateDemographicPercentages,
  groupUnderThreshold,
  mapKeyToLabel,
  mapKeyToColorIndex,
  convertToBarChart
} from '../../helpers/chartAdapterUtils';

/**
 * This adapter flow converts the score object into a format that the Cui.BarChart can load
 * Where ...values == { students_with_events, events }
 *   selectScoreValue      { demo_keys: { category_keys: { ..values } } } -> { category_keys: { ..values } }
 *   objToArray            { category_keys: { ..values } } -> [{ __key: category_key, ..values }]
 *   calculatePercentages  [{ __key: category_key, ..values }] -> [{ __key: category_key, percentage, ..values }]
 *   sortByProperty        [{ __key: category_key, percentage, ..values }] -> [{ __key: category_key, percentage, ..values }]
 *   mapKeyToLabel         [{ __key: category_key, percentage, ..values }] -> [{ label: category_label, percentage, ..values }]
 *   convertToBarChart     [{ label: category_label, percentage, ..values }] -> [{ label: category_label, value: percentage }]
 *   groupUnderThreshold   [{ label: category_label, value: percentage }] -> [{ label: category_label, value: percentage }]
 *
 * FIXME:
 * NOTES: The thought behind this adapter and making it follow a functional programming format is to eventually allow adapter chains
 *   ex. in the chart configs
 *     adapter:
 *       - name: selectScoreValue
 *         options:
 *           scoreKey: students_with_events
 *       - name: subsetScoreValue
 *         options:
 *           disaggregationKeys: ...
 *       - name: objToArray
 *       - name: calculatePercentages
 *         options:
 *           totalKey: total
 *           numeratorKey: students_with_events
 *       - name: sortByProperty
 *         options:
 *           sortKey: percentage
 *       ...
 */
const kdeBarChartAdapter = (score, options) => {
  const adapterFlow = flow(
    partialRight(selectScoreValue, options),
    partialRight(subsetScoreValue, options),
    partialRight(selectNestedGroup, options),
    partialRight(objToArray, options),
    partialRight(calculatePercentages, options),
    partialRight(calculateDemographicPercentages, options),
    partialRight(sortByProperty, options),
    partialRight(mapKeyToLabel, options),
    partialRight(mapKeyToColorIndex, options),
    partialRight(convertToBarChart, options),
    partialRight(groupUnderThreshold, options)
  );

  return adapterFlow(score);
};

export default kdeBarChartAdapter;
