import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Icon, Heading } from 'cui';
import InfoPopover from 'cui/lib/components/InfoPopover';

import Link from '../../components/Link';
import Markdown from '../../components/Markdown';
import styles from './PanelHeader.module.scss';

const renderInfoPopover = (text, label, className, popoverPlacement = 'right') => {
  const infoText = (
    <span data-test="info-icon-text" >
      <Markdown body={text} />
    </span>
  );
  const popoverProps = {
    popoverPlacement,
    appendToBody: false
  };

  return <InfoPopover content={infoText} popoverProps={popoverProps} label={label} className={className} />;
};

const PanelHeader = ({ title, linkTo, linkLabel, popoverContent, popoverPlacement, headingLevel, headingClass, headingSize, label }) => (
  <div className={styles.header}>
    <Heading level={headingLevel} size={headingSize} className={cx(headingClass, styles.title)}>
      {title}
    </Heading>
    { popoverContent && renderInfoPopover(popoverContent, label, styles.info, popoverPlacement)}
    { linkTo &&
      <Link to={linkTo} className={styles.link}>
        {linkLabel} <Icon name="bb-caret-right" />
      </Link>
    }
  </div>
);

PanelHeader.propTypes = {
  title: PropTypes.string.isRequired,
  linkTo: PropTypes.string,
  linkLabel: PropTypes.string,
  popoverContent: PropTypes.string,
  headingLevel: PropTypes.number,
  label: PropTypes.string,
  popoverPlacement: PropTypes.string,
  headingClass: PropTypes.string,
  headingSize: PropTypes.string
};

PanelHeader.defaultProps = {
  linkLabel: 'Explore Data',
  headingLevel: 3,
  headingSize: 'base'
};

export default PanelHeader;
