// This is fork of the table chart in the common folder
// TODO add storybook documentation
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import snakeize from 'snakeize';

import SideScroll from 'cui/lib/components/SideScroll';
import Table from 'cui/lib/components/Table';
import Icon from 'cui/lib/components/Icon';

import chartDataAdapter from '../../helpers/chartDataAdapters';
import when from '../../helpers/when';
import { getValueFromScores } from '../../helpers/scoresHelpers';
import template from '../../helpers/template';
import ChartEmptyState from '../../components/Chart/ChartEmptyState';
import { KENTUCKY_ORG_ID } from '../../constants';
import applyAdapters from '../../helpers/adapterHelpers';
import { isStringOrObjectWithTruthyWhen } from '../../helpers/chartAdapterUtils';
import TextCell from './TextCell';
import LabelCell from './LabelCell';
import DetailsLink from './DetailsLink';
import styles from './index.module.scss';

const a11yAttrs = {
  tabIndex: '0'
};

function tableHeaders({ options }, columns = []) {
  let headers = options.headers;
  if (!headers) {
    headers = columns.map(column => column.header);
  } else if (options.hideEmptyColumns) {
    headers = headers.filter(header => columns.some(column => column.header === header));
  }

  return headers;
}

/* eslint-disable complexity */
export const TableChartLegend = (props) => {
  const { config: chartConfig, scores, organization, node, additionalChartAdapters, viewingDropdownFilter, schoolYear } = props;
  const { options = {}, alwaysRender = false, useStateData = false  } = chartConfig;
  const { hideNullRows = false, hideZeroRows = false, defaultScoreKey = '', defaultScore = {}, hideEmptyColumns = false } = options;
  const whenProperties = {
    viewingDropdownFilter: get(viewingDropdownFilter, '0')
  };

  let rows = options.rows;
  let columns = options.columns || [];
  columns = columns.filter(column => !column.when || isStringOrObjectWithTruthyWhen(column, whenProperties));

  if (isEmpty(scores) && !alwaysRender) return null;

  // Not using `directScore`/currentOrgScore here
  const valueForOrg = getValueFromScores(scores, organization.id, node);

  let currentScore = defaultScoreKey ? valueForOrg[defaultScoreKey] : valueForOrg;


  if (useStateData) {
    const stateScore = getValueFromScores(scores, KENTUCKY_ORG_ID, node);
    currentScore = stateScore;
  }

  // Skip empty check if node has `alwaysRender`
  if (isEmpty(currentScore) && !alwaysRender) {
    /* eslint-disable no-console */
    console.error(`No Score Found for organization ${organization.id}`);
    /* eslint-enable no-console */
    return (<ChartEmptyState />);
  }

  if (chartConfig.adapter) {
    let scoreFromAdapter;

    if (Array.isArray(chartConfig.adapter)) {
      // Use new adapter api
      scoreFromAdapter = applyAdapters(chartConfig.adapter, currentScore, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    } else {
      scoreFromAdapter = chartDataAdapter(chartConfig.adapter, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    }

    if (isEmpty(scoreFromAdapter)) {
      return (<ChartEmptyState />);
    }

    if (scoreFromAdapter.additionalRows && scoreFromAdapter.score) {
      currentScore = scoreFromAdapter.score;
      // Handle rows from adapter
      // usually because those rows are dynamically computed
      rows = rows ? rows.concat(scoreFromAdapter.additionalRows) : scoreFromAdapter.additionalRows;
    } else {
      currentScore = scoreFromAdapter;
    }
  }

  // Get keys of all scores to be loaded in column configs
  const scoreKeys = uniq(compact(map(columns, 'scoreKey')));
  let rowData = compact(map(currentScore, (value, key) => {
    const commonProps = {
      key,
      entityType: organization.entityType || organization.entity_type
    };
    // add order index so that we can sort later by ordering specified in rows
    if (rows) {
      commonProps.orderIndex = rows.indexOf(key);
    }
    // https://app.clubhouse.io/brightbytes/story/31140/when-showing-data-points-by-level-only-show-relevant-level-for-org
    // This allows for only showing scores we have data for, and removing rows that don't have data
    // Simpler than writing logic to handling configurable show/hiding based on a organization's level (i.e. elementary, middle, high schools)
    if (hideNullRows && isEmpty(value)) {
      return null;
    }

    if (isObject(value)) {
      let outValue = value;
      // Handle `defaultScore` directive
      // This also speeds up rendering time by preventing the <TextCell/> templates from catching
      //   and logging `ReferenceErrors` for properties that don't exist.
      if (!isEmpty(defaultScore)) {
        // Pre-fill individual data objects with default values and keys
        outValue = reduce(scoreKeys, (acc, scoreKey) => {
          acc[scoreKey] = { ...defaultScore, ...get(value, scoreKey, {}) };
          return acc;
        }, value);
      }

      // Unpack score values into the row data
      return { ...outValue, ...commonProps };
    }
    return { value, ...commonProps };
  }));

  const getCellValue = (type, data, { ...colOptions }) => {
    let cellData = data;
    if (colOptions.scoreKey) {
      cellData = { ...data[colOptions.scoreKey], ...{ rowKey: data.key } };
    }

    return template(colOptions.text, cellData, false);
  };

  if (rows) {
    const rowsToShow = new Set(rows);
    // Remove those that are not in the rows config
    rowData = rowData.filter(row => rowsToShow.has(row.key));
    rowData.sort((a, b) => a.orderIndex - b.orderIndex);

    if (hideNullRows || hideZeroRows) {
      rowData = rowData.filter(row => !!map(columns, ({ type, ...colOptions }) => getCellValue(type, row, { ...colOptions }))
        .filter((value) => {
          if (
            hideNullRows &&
            (value === undefined || value === null || (typeof value === 'string' && !value.length))
          ) { return false; }

          if (hideZeroRows && (value === 0 || value === '0')) { return false; }

          return true;
        }).length);
    }
  }

  if (hideEmptyColumns) {
    columns = columns.filter(({ type, ...colOptions }) => {
      return type !== 'text' || rowData.some(row => {
        return !isEmpty(getCellValue(type, row, { ...colOptions }));
      });
    });
  }

  const sortRows = get(chartConfig, 'options.sortRows');
  if (sortRows) {
    rowData = orderBy(rowData, [sortRows.key], [sortRows.order]);
  }
  // Columns config takes `type` which configures how the cell is displayed
  const COLUMN_TYPE_MAP = {
    label: LabelCell,
    text: TextCell
  };

  const cellModifier = get(chartConfig, 'options.cellModifier');
  const renderCell = (type, data, { when: columnCondition = 'true', ...colOptions }) => {
    const Cell = get(COLUMN_TYPE_MAP, type);
    let cellData = data;

    // With complete cell data, the scoreKey determines what sub-value object to display
    // i.e. if data is nested like:
    //   data := {
    //     foo: { value: 10 },
    //     bar: { value: 20 },
    //     baz: { value: 30 }
    //   }
    //   colOptions.scoreKey := 'bar'
    //   cellData := { value: 20 }
    if (colOptions.scoreKey) {
      cellData = data[colOptions.scoreKey];
    }

    if (colOptions.snakecaseKeys) {
      cellData = snakeize(cellData);
    }

    const cellMod = colOptions.cellModifier;

    const percentRow = get(chartConfig, 'options.percentRow.destRow');
    if (percentRow && data.key === percentRow) {
      colOptions.alternateTextProp = get(chartConfig, 'options.percentRow.alternateTextKey');
    }

    const textAlign = type === 'text' ? get(chartConfig, 'textAlign', null) : null;
    const noWrap = type === 'text' ? get(chartConfig, 'noWrap', null) : null;
    const cellClasses = {
      [styles.lastSlantedDataColumn]: colOptions.isLastCol && chartConfig.slanted,
      [styles.lastDataColumn]: !chartConfig.slanted && colOptions.numOfColumns === 2 && colOptions.colIndex > 0,
      [styles.dataColumn]: colOptions.colIndex !== 0,
      [styles.noWrap]: noWrap
    };

    return when(columnCondition, {
      ...cellData,
      ...whenProperties
    }) ? (<Cell item={{ ...cellData, rowKey: data.key }} cellModifier={cellMod ? cellMod : cellModifier} textAlign={textAlign} {...colOptions} className={cx(cellClasses)} />) : '';
  };

  const renderRow = (row, rowIndex) => (
    <tr key={rowIndex}>
      {map(columns, ({ type, ...colOptions }, colIndex) => {
        if (colIndex === 0) {
          return (
            <th key={colIndex} className={cx(styles.rowHeader, { [styles.rowHeader_blue]: chartConfig.useBlueHeader })}>
              {renderCell(type, row, { ...colOptions, rowIndex, colIndex })}
            </th>
          );
        }

        return (
          <td key={colIndex}>
            {
              renderCell(
                type,
                row,
                {
                  ...colOptions,
                  rowIndex,
                  colIndex,
                  isLastCol: colIndex === columns.length - 1,
                  numOfColumns: columns.length
                }
              )
            }
          </td>
        );
      })}
    </tr>
  );

  const renderColumnHeader = (contentClassName, type, headerClassName, value, colIndex, numOfColumns) => {
    const content = contentClassName ? <div className={contentClassName}>{value}</div> : value;
    const dataColumnClass = chartConfig.slanted ? styles.slantedHeaderColumn : styles.dataColumn;

    const columnClass = {
      [dataColumnClass]: colIndex !== 0,
      [styles.lastDataColumn]: !chartConfig.slanted && numOfColumns === 2 && colIndex > 0
    };

    return (
      <Table.ColumnHeader
        className={headerClassName}
        key={value}
        property={value}
      >
        <div className={cx(columnClass)}>{content}</div>
      </Table.ColumnHeader>
    );
  };

  const className = cx('cui-margin-bottom-none', styles.table, { [styles.slantedTable]: chartConfig.slanted });
  const headers = tableHeaders(chartConfig, columns)
    .filter(header => isStringOrObjectWithTruthyWhen(header, whenProperties));

  return (
    <SideScroll>
      <div className={styles.tableContainer}>
        {
          chartConfig.chartMessage && (
            <p className={styles.chartMessage}>
              {chartConfig.chartMessage}
            </p>
          )
        }
        <Table
          data={rowData}
          renderRow={renderRow}
          className={className}
          description={get(node, 'name')}
          tbodyAttrs={a11yAttrs}
        >
          {
            headers
              .map((header, index) => renderColumnHeader(
                chartConfig.slanted ? styles['header-content'] : '',
                columns[index].type,
                styles.header,
                typeof header === 'object' ? header.value : header,
                index,
                headers.length
              ))
          }
        </Table>
        {
          get(chartConfig, 'externalLink.url') &&
            <DetailsLink
              to={chartConfig.externalLink.url}
              organizationId={organization.id}
              year={schoolYear}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.externalLink}
            >
              { chartConfig.externalLink.text } <Icon name="bb-arrow-box-top" />
            </DetailsLink>
        }
      </div>
    </SideScroll>
  );
};

TableChartLegend.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    entity_type: PropTypes.string,
    entityType: PropTypes.string
  }),

  // Score data
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      remote_organization_id: PropTypes.number.isRequired,
      value: PropTypes.object.isRequired
    })
  ),

  // Framework Tree Node
  node: PropTypes.object,

  // Chart Config object
  config: PropTypes.shape({
    options: PropTypes.shape({
      defaultScoreKey: PropTypes.string, // key of score object to select by default
      headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['label', 'text']).isRequired,
          when: PropTypes.string,
          labels: PropTypes.objectOf(PropTypes.string),
          tags: PropTypes.arrayOf(PropTypes.object),
          text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
          ]),
          icon: PropTypes.string,
          theme: PropTypes.string,
          scoreKey: PropTypes.string
        })
      ).isRequired
    })
  }),

  // Optionally extend the list of adapters this chart knows about
  additionalChartAdapters: PropTypes.object,
  viewingDropdownFilter: PropTypes.object,
  schoolYear: PropTypes.number
};

TableChartLegend.defaultProps = {
  organization: {
    id: null
  },
  scores: [],
  config: {},
  viewingDropdownFilter: {}
};

export default TableChartLegend;
/* eslint-enable complexity */
