/* eslint-disable no-useless-return */
/* eslint-disable no-param-reassign */
import { sortBy, includes } from 'lodash';
import { filterZeroValues } from '../chartHelpers';

export const transformData = (data, scoringOptions) => {
  if (!data || !scoringOptions) {
    return data;
  }

  const {
    shape,
    order,
    hideZeroValues,
    sortDescending
  } = scoringOptions;

  if (shape) {
    data = sortBy(data.filter(datum => includes(Object.keys(shape), datum.key)), ['index']);
  }

  if (order === 'reverse') {
    data.reverse();
  }

  if (hideZeroValues) {
    data = filterZeroValues(data);
  }

  if (sortDescending) {
    data.sort((a, b) => b.value - a.value);
  }

  return data;
};
export default { transformData };
