import React from 'react';
import PropTypes from 'prop-types';

import ScoreDot from 'cui/lib/components/ScoreDot';

import template from '../../../helpers/template';

const perfDesc = ['N/A', 'Lowest Performance', 'Low Performance', 'Medium Performance', 'High Performance', 'Highest Performance'];

export const ColorDotFormatter = (props) => {
  const { value, templateString, theme = 'sbcssDashboard', percentScore, performance } = props;
  const { colorIndex } = percentScore;
  const description = perfDesc[performance];
  const compiledValue = template(templateString, { value, ...props, description });
  return (
    <span>
      <ScoreDot value={colorIndex} theme={theme} /> {compiledValue}
    </span>
  );
};

ColorDotFormatter.propTypes = {
  cellText: PropTypes.string,
  value: PropTypes.string,
  templateString: PropTypes.string,
  colorIndex: PropTypes.number,
  theme: PropTypes.string,
  performance: PropTypes.number,
  percentScore: PropTypes.object
};

ColorDotFormatter.defaultProps = {
  cellText: '',
  value: 'L',
  templateString: '{{value}}'
};

export default ColorDotFormatter;
