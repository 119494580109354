import React from 'react';

import styles from './index.module.scss';

export default function Footer() {
  return (
    <div className={styles.Footer}>
      <div className={styles.Footer_image}>
        <img alt="Kentucky Department of Education Logo" src="https://bb-production-assets.s3.amazonaws.com/kde/kde-logo.svg" />
      </div>
      <p className={styles.Footer_description}>
        Access the Kentucky Department of Education School Report Card Suite at <a href="https://raisethebar.education.ky.gov">raisethebar.education.ky.gov</a>, where you can browse school data and compare the progress of all Kentucky schools.
      </p>
    </div>
  );
}
