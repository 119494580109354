import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import Heading from 'cui/lib/components/Heading';
import Divider from 'cui/lib/components/Divider';
import currentOrgYearScores from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import Chart from './Chart';

export default function MultiValueListChart(props) {
  const { config, scores, node, organization } = props;
  const score = currentOrgYearScores(scores, organization, node);

  if (isEmpty(score)) {
    return null;
  }

  const sections = chartDataAdapter(config.adapter, { ...props, score });

  return (
    <section>
      <Heading size="medium" level={3} className="cui-margin-top-large">{config.title}</Heading>
      <Divider className="cui-divider_medium cui-margin-bottom-none" />
      {
        sections.map(section => (
          <Chart {...section} key={section.title} />
        ))
      }
    </section>
  );
}

MultiValueListChart.propTypes = {
  config: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  node: PropTypes.object,
  organization: PropTypes.object
};

MultiValueListChart.defaultProps = {
  config: {}
};
