import 'isomorphic-fetch';
import isEmpty from 'lodash/isEmpty';

import { getPageContext } from './backend_context';

const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  const error = new Error(response.statusText);
  error.response = response;
  throw error;
};

const parseJSON = response => response.json();

const request = (url, fetchOptions = {}) => {
  let parseData;
  if (fetchOptions.parseData) {
    parseData = fetchOptions.parseData;
    delete fetchOptions.parseData;
  } else {
    parseData = data => data;
  }

  const options = Object.assign({ credentials: 'include' }, fetchOptions);

  /* eslint-disable no-undef */
  if (!isEmpty(options.body) && isEmpty(options.headers)) {
    options.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-CSRF-Token': getPageContext().csrfToken
    };
  }
  /* eslint-enable no-undef */

  return fetch(url, options)
    .then(checkStatus)
    .then(parseJSON)
    .then(parseData)
    .catch(err => ({ err }));
};

export const requestBlob = url => fetch(url)
  .then((response) => {
    if (response.status === 200 || response.status === 0) {
      return Promise.resolve(response.blob());
    }
    return Promise.reject(new Error(response.statusText));
  });

export default request;
