import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import ChartEmptyState from '../Chart/ChartEmptyState';
import emptyStateMessage from '../../helpers/emptyState';
import { chartScoreSelectorConfig } from '../../selectors/accountabilitySelector';
import ScoreBlock from '../../ui/ScoreBlock';
import ScoreBar from '../../ui/ScoreBar';
import ScaleBar from '../../ui/ScaleBar';
import { getYearSpecificCopy } from '../../helpers/frameworkTreeTraversalHelper';

function AcctScoreChart(props) {
  const {
    config,
    acctScores,
    framework,
    node,
    organization
  } = props;

  const domainScore = acctScores.find(s => s.chartScoreKey === config.scoreKey);
  const { noHoverTip = false, noLinkToDataPoint = false } = get(config, 'options', {});
  const isDataEmpty = !domainScore || !domainScore.scores.some(score => get(score, 'primary.score'));

  if (isDataEmpty) {
    return <ChartEmptyState emptyMessage={emptyStateMessage(node, organization)} />;
  }

  const { scores, title, chartScoreKey } = domainScore;
  const popoverContent = getYearSpecificCopy(framework, `acctPopoverContent.${chartScoreKey}`);

  return (
    <div aria-hidden="true">
      <ScoreBlock title={title} infoContent={popoverContent} label={`More information ${title}`}>
        {
          scores.map(({ primary, secondary, cuts }) => (
            <div key={primary.title}>
              <ScoreBlock.Primary title={primary.title}>
                <ScoreBar
                  score={primary.score}
                  rangeLow={cuts.rangeLow}
                  rangeHigh={cuts.rangeHigh}
                  scoreMax={cuts.max}
                  scoreBucket={primary.label}
                  scoreBucketInt={primary.colorIndex}
                  linkTo={primary.outboundLink}
                  noHoverTip={noHoverTip}
                  noLinkToDataPoint={noLinkToDataPoint}
                />
              </ScoreBlock.Primary>

              <ScoreBlock.Secondary>
                {
                  secondary && secondary.map(secondaryScore => (
                    <ScoreBar
                      key={secondaryScore.label}
                      score={secondaryScore.score}
                      rangeLow={cuts.rangeLow}
                      rangeHigh={cuts.rangeHigh}
                      scoreMax={cuts.max}
                      scoreBucket={secondaryScore.label}
                      scoreBucketInt={secondaryScore.colorIndex}
                      size="small"
                      linkTo={secondaryScore.outboundLink}
                      noHoverTip={noHoverTip}
                      noLinkToDataPoint={noLinkToDataPoint}
                    />
                  ))
                }
                <ScaleBar theme="kde-accountability2" data={cuts.scale} hideLowHighLabel />
              </ScoreBlock.Secondary>
            </div>
          ))
        }
      </ScoreBlock>
    </div>
  );
}

function mapStateToProps(state, props) {
  const chartScoreSelector = chartScoreSelectorConfig(get(props, 'node.node_path'));

  return {
    acctScores: chartScoreSelector(state),
    framework: get(state, 'framework.kde')
  };
}

export default connect(mapStateToProps)(AcctScoreChart);

AcctScoreChart.propTypes = {
  config: PropTypes.object,
  acctScores: PropTypes.arrayOf(PropTypes.object),
  framework: PropTypes.object,
  node: PropTypes.object,
  organization: PropTypes.object
};
