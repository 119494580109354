// The names mapping has been used all over the places in the kde.yml file
// Add the map here so that we can look up the name in our adapter or
// any place that needs the names

const NAMES = {
  out_of_school_suspension: 'Out-of-School Suspensions',
  in_school_removal: 'In-School Removal',
  frl: 'Economically Disadvantaged',
  non_frl: 'Non-Economically Disadvantaged',
  eth_black: 'African American',
  eth_alaskan: 'American Indian or Alaska Native',
  eth_asian: 'Asian',
  eth_hispanic: 'Hispanic or Latino',
  eth_pacific: 'Native Hawaiian or Pacific Islander',
  eth_multi: 'Two or More Races',
  eth_white: 'White (non-Hispanic)',
  gen_female: 'Female',
  gen_male: 'Male',
  preschool: 'Preschool',
  kindergarten: 'Kindergarten',
  grade_1: 'Grade 1',
  grade_2: 'Grade 2',
  grade_3: 'Grade 3',
  grade_4: 'Grade 4',
  grade_5: 'Grade 5',
  grade_6: 'Grade 6',
  grade_7: 'Grade 7',
  grade_8: 'Grade 8',
  grade_9: 'Grade 9',
  grade_10: 'Grade 10',
  grade_11: 'Grade 11',
  grade_12: 'Grade 12',
  grade_14: 'Grade 14'
};

export default function getName(labelMap, key, defaultValue) {
  const mapToUse = labelMap || NAMES;
  return mapToUse[key] || defaultValue;
}
