import filter from 'lodash/filter';

function currentOrgReducer(state, action) {
  const organization = action.data.data.organization;
  const districts = filter(organization.children, { entity_type: 'District' });
  return {
    ...state,
    currentOrganization: {
      ...organization,
      districts
    }
  };
}

export default currentOrgReducer;
