import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { HOME_PAGE_PATH, MAIN_CONTENT_ANCHOR } from '../../constants';
import styles from './index.module.scss';

// Scrolls to main content section without using anchor links.
// Reason is that anchor link navigation causes React to re-render route and reload all data.
function onClick() {
  const anchor = document.getElementById(MAIN_CONTENT_ANCHOR);
  if (!anchor) return;

  anchor.scrollIntoView();

  // We need to skip everything before an anchor element when navigating with keyboard.
  // I.e. the next focusable element should be after the anchor.
  // It can be achieved by focusing on anchor element.
  anchor.focus();
}

// "Skip to main content" a11y navigation button.
// See also `MainContentAnchor` component.
export const MainContentAnchorLink = ({ location }) => {
  if (location.pathname === HOME_PAGE_PATH) return null;

  return (
    <button type="button" className={cx('cui-btn kde-button', styles.button)} onClick={onClick}>
      Skip to main content
    </button>
  );
};

MainContentAnchorLink.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(MainContentAnchorLink);
