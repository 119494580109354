import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';

import {
  getDimensionsForOrg,
  calculatePercentage
} from '../../helpers/portalDataServiceHelpers';

// For data point doesn't have all_levels or all_students aggregation score
// We will display elementary_school by default for district and state
// score = {
//   dimensions: {
//     elementary_school: {
//       dimensions: {
//         gen_male: {
//           score: { tested_count: 100 }
//         },
//         gen_female: {
//           score: { tested_count: 110 }
//         },
//         eth_asian: {
//           score: { tested_count: 120 }
//         }
//       }
//     },
//     middle_school: {
//       dimensions: {
//         gen_male: {
//           score: { tested_count: 130 }
//         },
//         gen_female: {
//           score: { tested_count: 140 }
//         },
//         eth_asian: {
//           score: { tested_count: 150 }
//         }
//       }
//     },
//     high_school: {
//       dimensions: {
//         gen_male: {
//           score: { tested_count: 160 }
//         },
//         gen_female: {
//           score: { tested_count: 170 }
//         },
//         eth_asian: {
//           score: { tested_count: 180 }
//         }
//       }
//     }
//   }
// }
//
// portal_data = [
//   ['elementary_school', 'middle_school', 'high_school'],
//   ['gen_male', 'gen_female', 'eth_asian']
// ]
//
// the current viewing dimension is elementary_school
//
// result = {
//   gen_male: { tested_count: 100 },
//   gen_female: { tested_count: 110 },
//   eth_asian: { tested_count: 120 },
// }

export default function adapter({ currentOrgScore, chartConfig, node, currentOrganization, viewingDropdownFilter }) {
  // Get the default viewing dimension from the FT node config
  // Note the viewing block is in place, we should show data for the current selected dimension
  // instead of the default
  // TODO: remove the following when viewing block is in place.
  const scoreKey = get(viewingDropdownFilter, '0');

  if (scoreKey) {
    const hasNestedDimension = (getDimensionsForOrg(node, currentOrganization) || []).length > 1;
    let score;

    if (hasNestedDimension) {
      // Extra score out of the nested dimensions
      score = reduce(get(currentOrgScore, `dimensions.${scoreKey}.dimensions`), (memo, value, key) => {
        memo[key] = get(value, 'score', {});
        return memo;
      }, {});
    } else {
      score = get(currentOrgScore, `dimensions.${scoreKey}.score`);
    }

    // Wrap single values in object, filter by secondary dropdown filter
    const secondaryScoreKey = viewingDropdownFilter[1];
    score = reduce(score, (memo, value, key) => {
      memo[key] = isObject(value) ? value : { count: value };
      if (secondaryScoreKey) memo[key] = get(value, secondaryScoreKey);
      return memo;
    }, {});

    // sum a field given by includeTotalForKey and include in each array item
    const includeTotalForKey = get(chartConfig, 'includeTotalForKey');
    let commonProps;
    // Note that the value comes back as string not number
    if (includeTotalForKey) {
      commonProps = {
        total: reduce(score, (a, b) => (a + parseInt(get(b, includeTotalForKey, 0))), 0)
      };
    }

    if (commonProps) {
      return reduce(score, (memo, value, key) => {
        memo[key] = { ...commonProps, ...value };
        return memo;
      }, {});
    }

    return calculatePercentage(chartConfig, score);
  }

  return currentOrgScore;
}
