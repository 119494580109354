import React from 'react';
import { withRouter } from 'react-router';
import CuiBreadcrumbs from 'cui/lib/components/Breadcrumbs';

import propMapper from './propMapper';
import connected from '../connected';

export const Breadcrumbs = props => (
  <div className="cui-margin-bottom-medium">
    <CuiBreadcrumbs {...props} />
  </div>
);

Breadcrumbs.propTypes = CuiBreadcrumbs.propTypes;

export default withRouter(connected(Breadcrumbs, undefined, propMapper));
