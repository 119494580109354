import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';
import Donut from 'cui/lib/components/Donut';
import List from 'cui/lib/components/List';
import ScoreDot from 'cui/lib/components/ScoreDot';
import EmptyState from 'cui/lib/components/EmptyState';
import KdeChartWrapper from '../KdeChartWrapper';
import { valueToLocaleString } from '../../helpers/template/format';

export default function DemographicDonutChart(props) {
  const theme = get(props, 'config.options.theme');
  const dataCountKey = get(props, 'config.options.dataCountKey');
  const dataCountStr = valueToLocaleString(typeof (dataCountKey) === 'undefined' ? 'count' : dataCountKey);
  const count = parseInt(get(props, `currentScore.score.${dataCountStr}`, 0));

  if (!count) {
    return <EmptyState data-test="donut-chart-empty-state" kind="text" title="There is no data available." />;
  }

  return (
    <KdeChartWrapper {...props}>
      {
        ({ data }) => (
          <div className={cx('cui-media cui-media_center', props.className)}>
            <div className="cui-media-image">
              <Donut
                animate={false}
                data={data}
                width={150}
                theme={theme}
                radiusRatio={0.4}
              />
            </div>
            <div className="cui-media-content">
              <List className="legend">
                {
                  data.map((point, index) => (
                    <List.Item
                      className="legend-item"
                      icon={
                        <ScoreDot
                          className="legend-icon"
                          type="solid"
                          theme={theme}
                          value={index}
                        />
                      }
                      key={index}
                    >
                      <div>
                        <span className="legend-value">
                          {point.legendValue || `${point.percent}%`}
                        </span>
                        <span className="u-indent legend-label">
                          {point.label} <em className="u-indent">{point.description}</em>
                        </span>
                      </div>
                    </List.Item>
                  ))
                }
              </List>
            </div>
          </div>
        )
      }
    </KdeChartWrapper>
  );
}

DemographicDonutChart.propTypes = {
  className: PropTypes.string
};
