import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Well from 'cui/lib/components/Well';

import styles from './index.module.scss';

export class Narrative extends React.Component {
  render() {
    const { narrative } = this.props;

    if (!narrative || !narrative.body) {
      return null;
    }

    return (
      <div>
        <Well kind="stroked" className={[styles.narrative, 'l-dataPointPage-suppression']}>
          <strong>A message from the superintendent regarding finance</strong>
          <p>{ narrative.body }</p>
        </Well>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  narrative: get(state, 'module.narrative')
});

Narrative.propTypes = {
  narrative: PropTypes.shape({
    body: PropTypes.string.isRequired
  })
};

export default connect(mapStateToProps)(Narrative);
