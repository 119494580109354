/* eslint-disable max-len, prefer-template, react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import memoize from 'lodash/memoize';
import uniq from 'lodash/uniq';
import findLastIndex from 'lodash/findLastIndex';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import cx from 'classnames';
import Icon from 'cui/lib/components/Icon';
import CompareTable from 'cui/lib/components/CompareTable';
import EmptyState from 'cui/lib/components/EmptyState';
import InfoPopover from 'cui/lib/components/InfoPopover';

import Link from '../../components/Link';
import { filterByMetadata } from '../../helpers/nodeHelpers';
import connected from '../../components/connected';
import OrgSearch from '../../components/OrgSearch';
import MainContentAnchor from '../../components/MainContentAnchor';
import PublicOrWithPermissions from '../../components/PublicOrWithPermissions';
import CovidBanner from '../../components/CovidBanner';

import { routeInitActions, scoreLoadAction } from '../OrgReportCardPage/actions';
import { makeSelectFramework } from '../../selectors/frameworkSelector';
import {
  makeSelectCurrentOrganization,
  makeSelectScoresData,
  selectIsPreviewSite
} from '../../selectors/moduleSelector';
import { schoolStatSelector } from '../../selectors/accountabilitySelector';
import { makeSelectUserRole } from '../../selectors/userSelector';
import pageTitle from '../../helpers/pageHelpers';
import { getOrgIdFromOrganization, getOrgDataAttribute } from '../../helpers/portalDataServiceHelpers';
import { filterPublicOrWithPermissionsChart } from '../../helpers/dashboardChartHelpers';
import { findScoresForNodePaths } from '../../components/OrgReportCard';
import Chart from '../../components/OrgReportCard/OrgReportCardChart';
import Masthead from '../../components/Masthead';
import Footer from '../../components/Footer';
import StarRatingPanel from '../../components/StarRatingPanel';
import GaugeRatingPanel from '../../components/GaugeRatingPanel';
import SummaryStats from '../OrgReportCardPage/Summary';
import Classification from '../../components/OrgProfile/ClassificationDetails';
import Title1Status from '../../components/OrgProfile/Title1Status';
import {
  getSummary,
  federalClassificationInfo,
  getClassification
} from '../../helpers/OrgDataProvider';
import { ACCOUNTABILITY_DOMAIN_NODE_PATH, ORG_TYPES, DEFAULT_SUPPRESSION_COPY } from '../../constants';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import styles from '../Compare.module.scss';

const maxOrgsToCompare = 4;
const NOT_APPLICABLE = 'Not Applicable';

const orgSearchPropMap = {
  organizations: 'module.organizations'
};

// @param scores {array} array of score objects
// @return object with key to be the node_path and value to be scores of the node_path
function scoresByGroup(scores) {
  // Group scores by node_path
  return groupBy(scores, 'framework_tree_node.node_path');
}

// @param scores {array} array of score objects
// @param organizations {array} array of organizations
// @return array of scores with valid remote_organization_id
function fixRemoteOrganizationId(scores = [], organizations = []) {
  const orgIdMap = organizations.filter(org => !isEmpty(org))
    .reduce((memo, org) => {
      memo[getOrgIdFromOrganization(org)] = org.id;
      if (org.parentDistrict) {
        memo[getOrgIdFromOrganization(org.parentDistrict)] = org.parentDistrict.id;
      }
      return memo;
    }, {});

  return scores.map((score) => {
    // update remote_organization_id
    if (!score.remote_organization_id && score.portal_org_id) {
      return { ...score, remote_organization_id: orgIdMap[score.portal_org_id] };
    }

    return score;
  });
}

// @param domain {object} framework tree node for a domain
// @return return the charts configuration object for the domain
function getChartConfig(domain, { isPreviewSite, framework, userRole }) {
  const compareCharts = get(domain, 'metadata.comparePage.charts');
  const dashboardCharts = get(domain, 'metadata.dashboard.charts', []);

  let charts;

  if (!compareCharts) {
    // use charts config from dashboard page
    charts = dashboardCharts;
  } else if (get(domain, 'metadata.comparePage.mergeCharts')) {
    // Merge charts config from comparePage config if mergeCharts is turned on
    charts = dashboardCharts.map(chart => (compareCharts.find(c => c.name === chart.name) || chart));
  } else {
    // otherwise use charts config from comparePage config
    charts = compareCharts;
  }

  // filter charts that shouldn't be shown because of metadata constraints
  // (like `preview_site_only: true`). we omit currentEntity/currentSchoolLevel to avoid filtering
  // out charts not applicable to this organization type but potentially
  // applicable to others we're comparing against. (a better approach would be
  // to hide these charts but include a spacer if the chart is applicable to at
  // least one other organization we're comparing against.)
  return charts
    .filter(chart => filterByMetadata(chart, { isPreviewSite }))
    .filter(chart => filterPublicOrWithPermissionsChart(chart, userRole, framework));
}

// @param query {string}, query string portion from url which starts with question mark
// @return array of unique organization ids
function getOrgIdsFromUrl(query) {
  const { orgIds = [] } = queryString.parse(query, { arrayFormat: 'bracket' });

  return uniq(orgIds.map(Number));
}

// @param url {string} url path
// @param query {string} query portion
function updateUrl(location, newOrgIds) {
  // Update url only when pushState is defined
  // Note that we use native pushState here instead of react router
  // history.push to avoid react router unmount current component and remount
  // it again when we update only the query portion of the url
  const currentQuery = queryString.parse(location.search, { arrayFormat: 'bracket' });
  const newQuery = queryString.stringify(
    { ...currentQuery, orgIds: newOrgIds },
    { arrayFormat: 'bracket' }
  );

  history.pushState(null, null, `${location.pathname}?${newQuery}`);
}

function scoreActionsForOrgFromUrl() {
  return getOrgIdsFromUrl(location.search).map(orgId => scoreLoadAction({
    currentOrganization: { id: orgId },
    mergePortalData: true
  }));
}

class ComparePage extends PureComponent {
  constructor(props) {
    super(props);
    const orgIdsFromUrl = getOrgIdsFromUrl(get(props, 'location.search'));
    // extract orgs specified in the compare url
    this.state = {
      orgsForComparisons: orgIdsFromUrl.map(id => ({ id, orgIsLoaded: false }))
    };
  }

  componentWillReceiveProps(newProps) {
    // Update organization data when scores are loaded
    if (newProps.organizations.length > 0) {
      let shouldUpdateState = false;
      const orgsForComparisons = this.state.orgsForComparisons.map((org) => {
        // Find any org that with orgIsLoaded not true and replace it with the corresponding full org
        if (!org.orgIsLoaded) {
          const currentOrg = newProps.organizations.find(o => o.id === org.id);
          // Replace org with loaded scores data
          // Currently we don't have a way to know if scores for all node_path on current page
          // is loaded, here we use orgIsLoaded as an indicator of scores loaded because
          // the organization data is together with scores data.
          if (currentOrg && currentOrg.orgIsLoaded) {
            shouldUpdateState = true;
            return currentOrg;
          }
        }

        return org;
      });

      if (shouldUpdateState) {
        this.setState({
          orgsForComparisons
        });
      }
    }
  }

  componentWillUnmount() {
    // Clear orgIsLoaded flag so that when we reenter the page
    // We will be able to reload the scores for compared orgs
    // This is not ideal, but we haven't implemented a global cache strategy
    // Clearing the flag and reload to make sure we are able to show org data
    if (Array.isArray(this.state.orgsForComparisons)) {
      this.state.orgsForComparisons.forEach(org => (delete org.orgIsLoaded));
    }
  }

  loadScoreForOrgs(organizations) {
    this.state.orgsForComparisons.forEach((org) => {
      if (!org.orgIsLoaded) {
        const organization = organizations.find(o => o.id === org.id);
        if (organization) {
          // Reset organization orgIsLoaded flag
          delete organization.orgIsLoaded;
          this.props.loadScoreForOrg(org, this.props.schoolYear);
        }
      }
    });
  }

  addToCompareAndLoad = (selectedOrganization) => {
    const orgToCompare = this.props.organizations.find(org => org.id === selectedOrganization.id);
    if (orgToCompare) {
      // If orgToCompare hasn't been selected, add it to orgsForComparisons
      const orgsForComparisons = this.props.currentOrganization ? [this.props.currentOrganization, ...this.state.orgsForComparisons] : this.state.orgsForComparisons;
      if (!orgsForComparisons.find(org => org.id === orgToCompare.id)) {
        const currentOrgs = [
          ...this.state.orgsForComparisons,
          orgToCompare
        ];
        this.setState({ orgsForComparisons: currentOrgs });
        if (!orgToCompare.orgIsLoaded) {
          this.props.loadScoreForOrg(orgToCompare, this.props.schoolYear);
        }
        // Update compare url by adding the selected org id
        // Note: we should put this kind of side effect outside the component possibly
        // handled by the redux-saga
        updateUrl(this.props.location, currentOrgs.map(org => org.id));
      }
    }
  }

  removeFromCompare = (e, org) => {
    // Note that we can not remove current organization from comparisons because it is
    // acting as base organization to compare with other organizations.
    // remove selected organization from orgsForComparisons
    const currentOrgs = this.state.orgsForComparisons.filter(organization => organization.id !== org.id);
    this.setState({
      orgsForComparisons: currentOrgs
    });
    updateUrl(this.props.location, currentOrgs.map(o => o.id));
  }

  render() {
    const {
      featureFlags,
      framework,
      scoresData,
      currentOrganization,
      scoresFromPortalDataService,
      schoolYear,
      schoolStat,
      covidImpactBanner,
      isPreviewSite,
      userRole
    } = this.props;
    const domainsFromFramework = get(framework, 'items', []);
    const orgsForComparisons = this.state.orgsForComparisons.filter(org => org.orgIsLoaded);
    const organizations = isEmpty(currentOrganization) ? orgsForComparisons : [currentOrganization, ...orgsForComparisons];
    const allScores = fixRemoteOrganizationId(get(scoresData, 'scores', []), organizations);
    const scoresGroupByNodePath = scoresByGroup(allScores);
    const isSearchBarVisible = organizations.length < maxOrgsToCompare;
    const chartType = get(framework, 'metadata.chartType', 'star');

    return (
      <div className={styles.compareContainer}>
        <Masthead withSearchBar />
        {covidImpactBanner && <CovidBanner link={covidImpactBanner?.link} />}
        <div className="orgHeader container">
          <div className="container-constrained">
            <MainContentAnchor />
            <h2 className="cui-heading cui-text_xxLarge cui-font-bold">Compare</h2>
            <p>Compare key metrics of up to four schools or districts.</p>
          </div>
        </div>
        <div className="container">
          <div className="cui-flexbar cui-flexbar_top">
            <div className="cui-margin-xLarge cui-text_left cui-text_xSmall theme-global-neutral-fg-3">
              {DEFAULT_SUPPRESSION_COPY}
            </div>
            <div className="cui-margin-xLarge cui-text_right cui-text_xSmall theme-global-neutral-fg-3">
              Trying to print this page?
              &nbsp;
              <InfoPopover
                label="Printing tips"
                iconProps={{ className: styles.infoIcon }}
                popoverProps={{ className: 'cui-text_left' }}
                content={
                  [
                    'Comparisons are optimized for viewing in the browser. Read these ',
                    <Link to="/help">helpful printing tips</Link>,
                    ' if printing is required.'
                  ]
                }
              />
            </div>
          </div>
        </div>
        <section className="section section--padding-top-none" role="main">
          <div className="container-constrained compare-container">
            {
              isSearchBarVisible && (
                <div className="search-container">
                  <p>Add school or district to compare</p>
                  <OrgSearch
                    propMap={orgSearchPropMap}
                    onClick={this.addToCompareAndLoad}
                    autocompleteAttrs={{ placeholder: 'Add school or district', 'aria-label': 'Add a school or district to compare' }}
                  />
                </div>
              )
            }
            <CompareTable count={organizations.length} className={isSearchBarVisible ? styles.compareOrgsWithSearch : styles.compareOrgs}>
              <CompareTable.HeaderRow>
                {
                  organizations.map((org, i) => (
                    <CompareTable.Cell key={`button-${org.id}-${i}`} isHeaderCell>
                      {
                        i > 0 && (
                          <button
                            className="cui-btn"
                            onClick={e => this.removeFromCompare(e, org)}
                            aria-label="Remove column"
                          >
                            <Icon name="bb-close" />
                          </button>
                        )
                      }
                      <h2>
                        <Link to={`/organization/${org.id}`}>{org.name}</Link>
                      </h2>
                    </CompareTable.Cell>
                  ))
                }
              </CompareTable.HeaderRow>
              <CompareTable.Section>
                <CompareTable.HeaderRow>
                  <CompareTable.Cell className="cui-text_large cui-text_heavy">
                    <h3>Organization Details</h3>
                  </CompareTable.Cell>
                </CompareTable.HeaderRow>
                <PublicOrWithPermissions nodePath={ACCOUNTABILITY_DOMAIN_NODE_PATH}>
                  <CompareTable.Row>
                    {
                      organizations.map((org, index) => {
                        const federalClassification = federalClassificationInfo(org, schoolYear);
                        const isLoading = scoresData.organizationsWithScoresAwaiting.includes(org.id);

                        return (
                          <CompareTable.Cell key={`organization-details-${org.id}-${index}`}>
                            { chartType === 'gauge' ?
                              <GaugeRatingPanel
                                featureFlags={featureFlags}
                                federalClassification={federalClassification}
                                framework={framework}
                                schoolStat={schoolStat(org)}
                                organization={org}
                                year={schoolYear}
                                headingLevel={4}
                                isComaprePage="true"
                                isPreviewSite={isPreviewSite}
                                isLoading={isLoading}
                              />
                              :
                              <StarRatingPanel
                                featureFlags={featureFlags}
                                federalClassification={federalClassification}
                                framework={framework}
                                schoolStat={schoolStat(org)}
                                organization={org}
                                year={schoolYear}
                                headingLevel={4}
                                isComaprePage="true"
                              />
                            }
                          </CompareTable.Cell>
                        );
                      }
                      )
                    }
                  </CompareTable.Row>
                </PublicOrWithPermissions>
                <CompareTable.Row>
                  {
                    organizations.map((org, index) => {
                      const orgType = ORG_TYPES[get(org, 'entity_type', '').toUpperCase()];
                      const summaryData = getSummary({ scoresFromPortalDataService, currentOrganization: org, schoolYear }, orgType);

                      return (
                        <CompareTable.Cell key={`summary-${org.id}-${index}`}>
                          <SummaryStats summary={summaryData} headingLevel={4} />
                        </CompareTable.Cell>
                      );
                    })
                  }
                </CompareTable.Row>
                <CompareTable.Row>
                  {
                    organizations.map((org, index) => (
                      <CompareTable.Cell key={`classification-${org.id}-${index}`}>
                        <Classification
                          title={getClassification({ currentOrganization: org }) || NOT_APPLICABLE}
                          description="More information classifications"
                        />
                      </CompareTable.Cell>
                    ))
                  }
                </CompareTable.Row>
                <CompareTable.Row>
                  {
                    organizations.map((org, index) => (
                      <CompareTable.Cell key={`title1Status-${org.id}-${index}`}>
                        <Title1Status
                          title={getOrgDataAttribute(org, 'title1_status', schoolYear) || NOT_APPLICABLE}
                          description="More information title1 status"
                        />
                      </CompareTable.Cell>
                    ))
                  }
                </CompareTable.Row>
              </CompareTable.Section>
              {
                domainsFromFramework.map((domain) => {
                  const charts = getChartConfig(domain, { isPreviewSite, framework, userRole });
                  const domainEmptyStateMessage = get(domain, 'metadata.emptyStateMessage');

                  if (isEmpty(charts) && !domainEmptyStateMessage) return null;

                  return (
                    <CompareTable.Section key={domain.slug}>
                      <CompareTable.HeaderRow>
                        <CompareTable.Cell className="cui-text_large cui-text_heavy">
                          <h3>{domain.name}</h3>
                        </CompareTable.Cell>
                      </CompareTable.HeaderRow>
                      {
                        charts.map((chart, i) => (
                          <CompareTable.Row key={`${domain.slug}-${chart.name}-${i}`}>
                            {
                              organizations.map((org, index) => {
                                const { scores, currentScore } = findScoresForNodePaths(
                                  scoresGroupByNodePath,
                                  get(chart, 'metadata.data_node_path'),
                                  org,
                                  chart,
                                  true
                                );
                                const className = get(chart, 'options.className');
                                const additionalProps = { node: domain };
                                const isLoading = scoresData.organizationsWithScoresAwaiting.includes(org.id);
                                const isBarChart = chart.type === 'KdeSidewayBarChart';

                                let popoverPlacement = 'right';
                                if (organizations.length >= 3 && findLastIndex(organizations) === index) {
                                  popoverPlacement = 'left';
                                }

                                if (className) {
                                  additionalProps.className = styles[className];
                                }

                                return (
                                  <CompareTable.Cell
                                    key={`chart-${org.id}-${chart.name}-${index}`}
                                    className={cx({ [styles.barChartWrapper]: isBarChart })}
                                  >
                                    <Chart
                                      chartConfig={{ ...chart, isFirstColumn: index === 0, isLastColumn: index === organizations.length - 1 }}
                                      organization={org}
                                      scores={scores}
                                      currentScore={currentScore}
                                      isLoading={isLoading}
                                      additionalProps={additionalProps}
                                      popoverPlacement={popoverPlacement}
                                    />
                                  </CompareTable.Cell>
                                );
                              })
                            }
                          </CompareTable.Row>
                        ))
                      }
                      {
                        isEmpty(charts) && <EmptyState className="gatewayGrid-item_double" kind="text" {...domainEmptyStateMessage} />
                      }
                    </CompareTable.Section>
                  );
                })
              }
            </CompareTable>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  featureFlags: state => get(state, 'module.pageContext.featureFlags'),
  framework: makeSelectFramework(),
  currentOrganization: makeSelectCurrentOrganization(),
  scoresData: makeSelectScoresData(),
  isPreviewSite: selectIsPreviewSite,
  schoolYear: state => get(state, 'module.schoolYear'),
  schoolStat: schoolStatSelector,
  covidImpactBanner: state => get(state, 'framework.kde.metadata.covidImpactBanner'),
  userRole: makeSelectUserRole()
});

const mapDispatchToProps = dispatch => ({
  loadScoreForOrg: memoize(
    (organization, year) => dispatch(
      scoreLoadAction({ currentOrganization: organization, mergePortalData: true, year })
    ),
    ({ id }, year) => [id, year]
  )
});

export default connect(mapStateToProps, mapDispatchToProps)(connected(ComparePage));

export const config = {
  title: pageTitle('{{currentOrgName}} - Compare'),
  mapStateToProps: state => ({
    currentOrgName: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase()),
    organizations: get(state, 'module.organizations', []),
    scoresFromPortalDataService: get(state, 'module.scoresFromPortalDataService')
  }),
  actions: [
    {
      ...LOAD_FEATURE_FLAGS_ACTION,
      actions: routeInitActions(scoreActionsForOrgFromUrl())
    }
  ],
  // Route/Query params to watch for changes
  actionParams: [
    'domainSlug',
    'siSlug',
    'variableSlug'
  ]
};
