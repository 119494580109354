import React from 'react';
import PropTypes from 'prop-types';

import List from 'cui/lib/components/List';

import connected from '../connected';
import styles from './index.module.scss';

const OrgContactPerson = ({ currentOrganization, showEmail }) => {
  const contactType = () => {
    switch (currentOrganization.entity_type) {
      case 'School':
        return 'Principal';
      case 'District':
        return 'Superintendent';
      case 'State':
        return 'Commissioner';
      default:
        return '';
    }
  };

  return (
    <dl className="dl">
      <dt>
        <h2 className={styles.label}>{contactType()}</h2>
      </dt>
      <dd className={styles.value}>
        <List inline>
          <List.Item>{currentOrganization.contact_name || 'Not Available'}</List.Item>
          {currentOrganization.email && showEmail &&
            <List.Item>
              <a className={styles.contactName} href={`mailto:${currentOrganization.email}`}>
                {currentOrganization.email}
              </a>
            </List.Item>
          }
        </List>
      </dd>
    </dl>
  );
};

OrgContactPerson.defaultProps = {
  showEmail: true
};

OrgContactPerson.propTypes = {
  currentOrganization: PropTypes.object,
  showEmail: PropTypes.bool
};

export default connected(OrgContactPerson);
