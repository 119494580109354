import React from 'react';
import PropTypes from 'prop-types';

import CuiSegmentChart from 'cui/lib/components/SegmentChart';
import CuiFlexBar from 'cui/lib/components/FlexBar';
import CuiTag from 'cui/lib/components/Tag';

import template from '../../../helpers/template';

const SegmentFormatter = (props) => {
  const {
    segments,
    value,
    label,
    labelMap,
    templateString,
    theme,
    handlesEmptyValue,
    emptyValueText
  } = props;

  const findSpectrum = (level) => {
    const interval = 3 * Math.ceil(level / 3);

    return [interval, interval - 1, interval - 2];
  };

  const renderWithTag = () => {
    if (handlesEmptyValue && (value === 'N/A' || value === 0 || value === null)) {
      return <CuiTag theme="secondary">{emptyValueText || value}</CuiTag>;
    }
    return (
      <CuiFlexBar justify="left">
        <CuiFlexBar.Item>
          <CuiSegmentChart
            segments={segments}
            theme={theme}
            current={value}
            customSpectrum={findSpectrum}
          />
        </CuiFlexBar.Item>
        <CuiFlexBar.Item className="cui-text_cap">
          {labelMap[label] && template(templateString, { ...props, label: labelMap[label] })}
        </CuiFlexBar.Item>
      </CuiFlexBar>
    );
  };

  return (
    renderWithTag()
  );
};

SegmentFormatter.propTypes = {
  segments: PropTypes.number,
  segmentValueMap: PropTypes.object,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  labelMap: PropTypes.object,
  templateString: PropTypes.string,
  theme: PropTypes.string,
  handlesEmptyValue: PropTypes.bool,
  emptyValueText: PropTypes.string
};

SegmentFormatter.defaultProps = {
  segments: 3,
  segmentValueMap: {},
  value: '',
  label: '',
  labelMap: {},
  templateString: '',
  theme: 'rainbow',
  handlesEmptyValue: false
};

export default SegmentFormatter;
