import get from 'lodash/get';
import startsWith from 'lodash/startsWith';

const schoolClassification = (org) => {
  const entityType = (org.entity_type || org.entityType);
  if (!org || !entityType || entityType.toLowerCase() !== 'school') return null;

  // KDE stores `sch_type` in `organization`
  // The rest of modules store it in `organization_add_on.full_data`
  return org.sch_type || get(org, 'organization_add_on.full_data.SCH_TYPE', null);
};

const schoolHasNoCollectors = (org) => {
  const schType = schoolClassification(org);

  if (!schType) return false;
  if (['A1', 'A2', 'A3', 'A4', 'A5'].includes(schType)) return false;
  if (startsWith(schType, 'B')) return false;
  if (startsWith(schType, 'D')) return false;

  return true;
};

export default schoolHasNoCollectors;
