import React from 'react';
import PropTypes from 'prop-types';

import FlexBar from 'cui/lib/components/FlexBar';
import Progress from 'cui/lib/components/Progress';

import DetailsModalButton from '../../DetailsModalButton';

const ProgressBar = (props) => {
  const {
    data,
    label,
    progressProps,
    showDetails
  } = props;

  return (
    <div>
      <div className="cui-margin-bottom-xxSmall cui-text_small">{ label }</div>
      <FlexBar justify="left">
        <FlexBar.Item grow>
          <Progress data={data} {...progressProps} />
        </FlexBar.Item>
        <FlexBar.Item className="cui-text_small">
          {showDetails && <DetailsModalButton {...props}  />}
        </FlexBar.Item>
      </FlexBar>
    </div>
  );
};

ProgressBar.propTypes = {
  data: Progress.propTypes.data,
  label: PropTypes.string,
  progressProps: PropTypes.shape({
    theme: PropTypes.string
  }),
  showDetails: PropTypes.bool
};

ProgressBar.defaultProps = {
  progressProps: {},
  label: '',
  data: [],
  showDetails: false
};

export default ProgressBar;
