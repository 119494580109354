import get from 'lodash/get';
import sumBy from 'lodash/sumBy';
import PropTypes from 'prop-types';

import currentOrgScores from '../../helpers/scoresHelpers';
import template from '../../helpers/template';
import when from '../../helpers/when';
import { getParentData, getChartData } from '../../helpers/portalDataServiceHelpers';

/* eslint-disable complexity */
const KdeDashboardDonutProvider = (props) => {
  if (!props.scores.length) return null;

  const {
    config: {
      options,
      size
    },
    organization,
    scores,
    node,
    allScores
  } = {
    config: {},
    ...props
  };

  const includeParentData = get(node, 'metadata.includeParentData');
  const slug = get(node, 'slug', '');
  const parentData = includeParentData ? Object.values(getParentData(allScores, organization, node))[0] : null;
  let currentScores = get(parentData, slug) || currentOrgScores(scores, organization) || currentOrgScores(scores, organization.parentDistrict);
  if (includeParentData) {
    currentScores = node ? getChartData(allScores, organization, node) : get(parentData, slug);
  }
  const suppressedWhen = get(props, 'config.metadata.suppressedWhen');
  let isSuppressed = false;

  if (suppressedWhen) {
    isSuppressed = when(suppressedWhen, currentScores);
  }

  // An attempt to decouple some of the template logic to partials defined per data point
  const templatePartials = get(options, 'templatePartials', [])
    .reduce((accumulator, partial) => ({
      ...accumulator,
      [partial.name]: template(partial.value, {
        ...accumulator, // So you can use partials inside partials. Order of definition in framework tree matters!
        ...currentScores,
        ...props
      })
    }), {});

  const chartData = get(options, 'scorePoints', []).reduce((points, point) => {
    const descriptionTemplateString = point.description;
    const labelTemplateString = point.label;
    const valueTemplateString = point.value;
    const valueNumberTemplateString = point.valueNumber;
    const totalValue = templatePartials.totalValue;

    const templateProps = {
      ...currentScores,
      ...templatePartials,
      ...props
    };
    const percent = (parseFloat(template(valueNumberTemplateString, templateProps)) / parseFloat(totalValue)) * 100;

    return [
      ...points, {
        description: point.description && template(descriptionTemplateString, templateProps),
        label: point.label && template(labelTemplateString, templateProps),
        percent: totalValue ? percent : parseFloat(template(valueTemplateString, templateProps), 10) || 0,
        value: template(valueTemplateString, templateProps),
        totalValue
      }
    ];
  }, []);

  const getTheme = (count) => {
    switch (true) {
      case count < 3:
        return 'kde-duo';
      default:
        return 'kde-multi';
    }
  };

  const theme = getTheme(chartData.length);
  const labels = get(props, 'config.metadata.labels');
  const totalPosition = get(props, 'config.options.totalPosition');

  const percentageSum = sumBy(chartData, 'percent');

  const filledChartData = (percentageSum.toFixed(2) < 100 ? [
    ...chartData, {
      percent: 100 - percentageSum,
      colorIndex: theme === 'kde-multi' ? 7 : 1
    }
  ] : chartData)
    .filter(d => (d.percent >= 0 && d.percent <= 100));

  if (!chartData.length) return null;

  return props.children({
    data: filledChartData,
    displayDetailedLegend: chartData.length > 1,
    isWide: size === 'doublewide',
    isValueFirst: labels === 'valueFirst',
    totalValue: chartData[0].totalValue,
    totalPosition,
    label: chartData[0].label,
    title: chartData[0].value,
    theme,
    isSuppressed
  });
};

KdeDashboardDonutProvider.propTypes = {
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  config: PropTypes.object
};

KdeDashboardDonutProvider.defaultProps = {
  organization: {},
  scores: [],
  config: {}
};

export default KdeDashboardDonutProvider;
/* eslint-enable complexity */
