import React from 'react';
import PropTypes from 'prop-types';
import { scoreInfoPropType } from '../../../helpers/collectorHelpers';
import CheckboxSet from '../fields/CheckboxSet';
import EmptyWrapper from '../EmptyWrapper';
import RadioSet from '../fields/RadioSet';
import sectionHoc from './sectionHoc';

export const FacilitiesSection = ({ scores: { equitableAccess, programSpecificFacilities } }) => (
  <EmptyWrapper scoreInfos={{ equitableAccess, programSpecificFacilities }}>
    {() => (
      <section className="section" aria-labelledby="facilities_section_aria_label">
        <h2 id="facilities_section_aria_label">Facilities</h2>
        <EmptyWrapper scoreInfo={equitableAccess}>
          {({ scoreInfo, notReported }) => (
            <RadioSet fieldLabel="Equitable Access to Instructional Space" notReported={notReported} scoreInfo={scoreInfo} />
          )}
        </EmptyWrapper>
        <EmptyWrapper scoreInfo={programSpecificFacilities}>
          {({ scoreInfo, notReported }) => (
            <CheckboxSet fieldLabel="Access to Program-Specific Facilities" notReported={notReported} scoreInfo={scoreInfo} columnCount={1} />
          )}
        </EmptyWrapper>
      </section>
    )}
  </EmptyWrapper>
);

FacilitiesSection.propTypes = {
  scores: PropTypes.shape({
    equitableAccess: scoreInfoPropType,
    programSpecificFacilities: scoreInfoPropType
  })
};

export default sectionHoc(FacilitiesSection);
