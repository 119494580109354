import React from 'react';
import PropTypes from 'prop-types';

import List from 'cui/lib/components/List';
import Icon from 'cui/lib/components/Icon';

const SocialIcons = ({ currentOrganization }) => {
  const appUrl = encodeURIComponent(location.href);
  const text = `Check out ${currentOrganization} data from the Kentucky School Report Card!`;

  /* eslint-disable no-undef */
  const share = {
    facebook: `https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=page&quote=${text}&href=${appUrl}&redirect_uri=${appUrl}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(`${text}\n${appUrl}`)}`
  };
  /* eslint-enable no-undef */

  return (
    <List inline>
      <List.Item>
        <a href={share.facebook} title="Share with Facebook"><Icon name="ig-facebook" /></a>
      </List.Item>
      <List.Item>
        <a href={share.twitter} title="Share with Twitter"><Icon name="ig-twitter-2" /></a>
      </List.Item>
    </List>
  );
};

SocialIcons.propTypes = {
  currentOrganization: PropTypes.string
};

export default SocialIcons;
