import { get, flatten } from 'lodash';

import template from '../../helpers/template';

import { FIRST_SCHOOL_YEAR } from '../../constants';
import dataByOrgFromPortal from '../tableChart/dataByOrgFromPortalAdapter';
import { getOrgIdFromOrganization } from '../../helpers/portalDataServiceHelpers';

const TREND_YEARS = 5;

const getCurrentOrgScores = (scores, config, organizationId) => {
  const score = scores.find(s => s.portal_org_id === organizationId);
  const rawScores = get(score, 'value', {});

  if (config.extractTo) {
    return {
      dimensions: {
        ...rawScores.dimensions,
        [config.extractTo.to]: { score: { ...get(rawScores, config.extractTo.from, {}) } }
      }
    };
  }

  return rawScores;
};

const buildChartScores = ({ values, config, organization, node, studentGroup, viewingDropdownFilter }) => {
  if (config.dimensionless) {
    return values.map(({ year, scores }) => ({
      year,
      scores: get(scores, `[0].value.score.${studentGroup}`)
    }));
  }

  const organizationId = getOrgIdFromOrganization(organization);
  const filters = {
    ...viewingDropdownFilter,
    0: studentGroup
  };

  return values.map(({ year, scores }) => ({
    year,
    scores: dataByOrgFromPortal({
      chartConfig: config,
      currentOrganization: organization,
      currentOrgScore: getCurrentOrgScores(scores, config, organizationId),
      node,
      viewingDropdownFilter: filters
    })
  }));
};

const mergeValuesIntoSingleObject = (values, orgName) => values.reduce(
  (res, valuesByPriorSetting) => ({
    ...res,
    label: orgName,
    data: {
      ...res.data,
      [valuesByPriorSetting.label]: valuesByPriorSetting.data[orgName]
    }
  }),
  { data: {} }
);

const adapter = (options) => {
  const {
    scores: currentYearScores,
    previousYearScores,
    additionalOrganizations,
    viewingDropdownFilter,
    config,
    node,
    organization,
    schoolYear
  } = options;

  // TREND_YEARS - 1 because we have current year scores separately
  const startYear = Math.max(FIRST_SCHOOL_YEAR, schoolYear - (TREND_YEARS - 1));
  const previousYears = new Array(schoolYear - startYear).fill().map((_, idx) => startYear + idx);

  const previousYearOrganizationScores = previousYears.map(year => ({ year, scores: get(previousYearScores, year, []) }));
  const allYearScores = [...previousYearOrganizationScores, { year: schoolYear, scores: currentYearScores }];
  const studentGroups = flatten([get(viewingDropdownFilter, '0')]);

  const values = studentGroups.map((studentGroup) => {
    const data = [organization, ...additionalOrganizations].reduce((result, org) => {
      const chartScores = buildChartScores({
        values: allYearScores,
        config,
        organization: org,
        node,
        studentGroup,
        viewingDropdownFilter
      });

      const computedValues = chartScores.map(({ year, scores }) => ({
        x: year,
        y: template(config.valueTemplate, { value: scores }, false),
        label: template(config.labelTemplate, { value: scores }, false),
        ariaLabel: `${node.name}, ${year}`
      }));

      result[org.name] = computedValues.filter(({ y }) => Boolean(y) && y !== '*');
      return result;
    }, {});

    return {
      data,
      label: studentGroup
    };
  });

  if (!additionalOrganizations.length && studentGroups.length > 1) {
    // if no additionalOrganizations selected, display everything on 1 chart.
    // to do it - need to merge all valuesByPriorSetting's into 1 object
    return [mergeValuesIntoSingleObject(values, organization.name)];
  }

  return values;
};

export default adapter;
