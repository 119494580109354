import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import CuiAlert from 'cui/lib/components/Alert';

const getErrorMessage = (dp, errors, isDevelopment) => {
  const nodePath = get(dp, 'node_path');
  const scoreError = get(errors, nodePath);

  if (!scoreError) {
    return null;
  }

  if (isDevelopment) {
    return `Save unsuccessful: ${get(errors, nodePath)}`;
  }

  return 'Save unsuccessful';
};

const CollectionDataPointError = ({ dp, errors, isDevelopment }) => {
  const scoreError = getErrorMessage(dp, errors, isDevelopment);

  if (scoreError) {
    return (
      <CuiAlert kind={CuiAlert.ERROR_TYPE} type="text" closable={false} autoclose={false}>{scoreError}</CuiAlert>
    );
  }
  return (null);
};

CollectionDataPointError.propTypes = {
  isDevelopment: PropTypes.bool,
  dp: PropTypes.object,
  errors: PropTypes.object
};

export default CollectionDataPointError;
