import filter from 'lodash/filter';
import intersection from 'lodash/intersection';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import uniqBy from 'lodash/uniqBy';

import { findNodeByNodePath } from '../frameworkHelpers';
import when, { filterWhen } from '../when';
import filterByMetadataProps from '../metadataHelpers';

export const excludeDisabled = items => filter(items, item => !item.disabled);

export const isNodeVisible = node => !get(node, 'metadata.hide_in_list');

// The individual filter functions are no longer public. Use the combined one,
// filterByMetadata, instead.
const filterByMetadataEntity = (node, currentEntity) => {
  const entities = get(node, 'metadata.entities', []);

  if (entities.length > 0 && currentEntity) {
    return entities
      .map(entity => entity.toUpperCase())
      .includes(currentEntity.toUpperCase());
  }

  return true;
};

const filterByMetadataSchoolLevels = (node, currentSchoolLevel) => {
  const dataPointSchoolLevels = get(node, 'metadata.school_levels', []);

  if (dataPointSchoolLevels.length > 0 && currentSchoolLevel) {
    const orgSchoolLevels = currentSchoolLevel.split(',');

    const applicableLevels = intersection(
      dataPointSchoolLevels.map(el => el.toUpperCase()),
      orgSchoolLevels.map(el => el.toUpperCase())
    );

    return applicableLevels.length > 0;
  }

  return true;
};

const filterByMetadataEnvironment = (node) => {
  const currentEnvironment = String(process.env.DEPLOY_ENV);
  const environments = get(node, 'metadata.environments', []);

  if (environments.length > 0) {
    return environments
      .map(environment => environment.toUpperCase())
      .includes(currentEnvironment.toUpperCase());
  }

  return true;
};

const filterByMetadataPreviewSiteOnly = (node, isPreviewSite) =>
  isPreviewSite || !get(node, 'metadata.preview_site_only');

export const filterByMetadata = (node, { currentEntity, isPreviewSite, currentSchoolLevel }) => (
  filterByMetadataEntity(node, currentEntity) &&
  filterByMetadataSchoolLevels(node, currentSchoolLevel) &&
  filterByMetadataEnvironment(node) &&
  filterByMetadataPreviewSiteOnly(node, isPreviewSite)
);

export const isPublicOrWithClarityUserPermissions = (node = null, userRole = null) => {
  if (!node) {
    // eslint-disable-next-line no-console
    console.warn('Provide `node` in `isPublicOrWithClarityUserPermissions` filter');
    return false;
  }
  if (!userRole) {
    return true; // Public portal has no userRole
  }
  const grantedClarityUserRoles = get(node, 'metadata.clarity_user_restriction', []).map(role => role.toLowerCase());
  return grantedClarityUserRoles.length === 0 || grantedClarityUserRoles.includes(userRole.toLowerCase());
};

export function shouldUsePortalDataService(node) {
  const dataSources = get(node, 'dataSources');
  if (dataSources) {
    return dataSources.some(dataSource => !!get(dataSource, 'node_options.portal_data'));
  } else {
    return !!get(node, 'portal_data');
  }
}

// Pluck nodes from multiple data sources, it is usefully to fetch data for
// data point that use data from multiple data sources and depend on other data points
// @params nodes { array of objects }, framework tree nodes to examine
// @params framework {object}, framework tree
// @params featureFlags {object}, feature flag object
// @return nodes for sources(groot and portal)
// Note: Hopefully we will migrate data from groot to svc-portalData to make our life easier.
export function pluckNodes(nodes = [], framework, currentOrganization, featureFlags) {
  // Group the nodes under different sources
  const nodesBySources = groupBy(nodes, (n) => {
    const hasPortalConfig = get(n, 'metadata.portal_data');
    const hasDataSources = get(n, 'metadata.dataSources');
    let groupName;

    if (hasDataSources) {
      groupName = 'multiple';
    } else if (hasPortalConfig && shouldUsePortalDataService(n)) {
      groupName = 'portalService';
    } else {
      groupName = 'groot';
    }

    return groupName;
  });

  let nodePathsForGroot = nodesBySources.groot || [];
  let nodesForPortalService = nodesBySources.portalService || [];
  const shouldUseSource = source => (source.when ? when(source.when, { currentOrganization, featureFlags }) : true);
  // Process the data sources
  get(nodesBySources, 'multiple', []).forEach((n) => {
    get(n, 'metadata.dataSources', []).forEach((s) => {
      // Add node_path to nodePathsForGroot for Groot source
      if (s.source === 'groot' && shouldUseSource(s)) {
        nodePathsForGroot.push(s);
      }

      // Add node to nodesForPortalService for Portal source
      if (s.source === 'portalService') {
        // Find node by node_path
        // Some data point depends on other data point
        const node = findNodeByNodePath(framework, s.node_path);
        if (node) {
          nodesForPortalService.push(s.node_options ? { ...node, ...s.node_options } : node);
        }
      }
    });
  });

  nodePathsForGroot = uniqBy(nodePathsForGroot, 'node_path');
  nodesForPortalService = uniqBy(nodesForPortalService, n => `${n.node_path}${get(n, 'portal_data.slug', '')}`);

  return {
    nodePaths: nodePathsForGroot.map(n => n.node_path),
    dataNodePaths: nodePathsForGroot.map(({ data_node_path: dataNodePath, node_path: nodePath }) => dataNodePath || nodePath),
    nodes: nodesForPortalService,
    nodePathsSVCPD: nodesForPortalService.map(n => n.node_path)
  };
}

export const isDomainVisibleOnApprovalSite = (node, featureFlags) => {
  const featureFlagsByDomain = {
    school_overview: 'KdeApprovalSchoolOverview',
    academic_performance: 'KdeApprovalAcademicPerformance',
    educational_opportunity: 'KdeApprovalEducationalOpportunity',
    transition_readiness: 'KdeApprovalTransitionReadiness',
    school_safety: 'KdeApprovalSchoolSafety',
    school_accountability: 'KdeApprovalSchoolAccountability',
    other_education_data: 'KdeApprovalOtherEducationData',
    financial_transparency: 'KdeApprovalFinancialTransparency'
  };

  const currentDomainFeatureGuard = featureFlagsByDomain[node.slug];
  return featureFlags[currentDomainFeatureGuard] !== false;
};

export const getVisibleSuccessIndicators = (nodes, props) => {
  const metadataProps = filterByMetadataProps(props);

  const { userRole, ...otherProps } = props;

  return nodes
    .filter(isNodeVisible)
    .filter(node => filterByMetadata(node, metadataProps))
    .filter(node => !(node.when && !when(node.when, otherProps)))
    .filter(node => isPublicOrWithClarityUserPermissions(node, userRole));
};

export const getVisibleVariables = (nodes, props) => {
  const whenProps = { development: process.env.NODE_ENV === 'development', ...props };
  const metadataProps = filterByMetadataProps(props);

  return filterWhen(excludeDisabled(nodes), whenProps)
    .filter(nodeItem => filterByMetadata(nodeItem, metadataProps))
    .filter(isNodeVisible);
};

export const getVisibleDataPoints = (nodes, props) => {
  const metadataProps = filterByMetadataProps(props);

  return nodes
    .filter(isNodeVisible)
    .filter(node => filterByMetadata(node, metadataProps))
    .filter(node => !(node.when && !when(node.when, props)));
};

export const getAllVisibleDataPoints = (root, props) => {
  const successIndicators = root.items
    .map(domain =>
      getVisibleSuccessIndicators(domain.items, props).map(el => ({
        ...el,
        domain
      }))
    )
    .flat();

  const variables = successIndicators
    .map(successIndicator =>
      getVisibleVariables(successIndicator.items, props).map(el => ({
        ...el,
        domain: successIndicator.domain,
        successIndicator
      }))
    )
    .flat();

  const dataPoints = variables
    .map(variable =>
      getVisibleDataPoints(variable.items, props).map(el => ({
        ...el,
        domain: variable.domain,
        successIndicator: variable.successIndicator,
        variable
      }))
    )
    .flat();

  return dataPoints;
};

export const getNodeContentByPath = (node = {}, path, currentOrganization = '') => {
  const content = get(node, path, '');

  if (typeof content === 'string') {
    return content;
  }

  return content.find(item => when(item.when, { currentOrganization })).value;
};
