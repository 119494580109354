import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import some from 'lodash/some';

import Field from 'cui/lib/components/Form/Field';

import FederalClassificationExplainer from '../FederalClassificationExplainer';
import schoolAccountabilityPageLink from './schoolAccountabilityPageLink';
import StarRating from '../../ui/StarRating';
import PanelHeader from '../../ui/PanelHeader';
import { getYearSpecificCopy } from '../../helpers/frameworkTreeTraversalHelper';
import { isSchool } from '../../helpers/completionStatusHelpers/collection/utils';
import SchoolLevelExplanation from './SchoolLevelExplanation';

const starRatingPresent = schoolStat =>
  some(schoolStat, stat => get(stat, 'rating.star'));

const StarRatingPanel = ({
  featureFlags,
  federalClassification,
  framework,
  organization,
  schoolStat,
  year,
  headingLevel
}) => (
  <div>
    <PanelHeader
      headingLevel={headingLevel}
      title={getYearSpecificCopy(framework, 'starRating.title') || 'Star Rating'}
      linkTo={
        (starRatingPresent(schoolStat) || year < 2019) &&
        schoolAccountabilityPageLink(featureFlags, year, organization.id)
      }
      linkLabel="View Accountability Data"
      popoverContent={getYearSpecificCopy(framework, 'starRating.popover')}
      label="More information star rating"
    />
    <div>
      {starRatingPresent(schoolStat) ? (
        schoolStat.map(stat =>
          (
            <SchoolLevelExplanation
              stat={stat}
              popoverContent={getYearSpecificCopy(
                framework,
                'fedClassification.popover'
              )}
            />
          )
        )
      ) : (
        <div>
          <Field label="">
            <div className="flexRow flexRow--nowrap">
              <StarRating
                message={getYearSpecificCopy(framework, 'starRating.empty')}
                emptyState={getYearSpecificCopy(framework, 'starRating.emptyState')}
              />
            </div>
          </Field>
          {isSchool(organization) && (
            <Field label="">
              <FederalClassificationExplainer
                federalClassification={federalClassification}
                popoverContent={getYearSpecificCopy(
                  framework,
                  'fedClassification.popover'
                )}
                modalNotes={getYearSpecificCopy(framework, 'fedClassification.notes')}
              />
            </Field>
          )}
          {get(organization, 'no_star_rating_assessment') && (
            <Field label="">
              {getYearSpecificCopy(framework, 'starRating.no_assessment')}
            </Field>
          )}
        </div>
      )}
    </div>
  </div>
);

StarRatingPanel.propTypes = {
  featureFlags: PropTypes.object,
  federalClassification: PropTypes.object,
  framework: PropTypes.object,
  organization: PropTypes.object,
  schoolStat: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
  headingLevel: PropTypes.number
};

export default StarRatingPanel;
