import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Modal from 'cui/lib/components/Modal';
import Field from 'cui/lib/components/Form/Field';

import styles from './OrgProfile.module.scss';

class Title1Status extends Component {
  state = { isModalOpen: false }

  openModal = () => this.setState({ isModalOpen: true })

  closeModal = () => this.setState({ isModalOpen: false })

  popoverProps = { appendToBody: false }

  render() {
    return (
      <Field
        description={this.props.description}
        label={<h4 className={styles.title}>Title I Status</h4>}
        popoverContent={(
          <div>
            The school&apos;s designation of Title I services as reported for the specified school year.
            Schools that are eligible to receive Title I funding will have one of the following
            statues: Title I Eligible - No Program, Title I Eligible - Schoolwide School,
            or Title I Eligible - Targeted Assistance School.
            <Button size="small" onClick={this.openModal}>More info</Button>
          </div>
        )}
        popoverProps={this.popoverProps}
      >
        {this.props.title}
        <Modal isOpen={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header title="More Information about Title I Status" />
          <Modal.Body>
            <p>
              <b>Title I Eligible - No Program:</b><br />
              The school is eligible to receive Title I,
              Part A funds but the district decides to not participate or serve the school with an allocation.
            </p>
            <p>
              <b>Title I Eligible - Schoolwide School:</b><br />
              The school’s poverty percentage is at or above 40% low income as reported by the district. The school receives a Title I, Part A
               allocation and the district implements a schoolwide program. All students are eligible to be served with Title I, Part A funds.
                Schools that have operated schoolwide programs in the past may continue to do so, even if their poverty percentages drop below 40%.
            </p>
            <p>
              <b>Title I Eligible - Targeted Assistance School:</b><br />
              The school’s poverty percentage is less than 40% low income as reported in by the district. The school receives a Title I,
               Part A allocation and the district implements a targeted assistance program. Only identified students enrolled in the school receive
                Title I services. Schools in this category must also have poverty percentages above 35% or the district’s poverty average.
                 Schools eligible to operate schoolwide programs may choose to operate targeted assistance programs.
            </p>
            <p>
              <b>Not a Title I School:</b><br />
              This school is not eligible to receive Title I funding because its poverty percentage is not above 35% or the district’s
               poverty average.
            </p>
          </Modal.Body>
        </Modal>
      </Field>
    );
  }
}

Title1Status.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default Title1Status;
