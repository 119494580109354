import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ScoreDot from 'cui/lib/components/ScoreDot';
import styles from './index.module.scss';

export default function ReportLegend({ data, theme, className, inline }) {
  return (
    <ul className={cx('cui-list', styles.legend, className)}>
      {
        data.map((item, i) => (
          <li key={i} className={cx(styles.item, `theme-${theme}`)}>
            <span className="cui-list-text">
              <ScoreDot theme={theme} value={item.colorIndex || i} />
              <span className={styles.label}>{item.label}</span>
              { inline && <span className={styles.inlineDescription}>{item.description}</span> }
            </span>
            {
              !inline && (
                <span className={styles.description}>
                  {item.description}
                </span>
              )
            }
          </li>
        ))
      }
    </ul>
  );
}

ReportLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    colorIndex: PropTypes.number
  })),
  theme: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool
};
