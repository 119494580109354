import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Button from 'cui/lib/components/Button';
import Modal from 'cui/lib/components/Modal';
import FlexBar from 'cui/lib/components/FlexBar';
import RadioSet from 'cui/lib/components/Form/RadioSet';
import Field from 'cui/lib/components/Form/Field';

class FlagModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleStatusFieldChange = this.handleStatusFieldChange.bind(this);
    this.handleStatusFieldError = this.handleStatusFieldError.bind(this);
    this.state = {
      data: {
        status: props.status
      },
      errors: {
        status: null
      },
      submitted: false
    };
  }

  handleFieldChange(name, value) {
    this.setState({
      data: {
        ...this.state.data,
        [name]: value
      }
    });
  }

  handleStatusFieldChange(value) { this.handleFieldChange('status', value); }

  handleFieldError(name, message) {
    this.setState({
      errors: {
        ...this.state.errors,
        [name]: message
      }
    });
  }

  handleStatusFieldError(value) { this.handleFieldError('status', value); }

  escapeForGraphQL(str) {
    // backslash, doublequote, colon.
    return str.replace(/\\/g, '\\u005C').replace(/"/g, '\\u0022').replace(/:/g, '\\u003A');
  }

  save = () => {
    this.setState({ submitted: true }, () => {
      this.props.onSave(this.state.data);
      this.props.hideModal();
    });
  };

  render() {
    const { canEdit } = this.props;
    const title = this.props.status.status === 'flagged' ? 'Viewing Flag' : 'Change Status';

    return (
      <Modal size="medium" isOpen onHide={this.props.hideModal}>
        <Modal.Header title={title} />
        <Modal.Body className={cx('cui-form', { 'is-submitted': this.state.submitted })}>
          <Field label="Status">
            <RadioSet
              name="status"
              id="approvalStatus_status"
              required
              defaultValue={this.props.status}
              onChange={this.handleStatusFieldChange}
              onError={this.handleStatusFieldError}
            >
              <RadioSet.Option label="Unreviewed" id="approvalStatus_status_unreviewed" value="unreviewed" disabled={!canEdit} />
              <br />
              <RadioSet.Option label="Approved" id="approvalStatus_status_approved" value="approved" disabled={!canEdit} />
            </RadioSet>
          </Field>
        </Modal.Body>
        <Modal.Footer>
          <FlexBar justify="right">
            <FlexBar.Item>
              <Button title="Cancel" kind="solid" theme="secondary" onClick={this.props.hideModal}>{canEdit ? 'Cancel' : 'Close'}</Button>
            </FlexBar.Item>
            {canEdit && (
              <FlexBar.Item>
                <Button title="Save" kind="solid" theme="primary" onClick={this.save}>Save</Button>
              </FlexBar.Item>
            )}
          </FlexBar>
        </Modal.Footer>
      </Modal>
    );
  }
}

FlagModal.propTypes = {
  status: PropTypes.string,
  canEdit: PropTypes.bool,
  onSave: PropTypes.func,
  hideModal: PropTypes.func
};

FlagModal.defaultProps = {
  status: 'unreviewed'
};

export default FlagModal;
