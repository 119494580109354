/* eslint-disable max-len, prefer-template, react/prop-types, jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { Field, Heading } from 'cui';

import { findNodeOfTypeBySlug, findNodeByNodePath } from '../../helpers/frameworkHelpers';
import { LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS } from '../OrgReportCardPage/actions';
import pageTitle from '../../helpers/pageHelpers';
import {
  LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
  LOAD_CHILDREN_ORGS_ACTION,
  scoreLoadFromPortalDataService
} from '../../helpers/scoreLoadActions';
import buildLoadFrameworkAction from '../../actions/framework';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import {
  indicatorOutBoundLink,
  gapsOutBoundLink
} from '../../helpers/acctHelpers';
import InfoIcon from '../../components/KdeCondensedTable/KdeCondensedTableInfoIcon';
import starRatingMessage from '../../helpers/schoolStatHelpers';
import FederalClassificationExplainer from '../../components/FederalClassificationExplainer';
import PageShell from '../../components/PageShell';
import PublicOrWithPermissions from '../../components/PublicOrWithPermissions';
import StarRating from '../../ui/StarRating';
import AccountabilityDashboard from '../../ui/AccountabilityDashboard';
import AccountabilityHeader from '../../ui/AccountabilityHeader';
import AccountabilityLegend from '../../ui/AccountabilityLegend';
import AchievementGaps from '../../ui/AchievementGaps';
import OverviewBlock from '../../ui/OverviewBlock';
import ScoreBlock from '../../ui/ScoreBlock';
import ScoreBar from '../../ui/ScoreBar';
import ScaleBar from '../../ui/ScaleBar';
import { chartScoreSelectorConfig, gaugeScoreSelectorConfig, schoolStatSelector } from '../../selectors/accountabilitySelector';
import { featureFlagsSelector, currentOrganizationSelector, makeSelectScoresData } from '../../selectors/moduleSelector';
import { ACCOUNTABILITY_DOMAIN_NODE_PATH, ACCOUNTABILITY_ACHIEVEMENT_NODE_PATH } from '../../constants';
import { getYearSpecificCopy } from '../../helpers/frameworkTreeTraversalHelper';
import { findRestrictionProps } from '../../helpers/restrictionHelpers';
import styles from './index.module.scss';
import OrgDataProvider from '../../helpers/OrgDataProvider';
import OrgPanels2022Top from '../OrgReportCardPage/OrgPanels2022Top';
import Gauge from '../../ui/Gauge';
import PanelHeader from '../../ui/PanelHeader';

const chartScoreSelector = chartScoreSelectorConfig(ACCOUNTABILITY_ACHIEVEMENT_NODE_PATH);
const gaugeScoreSelector = gaugeScoreSelectorConfig(ACCOUNTABILITY_ACHIEVEMENT_NODE_PATH);

function SchoolAccountability({
  schoolStat,
  chartScores,
  gaugeScores,
  currentSchoolYear,
  featureFlags,
  currentOrganization,
  scoresData,
  framework
}) {
  const domain = get(framework, 'items', []).find(item => item.node_type === 'domain' && item.node_path === 'kde.school_accountability');
  const restrictAccess = get(domain, 'metadata.restrictAccess', []);
  const chartType = get(framework, 'metadata.chartType', 'star');
  const isGaugeChart = chartType === 'gauge';
  const legendList = get(framework, 'metadata.accountability.legendType') === 'color' ? [{ label: 'Red (Lowest Performance)' }, { label: 'Orange' }, { label: 'Yellow' }, { label: 'Green' }, { label: 'Blue (Highest Performance)' }] : null;
  const labels = get(framework, 'metadata.accountability.legendType') === 'color' ? ['Red', 'Orange', 'Yellow', 'Green', 'Blue'] : null;
  const mapping = get(framework, 'metadata.accountability.mapping', 'rating');

  if (!currentOrganization) {
    return null;
  }

  const restrictionProps = findRestrictionProps(currentOrganization, restrictAccess);

  if (restrictionProps) {
    return (
      <p className={styles.yearNotSupported} data-test="year-not-supported">
        {restrictionProps.title}
      </p>
    );
  }

  const ratings = [
    {
      field: 'proficiency',
      title: 'State Assessments: Reading & Mathematics',
      info: 'The State Assessment Results for Reading and Mathematics Indicator is determined by student performance for the knowledge and skills as measured on state required academic assessments in reading and mathematics in elementary, middle, and high schools. Indicator performance is color-coded with red being the lowest rating and blue being the highest rating.',
      levels: ['elementary_school', 'middle_school', 'high_school']
    },
    {
      field: 'seperate',
      title: 'State Assessments: Science, Social Studies, & Combined Writing',
      info: 'The State Assessment Results for Science, Social Studies, and Combined Writing Indicator is determined by student performance for the knowledge and skills as measured on state required academic assessments in science, social studies and writing in elementary, middle, and high schools. Indicator performance is color-coded with red being the lowest rating and blue being the highest rating.',
      levels: ['elementary_school', 'middle_school', 'high_school']
    },
    {
      field: 'el',
      title: 'Progress on English Language Proficiency',
      info: 'The Progress on English Language Proficiency Indicator is determined by student improvement on the English Language Proficiency Exam by English Learners. English learners’ progress is included in the calculation using an English Language Acquisition Value Table. Indicator performance is color-coded with red being the lowest rating and blue being the highest rating.',
      levels: ['elementary_school', 'middle_school', 'high_school']
    },
    {
      field: 'qscs',
      title: 'Quality of School Climate and Safety Survey',
      info: 'The Quality of School Climate and Safety Indicator is determined by student perception data from surveys that measure insight to the school environment. Indicator performance is color-coded with red being the lowest rating and blue being the highest rating.',
      levels: ['elementary_school', 'middle_school', 'high_school']
    },
    {
      field: 'grad',
      title: 'Graduation Rate',
      info: 'Graduation rate is determined by the percentage of students earning a high school diploma in four and five years compared to the group of students that began in high school in grade 9. Kentucky’s graduation rate indicator averages the four- and five-year rates. The calculation is based on the student’s final enrollment. Indicator performance is color-coded with red being the lowest rating and blue being the highest rating.',
      levels: ['high_school']
    },
    {
      field: 'psr',
      title: 'Postsecondary Readiness',
      info: 'The Postsecondary Readiness Indicator is determined by a student’s demonstration of the knowledge, skills and dispositions to successfully transition to the next level of his or her education career. To demonstrate postsecondary readiness, high school students must earn a high school diploma or be classified as a grade 12 non-graduate AND meet one type of readiness (Academic or Career).',
      levels: ['high_school']
    }
  ];

  const activeSchools = schoolStat(currentOrganization).filter(school => get(school, 'rating.star'));
  const activeSchoolLevels = activeSchools.map(school => school.level);

  return (
    <PageShell featureFlags={featureFlags}>
      <PublicOrWithPermissions
        nodePath={ACCOUNTABILITY_DOMAIN_NODE_PATH}
        accessDeniedMessage="Access Denied"
      >
        <AccountabilityDashboard>
          <AccountabilityDashboard.Overview>
            {
              !isGaugeChart &&
                <AccountabilityHeader
                  title="School Accountability"
                  icon="kde-115"
                />
            }

            {
              isGaugeChart ?
                <OrgDataProvider
                  scores={scoresData.scores}
                  isScoresLoading={scoresData.isLoading}
                  schoolStat={schoolStat}
                >
                  {/* Scores are considered only for schools*/}
                  {orgData => <OrgPanels2022Top showViewAccountability={false} {...orgData} />}
                </OrgDataProvider>
                :
                schoolStat(currentOrganization).map(stat => (
                  <OverviewBlock heading={stat.heading} key={stat.heading}>
                    <OverviewBlock.Item
                      label="Star Rating"
                      popoverContent={getYearSpecificCopy(framework, 'acctPopoverContent.star_rating')}
                      description="More information star rating"
                    >
                      <StarRating
                        score={get(stat, 'rating.star')}
                        message={starRatingMessage(get(stat, 'rating.gap_reduction'))}
                      />
                    </OverviewBlock.Item>
                    <OverviewBlock.Item
                      label="Significant Achievement Gaps"
                      popoverContent={getYearSpecificCopy(framework, 'acctPopoverContent.achievement_gap')}
                      description="More information signficant achievement gap"
                    >
                      <AchievementGaps
                        data={stat.gaps}
                        linkTo={gapsOutBoundLink(currentOrganization.id, currentSchoolYear, stat.level)}
                      />
                    </OverviewBlock.Item>
                    <OverviewBlock.Item>
                      <FederalClassificationExplainer
                        federalClassification={stat.fedClass}
                        popoverContent={getYearSpecificCopy(framework, 'acctPopoverContent.federal_classification')}
                        description="More information federal classifications"
                      />
                    </OverviewBlock.Item>
                  </OverviewBlock>
                ))
            }
          </AccountabilityDashboard.Overview>

          <AccountabilityDashboard.ScoreHeader>
            {
              isGaugeChart ?
                <PanelHeader
                  headingLevel={3}
                  title={getYearSpecificCopy(framework, 'indicatorRating.title')}
                  linkTo={indicatorOutBoundLink(currentOrganization.id, currentSchoolYear, 'proficiency')}
                  linkLabel="Explore Data"
                />
                :
                <AccountabilityHeader
                  title="Accountability Scores"
                  dataLink={indicatorOutBoundLink(currentOrganization.id, currentSchoolYear, 'proficiency')}
                  icon="kde-63"
                />
            }
            <AccountabilityLegend data={legendList} />
            {
              isGaugeChart &&
              <>
                <small>
                  <i>{getYearSpecificCopy(framework, 'indicatorRating.info')}</i>
                </small>
              </>
            }
          </AccountabilityDashboard.ScoreHeader>

          {
            isGaugeChart ?
              <div>
                {
                  gaugeScores.map(score => (activeSchoolLevels.includes(score.level) ?
                    <Field>
                      <hr />
                      <Heading size="base" level={2} className="cui-margin-bottom-large">{score.heading}</Heading>
                      <div className="gatewayGrid-4charts">
                        {

                          ratings.map(prop => (
                            prop.levels.includes(score.level) ?
                              <div>
                                <div className={styles.h100}>{prop.title}<InfoIcon text={prop.info} /></div>
                                <div className={styles.m1}>
                                  <Gauge
                                    key={score.level + prop.field}
                                    score={get(score, `${mapping}.${prop.field}`, '')}
                                    isLoading={get(score, 'isLoading', false)}
                                    labels={labels}
                                  />
                                </div>
                              </div> : ''
                          ))
                        }
                      </div>
                    </Field> : ''))
                }
              </div>
              :
              <AccountabilityDashboard.Scores>
                {
                  chartScores.map(({ scores, title, chartScoreKey }) => (
                    <ScoreBlock
                      title={title}
                      infoContent={getYearSpecificCopy(framework, `acctPopoverContent.${chartScoreKey}`)}
                      key={title}
                      label={`More information ${title}`}
                    >
                      {
                        scores.map(({ primary, secondary, cuts }) => (
                          <div key={primary.title}>
                            <ScoreBlock.Primary title={primary.title}>
                              <ScoreBar
                                score={primary.score}
                                rangeLow={cuts.rangeLow}
                                rangeHigh={cuts.rangeHigh}
                                scoreMax={cuts.max}
                                scoreBucket={primary.label}
                                scoreBucketInt={primary.colorIndex}
                                linkTo={primary.outboundLink}
                              />
                            </ScoreBlock.Primary>

                            <ScoreBlock.Secondary>
                              {
                                secondary && secondary.map(secondaryScore => (
                                  <ScoreBar
                                    key={secondaryScore.label}
                                    score={secondaryScore.score}
                                    rangeLow={cuts.rangeLow}
                                    rangeHigh={cuts.rangeHigh}
                                    scoreMax={cuts.max}
                                    scoreBucket={secondaryScore.label}
                                    scoreBucketInt={secondaryScore.colorIndex}
                                    size="small"
                                    linkTo={secondaryScore.outboundLink}
                                  />
                                ))
                              }
                              <ScaleBar theme="kde-accountability2" data={cuts.scale} hideLowHighLabel />
                            </ScoreBlock.Secondary>
                          </div>
                        ))
                      }
                    </ScoreBlock>
                  ))
                }
              </AccountabilityDashboard.Scores>
          }
        </AccountabilityDashboard>
      </PublicOrWithPermissions>
    </PageShell>
  );
}

SchoolAccountability.propTypes = {
  currentSchoolYear: PropTypes.number,
  scores: PropTypes.arrayOf(PropTypes.object),
  chartScores: PropTypes.arrayOf(PropTypes.object),
  gaugeScores: PropTypes.arrayOf(PropTypes.object),
  featureFlags: PropTypes.object,
  currentOrganization: PropTypes.object,
  scoresData: PropTypes.shape({
    isLoading: PropTypes.bool,
    scores: PropTypes.arrayOf(PropTypes.object)
  }),
  framework: PropTypes.object
};

function mapStateToProps(state) {
  return {
    currentSchoolYear: get(state, 'params.year'),
    chartScores: chartScoreSelector(state),
    gaugeScores: gaugeScoreSelector(state),
    schoolStat: schoolStatSelector(state),
    featureFlags: featureFlagsSelector(state),
    currentOrganization: currentOrganizationSelector(state),
    scoresData: makeSelectScoresData(),
    framework: get(state, 'framework.kde')
  };
}

export default connect(mapStateToProps)(SchoolAccountability);
/* eslint-disable no-unused-vars */
export const config = {
  title: pageTitle('School Accountability'),
  actions: buildLoadFrameworkAction({
    actions: [
      {
        ...LOAD_FEATURE_FLAGS_ACTION,
        actions: [
          {
            ...LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
            when: '{{!module.organizations}}'
          },
          {
            type: 'PLUCK_NODE_PATHS',
            pluckNodes(nodes = [], framework) {
              const schoolAccountabilityDomain = findNodeOfTypeBySlug(framework, 'school_accountability', 'domain');
              const dataPoints = get(schoolAccountabilityDomain, 'metadata.landingPage.dataPoints')
                .map(d => (d.dataNodePath ? findNodeByNodePath(framework, d.dataNodePath) : d));

              return {
                nodes: dataPoints.map(d => ({ ...d, ...d.metadata }))
              };
            },
            stateObjectMap: {
              currentNodes: 'nodes'
            },
            propMap: {
              framework: 'framework.kde',
              featureFlags: 'module.pageContext.featureFlags',
              currentOrganization: 'module.currentOrganization'
            },
            actions: [
              {
                ...LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS,
                actions: [
                  LOAD_CHILDREN_ORGS_ACTION,
                  {
                    type: 'FETCH_TOKEN',
                    service: 'svcpd',
                    slug: 'kde',
                    actions: [
                      scoreLoadFromPortalDataService
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  })
};
/* eslint-enable no-unused-vars */
