import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import template from '../../../helpers/template';

export const PercentFormatter = (props) => {
  const { value = '', templateString, valueKey, almostZeroValue } = props;
  let theValue = value;
  if (valueKey) {
    theValue = get(value, valueKey);
  }
  const percentAsIntString = (theValue * 100).toFixed(0);
  let templateValue = percentAsIntString;
  if (almostZeroValue && theValue !== 0 && percentAsIntString === '0') {
    templateValue = almostZeroValue; // ex: '< 1'
  }
  const compiledValue = template(templateString, { value: templateValue });
  if (theValue >= 0) {
    return (
      <span>
        {compiledValue}
      </span>
    );
  } else {
    return <i>No Data</i>;
  }
};

PercentFormatter.propTypes = {
  value: PropTypes.string,
  templateString: PropTypes.string,
  valueKey: PropTypes.string,
  almostZeroValue: PropTypes.string
};

PercentFormatter.defaultProps = {
  value: '',
  templateString: '{{value}}',
  valueKey: '',
  almostZeroValue: ''
};

export default PercentFormatter;
