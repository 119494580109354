import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';

export default function adapter({ currentOrgScore, chartConfig }) {
  let score = get(currentOrgScore, 'score');
  const valueKey = get(chartConfig, 'valueKey');
  const extractIntoScoreKey = get(chartConfig, 'extractIntoScoreKey');
  let additionalRows;

  if (extractIntoScoreKey) {
    let values = {};
    let numToDisplay = get(chartConfig, 'numToDisplay');
    const valueToExtract = get(score, extractIntoScoreKey, {});
    // extract items based on the numToDisplay option
    // numToDisplay can specify ordering and number of items
    // numToDisplay = 'desc-10', extract the first 10 when items are ordered desc.
    // or numToDisplay = '10', extract 10 items without ordering
    if (numToDisplay && !isEmpty(valueToExtract)) {
      numToDisplay = numToDisplay.trim().split('-');
      const sort = numToDisplay.length > 1 && numToDisplay[0];
      // convert the object into array of items
      values = reduce(valueToExtract, (memo, value, key) => {
        // Assuming value is a primitive here, string with digits only
        memo.push({ key, value: parseInt(value) || 0 });
        return memo;
      }, []);
      const numToExtract = parseInt(numToDisplay[numToDisplay.length - 1]);
      if (sort) {
        values = orderBy(values, ['value'], [sort]);
      }
      additionalRows = values.slice(0, numToExtract).map(v => v.key);
    }

    score = {
      ...score,
      ...valueToExtract
    };
    delete score[extractIntoScoreKey];
  }

  const result = reduce(score, (acc, value, key) => {
    acc[key] = { [valueKey]: value };
    return acc;
  }, {});

  return isEmpty(additionalRows) ? result : { score: result, additionalRows };
}
