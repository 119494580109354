import React from 'react';
import PropTypes from 'prop-types';

import isEmpty from 'lodash/isEmpty';

import Checkbox from 'cui/lib/components/Form/Checkbox';

import DynamicList from './DynamicList';

class OptionalDynamicList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showList: !isEmpty(props.values)
    };
  }

  componentWillReceiveProps = (nextProps) => {
    this.setState({ showList: !isEmpty(nextProps.values) });
  }

  toggle = (checked) => {
    this.setState({ showList: checked });
  }

  render() {
    return (
      <div>
        <Checkbox
          id={`field_other:${this.props.nodePath}:${this.props.sectionSlug}:${this.state.showList}`}
          label="Other"
          onChange={this.toggle}
          value={this.state.showList}
        />
        {this.state.showList && <DynamicList
          nodePath={this.props.nodePath}
          sectionSlug={this.props.sectionSlug}
          listChangeHandler={this.props.listChangeHandler}
          values={this.props.values}
          validations={this.props.validations}
        />}
      </div>
    );
  }
}

OptionalDynamicList.propTypes = {
  nodePath: PropTypes.string,
  sectionSlug: PropTypes.string,
  listChangeHandler: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.string
  ),
  validations: PropTypes.object
};

OptionalDynamicList.defaultProps = {
  values: []
};

export default OptionalDynamicList;
