import React from 'react';
import PropTypes from 'prop-types';
import TemplatePopover from './TemplatePopover';
import KdeDashboardBarChartPopover from './KdeDashboardBarChartPopover';

const POPOVERS = {
  default: TemplatePopover,
  KdeDashboardBarChartPopover
};

export default function DetailsPopover({ name, ...otherProps }) {
  const Popover = POPOVERS[name];
  return (<Popover {...otherProps} />);
}

DetailsPopover.propTypes = {
  name: PropTypes.string
};

DetailsPopover.defaultProps = {
  name: 'default'
};
