import get from 'lodash/get';
import { kdeCurrentSchoolYear } from '../../helpers/portals';

import { KDE_NEXT_YEAR_FG } from '../../constants';

export default (state, props) => {
  const nextYearEnabled = get(state, ['module', 'pageContext', 'featureFlags', KDE_NEXT_YEAR_FG], false);
  const currentYear = kdeCurrentSchoolYear();

  return {
    years: props.years.filter(year => year.value <= currentYear || nextYearEnabled)
  };
};
