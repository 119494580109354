import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'cui';

import Link from '../Link';

GatewayLink.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  disabled: PropTypes.bool,
  linkTo: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string
};

function GatewayLink({ title, description, disabled, linkTo, ariaLabel }) {
  return (
    <div className="gatewayLink">
      {
        disabled ? (
          <span className="gatewayLink-link gatewayLink-link_disabled">{title}</span>
        ) : (
          <h4>
            <Link className="gatewayLink-link" to={linkTo} aria-label={ariaLabel}>
              <span>{title}</span>
              <Icon name="bb-caret-right" />
            </Link>
          </h4>
        )
      }

      <p className="gatewayLink-description">
        {description}
      </p>
    </div>
  );
}

export default GatewayLink;
