export default ({ nodePath, dataIssues, status }) => ({
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  query: `
    mutation UpdateDataPointStatus(
     $organizationId: Int!
     $fiscalYear: Int!
     $nodePath: String!
     $status: String!
     $dataIssues: [DataIssueInputType]
   ) {
     update_section_status(
       organization_id: $organizationId
       fiscal_year: $fiscalYear
       section_slug: $nodePath
       status: $status
       data_issues: $dataIssues
     ) {
       sectionSlug: section_slug
       status
       dataIssues: data_issues {
         errorType: error_type
         comment
       }
     }
   }
  `,
  variables: {
    nodePath,
    status,
    dataIssues: dataIssues.map(el => ({ error_type: el.errorType, comment: el.comment }))
  },
  propMap: {
    fiscalYear: 'module.schoolYear',
    organizationId: 'module.currentOrganization.id'
  },
  propVariablesToVariables: ['organizationId', 'fiscalYear'],
  stateObjectMap: {
    dataPointStatuses: {
      method: (state, dataPointStatus) => [
        ...state.filter(record => record.sectionSlug !== dataPointStatus.sectionSlug),
        dataPointStatus
      ],
      dataKey: 'update_section_status'
    }
  }
});
