import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'cui/lib/components/Modal';
import Button from 'cui/lib/components/Button';

import find from 'lodash/find';
import DetailsList from '../../DetailsList';
import { findNodeByNodePath } from '../../../helpers/frameworkHelpers';
import template from '../../../helpers/template';

const DetailsModal = (props) => {
  const {
    detailItems,
    title,
    detailsModalTheme,
    isOpen,
    closeModal,
    modalSize,
    modalHeader,
    modalTitle,
    modalBodyTemplateString,
    listFilters,
    params,
    framework,
    detailsModalOverallMap,
    scoreKey
  } = props;

  const findCurrentNodeName = () => {
    const currentNode = find(listFilters, { nodePath: params.node_path });
    if (currentNode) {
      const reallyCurrentNode = findNodeByNodePath(framework, currentNode.nodePath);
      return currentNode ? template(currentNode.template, { ...props, ...reallyCurrentNode, value: '' }) : '';
    } else {
      return detailsModalOverallMap && detailsModalOverallMap[scoreKey.toLowerCase()];
    }
  };

  return (
    <Modal isOpen={isOpen} onHide={closeModal} size={modalSize}>
      <Modal.Header title={modalHeader} />
      <Modal.Body>
        <strong>{modalTitle}</strong>
        <div>
          { template(modalBodyTemplateString, props) }
        </div>
        <div className="cui-margin-bottom-large">
          { title }
          <br />
          {findCurrentNodeName()}
        </div>
        <DetailsList {...props} parentItems={detailItems} theme={detailsModalTheme} />
      </Modal.Body>
      <Modal.Footer className="cui-text_right">
        <Button kind="solid" theme="primary" onClick={closeModal}>Done</Button>
      </Modal.Footer>
    </Modal>
  );
};

DetailsModal.propTypes = {
  label: PropTypes.string,
  detailItems: PropTypes.arrayOf(
    PropTypes.object
  ),
  orgName: PropTypes.string,
  title: PropTypes.string,
  detailsModalTheme: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  modalSize: PropTypes.string,
  modalHeader: PropTypes.string,
  modalTitle: PropTypes.string,
  modalBodyTemplateString: PropTypes.string,
  listFilters: PropTypes.arrayOf(
    PropTypes.object
  ),
  params: PropTypes.object,
  framework: PropTypes.object,
  detailsModalOverallMap: PropTypes.object,
  scoreKey: PropTypes.string
};

DetailsModal.defaultProps = {
  label: '',
  detailItems: [],
  orgName: '',
  title: '',
  detailsModalTheme: '',
  isOpen: false,
  closeModal: () => {},
  modalSize: 'medium',
  modalHeader: '',
  modalTitle: '',
  modalBodyTemplateString: '',
  params: {},
  scoreKey: ''
};

export default DetailsModal;
