import get from 'lodash/get';
import isArray from 'lodash/isArray';
import concat from 'lodash/concat';
import isPlainObject from 'lodash/isPlainObject';
import merge from 'lodash/merge';
import isString from 'lodash/isString';
import unionBy from 'lodash/unionBy';
import set from 'lodash/set';

/* eslint-disable complexity */
const dataReducer = (state, action) => {
  const reducer = (mapped, key) => {
    if (key.match(/^\.\.\.(\w+)/)) {
      const stateKey = key.match(/^\.\.\.(\w+)/)[1];
      const stateData = state[stateKey];
      const newData = get(action.data, action.stateObjectMap[key]);
      if (isArray(stateData)) {
        return { ...mapped, [stateKey]: concat(stateData, newData) };
      } else if (isPlainObject(stateData) && isPlainObject(newData)) {
        return { ...mapped, [stateKey]: merge(newData, stateData) };
      } else if (isString(stateData) && isString(newData)) {
        return { ...mapped, [stateKey]: `${stateData}${newData}` };
      }
    }

    if (isPlainObject(action.stateObjectMap[key])) {
      const stateData = state[key];
      const config = action.stateObjectMap[key];
      const data = get(action.data, config.dataKey);
      // Call custom method to handle data
      if (typeof config.method === 'function') {
        return {
          ...mapped,
          [key]: config.method(stateData, data, action)
        };
      }

      if (config.method === 'union') {
        return { ...mapped, [key]: unionBy([].concat(data), stateData, config.idKey) };
      }
      // Merge action data into existing object
      if (config.method === 'merge') {
        const targetObject = { ...stateData };
        return {
          ...mapped,
          [key]: set(targetObject, config.path, data)
        };
      }
    }

    return {
      ...mapped,
      [key]: get(action.data, action.stateObjectMap[key])
    };
  };
  const mappedData = Object.keys(action.stateObjectMap || {}).reduce(reducer, {});

  return { ...state, ...mappedData };
};

export default dataReducer;
