import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

export default function adapter({ config, score }) {
  const valueKey = get(config, 'valueKey');
  let items = get(score, `value.score.${valueKey}`);
  const sortAsc = get(config, 'sortAsc');
  if (sortAsc) {
    items = sortBy(items, item => item.toLowerCase());
  }
  return items ? [{
    items
  }] : [];
}
