import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import isUndefined from 'lodash/isUndefined';

import template from '../../../template';
/* eslint-disable complexity */
const adapter = (options) => {
  const { currentOrgScore, config, legendPlacement, shape } = options;
  const LEGEND_THEME_DEFAULT = 'default';
  const LEGEND_PLACEMENT_DEFAULT = 'right';
  const WIDTH_DEFAULT = 200;
  const RADIUS_RATIO_DEFAULT = 0.55;
  const TITLE_DEFAULT = 'Chart';

  let scoreValue = get(currentOrgScore, 'value');

  if (config && config.defaultScoreKey) {
    scoreValue = get(currentOrgScore, `value[${config.defaultScoreKey}]`);
  }

  if (isUndefined(scoreValue)) return false;

  const total = shape && Object.keys(shape).reduce((sum, key) => sum + parseFloat(get(scoreValue, key, 0)), 0);

  const chartData = shape && sortBy(map(shape, (shapeConfig, key) => {
    const value = currentOrgScore && parseFloat(get(scoreValue, key, 0));
    const percentRounded = Math.round((value / total) * 100) || 0;
    let percentFormatted;
    // in case you want to show "< 1%" instead of (rounded down) "0%"
    if (percentRounded === 0 && value !== 0) {
      percentFormatted = '< 1';
    } else {
      percentFormatted = percentRounded;
    }

    return {
      ...shapeConfig,
      key,
      value,
      percent: percentRounded,
      percentFormatted,
      count: total
    };
  }), ['index']);

  if (config.showEmptyForZero) {
    const combinedPercentScore = reduce(chartData, (acc, datum) => (
      acc + datum.percent
    ), 0);

    if (combinedPercentScore === 0) {
      return false;
    }
  }

  const legendData = map(chartData, datum => (
    {
      colorIndex: datum.colorIndex,
      label: template(datum.label, { ...datum, ...options }),
      description: template(config.description, datum)
    }
  ));

  return {
    legendPlacement: legendPlacement || LEGEND_PLACEMENT_DEFAULT,
    theme: config.theme || LEGEND_THEME_DEFAULT,
    title: config.title || TITLE_DEFAULT,
    chartProps: {
      data: chartData,
      width: config.width || WIDTH_DEFAULT,
      radiusRatio: config.radiusRatio || RADIUS_RATIO_DEFAULT
    },
    legendProps: {
      data: legendData
    },
    className: `alchemy-donut ${config.chartClass || ''}`
  };
};
/* eslint-enable complexity */
export default adapter;
