import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

function DomainIcon({ domain, format = 'png' }) {
  const domainIconUrl = d => `//bb-production-assets.s3.amazonaws.com/kde/domainIcons/${d.slug}_icon.${format}`;
  return (
    <img className={styles.icon} src={domainIconUrl(domain)} alt="" role="presentation" data-test="DomainPanel-icon" />
  );
}

DomainIcon.propTypes = {
  domain: PropTypes.object,
  format: PropTypes.string
};

export default DomainIcon;
