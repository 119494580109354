import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import get from 'lodash/get';

import Heading from 'cui/lib/components/Heading';

import Link from '../components/Link';
import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import Footer from '../components/Footer';
import Masthead from '../components/Masthead';
import StaticPageContent from '../components/StaticPageContent';
import MainContentAnchor from '../components/MainContentAnchor';
import pageTitle from '../helpers/pageHelpers';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../helpers/scoreLoadActions';
import buildLoadFrameworkAction from '../actions/framework';

const Disclaimer = () => (
  <div>
    <ScrollToTopOnMount />
    <Masthead withSearchBar />
    <MainContentAnchor />
    <StaticPageContent>
      <div role="main">
        <Heading size="xxLarge" level={1}>Disclaimer</Heading>
        <div className="cui-textBlock">
          <p>
            No endorsement is intended or made of any hypertext link, product, service or information
            either by its inclusion or exclusion from any page within the website of the Kentucky
            Department of Education. While all attempts are made to ensure the correctness and
            suitability of information under our control and to correct any errors brought to our attention,
            no representation or guarantee can be made as to the correctness or suitability of that
            information or any linked information presented, referenced or implied. All critical
            information should be independently verified.
          </p>
          <p>Any questions or comments should be directed to our <Link to="/help" rel="noreferrer" target="_blank">Help page</Link>.</p>
          <p>
            The Kentucky Department of Education is an Equal Employment Opportunity/Affirmative Action Employer, M/F/D.
            <a target="_blank" rel="noopener noreferrer" href="https://brightbytes-public.s3.us-west-2.amazonaws.com/kde/KDE+Consolidated+Compliance+Plan+2021+(1).docx"> The Consolidated Nondiscrimination and Affirmative Action Compliance Plan</a> includes:
          </p>
          <ul>
            <li>
              USDA Nondiscrimination Statement
            </li>
            <li>
              2021 Title VI Implementation Update and Compliance Statement
            </li>
            <li>
              2021 Title IX Compliance Statement
            </li>
          </ul>
          <p> <a href="https://education.ky.gov/Pages/disclaimers.aspx">Additional disclaimer details</a> available at <a href="https://education.ky.gov">https://education.ky.gov.</a></p>
          <p>
            <em>
              The Kentucky Department of Education and the Kentucky Board of Education do not
              discriminate on the basis of race, color, national origin, sex, sexual orientation,
              religion, age or disability in employment or the provision of services.
            </em>
          </p>
        </div>
      </div>
    </StaticPageContent>
    <Footer />
  </div>
);

Disclaimer.propTypes = {
  framework: PropTypes.object
};

const mapStateToProps = state => ({
  framework: get(state, 'framework.kde')
});

export const config = {
  title: pageTitle('Disclaimer'),
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION
      ]
    })
  ]
};

export default withRouter(connect(mapStateToProps)(Disclaimer));
