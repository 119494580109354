import React from 'react';
import PropTypes from 'prop-types';

import styles from './AccountabilityDashboard.module.scss';

const Overview = ({ children }) => (
  <div className={`${styles.panel} ${styles.panel_lift}`}>
    { children }
  </div>
);

Overview.propTypes = {
  children: PropTypes.node
};

export default Overview;
