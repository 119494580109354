import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import List from 'cui/lib/components/List';
import ScoreDot from 'cui/lib/components/ScoreDot';
import template from '../../helpers/template';

function TemplatePopover(props) {
  const { config, data } = props;
  const optionsFromConfig = get(config, 'options.chartOptions', {});
  const itemConfig = get(optionsFromConfig, 'detailsPopover.items', []);
  let dataItems = get(data, 'value');
  // Render null when no data
  if (!dataItems) {
    return null;
  }
  // Normalize dataItems as array for easy processing
  dataItems = Array.isArray(dataItems) ? dataItems : [data];

  return (
    <div style={{ padding: '1rem' }}>
      <List>
        {
          dataItems.map(d => (
            itemConfig.map((item, i) => (
              <List.Item
                key={i}
                className="legend-item"
                icon={<ScoreDot className="legend-icon" type="solid" theme="kde-multi" value={d.colorIndex || i} />}
              >
                { item.titleTemplate && <div className="legend-label">{template(item.titleTemplate, d)}</div> }
                <div>
                  <span className="legend-value">{template(item.valueTemplate, { ...d, meta: data })}</span>
                  <span className="legend-label">
                    <em className="u-indent">
                      { item.labelTemplate ? template(item.labelTemplate, { ...d, meta: data }) : item.label }
                    </em>
                  </span>
                </div>
              </List.Item>
            ))
          ))
        }
      </List>
    </div>
  );
}

TemplatePopover.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object
};

export default TemplatePopover;
