import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';

import OrgSearch from '../OrgSearch';
import Branding from '../Branding';
import LanguageToggle from '../LanguageToggle';
// commenting out language toggle - since it will be available in 2019
// import LanguageSelector from '../LanguageSelector';
import YearSelect from '../YearSelect';
import { SUPPORTED_YEARS } from '../../constants';
import { setCurrentSchoolYear } from '../../store/actions';
import { isApprovalSite } from '../../helpers/approvalSite';

import styles from './index.module.scss';

const orgSearchPropMap = {
  organizations: 'module.organizations'
};

const Masthead = (props) => {
  const { featureFlags, onYearSelect, withSearchBar, sticky, year: currentYear } = props;

  const wrapperClassnames = cx({
    container: true,
    [styles.mastheadWrapper]: true,
    [styles['masthead--sticky']]: sticky
  });

  const showShoolYear = !(currentYear === 2023 && isApprovalSite());

  return (
    <header className={wrapperClassnames}>
      <div className={cx('container', styles.masthead)}>
        <div className={cx('container-constrained', styles.mastheadInner)}>
          <Branding />
          <div className={styles.mastheadRight} role="region">
            {/* <LanguageSelector /> */}
            <div className={styles.yearSelect}>
              { showShoolYear && <YearSelect
                initiallySelectedValue={currentYear}
                onSelect={onYearSelect}
                years={SUPPORTED_YEARS}
              /> }
            </div>
            {withSearchBar && (
              <OrgSearch
                linkRoute="/organization/{{ organization.id }}"
                propMap={orgSearchPropMap}
              />
            )}
            <LanguageToggle
              className={styles.languageToggle}
              featureFlags={featureFlags}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

Masthead.propTypes = {
  featureFlags: PropTypes.object,
  onYearSelect: PropTypes.func,
  withSearchBar: PropTypes.bool,
  year: PropTypes.number,
  sticky: PropTypes.bool
};

Masthead.defaultProps = {
  onYearSelect: () => {},
  withSearchBar: false,
  sticky: true
};

function mapStateToProps(state) {
  return {
    featureFlags: get(state, 'module.pageContext.featureFlags', {}),
    year: get(state, 'module.schoolYear')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onYearSelect: (year) => {
      dispatch(setCurrentSchoolYear(year));
      window.location.reload();
    }
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Masthead));
