import React from 'react';
import PropTypes from 'prop-types';

import styles from './ScoreBlock.module.scss';

const Secondary = ({ children }) => (
  <div className={styles.secondary}>
    { children }
  </div>
);

Secondary.propTypes = {
  children: PropTypes.node
};

export default Secondary;
