// for Some datapoint(courses offered), the aggregated value is duplicated in each dimension
// In order to display to aggregated total column for table, we need to extract the total out
// For example:
// const score = {
//   music: {
//     grade_1: { total: 100, grade_count: 20 },
//     grade_2: { total: 100, grade_count: 30 },
//     grade_3: { total: 100, grade_count: 50 }
//   },
//   math: {
//     grade_1: { total: 120, grade_count: 40 },
//     grade_2: { total: 120, grade_count: 30 },
//     grade_3: { total: 120, grade_count: 50 }
//   }
// }
//
// const chartConfig = {
//   options: {
//     extractTotal: 'total'
//   }
// }
//
// const result = {
//   music: {
//     total: { total: 100, grade_count: 20 },
//     grade_1: { total: 100, grade_count: 20 },
//     grade_2: { total: 100, grade_count: 30 },
//     grade_3: { total: 100, grade_count: 50 }
//   },
//   math: {
//     total: { total: 120, grade_count: 40 },
//     grade_1: { total: 120, grade_count: 40 },
//     grade_2: { total: 120, grade_count: 30 },
//     grade_3: { total: 120, grade_count: 50 }
//   }
// }

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

export default function adapter(currentOrgScore, { chartConfig }) {
  if (!isEmpty(currentOrgScore)) {
    const totalKey = get(chartConfig, 'options.extractTotal');

    return reduce(currentOrgScore, (memo, score, key) => {
      // If score is not empty object, copy a nested object used as total
      if (!isEmpty(score)) {
        const keys = Object.keys(score);
        memo[key] = { ...score, [totalKey]: score[keys[0]] };
      } else {
        memo[key] = score;
      }

      return memo;
    }, {});
  }

  return currentOrgScore;
}
