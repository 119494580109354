import React from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Modal from 'cui/lib/components/Modal';
import FlexBar from 'cui/lib/components/FlexBar';

const DeleteConfirmation = ({ isOpen, onCancel, onConfirm }) => (
  <Modal size="large" isOpen={isOpen} onHide={onCancel}>
    <Modal.Header title="Are you sure?" />
    <Modal.Body>
      <p>Are you sure you want to delete this flag?</p>
    </Modal.Body>
    <Modal.Footer>
      <FlexBar justify="right">
        <Button type="submit" title="Yes, Delete" kind="stroked" theme="secondary" onClick={onConfirm}>
          Yes, Delete
        </Button>

        <Button type="button" title="Cancel" kind="solid" theme="primary" onClick={onCancel}>
          Cancel
        </Button>
      </FlexBar>
    </Modal.Footer>
  </Modal>
);

DeleteConfirmation.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default DeleteConfirmation;
