import React from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const SuccessIndicatorLink = ({ successIndicatorData: { name, url } }) => (
  <div className="cui-margin-xSmall">
    <a
      className={styles.successIndicatorLink}
      disabled={!url}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  </div>
);

SuccessIndicatorLink.propTypes = {
  successIndicatorData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
  })
};

export default SuccessIndicatorLink;
