import get from 'lodash/get';
import reduce from 'lodash/reduce';
import each from 'lodash/each';
import pick from 'lodash/pick';
import forOwn from 'lodash/forOwn';
import assign from 'lodash/assign';
import uniq from 'lodash/uniq';
import when from '../../helpers/when';

import {
  calculatePercentage
} from '../../helpers/portalDataServiceHelpers';

/* eslint-disable complexity */
export default function adapter({ currentOrgScore, currentOrganization, chartConfig, viewingDropdownFilter }) {
  const checkEmptyTableKey = get(chartConfig, 'checkEmptyTableKey');

  if (checkEmptyTableKey) {
    if (!parseInt(get(currentOrgScore, `score.${checkEmptyTableKey}`))) {
      return {};
    }
  }

  const extractTo = get(chartConfig, 'extractTo');
  if (extractTo && when(extractTo.when, { currentOrganization })) {
    return extractTo.to ? { [extractTo.to]: get(currentOrgScore, extractTo.from) } : get(currentOrgScore, extractTo.from);
  }

  const nestedScoreKey = get(chartConfig, 'nestedScoreKey');
  let scoreKey = 'score';
  let dimensions = '';
  const extractKeys = get(chartConfig, 'extractKeys');
  if (Object.keys(viewingDropdownFilter).length > 1) {
    /* eslint-disable arrow-body-style */
    const dimensionsMap = Object.keys(viewingDropdownFilter).map((key) => {
      return `dimensions.${get(viewingDropdownFilter, key)}`;
    }).reverse().filter((e, i) => i !== 0);
    const scoreMap = Object.keys(viewingDropdownFilter).map((key) => {
      return `score.${get(viewingDropdownFilter, key)}`;
    }).reverse().filter((e, i) => i === 0);
    const dimension = reduce(dimensionsMap, (kay, index) => {
      return `${index}.${kay}`;
    });
    const score = reduce(scoreMap, (kay, index) => {
      return `${index}.${kay}`;
    });
    /* eslint-enable arrow-body-style */
    dimensions = dimension;
    scoreKey = score;
  } else if (get(viewingDropdownFilter, '0') && !get(chartConfig, 'dataDropdown.sliceSelect')) {
    scoreKey = `${scoreKey}.${get(viewingDropdownFilter, '0')}`;
  }

  if (nestedScoreKey) {
    scoreKey = `${scoreKey}.${nestedScoreKey}`;
  }
  const dimensionKeyFromConfig = get(chartConfig, 'dimensionKey');
  // Extra dimensions and put them at the top level
  let result = reduce(get(currentOrgScore, dimensions ?  `${dimensions}.dimensions` : 'dimensions', {}), (acc, rowScore, rowKey) => {
    let score = rowScore;

    if (extractKeys) {
      const scoreFromKeys = {
        dimensions: {}
      };

      let grades = [];

      forOwn(extractKeys, (value, key) => {
        const allKeyScores = get(score, (dimensionKeyFromConfig || `dimensions.${key}.dimensions`));
        value.forEach((val) => {
          const pickScore = pick(allKeyScores, val);
          if (pickScore[val] && pickScore[val].score) {
            assign(scoreFromKeys.dimensions, pickScore);
          }
        });

        grades = grades.concat(value);
      });

      uniq(grades).forEach((grade) => {
        if (!get(scoreFromKeys.dimensions, grade)) {
          assign(scoreFromKeys.dimensions, { [grade]: { dimensions: undefined, score: {} } });
        }
      });

      score = scoreFromKeys;
    }

    acc[rowKey] = chartConfig.columns_are_dimensions ? reduce(get(score, 'dimensions', {}), (memo, colValue, colKey) => {
      memo[colKey] = colValue;
      return memo;
    }, {}) : get(score, scoreKey);
    return acc;
  }, {});

  // Add aggregation score at the top level when totalKey is specified.
  const totalKey = get(chartConfig, 'totalKey');
  if (totalKey) {
    result[totalKey] = get(currentOrgScore,  dimensions ?  `${dimensions}.${scoreKey}` : `${scoreKey}`, {});
  }

  if (get(chartConfig, 'options.invert_data')) {
    const invertedResult = {};
    each(result, (innerValues, outerKey) => {
      each(innerValues, (value, innerKey) => {
        invertedResult[innerKey] = invertedResult[innerKey] || {};
        invertedResult[innerKey][outerKey] = value;
      });
    });
    result = invertedResult;
  }
  if (extractTo) {
    result[extractTo.to] = get(currentOrgScore, extractTo.from, {});
  }

  return calculatePercentage(chartConfig, result);
}
