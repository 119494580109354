/**
 * Invert nested objects
 * Example:
 * score = {
 *   kentucky: {
 *     iep: { count: 1 },
 *     lep: { count: 2 }
 *   },
 *   nation: {
 *     iep: { count: 3 },
 *     lep: { count: 4 }
 *   }
 * }
 *
 * it return:
 * {
 *   iep: {
 *     kentucky: { count: 1 },
 *     nation: { count: 3 }
 *   },
 *   lep: {
 *     kentucky: { count: 2 },
 *     nation: { count: 4 }
 *   }
 * }
 **/

import each from 'lodash/each';

export default function adapter(currentOrgScore) {
  const invertedResult = {};
  // This logics probably is used in multiple modules
  // TODO maybe move it to helpers
  each(currentOrgScore, (innerValues, outerKey) => {
    each(innerValues, (value, innerKey) => {
      invertedResult[innerKey] = invertedResult[innerKey] || {};
      invertedResult[innerKey][outerKey] = value;
    });
  });

  return invertedResult;
}
