import filter from 'lodash/filter';
import find from 'lodash/find';
import head from 'lodash/head';
import merge from 'lodash/merge';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import sum from 'lodash/sum';
import map from 'lodash/map';
import fpMap from 'lodash/fp/map';
import flow from 'lodash/fp/flow';
import fpSortBy from 'lodash/fp/sortBy';
import { intervalToDuration, getDaysInMonth, isWithinInterval } from 'date-fns';

const mapper = fpMap.convert({ cap: false });

const filterYearTimeSlices = (scores, organization) => filter(scores, (score) => {
  const start = score.start_at ? new Date(score.start_at) : new Date();
  const end = score.end_at ? new Date(score.end_at) : new Date();
  const duration = intervalToDuration({
    start,
    end
  });
  const timespan = duration.years * 12 + duration.months + duration.days / getDaysInMonth(end);

  const orgId = get(organization, 'id');
  return Math.round(timespan) === 12 && score.remote_organization_id === orgId;
});

export const scoreInTimeSliceRange = (scoreStartDate, scoreEndDate, selectedTimeSlice) => {
  if (selectedTimeSlice && selectedTimeSlice.startAt && selectedTimeSlice.endAt) {
    const startDate = new Date(selectedTimeSlice.startAt);
    const endDate = new Date(selectedTimeSlice.endAt);
    return isWithinInterval(new Date(scoreStartDate), {
      start: startDate,
      end: endDate
    }) && isWithinInterval(new Date(scoreEndDate), {
      start: startDate,
      end: endDate
    });
  }

  return true;
};

const filterMonthlyTimeSlices = (scores, organization, selectedTimeSlice) => filter(scores, (score) => {
  const start = score.start_at ? new Date(score.start_at) : new Date();
  const end = score.end_at ? new Date(score.end_at) : new Date();
  const duration = intervalToDuration({
    start,
    end
  });
  const timespan = duration.years * 12 + duration.months + duration.days / getDaysInMonth(end);

  return Math.round(timespan) === 1 && score.remote_organization_id === organization.id && scoreInTimeSliceRange(start, end, selectedTimeSlice);
});

export const currentOrgYearScores = (scores, organization, node = {}, selectedTimeSlice) => {
  const queryObject = {};
  if (!isEmpty(node)) {
    queryObject.framework_tree_node = { node_path: node.portal_data ? node.node_path : (node.data_node_path || node.node_path) };
  }
  if (selectedTimeSlice && selectedTimeSlice.startAt && selectedTimeSlice.endAt) {
    queryObject.start_at = selectedTimeSlice.startAt;
    queryObject.end_at = selectedTimeSlice.endAt;
  }

  const orgScores = filter(scores, queryObject);
  return head(filterYearTimeSlices(orgScores, organization));
};

export const generateScoreValue = (item, scores, organization, options = {}, selectedTimeSlice) => {
  const nodePath = item.score_node_path || item.node_path;
  const scoresQueryObject = merge({ framework_tree_node: { node_path: nodePath } }, options);
  const nodeScores = filter(scores, scoresQueryObject);

  return get(currentOrgYearScores(nodeScores, organization, undefined, selectedTimeSlice), 'value') || get(nodeScores[0], 'value');
};

export const monthlyOrgScores = (node, scores, organization, selectedTimeSlice) => {
  const nodeScores = filter(scores, { framework_tree_node: { node_path: node.node_path } });
  return sortBy(filterMonthlyTimeSlices(nodeScores, organization, selectedTimeSlice), ['start_at']);
};

export const yearlyOrgScores = (node, scores, organization) => {
  const nodeScores = filter(scores, { framework_tree_node: { node_path: node.node_path } });
  return sortBy(filterYearTimeSlices(nodeScores, organization), ['start_at']);
};

export const findHighestValue = (score, valKey) => {
  let highest = Number.MIN_SAFE_INTEGER;
  let numberVal;
  forEach(score.value, (value, key) => {
    const realVal = (valKey ? value[valKey] : value);
    if (realVal > highest) {
      highest = realVal;
      numberVal = key;
    }
  });
  return numberVal;
};

const sortByRemainder = (entry) => {
  const value = Object.values(entry)[0];
  return Math.round(value) - value;
};

const getEntriesFromShape = (scoreValue, shape, multiplier) => map(shape, (value, key) => ({ [key]: scoreValue[key] * multiplier }));

const getPercentageOffset = entries => 100 - sum(entries.map(item => Math.round(Object.values(item)[0])));

/* eslint-disable no-mixed-operators */
const calculatePercentages = (entries, multiplier) => {
  const offset = getPercentageOffset(entries);
  const applyOffset = (entry, i) => {
    const value = Object.values(entry)[0];
    const rounded = (
      Math.round(value) +
      (offset > i ? 1 : 0) -
      (i >= (entries.length + offset) ? 1 : 0));
    entry[Object.keys(entry)[0]] = (rounded / multiplier);
    return entry;
  };

  return flow(
    fpSortBy(sortByRemainder),
    mapper(applyOffset)
  )(entries);
};
/* eslint-enable no-mixed-operators */

export const roundPercentages = (score, shape, isProportion) => {
  const multiplier = isProportion ? 100 : 1;
  const scoreEntries = getEntriesFromShape(score.value, shape, multiplier);
  const rounded = calculatePercentages(scoreEntries, multiplier);
  score.value = merge(score.value, ...rounded);
  return score;
};

export const getValueFromScores = (scores = {}, organizationId = null, node = {}) => {
  const { value } = find(scores, {
    remote_organization_id: organizationId,
    framework_tree_node: {
      node_path: node.data_node_path
    }
  }) || find(scores, {
    remote_organization_id: organizationId,
    framework_tree_node: {
      node_path: node.node_path
    }
  }) || {};

  return value;
};

export default currentOrgYearScores;
