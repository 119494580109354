import { useRef, useState } from 'react';

const alertMessages = {
  update: 'District message successfully saved.',
  delete: 'District message successfully deleted.',
  error: 'Operation has not been completed.'
};

const useAlert = () => {
  const [alertType, setAlertType] = useState();
  const timerId = useRef();

  const hideAlertWithDelay = () => {
    if (timerId.current) clearTimeout(timerId.current);
    timerId.current = setTimeout(() => setAlertType(undefined), 5000);
  };

  const displayAlert = value => {
    setAlertType(value);
    hideAlertWithDelay();
  };

  return { alertType, alertMessage: alertMessages[alertType], displayAlert };
};

export default useAlert;
