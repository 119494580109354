import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading } from 'cui';

import Link from '../Link';
import PanelHeader from '../../ui/PanelHeader';

GatewayChart.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  description: PropTypes.string,
  wideChart: PropTypes.bool,
  hideBorder: PropTypes.bool,
  hideTopMargin: PropTypes.bool,
  size: PropTypes.oneOf(['', 'doublewide']),
  title: PropTypes.string,
  popoverText: PropTypes.string,
  popoverPlacement: PropTypes.string,
  label: PropTypes.string,
  linkUrl: PropTypes.string,
  linkLabel: PropTypes.string
};

export function GatewayChart({
  title,
  description,
  wideChart,
  hideBorder,
  hideTopMargin,
  popoverText,
  popoverPlacement,
  children,
  label,
  linkUrl,
  linkLabel
}) {
  const itemClassNames = cx({
    'gatewayGrid-item': !wideChart,
    'gatewayGrid-item_double': wideChart,
    'gatewayGrid-item_hideBorder': hideBorder
  });

  const chartClassNames = cx({
    'gatewayChart-chart': true,
    'gatewayChart-chart_hideTopMargin': hideTopMargin
  });
  const footerClassNames = cx({
    'gatewayChart-footer': true,
    'gatewayChart-footer_hideTopMargin': hideTopMargin
  });

  return (
    <div className={itemClassNames}>
      <div className="gatewayChart">
        {
          title && (
            <PanelHeader
              title={title}
              popoverPlacement={popoverPlacement}
              popoverContent={popoverText}
              label={label}
              headingLevel={4}
              headingClass="cui-text_cap"
            />
          )
        }
        {description && <Heading className="gatewayChart-subtitle" level={5} size="xSmall">{description}</Heading>}
        <div className={chartClassNames}>
          {children}
        </div>
        <div>
          {
            linkUrl &&
            <div className={footerClassNames}>
              <Link to={linkUrl}>{linkLabel}</Link>
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default GatewayChart;
