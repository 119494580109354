import compact from 'lodash/compact';
import get from 'lodash/get';
import getName from '../../helpers/nameHelper';

export default function adapter(score, { config, node }) {
  // percentageNumeratorKey is key we want to use to compute percentage
  const percentageNumeratorKey = get(config, 'percentageNumeratorKey');

  // render empty state if no percentageNumeratorKey is specified
  if (!percentageNumeratorKey) {
    return {
      chartData: []
    };
  }

  let scoreKey = get(config, 'options.scoreKey');
  // Get list of dimensions that we want to show as segments within a bar
  scoreKey = Array.isArray(scoreKey) ? scoreKey : [scoreKey];
  const scoreForKeys = compact(scoreKey.map(key => (score[key] ? { ...score[key], key } : undefined)));
  // Get list of subjects or events that we want to show as a bar
  const subjects = get(node, 'metadata.scoring_options.subjectKeys', []);
  const showLegend = get(config, 'options.showLegend');
  const labelMap = get(node, 'scoring_options.labelMap');
  let legendData;
  // If showLegend is true, generate an array of labels from the dimensions
  if (showLegend) {
    legendData = scoreForKeys.map(scoreForKey => ({ label: getName(null, scoreForKey.key, scoreForKey.key) }));
  }

  return {
    chartData: subjects.map((subject) => {
      // Sum values given by percentageNumeratorKey so that we can compute percentage for each dimension
      const total = scoreForKeys.reduce((sum, value) => (sum + get(value, [subject, percentageNumeratorKey], 0)), 0);

      return {
        value: scoreForKeys.map((scoreForKey, i) => ({
          label: getName(null, scoreForKey.key, scoreForKey.key),
          value: total > 0 ? (get(scoreForKey, [subject, percentageNumeratorKey], 0) / total) * 100 : 0,
          colorIndex: i,
          ...get(scoreForKey, subject, {})
        })),
        label: getName(labelMap, subject, subject)
      };
    }),
    legendData
  };
}
