import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import template from '../../helpers/template';
import getName from '../../helpers/nameHelper';
import getChartLegendIcon from '../../helpers/getChartLegendIcon';

function getChartLegendData(chartData) {
  return chartData.map(({ name, colorIndex, symbol }, i) => ({
    colorIndex: colorIndex !== undefined ? colorIndex : i,
    icon: getChartLegendIcon(symbol),
    label: name
  }));
}

function formatChartDataForLine(line, lineValues, { chartConfig, viewingDropdownFilter }) {
  const { name, detailsPopover, ...lineOptions } = line;

  const labelMap = get(chartConfig, 'options.labelMap');
  const popoverSettings = get(chartConfig, 'options.chartOptions.detailsPopover.items', []);
  const lineName = getName(labelMap, name, name);
  const popoverSettingsForLine = detailsPopover || popoverSettings;

  return {
    name: lineName,
    showLine: true,
    showPoints: true,
    ...lineOptions,
    values: reduce(lineValues, (values, y, x) => {
      if (!isFinite(x) || !isFinite(y)) return values;

      const popover = popoverSettingsForLine.map(item => ({
        title: template(item.titleTemplate, { name: lineName, value: y, xValue: x, dropdownData: viewingDropdownFilter }),
        value: template(item.valueTemplate, { name: lineName, value: y, xValue: x, dropdownData: viewingDropdownFilter })
      }));

      values.push({ x, y, popover });
      return values;
    }, [])
  };
}

/**
 * It takes an nested object and return chartData and legendData that are ready for line chart to use
 * Example:
 * score = {
 *   elementary_school: {
 *     goal: {
 *       2019: '5.6',
 *       2020: '9.9',
 *       2021: '14.2',
 *       2022: '18.5',
 *       2023: '22.8',
 *       2024: '27.1',
 *       2025: '31.3',
 *       2026: '35.6',
 *       2027: '39.9',
 *       2028: '44.2',
 *       2029: '48.5',
 *       2030: '52.8'
 *     },
 *     actual: {
 *       2019: '16.0'
 *     }
 *   }
 * }
 * chartConfig = {
 *   options: {
 *     lines: [{ name: 'goal' }, { name: 'actual', symbol: 'triangle-up' }],
 *     labelMap: { goal: 'Goal Performance', actual: 'Actual Performance' },
 *     showLegend: true
 *   }
 * }
 * viewingDropdownFilter = {0: 'elementary_school}
 * It requires chart config options lines to specify data lines to be displayed.
 * Optional labelMap to lookup the label for lines keys
 * It returns:
 * {
 *   chartData: [
 *     {
 *       name: 'Goal Performance',
 *       showLine: true,
 *       showPoints: true,
 *       values: [
 *        { x: '2019', y: '5.6', popover: [] },
 *        { x: '2020', y: '9.9', popover: [] },
 *        { x: '2021', y: '14.2', popover: [] },
 *        { x: '2022', y: '18.5', popover: [] },
 *        { x: '2023', y: '22.8', popover: [] },
 *        { x: '2024', y: '27.1', popover: [] },
 *        { x: '2025', y: '31.3', popover: [] },
 *        { x: '2026', y: '35.6', popover: [] },
 *        { x: '2027', y: '39.9', popover: [] },
 *        { x: '2028', y: '44.2', popover: [] },
 *        { x: '2029', y: '48.5', popover: [] },
 *        { x: '2030', y: '52.8', popover: [] }
 *       ]
 *     },
 *     {
 *       name: 'Actual Performance',
 *       showLine: true,
 *       showPoints: true,
 *       symbol: 'triangle-up'
 *       values: [
 *        { x: '2019', y: '5.6', popover: [] }
 *       ]
 *     }
 *   ],
 *   legendData: [
 *     { label: 'Goal Performance' },
 *     { label: 'Actual Performance' }
 *   ]
 * }
 **/

export default function adapter(currentOrgScore, { chartConfig, viewingDropdownFilter }) {
  let chartData = currentOrgScore;
  const lines = get(chartConfig, 'options.lines', []);
  const scoreKeys = Object.values(viewingDropdownFilter);

  if (!isEmpty(currentOrgScore) && !isEmpty(lines) && !isEmpty(scoreKeys)) {
    chartData = map(lines, line =>
      formatChartDataForLine(line, get(currentOrgScore, [...scoreKeys, line.name]), { chartConfig, viewingDropdownFilter })
    );
  }

  // When showLegend is true, return chartData and legendData as well
  if (get(chartConfig, 'options.showLegend')) {
    return {
      chartData,
      legendData: getChartLegendData(chartData)
    };
  }

  return { chartData };
}
