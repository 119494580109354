import React from 'react';
import PropTypes from 'prop-types';

import InfoPopover from 'cui/lib/components/InfoPopover';
import Icon from 'cui/lib/components/Icon';
import FlexBar from 'cui/lib/components/FlexBar';

import Markdown from '../Markdown';
import { isApprovalSite } from '../../helpers/approvalSite';
import DataPointFlagModal from '../DataPointFlagModal';
import styles from '../../ui/PanelHeader/PanelHeader.module.scss';
import DownloadLink from '../DownloadLink';

import useDataSetQuery from '../../store/localQueries/datasets';

const getDownloadItems = (schoolYear, nodePath) => {
  const { data } = useDataSetQuery(schoolYear, nodePath);
  return !isApprovalSite() && data;
};

const Title = ({ slug, nodePath, headerTitle, headerIcon, showPopover, popoverText, headerRef, schoolYear }) => {
  const downloadItems = getDownloadItems(schoolYear, nodePath);

  return (
    <FlexBar>
      <FlexBar.Item>
        <h3 id={slug} className="cui-dataPointPanel-heading" ref={headerRef}>
          {headerIcon && <Icon name={headerIcon} size="xLarge" className="cui-btn-icon" />}
          {headerTitle}
        </h3>

        {showPopover && popoverText && (
          <InfoPopover
            content={<Markdown body={popoverText} />}
            popoverProps={{ popoverTopIndent: 250, appendToBody: false }}
            label={`More information ${headerTitle}`}
            className={styles.info}
          />
        )}
      </FlexBar.Item>

      {downloadItems?.length > 0 && (
        <FlexBar.Item>
          <DownloadLink downloadItems={downloadItems} />
        </FlexBar.Item>
      )}

      {isApprovalSite() && (
        <FlexBar.Item>
          <DataPointFlagModal nodePath={nodePath} />
        </FlexBar.Item>
      )}
    </FlexBar>
  );
};

Title.propTypes = {
  nodePath: PropTypes.string,
  headerRef: PropTypes.func.isRequired,
  slug: PropTypes.string.isRequired,
  showPopover: PropTypes.bool,
  popoverText: PropTypes.string,
  headerTitle: PropTypes.string,
  headerIcon: PropTypes.string,
  schoolYear: PropTypes.number
};

Title.defaultProps = {
  popoverText: '',
  headerTitle: ''
};

export default Title;
