import React from 'react';
import PropTypes from 'prop-types';

import List from 'cui/lib/components/List';

import Link from '../../components/Link';
import styles from './AchievementGaps.module.scss';

const AchievementGaps = ({ data, linkTo }) => (
  <div>
    { data.length > 0 &&
      <List className={styles.gapList} size="small">
        { data.map((gap, i) => (
          <List.Item key={i}>
            <Link to={linkTo} className="cui-btn">
              <div>{gap.gap}</div>
              <div className={styles.ctrlGroup}>compared to {gap.ctrlGroup}</div>
            </Link>
          </List.Item>
        ))}
      </List>
    }
    { data.length === 0 &&
      <small>No significant gaps</small>
    }
  </div>
);

AchievementGaps.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      gap: PropTypes.string.isRequired,
      ctrlGroup: PropTypes.string.isRequired
    })
  ),
  linkTo: PropTypes.string,
  attrs: PropTypes.object
};

export default AchievementGaps;
