/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import connected from '../connected';

const pinIcon = decodeSvg(require('../../images/bb-location-pin.svg'));

function decodeSvg(svg) {
  if (isEmpty(svg)) return '';

  const parsedSvg = JSON.parse(svg);
  const firstComma = parsedSvg.indexOf(',');
  return decodeURIComponent(parsedSvg.substr(firstComma + 1));
}

// KDE stores `latitude` and `longitude` in `organization`
// The rest of modules store them in `entity`
function organizationLocation(organization) {
  const latitude = parseFloat(organization.latitude) || get(organization, 'entity.lat_centroid');
  const longitude = parseFloat(organization.longitude) || get(organization, 'entity.long_centroid');

  return {
    latitude,
    longitude
  };
}

function markerIconMeta(organization) {
  return {
    className: `map-icon ${organization.entity_type}-map-icon`,
    iconSize: [35, 35],
    html: `
      <div
        data-test='map-icon'
        style='position: relative;'
        data-organization-id='${organization.id}'
        data-entity-type='${organization.entity_type}'
      >
        ${pinIcon}
      </div>
    `
  };
}

export class HeaderBlockMap extends React.Component {
  componentDidMount() {
    const { latitude, longitude } = organizationLocation(this.props.organization);
    const L = require('mapbox.js');
    this.L = L;
    require('leaflet.markercluster');
    L.mapbox.accessToken = process.env.MAPBOX_TOKEN;
    this.map = L.mapbox.map(this.mapContainerRef, 'elyngved.map-d3lj8i3v', Object.assign({
      zoom: this.props.mapZoom,
      center: [
        latitude,
        longitude
      ]
    }, this.props.mapOptions));
    L.mapbox.styleLayer('mapbox://styles/mapbox/streets-v11').addTo(this.map);
    // Add marker to the map using the current organization's geolocation
    if (this.props.organization.entity_type !== 'State' && latitude && longitude) {
      this.locationMarker = L.marker([latitude, longitude], {
        icon: L.divIcon(markerIconMeta(this.props.organization))
      });
      this.locationMarker.addTo(this.map);
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps.organization !== this.props.organization) {
      // Update the location pin when organization is changed
      const { latitude, longitude } = organizationLocation(newProps.organization);
      if (this.map) {
        this.map.setView([latitude, longitude], newProps.mapZoom);
      }
      if (this.locationMarker) {
        this.locationMarker.setLatLng([latitude, longitude]);
        this.locationMarker.setIcon(this.L.divIcon(markerIconMeta(newProps.organization)));
      }
    }
  }

  componentWillUnmount() {
    // Destroy map before component is unmounted.
    return this.map && this.map.remove();
  }

  render() {
    return (
      <div style={{ display: 'block', position: 'relative', height: '100%', minHeight: 220 }}>
        <div
          id="map"
          ref={(ref) => { this.mapContainerRef = ref; }}
          className="mapSlab-map leaflet-container leaflet-retina leaflet-fade-anim"
          style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, borderRadius: '0.5rem', zIndex: 1 }}
        />
      </div>
    );
  }
}

HeaderBlockMap.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  organization: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    entity: PropTypes.shape({
      lat_centroid: PropTypes.number,
      long_centroid: PropTypes.number
    }),
    entity_type: PropTypes.string
  }).isRequired,
  mapZoom: PropTypes.number.isRequired,
  mapOptions: PropTypes.shape({
    zoomControl: PropTypes.bool,
    dragging: PropTypes.bool,
    scrollWheelZoom: PropTypes.bool,
    touchZoom: PropTypes.bool,
    doubleClickZoom: PropTypes.bool,
    keyboard: PropTypes.bool
  }).isRequired
};

export default connected(withRouter(HeaderBlockMap));
