import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

import template from '../../../template';

const adapter = (options) => {
  const { node, scores } = options;
  const scoringOptions = node.scoring_options;
  const shape = scoringOptions.shape;
  const data = map(scores, (score, key) => ({
    label: template(scoringOptions.labelTemplate, { value: '', ...score, startCase }),
    subLabel: template(scoringOptions.subLabelTemplate, { value: '', ...score }),
    key,
    value: `${get(score, 'percent', score)}`,
    colorIndex: get(shape, `${scoringOptions.shapeKey}.colorIndex`, 0),
    confidenceInterval: get(score, 'confidenceInterval', undefined),
    index: get(shape, `${scoringOptions.shapeKey}.index`, null)
  }));

  return sortBy(data, ['index']);
};

export default adapter;
