import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import some from 'lodash/some';

import Field from 'cui/lib/components/Form/Field';

import FederalClassificationExplainer from '../FederalClassificationExplainer';
import schoolAccountabilityPageLink from '../StarRatingPanel/schoolAccountabilityPageLink';
import Gauge from '../../ui/Gauge';
import PanelHeader from '../../ui/PanelHeader';
import { getYearSpecificCopy } from '../../helpers/frameworkTreeTraversalHelper';
import { isSchool } from '../../helpers/completionStatusHelpers/collection/utils';
import SchoolLevelExplanationGauge from './SchoolLevelExplanationGauge';
import AccountabilityLegend from '../../ui/AccountabilityLegend';

const starRatingPresent = schoolStat => some(schoolStat, stat => get(stat, 'rating.star'));
const legendData = [
  { label: 'Red (Lowest Performance)' },
  { label: 'Orange' },
  { label: 'Yellow' },
  { label: 'Green' },
  { label: 'Blue (Highest Performance)' }];

const GaugeRatingPanel = ({
  featureFlags,
  federalClassification,
  framework,
  organization,
  schoolStat,
  year,
  headingLevel,
  isComaprePage,
  isPreviewSite,
  showViewAccountability,
  isLoading
}) => (
  <div>
    <PanelHeader
      headingLevel={headingLevel}
      title={getYearSpecificCopy(framework, 'starRating.title') || 'Gauge Rating'}
      linkTo={
        showViewAccountability &&
        (starRatingPresent(schoolStat) || year < 2019) &&
        schoolAccountabilityPageLink(featureFlags, year, organization.id, isPreviewSite)
      }
      linkLabel={showViewAccountability && 'View Accountability Data'}
      popoverContent={getYearSpecificCopy(framework, 'starRating.popover')}
      label="More information star rating"
    />
    <AccountabilityLegend data={legendData} />
    <small>
      <i>{getYearSpecificCopy(framework, 'starRating.info')}</i>
    </small>
    <hr />
    <div className={isComaprePage ? '' : 'gatewayGrid-fullcharts'}>
      {starRatingPresent(schoolStat) ? (
        schoolStat.map(stat => (get(stat, 'rating.star') ?
          <SchoolLevelExplanationGauge
            className="gatewayGrid-item"
            stat={stat}
            popoverContent={getYearSpecificCopy(
              framework,
              'fedClassification.popover'
            )}
            isLoading={isLoading}
          /> : ''))
      ) : (
        <div>
          <Field label="">
            <div className="flexRow flexRow--nowrap">
              <Gauge
                message={getYearSpecificCopy(framework, 'starRating.empty')}
                emptyState={getYearSpecificCopy(framework, 'starRating.emptyState')}
                isLoading={isLoading}
              />
            </div>
          </Field>
          {isSchool(organization) && (
            <Field label="">
              <FederalClassificationExplainer
                federalClassification={federalClassification}
                popoverContent={getYearSpecificCopy(
                  framework,
                  'fedClassification.popover'
                )}
                modalNotes={getYearSpecificCopy(framework, 'fedClassification.notes')}
              />
            </Field>
          )}
          {get(organization, 'no_star_rating_assessment') && (
            <Field label="">
              {getYearSpecificCopy(framework, 'starRating.no_assessment')}
            </Field>
          )}
        </div>
      )}
    </div>
  </div>
);

GaugeRatingPanel.defaultProps = {
  isComaprePage: false,
  showViewAccountability: true
};

GaugeRatingPanel.propTypes = {
  featureFlags: PropTypes.object,
  federalClassification: PropTypes.object,
  framework: PropTypes.object,
  organization: PropTypes.object,
  schoolStat: PropTypes.arrayOf(PropTypes.object),
  year: PropTypes.number,
  headingLevel: PropTypes.number,
  isComaprePage: PropTypes.bool,
  isPreviewSite: PropTypes.bool,
  showViewAccountability: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default GaugeRatingPanel;
