import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Modal from 'cui/lib/components/Modal';

class SuperIntendentMessageModal extends PureComponent {
  handleKeyPress = e => {
    e.preventDefault();
    if (e.keyCode === 9) {
      const closeButtons = document.getElementsByClassName('cui-modal-close');
      if (closeButtons && closeButtons.length > 0) {
        const closeButton = closeButtons[0];
        if (closeButton) {
          closeButton.focus();
        }
      }
      window.removeEventListener('keydown', this.handleKeyPress);
    }
  };

  render() {
    const { districtMessage, isOpen, onHide } = this.props;

    if (isOpen) {
      window.addEventListener('keydown', this.handleKeyPress);
    } else {
      window.removeEventListener('keydown', this.handleKeyPress);
    }

    return (
      <Modal isOpen={isOpen} onHide={onHide}>
        <Modal.Header title="A Message from the Superintendent" />
        <Modal.Body>{districtMessage?.body && <p>{districtMessage?.body}</p>}</Modal.Body>
      </Modal>
    );
  }
}

SuperIntendentMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  districtMessage: PropTypes.shape({
    body: PropTypes.string,
    author: PropTypes.string,
    title: PropTypes.string
  })
};

export default SuperIntendentMessageModal;
