import React from 'react';
import get from 'lodash/get';

import EmptyState from 'cui/lib/components/EmptyState';

const KdePlaceholder = props => (
  <EmptyState
    kind="text"
    title={get(props, 'config.options.text', 'There is no data available.')}
  />
);

export default KdePlaceholder;
