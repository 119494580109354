import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import connected from '../connected';
import propMapper from './propMapper';

import DomainDataPoints from '../DomainDataPoints';

/**
 * Wrapper around `DomainDataPoints` that finds the data points to render
 */
export const DataPointsPage = props => (
  <div {...props.attrs}>
    <DomainDataPoints {...props} />
  </div>
);

DataPointsPage.propTypes = {
  development: PropTypes.bool,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  framework: PropTypes.object,
  currentNode: PropTypes.object,
  domainSlug: PropTypes.string,
  successIndicatorSlug: PropTypes.string,
  variableSlug: PropTypes.string,
  additionalChartAdapters: PropTypes.object,
  additionalChartTypes: PropTypes.object,
  attrs: PropTypes.object
};

DataPointsPage.defaultProps = {
  development: String(process.env.DEPLOY_ENV).toLowerCase() === 'development', // This probably would have to be removed after FIN for public launches
  featureFlags: {},
  attrs: {}
};

export default withRouter(connected(DataPointsPage, undefined, propMapper));
