import React from 'react';
import PropTypes from 'prop-types';

import GoogleLanguageToggle from './GoogleLanguageToggle';
import YandexLanguageToggle from './YandexLanguageToggle';
import './index.module.scss';

// Map of translation services to use
const SERVICES = {
  google: GoogleLanguageToggle,
  yandex: YandexLanguageToggle
};

export default function LanguageToggle({ service, ...otherProps }) {
  const Toggle = SERVICES[service];

  return Toggle ? <Toggle {...otherProps} /> : null;
}

// Please update prop type when adding more services
LanguageToggle.propTypes = {
  service: PropTypes.oneOf(Object.keys(SERVICES)).isRequired
};
