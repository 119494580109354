import React from 'react';
import PropTypes from 'prop-types';

import Trend from 'cui/lib/components/Trend';
import ScoreDot from 'cui/lib/components/ScoreDot';

import template from '../../helpers/template';

// FYI check configs before removing anything from props to make sure the templateString is not missing neccisary props

const DetailsListItem = (props) => {
  const {
    isParentItem,
    colorIndex,
    theme,
    trend,
    changedAmount,
    detailItemTemplateStrings,
    showOverall
  } = props;

  const overall = showOverall ? 'Overall' : '';

  const templateObject = { ...props, overall };

  const trendsText = templateString => <span className="cui-text_italic">{template(templateString, templateObject)}</span>;

  const parentText = templateString => <span className="cui-text_bold">{template(templateString, templateObject)}</span>;

  const childText = templateString => <span>{template(templateString, templateObject)}</span>;

  const { trendsItemText = '', parentItemText = '', childItemText = '' } = detailItemTemplateStrings;

  return (
    <div className="cui-text_normal">
      <ScoreDot value={colorIndex} theme={theme} />
      <small>
        { isParentItem &&  parentText(parentItemText) }
        { !isParentItem &&  childText(childItemText) }
        { trend && <Trend trend={trend} />}
        { trend && changedAmount && trendsText(trendsItemText) }
      </small>
    </div>
  );
};

DetailsListItem.propTypes = {
  riskUnavailable: PropTypes.bool,
  isParentItem: PropTypes.bool,
  colorIndex: PropTypes.number.isRequired,
  theme: PropTypes.string.isRequired,
  percent: PropTypes.number,
  label: PropTypes.string,
  students: PropTypes.number,
  trend: PropTypes.string,
  changedAmount: PropTypes.number,
  showOverall: PropTypes.bool,
  detailItemTemplateStrings: PropTypes.object
};

DetailsListItem.defaultProps = {
  riskUnavailable: false,
  isParentItem: undefined,
  percent: 0,
  label: '',
  students: 0,
  trend: undefined,
  changedAmount: undefined,
  showOverall: false,
  detailItemTemplateStrings: {}
};

export default DetailsListItem;
