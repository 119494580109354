import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import get from 'lodash/get';

import { isApprovalSite } from 'helpers/approvalSite';
import connected from '../connected';
import {
  getVisibleSuccessIndicators,
  getVisibleVariables,
  getNodeContentByPath,
  isDomainVisibleOnApprovalSite
} from '../../helpers/nodeHelpers';
import DomainNav from '../../ui/DomainNav';

const isDomainVisible = node => !get(node, 'metadata.hide_in_list') && !get(node, 'metadata.hide_in_sidebar_nav');

const getSuccessIndicatorUrl = (currentOrganization, domainSlug, node, props) => {
  const variables = getVisibleVariables(node.items, props);

  if (variables.length === 0) return undefined;

  return `/organization/${currentOrganization.id}/${domainSlug}/${node.slug}/${variables[0].slug}`;
};

const getDomainUrl = (currentOrganization, domain, props) => {
  const firstSuccessIndicator = getVisibleSuccessIndicators(domain.items, props).find(successIndicator => successIndicator.items.length > 0);

  if (!firstSuccessIndicator) return `/organization/${currentOrganization.id}`;

  const firstVariable = getVisibleVariables(firstSuccessIndicator.items)[0];

  return `/organization/${currentOrganization.id}/${domain.slug}/${firstSuccessIndicator.slug}/${firstVariable.slug}`;
};

export const SuccessIndicatorNav = (props) => {
  const { framework, domainSlug, successIndicatorSlug, ...otherProps } = props;
  const currentOrganization = get(otherProps, 'currentOrganization', '');

  const domains = framework.items
    .map(domain => ({
      ...domain,
      items: getVisibleSuccessIndicators(domain.items, props).map(node => ({
        ...node,
        name: node.name,
        linkTo: getSuccessIndicatorUrl(currentOrganization, domain.slug, node, props),
        active: domain.slug === domainSlug && node.slug === successIndicatorSlug
      })).filter(successIndicator => successIndicator.linkTo)
    }))
    .filter(domain => domain.items.length > 0)
    .filter(domain => isDomainVisible(domain))
    .filter(domain => (isApprovalSite() ? isDomainVisibleOnApprovalSite(domain, props.featureFlags) : true));

  const menu = domains
    .map(domain => ({
      domain: domain.name,
      desc: getNodeContentByPath(domain, 'description', currentOrganization),
      linkTo: getDomainUrl(currentOrganization, domain, props),
      icon: `//bb-production-assets.s3.amazonaws.com/kde/domainIcons/${domain.slug}_icon.svg`,
      isOpen: domain.slug === domainSlug,
      items: domain.items
    }))
    .filter(domain => domain.items.length > 0);

  return (
    <div className="l-dataPointPage-sidebar">
      <DomainNav menu={menu} />
    </div>
  );
};

SuccessIndicatorNav.propTypes = {
  userRole: PropTypes.string,
  development: PropTypes.bool,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,

  // Used in propMapper
  framework: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  domainSlug: PropTypes.string.isRequired,
  successIndicatorSlug: PropTypes.string.isRequired
};

SuccessIndicatorNav.defaultProps = {
  userRole: '',
  development: String(process.env.DEPLOY_ENV).toLowerCase() === 'development', // This probably would have to be removed after FIN for public launches
  featureFlags: {}
};

export default withRouter(connected(SuccessIndicatorNav));
