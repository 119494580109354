import React from 'react';
import PropTypes from 'prop-types';
import { getRadioOptions, scoreInfoPropType } from '../../../helpers/collectorHelpers';

const RadioSet = ({ fieldLabel, notReported, scoreInfo }) => (
  <div>
    <h3 className="fieldLabel">{fieldLabel}</h3>
    <div className="field">
      {notReported || (
        <ol className="checkboxList inline">
          {getRadioOptions(scoreInfo).map(({ label, value, selected }) => (
            <li key={value}>
              {selected
                ? <img src="https://bb-production-assets.s3.amazonaws.com/kde/report/circle-check.svg" className="checkmark" alt="Selected" />
                : <img src="https://bb-production-assets.s3.amazonaws.com/kde/report/circle-empty.svg" className="checkmark" alt="" />
              }
              <span className="value">{label}</span>
            </li>
          ))}
        </ol>
      )}
    </div>
  </div>
);

RadioSet.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  notReported: PropTypes.node,
  scoreInfo: scoreInfoPropType.isRequired
};

export default RadioSet;
