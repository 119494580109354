import get from 'lodash/get';
import {
  excludeDisabled,
  filterByMetadata, getVisibleSuccessIndicators,
  isNodeVisible,
  isPublicOrWithClarityUserPermissions,
  isDomainVisibleOnApprovalSite
} from 'helpers/nodeHelpers';
import filterByMetadataProps from 'helpers/metadataHelpers';
import when from 'helpers/when';
import { isApprovalSite } from 'helpers/approvalSite';

const DOMAINS_ORDER = [
  'school_overview',
  'academic_performance',
  'educational_opportunity',
  'transition_readiness',
  'school_safety',
  'school_accountability',
  'other_education_data'
];

const isDomainVisible = node => {
  const {
    hide_in_list: hideInList = false,
    hide_in_org_dashboard: hideInOrgDashboard = false
  } = node.metadata ?? {};

  return !(hideInList || hideInOrgDashboard);
};

const compareByDefinedArrayIndex = (a, b, orderedArray) => {
  if (orderedArray.indexOf(a.slug) > -1 && orderedArray.indexOf(b.slug) > -1) {
    return orderedArray.indexOf(a.slug) - orderedArray.indexOf(b.slug);
  }

  return orderedArray.indexOf(a.slug) === -1 ? 1 : -1;
};

const filterByWhen = (nodeItem, { isPreviewSite }) => {
  const showFromDashboardWhen = get(nodeItem, 'metadata.show_from_dashboard_when');
  const env = process.env.DEPLOY_ENV || 'development';

  return showFromDashboardWhen ? when(showFromDashboardWhen, { env, preview_site: isPreviewSite }) : true;
};

export const getMetadataContentByPath = (metadata = {}, path, currentOrganization = '') => {
  const description = get(metadata, path, '');

  if (typeof description === 'string') {
    return description;
  }

  return description.find(item => when(item.when, { currentOrganization })).value;
};

export const variableLinkRoute = (currentOrganization = {}, domain = {}, successIndicator = {}, userRole = '') => {
  const metadataProps = filterByMetadataProps({ currentOrganization });

  const dataPoints = excludeDisabled(successIndicator.items)
    .filter(node => isPublicOrWithClarityUserPermissions(node, userRole))
    .filter(isNodeVisible)
    .filter(dataPoint => filterByMetadata(dataPoint, metadataProps));

  if (dataPoints.length === 0) return undefined;

  return (
    `/organization/${currentOrganization.id}/${domain.slug}/${successIndicator.slug}/${get(dataPoints, [0, 'slug'])}`
  );
};

export const getDomains = (currentOrganization, domains, props) => {
  const featureFlags = props.featureFlags;

  return Array.from(domains)
    .sort((a, b) => compareByDefinedArrayIndex(a, b, DOMAINS_ORDER))
    .filter(domain => isDomainVisible(domain))
    .filter(domain => (isApprovalSite() ? isDomainVisibleOnApprovalSite(domain, featureFlags) : true))
    .filter(domain => filterByWhen(domain, props))
    .map(domain => ({
      ...domain,
      items: getVisibleSuccessIndicators(domain.items, props)
        .map(successIndicator => ({
          description: getMetadataContentByPath(successIndicator.metadata, 'description'),
          linkTo: variableLinkRoute(
            currentOrganization,
            domain,
            successIndicator,
            props.userRole
          ),
          title: successIndicator.name,
          ariaLabel: `${domain.name}: ${successIndicator.name} data`
        }))
        .filter(successIndicator => successIndicator.linkTo)
    }))
    .filter(domain => domain.items.length > 0);
};

export default variableLinkRoute;
