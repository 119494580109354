import { useQuery } from 'react-query';

export const FEATURE_FLAGS = [
  {
    key: 'DatapointToolbar',
    enabled: true
  },
  {
    key: 'DatapointTrends',
    enabled: true
  },
  {
    key: 'KdeApprovalSchoolAccountability',
    enabled: false
  },
  {
    key: 'KdeApprovalOtherEducationData',
    enabled: false
  },
  {
    key: 'KdeApprovalAcademicPerformance',
    enabled: false
  },
  {
    key: 'KDECollector',
    enabled: true
  },
  {
    key: 'KDESchoolAccountabilityLandingPage',
    enabled: true
  },
  {
    key: 'KDESchoolReportDownload',
    enabled: true
  },
  {
    key: 'SPRPDFDownload',
    enabled: true
  },
  {
    key: 'KdeLanguageTranslationGoogle',
    enabled: true
  },
  {
    key: 'KdeLanguageTranslationYandex',
    enabled: false
  },
  {
    key: 'ComicalClarity',
    enabled: false
  },
  {
    key: 'sso_config_fix',
    enabled: true
  },
  {
    key: 'SuperUsersRequireGoogleAuth',
    enabled: true
  },
  {
    key: 'c4_enable_legacy_cui_charts',
    enabled: false
  },
  {
    key: 'KdeCollectionTransitionReadiness',
    enabled: false
  },
  {
    key: 'KdeCollectionSchoolAccountability',
    enabled: false
  },
  {
    key: 'KdeDomainsFinancialTransparency',
    enabled: true
  },
  {
    key: 'KdeCollectionAcademicPerformance',
    enabled: false
  },
  {
    key: 'KDEApproval',
    enabled: false
  },
  {
    key: 'WalkMePilot',
    enabled: true
  },
  {
    key: 'KdeQualitySchoolClimateSafetySurvey',
    enabled: true
  },
  {
    key: 'ExportServiceStreamCsv',
    enabled: false
  },
  {
    key: 'sso_datum_screen_loads_sso_file',
    enabled: false
  },
  {
    key: 'sso_override_disable_limit_for_scs',
    enabled: false
  },
  {
    key: 'StudentDataSuperUserBouncer',
    enabled: false
  },
  {
    key: 'KDENextYear',
    enabled: false
  },
  {
    key: 'KdeApprovalSchoolOverview',
    enabled: false
  },
  {
    key: 'CspLockdown',
    enabled: false
  },
  {
    key: 'KdeCollectionSchoolSafety',
    enabled: false
  },
  {
    key: 'KdeCollectionSchoolOverview',
    enabled: false
  },
  {
    key: 'KdeCollectionFinancialTransparency',
    enabled: true
  },
  {
    key: 'KdeApprovalFinancialTransparency',
    enabled: true
  },
  {
    key: 'KdeApprovalEducationalOpportunity',
    enabled: false
  },
  {
    key: 'KdeApprovalTransitionReadiness',
    enabled: false
  },
  {
    key: 'KdeApprovalDashboardLink',
    enabled: true
  },
  {
    key: 'insights_cms_turn_down',
    enabled: false
  },
  {
    key: 'hard_delete_users_in_wipeout',
    enabled: true
  },
  {
    key: 'KdeApprovalPeriodOpen',
    enabled: false
  },
  {
    key: 'KdeApprovalSchoolSafety',
    enabled: false
  },
  {
    key: 'sso_override_disable_limit_for_kysrc',
    enabled: false
  },
  {
    key: 'KdeCollectionEducationalOpportunity',
    enabled: false
  }
];

const getFeatureFlags = () => Promise.resolve(FEATURE_FLAGS);

const useFeatureFlagsQuery = (options = {}) =>
  useQuery({
    queryKey: ['featureFlags'],
    queryFn: getFeatureFlags,
    ...options
  });

export const useFeatureFlagQuery = key =>
  useFeatureFlagsQuery({
    select: data => data.find(el => el.key === key)
  });

export default useFeatureFlagsQuery;
