import React from 'react';
import PropTypes from 'prop-types';

Footer.propTypes = {
  children: PropTypes.node
};

function Footer({ children }) {
  return (
    <div className="orgPanel-footer">
      { children }
    </div>
  );
}

Footer.defaultProps = {
  'data-slotName': 'Footer'
};

export default Footer;
