import PropTypes from 'prop-types';
import React from 'react';

import { Icon } from 'cui';
import InfoIcon from './KdeCondensedTableInfoIcon';
import styles from './KdeCondensedTable.module.scss';
import valueFormatters from './valueFormatters';
import propTypes from '../CondensedTable/types';

const CustomCell = props => (
  <td className={props.className}>
    <strong>{props.row[props.accessor]}</strong>
    {
      props.info && (
        <InfoIcon text={props.info} />
      )
    }
    {
      props.description && (
        <div className="u-color-kde-text-tint">
          {props.description}
        </div>
      )
    }
  </td>
);

CustomCell.propTypes = {
  accessor: PropTypes.string.isRequired,
  className: PropTypes.string,
  description: PropTypes.string,
  info: PropTypes.string,
  row: PropTypes.object
};

const customRenders = {
  additionalSchoolLevelData: ({ accessor, row, organizationType }) => {
    if (accessor !== 'name') return null;

    let entityType = organizationType.toLowerCase();
    // There are no separate links for schools and district data contains school data.
    // Thus if entity is a school display link to its parent district data.
    if (entityType === 'school') entityType = 'district';
    const href = row.hreflink ? row.hreflink : row[entityType];
    return (
      <td className={styles.externalLinkWrapper}>
        <a href={href} target="_blank" rel="noopener noreferrer">
          {row[accessor]}
          <span className={styles.externalLinkIcon}><Icon name="bb-arrow-box-top" /></span>
        </a>
      </td>
    );
  },
  accessorPerMembership: ({ accessor, className, data = [], info, row = {} }) => {
    const membership = data.find(({ id }) => id === 'membership') || {};
    let description;

    if (accessor === 'name') {
      description = 'Per Student';
    } else if (!membership[accessor]) {
      description = null;
    } else {
      const perStudent = row[accessor].replace(/[^\d.-]/g, '') / membership[accessor].replace(/[^\d.-]/g, '');
      description = `$${valueFormatters.addThousandsSeparator(perStudent.toFixed(2))}`;
    }

    return (
      <CustomCell
        accessor={accessor}
        className={className}
        description={description}
        info={info}
        row={row}
      />
    );
  },
  accessorPerMembershipSchool: ({ accessor, className, data = [], info, row = {} }) => {
    const membership = data.find(({ id }) => id === 'membership') || {};

    if (accessor === 'school') {
      row[accessor] = valueFormatters.moneyFormatter(row[accessor]);
    } else if (!!row[accessor] && !!membership[accessor]) {
      row[accessor] = valueFormatters.moneyFormatter(row[accessor] / membership[accessor]);
    } else if (!row[accessor]) {
      row[accessor] = 'Not provided';
    }

    return (
      <CustomCell
        accessor={accessor}
        className={className}
        info={info}
        row={row}
      />
    );
  },
  fundBalance: ({ accessor, className, data = [], info, row = {} }) => {
    const fundBalanceData = data.find(({ id }) => id === 'general_fund_balance_percentage') || {};
    const description = accessor === 'name' ?
      'Fund Balance %' :
      `${fundBalanceData[accessor]}%`;

    return (
      <CustomCell
        accessor={accessor}
        className={className}
        description={description}
        info={info}
        row={row}
      />
    );
  }
};

customRenders.accessorPerMembership.propTypes = {
  accessor: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: propTypes.data,
  info: PropTypes.string,
  row: PropTypes.object
};

customRenders.accessorPerMembershipSchool.propTypes = {
  accessor: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: propTypes.data,
  info: PropTypes.string,
  row: PropTypes.object
};

customRenders.additionalSchoolLevelData.propTypes = {
  accessor: PropTypes.string.isRequired,
  organizationType: PropTypes.string,
  row: PropTypes.object
};

customRenders.fundBalance.propTypes = {
  accessor: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: propTypes.data,
  info: PropTypes.string,
  row: PropTypes.object
};

export default customRenders;
