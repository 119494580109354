import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import parseInt from 'lodash/parseInt';
import uniq from 'lodash/uniq';
import { findNodeByNodePath } from '../../../helpers/frameworkHelpers';
import { getOrgIdFromOrganization } from '../../../helpers/portalDataServiceHelpers';
import orgCanAccessCollectorDataPoint from '../../../helpers/orgCanAccessCollectorDataPoint';

const ALL_GRADES = [
  { slug: 'total', title: 'Total' },
  { slug: 'preschool', title: 'Preschool' },
  { slug: 'kindergarten', title: 'Kindergarten' },
  { slug: 'grade_1', title: '1st Grade' },
  { slug: 'grade_2', title: '2nd Grade' },
  { slug: 'grade_3', title: '3rd Grade' },
  { slug: 'grade_4', title: '4th Grade' },
  { slug: 'grade_5', title: '5th Grade' },
  { slug: 'grade_6', title: '6th Grade' },
  { slug: 'grade_7', title: '7th Grade' },
  { slug: 'grade_8', title: '8th Grade' },
  { slug: 'grade_9', title: '9th Grade' },
  { slug: 'grade_10', title: '10th Grade' },
  { slug: 'grade_11', title: '11th Grade' },
  { slug: 'grade_12', title: '12th Grade' }
];

function transformScore(score) {
  const grades = [];
  const totals = {};
  let courseNames = [];
  const scoresByCourse = {};
  ALL_GRADES.forEach((grade) => {
    const scores = get(score, ['dimensions', grade.slug, 'scores']);
    if (scores && scores.length > 0) {
      grades.push(grade);
      scoresByCourse[grade.slug] = keyBy(scores, (gradeScore) => {
        const courseName = get(gradeScore, ['value', 'state_course_name']);
        const students = parseInt(get(gradeScore, ['value', 'grade_count']));
        if (students > 0) {
          totals[courseName] = totals[courseName] || 0;
          totals[courseName] += students;
        }
        courseNames.push(courseName);
        return courseName;
      });
    }
  });
  courseNames = uniq(courseNames).sort();
  return {
    grades,
    courseNames,
    scoresByCourse,
    totals
  };
}

function studentCount(scoresByCourse, slug, course) {
  return get(scoresByCourse, [slug, course, 'value', 'grade_count']);
}

function notReported(framework, nodePath, grades, organization) {
  const node = findNodeByNodePath(framework, nodePath);
  const required = orgCanAccessCollectorDataPoint(node, organization);
  return (grades.length === 0 && required) ? <p><strong>Data Not Reported</strong></p> : false;
}

export const CoursesOfferedSection = ({ scores, nodePath, organization, framework }) => {
  const orgId = getOrgIdFromOrganization(organization);
  const score = get(scores, [nodePath, orgId]);

  const { grades, courseNames, scoresByCourse, totals } = transformScore(score);

  return (
    <section className="section field" aria-labelledby="courses_offered_section_aria_label">
      <h2 id="courses_offered_section_aria_label">Courses Offered</h2>
      {
        notReported(framework, nodePath, grades, organization) || (
          <div>
            <table className="dataTable courseTable">
              <thead>
                <tr>
                  <th>Course</th>
                  <th>Total</th>
                  {grades.map(grade => <th className="rotate" key={grade.title}><div>{grade.title}</div></th>)}
                </tr>
              </thead>
              <tbody>
                {
                  courseNames.map(course => (
                    <tr key={course}>
                      <td>{course}</td>
                      <td><strong>{totals[course]}</strong></td>
                      {
                        grades.map(grade => (
                          <td key={grade.slug}>
                            {studentCount(scoresByCourse, grade.slug, course)}
                          </td>
                        ))
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        )
      }
    </section>
  );
};

CoursesOfferedSection.propTypes = {
  organization: PropTypes.shape({
    entity_type: PropTypes.string.isRequired
  }).isRequired,
  framework: PropTypes.object.isRequired,
  nodePath: PropTypes.string,
  scores: PropTypes.object
};

export default CoursesOfferedSection;
