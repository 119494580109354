import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';

import template from '../../../template';
import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, scores } = options;
  const scoringOptions = node.scoring_options || {};
  const trendLines = conditional(scoringOptions.trend_lines, {}, options);

  /* eslint-disable no-useless-escape */
  const data = trendLines && sortBy(map(trendLines, (value) => {
    const trendKey = get(value, 'key');
    return (
      {
        risk: template(get(value, 'name'), options),
        label: template(get(value, 'name'), options),
        showLine: get(value, 'show_line'),
        showPoints: get(value, 'show_points'),
        colorIndex: get(value, 'colorIndex'),
        symbol: 'circle',
        category: template(get(value, 'name'), options),
        active: get(value, 'active'),
        values: map(scores, (score, i) => {
          const prevScore = scores[i - 1] ? scores[i - 1] : null;
          const prevValue = prevScore ? get(find(prevScore.risks, trend => trend.risk === trendKey), 'percent') : null;
          const currentValue = get(find(score.risks, trend => trend.risk === trendKey), 'percent');
          return ({
            x: new Date(get(score, 'date').replace(/-/g, '\/')),
            y: currentValue,
            prevValue,
            total: get(find(score.risks, trend => trend.risk === trendKey), 'student_count'),
            score,
            visible: get(score, 'visible', true)
          });
        }),
        index: get(value, 'index')
      }
    );
  }), ['index']);

  return data.length && scores.length ? data : undefined;
  /* eslint-enable no-useless-escape */
};

export default adapter;
