export const reduxActionPrefix = 'application.alchemy.';

export const SET_FRAMEWORK_DATA = `${reduxActionPrefix}SET_FRAMEWORK_DATA`;
export const SET_LOADING = `${reduxActionPrefix}SET_LOADING`;
export const SET_LOADED = `${reduxActionPrefix}SET_LOADED`;
export const SET_CURRENT_MATCH = `${reduxActionPrefix}SET_CURRENT_MATCH`;
export const SET_CHART_FOR_NODE = `${reduxActionPrefix}SET_CHART_FOR_NODE`;
export const SET_VISIBLE_FOR_NODE = `${reduxActionPrefix}SET_VISIBLE_FOR_NODE`;
export const RESET_CHARTS = `${reduxActionPrefix}RESET_CHARTS`;
export const TOGGLE_MODAL = `${reduxActionPrefix}TOGGLE_MODAL`;

export const setFrameworkData = data => ({
  type: SET_FRAMEWORK_DATA,
  data
});

export const setLoading = source => ({
  type: SET_LOADING,
  source
});

export const setLoaded = source => ({
  type: SET_LOADED,
  source
});

export const setCurrentMatch = (match, search) => ({
  type: SET_CURRENT_MATCH,
  search,
  match
});

export const setChartForNode = (nodePath, comparison) => ({
  type: SET_CHART_FOR_NODE,
  nodePath,
  comparison
});

export const resetCharts = () => ({
  type: RESET_CHARTS
});

export const setVisibleForNode = (nodePath, item, activeItems) => ({
  type: SET_VISIBLE_FOR_NODE,
  nodePath,
  item,
  activeItems
});

export const showModal = modalKey => ({
  type: TOGGLE_MODAL,
  isOpen: true,
  modalKey
});

export const hideModal = modalKey => ({
  type: TOGGLE_MODAL,
  isOpen: false,
  modalKey
});
