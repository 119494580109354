import map from 'lodash/map';
import get from 'lodash/get';

import { SET_USER_CONTEXT } from '../actions';

function userContextReducer(state = {}, action) {
  switch (action.type) {
    case SET_USER_CONTEXT:
      return {
        ...state,
        ...action.data,

        // unpack module slugs for easy access
        moduleSlugs: map(get(action, 'data.modules', []), 'slug')
      };
    default:
      return state;
  }
}

export default userContextReducer;
