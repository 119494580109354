import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const getWhyThisMatters = (path, whyThisMatters) => {
  const wtm = !isEmpty(whyThisMatters)
    && whyThisMatters.find(
      ({ data_points }) => !!data_points.find(({ framework_tree_path }) => framework_tree_path === path)  // eslint-disable-line camelcase
    );

  return wtm ? {
    content: wtm.body_text,
    citation: get(wtm, 'citations[0].text', '')
  } : undefined;
};

export default getWhyThisMatters;
