// Merge scores from different sources (Groot, SVC-Portal)
// For example:
// const allScores = [
//   {
//     framework_tree_node: { node_path: 'kde.student' },
//     value: {
//       count: 1
//     }
//   },
//   {
//     framework_tree_node: { node_path: 'kde.teacher' },
//     value: {
//       count: 2
//     }
//   }
// ];
//
// const node = {
//   metadata: {
//     dataSources: [
//       {
//         source: 'groot',
//         slug: 'student',
//         node_path: 'kde.student'
//       },
//       {
//         source: 'portalService',
//         slug: 'teacher',
//         node_path: 'kde.teacher'
//       }
//     ]
//   }
// };
// Scores are organized under slugs
// const result = {
//   student: {
//     count: 1
//   },
//   teacher: {
//     count: 2
//   }
// };

import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';

import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import { filterByWhen } from '../../helpers/portalDataServiceHelpers';

export default function adapter(currentOrgScore, { chartConfig, allScores, node, currentOrganization, featureFlags }) {
  if (isEmpty(allScores)) {
    return allScores;
  }

  let nodesFromSources = get(node, 'metadata.dataSources')
    .filter(source => filterByWhen(source, { featureFlags }));
  const includeCurrentNode = get(chartConfig, 'options.mergeScores.includeCurrentNode');
  if (includeCurrentNode) {
    nodesFromSources.unshift(node);
  }
  nodesFromSources = uniqBy(nodesFromSources, 'node_path');
  const scoresByGroup = groupBy(allScores, score => get(score, 'framework_tree_node.node_path'));

  return nodesFromSources.reduce((memo, nodeFromSource) => {
    const score = currentOrgYearScores(scoresByGroup[nodeFromSource.node_path], currentOrganization);
    memo[nodeFromSource.slug] = get(score, 'value');
    return memo;
  }, {});
}
