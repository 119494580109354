import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import cx from 'classnames';
import get from 'lodash/get';
import EmptyState from 'cui/lib/components/EmptyState';
import Donut from 'cui/lib/components/Donut';
import List from 'cui/lib/components/List';
import ScoreDot from 'cui/lib/components/ScoreDot';

import KdeDashboardDonutProvider from './provider';
import { FRAMEWORK_NODE_PATHS } from '../../constants';
import { valueToLocaleString } from '../../helpers/template/format';
import { getOrgIdFromOrganization } from '../../helpers/portalDataServiceHelpers';

import styles from './KdeDashboardDonutChart.module.scss';

const KdeDashboardDonutTitle = ({ children, label }) => (
  <div className="cui-media-content">
    <strong className="cui-text_xxLarge">
      {children}
    </strong>
    <br />
    {label && (
      <em className="cui-text_small u-color-kde-text-tint">
        {label}
      </em>
    )}
  </div>
);

KdeDashboardDonutTitle.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node
};

KdeDashboardDonutTitle.defaultProps = {
  label: null,
  title: null
};

const KdeDashboardDonutLegend = ({ data, isValueFirst, totalValue, totalPosition, theme }) => {
  const legendWithTotal = !!totalValue && totalPosition === 'legend';
  return (
    <div className="cui-media-content">
      <List
        className={cx('legend', `${legendWithTotal && 'legend__double'}`)}
      >
        {
          data.map((point, index) => (
            point.label &&
              <List.Item
                className={cx('legend-item', styles.donutChartLegendItem)}
                icon={
                  <ScoreDot
                    className="legend-icon"
                    type="solid"
                    theme={theme}
                    value={index}
                  />
                }
                key={index}
              >
                {
                  isValueFirst ? (
                    <div>
                      <span className="legend-value">
                        {point.value}
                      </span>
                      {(point.label || point.description) && (
                        <span className="u-indent legend-label">
                          {point.label}
                          {point.description && <em className="u-indent">{point.description}</em>}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div>
                      {(point.label || point.description) && (
                        <div className="legend-label">
                          {point.label}
                          {point.description && <em className="u-indent">{point.description}</em>}
                        </div>
                      )}
                      <span className="legend-value">
                        {point.value}
                      </span>
                    </div>
                  )
                }
              </List.Item>
          ))
        }
      </List>
      {
        legendWithTotal && (
          <div className="cui-panel-footer cui-flexbar cui-margin-top-medium">
            <div className="cui-media-content legend-label cui-font-bold">
              TOTAL
            </div>
            <div className="cui-media-content legend-value">
              ${parseFloat(totalValue).toLocaleString()}
            </div>
          </div>
        )
      }
    </div>
  );
};

KdeDashboardDonutLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  isWide: PropTypes.bool,
  isValueFirst: PropTypes.bool,
  totalValue: PropTypes.string,
  totalPosition: PropTypes.string,
  theme: PropTypes.string
};

KdeDashboardDonutLegend.defaultProps = {
  data: [],
  isWide: false,
  isValueFirst: false,
  totalValue: '',
  theme: null
};

const KdeDashboardDonutChart = props => (
  <KdeDashboardDonutProvider {...props}>
    {({ data, displayDetailedLegend, isValueFirst, totalValue, totalPosition, theme, title, label, isSuppressed }) => {
      if (!data.length) {
        return <EmptyState data-test="donut-chart-empty-state" kind="text" title="There is no data available." />;
      }

      if (isSuppressed) {
        return <EmptyState data-test="donut-chart-empty-state" kind="text" title="This data is hidden to protect student privacy." />;
      }

      return (
        <div className={cx(`${styles.donutContainer}`, 'cui-media cui-media_center cui-media_chart', props.className)}>
          <div className="cui-media-image">
            <Donut
              animate={false}
              data={data}
              width={140}
              theme={theme}
              radiusRatio={0.4}
              {...(totalPosition === 'donut' && { label: 'total', value: valueToLocaleString(totalValue) })}
            />
          </div>
          {displayDetailedLegend ? (
            <KdeDashboardDonutLegend data={data} isValueFirst={isValueFirst} totalValue={totalValue} totalPosition={totalPosition} theme={theme} />
          ) : (
            <KdeDashboardDonutTitle label={label}>{title}</KdeDashboardDonutTitle>
          )}
        </div>
      );
    }}
  </KdeDashboardDonutProvider>
);

const portalDataSelector = state => get(state, 'module.scoresFromPortalDataService');
const organizationSelector = (state, props) => (get(props, 'organization') || get(state, 'module.currentOrganization', {}));
const schoolYearSelector = state => get(state, 'module.schoolYear');
const studentCountSelector = createSelector(
  portalDataSelector,
  organizationSelector,
  schoolYearSelector,
  (scores, organization, schoolYear) => ({
    studentCount: get(scores, [
      FRAMEWORK_NODE_PATHS[schoolYear].studentCount,
      getOrgIdFromOrganization(organization),
      'scores',
      '0',
      'value',
      'count'
    ]),
    studentMembershipCount: get(scores, [
      FRAMEWORK_NODE_PATHS[schoolYear].studentMembership,
      getOrgIdFromOrganization(organization),
      'scores',
      '0',
      'value',
      'count'
    ])
  })
);

KdeDashboardDonutChart.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = (state, props) => studentCountSelector(state, props);

export default connect(mapStateToProps)(KdeDashboardDonutChart);
