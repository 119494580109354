import React from 'react';

import LineRangeSlider from '../LineRangeSlider';

import KdeLineRangeSliderProvider from './provider';

const KdeRangeSliderChart = props => (
  <KdeLineRangeSliderProvider {...props}>
    {
      data => (
        <div>
          {
            data.map(({ key, max, min, title, value }) => (
              <LineRangeSlider
                key={key}
                max={max}
                min={min}
                title={title}
                value={value}
              />
            ))
          }
        </div>
      )
    }
  </KdeLineRangeSliderProvider>
);

export default KdeRangeSliderChart;
