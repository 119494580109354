import React from 'react';
import { useHistory } from 'react-router-dom';
import BlockTitle from 'cui/lib/components/BlockTitle';
import PropTypes from 'prop-types';

import { basename } from 'store';
import useOrganizationQuery from 'store/backend/queries/organization';
import ApprovalHeader from 'components/ApprovalHeader';
import approvalWrapper from 'components/ApprovalWrapper';
import MainContentAnchor from 'components/MainContentAnchor';
import DistrictMessageForm from 'components/DistrictMessageForm';
import { kdeCurrentSchoolYear } from 'helpers/portals';
import styles from './Status.module.scss';

const DistrictMessagePage = ({ match }) => {
  const year = kdeCurrentSchoolYear();
  const history = useHistory();
  const organizationId = Number(match.params.id);
  const { data: currentOrganization, isLoading } = useOrganizationQuery(organizationId);

  if (isLoading) return null;

  if (currentOrganization.entity_type !== 'District') {
    history.push(`${basename}/status/${currentOrganization.id}`);
    return null;
  }

  return (
    <div className={styles.container}>
      <ApprovalHeader currentOrganization={currentOrganization} />
      <div role="main">
        <MainContentAnchor />
        <BlockTitle title="District Message" />

        <DistrictMessageForm organizationId={organizationId} year={year} />
      </div>
    </div>
  );
};

DistrictMessagePage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default approvalWrapper(DistrictMessagePage);

export const config = {
  title: '{{name}} - District Message - Kentucky School Report Card Approval'
};
