import find from 'lodash/find';
import memoize from 'lodash/memoize';
import { findDimensionNodes } from '../../helpers/frameworkHelpers';

export default function propMapper(props) {
  const {
    domainSlug,
    successIndicatorSlug,
    variableSlug,
    framework,
    dataPointType,
    dateTemplate,
    showScoreDates = false
  } = props;

  if (!framework) {
    return {
      dataPoints: [],
      showScoreDates,
      dateTemplate: ''
    };
  }

  const nodeMatchesParamSlug = memoize((node, paramSlug) => (node.slug === paramSlug));

  // Use both the nodeType and slug as cache key to make sure even we name our node_path using
  // duplicate slug names, things still work
  // Such as kde.transition_readiness.transition_readiness_adult_life.transition_readiness.readiness
  const findNode = memoize((paramSlug, node, nodeType) => (
    find(findDimensionNodes(node, nodeType), n => nodeMatchesParamSlug(n, paramSlug))
  ), (paramSlug, node, nodeType) => `${nodeType}_${paramSlug}`);
  const domain = findNode(domainSlug, framework, 'domain');
  const successIndicator = findNode(successIndicatorSlug, domain, 'success_indicator');
  const variable = findNode(variableSlug, (successIndicator || domain), 'variable');
  let dataPoints;
  if (dataPointType) {
    dataPoints = findDimensionNodes(domain, dataPointType);
  } else {
    dataPoints = findDimensionNodes((variable || successIndicator || domain), 'data_point');
  }

  return {
    dataPoints,
    showScoreDates,
    dateTemplate,
    development: process.env.NODE_ENV === 'development',
    variableSlug
  };
}
