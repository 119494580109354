import React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import get from 'lodash/get';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import pluralize from 'pluralize';

import Icon from 'cui/lib/components/Icon';
import Table from 'cui/lib/components/Table';

import { findNodeByNodePath } from '../../helpers/frameworkHelpers';
import { basename } from '../../store';

const rowRenderer = (row, idx, columns, parentRow) => {
  if (parentRow) {
    return (
      <tr key={idx}>
        <td>
          <div>{row.name}</div>
          <div className="cui-font-medium">{row.address.variable.name}</div>
        </td>
        <td />
        <td>{row.updated_at && format(parseInt(row.updated_at), 'MMMM d, yyyy')}</td>
        <td>{row.updated_by}</td>
      </tr>
    );
  }

  const numberOfFlags = (row.data || []).length;

  return (
    <tr key={idx}>
      <td>{row.node.name}</td>
      <td>
        <Icon
          name="ig-flag"
          className={cn({
            'theme-kdeApproval-fill-1': numberOfFlags > 0,
            'theme-kdeApproval-fill-2': numberOfFlags === 0
          })}
        />
        &nbsp;
        {numberOfFlags}
        &nbsp;
        {pluralize('Flag', numberOfFlags)}
      </td>
      <td />
      <td />
    </tr>
  );
};

const variableUrl = (dataPointAddress, organizationId, year) => {
  const {
    domain: { slug: domainSlug },
    success_indicator: { slug: successIndicatorSlug },
    variable: { slug: variableSlug },
    data_point: { slug: dataPointSlug }
  } = dataPointAddress;

  const path = `${basename}/organization/${organizationId}/${domainSlug}/${successIndicatorSlug}/${variableSlug}`;
  const query = `year=${year}`;

  return `${path}?${query}#${dataPointSlug}`;
};

export class SuccessIndicatorStatusTable extends React.Component {
  constructor(props) {
    super(props);

    this.syncColumnSizes = this.syncColumnSizes.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.syncColumnSizes);

    this.syncColumnSizes();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.syncColumnSizes);
  }

  syncColumnSizes() {
    const outerTableHeadings = this.containerRef.closest('table').querySelectorAll('th');
    const innerTableCells = this.containerRef.querySelectorAll('table tr:first-child td');

    innerTableCells.forEach((cell, idx) => {
      if (idx === 0) return;
      cell.style.width = `${outerTableHeadings[idx].offsetWidth}px`;
    });
  }

  render() {
    const data = this.props.successIndicators.map((el) => {
      const flaggedDataPoints = el.childStatuses
        .filter(dataPointStatus => dataPointStatus.status === 'flagged')
        .map((dataPointStatus) => {
          const dataPointNode = findNodeByNodePath(el.node, dataPointStatus.section_slug);

          return {
            ...dataPointStatus,
            ...dataPointNode,
            href: variableUrl(dataPointNode.address, this.props.currentOrganization.id, dataPointStatus.fiscal_year)
          };
        })
        .sort((a, b) => a.index - b.index);

      const childRows = flaggedDataPoints.length > 0 ? flaggedDataPoints : undefined;

      return {
        node: el.node,
        data: childRows
      };
    });

    return (
      <div ref={ref => { this.containerRef = ref; }}>
        <Table
          linkable
          expandable
          striped={false}
          data={data}
          renderRow={rowRenderer}
          LinkableComponent={({ href, children, ...props }) => (
            <div className={!href && 'cui-visuallyHidden'}>
              <a href={href} {...props}>
                {children}
              </a>
            </div>
          )}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  currentOrganization: get(state, 'module.currentOrganization')
});

SuccessIndicatorStatusTable.propTypes = {
  currentOrganization: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  successIndicators: PropTypes.arrayOf(PropTypes.shape({
    node: PropTypes.object.isRequired,
    childStatuses: PropTypes.arrayOf(PropTypes.shape({
      section_slug: PropTypes.string.isRequired,
      status: PropTypes.oneOf(['flagged', 'unreviewed']).isRequired,
      updated_at: PropTypes.string,
      updated_by: PropTypes.string
    })).isRequired
  })).isRequired
};

export default connect(mapStateToProps)(SuccessIndicatorStatusTable);
