import React from 'react';
import get from 'lodash/get';

import Question from './Question';

const collectScores = (viewingDropdownFilter, dimensionScores) => {
  const questionScores = {};

  if (!viewingDropdownFilter?.[0]) return questionScores;

  viewingDropdownFilter[0].forEach((filterName) => {
    get(dimensionScores, `${filterName}.score`).forEach((score) => {
      if (
        viewingDropdownFilter[1] &&
        !(score.question_type === viewingDropdownFilter[1])
      ) return;
      // Skip empty scores
      if (score.responses === '0') return;

      questionScores[score.question] ||= [];
      questionScores[score.question].dimension ||= filterName;
      questionScores[score.question].questionText ||= score.question_text;
      questionScores[score.question].scores ||= [];
      score.dimension = filterName;

      questionScores[score.question].scores.push(score);
    });
  });

  return questionScores;
};

const KdeSurveyQuestions = (props) => {
  const { node, scores, viewingDropdownFilter, includeDoesNotApply = false, removeResponsePercent = false } = props;
  const dimensionScores = get(scores, `0.value.dimensions.${node.slug}.dimensions`);
  const questionScores = collectScores(viewingDropdownFilter, dimensionScores);

  const sortedKeys = Object.keys(questionScores).sort();

  const questions = sortedKeys.map((key) => {
    const questionScore = questionScores[key];

    return <Question
      key={questionScore.questionText}
      questionText={questionScore.questionText}
      includeDoesNotApply={includeDoesNotApply}
      removeResponsePercent={removeResponsePercent}
      scores={questionScore.scores}
    />;
  });

  return (
    <>
      {questions}
    </>
  );
};

export default KdeSurveyQuestions;
