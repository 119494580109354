import React, { useEffect } from 'react';
import get from 'lodash/get';
import groupBy from 'lodash/groupBy';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { scroller } from 'react-scroll';

import FlexBar from 'cui/lib/components/FlexBar';

import connected from '../connected';
import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import { ORG_TYPES, KENTUCKY_ORG_ID } from '../../constants';
import DataPointSearch from '../DataPointSearch';
import OrgDataProvider from '../../helpers/OrgDataProvider';
import { getOrgType } from '../../helpers/portalDataServiceHelpers';
import filterByMetadataProps from '../../helpers/metadataHelpers';
import { getDomains } from './helpers';
import SchoolProfileReportLink from './SchoolProfileReportLink';
import Domain from './Domain';

function dataPointSlug(nodePath) {
  const tokens = nodePath.split('.');
  return tokens[tokens.length - 1];
}

// Filter scores by current organization
// and if includeParentData is true, filter scores by parent organizations also
// @param scores {array} array of scores objects
// @param organization {object} current organization
// @param chartConfig {object} current chart configuration object
// @return scores filtered by orgs
function filterScoresByOrgs(scores = [], organization, chartConfig) {
  const orgEntityType = getOrgType(organization);
  const orgIds = {
    [organization.id]: organization
  };
  // When includeParentData is on, filtered the scores by parent orgs also
  if (get(chartConfig, 'metadata.includeParentData', false)) {
    if (orgEntityType === 'school') {
      const parentDistrictId = get(organization, 'parentDistrict.id');
      if (parentDistrictId) {
        orgIds[parentDistrictId] = get(organization, 'parentDistrict');
      }
      orgIds[KENTUCKY_ORG_ID] = { id: KENTUCKY_ORG_ID };
    } else if (orgEntityType === 'district') {
      orgIds[KENTUCKY_ORG_ID] = { id: KENTUCKY_ORG_ID };
    }
  }

  return scores.filter(score => !!orgIds[score.remote_organization_id]);
}

export function findScoresForNodePaths(
  scoresByGroup,
  nodePaths,
  organization,
  chartConfig,
  scopedToOrgs = false
) {
  const currentNodePaths = Array.isArray(nodePaths) ? nodePaths : [nodePaths];
  // Some chart needs scores from multiple data points
  // Make sure we only pass the scores of the primary data point to a chart component
  // for backward compatibility
  // and pass additional scores as a separate prop
  // Only pick the groups given by nodePaths
  const scoresForNodePaths = pick(scoresByGroup, nodePaths);
  const currentScores = reduce(
    scoresForNodePaths,
    (memo, score = []) => memo.concat(score),
    []
  );
  const result = {
    scores: scopedToOrgs
      ? filterScoresByOrgs(currentScores, organization, chartConfig)
      : currentScores
  };
  if (currentNodePaths.length === 1) {
    const score = get(scoresForNodePaths, currentNodePaths[0], []);
    result.currentScore = get(currentOrgYearScores(score, organization), 'value');
  } else {
    // group the scores under the slug when nodePaths has more than one path
    result.currentScore = reduce(
      scoresForNodePaths,
      (memo, score, nodePath) => {
        memo[dataPointSlug(nodePath)] = get(
          currentOrgYearScores(score, organization),
          'value'
        );
        return memo;
      },
      {}
    );
  }

  return result;
}

export const OrgReportCard = props => {
  const { currentOrganization, framework, userRole, isLoading } = props;
  const { year } = currentOrganization;
  const location = useLocation();
  const activeSection = location.hash.substr(1);

  useEffect(() => {
    if (activeSection && !isLoading) scroller.scrollTo(activeSection);
  }, [isLoading, activeSection]);

  const metadataProps = filterByMetadataProps(props);

  const domains = getDomains(currentOrganization, props.framework.items, props);

  const scoresByGroup = groupBy(props.scores, score =>
    get(score, 'framework_tree_node.node_path')
  );

  return (
    <div className="container-constrained">
      <FlexBar>
        <FlexBar.Item>
          <h2 className="cui-heading cui-text_base cui-font-bold cui-heading_padded">
            Key Topics
          </h2>
        </FlexBar.Item>

        <DataPointSearch />
      </FlexBar>
      {domains.map(domain => (
        <Domain
          key={domain.slug}
          domain={domain}
          currentOrganization={currentOrganization}
          userRole={userRole}
          framework={framework}
          metadataProps={metadataProps}
          scoresByGroup={scoresByGroup}
          isLoading={isLoading}
        />
      ))}
      <OrgDataProvider>
        {({ orgType, organization }) =>
          orgType === ORG_TYPES.SCHOOL && (
            <SchoolProfileReportLink id={organization.id} year={year} />
          )
        }
      </OrgDataProvider>
    </div>
  );
};

OrgReportCard.propTypes = {
  userRole: PropTypes.string,
  development: PropTypes.bool,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object)
};

OrgReportCard.defaultProps = {
  userRole: '',
  development: String(process.env.DEPLOY_ENV).toLowerCase() === 'development', // This probably would have to be removed after FIN for public launches
  featureFlags: {}
};

export default connected(OrgReportCard);
