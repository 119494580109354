import React from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Field from 'cui/lib/components/Form/Field';

import FederalClassificationModal from '../FederalClassificationModal';
import SkeletonTextList from '../SkeletonScreen/TextList';

class FederalClassificationExplainer extends React.PureComponent {
  state = { isOpen: false };

  showModal = () => this.setState({ isOpen: true });

  hideModal = () => this.setState({ isOpen: false });

  render() {
    const { federalClassification, popoverContent, label, modalNotes, description } = this.props;

    if (federalClassification.isLoading) {
      return (
        <Field
          label="Federal Classification"
          popoverContent={popoverContent}
          description={description}
        >
          <SkeletonTextList size={3} />
        </Field>
      );
    }

    return (
      <div>
        <Field
          label={label}
          popoverContent={popoverContent}
          description={description}
        >
          <small>{federalClassification.text}</small>
          <div>
            {federalClassification.classifications.length > 0 && (
              <>
                <Button
                  className="tinyCaps cui-text_left cui-margin-top-xSmall"
                  onClick={this.showModal}
                >
                  Learn more
                </Button>
                <FederalClassificationModal
                  isOpen={this.state.isOpen}
                  onHide={this.hideModal}
                  federalClassification={federalClassification}
                  modalNotes={modalNotes}
                />
              </>
            )}
          </div>
        </Field>
      </div>
    );
  }
}

FederalClassificationExplainer.defaultProps = {
  label: 'Federal Classification',
  federalClassification: {
    text: '',
    classifications: [],
    gaps: [],
    isLoading: false
  },
  popoverContent: `For 2017-2018, federal classifications include Comprehensive Support
    and Improvement (CSI), Targeted Support and Improvement (TSI) and Other (not CSI or TSI).
    These classifications only apply to A1 schools.`
};

FederalClassificationExplainer.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  federalClassification: PropTypes.shape({
    text: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    classifications: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    gaps: PropTypes.array,
    isLoading: PropTypes.bool
  }),
  popoverContent: PropTypes.string,
  modalNotes: PropTypes.string
};

export default FederalClassificationExplainer;
