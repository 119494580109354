import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Modal from 'cui/lib/components/Modal';
import Heading from 'cui/lib/components/Heading';
import List from 'cui/lib/components/List';
import Divider from 'cui/lib/components/Divider';
import Spacer from 'cui/lib/components/Spacer';

import AchievementGaps from '../../ui/AchievementGaps';

import styles from './index.module.scss';

export default function FederalClassificationModal({ isOpen, onHide, federalClassification, modalNotes }) {
  return (
    <Modal isOpen={isOpen} onHide={onHide}>
      <Modal.Header title="Federal Classification" />
      <Modal.Body>
        {
          federalClassification.classifications.map((classification, key) => (
            <div key={key} className="cui-margin-bottom-large">
              <Heading className={styles.heading} level={3} size="xSmall" caps>
                {classification.level} Federal Classification
              </Heading>
              <div className={styles.classification}>
                {classification.name}
              </div>
              <p className={styles.description}>
                {classification.description}
              </p>
              <Spacer size="small" />
              <Heading className={styles.heading} level={3} size="xSmall" caps>
                Identification Reasons
              </Heading>
              {
                classification.reasons.length > 0 && (
                  <List size="small">
                    { classification.reasons.map((reason, i) => (
                      <List.Item key={i}>
                        {reason}
                      </List.Item>
                    ))}
                  </List>
                )
              }
              {
                classification.gaps && (
                  <div>
                    <Spacer size="xSmall" />
                    <Divider />
                    <Spacer size="xSmall" />
                    <Heading className={styles.heading} level={3} size="xSmall" caps>
                      Achievement Gaps
                    </Heading>
                    <AchievementGaps
                      data={get(classification, 'gaps.data')}
                      linkTo={get(classification, 'gaps.linkTo')}
                    />
                  </div>
                )
              }
              {modalNotes && (
                <>
                  <Spacer size="small" />
                  <p className={styles.note}>
                    {modalNotes}
                  </p>
                </>
              )}
            </div>
          ))
        }
      </Modal.Body>
    </Modal>
  );
}

FederalClassificationModal.propTypes = {
  isOpen: PropTypes.bool,
  modalNotes: PropTypes.string,
  onHide: PropTypes.func.isRequired,
  federalClassification: PropTypes.shape({
    text: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    classifications: PropTypes.array,
    // eslint-disable-next-line react/forbid-prop-types
    gaps: PropTypes.array
  })
};
