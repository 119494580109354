import cn from 'classnames';
import FlexBar from 'cui/lib/components/FlexBar';
import Icon from 'cui/lib/components/Icon';
import EmptyState from 'cui/lib/components/EmptyState';
import PropTypes from 'prop-types';
import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import currentOrgYearScores from '../../helpers/scoresHelpers';
import styles from './index.module.scss';
import KdeInfoNote from '../KdeInfoNote';
import kdeTextNumberAdapter from '../../chartDataAdapters/kdeTextNumber';
import applyAdapters from '../../helpers/adapterHelpers';

const KdeTextNumber = (props) => {
  const {
    node,
    organization,
    scores,
    config,
    allScores,
    additionalChartAdapters,
    viewingDropdownFilter,
    featureFlags
  } = props;
  const adapter = get(config, 'adapter');

  let currentScore;

  if (adapter) {
    // use adapter defined in the config file
    currentScore = applyAdapters(adapter, scores, {
      allScores,
      currentOrganization: organization,
      chartConfig: config,
      node,
      additionalChartAdapters,
      featureFlags
    });
  } else {
    currentScore = currentOrgYearScores(scores, organization, node);
  }

  if (isEmpty(currentScore)) {
    return <EmptyState kind="text" title="Data not reported" />;
  }

  const values = kdeTextNumberAdapter(currentScore, node, config, viewingDropdownFilter) || [];

  if (!values.length) return <EmptyState kind="text" title="Data not reported" />;

  return (
    <div className={styles.wrapper}>
      <div>
        {
          values.map((item, index) => {
            const labelClassNames = cn({
              [styles.label]: true,
              [styles.labelWithoutIcon]: !item.icon
            });
            return (
              <div className={styles.container} key={`${item.input}-${index}`}>
                <FlexBar justify="left">
                  {
                    item.icon && (
                      <FlexBar.Item>
                        <div className={styles.icon}>
                          <Icon name={item.icon} size="xLarge" />
                        </div>
                      </FlexBar.Item>
                    )
                  }
                  <FlexBar.Item grow>
                    <div className={labelClassNames}>
                      {item.label}
                    </div>
                  </FlexBar.Item>
                  <FlexBar.Item>
                    <div className={styles.input}>
                      {item.input}
                    </div>
                  </FlexBar.Item>
                  {
                    item.date && (
                      <FlexBar.Item>
                        <div className={styles.date}>
                          <div className={styles.dateLabel}>
                            Date:
                          </div>
                          {item.date}
                        </div>
                      </FlexBar.Item>
                    )
                  }
                </FlexBar>
              </div>
            );
          })
        }
      </div>
      <KdeInfoNote>
        {/* Info is disabled until further notice */}
        {/* {get(node, 'metadata.info')} */}
      </KdeInfoNote>
    </div>
  );
};

KdeTextNumber.propTypes = {
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  viewingDropdownFilter: PropTypes.object,
  allScores: PropTypes.arrayOf(
    PropTypes.object
  ),
  config: PropTypes.object,
  additionalChartAdapters: PropTypes.object,
  featureFlags: PropTypes.object
};

KdeTextNumber.defaultProps = {
  node: {},
  organization: {},
  scores: [],
  viewingDropdownFilter: {},
  config: {}
};

export default KdeTextNumber;
