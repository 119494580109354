import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { Field, Label } from 'cui';
import { Icon } from 'cui/lib/components';
import ChartEmptyState from '../Chart/ChartEmptyState';
import emptyStateMessage from '../../helpers/emptyState';
import { gaugeScoreSelectorConfig } from '../../selectors/accountabilitySelector';
import Gauge from '../../ui/Gauge';
import AccountabilityLegend from '../../ui/AccountabilityLegend';
import AccountabilityChangeLegend from '../../ui/AccountabilityChangeLegend';

import styles from './AcctGaugeChartV2.module.scss';

function AcctGaugeChartV2(props) {
  const {
    config,
    acctScores,
    node,
    organization,
    isLoading
  } = props;

  const scores = !acctScores ? [] : acctScores.filter(rec => get(rec, `rating.${config.scoreKey}`, '') !== '');
  const isDataEmpty = scores.length === 0;

  const listRisk = [{ label: 'Very Low' }, { label: 'Low' }, { label: 'Medium' }, { label: 'High' }, { label: 'Very High' }];
  const listStatus = [
    { label: 'Declined Significantly' },
    { label: 'Declined' },
    { label: 'Maintained' },
    { label: 'Increased' },
    { label: 'Increased Significantly' }
  ];
  const listColor = ['Red', 'Orange', 'Yellow', 'Green', 'Blue'];
  const icons = [
    { icon: 'bb-down-arrow', class: '' },
    { icon: 'bb-down-right-arrow', class: '' },
    { icon: 'bb-right-arrow', class: '' },
    { icon: 'bb-up-right-arrow', class: '' },
    { icon: 'bb-down-arrow', class: styles.revert }
  ];

  if (isDataEmpty) {
    return <ChartEmptyState emptyMessage={emptyStateMessage(node, organization)} />;
  }

  return (
    <div aria-hidden="true">
      <AccountabilityLegend label="Status" data={listRisk} />
      <AccountabilityChangeLegend data={listStatus} />
      <small>
        <i>For each state indicator, in addition to the five color-coded performance levels,
          there are five Status Levels ranging from very low to very high and five Change Levels
          ranging from declined significantly to increased significantly. “Status Score” is the current year
          performance for each indicator. “Change Score” is the difference in the current year Status Score and
          previous year Status Score of each indicator.</i>
      </small>
      <br />
      <br />
      <div className="gatewayGrid-fullcharts">
        {
          scores.map(({ heading, rating, changes, cmb }) => {
            let index = get(rating, `${config.scoreKey}`);
            let changeIndex = get(changes, `${config.scoreKey}`);
            const cmbIndex = get(cmb, `${config.scoreKey}`);

            index = (index === '') ? index : index - 1;
            changeIndex = (changeIndex === '') ? changeIndex : changeIndex - 1;

            return <Field className="multiInputField  maxWidth300">
              <Gauge
                title={heading}
                score={cmbIndex}
                isLoading={isLoading}
                labels={listColor}

              />
              <div width="100%" align="center">
                <span className={styles.dis_label}>
                  <Label className={styles.label} value="Status:" position="inline" />
                  <Icon
                    name={index !== '' ? 'bb-score-circle' : ''} className={`theme-kde-accountability2-fill-${index}`}
                  />
                  <Label
                    className={styles.label}
                    value={index === '' ? 'No Data available' : listRisk[index].label} position="inline"
                  />
                </span>
                <br />
                <span className={styles.dis_label}>
                  <Label className={styles.label} value="Change:" position="inline" />
                  <Icon
                    name={changeIndex !== '' ? icons[changeIndex].icon : ''}
                    className={`theme-kde-accountability2-fill-${changeIndex}
                    ${changeIndex !== '' ? icons[changeIndex].class : ''}`}
                  />
                  <Label
                    className={styles.label}
                    value={changeIndex === '' ? 'No Data available' : listStatus[changeIndex].label} position="inline"
                  />
                </span>
              </div>
            </Field>;
          })
        }
      </div>
    </div>
  );
}

function mapStateToProps(state, props) {
  const gaugeScoreSelector = gaugeScoreSelectorConfig(get(props, 'node.node_path'));

  return {
    acctScores: gaugeScoreSelector(state),
    framework: get(state, 'framework.kde')
  };
}

export default connect(mapStateToProps)(AcctGaugeChartV2);

AcctGaugeChartV2.propTypes = {
  config: PropTypes.object,
  acctScores: PropTypes.arrayOf(PropTypes.object),
  framework: PropTypes.object,
  node: PropTypes.object,
  organization: PropTypes.object,
  isLoading: PropTypes.bool
};
