import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { filterZeroValues } from '../../../chartHelpers';

const adapter = (options) => {
  const { node, currentOrgScore } = options;
  const scoringOptions = get(node, 'scoring_options', {});
  const shape = get(scoringOptions, 'shape', []);

  const data = sortBy(map(shape, group => (
    {
      ...group,
      value: map(group.value, (item) => {
        let value = get(currentOrgScore.value, `${group.key}.${item.key}`, 0);
        if (value === 'NULL') {
          value = 0;
        }

        return { ...item, value };
      })
    }
  )), ['index']);

  return (scoringOptions && scoringOptions.hideZeroValues) ? filterZeroValues(data) : data;
};

export default adapter;
