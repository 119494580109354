import {
  SET_LOADING,
  SET_LOADED
} from '../../common_actions';

const DEFAULT_STATE = {
  sources: [],
  isLoading: false
};

function loadingReducer(state = DEFAULT_STATE, action = {}) {
  if (action.type === SET_LOADING) {
    return {
      ...state,
      sources: state.sources.concat(action.source),
      isLoading: true
    };
  } else if (action.type === SET_LOADED) {
    const index = state.sources.indexOf(action.source);
    const sources = state.sources.slice(0, index).concat(state.sources.slice(index + 1));
    return {
      ...state,
      sources,
      isLoading: sources.length > 0
    };
  } else {
    return state;
  }
}

export default loadingReducer;
