/* eslint no-unused-vars: off */
import find from 'lodash/find';
import map from 'lodash/map';
import get from 'lodash/get';

import formatNum from 'format-num';
import numberFormatter from '../numberFormatter';

const EXPANSIONS = {
  H: 'High',
  M: 'Medium',
  L: 'Low',
  true: 'Yes',
  false: 'No'
};

export const FORMATTERS = {
  money: (v, { divider = 100, withZero = false, fraction = 2 } = {}) => {
    if ((v === 0 || v === '0') && !withZero) {
      return 'Free';
    }
    const baseNum = formatNum(v / divider, { minFraction: fraction, maxFraction: fraction });
    return `$${baseNum}`;
  },
  comma_delimited: (v, options = {}) => numberFormatter(v, 'number'),
  percent: (v, options = {}) => numberFormatter(v, 'percent'),
  twoDigits: (v, options = {}) => numberFormatter(v, 'percent', 2),
  identity: (v, options = {}) => v,
  boolean: (v, options = {}) => Boolean(v),
  percentage: (v, options = {})  => {
    const percentAsIntString = (v * 100).toFixed(0);
    if (options.almostZeroValue && v !== 0 && percentAsIntString === '0') {
      return options.almostZeroValue;
    }
    return `${percentAsIntString}%`;
  },
  usage: (v, options = {}) => EXPANSIONS[v],
  array: (v, options) =>  {
    const labels = map(v, value => get(find(options.label_map, { value }), 'label'));
    return labels.join('\n');
  }
};

export default function (name, value, ...args) {
  const formatter = FORMATTERS[name];

  if (!formatter) {
    console.log(`No formatter: ${name} is found.`); // eslint-disable-line no-console
    return value;
  }

  return formatter(value, ...args);
}
