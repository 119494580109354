import get from 'lodash/get';
import map from 'lodash/map';
import sum from 'lodash/sum';
import flatMap from 'lodash/flatMap';
import flatten from 'lodash/flatten';
import round from 'lodash/round';
import maxBy from 'lodash/maxBy';

import { currentOrgYearScores } from '../scoresHelpers';

const standardMax = { max: 100 };

const customScoringOptions = (node, max) => {
  const multipliers = get(node, 'metadata.chartGrid.multipliers', []);
  const upperBound = get(node, 'metadata.chartGrid.upperBound', 10);
  const multiplier = find(multipliers, mult => (max / mult) < upperBound);
  const customLabelCount = Math.ceil(max / multiplier);
  return {
    max: customLabelCount * multiplier,
    customLabelCount
  };
};

const dataMax = (highFromOptions, max, multiplier = 1.5) => highFromOptions || (Math.floor(max * multiplier));

function roundValueToDigits(value, numOfDigits, up = true) {
  const method = up ? Math.ceil : Math.floor;
  return method(value / (10 ** numOfDigits)) * (10 ** numOfDigits);
}

// Compute nicer axis labels by rounding the interval computed from dividing min max range by the labelCount
export function findMinMaxByRounding(labelCount, max, min = 0) {
  let roundedMin = Math.floor(min);
  if (min > 10) {
    // round down the min to find a nicer value as new min
    const numDigitsOfMin = `${Math.ceil(min)}`.length;
    const roundToDigitsOfMin = numDigitsOfMin < 3 ? numDigitsOfMin - 1 : numDigitsOfMin - 2;
    roundedMin = roundValueToDigits(min, roundToDigitsOfMin, false);
  }
  // Compute the interval given min max range
  const interval = Math.ceil((max - roundedMin) / (labelCount - 1));
  const numOfDigits = `${Math.ceil(interval)}`.length;
  let roundToDigits = numOfDigits < 3 ? 0 : numOfDigits - 2;
  if (min > 0) {
    roundToDigits = numOfDigits - 1;
  }
  // Round the interval to find a nicer interval
  const newInterval = roundValueToDigits(interval, roundToDigits);

  return {
    max: roundedMin + (newInterval * (labelCount - 1)),
    min: roundedMin
  };
}

const barChartMax = (options) => {
  const { node, currentOrgScore, config } = options;
  const configOptions = config.options || {};
  const scoringOptions = get(node, 'scoring_options', {});
  const minValue = configOptions.low || 0;
  const maxValue = currentOrgScore && Math.max.apply(null, Object.values(currentOrgScore.value));
  let result;

  if (get(scoringOptions, 'customGridAttributes')) {
    result = customScoringOptions(node, maxValue);
  } else if (get(configOptions, 'yAxis.rounded', false)) {
    const labelCount = get(configOptions, 'yAxis.labelCount', 2);
    result = findMinMaxByRounding(labelCount, maxValue, minValue);
  } else {
    result = { max: dataMax(configOptions.high, maxValue, scoringOptions.maxMultiplier) };
  }

  return result;
};

export default {
  barChart: barChartMax,
  roundedDynamicBarChart: (options) => {
    const { data } = options;
    const highestValue = maxBy(data, d => d.value).value;
    const labels = get(options, 'config.options.yAxis.labelCount', 6) - 1;
    // rounded to 10, "Math.ceil(Math.round(parseInt((highestValue / labels) + 1) / 20) / 5) * 500" for 100
    return { max: highestValue % 10 === 0 ? highestValue : Math.ceil(Math.round(parseInt((highestValue / labels) + 1) / 2) / 5) * 50 };
  },
  staticMaxValueChart: options => ({ max: get(options, 'config.options.high', 100) }),
  demographicBarChart: (options) => {
    const { node, currentOrgScore, config } = options;
    const configOptions = config.options || {};
    const scoringOptions = get(node, 'scoring_options', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values(currentOrgScore.value || {}));
    return scoringOptions.customGridAttributes ?
      customScoringOptions(node, max) :
      { max: dataMax(configOptions.high, max, scoringOptions.maxMultiplier) };
  },
  default: () => standardMax,
  entityComparisonBarChart: () => barChartMax,
  demographicEntityComparisonBarChart: () => barChartMax,
  demographicEntityComparisonFilteredBarChart: () => standardMax,
  FilteredChart: () => standardMax,
  selfDescribedPercentAdapter: () => standardMax,
  confidenceIntervalsBarChart: (options) => {
    const { node, currentOrgScore, config } = options;
    const configOptions = config.options || {};
    const scoringOptions = get(node, 'scoring_options', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values(currentOrgScore.value));
    return scoringOptions.customGridAttributes ?
      customScoringOptions(node, max) :
      { max: dataMax(configOptions.high, max, scoringOptions.maxMultiplier) };
  },
  entityBarChart: (options) => {
    const { currentOrgScore } = options;
    const value = get(currentOrgScore, 'value', {});
    return currentOrgScore && Math.max.apply(null, Object.values(value));
  },
  percentBarChart: (options) => {
    const { currentOrgScore, node } = options;
    const scoringOptions = get(node, 'scoring_options', {});
    const isProportion = scoringOptions.type === 'proportion';
    const value = get(currentOrgScore, 'value', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values(value));
    return isProportion ? (max * 100) : max;
  },
  demographicPercentBarChart: (options) => {
    const { currentOrgScore, node } = options;
    const scoringOptions = get(node, 'scoring_options', {});
    const isProportion = scoringOptions.type === 'proportion';
    const value = get(currentOrgScore, 'value', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values(value));
    return isProportion ? (max * 100) : max;
  },
  dateBarChart: (options) => {
    const { currentOrgScore, node } = options;
    const scoringOptions = get(node, 'scoring_options', {});
    const isProportion = scoringOptions.type === 'proportion';
    const value = get(currentOrgScore, 'value', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values(value));
    return isProportion ? (max * 100) : max;
  },
  groupedBarChart: (options, ...chartOptions) => {
    const { currentOrgScore, node, scores, organization, config } = options;
    const high = chartOptions[1];

    if (high) {
      return { max: high };
    }
    const shape = get(node, 'scoring_options.shape', []);
    const nodePathsForMax = get(node, 'scoring_options.node_paths_for_max');
    let currentOrgScores;
    // max could be in any of these node_paths
    if (nodePathsForMax) {
      currentOrgScores = nodePathsForMax.map(
        nodePath => currentOrgYearScores(scores, organization, { node_path: nodePath })
      );
    } else {
      currentOrgScores = [currentOrgScore];
    }

    const values = flatMap(currentOrgScores, currentScore =>
      // example shape = [
      //   { // shapeGroup
      //     "key": "below_average",
      //     "label": "Below Average Success",
      //     "value": [
      //       {"key": "l", "colorIndex": 0}, // shapeGroupItem
      //       {"key": "m", "colorIndex": 1},
      //       {"key": "h", "colorIndex": 2}
      //     ]
      //   },
      //   ...
      // ];
      //
      // example currentScore.value = {
      //   "below_average": {
      //     "student_cnt": "14",
      //     "l": "10",
      //     "m": "4",
      //     "h": "NULL" // will be turned into 0
      //   },
      //   ...
      // };
      //
      // "student_cnt" will be ignored as a potential max value,
      //   as it is not one of the shapeGroupItem keys.
      flatMap(shape, shapeGroup => map(
        shapeGroup.value,
        shapeGroupItem =>
          get(currentScore, `value.${shapeGroup.key}.${shapeGroupItem.key}`)
      ))
    );

    // there may be "NULL" values (parsed to NaN, then ORed into zeroes)
    const cleanValues = map(values, value => (parseFloat(value) || 0));
    const maxValue =  Math.max(...cleanValues);
    const minValue = chartOptions[0];

    if (get(config, 'options.yAxis.rounded', false)) {
      const labelCount = get(config, 'options.yAxis.labelCount', 2);
      return findMinMaxByRounding(labelCount, maxValue, minValue);
    }
    return { max: Math.ceil((maxValue / 10) * 11), min: minValue };
  },
  dddGroupedBarChartAdapter: (options) => {
    const { node, currentOrgScore, config } = options;
    const configOptions = config.options || {};
    const scoringOptions = get(node, 'scoring_options', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values((currentOrgScore.value || currentOrgScore.values)));
    return scoringOptions.customGridAttributes ?
      customScoringOptions(node, max) :
      { max: dataMax(configOptions.high, max, scoringOptions.maxMultiplier) };
  },
  trendsChart: (options) => {
    const { scores, data, config, node } = options;
    const configOptions = config.options || {};
    const scoringOptions = node.scoringOptions || {};
    const trendsDataMax = (highFromOptions, max, multiplier = 1.1) => {
      const upperBuffer = (Math.abs(max) * multiplier) - Math.abs(max);
      return round(highFromOptions || max + upperBuffer);
    };
    const maxValue = scores && Math.max.apply(null, flatten(map(data, line => map(line.values, point => point.y))));
    let minValue;
    if (get(config, 'options.yAxis.rounded', false)) {
      const labelCount = get(config, 'options.yAxis.labelCount', 2);
      minValue = scores && Math.min.apply(null, flatten(map(data, line => map(line.values, point => point.y))));
      return findMinMaxByRounding(labelCount, maxValue, minValue);
    }

    return {
      max: trendsDataMax(configOptions.yAxis.high, maxValue, scoringOptions.maxMultiplier)
    };
  },
  stackedBarChart: (options) => {
    const { data } = options;
    const valueMaxes = map(data, dataValue => sum(map(dataValue.value, value => value.value)));
    return {
      max: (Math.ceil(valueMaxes && Math.max.apply(null, Object.values(valueMaxes))) + 10)
    };
  },
  donutChart: (options) => {
    const { shape, currentOrgScore } = options;
    return shape && Object.keys(shape).reduce((total, key) => total + parseFloat(get(currentOrgScore, `value[${key}]`, 0)), 0);
  },
  demographicDonutChart: (options) => {
    const { shape, currentOrgScore } = options;
    return shape && Object.keys(shape).reduce((total, key) => total + parseFloat(get(currentOrgScore, `value[${key}]`, 0)), 0);
  },
  multiScoresDonutChart: (options) => {
    const { scores, node } = options;
    const { scoring_options: scoringOptions } = node;
    return Math.round(scores.reduce((multiSum, score) => multiSum + parseFloat(score[scoringOptions.totalAggregateKey]), 0));
  },
  genericScoreDonutChart: (options) => {
    const { scores, node } = options;
    const { scoring_options: scoringOptions } = node;
    return Math.round(scores.reduce((multiSum, score) => multiSum + parseFloat(score[scoringOptions.totalAggregateKey]), 0));
  },
  BarChartWithTitle: (options) => {
    const { node, currentOrgScore, config } = options;
    const configOptions = config.options || {};
    const scoringOptions = get(node, 'scoring_options', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values((currentOrgScore.value || currentOrgScore.values)));
    return scoringOptions.customGridAttributes ?
      customScoringOptions(node, max) :
      { max: dataMax(configOptions.high, max, scoringOptions.maxMultiplier) };
  },
  multiFrameworkBarChart: (options) => {
    const { node, currentOrgScore, config } = options;
    const configOptions = config.options || {};
    const scoringOptions = get(node, 'scoring_options', {});
    const max = currentOrgScore && Math.max.apply(null, Object.values((currentOrgScore.value || currentOrgScore.values)));
    return scoringOptions.customGridAttributes ?
      customScoringOptions(node, max) :
      { max: dataMax(configOptions.high, max, scoringOptions.maxMultiplier) };
  }
};
