import get from 'lodash/get';
import uniq from 'lodash/uniq';
import snakeCase from 'lodash/snakeCase';
import first from 'lodash/first';
import last from 'lodash/last';

import { levelsForSchool, levelsToScoreKeys } from './orgHelper';
import { isDistrict, isState, isSchool } from './completionStatusHelpers/collection/utils';

// The cuts data comes from OSAA/ACCT_Cuts_2019_Sample.csv file
// Note alternatively, we could import cuts data as data point
// so that we don't need to manually update this file
export const CUTS = {
  2019: {
    elementary_school: {
      proficiency: {
        scale: [40, 51, 67, 77, 90, 110],
        min: 0,
        max: 125
      },
      seperate: {
        scale: [40, 50, 62, 72, 80, 100],
        min: 0,
        max: 125
      },
      growth: {
        scale: [38, 48, 55, 60, 69, 80],
        min: 0,
        max: 300
      }
    },
    middle_school: {
      proficiency: {
        scale: [50, 60, 70, 76, 86, 96],
        min: 0,
        max: 125
      },
      seperate: {
        scale: [40, 52, 61, 70, 79, 90],
        min: 0,
        max: 125
      },
      growth: {
        scale: [36, 46, 51, 57, 61, 71],
        min: 0,
        max: 300
      }
    },
    high_school: {
      proficiency: {
        scale: [34, 44, 54, 65, 74, 84],
        min: 0,
        max: 125
      },
      seperate: {
        scale: [41, 51, 61, 70, 75, 85],
        min: 0,
        max: 125
      },
      transition: {
        scale: [46, 56, 69, 80, 88, 98],
        min: 0,
        max: 125
      },
      grad: {
        scale: [88, 90, 93, 95, 97, 99],
        min: 0,
        max: 100
      }
    }
  }
};

export const INDICATORS = [
  {
    title: 'Proficiency',
    scoreKey: 'proficiency',
    link: 'proficiency'
  },
  {
    title: 'Separate Academic',
    scoreKey: 'seperate',
    link: 'separate_academic_indicator'
  },
  {
    title: 'Growth',
    scoreKey: 'growth',
    link: 'growth'
  },
  {
    title: 'Transition Readiness',
    scoreKey: 'transition',
    link: 'transition_readiness'
  },
  {
    title: 'Graduation Rate',
    scoreKey: 'grad',
    link: 'graduation_rate'
  }
];

const PROFICIENCY_INDICATOR = 0;
const SEPARATE_ACADEMIC_INDICATOR = 1;
const GROWTH_INDICATOR = 2;
const TRANSITION_READINESS_INDICATOR = 3;
const GRADUATION_RATE_INDICATOR = 4;

// Controls what indicators to show for a school level
// Elementary and middle school level can see Proficiency, Separate Academic, and Growth indicators
// High school level can see Proficiency, Separate Academic, Transition Readiness, and Graduation Rate indicators.
export const INDICATORS_BY_LEVELS = {
  ES: [PROFICIENCY_INDICATOR, SEPARATE_ACADEMIC_INDICATOR, GROWTH_INDICATOR],
  MS: [PROFICIENCY_INDICATOR, SEPARATE_ACADEMIC_INDICATOR, GROWTH_INDICATOR],
  HS: [PROFICIENCY_INDICATOR, SEPARATE_ACADEMIC_INDICATOR, TRANSITION_READINESS_INDICATOR, GRADUATION_RATE_INDICATOR]
};

export const COLOR_INDEX = {
  very_low: 1,
  low: 2,
  medium: 3,
  high: 4,
  very_high: 5
};

export function indicatorOutBoundLink(orgId, year, indicator) {
  return `/organization/${orgId}/school_accountability/${indicator}/indicator_score?year=${year}`;
}

export function gapsOutBoundLink(orgId, year, level) {
  return `/organization/${orgId}/school_accountability/achievement_gaps/indicator_score?year=${year}&current_item=${level}`;
}

export function schoolLevelsForIndicator(org, indicator) {
  const indicatorIndex = INDICATORS.findIndex(i => i.scoreKey === indicator);
  let levels;

  if (isState(org) || isDistrict(org)) {
    // for each level of district and state, only return the level that has the indicator
    levels = Object.keys(INDICATORS_BY_LEVELS);
  } else {
    levels = levelsForSchool(org);
  }

  return levelsToScoreKeys(levels.filter(level => INDICATORS_BY_LEVELS[level].includes(indicatorIndex)));
}

export function getAcctCut(year, level, indicator) {
  if (!level) {
    throw new Error('school level is required for computing the cuts.');
  }

  const cuts = get(CUTS, [year, level, indicator], {});

  if (cuts.scale) {
    return {
      ...cuts,
      rangeLow: first(cuts.scale),
      rangeHigh: last(cuts.scale)
    };
  }

  return cuts;
}

export function getIndicatorsForOrg(org) {
  if (isState(org) || isDistrict(org)) {
    // State and district see all indicators
    return INDICATORS;
  } else if (isSchool(org)) {
    // Some schools have multiple school levels
    // We will need to combine the indicators for multiple levels
    const indicators = (org.school_level || '')
      .split(',')
      .reduce((memo, level) => ([...memo, ...(INDICATORS_BY_LEVELS[level] || [])]), [])
      .sort();
    return uniq(indicators).map(i => INDICATORS[i]);
  }

  return [];
}

export function getColorIndex(label) {
  const index = COLOR_INDEX[snakeCase(label)];
  return index === undefined ? -1 : index;
}

export function achievementGaps(gaps = []) {
  return gaps.map((gap) => {
    const gapDemos = gap.split(':');

    return {
      gap: get(gapDemos, '0', ''),
      ctrlGroup: get(gapDemos, '1', '')
    };
  });
}
