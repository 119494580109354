import React from 'react';
import PropTypes from 'prop-types';

Charts.propTypes = {
  children: PropTypes.node
};

function Charts({ children }) {
  return (
    <div className="gatewayGrid-charts">
      { children }
    </div>
  );
}

Charts.defaultProps = {
  'data-slotName': 'Charts'
};

export default Charts;
