import get from 'lodash/get';

// take first N items from the list
// @param currentOrgScore array of object
// @return first N items
export default function adapter(currentOrgScore, { chartConfig }) {
  if (Array.isArray(currentOrgScore)) {
    const numToDisplay = get(chartConfig, 'options.numToDisplay', 0);
    const noArray = get(chartConfig, 'options.noArray');
    const result = currentOrgScore.slice(0, numToDisplay);
    return numToDisplay === 1 && noArray && result.length ? result[0] : result;
  }

  return [];
}
