import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import reduce from 'lodash/reduce';
import get from 'lodash/get';

import ScoreDot from 'cui/lib/components/ScoreDot';
import Icon from 'cui/lib/components/Icon';

import connected from '../connected';
import template from '../../helpers/template';

export const EntityComparisonDetailsPopover = (props) => {
  const { data, theme } = props;
  const { label, organization, baseUrl } = data;

  const renderOrganizationLink = () => {
    const domainSlug = get(props, 'match.params.domain');
    if (organization && domainSlug && baseUrl) {
      return (
        <a href={template(baseUrl, { id: organization.id, domainSlug  })}>
          { label } <Icon name="bb-caret-right" />
        </a>
      );
    }
    return <p>{label}</p>;
  };

  return (
    <div style={{ padding: '0.5rem' }}>
      {renderOrganizationLink()}
      { reduce(data.value, (acc, datum) => {
        if (datum.hidden === undefined || !datum.hidden) {
          acc.push(
            <div key={datum.label}>
              <ScoreDot theme={theme} value={datum.colorIndex} />
              {parseInt(datum.value)}% {datum.label}
            </div>
          );
        }
        return acc;
      }, [])
      }
    </div>
  );
};

EntityComparisonDetailsPopover.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string
};

EntityComparisonDetailsPopover.defaultProps = {
  theme: 'rainbow'
};

export default withRouter(connected(EntityComparisonDetailsPopover));
