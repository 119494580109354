import get from 'lodash/get';

// extract the score under scoreKey
export default function adapter({ currentOrgScore, chartConfig }) {
  const scoreKey = get(chartConfig, 'scoreKey');
  if (scoreKey) {
    return get(currentOrgScore, scoreKey);
  }

  return currentOrgScore;
}
