import React from 'react';
import { withRouter } from 'react-router';

import Heading from 'cui/lib/components/Heading';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../helpers/scoreLoadActions';
import Footer from '../components/Footer';
import Masthead from '../components/Masthead';
import StaticPageContent from '../components/StaticPageContent';
import MainContentAnchor from '../components/MainContentAnchor';
import pageTitle from '../helpers/pageHelpers';

const Privacy = () => (
  <div>
    <ScrollToTopOnMount />
    <Masthead withSearchBar />
    <MainContentAnchor />
    <StaticPageContent>
      <div role="main">
        <Heading size="xxLarge" level={1}>Privacy</Heading>
        <div className="cui-textBlock">
          <p>
            Your privacy and security are our highest priority. The Kentucky Department of Education
            (KDE) collects only the personal information necessary to provide enhanced services to our
            citizens and customers. Please note that all non-exempt information collected by KDE may
            be subject to public disclosure under KRS 61.870, the Kentucky Open Records Act.
            However, public records containing information of a personal nature are protected from
            disclosure by the personal privacy exemption of state law. Personal data in our possession
            is maintained and used in ways that respect individual privacy. KDE uses the Secure
            Sockets Layer (SSL) protocol to safeguard your sensitive personal information.
          </p>
          <p>
            Depending on the situation, we may collect the following information about visitors to our
            website:
          </p>
          <ul>
            <li>the domain name, but not the e-mail address;</li>
            <li>the e-mail addresses of those who communicate with us via e-mail;</li>
            <li>aggregate information about which pages consumers access or visit; or</li>
            <li>information volunteered by the consumer, such as survey information.</li>
          </ul>
          <p>
            This information is used only to improve the scope and content of our services on the Internet.
          </p>
          <Heading size="base" level={2}>KDE Information Sharing</Heading>
          <p>
            KDE will not release personal information to third parties except where it is already provided
            by law for purposes of notification. We will not provide, rent or sell personal information to
            third parties for marketing programs or any other purpose. State law restricts the use of
            personally identifiable information for commercial purposes.
          </p>
          <p>
            Depending on the specific nature of the service, information may be shared with other state
            agencies as stated in KRS 61.878 (5).
            This statute allows for the exchange of public records
            or sharing of information between public agencies when the exchange is serving a
            legitimate governmental need or is necessary in the performance of a legitimate
            government function.
          </p>
          <Heading size="base" level={2}>KDE and Internet Cookies</Heading>
          <p>
            Cookies are short and simple text files stored on your computer by internet websites to help
            identify users and enhance customer service. We may use cookies to customize and
            personalize your service transactions with state agencies.
          </p>
          <p>
            Cookies do not compromise your privacy or security. Using web browser settings, you can
            refuse the cookies or delete the cookie file from your computer by using any of the widely
            available methods.
          </p>
        </div>
      </div>
    </StaticPageContent>
    <Footer />
  </div>
);

export const config = {
  title: pageTitle('Privacy'),
  initActions: [
    LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION
  ]
};

export default withRouter(Privacy);
