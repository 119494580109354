import reduce from 'lodash/reduce';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import flattenDeep from 'lodash/flattenDeep';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';

import { currentOrgYearScores } from './scoresHelpers';
import when from './when';
import { shouldUsePortalDataService } from './nodeHelpers';
import { KENTUCKY_ORG_ID, ORG_TYPES } from '../constants';

/* eslint-disable camelcase */
const getDateRange = ({ start_at, end_at }) => ({ start_at, end_at });
/* eslint-enable camelcase */

function dataFromPortal(dp) {
  return dp.portal_data || get(dp, 'metadata.portal_data') || get(dp, 'metadata.dataSources');
}

function extractDates(queryResult) {
  const scoreDates = map(queryResult.scores, getDateRange);
  const dimensionDates = flatMap(queryResult.dimensions, extractDates);
  return uniqBy(scoreDates.concat(dimensionDates), isEqual);
}

function scoresForDate(queryResult, dateRange, collectAllScoresForSameDate) {
  if (collectAllScoresForSameDate) {
    return queryResult.scores.filter(score => isEqual(dateRange, getDateRange(score)))
      .map(score => get(score, 'value'));
  }

  return get(queryResult.scores.find(score => isEqual(dateRange, getDateRange(score))), 'value');
}

const extractScoresWithDate = (queryResult, dateRange, collectAllScoresForSameDate) => ({
  score: scoresForDate(queryResult, dateRange, collectAllScoresForSameDate),
  dimensions: reduce(queryResult.dimensions, (acc, dimensionQueryResult, dimension) => {
    acc[dimension] = extractScoresWithDate(dimensionQueryResult, dateRange, collectAllScoresForSameDate);
    return acc;
  }, {})
});

const segmentScoresByDate = (queryResult, collectAllScoresForSameDate) => map(extractDates(queryResult), dateRange => ({
  start_at: dateRange.start_at,
  end_at: dateRange.end_at,
  value: extractScoresWithDate(queryResult, dateRange, collectAllScoresForSameDate)
}));

export const getOrgIdFromOrganization = ({
  entity_type: entityType,
  dist_number: districtId,
  sch_number: schoolId
}) => {
  let orgId;
  if (entityType === 'State') {
    orgId = 'State';
  } else if (entityType === 'District') {
    orgId = districtId && districtId.toString().padStart(3, '0');
  } else {
    orgId = districtId && schoolId && `${districtId.toString().padStart(3, '0')}_${schoolId.toString().padStart(3, '0')}`;
  }

  return orgId;
};

export const getOrgDataAttribute = (organization, attribute, year) => {
  const orgData = get(organization, 'organization_data', []).find(data => data.year === year);
  return get(orgData, attribute);
};

export function filterByWhen(config, context) {
  return config.when ? when(config.when, context) : true;
}

export const scoresAreFromGroot = (node, whenContext) => {
  const grootSources = get(node, 'metadata.dataSources', [])
    .filter(dataSource => (dataSource.node_path === node.node_path && dataSource.source === 'groot'
      && dataSource.when ? when(dataSource.when, whenContext) : true)
    );

  return grootSources.length > 0;
};

export function mergeScores(grootScores, portalDataServiceScores, currentNodes, orgIdMap, currentOrganization) {
  const nodesByNodePath = keyBy(currentNodes, 'node_path');

  return filter(grootScores, (score) => {
    const nodePath = nodesByNodePath[get(score, 'framework_tree_node.node_path')];
    return !shouldUsePortalDataService(nodePath) || scoresAreFromGroot(nodePath, { currentOrganization });
  }).concat(transformScoresFromPortalDataService(portalDataServiceScores, currentNodes, orgIdMap));
}

export function transformScoresFromPortalDataService(scoresFromPortalDataService, currentNodes, orgIdMap, year) {
  const scoresByNodePath = reduce(scoresFromPortalDataService, (acc, scoresByOrgId, nodePath) => {
    const currentNode = (currentNodes || []).find(n => n.node_path === nodePath);
    const collectAllScoresForSameDate = get(currentNode, 'metadata.collectAllScoresForSameDate', false);
    // Same data points(courses offered) have multiple scores for the same date range, we need to collect all of them
    // when collectAllScoresForSameDate is configured to be true at the data point config
    acc[nodePath] = reduce(scoresByOrgId, (acc1, score, orgId) => {
      acc1[orgId] = segmentScoresByDate(score, collectAllScoresForSameDate);
      return acc1;
    }, {});
    return acc;
  }, {});

  /* eslint-disable arrow-body-style */
  return flattenDeep(map(filter(currentNodes, dataFromPortal), (node) => {
    return map(get(scoresByNodePath, [node.node_path], []), (scores, orgId) => {
      return map(scores, score => ({
        ...score,
        framework_tree_node: {
          node_path: node.node_path
        },
        remote_organization_id: orgIdMap[orgId],
        portal_org_id: orgId,
        schoolYear: year
      }));
    });
  }));
  /* eslint-enable arrow-body-style */
}

// Get dimensions defined in the portal_data config
export function getDimensionsForOrg(node, currentOrg) {
  const entityType = get(currentOrg, 'entity_type', '').toLowerCase();
  const dimensions = get(node, 'portal_data.dimensions');
  // If dimensions is an object with state, district, and school keys
  // Get dimension according to current org entity type
  // Otherwise return the dimensions
  return get(dimensions, entityType, dimensions);
}

export function calculatePercentage(chartConfig, score) {
  const percentageNumeratorKey = get(chartConfig, 'percentageNumeratorKey');
  const percentageDenumeratorKey = get(chartConfig, 'percentageDenumeratorKey');
  // Compute percentage based on percentageNumeratorKey and percentageDenumeratorKey
  if (percentageNumeratorKey && percentageDenumeratorKey) {
    return reduce(score, (memo, value, key) => {
      const percentageDenumerator = parseInt(get(value, percentageDenumeratorKey, 0));
      const percentageNumerator = parseInt(get(value, percentageNumeratorKey, 0));
      const percentage = Math.round((percentageNumerator / percentageDenumerator) * 100);
      memo[key] = { ...value, percentage: Number.isNaN(percentage) ? 0 : percentage };
      return memo;
    }, {});
  }

  return score;
}

// @param organization {object}, organization
// @return organization entity type in lower case
export function getOrgType(organization) {
  return (get(organization, 'entity_type') || '').toLowerCase();
}

// @param scores { array }, array of all score objects
// @param dataPoints { array }, array of data points
// @param currentOrganization { object }, current organization object
// @return object of scores for current organization keyed by slug of data points
export function getDataForOrganization(scores, node, currentOrganization) {
  const dataPoints = getDataPointsForNode(node);

  return dataPoints.reduce((memo, dataPoint) => {
    // Add score for each related data points keyed by the data point slug
    const score = currentOrgYearScores(scores, currentOrganization, dataPoint);
    memo[dataPoint.slug] = get(score, 'value');

    return memo;
  }, {});
}

// @params node { object }, framework tree node
// @return array of data points related to the node
function getDataPointsForNode(node) {
  // Get all related data points because current data point may depend on other data points
  const dataPoints = get(node, 'metadata.dataSources', [])
    .filter(source => get(source, 'node_options.includeParentData'));

  if (get(node, 'metadata.includeParentData', false)) {
    dataPoints.push(node);
  }

  return uniqBy(dataPoints, 'node_path');
}

// @param scores {array}, array of all scores
// @param currentOrganization { object }, current organization
// @param node {object}, framework tree node
// @return object with parent org data depends on the current organization
export function getParentData(scores, currentOrganization, node) {
  const parentData = {};
  const entityType = getOrgType(currentOrganization);

  // only school or district has parent data
  if (['school', 'district'].includes(entityType)) {
    const stateOrg = { id: KENTUCKY_ORG_ID, entity_type: 'State' };

    if (entityType === 'school') {
      // Include parent district data when current org is school.
      parentData.district = getDataForOrganization(scores, node, currentOrganization.parentDistrict);
    }

    // Include state data for school or district
    parentData.state = getDataForOrganization(scores, node, stateOrg);
  }

  return parentData;
}

// @param scores {array}, array of all scores
// @param currentOrganization { object }, current organization
// @param node {object}, framework tree node
// @return object with parent org data and current org data
export function getChartData(scores, currentOrganization, node) {
  const entityType = getOrgType(currentOrganization);

  return {
    ...getParentData(scores, currentOrganization, node),
    [entityType]: getDataForOrganization(scores, node, currentOrganization)
  };
}

// @param organization {object}, current organization
// @param type {string}, asserted organization type
// @return {boolean} stating whether questioned organization is of certain type
export const isOrganizationOfType = (organization, type) => organization.entity_type.toUpperCase() === ORG_TYPES[type.toUpperCase()];

// @param scores {array}, array of all scores
// @return {object} with scores for state
export const getStateScoresForOrganization = scores => scores.find(score => score.remote_organization_id === KENTUCKY_ORG_ID) || {};

// @param scores {array}, array of all scores
// @param organization {object}, current organization
// @return {object} with scores for district
export const getDistrictScoresForOrganization = (scores, organization) => {
  if (isOrganizationOfType(organization, ORG_TYPES.DISTRICT)) {
    return scores.find(score => score.remote_organization_id === organization.id) || {};
  }

  if (isOrganizationOfType(organization, ORG_TYPES.SCHOOL)) {
    if (!organization.parentDistrict) return {};
    return scores.find(score => score.remote_organization_id === organization.parentDistrict.id) || {};
  }

  return {};
};

// @param scores {array}, array of all scores
// @param organization {object}, current organization
// @return {object} with scores for school
export const getSchoolScoresForOrganization = (scores, organization) => {
  let scoreForOrg = {};

  if (isOrganizationOfType(organization, ORG_TYPES.SCHOOL)) {
    scoreForOrg = scores.find(score => score.remote_organization_id === organization.id) || {};
  }

  return get(scoreForOrg, 'value.score') || scoreForOrg;
};
