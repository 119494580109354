import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'cui';

import Link from '../../components/Link';
import styles from './ScoreBar.module.scss';

// determine the proper lenth of the bar, i.e. "percentage", based on the score passed combined with the low/high range
function percentage(score, rangeLow, rangeHigh) {
  const percent = ((score - rangeLow) / (rangeHigh - rangeLow)) * 100;

  if (percent < 0) {
    return 0;
  } else if (percent > 100) {
    return 100;
  }

  return percent;
}

// since cui css themes start with "0", take the bucket number passed and subtract one to render the proper theme.
// Note:  if buckets themselves will start at 0, this won't be needed.  I'm just assuming (maybe incorrectly) that if a chart has five buckets, it's
// best and most human understandable for us to be able to refer to them as bucket 1 through bucket 5, instead of bucket 0 through bucket 4
const themeIndex = scoreBucketInt => (
  scoreBucketInt - 1
);

const ScoreBar = ({
  score,
  rangeLow,
  rangeHigh,
  title,
  scoreMax,
  scoreBucket,
  scoreBucketInt,
  size,
  linkTo,
  theme,
  precision,
  noHoverTip,
  noLinkToDataPoint
}) => {
  let Wrapper;
  let wrapperProps = {};

  // render div as wrapper element when we don't need it to be a anchor link.
  if (noLinkToDataPoint) {
    Wrapper = 'div';
  } else {
    Wrapper = Link;
    wrapperProps = {
      to: linkTo
    };

    if (!noHoverTip) {
      wrapperProps.title = 'Explore this data';
    }
  }

  return (
    <Wrapper className={styles.scoreBar} {...wrapperProps}>
      <div className={`${styles.label} ${size}`}>
        <span>
          { title && <span>{title}</span> }{ title && scoreBucket && <span> - </span> }<span className={styles.label_bucket}>{scoreBucket}</span>
        </span>
      </div>
      <div className={styles.barGroup}>
        <div className={`${styles.bar} ${size}`}>
          <Progress
            data={[{ percent: percentage(score, rangeLow, rangeHigh), colorIndex: themeIndex(scoreBucketInt) }]}
            theme={theme} size={size}
          />
          {
            score && (
              <div
                className={`${styles.dot} theme-${theme}-border-${themeIndex(scoreBucketInt)}`}
                style={{ left: `${percentage(score, rangeLow, rangeHigh)}%` }}
              />
            )
          }
        </div>
        <span className={styles.score}>
          { score
            ? <span>{ (+score).toFixed(precision) }</span>
            : <span>&#8729;</span> }
          &nbsp;/ {scoreMax}
        </span>
      </div>
      { !score &&
        <div className={`${styles.emptyState} ${size}`}>
          No data reported.
        </div>
      }
    </Wrapper>
  );
};

ScoreBar.propTypes = {
  score: PropTypes.number.isRequired,
  rangeLow: PropTypes.number.isRequired,
  rangeHigh: PropTypes.number.isRequired,
  scoreMax: PropTypes.number.isRequired,
  precision: PropTypes.number.isRequired,
  scoreBucket: PropTypes.string,
  scoreBucketInt: PropTypes.number,
  size: PropTypes.oneOf(['small', 'large']),
  title: PropTypes.string,
  linkTo: PropTypes.string,
  theme: PropTypes.string,
  noHoverTip: PropTypes.bool,
  noLinkToDataPoint: PropTypes.bool
};

ScoreBar.defaultProps = {
  precision: 1,
  size: 'large',
  linkTo: '/',
  theme: 'kde-accountability2'
};

export default ScoreBar;
