import { connect } from 'react-redux';

import { mapStateToProps } from '../helpers/propMapper';

import awaitProps, { isAwaitingProps } from '../helpers/awaitProps';

export const connected = (Component, mapDispatchToProps, mapState) => (
  connect((state, ownProps) => {
    const stateProps = mapStateToProps(state, ownProps);

    // check for props to await here, in case there are fields in `mapState` that use async data
    if (mapState && !isAwaitingProps({ ...stateProps, ...ownProps })) {
      // call mapState defined on a component to generate additional props for the component
      return { ...mapState({ ...stateProps, ...ownProps }), ...stateProps };
    }

    return stateProps;
  }, mapDispatchToProps)(awaitProps(Component))
);

export default connected;
