import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import PropTypes from 'prop-types';
import CuiDownloadHelpers from 'cui/lib/components/PngDownloader';

const ICON_SIZE = 30;

function HiddenForm({ content, url, token, filename = '' }) {
  return (
    <div>
      <form action={url} method="POST" style={{ display: 'none' }} target="reportPdf">
        <input type="hidden" name="pdfContent" value={content} />
        <input type="hidden" name="token" value={token} />
        <input type="hidden" name="filename" value={filename} />
      </form>
      <iframe title="reportPdf" name="reportPdf" style={{ display: 'none' }} />
    </div>
  );
}

HiddenForm.propTypes = {
  content: PropTypes.string.isRequired,
  filename: PropTypes.string,
  token: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
};

function defaultTemplate(pdfContent) {
  return `
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <title>KDE School Profile Report</title>
      </head>
      <body>
        ${pdfContent}
      </body>
    </html>
  `;
}

export default class PDFDownload {
  constructor(endPoint) {
    this.url = endPoint;
    this.container = null;
  }

  replaceSvgWithImg(htmlContent) {
    // replace svg with image element
    return htmlContent.replace(/<svg[\s\S]*?>[\s\S]*?<\/svg>/g, svgContent => this.svgToImg(svgContent));
  }

  svgToImg(svgContent) {
    // pdf generator weasyprint doesn't support inline svg
    // encode svg in a data url
    const className = svgContent.includes('cui-icon') ? 'class="kde-pdf-icon"' : '';

    return `<img src="data:image/svg+xml;base64,${btoa(svgContent)}" ${className} />`;
  }

  processSvgIcon(el) {
    // weasyprint doesn't support svg use element
    // We need to fetch the svg icon from the definition section and replace
    // the use element with the icon elements.
    el.querySelectorAll('svg').forEach((svg) => {
      const svgUse = svg.querySelector('use');

      if (svgUse) {
        const svgPath = svgUse.getAttribute('xlink:href');
        const svgContent = document.querySelectorAll(`${svgPath} *`);

        if (svgContent) {
          svg.innerHTML = '';
          svgContent.forEach((svgEl) => {
            svg.appendChild(svgEl.cloneNode(true));
          });
          svg.setAttribute('viewBox', '0 0 32 32');
        }
      }
    });
  }

  setSvgSize(el) {
    let width;
    let height;

    el.querySelectorAll('svg').forEach((svg) => {
      const parentEl = svg.parentElement;

      if (parentEl) {
        width = parentEl.offsetWidth;
        height = parentEl.offsetHeight;

        if (svg.getAttribute('class').includes('cui-icon')) {
          let size = parseFloat(window.getComputedStyle(svg).fontSize);
          size = Number.isFinite(size) ? Math.round(size) : ICON_SIZE;
          width = size;
          height = size;
        }

        svg.setAttribute('height', height);
        svg.setAttribute('width', width);
      }
    });
  }

  renderToBody(el) {
    let wrapper = document.querySelector('#pdf-wrapper');
    // Create the wrapper container if it doesn't exist yet
    if (!wrapper) {
      wrapper = document.createElement('div');
      wrapper.setAttribute('id', 'pdf-wrapper');
      // render the element offscreen instead of display none
      // so that we can get the size of svg elements later
      wrapper.setAttribute('style', 'position: relative;top: -999999px;left: -999999px');
      document.body.appendChild(wrapper);
    }

    return new Promise((resolve) => {
      ReactDOM.render(el, wrapper, () => {
        // It seems like DOM is not stable at this point, wrap dom access in setTimeout
        // to make sure we get the finalized content
        setTimeout(() => {
          const pdf = document.querySelector('#pdf-content');
          // update the svg size
          this.setSvgSize(pdf);
          // weasyprint doesn't support styles for svg
          // copy styles to svg attributes instead
          this.processSvgIcon(pdf);
          CuiDownloadHelpers.copyStyles(pdf);
          resolve(pdf.outerHTML);
          // Remove temporary content
          ReactDOM.unmountComponentAtNode(wrapper);
        }, 1000);
      });
    });
  }

  downloadCss() {
    let hrefs = [];

    for (let i = 0; i < document.styleSheets.length; i += 1) {
      const href = document.styleSheets[i].href;

      if (href && href.includes(location.hostname)) {
        hrefs.push(href);
      }
    }

    hrefs = hrefs.map(href => fetch(href).then(res => res.text()));

    return Promise.all(hrefs)
      .then(res => res.join(''));
  }

  async downloadWithSvg(reactElement, url, token, filename = '', wrapInHtmlTag = false, htmlTemplate) {
    try {
      // renderToStaticMarkup doesn't seem to work very well with svg content
      // as the path attribute is not rendered correct.
      // use this trick to render the content to a hidden container and get the dom content instead
      const htmlContent = await this.renderToBody(reactElement);
      const cssContent = await this.downloadCss();

      const pdfContent = this.replaceSvgWithImg(htmlContent);
      this.downloadHelper(pdfContent, url, token, filename, wrapInHtmlTag, htmlTemplate, cssContent);
    } catch (e) {
      console.log(e);
    }
  }

  download(reactElement, url, token, filename = '', wrapInHtmlTag = false) {
    const pdfContent = ReactDOMServer.renderToStaticMarkup(reactElement);

    this.downloadHelper(pdfContent, url, token, filename, wrapInHtmlTag);
  }

  downloadHelper(htmlContent, url, token, filename = '', wrapInHtmlTag = false, htmlTemplate = defaultTemplate, cssContent = '') {
    let pdfContent = htmlContent;

    if (wrapInHtmlTag) {
      pdfContent = htmlTemplate(pdfContent, cssContent);
    }

    this.createHiddenForm(pdfContent, url, token, filename, this.submit.bind(this));
  }

  createHiddenForm(htmlContent, url, token, filename, callback) {
    if (!this.container) {
      this.container = document.createElement('div');
      document.querySelector('body').appendChild(this.container);
    }
    // create or update
    ReactDOM.render(<HiddenForm content={htmlContent} url={url || this.url} token={token} filename={filename} />, this.container, callback);
  }

  submit() {
    this.container.querySelector('form').submit();
  }

  removeForm() {
    // Remove form
    if (this.container) {
      ReactDOM.unmountComponentAtNode(this.container);
      document.querySelector('body').removeChild(this.container);
      this.container = null;
    }
  }
}
