import get from 'lodash/get';

// User can edit if:
//   - Not currently viewing the state
//   - Their authorized organization is the org being viewed
//   - Their authorized organization is a district and they are viewing a child
export const canUserEdit = (state, organizationId) => {
  const auth = get(state, 'module.userContext');
  const currentOrganization = get(state, 'module.currentOrganization', {});
  const authOrgId = get(auth, 'currentOrganization.id', -1);
  const isChild = authOrgId === get(currentOrganization, 'parentDistrict.id');
  const isDistrict = get(auth, 'currentOrganization.entityType') === 'District';

  return organizationId !== 20 && (authOrgId === organizationId || (isDistrict && isChild));
};

// User can view if:
//   - The user's org is the state (view anything)
//   - Their authorized organization is the org being viewed (district or school)
//   - Their authorized organization is a district and they are viewing a child
export const canUserView = (state) => {
  const userAuthOrgId = get(state, 'module.userContext.currentOrganization.id');
  if (!userAuthOrgId) return false;
  // state users, always true
  if (userAuthOrgId === 20) return true;

  // Pull current org from state
  const currentOrganization = get(state, 'module.currentOrganization', {});
  // auth org == current org, always true
  if (currentOrganization.id === userAuthOrgId) return true;

  // Get current org id from route params
  // This is in case the `currentOrganization` doesn't get set before this auth check is made
  const orgIdFromParams = parseInt(get(state, 'params.id'));
  if (orgIdFromParams === userAuthOrgId) return true;

  // we know this is a district, because it comes in from the 'default_district_organization' graph prop
  const parentDistrictId = get(currentOrganization, 'parentDistrict.id');
  if (userAuthOrgId === parentDistrictId) return true;

  return false;
};
