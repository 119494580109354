import React from 'react';
import PropTypes from 'prop-types';

Sidebar.propTypes = {
  children: PropTypes.node
};

function Sidebar({ children }) {
  return (
    <div className="orgPanel-sidebar">
      { children }
    </div>
  );
}

Sidebar.defaultProps = {
  'data-slotName': 'Sidebar'
};

export default Sidebar;
