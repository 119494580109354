import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import styles from './Gaps.module.scss';

const Gaps = ({ gapData }) => (
  <div>
    { !isEmpty(gapData) ?
      <ul className={styles.Gaps}>
        { gapData.map((gap, i) => (
          <li className={styles.Gaps_item} key={i}>
            <strong>{gap.gap}</strong> <span>compared to {gap.ctrlGroup}</span>
          </li>
        ))}
      </ul>
      :
      <div>No significant gaps</div>
    }
  </div>
);

Gaps.propTypes = {
  gapData: PropTypes.arrayOf(
    PropTypes.shape({
      gap: PropTypes.string.isRequired,
      ctrlGroup: PropTypes.string.isRequired
    })
  )
};

export default Gaps;
