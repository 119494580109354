import { getChartData } from '../../helpers/portalDataServiceHelpers';

// combine current org data with parent data into single object
// For example: current organization is a school.
// const currentOrgScore = {
//   value: 100
// }

// const parentData = {
//   district: { value: 200 },
//   state: { value: 300 }
// };

// it returns:
// {
//   school: { value: 100 },
//   district: { value: 200 },
//   state: { value: 300 }
// }

export default function adapter(currentOrgScore, { node, allScores, currentOrganization }) {
  return getChartData(allScores, currentOrganization, node);
}
