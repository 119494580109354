import currentOrgScores from '../../helpers/scoresHelpers';
import template from '../../helpers/template';

const KdeRangeSliderProvider = (props) => {
  if (!props.scores.length) return null;

  const {
    config: {
      max = 125,
      min = 0,
      segmentCount = 5,
      theme = 'kde-scale',
      title,
      value = '{{ value }}'
    },
    organization,
    scores
  } = {
    config: {},
    ...props
  };

  const currentScores = currentOrgScores(scores, organization);

  return props.children({
    max,
    min,
    segmentCount,
    theme,
    title,
    value: parseFloat(template(value, currentScores), 10)
  });
};

export default KdeRangeSliderProvider;
