import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Alert from 'cui/lib/components/Alert';

export default function FieldErrorMessage({ error, className }) {
  if (!error) {
    return null;
  }

  return (
    <div className={cx('cui-field-alert', className)}>
      <Alert kind={Alert.ERROR_TYPE} type="text" closable={false} autoclose={false}>
        {error}
      </Alert>
    </div>
  );
}

FieldErrorMessage.propTypes = {
  error: PropTypes.string,
  className: PropTypes.string
};
