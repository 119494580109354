import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import ViewingBlock from 'cui/lib/components/ViewingBlock';
import EmptyState from 'cui/lib/components/EmptyState';

import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import buildDropdownOptions from '../../helpers/buildDropdownOptions';

import styles from './filteredChart.module.scss';

const DropdownFilterWrapper = (WrappedComponent, additionalProps = {}) => {
  class DropdownHOC extends React.PureComponent {
    constructor(props) {
      super(props);
      this.state = { selected: get(props.config, 'defaultSelection', '3') };
    }

    selectionChanged(item) {
      this.setState({ selected: item.key });
    }

    render() {
      const score = currentOrgYearScores(this.props.scores, this.props.organization, this.props.node);
      const currentFilter = get(this.props.params, 'filters.demographic', 'All');
      const value = get(score, `value.${currentFilter}`, {});
      const options = buildDropdownOptions(Object.keys(value), get(this.props.config, 'filterOptions'));
      let selectedIndex = findIndex(options, o => o.key === this.state.selected);
      if (selectedIndex === -1) {
        selectedIndex = 0;
      }
      const selectedKey = options[selectedIndex] && options[selectedIndex].key;

      const dataPointHasOptions = !isEmpty(options);

      const entityScores = reduce(this.props.entityScores, (acc, entityScore) => {
        const scoreValue = (
          get(entityScore, `value.${currentFilter}.${selectedKey}.value`, get(entityScore, `value.${currentFilter}.${selectedKey}`))
        );
        if (scoreValue) {
          acc.push(
            {
              ...entityScore,
              value: scoreValue
            });
        }
        return acc;
      }, []);

      const scores = reduce(this.props.scores, (acc, normalScore) => {
        const scoreValue = (
          get(normalScore, `value.${currentFilter}.${selectedKey}.value`, get(normalScore, `value.${currentFilter}.${selectedKey}`))
        );
        if (scoreValue) {
          acc.push(
            {
              ...normalScore,
              value: scoreValue
            });
        }
        return acc;
      }, []);

      return (
        <div>
          {
            !dataPointHasOptions && <EmptyState kind="text" title="There is no data available." />
          }
          {
            dataPointHasOptions && <div>
              <ViewingBlock
                type="dropdown"
                items={options}
                initiallySelectedItem={selectedIndex}
                onSelectionChange={item => this.selectionChanged(item)}
                className={styles.viewingDropdown}
              />
              <WrappedComponent
                {...this.props}
                {...additionalProps}
                entityScores={entityScores}
                scores={scores}
                hasFilter={false}
                selected={selectedKey}
                orginalScores={this.props.scores}
                originalEntityScores={this.props.entityScores}
              />
            </div>
          }
        </div>
      );
    }
  }

  DropdownHOC.propTypes = {
    config: PropTypes.object,
    scores: PropTypes.arrayOf(
      PropTypes.object
    ),
    organization: PropTypes.object,
    node: PropTypes.object,
    params: PropTypes.object,
    entityScores: PropTypes.arrayOf(
      PropTypes.object
    )
  };

  DropdownHOC.displayName = `DropdownHOC(${WrappedComponent.displayName || WrappedComponent.name})`;

  return DropdownHOC;
};

export default DropdownFilterWrapper;
