import reduxQuerySync from 'redux-query-sync';
import get from 'lodash/get';
import { setCurrentSchoolYear } from './actions';

export default (store, history) => {
  // Run this only in KDE
  /* eslint-disable no-undef */
  if (typeof KDE_CURRENT_SCHOOL_YEAR === 'undefined') return;

  reduxQuerySync({
    store,
    params: {
      year: {
        selector: state => get(state, 'module.schoolYear'),
        action: value => setCurrentSchoolYear(value)
      }
    },
    initialTruth: 'location',
    history
  });
};
