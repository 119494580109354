import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';

import connected from '../components/connected';
import { getOrgType } from '../helpers/orgHelper';

import pageTitle from '../helpers/pageHelpers';
import OrgDataProvider from '../helpers/OrgDataProvider';
import ApprovalHeader from '../components/ApprovalHeader';
import Footer from '../components/Footer';
import Masthead from '../components/Masthead';
import OrgHeaderUI from '../components/OrgHeaderUI';
import WithYearAvailableForOrganization from '../components/WithYearAvailableForOrganization';
import NoDataForYearInfo from '../components/NoDataForYearInfo';
import { unsetModuleData } from '../store/actions';

import { schoolStatSelector } from '../selectors/accountabilitySelector';
import { makeSelectFramework } from '../selectors/frameworkSelector';
import {
  makeSelectCurrentOrganization,
  makeSelectScoresData,
  selectFeatureFlags,
  selectIsPreviewSite,
  currentYearSelector
} from '../selectors/moduleSelector';

import { routeInitActions } from './OrgReportCardPage/actions';
import OrgReportCardContent from './OrgReportCardPage/OrgReportCardContent';
import OrgReportCardContent2022 from './OrgReportCardPage/OrgReportCardContent2022';
import LOAD_FEATURE_FLAGS_ACTION from '../actions/feature_flag';

import styles from './OrgReportCardPage.module.scss';

class OrgReportCardPage extends PureComponent {
  componentDidMount() {
    window.scrollTo(0, 0);

    [
      'https://use.typekit.net/ktq5tjx.css',
      'https://use.typekit.net/poy3cpw.css'
    ].forEach(stylesheet => {
      if (!document.querySelector(`link[href="${stylesheet}"]`)) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = stylesheet;
        document.head.insertBefore(link, document.head.firstChild);
      }
    });
  }

  componentWillUnmount() {
    this.props.unsetModuleData(['scoresFromPortalDataService']);
  }

  renderNoDataForYearInfo() {
    return (
      <section className="section section_tight">
        <div className="container-constrained">
          <NoDataForYearInfo year={this.props.year} />
        </div>
      </section>
    );
  }

  render() {
    const {
      currentOrganization,
      year,
      showDownloadButton,
      covidImpactBanner,
      showNewReportPageContent,
      userContext,
      pageContext
    } = this.props;
    // @todo: reset currentOrganization if Id is changed.
    // for now the only way to now that URl is changed (orgID) is comparing id from current location with currentOrg from store.
    // it turned out that every :id change results unmounting component, thus the is no prop-change detected
    if (
      isEmpty(currentOrganization) ||
      Number(this.props.match.params.id) !== Number(currentOrganization.id)
    ) {
      return null;
    }

    const authUserView = !isEmpty(userContext) && !isEmpty(pageContext);
    const isNotClarity = (!authUserView || isEmpty(currentOrganization));

    const isSchool = getOrgType(currentOrganization) === 'school';

    return (
      <div className={styles.page}>
        <ApprovalHeader />
        <Masthead withSearchBar />
        <div role="main" className={styles.mainContent}>
          <OrgDataProvider>
            {orgData => (
              <OrgHeaderUI
                {...orgData}
                covidImpactBanner={covidImpactBanner}
                showDownloadDropdown={isSchool && showDownloadButton}
              />
            )}
          </OrgDataProvider>
          <WithYearAvailableForOrganization
            organization={currentOrganization}
            year={year}
          >
            {({ isYearAvailable }) => {
              if (!isYearAvailable) {
                return this.renderNoDataForYearInfo();
              } else if (showNewReportPageContent) {
                return <OrgReportCardContent2022 {...this.props} isNotClarity={isNotClarity} />;
              }
              return <OrgReportCardContent {...this.props} />;
            }}
          </WithYearAvailableForOrganization>
        </div>
        <Footer />
      </div>
    );
  }
}

OrgReportCardPage.propTypes = {
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  showNewReportPageContent: PropTypes.bool,
  scoresData: PropTypes.shape({
    isLoading: PropTypes.bool,
    scores: PropTypes.arrayOf(PropTypes.object)
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  schoolStat: PropTypes.func,
  unsetModuleData: PropTypes.func,
  year: PropTypes.number,
  covidImpactBanner: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  }),
  userContext: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  pageContext: PropTypes.object
};

OrgReportCardPage.defaultProps = {
  featureFlags: {},
  isPreviewSite: false,
  showDownloadButton: true,
  showNewReportPageContent: false
};

const mapStateToProps = createStructuredSelector({
  framework: makeSelectFramework(),
  currentOrganization: makeSelectCurrentOrganization(),
  scoresData: makeSelectScoresData(),
  featureFlags: selectFeatureFlags,
  isPreviewSite: selectIsPreviewSite,
  schoolStat: schoolStatSelector,
  year: currentYearSelector,
  covidImpactBanner: state => get(state, 'framework.kde.metadata.covidImpactBanner'),
  userContext: state => get(state, 'module.userContext'),
  pageContext: state => get(state, 'module.pageContext')
});

const mapDispatchToProps = dispatch => ({
  unsetModuleData: keys => dispatch(unsetModuleData(keys))
});

export default connect(mapStateToProps, mapDispatchToProps)(connected(OrgReportCardPage));

export const config = {
  title: pageTitle('{{currentOrgName}}'),
  mapStateToProps: state => ({
    currentOrgName: startCase(
      get(state, 'module.currentOrganization.name', '').toLowerCase()
    ),
    showDownloadButton: get(state, 'framework.kde.schoolReportCard.showDownloadButton'),
    showNewReportPageContent: get(
      state,
      'framework.kde.schoolReportCard.showNewReportPageContent'
    )
  }),
  actions: [
    {
      ...LOAD_FEATURE_FLAGS_ACTION,
      actions: routeInitActions()
    }
  ],
  // Route/Query params to watch for changes
  actionParams: ['domainSlug', 'siSlug', 'variableSlug']
};
