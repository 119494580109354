import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';

const schoolClassification = (org) => {
  if (!org || org.entity_type.toLowerCase() !== 'school') return null;

  return org.sch_type;
};

export function isA1School(org) {
  return schoolClassification(org) === 'A1';
}

const schoolCanAccessCollector = (node, org) => {
  const allowedTypes = get(node, 'field_access_by_school_type', []);
  // if there's no access restriction, editable by all
  if (isEmpty(allowedTypes)) return true;

  // if there are restrictions but org is missing or incorrect, no access
  const schType = schoolClassification(org);
  if (!schType) return false;

  // if there's an exact match, we're good to go
  if (allowedTypes.includes(schType)) return true;

  // if we have wildcards in the allowedTypes, check these
  const wildcardAllowedTypes = filter(allowedTypes, t => endsWith(t, '*'));
  const wildcardCheckResults = map(wildcardAllowedTypes, t => (startsWith(schType, t.replace('*', ''))));
  return wildcardCheckResults.includes(true);
};

export default schoolCanAccessCollector;
