import get from 'lodash/get';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import startCase from 'lodash/startCase';
import sortBy from 'lodash/sortBy';

import conditional from '../../../conditional';
import { currentOrgYearScores } from '../../../scoresHelpers';

const formattedGraphTitle = (title, titleMap = {}) => {
  const mappedTitle = titleMap[title];
  return mappedTitle || startCase(title);
};

const adapter = (options) => {
  const { originalScores, scores, organization, node, selected, activeItems, config = {} } = options;
  const { selfDescribedDataShape = {}, hide_label: hideLabel = false } = config;
  const score = currentOrgYearScores((originalScores || scores), organization, node);
  const scoringOptions = get(node, 'scoring_options', {});
  const shape = conditional(get(scoringOptions, 'shape'), {}, options);
  const { noValueShape = false } = shape;
  const visibleItems = activeItems || Object.keys(shape);
  let dataValue;

  const data = score && sortBy(reduce(score.value, (acc, value, key) => {
    const valueShape = selfDescribedDataShape[key] || { label: formattedGraphTitle(key), index: 100 };
    const { label, index } = valueShape;
    let totalDemographicValue = 0;
    const total =  reduce(Object.keys(shape), (sum, k) => (
      sum + (selected ? get(value, `${selected}.${k}`, 0) : get(value, `${k}`, 0))
    ), 0);
    if (!noValueShape) {
      dataValue = sortBy(map(visibleItems, (visibleItem) => {
        const valueScore = selected ? get(value, `${selected}.${visibleItem}`, 0) : get(value, `${visibleItem}`, 0);
        totalDemographicValue += valueScore;
        return {
          value: ((valueScore / total) * 100),
          colorIndex: get(shape, `${visibleItem}.colorIndex`, 1),
          label: get(shape, `${visibleItem}.label`, ''),
          index: get(shape, `${visibleItem}.index`, 0)
        };
      }), ['index']);

      if (totalDemographicValue < total) {
        const unselected = {
          colorIndex: 4,
          hidden: true,
          value: ((total - totalDemographicValue) / total) * 100
        };

        dataValue.push(unselected);
      }
    } else {
      const { colorIndex = 0 } = shape;
      const valueScore = get(value, 'value', value);
      if (valueScore !== undefined) {
        totalDemographicValue = 1;
      }
      const returnObj = {
        value: valueScore,
        colorIndex
      };
      if (!hideLabel) {
        returnObj.label = label;
      }
      dataValue = [returnObj];
    }

    if (totalDemographicValue > 0) {
      acc.push({ label, value: dataValue, index });
    }
    return acc;
  }, []), ['index']);

  return (score && data.length) ? data : undefined;
};

export default adapter;
