import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';
import styles from './index.module.scss';

const CovidBanner = ({ link }) => (
  <div className={styles.container}>
    <Icon className={styles.icon} name="kde-info" />
    <p className={styles.copy}>
    Information about changes to the data being reported is available in the
      <a href={link.href} rel="noreferrer" target="_blank">
        {link.title}
      </a>
      .
    </p>
  </div>
);

CovidBanner.propTypes = {
  link: PropTypes.shape({
    title: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired
  }).isRequired
};

export default CovidBanner;
