import merge from 'lodash/merge';
import { camelizeJson } from './camelize_helper';

function fetchContext(domId) {
  const element = document.getElementById(domId);
  if (!element) {
    return {};
  }
  return JSON.parse(element.getAttribute('data-value') || {});
}

function fetchCsrfToken() {
  const csrfMeta = document.querySelector('meta[name="csrf-token"]');
  return csrfMeta && csrfMeta.content;
}

function reformatFeatureFlags(featureFlags) {
  return JSON.parse(featureFlags).reduce((memo, fg) => {
    memo[fg.feature] = fg.enabled;
    return memo;
  }, {});
}

let userContext;
let pageContext;

function getUserContext(providedContext) {
  if (!userContext) {
    userContext = camelizeJson(providedContext || fetchContext('userContext'));
  }
  if (providedContext) {
    merge(userContext, camelizeJson(providedContext));
  }

  return userContext;
}

function getPageContext(providedContext) {
  if (!pageContext) {
    pageContext = providedContext || fetchContext('pageContext');
    const moduleData = pageContext.module_data;
    pageContext = camelizeJson(pageContext);
    pageContext.moduleData = moduleData;
    pageContext.csrfToken = fetchCsrfToken();

    if (typeof pageContext.featureFlags === 'string') {
      pageContext.featureFlags = reformatFeatureFlags(pageContext.featureFlags);
    }
  }
  if (providedContext) {
    merge(pageContext, camelizeJson(providedContext));
    // Don't camelize feature flag overrides. TODO: Figure out if we really
    // need to camelize the provided context at all.
    if (providedContext.featureFlagOverrides) {
      pageContext.featureFlagOverrides = providedContext.featureFlagOverrides;
    }
  }

  if (pageContext.featureFlags && pageContext.featureFlagOverrides) {
    merge(pageContext.featureFlags, pageContext.featureFlagOverrides);
  }

  return pageContext;
}

export { getUserContext, getPageContext };
