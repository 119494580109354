import PropTypes from 'prop-types';

const WithPredicate = ({ children, predicate, placeholder }) => {
  const isPredicateTruthy = predicate;

  if (typeof children === 'function') {
    return children({ isTruthy: isPredicateTruthy });
  }

  if (isPredicateTruthy) {
    return children;
  }

  if (typeof placeholder === 'function') {
    return placeholder();
  }

  return placeholder;
};

WithPredicate.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  predicate: PropTypes.bool,
  placeholder: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ])
};

WithPredicate.defaultProps = {
  predicate: true,
  placeholder: () => null
};

export default WithPredicate;
