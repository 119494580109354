import isEmpty from 'lodash/isEmpty';
import { getUserContext } from './backend_context';
import getScoreLoadingIndicator from './getScoreLoadingIndicator';
import LOAD_CHILDREN_ORGS from '../queries/load_children_orgs';
import LOAD_ORGANIZATIONS from '../queries/load_organizations';
import loadScoresForOrganizationQuery from './queries/loadScoresForOrganizationQuery';

export const publicScoreLoad = {
  type: 'GRAPH_LOAD',
  service: 'groot',
  when: '{{!userContext && Array.isArray(nodePaths) && nodePaths.length}}',
  isPublic: true,
  query: `query($nodePaths: [String], $framework: String) {
    scores(node_paths: $nodePaths, organization_ids: [:currentOrganizationId], framework: $framework) {
    remote_organization_id
    framework_tree_node { node_path }
    start_at
    end_at
    value
    }
  }`,
  stateObjectMap: {
    scoresFromGroot: 'data.scores'
  },
  propMap: {
    currentOrganizationId: 'module.currentOrganization.id',
    userContext: 'module.userContext',
    nodePaths: 'module.currentDataNodePaths'
  },
  propVariablesToVariables: ['nodePaths'],
  variables: {
    framework: 'kde'
  },
  loadingIndicator: action => getScoreLoadingIndicator('groot', action.currentOrganizationId)
};
/* eslint-disable no-undef */
export const scoreLoad = {
  type: 'GRAPH_LOAD',
  service: 'groot',
  when: '{{!!userContext && Array.isArray(nodePaths) && nodePaths.length}}',
  query: `query($nodePaths: [String], $framework: String) {
    scores(node_paths: $nodePaths, organization_ids: [:currentOrganizationId], framework: $framework) {
      remote_organization_id
      framework_tree_node { node_path }
      start_at
      end_at
      value
    }
  }`,
  stateObjectMap: {
    scoresFromGroot: 'scores'
  },
  propMap: {
    currentOrganizationId: 'module.currentOrganization.id',
    userContext: 'module.userContext',
    nodePaths: 'module.currentDataNodePaths'
  },
  propVariablesToVariables: ['nodePaths'],
  variables: {
    framework: 'kde'
  },
  loadingIndicator: action => getScoreLoadingIndicator('groot', action.currentOrganizationId)
};

/* eslint-enable no-undef */
export const scoreLoadFromPortalDataService = {
  type: 'PORTAL_DATA_LOAD',
  propMap: {
    currentOrganizationId: 'module.currentOrganization.id',
    selectedSchoolYear: 'params.year',
    featureFlags: 'module.pageContext.featureFlags'
  },
  stateKey: 'scoresFromPortalDataService',
  query: loadScoresForOrganizationQuery,
  loadingIndicator: action => getScoreLoadingIndicator('svcpd', action.currentOrganizationId)
};

export const compareScoresLoadFromPortalDataService = {
  ...scoreLoadFromPortalDataService,
  stateKey: 'compareScores',
  mergePortalData: true,
  loadingIndicator: 'svcpd-score-compare-scores'
};

const isPublicSite = isEmpty(getUserContext());

export const LOAD_CHILDREN_ORGS_ACTION = {
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  query: LOAD_CHILDREN_ORGS,
  isPublic: isPublicSite,
  propMap: {
    id: 'params.id',
    year: 'params.year'
  },
  stateObjectMap: {
    currentOrganization: {
      dataKey: isPublicSite ? 'data.organization.children' : 'organization.children',
      method: 'merge',
      path: 'children'
    }
  }
};

export const LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION = {
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  query: LOAD_ORGANIZATIONS,
  isPublic: isPublicSite,
  stateObjectMap: {
    organizations: isPublicSite ? 'data.organizations' : 'organizations'
  },
  propMap: {
    year: 'params.year'
  }
};
