import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'mapbox.js/dist/mapbox.css';

import App from './App';
import './styles/dashboards/main.scss';

ReactDOM.render(<App />, document.getElementById('external-root'));
