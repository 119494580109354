import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import FlexBar from 'cui/lib/components/FlexBar';
import Text from 'cui/lib/components/Form/Text';
import Label from 'cui/lib/components/Label';

import FilterDropdown, { filterItemShape } from './FilterDropdown';

const TableFilter = ({
  values,
  onFilterChange,
  hasSearch,
  searchInputProps,
  total,
  filters,
  cta
}) => {
  const [query, setQuery] = useState(values?.query ?? '');
  const filterValues = values?.filters ?? {};

  const handleQueryChange = debounce((newQuery) => {
    setQuery(newQuery);
    onFilterChange({ query: newQuery });
  }, 200, { trailing: true });

  const handleFilterChange = useCallback((option) => {
    const newFilters = {
      ...filterValues,
      [option.group]: option.value
    };
    onFilterChange({ filters: newFilters });
  }, [onFilterChange, filterValues]);

  return (
    <FlexBar stack="phablet">
      <FlexBar.Item grow>
        <FlexBar justify="left" stack="phablet">
          {hasSearch && (
            <FlexBar.Item>
              <Text
                {...searchInputProps}
                type="search"
                label="Search"
                value={query}
                onChange={handleQueryChange}
              />
            </FlexBar.Item>
          )}
          {total && (
            <FlexBar.Item>
              <Label position="inline" value={`${total.label}:`} />
              <span className="cui-text_bold">{total.value}</span>
            </FlexBar.Item>
          )}
          {filters.map(filter => (
            <FilterDropdown
              key={filter.key}
              filter={filter}
              handleFilterChange={handleFilterChange}
              filterValues={filterValues}
            />
          ))}
        </FlexBar>
      </FlexBar.Item>
      {cta && <FlexBar.Item>{cta}</FlexBar.Item>}
    </FlexBar>
  );
};

TableFilter.propTypes = {
  hasSearch: PropTypes.bool,
  searchInputProps: PropTypes.shape(Text.propTypes),
  total: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
  }),
  filters: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(filterItemShape)
  })),
  onFilterChange: PropTypes.func,
  cta: PropTypes.node,
  values: PropTypes.shape({
    query: PropTypes.string,
    filters: PropTypes.objectOf(PropTypes.any)
  })
};

TableFilter.defaultProps = {
  hasSearch: true,
  searchInputProps: null,
  total: null,
  filters: [],
  onFilterChange: noop,
  cta: null,
  values: null
};

export  default TableFilter;
