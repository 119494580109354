import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import Popover from 'cui/lib/components/Popover';

import formatFunc from '../../../helpers/formatter';

const DefaultPopover = (props) => {
  const { item, options } = props;
  const formatter = get(options, 'yAxis.formatter');
  let value = item.y;

  if (formatter) {
    const { name, args } = formatter;
    value = formatFunc(name, value, args);
  }

  return (
    <Popover.Body key="body">
      <div>{item.title && `${item.title}: `}{value}</div>
    </Popover.Body>
  );
};

DefaultPopover.propTypes = {
  item: PropTypes.object,
  options: PropTypes.object
};

export default DefaultPopover;
