import React from 'react';
import PropTypes from 'prop-types';

import LineChart from 'cui/lib/components/LineChart';
import Legend from 'cui/lib/components/Legend';
import Label from 'cui/lib/components/Label';
import FlexBar from 'cui/lib/components/FlexBar';
import { merge, get, flatten } from 'lodash';

import template from '../../helpers/template';
import adapter from '../../chartDataAdapters/trendsChart';
import { getOrgIdFromOrganization } from '../../helpers/portalDataServiceHelpers';
import FILTERS_OPTIONS from '../../helpers/data_dropdown_helper/filtersOptions';
import { FIRST_SCHOOL_YEAR } from '../../constants';
import popoverRender from './trendsPopoverRender';

const TREND_YEARS = 5;

const calcAxisExtremes = (values, options) => {
  if (!values.length) {
    return {
      high: 100,
      low: 0
    };
  }

  const sortedValues = values
    .map(el => el.y)
    .filter(val => Number.isFinite(Number(val)))
    .sort((a, b) => a - b);

  const min = Number(sortedValues[0]);
  const max = Number(sortedValues[sortedValues.length - 1]);
  const maxMinDiff = max - min;
  const bottomBoundary = get(options, 'yAxis.bottomBoundary');

  const roundToHundreds = Math.max(Math.abs(min), Math.abs(max)) >= 100;
  const multiplier = roundToHundreds ? 100 : 10;
  const gap = maxMinDiff * 0.1; // 10% up and down from min and max respectively

  const axisExtremes = {
    high: Math.ceil((max + gap) / multiplier) * multiplier || 100,
    low: Math.floor((min - gap) / multiplier) * multiplier || 0
  };

  if (bottomBoundary !== undefined && axisExtremes.low < bottomBoundary) axisExtremes.low = bottomBoundary;

  return axisExtremes;
};

const getChartOptions = (values, options, schoolYear) => {
  const allOrgsValues = flatten(values);
  const startYear = Math.max(FIRST_SCHOOL_YEAR, schoolYear - (TREND_YEARS - 1));
  const allYears = new Array((schoolYear - startYear) + 1).fill().map((_, idx) => startYear + idx);

  const baseOptions = {
    yAxis: {
      showGrid: true,
      showAxis: true,
      showLabel: true,
      labelCount: 5,
      numOfDecimal: 2,
      labelInterpolationFunc: l => template(get(options, 'yAxis.labelTemplate', '{{l}}'), { l }),
      ...calcAxisExtremes(allOrgsValues, options)
    },
    xAxis: {
      showAxis: true,
      showGrid: true,
      showLabel: true,
      labelCount: allYears.length,
      low: allYears[0],
      high: allYears[allYears.length - 1]
    },
    usePopover: true,
    columnSelect: true,
    symbolWithinGrid: true,
    padding: { top: 0, bottom: 30, left: 50, right: 60 },
    popoverRender
  };

  return merge(baseOptions, options);
};

const dataPointAriaLabel = dp => dp.ariaLabel;

const KdeTrendsChart = (props) => {
  const organizationsWithoutTrends = get(props, 'framework.kde.organizations_without_trends');
  const organizationId = getOrgIdFromOrganization(props.organization);

  if (organizationsWithoutTrends.includes(organizationId)) {
    return <div>Due to changes in the student population, trend data is not available</div>;
  }

  const values = adapter(props);

  const data = values.map(valuesByPriorSetting => ({
    label: get(FILTERS_OPTIONS, [valuesByPriorSetting.label, 'text'], valuesByPriorSetting.label),
    data: Object.entries(valuesByPriorSetting.data).map(([name, value]) => ({
      name: get(FILTERS_OPTIONS, [name, 'text'], name),
      showLine: true,
      showPoints: true,
      values: value.map(el => ({ ...el, y: Number(el.y) }))
    }))
  }));

  const allValues = values.map(({ data: dataByPriorSetting }) => flatten(Object.values(dataByPriorSetting)));
  const chartOptions = getChartOptions(allValues, props.config.options, props.schoolYear);

  return (
    <div>
      {data.map((dataByPriorSetting, index) => (
        <div key={dataByPriorSetting.label || index}>
          <FlexBar className="cui-margin-bottom-medium">
            <FlexBar.Item>
              <Legend
                data={dataByPriorSetting.data.map(({ colorIndex, name }) => ({ colorIndex, label: name }))}
                kind="inline"
                theme="state-goals"
                className="cui-text_small"
              />
            </FlexBar.Item>
            <FlexBar.Item>
              <Label value={dataByPriorSetting.label} />
            </FlexBar.Item>
          </FlexBar>
          <LineChart data={dataByPriorSetting.data} theme="state-goals" chartOptions={chartOptions} dataPointAriaLabel={dataPointAriaLabel} />
        </div>
      ))}
    </div>
  );
};

KdeTrendsChart.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.object),
  previousYearScores: PropTypes.object,
  schoolYear: PropTypes.number,
  viewingDropdownFilter: PropTypes.object,
  config: PropTypes.object,
  node: PropTypes.object,
  organization: PropTypes.object
};

export default KdeTrendsChart;
