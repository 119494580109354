import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Link extends Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const targetEntry = document.querySelector(`#${this.props.href} .glossarySection-letter`);

    if (targetEntry) {
      // Set focus on the target entry so that screen reader can read out the content correctly
      targetEntry.focus();
    }
  }

  render() {
    const { href, ...props } = this.props;

    return (
      <AnchorLink
        href={`#${href}`}
        onClick={this.onClick}
        {...props}
      >
        {href}
      </AnchorLink>
    );
  }
}

Link.propTypes = {
  href: PropTypes.string.isRequired
};

export default Link;
