import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import connected from '../../components/connected';
import { schoolStatSelector } from '../../selectors/accountabilitySelector';
import { makeSelectFramework } from '../../selectors/frameworkSelector';
import {
  makeSelectCurrentOrganization,
  makeSelectScoresData,
  selectFeatureFlags,
  selectIsPreviewSite,
  currentYearSelector,
  makePreviousYearsDataSelector,
  schoolLevelSelector
} from '../../selectors/moduleSelector';
import { reportData } from '../../helpers/reportHelpers';
import { routeInitActions } from '../OrgReportCardPage/actions';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import SchoolReport from '.';
import styles from './index.module.scss';

function SchoolReportPdf(props) {
  const { currentOrganization, scoresData, previousYearScore, framework, year, schoolLevel } = props;

  return (
    <div className={styles.container}>
      <SchoolReport
        isWebView
        isScoresLoading={scoresData.isLoading}
        {...reportData(currentOrganization, scoresData.scores, previousYearScore, framework, year, schoolLevel)}
      />
    </div>
  );
}

SchoolReportPdf.propTypes = {
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  scoresData: PropTypes.shape({
    isLoading: PropTypes.bool,
    scores: PropTypes.arrayOf(PropTypes.object)
  }),
  previousYearScore: PropTypes.arrayOf(PropTypes.object),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  schoolStat: PropTypes.func,
  unsetModuleData: PropTypes.func,
  year: PropTypes.number,
  schoolLevel: PropTypes.string
};

SchoolReportPdf.defaultProps = {
  featureFlags: {},
  isPreviewSite: false
};

const mapStateToProps = createStructuredSelector({
  framework: makeSelectFramework(),
  currentOrganization: makeSelectCurrentOrganization(),
  scoresData: makeSelectScoresData(),
  featureFlags: selectFeatureFlags,
  isPreviewSite: selectIsPreviewSite,
  schoolStat: schoolStatSelector,
  year: currentYearSelector,
  previousYearScore: makePreviousYearsDataSelector(),
  schoolLevel: schoolLevelSelector
});

export default connect(mapStateToProps)(connected(SchoolReportPdf));

export const config = {
  title: 'School Summary Report',
  mapStateToProps: state => ({
    currentOrgName: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase())
  }),
  actions: [
    {
      ...LOAD_FEATURE_FLAGS_ACTION,
      actions: routeInitActions()
    }
  ],
  // Route/Query params to watch for changes
  actionParams: [
    'domainSlug',
    'siSlug',
    'variableSlug'
  ]
};
