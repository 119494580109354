import React from 'react';
import { withRouter } from 'react-router';

import { Heading } from 'cui';

import Link from '../Link';
import styles from './index.module.scss';

function Branding() {
  return (
    <div className={styles.branding}>
      <img src="//bb-production-assets.s3.amazonaws.com/kde/kde-logo.svg" alt="Kentucky Department of Education Logo" />
      <div>
        <Heading level={1} size="medium"><Link to="/">School Report Card</Link></Heading>
      </div>
    </div>
  );
}

export default withRouter(Branding);
