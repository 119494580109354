import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import slice from 'lodash/slice';

import template from '../../../template';
import conditional from '../../../conditional';
import { monthlyOrgScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const { node, scores, organization, selectedTimeSlice } = options;
  const scoringOptions = node.scoring_options || {};
  const trendLines = conditional(scoringOptions.trend_lines, {}, options);
  const monthlyScores = slice(monthlyOrgScores(node, scores, organization, selectedTimeSlice), -6);

  const FORMATTER = {
    money: v => v / 100,
    proportion: v => v * 100,
    rounded_proportion: v => Math.round(v * 100),
    identity: v => v
  };

  const format = FORMATTER[scoringOptions.type || 'identity'];

  /* eslint-disable no-useless-escape */
  const data = trendLines && sortBy(map(trendLines, (value) => {
    const colorIndexKey = score => score.value[get(value, 'colorIndexKey', -1)];
    const colorIndexMap = get(value, 'colorIndexMap', {});
    const dataPointColorIndex = score => (colorIndexMap[colorIndexKey(score)] === undefined ?
      get(value, 'colorIndex') :
      colorIndexMap[colorIndexKey(score)]);
    return (
      {
        name: template(get(value, 'name'), options),
        label: template(get(value, 'name'), options),
        showLine: get(value, 'show_line'),
        showPoints: get(value, 'show_points'),
        colorIndex: get(value, 'colorIndex'),
        symbol: 'circle',
        values: map(monthlyScores, score => (
          {
            x: new Date(get(score, 'start_at').replace(/-/g, '\/')),
            y: value.keys ? format(sumBy(map(value.keys, key => Number(score.value[key])))) : format(score.value),
            colorIndex: dataPointColorIndex(score),
            score
          }
        )),
        index: get(value, 'index')
      }
    );
  }), ['index']);

  return data.length && monthlyScores.length ? data : undefined;
  /* eslint-enable no-useless-escape */
};

export default adapter;
