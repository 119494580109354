const gridFilter = (i, length) => i === 0 || i + 1 === length;

export default class ChartOptionHelpers {
  static gridFilter(chartOptions) {
    // show vertical grid lines if it is horizontal bar chart
    // otherwise show horizontal grid lines
    if (chartOptions.horizontal) {
      chartOptions.xAxis.gridFilter = gridFilter;
    } else {
      chartOptions.yAxis.gridFilter = gridFilter;
    }

    return chartOptions;
  }
}
