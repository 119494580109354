// Add an additional item to an array based on some numeric fields in each array item.
// It's useful when chart wants to display only part of the top dimensions and group other dimensions
// into "others".
// example:
// Here's top 3 fruits, other fruits are not included in score.
// const score = [
//   { percent: 10, name: 'banana', label: '10%' },
//   { percent: 20, name: 'apple', label: '20%' },
//   { percent: 30, name: 'grape', label: '30%' }
// ];
//
// const chartConfig = {
//   options: {
//     addRemainder: {
//       remainderItems: [
//         { total: 100, scoreKey: 'percent' }
//       ],
//       props: {
//         name: 'others',
//         label: '{{percent}}%'
//       }
//     }
//   }
// };
//
// const result = [
//   { percent: 10, name: 'banana', label: '10%' },
//   { percent: 20, name: 'apple', label: '20%' },
//   { percent: 30, name: 'grape', label: '30%' },
//   { percent: 40, name: 'others', label: '40%' }
// ];

import get from 'lodash/get';
import set from 'lodash/set';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import template from '../../helpers/template';
import getName from '../../helpers/nameHelper';
import { valueToFixedPoint } from '../../helpers/mathHelpers';

export default function adapter(currentOrgScore, { chartConfig, orginalScore }) {
  if (!isEmpty(currentOrgScore)) {
    const { remainderItems, props } = get(chartConfig, 'options.addRemainder');
    const remainderInfo = remainderItems.reduce((memo, item) => {
      // Compute the total value
      const total = parseFloat(template(item.total, { orginalScore }));
      if (Number.isNaN(total)) {
        set(memo, item.scoreKey, item.defaultValue);
      } else {
        // When total is valid number
        // Compute the remainder value by subtracting each item from the total value
        const value = currentOrgScore.reduce((acc, score) => {
          // value under scoreKey must be numeric value
          const scoreValue = parseFloat(get(score, item.scoreKey));
          return !Number.isNaN(scoreValue) ? acc - scoreValue : acc;
        }, total);
        set(memo, item.scoreKey, Number.isInteger(value) ? value : valueToFixedPoint(value, 1));
      }

      return memo;
    }, {});

    const context = { ...remainderInfo, getName, orginalScore };
    // Compute additional props to add to the remainder item
    const computedProps = reduce(props, (memo, prop, key) => {
      memo[key] = template(prop, context);
      return memo;
    }, {});

    return [...currentOrgScore, { ...remainderInfo, ...computedProps }];
  }

  return currentOrgScore;
}
