import React from 'react';

import { MAIN_CONTENT_ANCHOR } from '../../constants';
import styles from './index.module.scss';

// Invisible anchor element to scroll to when using "Skip to main content" a11y navigation.
// Should be placed before a main content section.
// Anchor needs to be focusable with js but not focusable with keyboard. Thus -1 tabindex.
const MainContentAnchor = () => (
  <div id={MAIN_CONTENT_ANCHOR} className={styles.anchor} tabIndex="-1" />
);

export default MainContentAnchor;
