import React from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Modal from 'cui/lib/components/Modal';
import FlexBar from 'cui/lib/components/FlexBar';
import Form from 'cui/lib/components/Form';
import Spacer from 'cui/lib/components/Spacer';

import FormFields from './FormFields';
import validationSchema from './validationSchema';

const ModalForm = ({ isOpen, onHide, initialValues, onSubmit, onDelete }) => (
  <Modal size="large" isOpen={isOpen} onHide={onHide}>
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      render={({ values, handleSubmit, setFieldValue, isSubmitting }) => (
        <Form onSubmit={handleSubmit} className="is-submitted">
          <Modal.Header title="Data Flagging" />
          <Modal.Body>
            <div className="cui-flexbar cui-flexbar_top">
              <p>
                Flagging data triggers an additional review by Support team. To expedite a resolution to the issue, please provide the following
                information:
              </p>
            </div>

            <Spacer />

            <FormFields dataIssues={values.dataIssues} setFieldValue={setFieldValue} />
          </Modal.Body>
          <Modal.Footer>
            <FlexBar>
              <div>
                <Button
                  disabled={isSubmitting}
                  type="button"
                  title="Delete All Flags"
                  kind="solid"
                  theme="caution"
                  onClick={onDelete}
                >
                  Delete All Flags
                </Button>
              </div>

              <FlexBar>
                <Button
                  disabled={isSubmitting}
                  type="button"
                  title="Cancel"
                  kind="stroked"
                  theme="secondary"
                  onClick={onHide}
                >
                  Cancel
                </Button>

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  title="Save"
                  kind="solid"
                  theme="primary"
                >
                  Save
                </Button>
              </FlexBar>
            </FlexBar>
          </Modal.Footer>
        </Form>
      )}
    />
  </Modal>
);

ModalForm.propTypes = {
  initialValues: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    dataIssues: PropTypes.array
  }).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default ModalForm;
