import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';
import maxBy from 'lodash/maxBy';
import cx from 'classnames';
import Popover from 'cui/lib/components/Popover';
import LineChart from 'cui/lib/components/LineChart';
import Legend from 'cui/lib/components/Legend';
import Divider from 'cui/lib/components/Divider';
import Icon from 'cui/lib/components/Icon';
import FlexBar from 'cui/lib/components/FlexBar';
import ChartEmptyState from '../Chart/ChartEmptyState';
import template from '../../helpers/template';
import applyAdapters from '../../helpers/adapterHelpers';
import styles from './index.module.scss';

const dataPointAriaLabel = dp => dp.ariaLabel;
const dataPointLabel = (dp, groupLabel, nodeName) => `${nodeName}, ${groupLabel}, ${dp.x}`;

function getPopoverElement(title, values) {
  return [
    [
      <Popover.Title key="title">{ title }</Popover.Title>,
      <Popover.Body key="body">
        {values.map(value => <div dangerouslySetInnerHTML={{ __html: value }} />)}
      </Popover.Body>
    ]
  ];
}

function getOptionsFromValues(chartData, optionsFromConfig) {
  const longestDataSet = maxBy(chartData, chart => chart.values.length);

  const lowestX = Number(get(longestDataSet, ['values', 0, 'x']));
  const biggestX = Number(get(longestDataSet, ['values', longestDataSet.values.length - 1, 'x']));

  const { low: lowestXFromConfig, high: biggestXFromConfig } = get(optionsFromConfig, 'xAxis', {});

  const finalLowestX = Math.min(lowestX, Number(lowestXFromConfig) || Number.MAX_SAFE_INTEGER);
  const finalBiggestX = Math.max(biggestX, Number(biggestXFromConfig) || Number.MIN_SAFE_INTEGER);

  return {
    xAxis: {
      labelCount: (finalBiggestX - finalLowestX) + 1,
      low: finalLowestX,
      high: finalBiggestX
    }
  };
}

const defaultChartOptions = {
  padding: { top: 0, bottom: 30, left: 50, right: 60 },
  xAxis: {
    showGrid: true,
    showAxis: true,
    showLabel: true
  },
  yAxis: {
    showLabel: true,
    showGrid: true,
    showAxis: true
  },
  usePopover: true,
  columnSelect: true,
  popoverRender: (item) => {
    if (Array.isArray(item)) {
      // We support only single popover for now
      const popoverTitle = get(item, [0, 'popover', 0, 'title']);
      return getPopoverElement(popoverTitle, item.map(({ popover }) => get(popover, [0, 'value'])));
    }

    const popover = get(item, ['popover', '0']);
    return getPopoverElement(popover.title, [popover.value]);
  }
};

function KdeLineChart(props) {
  const {
    config,
    scores,
    allScores,
    node,
    additionalChartAdapters,
    viewingDropdownFilter,
    organization,
    className
  } = props;
  const { alwaysRender = false } = config;
  const adapterName = get(config, 'adapter');

  if ((isEmpty(scores) || isEmpty(adapterName)) && !alwaysRender) {
    return <ChartEmptyState />;
  }

  // Note: for now there's only one score for kde, later we will have data for more than one year
  const score = get(scores, '[0].value', {});
  const optionsFromConfig = get(config, 'options.chartOptions', {});
  const baseConfig = {};

  const { chartData, legendData } = applyAdapters(adapterName, score, {
    currentOrganization: organization,
    chartConfig: config,
    additionalChartAdapters,
    node,
    config,
    viewingDropdownFilter,
    allScores
  });

  if (isEmpty(chartData) || every(chartData, ({ values }) => isEmpty(values))) {
    return <ChartEmptyState />;
  }

  let legendProps;
  if (get(config, 'options.showLegend') && !isEmpty(legendData)) {
    legendProps = {
      title: '',
      transparent: true,
      data: legendData
    };
  }

  const chartOptions = merge(baseConfig, defaultChartOptions, optionsFromConfig, getOptionsFromValues(chartData, optionsFromConfig));
  if (get(chartOptions, 'yAxis.labelTemplate')) {
    chartOptions.yAxis.labelInterpolationFunc = l => template(chartOptions.yAxis.labelTemplate, { l });
  }

  const chartDataWithLabel = chartData.map((d) => {
    if (Array.isArray(d.values)) {
      return {
        ...d,
        values: d.values.map(dp => ({
          ...dp,
          ariaLabel: dataPointLabel(dp, d.name, node.name)
        }))
      };
    }

    return d;
  });

  return (
    <div>
      <FlexBar justify="space-between" className={styles.legendWrapper}>
        <FlexBar.Item>
          <Legend
            className={className}
            theme={get(config, 'options.theme', 'default')}
            {...legendProps}
            renderLegendItem={(theme, item, i) => (
              <li key={i} className={`theme-${theme} cui-list-item`}>
                <Icon
                  name={item.icon}
                  className={`cui-list-icon theme-${theme}-fill-${item.colorIndex != null ? item.colorIndex : i}`}
                />
                <span className="cui-list-text"><strong>{item.label}</strong> {item.description}</span>
              </li>
            )}
          />
        </FlexBar.Item>
        <FlexBar.Item>
          <span className={styles.hint}>{get(config, 'options.hint')}</span>
        </FlexBar.Item>
      </FlexBar>
      <Divider className="cui-margin-bottom-xLarge" />
      <div className={cx(styles.lineChart, className)}>
        <LineChart
          theme={get(config, 'options.theme', 'default')}
          data={chartDataWithLabel}
          chartOptions={chartOptions}
          dataPointAriaLabel={dataPointAriaLabel}
        />
      </div>
    </div>
  );
}

const commonProps = {
  defaultChartOptions: PropTypes.object,
  config: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  node: PropTypes.object,
  additionalChartAdapters: PropTypes.object
};

KdeLineChart.propTypes = {
  className: PropTypes.string,
  ...commonProps
};

KdeLineChart.commonProps = commonProps;

export default KdeLineChart;
