import React from 'react';
import PropTypes from 'prop-types';
import { first, last, reduce } from 'lodash';
import cx from 'classnames';
import { Progress } from 'cui';

import styles from './ScaleBar.module.scss';

function shouldHide(i, length, shouldHideLowAndHigh) {
  return shouldHideLowAndHigh && (i === 0 || i === length - 1);
}

const ScaleBar = ({ data, theme, hideLowHighLabel }) => {
  const firstEntry = first(data);
  const scaleLength = last(data) - firstEntry;

  // calculate the bar segments and labels from the passed data
  const scaleData = reduce(data, (accumulator, entry) => {
    // find the difference from the current entry back to the first entry
    const rawDifference = entry - firstEntry;
    // convert that distance to the proper percentage to use for label positioning
    const positionPercent = (rawDifference / scaleLength) * 100;
    // push out the label info
    accumulator.labels.push({ label: entry, position: positionPercent.toFixed(1) });

    // establish the length of each segment in the bar
    if (accumulator.previous !== null) {
      // find the distance from the current entry to the previous entry
      const segmentDifference = entry - accumulator.previous;
      // convert that distance to the proper percentage to establish the length of that segment of the bar
      const segmentPercent = (segmentDifference / scaleLength) * 100;
      // push out the segment lengths
      accumulator.segments.push(segmentPercent.toFixed(2));
    }

    accumulator.previous = entry;

    return accumulator;
  }, { previous: null, labels: [], segments: [] });

  const scaleLabels = scaleData.labels;
  const shouldHideLowAndHigh = hideLowHighLabel && scaleLabels.length > 2;

  return (
    <div className={styles.scaleBar}>
      <div className={styles.title}>Scale</div>
      <Progress className={styles.progressBar} data={scaleData.segments} theme={theme} size="xsmall" />
      <div className={styles.labels}>
        {scaleLabels.map((item, i) => (
          <div
            className={cx(styles.label, { 'cui-hidden': shouldHide(i, scaleLabels.length, shouldHideLowAndHigh) })}
            key={item.key}
            style={{ left: `${item.position}%` }}
          >
            {item.label}
          </div>
        ))}
      </div>
    </div>
  );
};

ScaleBar.propTypes = {
  data: PropTypes.arrayOf(PropTypes.number),
  theme: PropTypes.string,
  hideLowHighLabel: PropTypes.bool
};

ScaleBar.defaultProps = {
  theme: 'kde-accountability2'
};

export default ScaleBar;
