import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import EmptyState from 'cui/lib/components/EmptyState';

import { findNodeByNodePath } from '../../helpers/frameworkHelpers';
import { isPublicOrWithClarityUserPermissions } from '../../helpers/nodeHelpers';
import { makeSelectFramework } from '../../selectors/frameworkSelector';
import { makeSelectUserRole } from '../../selectors/userSelector';

class PublicOrWithPermissions extends React.PureComponent {
  isPermitted() {
    const { framework, nodePath, userRole } = this.props;
    const node = findNodeByNodePath(framework, nodePath);

    return isPublicOrWithClarityUserPermissions(node, userRole);
  }

  render() {
    const { accessDeniedMessage, children } = this.props;

    if (this.isPermitted()) {
      return <div>{children}</div>;
    } else if (accessDeniedMessage) {
      return <EmptyState title={accessDeniedMessage} />;
    }

    return null;
  }
}

PublicOrWithPermissions.propTypes = {
  framework: PropTypes.object.isRequired,
  userRole: PropTypes.string,
  nodePath: PropTypes.string.isRequired,
  accessDeniedMessage: PropTypes.string,
  children: PropTypes.node.isRequired
};

const mapStateToProps = createStructuredSelector({
  framework: makeSelectFramework(),
  userRole: makeSelectUserRole()
});

export default connect(mapStateToProps)(PublicOrWithPermissions);
