import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

import template from '../../../template';

const adapter = (options) => {
  const { node, scores } = options;
  const scoringOptions = node.scoring_options;
  const shape = {};
  const upperLimit = node.chartLimit ? node.chartLimit : scores.length;
  const scoresToDisplay = scores.slice(0, upperLimit);
  scoresToDisplay.forEach((item, index) => {
    shape[item.type] = {
      label: startCase(item.type),
      colorIndex: 0,
      index,
      subLabelTemplate: '{{student_count.toLocaleString()}} students'
    };
  });
  const data = scoresToDisplay && map(scoresToDisplay, (score) => {
    const key = score.type;
    const subLabelTemplate = get(shape, `${key}.subLabelTemplate`, '{{value}}');
    return ({
      label: get(shape, `${key}.label`, key),
      key,
      value: get(score, (scoringOptions.valueKey || 'value'), 0),
      colorIndex: get(shape, `${key}.colorIndex`, 0),
      confidenceInterval: score.confidenceInterval,
      index: get(shape, `${key}.index`, null),
      subLabel: template(subLabelTemplate, { value: '', ...options, ...score }),
      percent: score.percent
    });
  });
  return sortBy(data, ['index']);
};

export default adapter;
