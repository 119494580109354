import get from 'lodash/get';
import isObject from 'lodash/isObject';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';

import when from '../../../when';
import template from '../../../template';
import validScoreValue from '../../../validScoreValue';
import { FORMATTERS } from '../../../formatter';

const adapter = (options) => {
  const { currentOrgScore, node, config } = options;

  if (isEmpty(currentOrgScore)) {
    return null;
  }

  const scoringOptions = get(node, 'scoring_options', {});
  let scoreValue = get(currentOrgScore, 'value', get(currentOrgScore, 'values[0]'));

  if (config && config.options && config.options.defaultScoreKey) {
    const defaultScoreKey = config.options.defaultScoreKey;
    scoreValue = get(currentOrgScore, `value[${defaultScoreKey}]`, get(currentOrgScore, `value[${defaultScoreKey}][0]`));
  }

  const templateContext = isObject(scoreValue) ? scoreValue : currentOrgScore;

  const items = scoringOptions.shape
    .filter(scoringOption => when(scoringOption.when, options))
    .map((scoringOption) => {
      const {
        valueKey = 'value',
        type: formatType = get(scoringOption, 'type', get(scoringOptions, 'type', 'identity')),
        template: valueTemplate
      } = scoringOption;

      if (!validScoreValue(templateContext[valueKey])) return null;
      const formattedValue = FORMATTERS[formatType](templateContext[valueKey], get(node, 'scoring_options.format_options'));

      return {
        ...scoringOption,
        value: valueTemplate ? template(valueTemplate, { value: formattedValue }) : formattedValue
      };
    });

  return compact(items);
};

export default adapter;
