import React from 'react';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { requestBlob } from '../../helpers/request';

class DataSetsLastUpdatedAt extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      lastUpdatedAt: null
    };
  }

  componentDidMount() {
    this.fetchLastUpdatedAt();
  }

  fetchLastUpdatedAt() {
    requestBlob(this.props.lastUpdatedAtUrl)
      .then(blob => blob.text())
      .then((text) => {
        this.setState({ lastUpdatedAt: text });
      });
  }

  render() {
    const { lastUpdatedAt } = this.state;

    if (!lastUpdatedAt) return null;

    const date = new Date(lastUpdatedAt);
    const isDateInvalid = isNaN(date.getTime());

    if (isDateInvalid) return null;

    return (
      <div className="cui-text cui-text_small cui-margin-bottom-medium">
        Data is updated as of {format(date, 'MM/dd/yyyy')}
      </div>
    );
  }
}

DataSetsLastUpdatedAt.propTypes = {
  lastUpdatedAtUrl: PropTypes.string.isRequired
};

export default DataSetsLastUpdatedAt;
