export default `
  query {
    organization(id: :id) {
      id
      name
      entity_type
      dist_number
      sch_number
      sch_cd
      sch_type
      children(years: [:year]) {
        id
        name
        entity_type
        dist_number
        sch_number
        sch_type
        parentDistrict: parent_district {
          id
        }
      }
      parentDistrict: parent_district {
        id
        name
        entity_type
        children(years: [:year]) {
          id
          name
          entity_type
          dist_number
          sch_number
        }
      }
    }
  }
`;
