import { useQuery } from 'react-query';

import { importFns } from 'actions/framework';

export const frameworkQuery = year => ({
  queryKey: ['framework-tree', year],
  queryFn: () => importFns[year]().then(module => module.default),
  staleTime: Infinity,
  cacheTime: Infinity
});

const useFrameworkQuery = year => useQuery(frameworkQuery(year));

export default useFrameworkQuery;
