import React from 'react';
import PropTypes from 'prop-types';
import isString from 'lodash/isString';

import EmptyState from 'cui/lib/components/EmptyState';

const ChartEmptyState = ({ emptyMessage, emptySubtitle }) => {
  let props = {};

  if (!isString(emptyMessage)) {
    props = { ...emptyMessage };
  } else {
    props = { title: emptyMessage, subtitle: emptySubtitle };
  }

  return (
    <EmptyState kind="text" {...props} />
  );
};

ChartEmptyState.propTypes = {
  emptyMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object
  ]),
  emptySubtitle: PropTypes.string
};

ChartEmptyState.defaultProps = {
  emptyMessage: 'There is no data available.',
  emptySubtitle: null
};

export default ChartEmptyState;
