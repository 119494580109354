import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import startCase from 'lodash/startCase';

import template from '../../../helpers/template';

export const DefaultFormatter = (props) => {
  const { value, templateString, valueKey, titleCase, formatterClasses } = props;
  let theValue = value;
  if (valueKey) {
    theValue = get(value, valueKey);
  }
  theValue = titleCase ? startCase(theValue) : theValue;
  const compiledValue = template(templateString, { ...props, value: theValue });
  return (
    <span className={formatterClasses}>
      {compiledValue}
    </span>
  );
};

DefaultFormatter.propTypes = {
  cellText: PropTypes.string,
  value: PropTypes.string,
  templateString: PropTypes.string,
  valueKey: PropTypes.string,
  formatterClasses: PropTypes.string,
  titleCase: PropTypes.bool
};

DefaultFormatter.defaultProps = {
  cellText: '',
  value: '',
  templateString: '{{value}}',
  valueKey: '',
  formatterClasses: '',
  titleCase: false
};

export default DefaultFormatter;
