import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import List from 'cui/lib/components/List';

import AnchorLink from './Link';

const NAV_DESCRIPTION = 'Glossary Navigation: Choose a Letter for a List of Terms';

const GlossaryNav = props => (
  <List inline className={cx('glossaryNav', props.className)} role="navigation" aria-label={NAV_DESCRIPTION}>
    {Object.keys(props.data).map((key, index) => <List.Item key={index}><AnchorLink offset="40" href={key} /></List.Item>)}
  </List>
);

GlossaryNav.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string
};

export default GlossaryNav;
