import React from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Element } from 'react-scroll';
import EmptyState from 'cui/lib/components/EmptyState';

import { getMetadataContentByPath } from 'components/OrgReportCard/helpers';
import { filterPublicOrWithPermissionsChart } from 'helpers/dashboardChartHelpers';
import { filterByMetadata } from 'helpers/nodeHelpers';
import OrgReportCardDomainGatewayPanel from 'components/OrgReportCardDomainGatewayPanel';
import OrgReportCardChart from 'components/OrgReportCard/OrgReportCardChart';
import { findScoresForNodePaths } from 'components/OrgReportCard/index';

const Domain = props => {
  const {
    domain,
    currentOrganization,
    userRole,
    framework,
    metadataProps,
    scoresByGroup,
    isLoading
  } = props;
  const successIndicatorLinks = domain.items;

  const subheading = getMetadataContentByPath(
    domain.metadata,
    'description',
    currentOrganization
  );

  const charts = get(domain, 'metadata.dashboard.charts', [])
    .filter(chart => filterPublicOrWithPermissionsChart(chart, userRole, framework))
    .filter(chart => filterByMetadata(chart, metadataProps));

  const restrictAccess = get(domain, 'metadata.restrictAccess', []);
  const domainEmptyStateMessage = get(domain, 'metadata.emptyStateMessage');

  return (
    <Element name={domain.slug} key={domain.slug}>
      <OrgReportCardDomainGatewayPanel
        heading={domain.name}
        subheading={subheading}
        allDataLink="" // TODO: Where should this go?
        iconUrl={`//bb-production-assets.s3.amazonaws.com/kde/domainIcons/${domain.slug}_icon.svg`}
        currentOrganization={currentOrganization}
        successIndicatorLinks={successIndicatorLinks}
        restrictAccess={restrictAccess}
      >
        {charts.map((chart, index) => {
          const { scores, currentScore } = findScoresForNodePaths(
            scoresByGroup,
            get(chart, 'metadata.data_node_path'),
            currentOrganization,
            chart,
            true
          );
          const emptyStateMessage = get(chart, 'metadata.emptyStateMessage');

          return (
            <OrgReportCardChart
              additionalProps={{ node: domain }}
              chartConfig={chart}
              currentScore={currentScore}
              isLoading={isLoading}
              key={`${domain.slug}-${index}`}
              organization={currentOrganization}
              emptyText={emptyStateMessage}
              scores={scores}
            />
          );
        })}
        {isEmpty(charts) && (
          <EmptyState
            className="gatewayGrid-item_double"
            kind="text"
            {...domainEmptyStateMessage}
          />
        )}
      </OrgReportCardDomainGatewayPanel>
    </Element>
  );
};

export default Domain;
