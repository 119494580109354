/**
* The component render a viewing drop down inside each data point tab.
* To show a viewing dropdown, add menuAction config under a tab config
* - name: Table
*   chartType: table
*   menuAction:
*     component: dataDropdown
* When the data point block component see this config, it will render dataDropdown component inside a tab.
* We can specify viewing dropdown option by adding a dataDropdown config under scoring_options, it will be
* a common config for all chart with dataDropdown component configured. We can also overwrite the common dataDropdown
* config by specify the config under a chart config.
* Here's a common dataDropdown config:
* scoring_options:
*   dataDropdown:
*     school:
*       grades:
*         - grade_3
*         - grade_4
*         - grade_5
*         - grade_6
*         - grade_7
*         - grade_8
*         - grade_11
*     district:
*       - elementary_school
*       - middle_school
*       - high_school
*     state:
*       - elementary_school
*       - middle_school
*       - high_school
* We configure the dropdown options for each org type (school, district, state).
* For district and state, we use the array as the dropdown options without further processing.
* For school, we can also use an array of options. There's a case that we want to display grades only
* relevant to specific school level (elementary, middle, high) school. To achieve that, specify lists of grades
* to show under the school.grades, the component will figure out what grades to show by checking if a grade in
* that list falls within the low_grade and high_grade range of the school.
* The array items in the config are group key in a score.
* A score may looks like the following:
*
* {
*    elementary_school: { eth_asian: { events: 100 }, eth_white: { events: 200} },
*    middle_school: { eth_asian: { events: 100 }, eth_white: { events: 200} },
*    high_school: { eth_asian: { events: 100 }, eth_white: { events: 200} }
* }
*
* Important:
* The DataPointBlock component will maintain the current viewing dropdown option for each tab and pass
* it down to the Chart component. It's up to the developer to use the viewing dropdown option to generate proper
* score shape. We could pass it down to the adapter or however we want to handle viewing dropdown filtering.
*
* Example 2:
* There is case when a dp only group its data under levels for all org type.
* In this case, we only want to select the school level(s) relevent to the school only.
* scoring_options:
*   dataDropdown:
*     school:
*       levels:
*         - elementary_school
*         - middle_school
*         - high_school
*     district:
*       - elementary_school
*       - middle_school
*       - high_school
*     state:
*       - elementary_school
*       - middle_school
*       - high_school
* For school, it will return a list of levels which contains only one item normally, it should also work
* for school with more than one school level.
**/
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import Dropdown from 'cui/lib/components/Dropdown';
import Label from 'cui/lib/components/Label';

import dropdownItems from '../../helpers/data_dropdown_helper';

import DataDropdownItem from './DataDropdownItem';
import { currentScore, currentChartConfig, findItemIndexByFilterKey } from './helpers';
import styles from './DataDropdown.module.scss';

function toggleAriaLabel(nodeName, label, selectedItemText) {
  if (label) {
    return `${nodeName}, ${label}, ${selectedItemText}`;
  }

  return `${nodeName}, ${selectedItemText}`;
}

export default class DataDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      initiallySelectedItemIndex: 0,
      selectedItemText: ''
    };
  }

  componentDidMount() {
    const { config, node, organization, tab, scores, index, currentItem: selectedFilter } = this.props;

    this.setDropdownItems({ config, node, organization, scores, selectedFilter: get(selectedFilter, index), tab });
  }

  componentWillReceiveProps(newProps) {
    const { config, node, organization, tab, scores, selectedFilter } = this.props;

    if (
      newProps.config !== config ||
      newProps.node !== node ||
      newProps.organization !== organization ||
      newProps.tab !== tab ||
      newProps.scores !== scores ||
      newProps.selectedFilter !== selectedFilter
    ) {
      this.setDropdownItems({
        config: newProps.config,
        node: newProps.node,
        scores: newProps.scores,
        organization: newProps.organization,
        selectedFilter: this.state.items.length > 0 ? get(newProps.selectedFilter, newProps.index) : newProps.currentItem,
        tab: newProps.tab
      });
    }
  }

  onSelectionChange = (filter) => {
    const { index, tab } = this.props;
    this.setState({ selectedItemText: filter.text });
    this.props.onChange(filter.filterKey, index, tab.name);
  }

  setDropdownItems({ config, node, organization, scores, selectedFilter, tab }) {
    const tabName = get(tab, 'name');
    const chartConfig = currentChartConfig(node, tab);
    const score = currentScore({ scores, chartConfig, node, organization });
    const items = dropdownItems(config, node, organization, chartConfig, score);
    const initiallySelectedItemIndex = findItemIndexByFilterKey(items, selectedFilter);

    const { items: prevItems, initiallySelectedItemIndex: prevInitiallySelectedItemIndex, tabName: prevTabName } = this.state;

    if (!isEqual(items, prevItems) || !isEqual(initiallySelectedItemIndex, prevInitiallySelectedItemIndex) || prevTabName !== tabName) {
      this.setState({ items, initiallySelectedItemIndex, tabName });
      this.setDefaultOption(items, initiallySelectedItemIndex, tabName);
    }
  }

  setDefaultOption(items, initiallySelectedItemIndex, tabName) {
    // Default to be the first option
    return !isEmpty(items) && this.onSelectionChange(items[initiallySelectedItemIndex], tabName);
  }

  render() {
    const { items, initiallySelectedItemIndex } = this.state;
    const label = get(this.props.config, 'label');
    const containerStyles = this.props.config?.styles;

    // Do not show dropdown if there is less than 2 item
    if (isEmpty(items)) {
      return null;
    }

    // Default to be first option
    return (
      <div className={styles.dataDropdownWrapper} style={containerStyles}>
        {label && <Label value={label} />}
        <Dropdown
          items={items}
          menuItem={DataDropdownItem}
          selectable
          className={styles.dropdown}
          trigger="click"
          onSelectionChange={this.onSelectionChange}
          appendToBody={false}
          initiallySelectedItem={initiallySelectedItemIndex}
          toggleAriaLabel={toggleAriaLabel(this.props.node.name, label, this.state.selectedItemText)}
        />
      </div>
    );
  }
}

DataDropdown.propTypes = {
  config: PropTypes.object,
  index: PropTypes.number,
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  currentItem: PropTypes.string
};
