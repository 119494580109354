import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';

import { findAnyDimensionNodes } from '../../helpers/frameworkHelpers';
import conditional from '../../helpers/conditional';
import {
  filterByMetadata,
  isPublicOrWithClarityUserPermissions,
  isNodeVisible
} from '../../helpers/nodeHelpers';
import metadataFilterProps from '../../helpers/metadataHelpers';

export default function propMapper(props) {
  const {
    framework = {},
    baseRoutes = [],
    selected,
    nestedNodeType,
    userRole
  } = props;

  if (isEmpty(framework)) return props;

  const metadataProps = metadataFilterProps(props);
  let nodes = nestedNodeType === 'domain' ? framework.items : findAnyDimensionNodes(framework, nestedNodeType) || [];
  nodes = nodes
    .filter(node => filterByMetadata(node, metadataProps))
    .filter(node => isPublicOrWithClarityUserPermissions(node, userRole))
    .filter(isNodeVisible);

  const findTabIndex = memoize(findIndex);
  const activeTab = findTabIndex(nodes, { slug: selected });

  const newProps = {
    ...props,
    nodes,
    activeTab
  };

  // Conditionally select from `baseRoutes` and replace `routeTemplate` prop
  const findBaseRoutes = memoize(conditional);
  const routeObj = findBaseRoutes(baseRoutes, '', props);
  if (!isEmpty(routeObj)) {
    newProps.routeTemplate = routeObj.value;
  }

  return newProps;
}
