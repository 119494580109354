import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import Dropdown from 'cui/lib/components/Dropdown';

import styles from './YearSelect.module.scss';
import mapStateToProps from './mapStateToProps';

const yearItemShape = {
  selected: PropTypes.bool,
  text: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

const YearSelectItem = ({ item, selectedItem }) => {
  const selected = selectedItem.value === item.value;
  const classNames = cn({
    [styles.dropdown__item]: true,
    [styles['dropdown__item--selected']]: selected
  });
  const handleClick = (event) => {
    event.preventDefault();
    item.onClick();
  };

  return (
    <li className={classNames}>
      <a
        href="#"
        onClick={handleClick}
        role="application" // eslint-disable-line jsx-a11y/no-interactive-element-to-noninteractive-role
        aria-label={item.text}
      >
        {item.text}
      </a>
    </li>
  );
};

YearSelectItem.propTypes = {
  item: PropTypes.shape(yearItemShape),
  selectedItem: PropTypes.shape(yearItemShape)
};

export const YearSelect = ({ years, initiallySelectedValue, onSelect }) => {
  const initiallySelectedYear = Math.max(years.findIndex(({ value }) => value === initiallySelectedValue), 0);
  const handleSelectionChange = ({ value }) => onSelect(value);
  const schoolYear = Array.isArray(years) ? years[initiallySelectedYear] : { text: '' };

  return (
    <div>
      <h2 className={styles.label}>
        School Year:
      </h2>
      <Dropdown
        appendToBody={false}
        className={styles.dropdown}
        initiallySelectedItem={initiallySelectedYear}
        items={years}
        menuItem={YearSelectItem}
        onSelectionChange={handleSelectionChange}
        selectable
        toggleAriaLabel={`school year ${schoolYear.text}`}
      />
    </div>
  );
};

YearSelect.propTypes = {
  onSelect: PropTypes.func,
  initiallySelectedValue: PropTypes.number,
  years: PropTypes.arrayOf(PropTypes.shape(yearItemShape)).isRequired
};

YearSelect.defaultProps = {
  onSelect: () => {},
  years: []
};

export default connect(mapStateToProps)(YearSelect);
