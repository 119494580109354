import React from 'react';
import { Formik, Field } from 'formik';
import cx from 'classnames';

import Button from 'cui/lib/components/Button';
import Form from 'cui/lib/components/Form';
import useDistrictMessageQuery, {
  useDeleteDistrictMessageMutation,
  useUpdateDistrictMessageMutation
} from 'store/backend/queries/district_messages';
import useAlert from './useAlert';
import validationSchema from './validationSchema';
import styles from './styles.module.scss';

const DistrictMessageForm = ({ organizationId, year }) => {
  const { data: districtMessage, isLoading } = useDistrictMessageQuery(
    organizationId,
    year
  );
  const updateMutation = useUpdateDistrictMessageMutation();
  const deleteMutation = useDeleteDistrictMessageMutation();
  const { alertType, alertMessage, displayAlert } = useAlert();

  if (isLoading) return null;

  const initialValues = {
    body: districtMessage?.body ?? '',
    author: districtMessage?.author ?? '',
    title: districtMessage?.title ?? ''
  };

  const handleUpdate = async (values, { setSubmitting }) => {
    try {
      await updateMutation.mutateAsync({ organizationId, year, ...values });
      setSubmitting(false);
      displayAlert('update');
    } catch {
      displayAlert('error');
    }
  };

  const handleDelete = async () => {
    try {
      await deleteMutation.mutateAsync({ organizationId, year });
      displayAlert('delete');
    } catch {
      displayAlert('error');
    }
  };

  return (
    <div className="cui-panel">
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={initialValues}
        initialTouched={{ body: true, author: true, title: true }}
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
      >
        {({ handleSubmit, errors, isSubmitting }) => (
          <Form onSubmit={handleSubmit}>
            <div className={cx('cui-panel-header', styles.panelHeader)}>
              Superintendent&apos;s Message
              <div
                className={cx(styles.alert, alertType && styles[`alert_${alertType}`])}
              >
                {alertMessage}
              </div>
            </div>

            <div className="cui-panel-content">
              <div className={cx('cui-flexbar', 'cui-flexbar_top', styles.instructions)}>
                <div className={styles.instructionsLeftPane}>
                  <div className="cui-heading cui-caps">Share your message</div>
                  <p>
                    Each district has a unique story. Looking at the data alone in the
                    School Report Card only tells one part of that story. Ensure that
                    parents, community members and researchers have a more complete
                    picture of your district by providing a brief message. Narratives
                    should be concise and easily understood. Consider sharing your vision,
                    mission, unique program, initiative, or special learning opportunities
                    that helps someone viewing School Report Card data understand more
                    about your district. This is optional and there is a 750-character
                    limit.
                  </p>
                </div>
                <div className={styles.instructionsRightPane}>
                  <div className="cui-heading cui-caps">Publishing your message</div>
                  <ul>
                    <li>
                      To view your message, go to the Approval tab and select the Preview
                      Dashboard link. Your message will be beneath the organization
                      summary.
                    </li>
                    <li>Message text is limited to 750 characters.</li>
                  </ul>
                </div>
              </div>
              <label className="cui-heading cui-caps">
                Your message
                <Field
                  id="message-body"
                  name="body"
                  as="textarea"
                  className="cui-input cui-textarea cui-input_stroked"
                />
              </label>
              <div className="cui-flexbar cui-margin-top-medium">
                <label className="cui-heading cui-caps cui-flexbar-item_grow">
                  Author name
                  <Field
                    id="message-author"
                    name="author"
                    className="cui-input cui-input_stroked"
                  />
                </label>
                <label className="cui-heading cui-caps cui-flexbar-item_grow">
                  Title/position
                  <Field
                    id="author-title"
                    name="title"
                    className="cui-input cui-input_stroked"
                  />
                </label>
              </div>
            </div>
            <div
              className={cx(
                'cui-panel-footer cui-flexbar',
                !districtMessage && 'cui-flexbar_right'
              )}
            >
              {districtMessage && (
                <Button
                  type="button"
                  theme="caution"
                  kind="stroked"
                  title="Delete district message"
                  onClick={handleDelete}
                >
                  Delete district message
                </Button>
              )}

              <Button disabled={Object.entries(errors).length > 0 || isSubmitting} type="submit" theme="primary" kind="solid" title="Save">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DistrictMessageForm;
