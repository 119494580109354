import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cx from 'classnames';
import ScoreDot from 'cui/lib/components/ScoreDot';
import List from 'cui/lib/components/List';
import Heading from 'cui/lib/components/Heading';
import styles from './index.module.scss';
import KdeBarChart from '../KdeBarChart';

/* eslint-disable complexity */
function KdeGroupedBarChartPrototype(props) {
  const {
    config,
    organization,
    scores
  } = props;

  const labelDescription = get(config, 'options.chartOptions.labelDescription', '% Students');
  const labelInterpolation = get(config, 'options.chartOptions.labelInterpolation', '');
  const labelCount = get(config, 'options.chartOptions.xAxis.labelCount', 6);
  const high = get(config, 'options.chartOptions.high', 100);
  const horizontal = get(config, 'options.chartOptions.horizontal', true);

  const defaultChartOptions = {
    xAxis: {
      showGrid: false,
      showAxis: true,
      showLabel: true,
      position: 'start',
      labelAlignment: 'start',
      labelCount,
      labelDescription: horizontal && [
        {
          description: labelDescription,
          position: 'center',
          alignment: 'center'
        }
      ],
      labelInterpolationFunc: horizontal ? l => `${l}${labelInterpolation}` : l => l
    },
    yAxis: {
      showLabel: true,
      showGrid: true,
      showAxis: true,
      labelCount,
      labelAlignment: 'center',
      labelDirection: 'btt',
      labelInterpolationFunc: !horizontal ? l => `${l}${labelInterpolation}` : l => l,
      title: !horizontal && labelDescription
    },
    low: 0,
    high,
    barWidthRatio: 0.95,
    animate: false,
    horizontal,
    valueLabel: true
  };

  function ChartLegend({ legendItems, theme }) {
    return (
      <List inline className={styles.legend}>
        {
          legendItems.map((d, i) => (
            <List.Item
              key={i}
              className={styles.legendItem}
              icon={<ScoreDot type="solid" theme={theme} value={d.colorIndex !== undefined ? d.colorIndex : i} />}
            >
              {d.label}
            </List.Item>
          ))
        }
      </List>
    );
  }

  ChartLegend.propTypes = {
    legendItems: PropTypes.arrayOf(PropTypes.object),
    theme: PropTypes.string,
    legendPlacement: PropTypes.string
  };

  ChartLegend.defaultProps = {
    theme: 'kde-multi',
    legendPlacement: 'top'
  };

  const labels = get(config, 'options.labels', '');
  const dimension = get(config, 'options.dimension', '');
  const generateSampleData = () => {
    const sampleData = labels ? [{
      label: labels[0],
      value: [{ label: 'State', colorIndex: 2, total_students: 1950, value: 12 }]
    }, {
      label: labels[1],
      value: [{ label: 'State', colorIndex: 2, total_students: 2309, value: 17 }]
    }, {
      label: labels[2],
      value: [{ label: 'State', colorIndex: 2, total_students: 1722, value: 15 }]
    }] : [{
      label: dimension,
      value: [{ label: 'State', colorIndex: 2, total_students: 6209, value: 12 }]
    }];

    if (organization.entity_type !== 'State') {
      sampleData[0].value.unshift({ label: 'District', colorIndex: 1, total_students: 438, value: 20 });
      if (labels) {
        sampleData[1].value.unshift({ label: 'District', colorIndex: 1, total_students: 560, value: 41 });
        sampleData[2].value.unshift({ label: 'District', colorIndex: 1, total_students: 174, value: 32 });
      }
    }

    if (organization.entity_type === 'School') {
      sampleData[0].value.unshift({ label: 'School', colorIndex: 0, total_students: 103, value: 35 });
      if (labels) {
        sampleData[1].value.unshift({ label: 'School', colorIndex: 0, total_students: 138, value: 23 });
        sampleData[2].value.unshift({ label: 'School', colorIndex: 0, total_students: 238, value: 31 });
      }
    }

    return sampleData;
  };

  const data = get(scores, null) || generateSampleData();
  const chartTitle = get(config, 'options.score', '');
  const chartTheme = get(config, 'options.theme', 'kde-multi');

  return (
    <div className={cx('cui-media cui-media_center cui-media_chart', styles.wrapper)}>
      <Heading level={3} size="base">{chartTitle}</Heading>
      <ChartLegend legendItems={data[0].value} theme={chartTheme} />
      <div className={styles.chartWithLabel}>
        <div className="cui-grid-cell">
          <KdeBarChart
            {...props}
            sampleData={data}
            defaultChartOptions={defaultChartOptions}
          />
          {labels && <div className="cui-chartAxis-label">{dimension}</div>}
        </div>
      </div>
    </div>
  );
}

KdeGroupedBarChartPrototype.propTypes = {
  config: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object)
};

export default KdeGroupedBarChartPrototype;
/* eslint-enable complexity */
