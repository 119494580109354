import React from 'react';
import PropTypes from 'prop-types';

import cloneDeep from 'lodash/cloneDeep';
import findIndex from 'lodash/findIndex';

import Checkbox from 'cui/lib/components/Form/Checkbox';

import uuid from 'uuid/v4';

import BarChart from '../BarChart';

export class ConfidenceIntervalsBarChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfidenceIntervals: false
    };
  }

  render() {
    const chartProps = cloneDeep(this.props);
    if (chartProps.scores && chartProps.scores[0] && !this.state.showConfidenceIntervals) {
      const index = findIndex(chartProps.scores, { remote_organization_id: this.props.organization.id });
      const score = chartProps.scores[index];

      chartProps.scores[index] = score;
    }

    return (
      <div>
        <div className="cui-margin-bottom-large">
          <Checkbox
            id={uuid()}
            label="Confidence Intervals"
            defaultValue={this.state.showConfidenceIntervals}
            onChange={showConfidenceIntervals => this.setState({ showConfidenceIntervals })}
          />
        </div>
        <BarChart {...chartProps} showConfidenceIntervals={this.state.showConfidenceIntervals} />
      </div>
    );
  }
}

ConfidenceIntervalsBarChart.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.string
  })
};

export default ConfidenceIntervalsBarChart;
