import get from 'lodash/get';

export const isApprovableDomain = (domain, organization) => (
  get(domain, `approvable.${organization.entity_type.toLowerCase()}`, true)
);

export const supportsNarrative = (domain, organization) => (
  get(domain, `narrativeSupport.${organization.entity_type.toLowerCase()}`, false)
);

export default isApprovableDomain;
