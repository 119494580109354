import DonutChart from './components/DonutChart';
import kdeCompareChart from './chartDataAdapters/compareChart';
import kdeCompareChartStacked from './chartDataAdapters/compareChartStacked';
import kdeFrlChart from './chartDataAdapters/frlChart';
import kdeDonutPercentWithRemainder from './chartDataAdapters/donutChart/percentWithRemainderAdapter';
import kdeTableWithPercentRow from './chartDataAdapters/tableChart/tableWithPercentRowAdapter';
import kdeTableWithPercentColumn from './chartDataAdapters/tableChart/tableWithPercentColumnAdapter';
import kdeTableFromPortalData from './chartDataAdapters/tableChart/tableFromPortalData';
import kdeTableFromPortalDataQuestions from './chartDataAdapters/tableChart/tableFromPortalDataQuestions';
import kdeTableFromPortalDataInverted from './chartDataAdapters/tableChart/tableFromPortalDataInverted';
import kdeTableFromDimensionlessPortalData from './chartDataAdapters/tableChart/tableWithoutDimensions';
import kdeTableNestedDimensions from './chartDataAdapters/tableChart/tableWithNestedDimensions';
import kdeDataByOrgType from './chartDataAdapters/tableChart/dataByOrgTypeAdapter';
import stackedChart from './chartDataAdapters/barChart/stackedChartAdapter';
import extractKey from './chartDataAdapters/tableChart/extractKeyAdapter';
import flattenArrayOfObjects from './chartDataAdapters/tableChart/flattenArrayOfObjectsAdapter';
import dataByOrgFromPortal from './chartDataAdapters/tableChart/dataByOrgFromPortalAdapter';
import englishLearnerAttainment from './chartDataAdapters/tableChart/englishLearnerAttainmentAdapter';
import flattenList from './chartDataAdapters/flattenList';
import sortBy from './chartDataAdapters/sortBy';
import takeN from './chartDataAdapters/takeN';
import takeFirst from './chartDataAdapters/takeFirst';
import pickFields from './chartDataAdapters/pickFields';
import groupFields from './chartDataAdapters/groupFields';
import computeProps from './chartDataAdapters/computeProps';
import extractScoreUnderKey from './chartDataAdapters/extractScoreUnderKey';
import extractDimensions from './chartDataAdapters/extractDimensions';
import combineScores from './chartDataAdapters/combineScores';
import objectToArray from './chartDataAdapters/objectToArray';
import addRemainder from './chartDataAdapters/addRemainder';
import mergeScores from './chartDataAdapters/mergeScores';
import collectBy from './chartDataAdapters/collectBy';
import addValueToLabel from './chartDataAdapters/addValueToLabel';
import KdeCondensedTable from './components/KdeCondensedTable';
import KdeGroupedBarChart from './components/KdeGroupedBarChart';
import KdeOverviewBarChart from './components/KdeOverviewBarChart';
import KdeDonutChart from './components/KdeDonutChart';
import KdeDashboardDonutChart from './components/KdeDashboardDonutChart';
import KdeRangeSlider from './components/KdeRangeSlider';
import KdeLineRangeSlider from './components/KdeLineRangeSlider';
import KdePlaceholder from './components/KdePlaceholder';
import KdeSidewayBarChart from './components/KdeSidewayBarChart';
import KdeStackedCompareChart from './components/KdeStackedCompareChart';
import KdeVerticalBarChart from './components/KdeVerticalBarChart';
import KdeTrendsChart from './components/KdeTrendsChart';
import percentOverviewAdapter from './components/KdeOverviewBarChart/adapter';
import KdeProgressTextNumber from './components/KdeProgressTextNumber';
import KdeTextLink from './components/KdeTextLink';
import KdeTextNumber from './components/KdeTextNumber';
import extractScore from './chartDataAdapters/extractScore';
import invertNestedKeys from './chartDataAdapters/invertNestedKeys';
import barChartGroups from './chartDataAdapters/barChartGroups';
import arrayToObject from './chartDataAdapters/arrayToObject';
import extractTotal from './chartDataAdapters/extractTotal';
import extractFilteredScore from './chartDataAdapters/extractFilteredScore';
import lineChart from './chartDataAdapters/lineChart';
import parentData from './chartDataAdapters/parentData';
import singleBarChart from './chartDataAdapters/singleBarChart';
import KdeLineChart from './components/KdeLineChart';
import KdeListChart from './components/KdeListChart';
import KdeDashboardSingleBarChart from './components/KdeDashboardSingleBarChart';
import KdeDemographicDonutChart from './components/KdeDashboardDonutChart/DemographicDonutChart';
import KdeGroupedBarChartPrototype from './components/KdeGroupedBarChartPrototype';
import KdeTableChart from './ui/TableChart';
import KdeTableChartLegend from './ui/TableChartLegend';
import KdeTableChartQuestion from './ui/TableChartQuestion';
import AcctScoreChart from './components/AcctScoreChart';
import AcctGaugeChart from './components/AcctGaugeChart';
import AcctGaugeChartV2 from './components/AcctGaugeChartV2';
import AcctGaugeStudentGroupDetails from './components/AcctGaugeStudentGroupDetails';
import KdeCompareChart from './components/CompareChart';
import GaugeGrid from './ui/GaugeGrid';
/* eslint-disable import/prefer-default-export */

export const MAIN_CONTENT_ANCHOR = 'main-content-anchor';

// The clarity organization id of Kentucky state
// Serves as the root org for the KDE hierarchy
export const KENTUCKY_ORG_ID = 20;

// the school year we start loading scores from
export const FIRST_SCHOOL_YEAR = 2018;

export const ORG_TYPES = {
  SCHOOL: 'SCHOOL',
  DISTRICT: 'DISTRICT',
  STATE: 'STATE'
};

export const DEFAULT_SUPPRESSION_COPY = `
  Data is displayed to the extent available for public reporting and is reflective of the student population.
  To protect the privacy of an individual student’s identity or performance,
  the data tables include an asterisk (*) to indicate insufficient data. If no data is displayed,
  the data is unavailable for the year or population.
`;

export const DEFAULT_NOT_AUTHORIZED_COPY = 'You are not authorized to view this data.';

export const KDE_COLLECTOR_FG = 'KDECollector';
export const KDE_APPROVAL_FG = 'KDEApproval';
export const SPR_PDF_DOWNLOAD_FG = 'SPRPDFDownload';
export const KDE_COMPARISONS_FG = 'KDEComparisons';
export const KDE_NEXT_YEAR_FG = 'KDENextYear';
export const KDE_APPROVAL_DOMAIN_FGS = {
  school_overview: 'KdeApprovalSchoolOverview',
  academic_performance: 'KdeApprovalAcademicPerformance',
  educational_opportunity: 'KdeApprovalEducationalOpportunity',
  transition_readiness: 'KdeApprovalTransitionReadiness',
  school_safety: 'KdeApprovalSchoolSafety',
  school_accountability: 'KdeApprovalSchoolAccountability',
  financial_transparency: 'KdeApprovalFinancialTransparency',
  other_education_data: 'KdeApprovalOtherEducationData'
};

export const KDE_COLLECTION_DOMAIN_FGS = {
  school_overview: 'KdeCollectionSchoolOverview',
  academic_performance: 'KdeCollectionAcademicPerformance',
  educational_opportunity: 'KdeCollectionEducationalOpportunity',
  transition_readiness: 'KdeCollectionTransitionReadiness',
  school_safety: 'KdeCollectionSchoolSafety',
  school_accountability: 'KdeCollectionSchoolAccountability',
  financial_transparency: 'KdeCollectionFinancialTransparency'
};

/* eslint-disable quote-props*/
export const BOARD_MEMBERS_INFO_MAP = {
  'Exempt': 'Pursuant to KRS 160.345(5), this school is exempt from the requirement of implementing a School-Based Decision Making (SBDM) council.',
  'CSI': 'Pursuant to KRS 160.346(7)(b), the authority of the school council granted under KRS 160.345 has been transferred to the superintendent. ' +
    'An advisory leadership team representing school stakeholders including other leaders, teachers, and parents ' +
    'has been established at this school.',
  'Not Required': 'Pursuant to KRS 160.345(1)(b), this school is not required to establish a School-Based Decision Making (SBDM) council.'
};
/* eslint-enable quote-props*/

export const FEDERAL_CLASSIFICATION_MAPPED_REASONS = {
  Asian: 'Student Group Racial and Ethnic Group - Asian',
  Disability: 'Student Group - Students with Disability',
  Female: 'Student Group Gender - Female',
  Hispanic: 'Student Group Racial and Ethnic Group - Hispanic or Latino',
  Male: 'Student Group Gender - Male',
  Migrant: 'Student Group - Migrant',
  White: 'Student Group Racial and Ethnic Group - White (non-Hispanic)',
  'African American': 'Student Group Racial and Ethnic Group - African American',
  'Economically Disadvantaged': 'Student Group - Economically Disadvantaged',
  'English Learners': 'Student Group - Current Year English Learners',
  'English Learners plus Monitored': 'Student Group - English Learners Monitoring',
  'Native HI or Other Pacific Islander': 'Student Group Racial and Ethnic Group - Native Hawaiian or Pacific Islander',
  'Two or More Races': 'Student Group Racial and Ethnic Group - Two or More Races',
  'Bottom 5%': 'Bottom 5% of Title I or non-Title I school',
  'Graduation Rate': 'Less than 80% graduation rate'
};

export const SCHOOL_LEVELS = {
  elementary_school: 'Elementary School',
  middle_school: 'Middle School',
  high_school: 'High School'
};

export const FRAMEWORK_NODE_PATHS = {
  2018: {
    studentCount: 'kde.school_overview.students.end_of_year_enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.end_of_year_enrollment.student_membership'
  },
  2019: {
    studentCount: 'kde.school_overview.students.enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.enrollment.student_membership'
  },
  2020: {
    studentCount: 'kde.school_overview.students.enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.enrollment.student_membership'
  },
  2021: {
    studentCount: 'kde.school_overview.students.enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.enrollment.student_membership'
  },
  2022: {
    studentCount: 'kde.school_overview.students.enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.enrollment.student_membership'
  },
  2023: {
    studentCount: 'kde.school_overview.students.enrollment.total_student_count',
    studentMembership: 'kde.school_overview.students.enrollment.student_membership'
  }
};

// Overrides or local additions to the chart data adapters
// Import the adapter function and add as value for the corresponding key.
export const KDE_CHART_DATA_ADAPTERS = {
  kdeCompareChart,
  kdeCompareChartStacked,
  kdeDonutPercentWithRemainder,
  kdeTableWithPercentRow,
  kdeTableWithPercentColumn,
  kdeTableFromPortalData,
  kdeTableFromPortalDataQuestions,
  kdeTableFromPortalDataInverted,
  kdeTableFromDimensionlessPortalData,
  kdeTableNestedDimensions,
  kdeFrlChart,
  percentOverviewAdapter,
  kdeDataByOrgType,
  stackedChart,
  extractKey,
  flattenArrayOfObjects,
  dataByOrgFromPortal,
  englishLearnerAttainment,
  extractScore,
  invertNestedKeys,
  barChartGroups,
  lineChart,
  flattenList,
  sortBy,
  takeN,
  takeFirst,
  pickFields,
  groupFields,
  computeProps,
  extractScoreUnderKey,
  extractDimensions,
  combineScores,
  arrayToObject,
  extractTotal,
  extractFilteredScore,
  objectToArray,
  addRemainder,
  mergeScores,
  parentData,
  singleBarChart,
  collectBy,
  addValueToLabel
};

// Import the component and add as value for the corresponding key.
export const KDE_CHART_TYPES = {
  kdeDonutPercentWithRemainder: DonutChart,
  kdeGroupedBarChart: KdeGroupedBarChart,
  kdeOverviewBarChart: KdeOverviewBarChart,
  kdeDonutChart: KdeDonutChart,
  kdeDashboardDonutChart: KdeDashboardDonutChart,
  kdePlaceholder: KdePlaceholder,
  kdeRangeSlider: KdeRangeSlider,
  kdeLineRangeSlider: KdeLineRangeSlider,
  kdeTextLink: KdeTextLink,
  kdeTextNumber: KdeTextNumber,
  kdeProgressTextNumber: KdeProgressTextNumber,
  KdeSidewayBarChart,
  KdeStackedCompareChart,
  KdeVerticalBarChart,
  KdeLineChart,
  KdeListChart,
  KdeCondensedTable,
  KdeDashboardSingleBarChart,
  KdeDemographicDonutChart,
  KdeGroupedBarChartPrototype,
  KdeTableChart,
  KdeTableChartLegend,
  KdeTableChartQuestion,
  AcctScoreChart,
  AcctGaugeChart,
  AcctGaugeChartV2,
  AcctGaugeStudentGroupDetails,
  KdeCompareChart,
  KdeTrendsChart,
  GaugeGrid
};

export const HOME_PAGE_PATH = '/home';

// We have this thing called a preview site that allows us to preview stuff to
// KDE before going live and without having to give them access to our staging
// environment. The preview site changes things in a few ways:
//
//   - Feature flag listed in PREVIEW_SITE_FEATURE_FLAG_OVERRIDES below are
//     set to the indicated states
//   - Charts with a `preview_site_only` attribute set to true are displayed
//
// The preview site is enabled whenever the SRC is visited from one of a few
// domains, listed below in PREVIEW_SITE_DOMAINS. To view it locally, visit
// kde-preview.localhost:8080 instead of localhost:8080.
//
// You can detect whether a user is visiting from the preview site by pulling
// module.pageContext.isPreviewSite out of the redux store.

export const PREVIEW_SITE_FEATURE_FLAG_OVERRIDES = {
  KdeLanguageTranslationGoogle: true,
  KdeApprovalPeriodOpen: true,
  KdeApprovalDashboardLink: true,
  KdeApprovalSchoolAccountability: false,
  KdeApprovalSchoolOverview: true,
  KdeApprovalAcademicPerformance: false,
  KdeApprovalEducationalOpportunity: true,
  KdeApprovalTransitionReadiness: false,
  KdeApprovalSchoolSafety: true,
  KdeApprovalFinancialTransparency: false,
  KdeApprovalOtherEducationData: false,
  KDESchoolAccountabilityLandingPage: false,
  KDESchoolReportDownload: false,
  KdeCollectionSchoolOverview: true,
  KdeCollectionAcademicPerformance: false,
  KdeCollectionEducationalOpportunity: true,
  KdeCollectionTransitionReadiness: false,
  KdeCollectionSchoolSafety: true,
  KdeCollectionSchoolAccountability: false,
  KdeCollectionFinancialTransparency: false
};

export const PREVIEW_SITE_DOMAINS = [
  'kde-preview.bbportals.com',
  'kde-preview-staging.bbportals.com',
  'kde-preview.localhost'
];

export const SUPPORTED_YEARS = [
  {
    text: '2022-2023',
    value: 2023
  }, {
    text: '2021-2022',
    value: 2022
  }, {
    text: '2020-2021',
    value: 2021
  }, {
    text: '2019-2020',
    value: 2020
  }, {
    text: '2018-2019',
    value: 2019
  }, {
    text: '2017-2018',
    value: 2018
  }
];

export const ACCOUNTABILITY_DOMAIN_NODE_PATH = 'kde.school_accountability';
export const ACCOUNTABILITY_ACHIEVEMENT_NODE_PATH = 'kde.school_accountability.school_accountability_achievement_scores';
export const STUDENT_TEACHER_RATIO_NOTH_PATH = 'kde.school_overview.faculty_staff_community.faculty_counts.student_to_teacher_ratio';
export const ATTENDANCE_RATE_NODE_PATH = 'kde.school_overview.students.attendance.attendance_rate';
export const STUDENT_ECON_DISADVANTAGE_NODE_PATH = 'kde.school_overview.students.student_groups.economically_disadvantaged';
export const STUDENT_MEMBERSHIP_NODE_PATH = 'kde.school_overview.students.enrollment.student_membership';
export const READING_PROFICIENCY_NODE_PATH = 'kde.school_accountability.proficiency.reading.reading';
export const MATH_PROFICIENCY_NODE_PATH = 'kde.school_accountability.proficiency.mathematics.mathematics';
export const STUDENT_SPENDING_NODE_PATH = 'kde.financial_transparency.spending.spending_per_student.total_spending_per_student_state_local_funds';
/* eslint-enable import/prefer-default-export */
