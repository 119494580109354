/**
 * @name chartAdapterUtils
 * @description
 *   A collection of adapter functions to transform score data into formats usable by charts
 *   FIXME: These can be moved into individual files, as needed
 */

/* eslint-disable no-underscore-dangle */
import flatten, { unflatten } from 'flat';
import map from 'lodash/map';
import merge from 'lodash/merge';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import flow from 'lodash/flow';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import fpSlice from 'lodash/fp/slice';
import fpReduce from 'lodash/fp/reduce';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';
import isUndefined from 'lodash/isUndefined';

import when from '../when';

export const selectScoreValue = (score = {}, options) => {
  const { config } = options;
  const scoreKey = get(config, 'options.scoreKey');
  if (scoreKey && scoreKey in score) {
    return score[scoreKey];
  }
  return score;
};

export const subsetScoreValue = (score = {}, options) => {
  const { config } = options;
  const disaggregationKeys = get(config, 'options.disaggregationKeys');
  if (disaggregationKeys) {
    return pick(score, disaggregationKeys);
  }
  return score;
};

/**
 * Takes an object and transforms into an array of objects, with the key set as `__key`
 * @param {Object} score - Score data object
 * @return {Object[]}
 */
export const objToArray = (score = {}) => {
  if (!isObject(score)) return score;
  return map(score, (value, key) => ({
    ...value,
    __key: key
  }));
};

/**
 * Takes an object and sorts by the key, based on the `node.metadata.charts[].valueKey` configuration
 * @param {Object} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 * @param {string} options.config.options.valueKey - Chart config options (`node.metadata.charts[]`)
 * @return {Object}
 */
export const sortByProperty = (score = [], options) => {
  const { config, node } = options;
  const valueKey = get(config, 'options.valueKey');
  const sortByKeys = get(config, 'options.sortByKeys');
  const skipSort = get(config, 'options.skipSort');

  if (skipSort || (!valueKey && !sortByKeys)) return score;
  if (sortByKeys) {
    // sort score in order specified in the sortByKeys array
    const sortKeys = get(node, sortByKeys);
    if (sortKeys) {
      return orderBy(score, [s => sortKeys.indexOf(s.__key)], ['asc']);
    }
  }
  return orderBy(score, [valueKey], ['desc']);
};

/**
 * Takes an object and calculates percentages based on a numerator value, and the total value
 * @param {Object} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 * @param {string} options.config.options.totalKey - key of the data object to get the "total", i.e. denominator
 * @param {string} options.config.options.percentageNumeratorKey - key of the data object to get the numerator
 * @return {Object[]}
 */
export const calculatePercentages = (score = [], options) => {
  const { config } = options;
  const totalKey = get(config, 'options.totalKey');
  const percentageNumeratorKey = get(config, 'options.percentageNumeratorKey');
  const total = totalKey ?
    // Get from [totalKey] value
    get(find(score, { __key: totalKey }), percentageNumeratorKey) :
    // Otherwise, calculate the total
    reduce(score, (acc, value) => (acc + value[percentageNumeratorKey]), 0);

  if (!totalKey || !total) return score;
  return compact(map(score, (value) => {
    if (value.__key === totalKey) return null; // eslint-disable-line no-underscore-dangle
    return {
      ...value,
      percentage: (value[percentageNumeratorKey] / total) * 100
    };
  }));
};

export const selectNestedGroup = (score = {}, options) => {
  const { config } = options;
  const selectedNestedGroup = get(config, 'options.selectedNestedGroup');
  if (selectedNestedGroup) {
    const unnestedScores = reduce(score, (result, value, key) => ({
      ...result,
      [key]: get(value, selectedNestedGroup)
    }), {});
    return pickBy(unnestedScores, val => !isUndefined(val));
  }
  return score;
};

export const calculateDemographicPercentages = (score = {}, options) => {
  const { config } = options;
  const demographicTotalKey = get(config, 'options.demographicTotalKey');
  const percentageNumeratorKey = get(config, 'options.percentageNumeratorKey');
  if (!demographicTotalKey || !percentageNumeratorKey) return score;
  return map(score, value => ({
    ...value,
    percentage: (value[percentageNumeratorKey] / value[demographicTotalKey]) * 100
  }));
};

/**
 * Takes an object and calculates percentages based on a numerator value, and the total value
 * NOTE: this does not sort the scores beforehand, so it will group the score array as-is
 * @param {Object} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 * @param {number} options.config.options.groupingThreshold -
 *   Any groups above this integer are grouped into an "other" section. This makes a total of n + 1 groups
 * @return {Object[]}
 */
export const groupUnderThreshold = (score = [], options) => {
  const { config } = options;
  const groupingThreshold = get(config, 'options.groupingThreshold');
  if (!groupingThreshold || score.length <= groupingThreshold) return score;

  const buildOther = flow(
    fpSlice(groupingThreshold, undefined), // Quirk of fp.slice, slice is fixed arity of 3
    fpReduce((acc, { value }) => (acc + value), 0)
  );

  const mainScores = score.slice(0, groupingThreshold);
  const otherScores = {
    label: 'Other',
    value: buildOther(score)
  };

  return [...mainScores, otherScores];
};

/**
 * Takes an array of score value objects and creates a `label` for each object using the `__key` property
 * If not value is found in the `labelMap`, then the original key value is used
 * @param {Object} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 * @param {number} options.node.scoring_options.labelMap -
 *   Any groups above this integer are grouped into an "other" section. This makes a total of n + 1 groups
 * @return {Object[]}
 */
export const mapKeyToLabel = (score = [], options) => {
  const { node } = options;
  let labelMap = get(node, 'scoring_options.labelMap');
  const scoreShape = get(node, 'scoring_options.shape');
  if (!labelMap) {
    if (!scoreShape) {
      return score;
    } else {
      labelMap = reduce(scoreShape, (memo, value, key) => {
        memo[key] = value.label;
        return memo;
      }, {});
    }
  }

  return map(score, ({ __key, ...item }) => ({
    ...item,
    __key,
    label: get(labelMap, __key, __key)
  }));
};

/**
 * Takes an array of score value objects and creates a `colorIndex` for each object using the `__key` property
 * @param {Object} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 */
export const mapKeyToColorIndex = (score = [], options) => {
  const { node } = options;
  const scoreShape = get(node, 'scoring_options.shape');
  if (!scoreShape) return score;

  return map(score, ({ __key, ...item }) => ({
    ...item,
    __key,
    colorIndex: get(scoreShape, [__key, 'colorIndex'])
  }));
};

/**
 * Converts an array of score value objects into a format that Cui.BarChart can render
 * @param {Object[]} score - Score data object
 * @param {Object} options - Chart config options (`node.metadata.charts[]`)
 * @param {string} options.config.options.valueKey - Key of data object to select the value to display for
 * @return {Object[]}
 */
export const convertToBarChart = (score = [], options) => {
  const { config } = options;
  const valueKey = get(config, 'options.valueKey');
  if (!valueKey) return score;
  return map(score, ({ label = '', ...item }) => ({
    ...item,
    label,
    value: get(config, 'options.groupValue') ? [
      { value: get(item, valueKey, 0), colorIndex: item.colorIndex }
    ] : get(item, valueKey, 0)
  }));
};

/**
 * Magic function that converts demographics grouped by school into
 * schools grouped by demography and joins nested demographics
 * like ethnicity and gender into one object.
 * @param {Object} score - Score data object
 * @param {Object[]} nestedDemographics - Array of demography slugs to nest
 * @return {Object}
 */
export const groupSchoolLevelsByDemography = (
  score,
  nestedDemographics = ['dis', 'eth', 'gen', 'gt', 'kscreen']
) => Object.entries(
  merge(
    ...Object.entries(score.value).map(([schoolLevel, schoolDemographics]) => Object.entries(schoolDemographics).reduce(
      (accumulator, [demographyName, demographyData]) => ({
        ...accumulator,
        [demographyName]: {
          [schoolLevel]: demographyData
        }
      }), {}
    ))
  )
).reduce((accumulator, [name, data]) => {
  const test = new RegExp(`^(${nestedDemographics.join('|')})_.*`, 'g').exec(name);

  if (test) {
    return {
      ...accumulator,
      [test[1]]: {
        ...accumulator[test[1]],
        [name]: data
      }
    };
  }

  return {
    ...accumulator,
    [name]: {
      [name]: data
    }
  };
}, {});

const groupProficiencyByTemplate = (score, template = '0.1.2') => unflatten(
  Object.entries(flatten(score.value)).reduce((accumulator, [path, value]) => {
    const pathArray = path.split('.');
    // eslint-disable-next-line radix
    const newPath = template.split('.').map(segment => pathArray[parseInt(segment, 10)]).join('.');
    return {
      ...accumulator,
      [newPath]: {
        value
      }
    };
  }, {})
);

/**
 * Calculates the height of a bar chart based on its values
 * @param {Object[]} values - Values object for CUI BarChart
 * @param {Number} offsetTop - Top offest from the highest value
 * @return {Number}
 */
export const getRoundChartHighFromValues = (values = [], offsetTop = 10) => Math.round((Math.max(
  ...flattenDeep(values.map(({ value }) => value.map(({ value: val }) => val)))
) + offsetTop) / 10) * 10;

const orderByArray = columnsOrder => (a, b) => columnsOrder.indexOf(a[0]) - columnsOrder.indexOf(b[0]);

/**
 * Get object suitable for rendering as a BarChart prop
 * @param {Object} labelsMap - Map with xAsis labels
 * @param {Object[]} columnsOrder - Array with requested order of the keys
 * @param {String} valueTransformer - Function executed on each value
 * @param {Object[]} omitBars - Array of bar labels to omit
 * @param {Object[]} includeBars - Array of bar labels to include
 * @return {Object}
 */
export const getChartDataObject = (
  labelsMap = {},
  valueTransformer = value => value,
  omitBars = [],
  includeBars = []
) => ([taxonomy, data]) => {
  const normalizedBars = Array.isArray(includeBars) ? includeBars : Object.keys(includeBars);
  const filteredBars = normalizedBars.length ? normalizedBars : Object.keys(data);

  return {
    label: (labelsMap[taxonomy]) || taxonomy,
    value: Object.entries(data)
      .filter(([itemLabel]) => filteredBars.includes(itemLabel))
      .filter(([itemLabel]) => !omitBars.includes(itemLabel))
      .sort(orderByArray(normalizedBars))
      .map(([itemLabel, itemValue], colorIndex) => ({
        colorIndex,
        label: (labelsMap[itemLabel]) || itemLabel,
        slug: itemLabel,
        value: valueTransformer(Object.values(itemValue)[0], data)
      }))
  };
};

/**
 * Get scores object transformed with the help of passed data massaging function
 * @param {Object} score - Scoring object from pros
 * @param {String} groupingFunctionName - Name of the massaging function
 * @return {Object}
 */
export const getGroupedScores = (score, groupingFunctionName, groupingTemplate) => {
  const scoreGroupingFunctions = {
    groupProficiencyByTemplate,
    groupSchoolLevelsByDemography,
    default: defaultScore => defaultScore.value
  };
  const groupingFunctionExists = Object.keys(scoreGroupingFunctions).includes(groupingFunctionName);
  const functionName = groupingFunctionExists ? groupingFunctionName : 'default';
  const groupingFunction = fnName => scoreGroupingFunctions[fnName];

  return groupingFunction(functionName)(score, groupingTemplate);
};
/* eslint-enable no-underscore-dangle */

export const isStringOrObjectWithTruthyWhen = (value, whenProperties = {}) => {
  if (typeof value !== 'object') return true;
  try {
    if (value.when && when(value.when, whenProperties)) return true;
  } catch (error) {
    /* eslint-disable no-console */
    console.error(error);
    /* eslint-enable no-console */
  }
  return false;
};
