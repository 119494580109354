import React from 'react';
import PropTypes from 'prop-types';

import { Rating } from 'cui';

import styles from './StarRating.module.scss';

const StarRating = ({ emptyState, score, message, total }) => (
  <div className={styles.starRating}>
    { score &&
      <div className={styles.vizGroup}>
        <Rating total={total} filled={score} className={styles.stars} color="yellow-star" />
        <div className={styles.text}>{score} of {total} stars</div>
      </div>
    }
    { !score &&
      <div className={styles.noRating}>{emptyState}</div>
    }
    {
      message && (
        <div className={styles.message}>
          {message}
        </div>
      )
    }
  </div>
);

StarRating.propTypes = {
  emptyState: PropTypes.string,
  score: PropTypes.number,
  message: PropTypes.string,
  total: PropTypes.number
};

StarRating.defaultProps = {
  total: 5,
  emptyState: 'No Star Rating'
};

export default StarRating;
