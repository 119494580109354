import DataSets2021 from '../../pages/DataSets/DataSetsIndex2021.json';
import DataSets2022 from '../../pages/DataSets/DataSetsIndex2022.json';
import DataSets2023 from '../../pages/DataSets/DataSetsIndex2023.json';

const recurseList = (obj) => {
  if (obj.type === 'GROUP') {
    return obj.items.reduce((acc, cur) => acc.concat(recurseList(cur)), []);
  }
  if (obj.type === 'ITEM') {
    return [obj];
  }
  if (obj.type === 'LINK') {
    return [obj];
  }
  return [];
};

const getItems = (obj) => obj.reduce((acc, cur) => acc.concat(recurseList(cur)), []);

const getDownloadItems = (schoolYear) => {
  const dataSetsByYear = {
    2021: DataSets2021,
    2022: DataSets2022,
    2023: DataSets2023

  };
  const dataSets = dataSetsByYear[schoolYear];
  if (!dataSets) return undefined;

  const items = getItems(dataSets.dataSets);

  return items;
};

export default getDownloadItems;
