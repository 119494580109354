import get from 'lodash/get';

// divide an array into groups
// example 1:
// const chartConfig = {
//   options: {
//     collectBy: { numPerGroup: 3 }
//   }
// }
// const currentOrgScores = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];
// three item per group
// const result = [[{ value: 1 }, { value: 2 }, { value: 3 }], [{ value: 4 }, { value: 5 }]];
//
// example
// const chartConfig = {
//   options: {
//     collectBy: { numPerGroup: 3, key: 'value' }
//   }
// }
// const currentOrgScores = [{ value: 1 }, { value: 2 }, { value: 3 }, { value: 4 }, { value: 5 }];
// three item per group and put under key
// const result = [
//   { value: [{ value: 1 }, { value: 2 }, { value: 3 }] },
//   { value: [{ value: 4 }, { value: 5 }] }
// ];

export default function adapter(currentOrgScores, { chartConfig }) {
  const { numPerGroup, key, groupData = {} } = get(chartConfig, 'options.collectBy', {});

  if (Array.isArray(currentOrgScores) && numPerGroup) {
    let groups = [];
    const totalGroups = Math.ceil(currentOrgScores.length / numPerGroup);

    for (let i = 0; i < totalGroups; i += 1) {
      groups.push(currentOrgScores.slice(i * numPerGroup, (i + 1) * numPerGroup));
    }

    if (key) {
      // Put the group under key
      // Merge groupData if specified
      groups = groups.map(group => ({ [key]: group, ...groupData }));
    }

    return groups;
  }

  return currentOrgScores;
}
