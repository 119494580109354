import get from 'lodash/get';
import pick from 'lodash/pick';
import isUndefined from 'lodash/isUndefined';
import sumBy from 'lodash/sumBy';
import { map } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import template from '../../helpers/template';
import when from '../../helpers/when';

/* eslint-disable complexity */
function roundTo(value, precision) {
  if (Number.isFinite(value)) {
    return value === Math.round(value) ? value : value.toFixed(precision);
  }

  return value;
}

const adapter = (options) => {
  const { currentOrgScore, config, legendPlacement } = options;
  const {
    singleDonut,
    scoresPoint,
    keys,
    theme,
    iconName,
    percentKey,
    valueKey,
    totalKey,
    hideCenterText,
    centerTemplate,
    labelTemplate,
    labelTemplate2,
    descriptionTemplate,
    suppressedWhen
  } = config.options;
  const LEGEND_THEME_DEFAULT = 'kde-duo';
  const LEGEND_PLACEMENT_DEFAULT = 'right';
  const WIDTH_DEFAULT = 200;
  const RADIUS_RATIO_DEFAULT = 0.55;

  const scoreValue = scoresPoint ? (get(currentOrgScore, scoresPoint)) : (get(currentOrgScore, 'value'));
  if (isUndefined(scoreValue)) return false;
  const totalData = keys ? pick(scoreValue, keys) : scoreValue;
  if (!totalData) {
    return false;
  }
  let isSuppressed = false;
  if (suppressedWhen) {
    isSuppressed = when(suppressedWhen, scoreValue);
  }

  let total;
  let value;
  let percent;
  let percentLabel;
  let legendData = [];
  let chartData = [];

  if (singleDonut) {
    total = totalKey ? totalData[totalKey] : Object.keys(totalData).reduce((sum, key) => {
      const pointData = totalData[key];
      const pointValue = pointData[valueKey];
      return sum + pointValue;
    }, 0);
    const itemData = keys ? get(totalData, keys[0]) : totalData;
    if (valueKey) {
      value = (itemData[valueKey] === '') ? 0 : itemData[valueKey];
    } else {
      value = null;
    }
    percent = percentKey ? parseFloat(itemData[percentKey]).toFixed(1) : ((value / total) * 100).toFixed(1) || 0;

    if (centerTemplate) {
      total = template(centerTemplate, { percent });
    }

    chartData = { percent };
    legendData = [{
      label: template(labelTemplate, { percent }),
      description: descriptionTemplate ? template(descriptionTemplate, { value, total }) : ''
    }];

    if (labelTemplate2) {
      legendData.push({
        label: template(labelTemplate2, { percent: roundTo(percent, 1) }),
        description: descriptionTemplate ? template(descriptionTemplate, { value: Math.round(total - value) }) : ''
      });
      const percentRest = 100 - percent;
      chartData = [
        { percent: roundTo(percent, 1) },
        { percent: roundTo(percentRest, 1) }
      ];
    }
  } else {
    const data = totalData[keys[0]];
    if (valueKey) {
      if (isEmpty(data)) return false;

      if (totalKey.indexOf('mock@') > -1) {
        total = 1111;
      } else {
        total = data[totalKey];
      }

      valueKey.forEach((key, i) => {
        if (key.indexOf('mock@') > -1) {
          value = 111;
        } else if (data[key]) {
          value = Number.isFinite(parseFloat(data[key])) ? Math.round(parseFloat(data[key])) : data[key];
        } else {
          value = 0;
        }
        if (key === 'the_rest') {
          const sumValues = valueKey.reduce((sum, item) => {
            const pointValue = parseFloat(data[item]);
            if (data[item] === '*') return false;
            return pointValue ? sum + pointValue : sum;
          }, 0);
          value = sumValues ? Math.round(total - sumValues) : '*';
        }
        const percentRaw = value === '*' ? 0 : (value / total) * 100;
        percent = percentRaw === Math.round(percentRaw) ? percentRaw : percentRaw.toFixed(1) || 0;
        percentLabel = value === '*' ? '*' : percent;
        chartData.push({ percent });
        legendData.push({
          label: template(labelTemplate[i], { percent: percentLabel }),
          description: descriptionTemplate ? template(descriptionTemplate, { value }) : ''
        });
      });
    }

    if (percentKey) {
      percentKey.forEach((key, i) => {
        percent = data[key] ? parseFloat(data[key]) : '';
        percent = percent || 0;
        chartData.push({ percent });
        legendData.push({
          label: template(labelTemplate[i], { percent }),
          description: descriptionTemplate ? template(descriptionTemplate, { percent }) : ''
        });
      });
    }

    const percentageSum = sumBy(map(chartData, val => (val.percent ? +val.percent : val.percent)));
    const filledChartData = parseFloat(percentageSum).toFixed(2) < 100 ? [
      ...chartData, {
        percent: 100 - percentageSum,
        colorIndex: theme === 'kde-multi' ? 7 : 1
      }
    ] : chartData;
    chartData = filledChartData;
  }

  return {
    legendPlacement: legendPlacement || LEGEND_PLACEMENT_DEFAULT,
    chartProps: {
      data: chartData,
      total,
      iconName,
      theme: theme || LEGEND_THEME_DEFAULT,
      width: config.width || WIDTH_DEFAULT,
      radiusRatio: config.radiusRatio || RADIUS_RATIO_DEFAULT
    },
    legendData,
    hideCenterText,
    isSuppressed
  };
};
/* eslint-enable complexity */
export default adapter;
