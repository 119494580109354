import React from 'react';
import PropTypes from 'prop-types';
import sortBy from 'lodash/sortBy';
import map from 'lodash/map';
import get from 'lodash/get';

import Popover from 'cui/lib/components/Popover';

import dynamicFormatter from '../../../helpers/dynamicFormatter';

const DefaultPopover = (props) => {
  const { item, options } = props;
  const { popoverSections = [] } = options;

  const sectionRenderer = () => (
    map(sortBy(popoverSections, ['index']), (sectionOptions) => {
      const { title, formatterType, percentKey } = sectionOptions;
      const scoreValues = (get(item, 'score.value', {}));
      const percentValue = get(scoreValues, percentKey, 0);
      const percentScore = { percent: percentValue * 20, colorIndex: percentValue - 1 };
      return (
        <div>
          <p className="cui-label cui-text_xSmall">{title}</p>
          {dynamicFormatter(formatterType, { ...scoreValues, ...sectionOptions, percentScore  })}
        </div>
      );
    })
  );

  return (
    <Popover.Body key="body">
      {sectionRenderer()}
    </Popover.Body>
  );
};

DefaultPopover.propTypes = {
  item: PropTypes.object,
  options: PropTypes.object
};

export default DefaultPopover;
