import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FORMATTERS } from '../../helpers/formatter';

import Page1 from '../../ui/SrcSummary/Page1';
import Page2 from '../../ui/SrcSummary/Page2';

function fedClassData(fedClass) {
  const classifications = get(fedClass, 'classifications[0]');

  if (classifications) {
    return {
      classification: classifications.name,
      classificationDescription: classifications.description,
      classificationData: classifications.reasons
    };
  }

  return {};
}

function schoolGrades(org) {
  if (!org.low_grade && !org.high_grade) {
    return 'n/a';
  } else if (!org.low_grade && org.high_grade) {
    return org.high_grade;
  } else if (org.low_grade && !org.high_grade) {
    return org.low_grade;
  }

  return `${org.low_grade} - ${org.high_grade}`;
}

function orgAddress(org) {
  return `${org.address} • ${org.city}, ${org.state} ${org.zipcode}`;
}

export default function SchoolReport(props) {
  const {
    currentOrganization,
    isScoresLoading,
    year,
    stars,
    gapReduction,
    sections,
    gaps,
    fedClass,
    schoolType,
    studentTeacherRatio,
    studentDemographics,
    attendanceRate,
    disadvantaged,
    noviceScores,
    totalSpending,
    introCopy,
    isWebView
  } = props;

  return (
    <div id="pdf-content">
      <section className="page">
        <Page1
          isLoading={isScoresLoading}
          schoolName={currentOrganization.name}
          address={orgAddress(currentOrganization)}
          phone={currentOrganization.phone}
          year={year}
          schoolType={schoolType}
          stars={stars}
          gapReduction={gapReduction}
          gapData={gaps}
          dataPoints={sections}
          introCopy={introCopy}
          showFooter={!isWebView}
          {...fedClassData(fedClass)}
        />
      </section>

      <section className="page">
        <Page2
          isLoading={isScoresLoading}
          showHeader={!isWebView}
          year={year}
          schoolName={currentOrganization.name}
          address={orgAddress(currentOrganization)}
          phone={currentOrganization.phone}
          studentCount={FORMATTERS.comma_delimited(currentOrganization.student_count)}
          grades={schoolGrades(currentOrganization)}
          studentTeacherRatio={studentTeacherRatio}
          attendanceRate={attendanceRate}
          disadvantaged={disadvantaged}
          noviceScores={noviceScores}
          totalSpending={totalSpending}
          studentDemographics={studentDemographics}
        />
      </section>
    </div>
  );
}

SchoolReport.propTypes = {
  isScoresLoading: PropTypes.bool,
  isWebView: PropTypes.bool,
  currentOrganization: PropTypes.object,
  year: PropTypes.number,
  stars: PropTypes.number,
  gapReduction: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
  gaps: PropTypes.arrayOf(PropTypes.object),
  fedClass: PropTypes.object,
  studentTeacherRatio: PropTypes.string,
  studentDemographics: PropTypes.object,
  attendanceRate: PropTypes.object,
  disadvantaged: PropTypes.object,
  noviceScores: PropTypes.arrayOf(PropTypes.object),
  totalSpending: PropTypes.object,
  schoolType: PropTypes.oneOf(['elementary_school', 'middle_school', 'high_school']),
  introCopy: PropTypes.string
};
