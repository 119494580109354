import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { Button, Field, InfoPopover } from 'cui';

import FederalClassificationModal from '../FederalClassificationModal';
import starRatingMessage from '../../helpers/schoolStatHelpers';
import Gauge from '../../ui/Gauge';

const lebelData = ['Red', 'Orange', 'Yellow', 'Green', 'Blue'];

function loadChart(scoreAvail, popoverContent, federalClassification, state, showModal, hideModal) {
  if (scoreAvail) {
    if (federalClassification.classifications.length > 0) {
      return (<div align="center">
        <small>
          FEDERAL CLASSIFICATION&nbsp;
          <InfoPopover content={popoverContent} />
        </small>
        <br />
        <small>
          {federalClassification.text}
        </small>
        <div>
          <Button
            className="tinyCaps cui-text_left cui-margin-top-xSmall"
            onClick={showModal}
          >
            Learn more
          </Button>
        </div>
        <FederalClassificationModal
          isOpen={state.isOpen}
          onHide={hideModal}
          federalClassification={federalClassification}
        />
      </div>);
    } else {
      return (<div align="center">
        <small>FEDERAL CLASSIFICATION&nbsp;
          <InfoPopover content={popoverContent} />
          <br />
        Not Applicable</small>
      </div>);
    }
  }
  return '';
}

class SchoolLevelExplanationGauge extends React.PureComponent {
  state = { isOpen: false };

  showModal = () => this.setState({ isOpen: true });

  hideModal = () => this.setState({ isOpen: false });

  render() {
    const { stat, popoverContent, isLoading } = this.props;
    const federalClassification = stat.fedClass;
    const score = get(stat, 'rating.star');
    const scoreAvail = !!score;

    return (
      <Field className="multiInputField maxWidth300">
        <div className="flexRow flexRow--nowrap">
          <Gauge
            title={get(stat, 'heading')}
            labels={lebelData}
            score={score}
            message={starRatingMessage(get(stat, 'rating.gap_reduction'))}
            isLoading={isLoading}
          />
        </div>
        { loadChart(scoreAvail, popoverContent, federalClassification, this.state, this.showModal, this.hideModal)}
      </Field>
    );
  }
}

SchoolLevelExplanationGauge.propTypes = {
  stat: PropTypes.shape({
    rating: PropTypes.shape({
      star: PropTypes.number,
      gap_reduction: PropTypes.string
    })
  }),
  popoverContent: PropTypes.string,
  isLoading: PropTypes.bool
};

export default SchoolLevelExplanationGauge;
