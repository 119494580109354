import camelCase from 'lodash/camelCase';

/* eslint-disable no-use-before-define */
const camelizeAnything = (data) => {
  if (data !== null && typeof data === 'object') {
    return Array.isArray(data) ? camelizeArray(data) : camelizeObject(data);
  }
  return data;
};

const camelizeArray = array => array.map(camelizeAnything);

const camelizeObject = object => (
  Object.keys(object).reduce((memo, key) => {
    const newKey = camelCase(key);
    memo[newKey] = camelizeAnything(object[key]);
    return memo;
  }, {})
);
/* eslint-enable no-use-before-define */

// eslint-disable-next-line import/prefer-default-export
export const camelizeJson = (data) => {
  if (data === null || typeof data !== 'object') {
    throw new Error(`Can't camelize non-json compatible type: ${typeof data}`);
  }
  return camelizeAnything(data);
};
