import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const WithFeature = ({ children, name, featureFlags, featureFlagsOverwrite }) => {
  if (!featureFlags.length) return null;

  const isFeatureActive = featureFlags.includes(name) || featureFlagsOverwrite[name];

  if (typeof children === 'function') {
    return children({ isActive: !!isFeatureActive });
  }

  if (!isFeatureActive) return null;

  return children;
};

WithFeature.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  name: PropTypes.string.isRequired,
  featureFlags: PropTypes.arrayOf(PropTypes.string),
  featureFlagsOverwrite: PropTypes.object
};

WithFeature.defaultProps = {
  featureFlags: [],
  featureFlagsOverwrite: {}
};

const mapStateToProps = state => ({
  featureFlags: Object.entries(get(state, 'module.pageContext.featureFlags', {}))
    .reduce((accumulator, [name, isActive]) => {
      if (isActive) {
        return [
          ...accumulator,
          name
        ];
      }
      return accumulator;
    }, [])
});

export default connect(mapStateToProps)(WithFeature);
