import get from 'lodash/get';
import reduce from 'lodash/reduce';
import startCase from 'lodash/startCase';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';

import conditional from '../../../conditional';
import template from '../../../template';

import { currentOrgYearScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const { orginalScores, scores, organization, node, selected, formatterTypes, formatter, config = {} } = options;
  const { selfDescribedDataShape = {}, linkableShape } = config;
  const { scoring_options: scoringOptions } = node;
  const score = currentOrgYearScores((orginalScores || scores), organization, node);

  const digToValue = (scoreValue, filter) => {
    if (filter) {
      return get(scoreValue, `${filter}.value`, get(score, `${filter}`));
    }
    return get(scoreValue, 'value.value',  get(scoreValue, 'value'));
  };

  const data = score && sortBy(reduce(score.value, (acc, val, key) => {
    const { label, index } = selfDescribedDataShape[key] || { label: startCase(key), index: 100 };
    let formatterType = get(scoringOptions, 'type', 'identity');
    if (!(includes(formatterTypes, formatterType))) {
      formatterType = 'identity';
    }

    const value = digToValue(val, selected);

    const templates = get(scoringOptions, 'value_templates', []);
    const valueTemplate = conditional(templates, { template: '{{value}}' }, { ...options, value: (value || 0) }).template;

    if (value !== undefined) {
      const formattedValue = formatter[formatterType](value);
      const templatedValue = template(valueTemplate, { ...options, value: formattedValue });
      const returnObj = { name: label, value: templatedValue, index };
      if (linkableShape) {
        returnObj.href = template(linkableShape.href, options);
        returnObj.linkableText = template(linkableShape.linkableText, options);
      }
      acc.push(returnObj);
    }
    return acc;
  }, []), ['index']);

  return (score && data && data.length) ? data : [];
};

export default adapter;
