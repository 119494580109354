import React from 'react';
import PropTypes from 'prop-types';
import {
  arc as d3Arc,
  pie as d3Pie,
} from 'd3-shape';
import cx from 'classnames';

import Spinner from 'cui/lib/components/LoadingSpinner';

import styles from './Gauge.module.scss';

const symbol = "M29.477 29.405h-26.954c-1.558 0-2.528-1.744-1.713-3.116l13.477-22.713c0.784-1.311 2.642-1.311 3.426 0l13.477 22.713c0.805 1.372-0.155 3.116-1.713 3.116z";

const Gauge = ({
  score,
  title,
  theme,
  labels,
  children,
  isLoading
}) => {
  const box = 300;
  const margin = 20;
  const radius = 0.5 * box;
  labels = labels != null ? labels : ['Very Low', 'Low', 'Medium', 'High', 'Very High'];
  const high = labels.length;
  // const symbol = d3Symbol().type(symbolTriangle).size(0.3 * box);
  // builds an array the length of the number of labels.
  const labelArray = Array.from({ length: labels.length }, (v, i) => 1);
  const arc = d3Arc()
    .innerRadius(radius * 0.94)
    .outerRadius(radius)
    .padAngle(.02)
    .padRadius(400)
    .cornerRadius(4);

  const pie = d3Pie()
    .sort(null)
    .startAngle(-0.5 * Math.PI)
    .endAngle(0.5 * Math.PI);

  const isOdd = (x) => { return x & 1; };

  const getLabel = () => {
    if (labels) {
      return labels[score - 1];
    }

    return null;
  }


  const caretRotation = () => {
    const constant = 180;
    const degrees = 180 / labelArray.length;
    const center = labelArray[Math.floor(labelArray.length / 2)] + 1;

    return labels.map((label, i) => {
      if (isOdd(labelArray.length)) {
        return constant + (i - center) * degrees;
      }
      return constant + (i - center) * degrees + degrees / 2;
    });
  }

  const rotation = caretRotation();
  const renderArc = (arc, pie) => {

    return labels.map((label, i) => {
      const isActive = i === score - 1;
      const arcData = pie(labelArray);
      const arcPath = arc(arcData[i]);
      return (
        <>
          {isActive && (<path key={i} d={symbol} className={styles.symbol} transform={`scale(0.4,0.4) rotate(${rotation[i]}) translate(-17, 380)`} />)}
          <path data-test="gauge-indicator-chart-segment" key={i} d={arcPath} className={`theme-${theme}-fill-${i} ${isActive ? 'isActive' : ''}`} transform={`translate(0, 0)`} />
        </>
      );
    });
  }

  return (
    <>
      {isLoading && <Spinner request={new Promise(() => { })} />}
      {(!isLoading && !!score) && (
        <div className={styles.base}>
          <div className={styles.title}>{title}</div>
          <span
            className={styles.a11y}
            tabIndex="0"
          >{`${title} ${getLabel()}, Level ${score} out of ${high}`}</span>
          <div>
            <svg
              width="100%"
              height="100%"
              viewBox={`0 0 ${box + margin * 2} ${box / 2 + margin}`}
              preserveAspectRatio="xMinYMin"
              className={styles.svg}
              data-test="gauge-indicator-chart"
            >
              <g transform={`translate(${margin}, ${margin})`}>
                <g transform={`translate(${radius}, ${radius})`}>
                  {renderArc(arc, pie)}
                  <path
                    d={symbol}
                    className={styles.symbol}
                    transform={`scale(0.4,0.4) rotate(${
                      rotation[score - 1]
                    }) translate(-17, 380)`}
                  />
                </g>
              </g>
            </svg>
          </div>
          <div
            className={cx('gauge-label', styles.label)}
            data-test="gauge-indicator-label"
          >
            {getLabel()}
          </div>
          <>{children}</>
        </div>
      )}
      {(!isLoading && !score) && <div className={styles.noRating}>No Score available</div>}
    </>
  );
};

Gauge.propTypes = {
  score: PropTypes.number.isRequired,
  title: PropTypes.string,
  theme: PropTypes.string,
  labels: PropTypes.array,
  children: PropTypes.node,
  isLoading: PropTypes.bool
};

Gauge.defaultProps = {
  theme: 'kde-accountability2',
  isLoading: false
};

export default Gauge;
