import get from 'lodash/get';
import range from 'lodash/range';
import { findNodeByNodePath } from '../helpers/frameworkHelpers';
import { STUDENT_SPENDING } from '../helpers/reportHelpers';
import loadScoresForOrganizationQuery from '../helpers/queries/loadScoresForOrganizationQuery';
import { FIRST_SCHOOL_YEAR } from '../constants';

const yearsUpto = (currentYear, firstYear) => firstYear < currentYear && range(firstYear, currentYear, 1);
const dataYears = schoolYear => yearsUpto(schoolYear, FIRST_SCHOOL_YEAR);

const nodesToFetch = framework =>
  get(framework, 'metadata.schoolReportDataPoints.node_paths', [])
    .filter(n => n.includePreviousYearData)
    .map(n => findNodeByNodePath(framework, n.node_path)).concat([STUDENT_SPENDING]);

const previousYearScoreLoad = {
  type: 'PORTAL_DATA_LOAD',
  customReducer(scores) {
    return scores.reduce((memo, score) => {
      Object.keys(score).forEach((year) => {
        if (!memo[year]) {
          memo[year] = score[year];
        } else {
          Object.keys(score[year]).forEach((dp) => {
            if (!memo[year][dp]) {
              memo[year][dp] = score[year][dp];
            } else {
              memo[year][dp] = { ...memo[year][dp], ...score[year][dp] };
            }
          });
        }
      });

      return memo;
    }, {});
  },
  query: (state, action) =>
    loadScoresForOrganizationQuery(
      state, {
        ...action,
        selectedSchoolYear: dataYears(get(state, 'module.schoolYear')),
        currentNodes: action.dataPoints || nodesToFetch(get(state, 'framework.kde'))
      }),
  propMap: {
    currentOrganizationId: 'module.currentOrganization.id',
    featureFlags: 'module.pageContext.featureFlags'
  },
  stateKey: 'previousYearScores'
};

export const previousYearScoreLoadAction = (props = {}) => ({
  ...previousYearScoreLoad,
  ...props
});

export default previousYearScoreLoad;
