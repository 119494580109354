import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import pick from 'lodash/pick';

// Recursively collect fields
function collect(score, fields) {
  if (isEmpty(score) || isEmpty(fields)) {
    return score;
  }

  return reduce(pick(score, fields[0]), (memo, value, key) => {
    memo[key] = collect(value, fields.slice(1));
    return memo;
  }, {});
}

// @return an object containing only fields given in the pickFields option
// const score = {
//   elementary_school: {
//     math: { count: 1, label: 'math' },
//     music: { count: 2, label: 'music' }
//   },
//   middle_school: {
//     math: { count: 3, label: 'math' },
//     music: { count: 4, label: 'music' }
//   },
//   high_school: {
//     math: { count: 5, label: 'math' },
//     music: { count: 6, label: 'music' }
//   }
// }
//
// const chartConfig = {
//   options: {
//     pickFields: [
//       ['elementary_school', 'middle_school'], ['math']
//     ]
//   }
// }
//
// return
// {
//   elementary_school: {
//     math: { count: 1, label: 'math' }
//   },
//   middle_school: {
//     { count: 3, label: 'math' }
//   }
// }

export default function adapter(currentOrgScore, { chartConfig }) {
  if (!isEmpty(currentOrgScore)) {
    const fields = get(chartConfig, 'options.pickFields');
    return collect(currentOrgScore, fields);
  }

  return currentOrgScore;
}
