import includes from 'lodash/includes';

import { PREVIEW_SITE_DOMAINS, PREVIEW_SITE_FEATURE_FLAG_OVERRIDES } from '../constants';

// Don't use these helpers directly; instead, if you need to detect whether
// this is the preview site and can't abstract whatever you're doing into a
// feature flag, pull `module.pageContext.isPreviewSite` out of the Redux
// store. That way it'll be easy to pivot to keying isPreviewSite off of user
// context information, should that be needed down the road.
const getHostname = () => window.location.hostname;
const getPathname = () => window.location.pathname;
const isSchoolReportCardPreviewSite = () => includes(PREVIEW_SITE_DOMAINS, getHostname());
const isApprovalPreviewSite = () => getPathname().indexOf('/modules/kde-preview') === 0;

export const isPreviewSite = () => isSchoolReportCardPreviewSite() || isApprovalPreviewSite();

// If this is the preview site, return feature flags whose states should be
// overridden. If not, return an empty object. Don't use this directly; it's
// loaded into the pageContext in sites/kde/src/index.jsx and merged with
// the redux store's module.pageContext.featureFlags as the feature flags are
// loaded, so the feature flag states as present in the redux store will
// already take any preview site overrides into account.
export const getPreviewSiteFeatureFlagOverrides = () => (isPreviewSite() ? PREVIEW_SITE_FEATURE_FLAG_OVERRIDES : {});
