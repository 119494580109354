import groupBy from 'lodash/groupBy';
import round from 'lodash/round';

import statusText from '../statusText';
import editableDataPoints from '../editableDataPoints';
import { getCollectedScores, collectionStatus, isState, isDistrict, isSchool } from './utils';

const collectionStatusForSchool = (organizations, framework, scores, domains) => {
  const collectedScores = getCollectedScores(scores);
  const scoresByOrg = groupBy(collectedScores, 'remote_organization_id');

  // determine whether there are scores for each of these node paths
  // and return the counts in question
  return (organizations ?? []).reduce((acc, org) => {
    acc[org.id] = {
      parentId: org?.parentDistrict?.id,
      ...domains.reduce((res, domain) => {
        const orgCollectors = editableDataPoints(framework, org, [domain])
          .reduce((innerAcc, dp) => {
            innerAcc[dp.node_path] = true;
            return innerAcc;
          }, {});

        const orgCollectorsCount = Object.keys(orgCollectors).length;
        // all we need here is to confirm the lookup of the score's node path
        // in the expected collection nodes for that org.  if the score's np isn't in
        // the list, it will return undefined which is eliminated by the reduce.
        const orgScoreCount = (scoresByOrg?.[org.id] ?? []).map(score => orgCollectors[score.framework_tree_node.node_path]).filter(Boolean).length;

        return {
          ...res,
          [domain.node_path]: {
            collectorsCount: orgCollectorsCount,
            collectedCount: orgScoreCount,
            collectedPct: orgCollectorsCount > 0 ? round(100 * (orgScoreCount / orgCollectorsCount), 1) : 0,
            status: collectionStatus(domain.node_path, org, statusText(orgScoreCount, orgCollectorsCount))
          }
        };
      }, {})
    };

    return acc;
  }, {});
};

// aggregate child school collected data when computing district collection status
function getDistrictCollectionStatus(schoolCollectionStatus, domains) {
  return Object.values(schoolCollectionStatus).reduce((memo, status) => {
    const parentDistrictId = status.parentId;

    if (!parentDistrictId) return memo;

    if (!memo[parentDistrictId]) {
      memo[parentDistrictId] = {};
    }

    domains.forEach((domain) => {
      if (!memo[parentDistrictId][domain.node_path]) {
        memo[parentDistrictId][domain.node_path] = {
          collectorsCount: 0,
          collectedCount: 0
        };
      }

      memo[parentDistrictId][domain.node_path].collectorsCount += status[domain.node_path].collectorsCount;
      memo[parentDistrictId][domain.node_path].collectedCount += status[domain.node_path].collectedCount;
    });

    return memo;
  }, {});
}

function collectionStatusByOrgDomains(currentOrganization, framework, scores, domains) {
  if (isState(currentOrganization)) {
    const childDistricts = currentOrganization.children.filter(org => isDistrict(org));
    const childSchools = currentOrganization.children.filter(org => isSchool(org));
    const schoolCollectionStatus = collectionStatusForSchool(childSchools, framework, scores, domains);
    const districtCollectionStatus = getDistrictCollectionStatus(schoolCollectionStatus, domains);

    return childDistricts.reduce((memo, org) => {
      const districtStatus = districtCollectionStatus[org.id];

      if (!districtStatus) return memo;

      memo[org.id] = {
        ...districtStatus,
        ...domains.reduce((res, domain) => ({
          ...res,
          [domain.node_path]: {
            ...districtStatus[domain.node_path],
            status: collectionStatus(
              domain.node_path,
              org,
              statusText(districtStatus[domain.node_path].collectedCount, districtStatus[domain.node_path].collectorsCount)
            )
          }
        }), {})
      };

      return memo;
    }, {});
  }

  if (isDistrict(currentOrganization)) {
    const childSchools = currentOrganization.children.filter(org => isSchool(org));
    return collectionStatusForSchool(childSchools, framework, scores, domains);
  }

  return {};
}

export default collectionStatusByOrgDomains;
