import React from 'react';
import PropTypes from 'prop-types';

const roundNumberFormatter = (props) => {
  const {
    value,
    lowValue,
    lowValueLabel,
    highValue,
    highValueLabel
  } = props;
  let suffix;
  let roundedValue;

  if (value > lowValue && value < highValue) {
    roundedValue = Math.round(value / 100) / 10;
    suffix = lowValueLabel;
  } else if (value >= highValue) {
    roundedValue = Math.round(value / 100000) / 10;
    suffix = highValueLabel;
  } else {
    roundedValue = value;
    suffix = null;
  }
  return <span>{roundedValue}{suffix}</span>;
};

roundNumberFormatter.propTypes = {
  value: PropTypes.number,
  lowValue: PropTypes.number,
  lowValueLabel: PropTypes.string,
  highValue: PropTypes.number,
  highValueLabel: PropTypes.string
};

roundNumberFormatter.defaultProps = {
  lowValue: 10000,
  lowValueLabel: 'K',
  highValue: 1000000,
  highValueLabel: 'M'
};

export default roundNumberFormatter;
