import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';

import { DATA_POINT_ERROR_TYPES, DEFAULT_SECTION_STATUS } from './constants';
import ModalForm from './ModalForm';
import DeleteConfirmation from './DeleteConfirmation';

const IDLE = 'idle';
const EDIT_FLAG = 'edit';
const DELETE_CONFIRMATION = 'delete';

class DataPointFlagModal extends React.Component {
  state = { status: IDLE };

  openFlaggingModal = () => this.setState({ status: EDIT_FLAG });

  hideModal = () => this.setState({ status: IDLE });

  requestDeleteConfirmation = () => this.setState({ status: DELETE_CONFIRMATION });

  saveFlag = async (values, { setSubmitting }) => {
    const { nodePath } = this.props;

    await this.props.saveFlag({ nodePath, ...values });

    setSubmitting(false);

    // setTimeout is needed to solve this warning:
    //
    // Warning: setState(...): Can only update a mounted or mounting component.
    // This usually means you called setState() on an unmounted component.
    // This is a no-op. Please check the code for the FormControlHoc(Text) component.
    setTimeout(() => this.hideModal());
  };

  deleteFlag = async () => {
    await this.props.deleteFlag({ nodePath: this.props.nodePath });

    this.hideModal();
  };

  render() {
    const { isFlagged, dataIssues } = this.props;
    const buttonTitle = isFlagged ? 'Review flag' : 'Flag';

    const initialValues = {
      dataIssues: dataIssues.length > 0 ? dataIssues : [DEFAULT_SECTION_STATUS]
    };

    return (
      <div>
        <Button type="button" kind="solid" theme="secondary" size="large" title={buttonTitle} onClick={this.openFlaggingModal}>
          <Icon name="ig-flag" className={cn({ 'theme-kde-scale-fg-1': isFlagged })} />
          &nbsp;
          {buttonTitle}
        </Button>

        <ModalForm
          isOpen={this.state.status === EDIT_FLAG}
          initialValues={initialValues}
          onHide={this.hideModal}
          onSubmit={this.saveFlag}
          onDelete={this.requestDeleteConfirmation}
        />

        <DeleteConfirmation
          isOpen={this.state.status === DELETE_CONFIRMATION}
          onCancel={this.openFlaggingModal}
          onConfirm={this.deleteFlag}
        />
      </div>
    );
  }
}

DataPointFlagModal.propTypes = {
  nodePath: PropTypes.string,
  isFlagged: PropTypes.bool.isRequired,
  dataIssues: PropTypes.arrayOf(
    PropTypes.shape({
      errorType: PropTypes.oneOf(DATA_POINT_ERROR_TYPES.map(el => el.value)),
      comment: PropTypes.string
    })
  ).isRequired,
  saveFlag: PropTypes.func.isRequired,
  deleteFlag: PropTypes.func.isRequired
};

export default DataPointFlagModal;
