import get from 'lodash/get';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import customRenders from './customRenders.jsx';
import InfoIcon from './KdeCondensedTableInfoIcon';
import valueFormatters from './valueFormatters';
import CondensedTable from '../CondensedTable';
import {
  getSchoolScoresForOrganization,
  getStateScoresForOrganization,
  getDistrictScoresForOrganization
} from '../../helpers/portalDataServiceHelpers';
import filterObjectKeysByMetadataEntity from '../../helpers/filterByEntities';
import when from '../../helpers/when';
import template from '../../helpers/template';
import applyAdapters from '../../helpers/adapterHelpers';
import { isA1School } from '../../helpers/orgCanAccessCollectorDataPoint';

/* eslint-disable complexity */
const DefaultCell = ({ emptyState, currentOrganization, accessor, frameworkTreeRows, row, ...rest }) => {
  const info = get(row, `info.${accessor}`);

  if (row[accessor] && typeof row[accessor] !== 'string') return <td className={rest.className}>-</td>;

  if (row.hide) return null;

  if (row.customRender) {
    try {
      return customRenders[row.customRender]({ accessor, row, info, ...rest });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  if (row[accessor] && frameworkTreeRows.find(({ id }) => row.id === id)) {
    const description = get(row, `description.${accessor}`);

    return (
      <td className={rest.className}>
        <strong>{row[accessor]}</strong>
        {info && <InfoIcon text={info} ariaLabel={`${row[accessor]} popover`} />}
        {description && <div className="u-color-kde-text-tint">{description}</div>}
      </td>
    );
  }

  let emptyStateText = '-';
  if (emptyState && when(emptyState.when, { isA1School, currentOrganization })) {
    emptyStateText = emptyState.message;
  }

  return (
    <td className={rest.className}>{emptyStateText}</td>
  );
};
/* eslint-enable complexity */

DefaultCell.propTypes = {
  accessor: PropTypes.string.isRequired,
  frameworkTreeRows: PropTypes.arrayOf(PropTypes.object),
  row: PropTypes.object,
  emptyState: PropTypes.object,
  currentOrganization: PropTypes.object
};

const KdeCondensedTable = ({ additionalChartAdapters, node, currentScore, config, organization, scores, allScores }) => {
  const defaultValueFormatters = get(config, 'options.defaultValueFormatters', []);
  const frameworkTreeColumns = get(config, 'options.columns', []);
  const frameworkTreeRows = get(config, 'options.rows', []);
  const financialPortalDataKeys = frameworkTreeRows.map(({ id, portalDataKey }) => portalDataKey || id);

  let chartScores = {
    school: getSchoolScoresForOrganization(scores, organization).value,
    state: pick(get(getStateScoresForOrganization(scores), 'value.score', {}), financialPortalDataKeys),
    district: pick(get(getDistrictScoresForOrganization(scores, organization), 'value.score', {}), financialPortalDataKeys)
  };

  // use adapter if defined in the framework node
  const adapterName = get(config, 'adapter');
  if (adapterName) {
    chartScores = applyAdapters(adapterName, currentScore, {
      currentOrganization: organization,
      chartConfig: config,
      additionalChartAdapters,
      node,
      config,
      allScores
    });
  }

  const data = frameworkTreeRows
    .map(row => filterObjectKeysByMetadataEntity(row, organization.entity_type))
    .filter(row => !!row)
    .map((row) => {
      const portalDataKey = row.portalDataKey || row.id;

      return {
        ...frameworkTreeColumns.reduce((accumulator, column) => {
          const value = get(chartScores, `${column.accessor}.${portalDataKey}`) ||
            get(chartScores, `${column.accessor}.value`) ||
            (get(chartScores, `${column.accessor}`) && template(row.value, get(chartScores, `${column.accessor}`))) ||
            get(chartScores, `${column.accessor}`);

          const formatters = get(row, `formatters.${column.accessor}`) || defaultValueFormatters || [];
          const formattedValue = formatters.reduce((valueAccumulator, currentFormatter) => {
            if (!value) return valueAccumulator;
            return valueFormatters[currentFormatter](valueAccumulator);
          }, value);

          return {
            ...accumulator,
            [column.accessor]: formattedValue
          };
        }, {}),
        ...row
      };
    });

  const filteredFrameworkTreeColumns = frameworkTreeColumns
    .map(column => filterObjectKeysByMetadataEntity(column, organization.entity_type))
    .filter(column => !!column);

  const columns = filteredFrameworkTreeColumns
    .map(column => ({
      ...column,
      cell: props => (
        <DefaultCell
          frameworkTreeRows={frameworkTreeRows}
          organizationType={organization.entity_type}
          {...props}
          currentOrganization={organization}
          emptyState={column.emptyState}
        />
      )
    }));

  return <CondensedTable columns={columns} data={data} description={get(config, 'description') || get(node, 'name')} />;
};

KdeCondensedTable.propTypes = {
  config: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  allScores: PropTypes.arrayOf(PropTypes.object),
  additionalChartAdapters: PropTypes.object,
  node: PropTypes.object,
  currentScore: PropTypes.object
};

export default KdeCondensedTable;
