import isEmpty from 'lodash/isEmpty';
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cx from 'classnames';
import Link from '../Link';

import connected from '../connected';
import template from '../../helpers/template';
import mapDispatchToProps from '../../helpers/mapDispatchToProps';
import when from '../../helpers/when';

import propMapper from './propMapper';

import styles from './NodeTabs.module.scss';

export class NodeTabs extends React.Component {
  render() {
    const {
      nodes,
      routeTemplate,
      activeTab,
      ...additionalProps
    } = this.props;

    if (isEmpty(nodes)) {
      return null;
    }

    return (
      <ul className={cx('cui-tabs cui-tabs_folders', styles.tabs)}>
        {
          nodes.map((node, i) => {
            // INFO: This doesn't look good, but I couldn't find any other way for it to work
            if (node.when && !when(node.when, this.props)) return <span key={node.slug} />;

            const itemClassName = cx('cui-tabs-item', {
              [styles.disabled]: node.disabled,
              'is-active': activeTab === i
            });

            let wrapperProps = {
              to: template(routeTemplate, { ...additionalProps, node }),
              'aria-current': activeTab === i ? 'location' : 'false'
            };
            let Wrapper = Link;

            if (node.disabled) {
              Wrapper = 'div';
              wrapperProps = {};
            }

            return (
              <li className={itemClassName} key={node.slug}>
                <Wrapper
                  className="cui-btn cui-tabs-link"
                  {...wrapperProps}
                >
                  {node.name}
                </Wrapper>
              </li>
            );
          })
        }
      </ul>
    );
  }
}

NodeTabs.propTypes = {
  userRole: PropTypes.string,
  development: PropTypes.bool,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  nodes: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string
  })).isRequired,
  routeTemplate: PropTypes.string,
  type: PropTypes.string,
  activeTab: PropTypes.number,
  nestedNodeType: PropTypes.string
};

NodeTabs.defaultProps = {
  userRole: '',
  development: String(process.env.DEPLOY_ENV).toLowerCase() === 'development', // This probably would have to be removed after FIN for public launches
  featureFlags: {},
  routeTemplate: '',
  nodes: [],
  activeTab: 0,
  nestedNodeType: 'domain'
};

export default withRouter(connected(NodeTabs, mapDispatchToProps, propMapper));
