import get from 'lodash/get';
import compileTemplate from '../../helpers/template';

const kdeProgressTextNumberAdapter = (scores, node, config, viewingDropdownFilter) => {
  const { metric_options: metricOptions = [{}] } = node;
  const score = get(scores, '[0].value', {});
  const studentGroupFilter = Array.isArray(viewingDropdownFilter[0]) ? viewingDropdownFilter[0][0] : viewingDropdownFilter[0];
  const levelFilter = viewingDropdownFilter[1];
  const studentGroupScores = get(score, `dimensions[${studentGroupFilter}]`, {});
  const levelScores = get(studentGroupScores, `dimensions[${levelFilter}].score`, {});

  return metricOptions.map((item) => {
    const itemScores = get(levelScores, item.key);
    return {
      item,
      input: compileTemplate(item.template, { value: itemScores }),
      scores: itemScores
    };
  });
};

export default kdeProgressTextNumberAdapter;
