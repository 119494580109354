import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { format, startOfYear, endOfYear } from 'date-fns';
import Field from 'cui/lib/components/Form/Field';
import Text from 'cui/lib/components/Form/Text';

export default class CollectionTextField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: get(this.props.currentScore, 'value')
    };
    if (this.props.submissionValueGenerators) {
      this.props.submissionValueGenerators[get(this.props, 'dp.node_path')] = this.generateSubmissionValue;
    }
    this.isValid = true;
  }

  componentWillReceiveProps(newProps) {
    const nextScore = get(newProps.currentScore, 'value');
    const currentScore = get(this.props.currentScore, 'value');

    if (nextScore !== currentScore) {
      this.setState({ value: nextScore });
    }
  }

  onChange = (value) => {
    this.setState({ value });
  }

  onError = (error) => {
    this.isValid = isEmpty(error);
  }

  generateSubmissionValue = () => {
    if (!this.isValid) { return null; }

    const currentValue = get(this.props.currentScore, 'value');
    if (isEqual(currentValue, this.state.value)) { return null; }

    const startAt = get(this.props.currentScore, 'start_at', format(startOfYear(new Date()), 'yyyy-MM-dd'));
    const endAt = get(this.props.currentScore, 'end_at', format(endOfYear(new Date()), 'yyyy-MM-dd'));

    return {
      startAt,
      endAt,
      value: this.state.value
    };
  }

  render() {
    const { type, dp } = this.props;
    const fieldPrompt = get(dp, 'field_prompt', get(dp, 'name'));
    if (type === 'textarea') {
      return (
        <Field label={fieldPrompt}>
          <Text
            type={type}
            id={dp.node_path}
            value={this.state.value}
            onChange={this.onChange}
            onError={this.onError}
            stroked
            maxLength={300}
          />
        </Field>
      );
    } else {
      return (
        <Field label={fieldPrompt}>
          <Text
            type={type}
            id={dp.node_path}
            value={this.state.value}
            onChange={this.onChange}
            onError={this.onError}
            stroked
          />
        </Field>
      );
    }
  }
}

CollectionTextField.propTypes = {
  dp: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['url', 'text', 'textarea']).isRequired,
  currentScore: PropTypes.object,
  submissionValueGenerators: PropTypes.object
};

CollectionTextField.defaultProps = {
  currentScore: {}
};
