/**
 * DEPRECATED
 * FIXME: remove this component in favor of `tableChart`
 */
import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import find from 'lodash/find';

import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';
import Popover from 'cui/lib/components/Popover';
import Table from 'cui/lib/components/Table';
import Tag from 'cui/lib/components/Tag';

const renderDetails = schools => (
  <Popover popoverPlacement="right" appendToBody={false}>
    <Popover.Toggle>
      <Button>Details</Button>
    </Popover.Toggle>
    <Popover.Content>
      <Popover.Title>School Details</Popover.Title>
      <Popover.Body>
        {
          schools.map(school => (
            <div key={school}>{ school }</div>
          ))
        }
      </Popover.Body>
    </Popover.Content>
  </Popover>
);

const renderRow = (item) => {
  const status = item.statusMap[item.status];

  return (
    <tr>
      <td className="cui-text_bold cui-text_cap">{item.subject}</td>
      <td>
        <Tag className="cui-text_upper" theme={status.theme}>
          <Icon name={status.icon} /> {item.status}
        </Tag>
      </td>
      <td>
        {item.orgType === 'District' && item.schools && item.schools.length > 0 && item.status === 'not sufficient' &&
          renderDetails(item.schools) }
      </td>
    </tr>
  );
};

export const TableChart = (props) => {
  console.warn('DEPRECATED: LcapTableChart is deprecated.');
  const orgType = props.organization.entityType || props.organization.entity_type;
  const labels = props.config.options.labels;
  const statusMap = {
    sufficient: {
      icon: props.config.options.sufficientIcon
    },
    'not sufficient': {
      icon: props.config.options.notSufficientIcon,
      theme: props.config.options.notSufficientTheme
    }
  };

  const score = find(props.scores, { remote_organization_id: props.organization.id });

  const rows = map(score.value, (value, key) => ({
    subject: labels[key],
    status: value.sufficient ? 'sufficient' : 'not sufficient',
    schools: value.schools,
    orgType,
    statusMap
  }));

  return (
    <Table data={rows} renderRow={renderRow} striped>
      <Table.ColumnHeader property="subject">subject</Table.ColumnHeader>
      <Table.ColumnHeader property="status">status</Table.ColumnHeader>
      <Table.ColumnHeader property="schools" />
    </Table>
  );
};

TableChart.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    entity_type: PropTypes.string,
    entityType: PropTypes.string
  }),
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  config: PropTypes.shape({
    options: PropTypes.shape({
      labels: PropTypes.object,
      sufficientIcon: PropTypes.string,
      notSufficientIcon: PropTypes.string,
      notSufficientTheme: PropTypes.string
    })
  })
};

TableChart.defaultProps = {
  organization: {
    id: null
  },
  scores: []
};

export default TableChart;
