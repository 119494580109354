// This component could be a vertical bar chart with single bar in each group
// or vertical group bar chart with multiple bars within each group
// Based on the chartOptions and data prop

import React from 'react';
import merge from 'lodash/merge';
import get from 'lodash/get';

import template from '../../helpers/template';
import KdeBarChart from '../KdeBarChart';
import styles from './index.module.scss';
import prototypeData from './prototypeData';

const defaultChartOptions = {
  xAxis: {
    showGrid: true,
    showAxis: true,
    showLabel: true,
    position: 'end',
    labelAlignment: 'start'
  },
  yAxis: {
    offset: 80,
    showLabel: true,
    showGrid: false,
    showAxis: false,
    labelCount: 6
  },
  valueLabel: true,
  high: 100,
  low: 0,
  barWidthRatio: 0.95,
  animate: false
};

function KdeVerticalBarChart(props) {
  const labelTemplate = get(props.config, 'options.chartOptions.yAxis.labelTemplate', '{{l}}');
  const chartOptions = merge({
    yAxis: {
      labelInterpolationFunc: l => (template(labelTemplate, { l }))
    }
  }, defaultChartOptions);
  const legendOnMobile = get(props.config, 'options.legendOnMobile');
  const sampleData = get(props.config, 'isPrototype') && prototypeData;

  return (
    <KdeBarChart
      {...props}
      horizontal={false}
      defaultChartOptions={chartOptions}
      sampleData={sampleData}
      className={legendOnMobile && styles.wrapper}
    />
  );
}

KdeVerticalBarChart.propTypes = KdeBarChart.commonProps;

export default KdeVerticalBarChart;
