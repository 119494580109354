import get from 'lodash/get';
import reduce from 'lodash/reduce';
import includes from 'lodash/includes';
import sortBy from 'lodash/sortBy';
import castArray from 'lodash/castArray';

import conditional from '../../../conditional';
import template from '../../../template';
import { currentOrgYearScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const {
    entities,
    formatterTypes,
    node,
    currentFilter,
    entityScores,
    formatter,
    scores,
    organization,
    config = {}
  } = options;
  const { linkableShape, sortByProperty, addParentOrgScore = false } = config;
  const { scoring_options: scoringOptions } = node;

  const digToValue = (score, scoreFilter) => {
    if (scoreFilter) {
      return get(score, `value.${scoreFilter}.value`, get(score, `value.${scoreFilter}`));
    }
    return get(score, 'value.value',  get(score, 'value'));
  };

  const templates = get(scoringOptions, 'value_templates', []);

  let formatterType = get(scoringOptions, 'type', 'identity');
  if (!(includes(formatterTypes, formatterType))) {
    formatterType = 'identity';
  }

  const data = entities && reduce(entities, (acc, entity) => {
    const score = currentOrgYearScores(entityScores, entity, node);
    const value = digToValue(score, currentFilter);
    const valueTemplate = conditional(templates, { template: '{{value}}' }, { ...options, value: (value || 0) }).template;
    if (value !== undefined) {
      const formattedValue = formatter[formatterType](value);
      const templatedValue = template(valueTemplate, { ...options, value: formattedValue });
      const returnObj = { name: entity.name, value: templatedValue };
      if (linkableShape) {
        returnObj.href = template(linkableShape.href, { ...options, organization: entity });
        returnObj.linkableText = template(linkableShape.linkableText, options);
      }
      acc.push(returnObj);
    }
    return acc;
  }, []);

  const sortedData = sortByProperty ? sortBy(data, castArray(sortByProperty)) : data;

  if (addParentOrgScore) {
    const parentScore = currentOrgYearScores(scores, organization, node);
    if (parentScore) {
      const value = digToValue(parentScore, currentFilter);
      const valueTemplate = conditional(templates, { template: '{{value}}' }, { ...options, value: (value || 0) }).template;
      if (value !== undefined) {
        const formattedValue = formatter[formatterType](value);
        const templatedValue = template(valueTemplate, { ...options, value: formattedValue });
        const returnObj = { name: organization.name, value: templatedValue };
        sortedData.unshift(returnObj);
      }
    }
  }

  return sortedData;
};

export default adapter;
