import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'cui';

import styles from './WhyImportant.module.scss';

const WhyImportant = ({ children, showHeader }) => (
  <div className={styles.WhyImportant}>
    { showHeader && <Heading level={4} className={styles.heading}>Why is this important?</Heading> }
    {children}
  </div>
);

WhyImportant.propTypes = {
  children: PropTypes.node,
  showHeader: PropTypes.bool
};

export default WhyImportant;
