import React from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';

import EmptyState from 'cui/lib/components/EmptyState';

import ChartOptionHelpers from '../../helpers/chartOptionHelpers';

import EntityComparisonDetailsPopover from '../EntityComparisonDetailsPopover';
import EntityComparisonDetailsPopoverWithoutPercentSign from '../EntityComparisonDetailsPopoverWithoutPercentSign';

import template from '../../helpers/template';
import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';
import numberFormatter from '../../helpers/numberFormatter';

const popoverMap = {
  entityComparisonDetailsPopover: EntityComparisonDetailsPopover,
  entityComparisonDetailsPopoverWithoutPercentSign: EntityComparisonDetailsPopoverWithoutPercentSign
};

export const PercentBarChart = (props) => {
  const { config, scores, organization, chartType } = props;
  const options = config.options || {};

  const score = currentOrgYearScores(scores, organization);
  if (isEmpty(score)) {
    return null;
  }

  const dataOptions = {
    ...props,
    currentOrgScore: score
  };

  const data = chartDataAdapter(config.adapter || chartType, dataOptions);
  const max = dataMaxHelper[config.adapter || chartType](dataOptions);
  const precision = get(options, 'yAxis.precision') || 0;

  let chartOptions = options && merge(options, {
    low: 0,
    barWidthRatio: 0.8,
    high: options.high || (Math.ceil(max / 10)) * 10,
    xAxis: {
      showGrid: true,
      showLabel: true,
      showAxis: true,
      labelInterpolationFunc: l => (
        template(options.xAxis.labelTemplate || '{{l}}', { l })
      )
    },
    yAxis: {
      offset: 50,
      labelCount: 6,
      showLabel: true,
      showGrid: true,
      showAxis: true,
      labelInterpolationFunc: l => (
        template(options.yAxis.labelTemplate || '{{l}}', { l })
      )
    },
    valueLabelFormatter: v => (`${numberFormatter(v, 'percent', precision)}%`),
    detailsPopoverRenderer: popoverMap[options.detailsPopoverRenderer]
  });

  chartOptions = ChartOptionHelpers.gridFilter(chartOptions);

  return (
    <div>
      {
        data &&
        <BarChartWithLegend
          theme={config.theme}
          chartProps={{
            data,
            chartOptions
          }}
        />
      }
      {
        !data && <EmptyState kind="text" title="There is no data available." />
      }
    </div>

  );
};

PercentBarChart.propTypes = {
  config: PropTypes.object,
  organization: PropTypes.object,
  chartType: PropTypes.string,
  scores: PropTypes.arrayOf(
    PropTypes.object
  )
};

PercentBarChart.defaultProps = {
  scores: [],
  organization: {},
  chartType: '',
  config: {
    options: {},
    theme: ''
  }
};

export default PercentBarChart;
