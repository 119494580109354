import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import map from 'lodash/map';
import compact from 'lodash/compact';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import orderBy from 'lodash/orderBy';
import snakeize from 'snakeize';

import SideScroll from 'cui/lib/components/SideScroll';
import Table from 'cui/lib/components/Table';
import Icon from 'cui/lib/components/Icon';
import ChartEmptyState from '../Chart/ChartEmptyState';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import applyAdapters from '../../helpers/adapterHelpers';
import { isStringOrObjectWithTruthyWhen } from '../../helpers/chartAdapterUtils';
import when from '../../helpers/when';
import { getValueFromScores } from '../../helpers/scoresHelpers';
import TextCell from './TextCell';
import LabelCell from './LabelCell';
import TagCell from './TagCell';
import PopoverCell from './PopoverCell';
import styles from './index.module.scss';
import { KENTUCKY_ORG_ID } from '../../constants';

import template from '../../helpers/template';

/**
 * Expects a score shape of:
 *   {
 *     [key]: Int | String | Object
 *   }
 */

function excludeEmptyColumns(data, columns, key) {
  // remove empty column that is for each row, the cell of the column is empty
  return columns.filter(column => !data.every((d) => {
    if (!column.scoreKey) return false;
    const value = d[column.scoreKey] ? d[column.scoreKey][key] : 0;
    return isEmpty(value);
  }));
}

// Returns an array of keys that represent truthy values in an object.
// If specific value is an object it must have all truthy keys.
function getTruthyKeys(obj) {
  return Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (
      (Array.isArray(value) && value.filter(Boolean).length > 0) ||
      (!Array.isArray(value) && typeof value === 'object' && Object.values(value).filter(Boolean).length > 0) ||
      (['number', 'string'].includes(typeof value) && !!value)
    ) {
      return [...accumulator, key];
    }
    return accumulator;
  }, []);
}

// Exclude columns that are either:
//  - missing from returned scores data
//  - are of type other than text
function getColumnsWithoutScores(data, columns) {
  const allowedKeys = [...new Set(data.reduce((accumulator, rowData) => {
    const truthyColumns = getTruthyKeys(rowData);
    return [...accumulator, ...truthyColumns];
  }, []))];

  return columns.filter(column => column.type !== 'text' || allowedKeys.includes(column.scoreKey));
}

function getIndexesOfAvailableColumns(allColumns, availableColumns) {
  const availableScoreKeys = availableColumns.map(column => column.scoreKey);

  return allColumns
    .map((column, index) => ({
      [index]: column.scoreKey
    }))
    .filter(column => availableScoreKeys.indexOf(Object.values(column)[0]) > -1)
    .map(column => Number(Object.keys(column)[0]));
}

function tableHeaders(chartConfig, columns = []) {
  let headers = get(chartConfig, 'options.headers');
  if (!headers) {
    headers = columns.map(column => column.header);
  }

  if (chartConfig.excludeMissingScoreColumn) {
    const availableColumnsIndexes = getIndexesOfAvailableColumns(
      get(chartConfig, 'options.columns'),
      columns
    );
    headers = headers.filter((header, headerIndex) => availableColumnsIndexes.includes(headerIndex));
  }

  return headers;
}

/* eslint-disable complexity */
export const TableChart = (props) => {
  const { config: chartConfig, scores, organization, node, additionalChartAdapters, viewingDropdownFilter } = props;
  const { options = {}, alwaysRender = false, useStateData = false } = chartConfig;
  const { hideNullRows = false, hideZeroRows = false, defaultScoreKey = '', defaultScore = {} } = options;
  const whenProperties = {
    viewingDropdownFilter
  };

  let rows = options.rows;
  let columns = options.columns || [];

  if (isEmpty(scores) && !alwaysRender) return null;

  // Not using `directScore`/currentOrgScore here
  const valueForOrg = getValueFromScores(scores, organization.id, node);

  let currentScore = defaultScoreKey ? valueForOrg[defaultScoreKey] : valueForOrg;

  if (useStateData) {
    const stateScore = getValueFromScores(scores, KENTUCKY_ORG_ID, node);
    currentScore = stateScore;
  }

  // Skip empty check if node has `alwaysRender`
  if (isEmpty(currentScore) && !alwaysRender) {
    /* eslint-disable no-console */
    console.error(`No Score Found for organization ${organization.id}`);
    /* eslint-enable no-console */
    return (<ChartEmptyState />);
  }

  if (chartConfig.adapter) {
    let scoreFromAdapter;

    if (Array.isArray(chartConfig.adapter)) {
      // Use new adapter api
      scoreFromAdapter = applyAdapters(chartConfig.adapter, currentScore, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    } else {
      scoreFromAdapter = chartDataAdapter(chartConfig.adapter, {
        currentOrganization: organization,
        currentOrgScore: currentScore,
        node,
        chartConfig,
        additionalChartAdapters,
        viewingDropdownFilter
      });
    }

    if (isEmpty(scoreFromAdapter)) {
      return (<ChartEmptyState />);
    }

    if (scoreFromAdapter.additionalRows && scoreFromAdapter.score) {
      currentScore = scoreFromAdapter.score;
      // Handle rows from adapter
      // usually because those rows are dynamically computed
      rows = rows ? rows.concat(scoreFromAdapter.additionalRows) : scoreFromAdapter.additionalRows;
    } else {
      currentScore = scoreFromAdapter;
    }
  }

  if (chartConfig.isPrototype) {
    currentScore = chartConfig.sampleData;
  }

  // Get keys of all scores to be loaded in column configs
  const scoreKeys = uniq(compact(map(columns, 'scoreKey')));
  let rowData = compact(map(currentScore, (value, key) => {
    const commonProps = {
      key,
      entityType: organization.entityType || organization.entity_type
    };
    // add order index so that we can sort later by ordering specified in rows
    if (rows) {
      commonProps.orderIndex = rows.indexOf(key);
    }
    // https://app.clubhouse.io/brightbytes/story/31140/when-showing-data-points-by-level-only-show-relevant-level-for-org
    // This allows for only showing scores we have data for, and removing rows that don't have data
    // Simpler than writing logic to handling configurable show/hiding based on a organization's level (i.e. elementary, middle, high schools)
    if (hideNullRows && isEmpty(value)) {
      return null;
    }

    if (isObject(value)) {
      let outValue = value;
      // Handle `defaultScore` directive
      // This also speeds up rendering time by preventing the <TextCell/> templates from catching
      //   and logging `ReferenceErrors` for properties that don't exist.
      if (!isEmpty(defaultScore)) {
        // Pre-fill individual data objects with default values and keys
        outValue = reduce(scoreKeys, (acc, scoreKey) => {
          acc[scoreKey] = { ...defaultScore, ...get(value, scoreKey, {}) };
          return acc;
        }, value);
      }

      // Unpack score values into the row data
      return { ...outValue, ...commonProps };
    }
    return { value, ...commonProps };
  }));

  const getCellValue = (type, data, { ...colOptions }) => {
    let cellData = data;
    if (colOptions.scoreKey) {
      cellData = data[colOptions.scoreKey];
    }
    return template(colOptions.text, cellData, false);
  };

  if (rows) {
    const rowsToShow = new Set(rows);
    // Remove those that are not in the rows config
    rowData = rowData.filter(row => rowsToShow.has(row.key));
    rowData.sort((a, b) => a.orderIndex - b.orderIndex);

    if (hideNullRows || hideZeroRows) {
      rowData = rowData.filter(row => !!map(columns, ({ type, ...colOptions }) => getCellValue(type, row, { ...colOptions }))
        .filter((value) => {
          if (
            hideNullRows &&
            (value === undefined || value === null || (typeof value === 'string' && !value.length))
          ) { return false; }

          if (hideZeroRows && (value === 0 || value === '0')) { return false; }

          return true;
        }).length);
    }
  }

  const sortRows = get(chartConfig, 'options.sortRows');
  if (sortRows) {
    rowData = orderBy(rowData, [sortRows.key], [sortRows.order]);
  }
  // Columns config takes `type` which configures how the cell is displayed
  const COLUMN_TYPE_MAP = {
    label: LabelCell,
    text: TextCell,
    tag: TagCell,
    popover: PopoverCell
  };

  const renderCell = (type, data, { when: columnCondition = 'true', ...colOptions }) => {
    const Cell = get(COLUMN_TYPE_MAP, type);
    let cellData = data;

    // With complete cell data, the scoreKey determines what sub-value object to display
    // i.e. if data is nested like:
    //   data := {
    //     foo: { value: 10 },
    //     bar: { value: 20 },
    //     baz: { value: 30 }
    //   }
    //   colOptions.scoreKey := 'bar'
    //   cellData := { value: 20 }
    if (colOptions.scoreKey) {
      cellData = data[colOptions.scoreKey];
    }

    if (colOptions.snakecaseKeys) {
      cellData = snakeize(cellData);
    }

    const percentRow = get(chartConfig, 'options.percentRow.destRow');
    if (percentRow && data.key === percentRow) {
      colOptions.alternateTextProp = get(chartConfig, 'options.percentRow.alternateTextKey');
    }

    const textAlign = type === 'text' ? get(chartConfig, 'textAlign', null) : null;

    return when(columnCondition, {
      ...cellData,
      ...whenProperties
    }) ? <Cell item={{ ...cellData, rowKey: data.key }} textAlign={textAlign} {...colOptions} /> : '';
  };

  if (chartConfig.excludeEmptyColumn) {
    columns = excludeEmptyColumns(rowData, columns, chartConfig.excludeEmptyColumnKey);
  }

  if (chartConfig.excludeMissingScoreColumn) {
    columns = getColumnsWithoutScores(rowData, columns);
  }

  const renderRow = (row, rowIndex) => (
    <tr key={rowIndex}>
      {map(columns, ({ type, ...colOptions }, colIndex) => {
        let cellData = row;

        if (colOptions.scoreKey) {
          cellData = row[colOptions.scoreKey];
        }

        if (colIndex === 0) {
          return (
            <th key={colIndex} className={cx(styles.rowHeader, { [styles.rowHeader_blue]: chartConfig.useBlueHeader })}>
              {renderCell(type, row, { ...colOptions, rowIndex, colIndex })}
            </th>
          );
        }

        if (
          !colOptions.when ||
          isStringOrObjectWithTruthyWhen(colOptions, {
            ...cellData,
            ...whenProperties
          })
        ) {
          return (
            <td key={colIndex}>
              {renderCell(type, row, { ...colOptions, rowIndex, colIndex })}
            </td>
          );
        }

        return null;
      })}
    </tr>
  );

  const renderColumnHeader = (contentClassName, type, headerClassName, value) => {
    const content = contentClassName ? <div className={contentClassName}>{value}</div> : value;
    const textAlign = (type === 'text' && !chartConfig.slanted) ? get(chartConfig, 'textAlign', 'left') : 'left';

    return (
      <Table.ColumnHeader
        className={headerClassName}
        key={value}
        property={value}
      >
        <div style={{ textAlign }}>{content}</div>
      </Table.ColumnHeader>
    );
  };

  const className = cx('cui-margin-bottom-none', styles.table, { [styles.slantedTable]: chartConfig.slanted });
  const headers = tableHeaders(chartConfig, columns);

  return (
    <SideScroll>
      <div className={styles.tableContainer}>
        {
          chartConfig.chartMessage && (
            <p className={styles.chartMessage}>
              {chartConfig.chartMessage}
            </p>
          )
        }
        <Table data={rowData} renderRow={renderRow} className={className} description={get(node, 'name')}>
          {
            headers
              .filter(header => isStringOrObjectWithTruthyWhen(header, whenProperties))
              .map((header, index) => renderColumnHeader(
                chartConfig.slanted ? styles['header-content'] : '',
                columns[index].type,
                styles.header,
                typeof header === 'object' ? header.value : header
              ))
          }
        </Table>
        {
          chartConfig.externalLink &&
            <a
              href={chartConfig.externalLink.url}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.externalLink}
            >
              { chartConfig.externalLink.text } <Icon name="bb-arrow-box-top" />
            </a>
        }
      </div>
    </SideScroll>
  );
};

TableChart.propTypes = {
  organization: PropTypes.shape({
    id: PropTypes.number,
    entity_type: PropTypes.string,
    entityType: PropTypes.string
  }),

  // Score data
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      remote_organization_id: PropTypes.number.isRequired,
      value: PropTypes.object.isRequired
    })
  ),

  // Framework Tree Node
  node: PropTypes.object,

  // Chart Config object
  config: PropTypes.shape({
    options: PropTypes.shape({
      defaultScoreKey: PropTypes.string, // key of score object to select by default
      headers: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
      columns: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(['label', 'text', 'tag', 'popover']).isRequired,
          when: PropTypes.string,
          labels: PropTypes.objectOf(PropTypes.string),
          tags: PropTypes.arrayOf(PropTypes.object),
          text: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.arrayOf(PropTypes.string)
          ]),
          icon: PropTypes.string,
          theme: PropTypes.string,
          scoreKey: PropTypes.string
        })
      ).isRequired
    })
  }),

  // Optionally extend the list of adapters this chart knows about
  additionalChartAdapters: PropTypes.object,
  viewingDropdownFilter: PropTypes.string
};

TableChart.defaultProps = {
  organization: {
    id: null
  },
  scores: [],
  config: {},
  viewingDropdownFilter: null
};

export default TableChart;
/* eslint-enable complexity */
