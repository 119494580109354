import get from 'lodash/get';

// Extract the data nested under current viewing dataDropdown filter for score returned from
// the svc-portaldata service
// Example:
// const score = {
//   dimensions: {
//     elementary_school: {
//       dimensions: {
//         gen_male: { dimensions: {}, score: { count: 1 } },
//         gen_female: { dimensions: {}, score: { count: 2 } }
//       }
//     },
//     middle_school: { dimensions: {}, score: {} },
//     high_school: { dimensions: {}, score: {} }
//   },
//   score: {}
// }
// cont viewingDropdownFilter = 'middle_school'
//   it returns: { count: 2 }

export default function adapter(currentOrgScore, { viewingDropdownFilter }) {
  const scoreKey = get(viewingDropdownFilter, '0');

  if (scoreKey) {
    return get(currentOrgScore, ['dimensions', scoreKey], {});
  }

  return currentOrgScore;
}
