import React from 'react';
import PropTypes from 'prop-types';

import ScoreDot from 'cui/lib/components/ScoreDot';
import FlexBar from 'cui/lib/components/FlexBar';
import Progress from 'cui/lib/components/Progress';

const COLORS = {
  H: 0,
  M: 1,
  L: 2
};

const generateColorValue = level => COLORS[level.toUpperCase()];

export const ColorDotWithProgressFormatter = (props) => {
  const { value } = props;
  const { level = '', value: subValue, name } = value;
  return (
    <span>
      <FlexBar>
        <FlexBar.Item>
          <ScoreDot
            value={generateColorValue(level)}
            theme="socialImpactAchievement"
            label={level.toUpperCase()}
            className="tlo-impactScoreDot"
          />
        </FlexBar.Item>
        <FlexBar.Item grow>
          <div style={{ width: '5rem' }}>
            <Progress data={[{ percent: subValue }]} theme="dataHighlight" className="tlo-impactProgress cui-border-radius-none" />
          </div>
        </FlexBar.Item>
        <FlexBar.Item>
          <div className="value-name">{ name }</div>
        </FlexBar.Item>
      </FlexBar>
    </span>
  );
};

ColorDotWithProgressFormatter.propTypes = {
  value: PropTypes.shape({
    level: PropTypes.string,
    name: PropTypes.string
  }).isRequired
};

ColorDotWithProgressFormatter.defaultProps = {
  value: {
    level: '',
    name: '',
    value: 0
  }
};

export default ColorDotWithProgressFormatter;
