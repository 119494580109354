import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

import { FORMATTERS } from './formatter';
import { currentScoreForOrg } from './scoreHelpers';
import { computeRatio } from './mathHelpers';
import { achievementGaps } from './acctHelpers';
import { federalClassificationInfo } from './OrgDataProvider';

import {
  KENTUCKY_ORG_ID,
  STUDENT_TEACHER_RATIO_NOTH_PATH,
  ATTENDANCE_RATE_NODE_PATH,
  STUDENT_ECON_DISADVANTAGE_NODE_PATH,
  STUDENT_MEMBERSHIP_NODE_PATH,
  READING_PROFICIENCY_NODE_PATH,
  MATH_PROFICIENCY_NODE_PATH,
  STUDENT_SPENDING_NODE_PATH
} from '../constants';

export const STUDENT_SPENDING = {
  node_path: STUDENT_SPENDING_NODE_PATH,
  portal_data: {
    slug: 'finance_domain'
  },
  includeParentData: true,
  metadata: {
    includeParentData: true
  }
};

const STUDENT_MEMBERSHIP_MAP = [
  {
    key: 'eth_white',
    title: 'White (non-Hispanic)'
  },
  {
    key: 'eth_hispanic',
    title: 'Hispanic or Latino'
  },
  {
    key: 'eth_black',
    title: 'African American'
  }
];

const NOVICE_PERFORMANCE_MAP = [
  {
    title: 'Reading',
    nodePath: READING_PROFICIENCY_NODE_PATH,
    percent: true
  },
  {
    title: 'Mathematics',
    nodePath: MATH_PROFICIENCY_NODE_PATH,
    percent: true
  }
];

function scoreFilter(organization, dataPoint, schoolYear) {
  const filters = {
    framework_tree_node: {
      node_path: dataPoint.data_node_path
    },
    remote_organization_id: organization.id
  };

  if (schoolYear) {
    filters.schoolYear = schoolYear;
  }

  return filters;
}

function parseValue(value) {
  const result = parseFloat(value);
  // return null if it is not a number so that the chart can display empty state
  return Number.isFinite(result) ? result : null;
}

function getSections(framework, scores, organization, level) {
  return get(framework, `metadata.schoolReportDataPoints.${level}`, [])
    .map(section => ({
      ...section,
      scores: section.nodes.map((dp) => {
        const schoolDataPoint = currentScoreForOrg(scores, scoreFilter(organization, dp));
        const stateDataPoint = currentScoreForOrg(scores, scoreFilter({ id: KENTUCKY_ORG_ID }, dp));
        const schoolScore = get(schoolDataPoint, `value.dimensions.${level}.score.${dp.score_key}`);
        const stateScore = get(stateDataPoint, `value.dimensions.${level}.score.${dp.score_key}`);

        return {
          ...dp,
          score: parseValue(schoolScore),
          average: parseValue(stateScore)
        };
      })
    }));
}

function toFixed(value, precision = 1) {
  return parseFloat(value.toFixed(precision));
}

function studentEconDisadvantage(score) {
  if (isEmpty(score)) {
    return {};
  }

  const freeLunchCount = parseInt(score.free_lunch_count);
  const totalCount = parseInt(score.total_count);
  let freePercent = (freeLunchCount / totalCount) * 100;

  if (Number.isNaN(freePercent)) {
    return null; // should show empty state
  } else {
    freePercent = toFixed(freePercent, 1);
  }

  const nonFree = toFixed(100 - freePercent, 1);

  return {
    data: [
      { percent: freePercent },
      { percent: nonFree }
    ],
    legendData: [
      {
        label: `${freePercent}%`,
        description: 'Economically Disadvantaged'
      },
      {
        label: `${nonFree}%`,
        description: 'Non Economically Disadvantaged'
      }
    ]
  };
}

function studentMembership(score) {
  if (isEmpty(score)) {
    return {};
  }

  let data = STUDENT_MEMBERSHIP_MAP
    .map(item => ({
      ...item,
      count: parseInt(get(score, `value.dimensions.${item.key}.score.count`)) || null
    }));

  if (data.every(d => d.count === null)) {
    return {}; // should show empty state in this case.
  }

  const totalCount = parseInt(get(score, 'value.dimensions.all_demographics.score.count')) || 0;
  const dataCount = data.reduce((memo, d) => memo + (d.count || 0), 0);
  // count for others
  data.push({
    count: totalCount - dataCount,
    title: 'Other'
  });

  data = data.map(d => ({
    ...d,
    percent: toFixed((d.count / totalCount) * 100, 2)
  }));

  return {
    data: data.map(d => ({ percent: d.percent })),
    legendData: data.map(d => ({
      label: `${d.percent}%`,
      description: `${d.title} ${d.count} students`
    }))
  };
}

function novicePerformance(scores, previousYearScores, organization, level, currentYear) {
  return NOVICE_PERFORMANCE_MAP.map((item) => {
    const score = currentScoreForOrg(scores, scoreFilter(organization, { data_node_path: item.nodePath }));
    const prevScore = currentScoreForOrg(previousYearScores, scoreFilter(organization, { data_node_path: item.nodePath }, currentYear - 1));
    const noviceScore = get(score, `value.dimensions.${level}.score.novice`);
    const prevNoviceScore = get(prevScore, `value.dimensions.${level}.score.novice`);

    return {
      ...item,
      score: parseValue(noviceScore),
      average: parseValue(prevNoviceScore)
    };
  });
}

// TODO, we don't have financial data for year 2 therefore we display previous year data
// for per student spending in the report, revisit this function after financial data are
// imported to the system.

function studentSpending(scores, currentOrganization, schoolYear) {
  let districtSpending;
  const district = currentOrganization.parentDistrict;

  if (district) {
    districtSpending = currentScoreForOrg(
      scores,
      scoreFilter(district, { data_node_path: STUDENT_SPENDING_NODE_PATH }, schoolYear - 1)
    );
  }

  const stateSpending = currentScoreForOrg(
    scores,
    scoreFilter({ id: KENTUCKY_ORG_ID }, { data_node_path: STUDENT_SPENDING_NODE_PATH }, schoolYear - 1)
  );

  const data = [
    {
      value: computeRatio(
        get(districtSpending, 'value.score.total_expenditures_state_local'),
        get(districtSpending, 'value.score.membership'),
        null,
        1
      ),
      description: 'District',
      colorIndex: 1
    },
    {
      value: computeRatio(
        get(stateSpending, 'value.score.total_expenditures_state_local'),
        get(stateSpending, 'value.score.membership'),
        null,
        1
      ),
      description: 'State',
      colorIndex: 2
    }
  ];

  if (data.every(d => Number.isNaN(d))) {
    return {}; // should show empty state in this case.
  }

  return {
    data,
    legendData: data.map(d => ({
      colorIndex: d.colorIndex,
      label: FORMATTERS.money(d.value, { divider: 1, fraction: 0 }),
      description: d.description
    }))
  };
}

export function reportData(currentOrganization, scores, previousYearScore, framework, schoolYear, level) {
  const sections = getSections(framework, scores, currentOrganization, level);
  const orgDatum = find(currentOrganization.organization_data, { year: schoolYear });

  const gaps = achievementGaps(get(orgDatum, ['gap_demo', level], []));
  const star = parseInt(get(orgDatum, ['star_rating', level]));
  const gapReduction = get(orgDatum, ['gap_reduction', level]);
  const fedClass = federalClassificationInfo(
    currentOrganization,
    schoolYear,
    [level],
    framework
  );
  const studentRatioScore = currentScoreForOrg(scores, scoreFilter(currentOrganization, { data_node_path: STUDENT_TEACHER_RATIO_NOTH_PATH }));
  const attendanceRateScore = currentScoreForOrg(scores, scoreFilter(currentOrganization, { data_node_path: ATTENDANCE_RATE_NODE_PATH }));
  const studentEconScore = currentScoreForOrg(scores, scoreFilter(currentOrganization, { data_node_path: STUDENT_ECON_DISADVANTAGE_NODE_PATH }));
  const studentMembershipScore = currentScoreForOrg(scores, scoreFilter(currentOrganization, { data_node_path: STUDENT_MEMBERSHIP_NODE_PATH }));
  const studentRatio = get(studentRatioScore, 'value.score.students_per_teacher');
  const attendanceRate = {
    percent: parseValue(get(attendanceRateScore, 'value.score.attendance_rate')),
    colorIndex: 0
  };
  const studentEcon = studentEconDisadvantage(get(studentEconScore, 'value.score'));
  const studentMembershipCount = studentMembership(studentMembershipScore);
  const novice = novicePerformance(scores, previousYearScore, currentOrganization, level, schoolYear);
  const spendings = studentSpending(previousYearScore, currentOrganization, schoolYear);

  return {
    sections,
    currentOrganization,
    gaps,
    gapReduction,
    fedClass,
    attendanceRate,
    year: schoolYear,
    stars: star,
    schoolType: level,
    studentTeacherRatio: studentRatio,
    studentDemographics: studentMembershipCount,
    disadvantaged: studentEcon,
    noviceScores: novice,
    totalSpending: spendings,
    introCopy: get(framework, `metadata.schoolReportDataPoints.introCopy.${level}`, '')
  };
}
