import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

import { getPageContext } from '../helpers/backend_context';
import syncQueryWithRedux from './portals';
import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

// This is injected by Clarity and is usually the module path. For example, if
// this app is being loaded because someone visited
// https://clarity.brightbytes.net/modules/kde/foo/bar, basename will be
// "/modules/kde".
const basename = getPageContext().basename;

const history = createBrowserHistory({ basename });

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  createReducer(history),
  {},
  composeEnhancers(
    applyMiddleware(
      sagaMiddleware,
      routerMiddleware(history)
    )
  )
);

syncQueryWithRedux(store, history);

/* eslint-disable no-underscore-dangle */
if (window.Cypress) {
  window.__store__ = store;
  window.__REDUX_DEVTOOLS_EXTENSION__ = window.parent.__REDUX_DEVTOOLS_EXTENSION__;
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ = window.parent.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
}
/* eslint-enable no-underscore-dangle */

export { store, sagaMiddleware, history, basename };
