import xor from 'lodash/xor';

import {
  SET_CHART_FOR_NODE,
  SET_VISIBLE_FOR_NODE,
  RESET_CHARTS
} from '../../common_actions';

const DEFAULT_STATE = {};

function chartsReducer(state = DEFAULT_STATE, action = {}) {
  if (action.type === SET_CHART_FOR_NODE) {
    return {
      ...state,
      [action.nodePath]: { comparison: action.comparison }
    };
  } else if (action.type === SET_VISIBLE_FOR_NODE) {
    const activeItems = (state[action.nodePath] && state[action.nodePath].activeItems) || action.activeItems;
    return {
      ...state,
      [action.nodePath]: { ...state[action.nodePath], activeItems: xor(activeItems, [action.item]) }
    };
  } else if (action.type === RESET_CHARTS) {
    return {};
  } else {
    return state;
  }
}

export default chartsReducer;
