import React from 'react';
import PropTypes from 'prop-types';

import styles from './AccountabilityDashboard.module.scss';

const Scores = ({ children }) => (
  <div className={styles.scores}>
    { children }
  </div>
);

Scores.propTypes = {
  children: PropTypes.node
};

export default Scores;
