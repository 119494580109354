import React from 'react';

import merge from 'lodash/merge';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';
import { ChartEmptyState, chartPropTypes } from '../Chart';
import template from '../../helpers/template';

import adapter from './adapter';

class KdeOverviewBarChart extends React.Component {
  static BASE_CHART_PROPS = {
    low: 0,
    high: 100,
    yAxis: {
      offset: 40,
      labelCount: 5,
      showLabel: true,
      showAxis: true,
      showGrid: false,
      labelDirection: 'btt'
    },
    xAxis: {
      showAxis: true,
      showLabel: true,
      showGrid: true,
      offset: 40
    }
  };

  shouldComponentUpdate(newProps) {
    // Prevents re-render of component unless new scores are detected
    if (isEqual(newProps.directScore, this.props.directScore)) {
      return false;
    }
    return true;
  }

  render() {
    const { scores, node, config } = this.props;
    const scoreObj = get(scores, '[0].value', {});
    const { chartOptions = {} } = get(config, 'options', {});

    const hasScore = !isEmpty(scoreObj);

    if (!hasScore) return <ChartEmptyState />;

    const displayScore = adapter(scoreObj, { node, config });
    const chartProps = {
      theme: get(config, 'options.theme', 'default'),
      data: displayScore,
      chartOptions: merge({
        yAxis: {
          labelInterpolationFunc: l => template(get(chartOptions, 'yAxis.labelTemplate', '{{l}}'), { l })
        }
      }, KdeOverviewBarChart.BASE_CHART_PROPS, chartOptions)
    };

    return <BarChartWithLegend chartProps={chartProps} />;
  }
}

KdeOverviewBarChart.propTypes = {
  ...chartPropTypes
};

export default KdeOverviewBarChart;
