import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import find from 'lodash/find';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isEqual from 'lodash/isEqual';
import { format, startOfYear, endOfYear } from 'date-fns';

import Field from 'cui/lib/components/Form/Field';
import RadioSet from 'cui/lib/components/Form/RadioSet';
import DatePicker from 'cui/lib/components/Form/DatePicker';
import List from 'cui/lib/components/List';

import { validationMeta } from '../../helpers/collectorHelpers';

export default class CollectionRadioField extends PureComponent {
  constructor(props) {
    super(props);

    this.setSubmissionValueGenerator(props);

    this.state = {
      ...this.computeState(props.currentScore),
      error: null
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setSubmissionValueGenerator(nextProps);

    if (isUndefined(this.props.currentScore) && !isUndefined(nextProps.currentScore)) {
      this.setState(this.computeState(nextProps.currentScore));
    }
  }

  setSubmissionValueGenerator({ dp, submissionValueGenerators }) {
    if (dp && submissionValueGenerators) {
      submissionValueGenerators[dp.node_path] = this.generateSubmissionValue;
    }
  }

  handleRadioChange = value => this.setState({ value });

  handleDateChange = date => this.setState({ date });

  handleDateError = error => this.setState({ error });

  computeState(currentScore) {
    const scoreDate = get(currentScore, 'value.date', null);

    return {
      value: get(currentScore, 'value.value', null),
      date: scoreDate ? new Date(scoreDate) : scoreDate
    };
  }

  hasDate() {
    const option = find(get(this.props.dp, 'field_options.options'), { value: this.state.value });
    return get(option, 'hasDate', false);
  }

  generateSubmissionValue = () => {
    const { value: fieldValue, date, error } = this.state;
    if (error) {
      return null;
    }

    const value = {
      value: fieldValue,
      date: this.hasDate() ? format(new Date(date), 'yyyy-MM-dd') : null
    };
    const currentValue = get(this.props.currentScore, 'value', { value: null, date: null });

    if (isUndefined(currentValue.date)) {
      currentValue.date = null;
    }

    if (isEqual(currentValue, value)) {
      return null;
    }

    const startAt = get(this.props.currentScore, 'start_at', format(startOfYear(new Date()), 'yyyy-MM-dd'));
    const endAt = get(this.props.currentScore, 'end_at', format(endOfYear(new Date()), 'yyyy-MM-dd'));

    return {
      startAt,
      endAt,
      value
    };
  };

  renderRadioOption = ({ label, value }) => (
    <List.Item key={value}>
      <RadioSet.Option
        id={`${this.props.nodePath}_${value}`}
        label={label}
        value={value}
      />
    </List.Item>
  );

  renderDateField() {
    const { nodePath, dp } = this.props;
    const validations = validationMeta(get(dp, 'field_options.validations', {}));

    if (!this.hasDate()) {
      return <div />;
    }

    return (
      <div>
        <DatePicker
          id={`${nodePath}_date`}
          stroked
          required
          value={this.state.date}
          onChange={this.handleDateChange}
          onError={this.handleDateError}
          {...validations.props}
          errors={validations.errors}
        />
      </div>
    );
  }

  render() {
    const { fieldPrompt, nodePath, dp } = this.props;
    const { value } = this.state;
    const options = get(dp, 'field_options.options', []);

    return (
      <Field key={nodePath} label="">
        <fieldset>
          <legend>{fieldPrompt}</legend>
          <RadioSet
            id={nodePath}
            name={nodePath}
            onChange={this.handleRadioChange}
            value={value}
          >
            <List inline>
              {options.map(this.renderRadioOption)}
            </List>
          </RadioSet>
          {this.renderDateField()}
        </fieldset>
      </Field>
    );
  }
}

CollectionRadioField.propTypes = {
  currentScore: PropTypes.shape({
    value: PropTypes.shape({
      value: PropTypes.string,
      date_of: PropTypes.string
    })
  }),
  submissionValueGenerators: PropTypes.objectOf(PropTypes.func),
  dp: PropTypes.shape({
    node_path: PropTypes.string.isRequired,
    field_options: PropTypes.shape({
      options: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
        hasDate: PropTypes.bool
      }))
    })
  }),
  fieldPrompt: PropTypes.string.isRequired,
  nodePath: PropTypes.string.isRequired
};
