import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';

import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';
import EmptyState from 'cui/lib/components/EmptyState';
import Heading from 'cui/lib/components/Heading';
import FlexBar from 'cui/lib/components/FlexBar';
import Legend from 'cui/lib/components/Legend';
import ScoreDot from 'cui/lib/components/ScoreDot';
import List from 'cui/lib/components/List';

import EntityComparisonDetailsPopover from '../EntityComparisonDetailsPopover';
import EntityComparisonDetailsPopoverWithoutPercentSign from '../EntityComparisonDetailsPopoverWithoutPercentSign';
import template from '../../helpers/template';
import ChartOptionHelpers from '../../helpers/chartOptionHelpers';
import currentOrgYearScores from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';
import numberFormatter from '../../helpers/numberFormatter';
import OtherDataModal from '../OtherDataModal';
import BarChartWithTitleDetailsPopover from '../BarChartWithTitleDetailsPopover';

/* eslint-disable complexity */
export const BarChartWithTitle = (props) => {
  const { config, scores, node, organization, chartType, directScore } = props;
  const options = config.options || {};
  const score = directScore || currentOrgYearScores(scores, organization);

  const dataOptions = {
    ...props,
    currentOrgScore: score
  };

  const popoverMap = {
    entityComparisonDetailsPopover: EntityComparisonDetailsPopover,
    barChartWithTitleDetailsPopover: ({ theme, data }) => (
      <BarChartWithTitleDetailsPopover theme={theme} data={data} subtitle={options.detailsPopoverSubtitle} />
    ),
    entityComparisonDetailsPopoverWithoutPercentSign: EntityComparisonDetailsPopoverWithoutPercentSign
  };

  const data = chartDataAdapter(config.adapter || chartType, dataOptions);
  const { min = 0, max, customLabelCount = 6 } = dataMaxHelper[config.maxDataAdapter || chartType]({ ...dataOptions, data });
  const hasData = data && data.length;
  const lowAndHigh = { high: max };
  if (min !== undefined) {
    lowAndHigh.low = min;
  }
  let index = -1;
  let chartOptions = options && merge(options, {
    ...lowAndHigh,
    yAxis: {
      labelInterpolationFunc: l => template(options.yAxis.labelTemplate || '{{l}}', { l })
    },
    xAxis: {
      labelCount: get(options, 'xAxis.labelCount', customLabelCount)
    },
    valueLabelFormatter: () => {
      index += 1;
      if (index >= data.length) index = 0;

      let { value } = data[index];
      const { subLabelTemplate = '{{value}}', valueFormat, precision } = options.yAxis;

      if (valueFormat === 'number' || valueFormat === 'percent') {
        value = numberFormatter(value, valueFormat, precision);
      }

      return template(subLabelTemplate, { l: data[index].label, value }) || template(subLabelTemplate, data[index]);
    },
    detailsPopoverRenderer: popoverMap[options.detailsPopoverRenderer],
    detailsPopoverTitle: column => (
      options.detailsCustomPopoverTitle ? template(options.detailsCustomPopoverTitle, column) : null
    )
  });
  const renderViewOtherData = () => {
    const { showViewOtherData } = props;
    if (!showViewOtherData) return '';
    const { viewOtherData } = props;
    return (
      <FlexBar justify="right" className="cui-text_small">
        <FlexBar.Item>
          <OtherDataModal viewOtherData={viewOtherData} />
        </FlexBar.Item>
      </FlexBar>
    );
  };

  chartOptions = ChartOptionHelpers.gridFilter(chartOptions);
  set(chartOptions, 'xAxis.forceOrdinalScale', true);
  const title = data.title || node.title;
  const legendData = data[0] ? data[0].value : data;
  return (
    <div>
      { !hasData && <EmptyState kind="text" title="There is no data available." /> }
      { !!hasData && (
        <div>
          <FlexBar align="top">
            {title && (
              <Heading level={3} className="cui-margin-bottom-large theme-global-neutral-fg-7" size="small" caps>{title}</Heading>
            )}
            {chartOptions.hasLegend && (
              <div>
                <Legend
                  data={legendData}
                  theme={config.theme}
                  kind="inline"
                  className="cui-margin-bottom-large"
                  renderLegendItem={(theme, item, i) => (
                    <List.Item
                      key={i}
                      icon={
                        <ScoreDot type="solid" theme={theme} value={item.colorIndex} />
                      }
                    >
                      <span className="cui-list-text">
                        { template(chartOptions.legendLabel, { legendLabel: item.legendLabel }) || item.label}
                      </span>
                    </List.Item>
                  )}
                />
              </div>
            )}
            { renderViewOtherData() }
          </FlexBar>
          <BarChartWithLegend
            chartProps={{
              data,
              chartOptions
            }}
            theme={config.theme}
          />
        </div>
      )}
    </div>
  );
};
/* eslint-enable complexity */

BarChartWithTitle.propTypes = {
  node: PropTypes.object,
  data: PropTypes.object,
  theme: PropTypes.string,
  config: PropTypes.shape({
    options: PropTypes.object
  }),
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  organization: PropTypes.object,
  chartType: PropTypes.string,
  directScore: PropTypes.object,
  showViewOtherData: PropTypes.bool,
  viewOtherData: PropTypes.arrayOf(
    PropTypes.object
  )
};

BarChartWithTitle.defaultProps = {
  showViewOtherData: false
};

export default BarChartWithTitle;
