import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import when from '../../helpers/when';
import formatter from '../../helpers/formatter';
import getName from '../../helpers/nameHelper';
import { isA1School } from '../../helpers/orgCanAccessCollectorDataPoint';

// converts the current score object into array of objects for single bar chart.
// Example:
// currentOrgScore = {
//   school: { value: 5800 },
//   district: { value: 10000.80 },
//   state: { value: 18000.78 }
// }

// chartConfig = {
//   options: {
//     singleBarChart: {
//       legend: {
//         formatter: 'money'
//       },
//       showLegend: true,
//       bars: [
//         {
//           name: 'School',
//           scoreKey: 'school.value'
//         },
//         {
//           name: 'District',
//           scoreKey: 'district.value'
//         },
//         {
//           name: 'Statewide',
//           scoreKey: 'state.value'
//         }
//       ]
//     }
//   }
// }

// return {
//   chartData: [
//     {
//       value: 1,
//       colorIndex: 0,
//       label: 'School'
//     },
//     {
//       value: 2,
//       colorIndex: 1,
//       label: 'District'
//     },
//     {
//       value: 3,
//       colorIndex: 2,
//       label: 'State'
//     }
//   ],
//   legendData: [
//     {
//       colorIndex: 0,
//       label: 'School',
//       legendValue: '$5,800.00'
//     },
//     {
//       colorIndex: 1,
//       label: 'District',
//       legendValue: '$10,000.80'
//     },
//     {
//       colorIndex: 2,
//       label: 'State',
//       legendValue: '$18,000.78'
//     }
//   ]
// }

export default function adapter(currentOrgScore, { currentOrganization, chartConfig }) {
  if (!isEmpty(currentOrgScore)) {
    const labelMap = get(chartConfig, 'options.singleBarChart.labelMap');
    const chartData = get(chartConfig, 'options.singleBarChart.bars', [])
      .map((bar, i) => {
        const barData = {
          label: getName(labelMap, bar.name, bar.name),
          colorIndex: i,
          value: null
        };
        let scoreUnderKey = get(currentOrgScore, bar.scoreKey);
        if (scoreUnderKey !== undefined && scoreUnderKey !== '') {
          scoreUnderKey = parseFloat(scoreUnderKey);
          barData.value = Number.isNaN(scoreUnderKey) ? 0 : scoreUnderKey;
        }

        return barData;
      });
    // Do not display bar for any item without score
    const barChartData = {
      chartData: chartData.filter(bar => bar.value !== null)
    };

    // when showLegend is true, add legend data
    if (get(chartConfig, 'options.singleBarChart.showLegend')) {
      const legendEmptyState = get(chartConfig, 'options.singleBarChart.legend.emptyState');
      const legendValueFormatter = get(chartConfig, 'options.singleBarChart.legend.formatter');

      barChartData.legendData = chartData.reduce((memo, bar) => {
        // show legend item for bar with valid value
        if (bar.value !== null) {
          memo.push({
            colorIndex: bar.colorIndex,
            label: bar.label,
            legendValue: legendValueFormatter ? formatter(legendValueFormatter, bar.value, { divider: 1, fraction: 0 }) : bar.value
          });
        } else if (legendEmptyState && when(legendEmptyState.when, { isA1School, currentOrganization })) {
          // when bar value is null and empty state is defined and the when expression evaluates to true
          // show empty state message for that bar item instead of the value
          memo.push({
            colorIndex: bar.colorIndex,
            label: bar.label,
            legendValue: legendEmptyState.message
          });
        }

        return memo;
      }, []);
    }

    return barChartData;
  }

  return currentOrgScore;
}
