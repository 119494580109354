import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';
import FlexBar from 'cui/lib/components/FlexBar';

import template from '../../../helpers/template';
import conditional from '../../../helpers/conditional';

export const IconFormatter = (props) => {
  const { icon, templateString, icons } = props;
  let iconName;
  let conditionalText;
  if (icons) {
    const { name, conditionalTemplate } = conditional(icons, undefined, props);
    iconName = name;
    conditionalText = template(conditionalTemplate, props);
  }
  const compiledValue = template(templateString, props);

  return (
    <FlexBar justify="left">
      <FlexBar.Item>{ (iconName || icon) && <Icon name={iconName || icon} />}</FlexBar.Item>
      <FlexBar.Item>{conditionalText || compiledValue}</FlexBar.Item>
    </FlexBar>
  );
};

IconFormatter.propTypes = {
  icon: PropTypes.string,
  templateString: PropTypes.string,
  icons: PropTypes.arrayOf(
    PropTypes.object
  )
};

export default IconFormatter;
