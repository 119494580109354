import map from 'lodash/map';

export const mapStateToProps = (state, ownProps) => {
  const propMap = ownProps.propMap;
  if (propMap) {
    propMap.propMap = undefined;
    return Object.keys(propMap).reduce((props, key) => {
      const path = ownProps.propMap[key] || '';
      props[key] = path.split('.').reduce((memo, token) => {
        if (token === '@each') {
          return { each: true, value: memo.value };
        } else if (memo.each) {
          return { value: map(memo.value, token) };
        } else {
          return { value: memo.value && memo.value[token] };
        }
      }, { value: state }).value || ownProps[key];
      return props;
    }, {});
  } else {
    return {};
  }
};

export default mapStateToProps;
