import React from 'react';
import PropTypes from 'prop-types';

import { Label, Legend } from 'cui';

import styles from './AccountabilityLegend.module.scss';

const AccountabilityLegend = ({
  data,
  label
}) => {
  const list = data ? data : [{ label: 'Very Low' }, { label: 'Low' }, { label: 'Medium' }, { label: 'High' }, { label: 'Very High' }];
  return (
    <div className={styles.legend}>
      <Label className={styles.label} value={label} position="inline" />
      <Legend
        data={list}
        theme="kde-accountability2"
        kind="inline"
      />
    </div>
  );
}

AccountabilityLegend.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string
}



AccountabilityLegend.defaultProps = {
  label: "Legend"
};

export default AccountabilityLegend;
