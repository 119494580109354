import React from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';

import DetailsModal from './DetailsModal';

class DetailsModalButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  openModal = () =>  this.setState({ isModalOpen: true })

  closeModal = () => this.setState({ isModalOpen: false });

  render() {
    return (
      <div>
        <Button onClick={this.openModal}>{this.props.detailsButtonLabel}</Button>
        <DetailsModal {...this.props} isOpen={this.state.isModalOpen} closeModal={this.closeModal} />
      </div>
    );
  }
}

DetailsModalButton.propTypes = {
  detailsButtonLabel: PropTypes.string
};

DetailsModalButton.defaultProps = {
  detailsButtonLabel: ''
};

export default DetailsModalButton;
