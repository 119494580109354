import React from 'react';
import { Switch, Redirect, useLocation } from 'react-router-dom';
import CuiLoadingSpinner from 'cui/lib/components/LoadingSpinner';

import { useFeatureFlagQuery } from 'store/backend/queries/featureFlags';
import { kdeCurrentSchoolYear } from 'helpers/portals';
import './styles/index.scss';
import AlchemyRoute from './components/Route';
import { isPreviewSite, getPreviewSiteFeatureFlagOverrides } from './helpers/previewSite';
import NavigationFocusReset from './components/NavigationFocusReset';
import MainContentAnchorLink from './components/MainContentAnchorLink';
import ScrollToTopButton from './components/ScrollToTopButton';
import PasswordGate from './components/PasswordGate';
import * as HomePage from './pages/Home';
import * as LandingPage from './pages/Landing';
import * as CollectionPage from './pages/Collection';
import * as ProfileReportPage from './pages/ProfileReport';
import * as StatusPage from './pages/Status';
import * as StatusChildrenPage from './pages/StatusChildren';
import * as StatusDownloadsPage from './pages/StatusDownloads';
import * as OrgReportCardPage from './pages/OrgReportCardPage';
import * as SuccessIndicatorPage from './pages/SuccessIndicatorPage';
import * as Help from './pages/Help';
import * as Disclaimer from './pages/Disclaimer';
import * as Glossary from './pages/Glossary';
import * as OtherData from './pages/OtherData';
import * as Privacy from './pages/Privacy';
import * as ComparePage from './pages/Compare';
import * as SchoolReportPdfPage from './pages/SchoolReport/pdf';
import * as SchoolAccountabilityPage from './pages/Accountability';
import * as DataSets from './pages/DataSets';
import * as DistrictMessagePage from './pages/DistrictMessagePage';
import { isApprovalSite } from './helpers/approvalSite';
import { HOME_PAGE_PATH } from './constants';

const getYear = urlSearch => {
  const urlParams = new URLSearchParams(urlSearch);
  const year = Number(urlParams.get('year'));

  if (Number.isFinite(year)) return year;

  return null;
};

const isPublicPortal = () => !isPreviewSite() && !isApprovalSite();

const Entry = () => {
  const location = useLocation();
  const selectedYear = getYear(location.search);
  const currentYear = kdeCurrentSchoolYear();

  const { data: nextYearFeatureFlag, isLoading } = useFeatureFlagQuery('KDENextYear');

  if (
    isPublicPortal() &&
    currentYear &&
    selectedYear > currentYear &&
    nextYearFeatureFlag &&
    !nextYearFeatureFlag.enabled
  ) {
    window.location.href = `${location.pathname}?year=${currentYear}`;
  }

  return (
    <div>
      <NavigationFocusReset />
      <MainContentAnchorLink />
      <div
        style={{
          display: 'flex',
          position: 'absolute',
          zIndex: -1,
          left: '0',
          right: '0'
        }}
      >
        <CuiLoadingSpinner request={new Promise(() => {})} />
      </div>
      {!isLoading && (
        <div className="content-container">
          <Switch>
            <AlchemyRoute path="/" exact module={LandingPage} />
            <AlchemyRoute path={HOME_PAGE_PATH} exact module={HomePage} />
            {/*
            NOTE: params.id must be the organization ID for some logic to work
            Used to check auth and allow users to see certain pages
          */}
            <AlchemyRoute path="/status/:id" exact module={StatusPage} />
            <AlchemyRoute path="/status/:id/children" exact module={StatusChildrenPage} />
            <AlchemyRoute
              path="/status/:id/downloads"
              exact
              module={StatusDownloadsPage}
            />
            <AlchemyRoute
              path="/status/:id/district_message"
              exact
              module={DistrictMessagePage}
            />
            <AlchemyRoute path="/collection/:id" exact module={CollectionPage} />
            <AlchemyRoute path="/organization/:id" exact module={OrgReportCardPage} />
            <AlchemyRoute
              path="/school-profile-report/:id"
              exact
              module={ProfileReportPage}
            />
            <AlchemyRoute
              path="/organization/:id/:domainSlug/:siSlug/:variableSlug"
              exact
              module={SuccessIndicatorPage}
            />
            <AlchemyRoute path="/help" exact module={Help} />
            <AlchemyRoute path="/disclaimer" exact module={Disclaimer} />
            <AlchemyRoute path="/glossary" exact module={Glossary} />
            <AlchemyRoute path="/other-data" exact module={OtherData} />
            <AlchemyRoute path="/privacy" exact module={Privacy} />
            <AlchemyRoute path="/datasets" exact module={DataSets} />
            <AlchemyRoute
              path="/organization/:id/school_summary_report/:schoolLevel"
              exact
              module={SchoolReportPdfPage}
            />
            <AlchemyRoute path="/organization/:id/compare" exact module={ComparePage} />
            <AlchemyRoute
              path="/organization/:id/accountability"
              exact
              module={SchoolAccountabilityPage}
            />

            <Redirect to="/" />
          </Switch>
          <ScrollToTopButton />
        </div>
      )}
    </div>
  );
};

const EntryWithPasswordGate = () => {
  // Require a password to access the preview view of the approval site.
  // Authentication for the preview view of the SRC is handled by Netlify so no
  // need to do anything here for that.
  const requiresPassword = isApprovalSite() && isPreviewSite();
  if (requiresPassword) {
    return (
      <PasswordGate>
        <Entry />
      </PasswordGate>
    );
  } else {
    return <Entry />;
  }
};

export default EntryWithPasswordGate;

export const config = {
  disableOrgFilter: true,
  site: {
    title: 'Kentucky School Report Card'
  },
  pageContext: {
    moduleName: 'kde',
    basename: '/',
    isPreviewSite: isPreviewSite(),
    featureFlagOverrides: getPreviewSiteFeatureFlagOverrides()
  }
};
