// take items in an array and put them in the top level object under key given by some property of the item
// For example
// const score = {
//   grade_1: {
//     score: [
//       { name: 'Music', count: 1 },
//       { name: 'Math', count: 2 },
//       { name: 'English', count: 3 }
//     ]
//   },
//   grade_2: {
//     score: [
//       { name: 'Music', count: 4 },
//       { name: 'Math', count: 5 },
//       { name: 'English', count: 6 }
//     ]
//   },
//   grade_3: {
//     score: [
//       { name: 'Music', count: 7 },
//       { name: 'Math', count: 8 },
//       { name: 'English', count: 9 }
//     ]
//   }
// }
//
// const chartConfig = {
//   options: {
//     arrayToObject: {
//       scoreKey: 'score',
//       itemKey: 'name'
//     }
//   }
// }
//
// const result = {
//   grade_1: {
//     Music: { name: 'Music', count: 1 },
//     Math: { name: 'Math', count: 2 },
//     English: { name: 'English', count: 3 }
//   },
//   grade_2: {
//     Music: { name: 'Music', count: 4 },
//     Math: { name: 'Math', count: 5 },
//     English: { name: 'English', count: 6 }
//   },
//   grade_3: {
//     Music: { name: 'Music', count: 7 },
//     Math: { name: 'Math', count: 8 },
//     English: { name: 'English', count: 9 }
//   }
// }

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

export default function adapter(currentOrgScore, { chartConfig }) {
  if (!isEmpty(currentOrgScore)) {
    const scoreKey = get(chartConfig, 'options.arrayToObject.scoreKey');
    const itemKey = get(chartConfig, 'options.arrayToObject.itemKey');

    return reduce(currentOrgScore, (memo, value, key) => {
      memo[key] = get(value, scoreKey, []).reduce((acc, item) => {
        acc[get(item, itemKey)] = item;
        return acc;
      }, {});
      return memo;
    }, {});
  }

  return currentOrgScore;
}
