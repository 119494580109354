// This is base for KdeSidewayBarChart and KdeVerticalBarChart
import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';
import BlockTitle from 'cui/lib/components/BlockTitle';
import ScoreDot from 'cui/lib/components/ScoreDot';
import DetailsPopover from './DetailsPopover';
import ChartEmptyState from '../Chart/ChartEmptyState';
import template from '../../helpers/template';
import applyAdapters, { isValidBarChartData, isDataSuppressed } from '../../helpers/adapterHelpers';
import { handlePopoverPlacement } from '../../helpers/dashboardChartHelpers';

import styles from './index.module.scss';

function replaceInvalidData(data, newValue = 0) {
  return data.map(d => {
    if (Array.isArray(d.value)) {
      return { ...d, value: replaceInvalidData(d.value, newValue) };
    }

    const isValueInvalid = Number.isNaN(parseFloat(d.value));
    return {
      ...d,
      value: isValueInvalid ? newValue : d.value,
      originalValue: d.value
    };
  });
}

function legendItems(chartConfig, chartData) {
  const legendFromGroup = get(chartConfig, 'options.legendFromGroup');
  const group = get(chartData, '[0].value');
  // Use group item labels
  if (legendFromGroup && Array.isArray(group)) {
    return group.map(d => ({
      label: d.label,
      colorIndex: d.colorIndex
    }));
  }

  return chartData.map(d => ({
    label: d.label,
    colorIndex: d.colorIndex,
    d
  }));
}

function ChartLegendItem({ item, theme, score, showValue, showAccessibleLabel }) {
  const a11yAttrs = showAccessibleLabel ? { 'aria-hidden': true } : {};

  return (
    <li key={score} className="legend-item legend-item_tight">
      {
        showAccessibleLabel && (
          <div className="legend-a11yLabel" aria-label={`${item.label}, ${item.d.topLabel || item.d.value}`}>
            {item.label}, {item.d.topLabel || item.d.value}
          </div>
        )
      }
      <div className="cui-media" {...a11yAttrs}>
        <div className={cx(styles.legendDot, 'cui-media-image')}>
          <ScoreDot
            theme={theme}
            value={score}
            className="cui-list-icon"
          />
        </div>
        <div className={cx(styles.legendContent, 'cui-media-content')}>
          <span className="cui-list-text">
            <div>
              <div className="legend-label">{item.label}</div>
              {
                showValue && (
                  <span className="legend-value">{item.d.topLabel || item.d.value}</span>
                )
              }
            </div>
          </span>
        </div>
      </div>
    </li>
  );
}

ChartLegendItem.propTypes = {
  item: PropTypes.shape({
    d: PropTypes.shape({
      topLabel: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.number
    }),
    label: PropTypes.string
  }),
  theme: PropTypes.string,
  score: PropTypes.number,
  showValue: PropTypes.bool,
  showAccessibleLabel: PropTypes.bool
};

/* eslint-disable complexity */
function KdeBarChart(props) {
  const {
    horizontal,
    config,
    scores,
    allScores,
    node,
    additionalChartAdapters,
    defaultChartOptions,
    viewingDropdownFilter,
    organization,
    className,
    ...restProps
  } = props;
  const { alwaysRender = false } = config;

  if (isEmpty(scores) && !alwaysRender) {
    return <ChartEmptyState />;
  }
  // Note: for now there's only one score for kde, later we will have data for more than one year
  // We need to way to select score to view based on the filter
  const score = get(scores, '[0].value', {});
  const optionsFromConfig = get(config, 'options.chartOptions', {});
  const baseConfig = {
    horizontal,
    detailsPopoverRenderer(currentItem) {
      return (
        <DetailsPopover
          name={get(optionsFromConfig, 'detailsPopover.name')}
          config={config}
          data={get(currentItem, 'data')}
        />
      );
    },
    viewDetailsAriaLabel: d => d.ariaLabel
  };
  const chartOptions = merge(baseConfig, defaultChartOptions, optionsFromConfig);
  const chartName = get(config, 'name');
  const adapterName = get(config, 'adapter');
  const handleInvalidValueAsZero = get(config, 'handleInvalidValueAsZero');
  const handleInvalidValueAsSuppressed = get(config, 'handleInvalidValueAsSuppressed');

  if (isEmpty(adapterName) && !alwaysRender) {
    return <ChartEmptyState />;
  }

  let chartData;
  let legendData;
  if (props.sampleData) {
    chartData = props.sampleData;
  } else {
    const data = applyAdapters(adapterName, score, {
      ...restProps,
      currentOrganization: organization,
      chartConfig: config,
      additionalChartAdapters,
      node,
      config,
      viewingDropdownFilter,
      allScores
    });

    if (data && data.chartData) {
      chartData = data.chartData;
      legendData = data.legendData;
    } else {
      chartData = data;
    }
  }

  // Currently CUI BarChart can not deal with bad data(not number)
  // Add this validate step here to prevent page failure
  // TODO we could add this validate logics to the CUI BarChart
  if (isDataSuppressed(score, chartData)) {
    return <ChartEmptyState emptyMessage="This data is hidden to protect student privacy." />;
  }

  const hasInvalidData = !isValidBarChartData(chartData);

  if (isEmpty(chartData) || (hasInvalidData && !handleInvalidValueAsZero && !handleInvalidValueAsSuppressed)) {
    return <ChartEmptyState />;
  }

  if (hasInvalidData && handleInvalidValueAsZero) {
    chartData = replaceInvalidData(chartData);
  }

  let legendProps;
  if (get(config, 'options.showLegend')) {
    if (isEmpty(legendData) && !isEmpty(chartData)) {
      legendData = legendItems(config, chartData);
    }

    legendProps = {
      title: '',
      transparent: true,
      data: legendData
    };
  }

  const titleTemplate = get(config, 'options.titleTemplate');
  const topLabelTemplate = get(config, 'options.topLabelTemplate');
  const legendAlignment = get(config, 'options.legendAlignment');

  let chartPropsData = chartData.map((item) => {
    const dataItem = {
      ...item,
      labelClassName: item.topLabel === '*' && styles['chart--suppression-asterisk'],
      ariaLabel: `view ${node.name}, ${item.label} details`
    };

    if (topLabelTemplate) {
      dataItem.topLabel = template(topLabelTemplate, item);
    }
    return dataItem;
  });

  if (get(config, 'options.showValueInLegend', false)) {
    legendProps.renderLegendItem = (theme, item, i) => (
      <ChartLegendItem item={item} theme={theme} score={i} showValue />
    );
  } else if (get(config, 'options.showAccessibleLabelInLegend', false)) {
    legendProps.renderLegendItem = (theme, item, i) => (
      <ChartLegendItem item={item} theme={theme} score={i} showAccessibleLabel />
    );
  }

  if (hasInvalidData && handleInvalidValueAsSuppressed) {
    chartPropsData = replaceInvalidData(chartPropsData, 0.5);
  }

  chartPropsData = handlePopoverPlacement(chartPropsData, config);

  return (
    <div>
      {titleTemplate && <BlockTitle title={template(titleTemplate, score)} weight="light" />}
      <BarChartWithLegend
        className={cx(styles.chart, className, { [styles.rightLegend]: legendAlignment === 'right' })}
        theme={get(config, 'options.theme', 'default')}
        chartProps={{
          data: chartPropsData,
          name: chartName,
          chartOptions
        }}
        legendPlacement={get(config, 'options.legendPlacement', 'top')}
        legendProps={legendProps}
      />
    </div>
  );
}

const commonProps = {
  defaultChartOptions: PropTypes.object,
  config: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  node: PropTypes.object,
  additionalChartAdapters: PropTypes.object
};

KdeBarChart.propTypes = {
  className: PropTypes.string,
  horizontal: PropTypes.bool,
  ...commonProps
};

KdeBarChart.commonProps = commonProps;

export default KdeBarChart;
/* eslint-enable complexity */
