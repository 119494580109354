import React from 'react';
import PropTypes from 'prop-types';
import isObject from 'lodash/isObject';
import CuiRating from 'cui/lib/components/Rating';

import template from '../../../helpers/template';
import format from '../../../helpers/formatter';

const RATINGS = {
  H: 3,
  M: 2,
  L: 1
};

export const RatingFormatter = ({ value = 'L', valueKey, templateString, color }) => {
  const ratingValue = isObject && valueKey ? value[valueKey] : value;
  const ratingBucketKey = (ratingValue || value).toUpperCase();
  const compiledValue = template(templateString, { value: format('usage', ratingBucketKey) });

  return (
    <CuiRating total={3} filled={RATINGS[ratingBucketKey]} label={compiledValue} color={color} />
  );
};

RatingFormatter.propTypes = {
  cellText: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  valueKey: PropTypes.string,
  templateString: PropTypes.string,
  color: PropTypes.string
};

RatingFormatter.defaultProps = {
  cellText: '',
  value: '',
  valueKey: 'bucket',
  templateString: '{{value}}'
};

export default RatingFormatter;
