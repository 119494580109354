import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';

import CuiForm from 'cui/lib/components/Form';

// it's going to change quickly, so skipping rule
// eslint-disable-next-line react/prefer-stateless-function
class Select extends PureComponent {
  render() {
    const {
      organizations,
      onChange
    } = this.props;

    const options = organizations.map(organization => ({ label: organization.name, value: organization.id }));

    return (
      <CuiForm.Select
        options={options}
        multi
        onChange={ids => onChange(filter(organizations, org => ids.includes(org.id)))}
      />
    );
  }
}

const organizationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired
});

Select.propTypes = {
  organizations: PropTypes.arrayOf(organizationShape),
  selectedOrganizations: PropTypes.arrayOf(organizationShape),
  onChange: PropTypes.func.isRequired
};

export default Select;
