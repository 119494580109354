import { useQuery, useQueryClient } from 'react-query';

import { frameworkQuery } from 'store/backend/queries/framework';
import request from 'helpers/request';
import allDataPoints from 'helpers/allDataPoints';
import { transformScoresFromPortalDataService } from 'helpers/portalDataServiceHelpers';

const buildQueryFn = queryClient => async ctx => {
  const [_key, organizations, year] = ctx.queryKey;

  const framework = await queryClient.fetchQuery(frameworkQuery(year));
  const dataPoints = allDataPoints(framework, { metadata: { editable: true } });
  const ids = organizations.map(org => org.organization_id);

  const requestData = {
    year, // only allow to see score for current school year
    organization_ids: ids,
    data_points: dataPoints.map(dp => ({
      slug: dp?.metadata?.collectorSource?.slug ?? dp?.slug,
      key: dp.node_path,
      dimensions: [] // collected data points don't have dimensions
    }))
  };

  const data = await request(`${HOSTS.PORTAL_DATA}/scores`, {
    method: 'POST',
    body: JSON.stringify(requestData)
  });

  const orgMapping = Object.fromEntries(
    organizations.map(org => [org.organization_id, org.id])
  );
  return transformScoresFromPortalDataService(data, dataPoints, orgMapping, year);
};

const useEditableScoresQuery = (organizations = [], year) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: ['editableScores', organizations, year],
    queryKeyHashFn: ([name, orgs, ...rest]) => [name, orgs.map(org => org.id), ...rest],
    queryFn: buildQueryFn(queryClient),
    enabled: organizations.length > 0
  });
};

export default useEditableScoresQuery;
