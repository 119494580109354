import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import LanguageToggle from './LanguageToggle';

// Note that feature flags are not mutual exclusive.
// When there are multiple feature guarded translation services are on, for example,
// if we enable google and yandex translation at the same time,
// This component only render the first one found in this array.

const FEATURE_FLAGED_SERVICES = [
  {
    key: 'KdeLanguageTranslationGoogle',
    service: 'google'
  },
  {
    key: 'KdeLanguageTranslationYandex',
    service: 'yandex'
  }
];

// Encapsulate feature flag logics in this component so that we don't have to
// repeat this logics in different pages that use LanguageToggle
export default function TranslationToggle({ featureFlags, ...otherProps }) {
  const translationService = FEATURE_FLAGED_SERVICES.find(fg => get(featureFlags, fg.key));

  return (
    <LanguageToggle
      service={get(translationService, 'service')}
      {...otherProps}
    />
  );
}

TranslationToggle.propTypes = {
  featureFlags: PropTypes.object
};
