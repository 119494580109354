/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import when from '../../helpers/when';
import { containMoreThan } from '../../helpers/adapterHelpers';

const helpers = {
  containMoreThan
};

export default function ShowComponentWhen(props) {
  if (props.when) {
    const currentScore = get(props.scores, '[0].value', {});
    const isDevelopmentEnv = process.env.NODE_ENV === 'development';
    const isStagingEnv = process.env.NODE_ENV === 'staging';

    if (
      !when(props.when, {
        development: isDevelopmentEnv,
        staging: isStagingEnv,
        ...props,
        currentScore,
        ...helpers
      })
    ) {
      return null;
    }
  }

  return props.children;
}

ShowComponentWhen.propTypes = {
  children: PropTypes.node,
  when: PropTypes.string,
  scores: PropTypes.arrayOf(PropTypes.object)
};
/* eslint-enable no-unused-vars */
