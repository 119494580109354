import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import uniqWith from 'lodash/uniqWith';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

const FIELDS_TO_COMPARE = [
  'remote_organization_id',
  'framework_tree_node',
  'start_at',
  'end_at'
];

// @param currentScores, {array}, array of score objects
// @param newScores, {array}, array of score objects
// @return combined array of scores without duplicates which are defined by FIELDS_TO_COMPARE
// An example score will look like
// {
//   remote_organization_id: 1000,
//   framework_tree_node: { node_path: 'kde.finance_domain.spending' },
//   start_at: '2019-01-01',
//   end_at: '2019-12-31',
//   value: 1000
// }

export default function mergeScoreArrays(currentScores, newScores = []) {
  // Return newScores if currentScores is empty
  if (isEmpty(currentScores)) {
    return newScores;
  }

  const allScores = newScores.concat(currentScores);
  // Remove duplicate scores defined by the FIELDS_TO_COMPARE
  // newScores take precedence over currentScores
  return uniqWith(allScores, (scoreToCompare, currentScore) => (
    isEqual(pick(scoreToCompare, FIELDS_TO_COMPARE), pick(currentScore, FIELDS_TO_COMPARE))
  ));
}

/**
 * Determines if a score's value is empty. The value must already have been normalized (i.e. unserialized, not wrapped in a { value } object, etc.).
 * The criteria that determine a value's emptiness varies by the node's field type. For multi-number fields, every input must have a value or the
 * entire score is empty. For checkbox fields, every section must have a value or the entire score is empty.
 */
export const isValueEmpty = (value, node) => {
  if (!value || isEmpty(value)) {
    return true;
  }

  if (node.field_type === 'number' && node.field_inputs) {
    return node.field_inputs.every(({ key }) => !value[key]);
  } else if (node.field_type === 'checkbox') {
    const sections = get(node, 'scoring_options.format_options.sections', []);
    return isEmpty(sections) || sections.some(({ has_other: hasOther, label_map: labelMap, section_slug: sectionSlug }) => {
      const { others = [], selected_options: selectedOptions = {} } = get(value, sectionSlug, {});
      return labelMap.every(option => !selectedOptions[option.value]) && (!hasOther || isEmpty(others));
    });
  } else if (get(node, 'metadata.metric_options')) {
    // scores with metrics are considered non-empty if any of their corresponding values is non-empty or if there is only one metric item.
    // that last part is to allow us to maintain the same logic the kdeTextNumber adapter does, which is needed because a lot of non-metricized
    // things gained metric_options fields when we added kdeTextNumber charts to them. at some point we should either combine the common bits
    // for extracting scores with metrics in both kdeTextNumber's adapter and the collector helpers, or we should teach kdeTextNumber to use
    // something different to decide what fields to render.
    return node.metadata.metric_options.length > 1 && node.metadata.metric_options.every(({ key }) => get(value, key) == null);
  } else {
    return false;
  }
};

/**
 * Given a score and its data point node, returns the value of the score, parsing it if it is provided as JSON, and returning the nested value
 * property value if that is the designated data shape for that node's field type. Returns null if there is no value for this data point, or if it
 * can be determined to be "empty."
 */
export const normalizeScoreValue = (score, node) => {
  let value = get(score, 'value.score', null) || get(score, 'value', null);

  if (node.field_type) {
    if (node.field_type !== 'text' && typeof value === 'string') {
      value = JSON.parse(value);
    }

    if (node.field_type !== 'text' && (node.field_type !== 'number' || !node.field_inputs)) {
      value = get(value, 'value', null);
    }
  } else {
    value = get(value, 'value', value);
  }

  return isValueEmpty(value, node) ? null : value;
};

// @param scores {array}, array of scores
// @param filters {object}, object of filter options
// @return first score filtered by filters
export function currentScoreForOrg(scores, filters) {
  const filteredScores = filter(scores, filters);
  return isEmpty(filteredScores) ? null : filteredScores[0];
}
