import groupBy from 'lodash/groupBy';

import { newStatusText } from '../statusText';
import { approvalStatus } from './utils';

const approvalStatusByOrgDomains = (organizations, framework, statuses, domains) =>
  (organizations || []).reduce((acc, org) => {
    acc[org.id] = {};

    domains.forEach(domain => {
      const filteredStatuses = statuses.filter(status => status.section_slug.indexOf(domain.node_path) === 0);
      const groupedStatuses = groupBy(filteredStatuses, 'clarity_organization_id');
      const sectionStatusesForOrg = groupedStatuses?.[org.id] ?? [];
      const approvedCount = sectionStatusesForOrg.filter(status => status.status === 'approved').length;
      const flaggedCount = sectionStatusesForOrg.filter(status => status.status === 'flagged').length;

      acc[org.id][domain.node_path] = {
        status: approvalStatus(
          domain.node_path,
          org,
          newStatusText(flaggedCount, approvedCount, sectionStatusesForOrg.length)
        )
      };
    });

    return acc;
  }, {});

export default approvalStatusByOrgDomains;
