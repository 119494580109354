import React from 'react';
import PropTypes from 'prop-types';

import CuiTag from 'cui/lib/components/Tag';

const MixedTagFormatter = (props) => {
  const { value, labelMap, themeMap } = props;

  const mapTheme = themeMap[value] ? themeMap[value] : '';
  const mapLabel = () => {
    if (value === null) {
      return 'N/A';
    } else if (labelMap[value]) {
      return labelMap[value];
    }
    return value;
  };

  return mapTheme ? (
    <CuiTag theme={mapTheme}>
      {mapLabel()}
    </CuiTag>
  ) : <div>{value}</div>;
};

MixedTagFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  labelMap: PropTypes.object,
  themeMap: PropTypes.object
};

MixedTagFormatter.defaultProps = {
  value: '',
  labelMap: {},
  themeMap: {}
};

export default MixedTagFormatter;
