import React from 'react';
import * as Sentry from '@sentry/react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
// import { hot } from 'react-hot-loader';
import { ConnectedRouter } from 'connected-react-router';
import { QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { history, sagaMiddleware, store } from './store';
import rootSaga from './store/sagas';
import Entry, { config } from './Entry';

Sentry.init({ dsn: process.env.SENTRY_DSN });

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: Sentry.captureException
  }),
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      staleTime: 15 * 60 * 1000,
      cacheTime: 15 * 60 * 1000,
      useErrorBoundary: true
    }
  }
});

sagaMiddleware.run(rootSaga, config);

// Note: In Cypress testing environment we push snapshot of the final state straight to the store,
// so there's no need to fire actions in the middleware and analytics.
let previousPath;

if (!window.Cypress) {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);

  history.listen(({ pathname }) => {
    // NOTE: not sure why this handler got called twice when a route changes
    // ADD this condition here to prevent the handler from getting called twice for the same
    // route change
    if (previousPath !== pathname) {
      previousPath = pathname;
      ReactGA.pageview(pathname);
    }
  });
}

const Root = () => (
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Entry />
      </ConnectedRouter>
    </Provider>

    {process.env.NODE_ENV === 'development' && <ReactQueryDevtools />}
  </QueryClientProvider>
);

// TODO: reenable when CUI supports hot module replacement
// export default hot(module)(Root);
export default Root;
