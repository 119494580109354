import React from 'react';
import PropTypes from 'prop-types';

import { ORG_TYPES } from '../../constants';

const Address = ({ orgType, address }) => (
  <address>
    {address.street}
    <br />
    {address.city}, {address.state} {address.zip}
    <br />
    <br />
    Phone: {address.phone}
    <br />
    Fax: {address.fax}
    <br />
    <br />
    {orgType === ORG_TYPES.SCHOOL && <div>School Code: {address.schoolCode}</div>}
    {orgType === ORG_TYPES.DISTRICT && <div>District Code: {address.districtCode}</div>}
  </address>
);

Address.propTypes = {
  orgType: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    schoolCode: PropTypes.string,
    districtCode: PropTypes.string
  }).isRequired
};

Address.defaultProps = {
  address: {}
};

export default Address;
