/* eslint-disable no-eval */
import isString from 'lodash/isString';
import filter from 'lodash/filter';
import find from 'lodash/find';

import template from '../template';

/**
 * Note: in order to work with `this` context, it must be a regular, non-arrow function
 */
function when(statement = 'true', props = {}, defaultValue = false) {
  if (!isString(statement)) {
    throw new TypeError('"when" statement argument must be of type "String"');
  }
  // FIXME: Add some verification/sanitation of statement
  return eval(template.call(this, statement, props)) || defaultValue;
}

function constructWhen(func) {
  return function (collection = [], props = {}, condition = '') {
    return func(collection, item => when(condition || item.when, { ...item, ...props }));
  };
}

/**
 * Filters an array of objects based on the 'when' property
 */
export const filterWhen = constructWhen(filter);

/**
 * Finds the first element in an array of objects based on the 'when' property
 */
export const findWhen = constructWhen(find);

export default when;
