import qs from 'qs';
import deepMap from 'deep-map';

const paramsParser = (searchString = '', params = {}) => {
  const parsedString = qs.parse(searchString, { ignoreQueryPrefix: true });
  const deeplyParsedString = deepMap(parsedString, (value) => {
    try {
      return JSON.parse(value);
    } catch (e) {
      return value;
    }
  });
  let filters;
  try {
    filters = deeplyParsedString.filters ? { filters: JSON.parse(deeplyParsedString.filters) } : {};
  } catch (e) {
    filters = {};
  }
  return {
    ...params,
    ...deeplyParsedString,
    ...filters
  };
};

export default paramsParser;
