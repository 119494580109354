import React from 'react';
import round from 'lodash/round';
import startCase from 'lodash/startCase';

import template from '../../helpers/template';
import { valueToLocaleString } from '../../helpers/template/format';

import { sortByExactOrders } from '../sortBy';
import { getParentData, getDataForOrganization } from '../../helpers/portalDataServiceHelpers';
import FILTERS_OPTIONS from '../../helpers/data_dropdown_helper/filtersOptions';

const DEFAULT_ORDER = [
  'school',
  'district',
  'state'
];

const EMPTY_DATA_PLACEHOLDER = 'No Data Reported';

const filterByDimensions = (chartData, dimensions, options) => {
  const scoreKey = options?.node?.slug;

  const chartDataByOrgType = Object.entries(chartData).map(([key, value]) => ({ key: String(key).toLowerCase(), ...value }));

  return dimensions.map((dimensionKey) => {
    const dimensionDataByOrgType = chartDataByOrgType.map(organizationData =>
      ({
        ...(organizationData?.[scoreKey]?.dimensions?.[dimensionKey] ?? organizationData[scoreKey]),
        key: organizationData.key
      })
    );

    return dimensionDataByOrgType.map(dimension => ({
      ...dimension?.score,
      key: dimension.key,
      dimension: dimensionKey
    }));
  });
};

const getChartData = (options) => {
  const {
    currentOrganization,
    node,
    allScores
  } = options;

  return {
    ...getParentData(allScores, currentOrganization, node),
    [currentOrganization.entity_type]: getDataForOrganization(allScores, node, currentOrganization)
  };
};

export const getTopLabel = (value, denominator) => {
  if (value === undefined) return EMPTY_DATA_PLACEHOLDER;
  if (denominator === '*' || value === '*') return '*';
  return '';
};

export const getPopoverItem = (value, denominator) => {
  const topLabel = getTopLabel(value, denominator);
  if (topLabel) return <span className="legend-label">{topLabel}</span>;
  if (denominator === '0') return <span className="legend-label">0 students</span>;

  return (
    <div>
      <span className="legend-value">
        {value}%
      </span>
      <span className="legend-label">
        <em className="u-indent">
          of {valueToLocaleString(denominator)} students
        </em>
      </span>
    </div>
  );
};

const adapter = (currentOrgScore, options) => {
  const chartData = getChartData(options);
  const dimensions = options?.chartConfig?.options?.dimensions ?? [];
  const scoreDataByOrgType = filterByDimensions(chartData, dimensions, options);
  const denominatorKey = 'total_count';
  const valueKey = 'free_lunch_count';

  return scoreDataByOrgType.map((scores) => {
    const sortedData = sortByExactOrders(scores, DEFAULT_ORDER, 'key');
    const data = sortedData.map((scoreData, index) => {
      const isValidValue = isFinite(scoreData[valueKey]) && isFinite(scoreData[denominatorKey]);
      const value = isValidValue ? round((scoreData[valueKey] / scoreData[denominatorKey]) * 100, 1) : undefined;

      return {
        value: isValidValue ? Math.max(value, 0.5) : 0.5,
        colorIndex: index,
        label: startCase(scoreData.key),
        topLabel: getTopLabel(value, scoreData[denominatorKey]),
        popoverItem: getPopoverItem(value, scoreData[denominatorKey]),
        denominator: scoreData[denominatorKey]
      };
    });

    return {
      label: template(
        '{{ get(FILTERS_OPTIONS, [dimension, "text"], dimension) }}',
        { FILTERS_OPTIONS, dimension: scores[0].dimension }
      ),
      value: data
    };
  });
};

export default adapter;
