import React from 'react';
import Icon from 'cui/lib/components/Icon';

const FamilyInfoBanner = () => (
  <>
    <Icon className="u-color-kde-text-tint-2 cui-list-icon" name="kde-families" />
    <span className="cui-list-text">Families for up-to-date detailed information on your child’s performance, log into the <br /><a href="https://www.infinitecampus.com/audience/parents-students" target="_blank" rel="noopener noreferrer">Infinite Campus app or web portal. </a></span>
  </>
);
export default FamilyInfoBanner;
