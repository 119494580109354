import React from 'react';
import PropTypes from 'prop-types';

import List from 'cui/lib/components/List';

import DetailsListItem from '../DetailsListItem';

const DetailsList = (props) => {
  const { parentItems, highestItemValue, theme } = props;

  return (
    <List tree>
      {
        parentItems.map((parentItem, i) => (
          <List.Item key={parentItem.label}>
            <DetailsListItem
              {...props}
              colorIndex={parentItem.colorIndex}
              label={parentItem.label}
              theme={theme}
              isParentItem
              {...parentItem}
            />
            <List>
              {
                parentItem.items.map((item, itemIndex) => (
                  <List.Item key={item.label}>
                    <DetailsListItem
                      {...props}
                      colorIndex={highestItemValue - ((3 * i) + itemIndex)}
                      theme={theme}
                      label={parentItem.label}
                      {...item}
                    />
                  </List.Item>
                ))
              }
            </List>
          </List.Item>
        ))
      }
    </List>
  );
};

DetailsList.propTypes = {
  parentItems: PropTypes.arrayOf(
    PropTypes.object
  ),
  highestItemValue: PropTypes.number,
  theme: PropTypes.string
};

DetailsList.defaultProps = {
  parentItems: [],
  highestItemValue: 9,
  theme: ''
};

export default DetailsList;
