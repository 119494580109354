import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Modal from 'cui/lib/components/Modal';
import Field from 'cui/lib/components/Form/Field';

import styles from './OrgProfile.module.scss';

class ClassificationDetails extends Component {
  state = { isModalOpen: false }

  openModal = () => this.setState({ isModalOpen: true })

  closeModal = () => this.setState({ isModalOpen: false })

  popoverProps = { appendToBody: false }

  render() {
    return (
      <Field
        description={this.props.description}
        label={<h4 className={styles.title}>Classification</h4>}
        popoverContent={(
          <div>
            State classification designation. Schools can be classified as either a public school (A1)
            a public program (A2, A3, A5, A6), a laboratory or training school operated by a college (B1),
            dual enrollment postsecondary or college-level program operated by college or university (B2),
            state-operated ATC (C2) or a state department of education-operated school (D1).
            <Button size="small" onClick={this.openModal}>More info</Button>
          </div>
        )}
        popoverProps={this.popoverProps}
      >
        {this.props.title}
        <Modal isOpen={this.state.isModalOpen} onHide={this.closeModal}>
          <Modal.Header title="More Information about School Classifications" />
          <Modal.Body>
            <p>
              <b>Public School:</b><br />
              <b>A1 -</b> A school under administrative control of a principal or head teacher and eligible to
              establish a school-based decision making council. An A1 school is not a program operated by,
              or as a part of, another school.
            </p>
            <p>
              <b>Public Program:</b><br />
              <b>A2 -</b> District-operated, career and technical education center (CTC), where the membership
              is counted in an A1 school. <br />
              <b>A3 -</b> District-operated, special education program (all enrollments should be special education). <br />
              <b>A4 -</b> District-operated, state-funded preschool program (includes blended preschool/Head Start). <br />
              <b>A5 -</b> District-operated alternative program with no definable attendance boundaries designed to remediate
              academic performance, improve behavior or provide an enhanced learning experience. <br />
              <b>A6 -</b> KECSAC funded programs serving state children agency <br />
              <b>A7 -</b> Miscellaneous schools set up for tracking of Home/Hospital or Summer School,
              membership is included in the A1 School
            </p>
            <p>
              <b>Non-Public Program/School:</b><br />
              <b>B1 -</b> Laboratory or training school in state operated by college or university. <br />
              <b>B2 -</b> Dual enrollment postsecondary or college-level course program operated by a college or university. <br />
              <b>C1 -</b> College or university operated career and technical education schools. <br />
              <b>C2 -</b> State operated career and technical education area centers (ATC). <br />
              <b>D1 -</b> State Department of Education operated schools (blind & deaf). <br />
              <b>F1 -</b> Federal Dependent Schools (Ft. Knox & Ft. Campbell). <br />
              <b>F2 -</b> Federally funded Job Corp. <br />
              <b>J1 -</b> Roman Catholic operated non-public schools/programs. <br />
              <b>M1 -</b> Other religious operated non-public schools/programs. <br />
              <b>M2 -</b> Seventh Day Adventist operated non-public schools/programs. <br />
              <b>R1 -</b> Secular non-public schools/programs.
            </p>
          </Modal.Body>
        </Modal>
      </Field>
    );
  }
}

ClassificationDetails.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string
};

export default ClassificationDetails;
