import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { get, isEmpty, isEqual, difference, intersection } from 'lodash';

import Label from 'cui/lib/components/Label';
import Icon from 'cui/lib/components/Icon';

import MultiSelect from '../MultiSelect';
import dropdownItems from '../../helpers/data_dropdown_helper';

import { currentScore, currentChartConfig } from './helpers';
import styles from './DataDropdown.module.scss';

function toggleAriaLabel(nodeName, label, selectedItemText) {
  return [nodeName, label, selectedItemText].filter(str => str).join(', ');
}

function transformDropdownItems(items) {
  return items.reduce((result, item) => {
    result.push({
      ...item,
      label: item.text,
      value: item.filterKey
    });

    if (item.subList) {
      result.push(
        ...item.subList.map(listItem => ({
          ...listItem,
          label: listItem.text,
          value: listItem.filterKey,
          parentValue: item.filterKey
        }))
      );
    }

    return result;
  }, []);
}

function getSelectedMultiSelectValues(options, selectedValues) {
  const values = [];
  const valuesToExclude = [];

  selectedValues.forEach((value) => {
    const option = options.find(item => item.value === value);

    if (!option) return;

    if (option.subList) {
      // handle option group click
      const childValues = options
        .filter(item => item.parentValue === value)
        .map(item => item.value);
      const notSelectedChildValues = childValues
        .filter(childValue => !selectedValues.includes(childValue));

      if (notSelectedChildValues.length) {
        // if there are notSelectedChildValues and option group was clicked
        // select all notSelectedChildValues
        values.push(...notSelectedChildValues);
      } else {
        // if all children was selected and option group was clicked
        // it means that we need to deselect all child options
        valuesToExclude.push(...childValues);
      }
    } else {
      // handle simple option
      values.push(option.value);
    }
  });

  return difference(values, valuesToExclude);
}

// We need to filter select values to contain values of only one lastly selected group
function filterSelectedValues(options, selectedValues, lastlySelectedValues) {
  const lastlySelectedValue = lastlySelectedValues[0];
  const lastlySelectedOption = options.find(item => item.value === lastlySelectedValue);

  if (!lastlySelectedOption) return selectedValues;

  let allowedValues;

  if (lastlySelectedOption.subList) {
    allowedValues = options.filter(item => item.parentValue === lastlySelectedOption.value).map(item => item.value);
  } else if (lastlySelectedOption.parentValue) {
    allowedValues =  options.filter(item => item.parentValue === lastlySelectedOption.parentValue).map(item => item.value);
  } else {
    allowedValues = [lastlySelectedValue];
  }

  return intersection(selectedValues, allowedValues);
}

export default class DataMultiDropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      items: []
    };
  }

  componentDidMount() {
    const { config, node, organization, tab, scores } = this.props;
    this.setDropdownItems({ config, node, organization, scores, tab });
  }

  componentWillReceiveProps(newProps) {
    const { config, node, organization, tab, scores } = this.props;

    if (
      newProps.config !== config ||
      newProps.node !== node ||
      newProps.organization !== organization ||
      newProps.tab !== tab ||
      newProps.scores !== scores
    ) {
      this.setDropdownItems({
        config: newProps.config,
        node: newProps.node,
        scores: newProps.scores,
        organization: newProps.organization,
        tab: newProps.tab
      });
    }
  }

  onMultiSelectChange = (selectValue, prevSelectValue) => {
    const { index, tab } = this.props;
    const { items } = this.state;
    const lastlySelectedValues = difference(selectValue, prevSelectValue);

    if (isEqual(selectValue, prevSelectValue)) return;

    const selectedValues = getSelectedMultiSelectValues(items, selectValue);
    const filteredValues = filterSelectedValues(items, selectedValues, lastlySelectedValues);

    this.props.onChange(filteredValues, index, tab.name);
  }

  onClearAll = () => {
    const { items } = this.state;
    this.setDefaultOption(items);
  }

  setDropdownItems({ config, node, organization, scores, tab }) {
    const chartConfig = currentChartConfig(node, tab);
    const score = currentScore({ scores, chartConfig, node, organization });
    const items = transformDropdownItems(dropdownItems(config, node, organization, chartConfig, score));
    const { items: prevItems } = this.state;

    if (isEqual(items, prevItems)) return;

    this.setState({ items });
    this.setDefaultOption(items);
  }

  getDefaultOption(items) {
    return isEmpty(items) ? null : [items[0].value];
  }

  setDefaultOption(items) {
    const { index, tab } = this.props;
    return !isEmpty(items) && this.props.onChange(this.getDefaultOption(items), index, tab.name);
  }

  isOptionSelected = (option) => {
    const { index, selectedFilter } = this.props;
    const { items } = this.state;
    const currentValue = get(selectedFilter, index, []);

    if (!option.subList) return currentValue.includes(option.value);

    const childValues = items
      .filter(item => item.parentValue === option.value);
    const selectedChildValues = childValues
      .filter(child => currentValue.includes(child.value));

    return childValues.length === selectedChildValues.length;
  }

  renderOptionItem = (option) => {
    const isSelected = this.isOptionSelected(option);
    const className = cn('cui-flexbar', {
      [styles.subListPadding]: Boolean(option.parentValue)
    });

    return (
      <div className={styles.multiDropdownItem}>
        <span className={className}>
          <span className={cn('cui-flexbar-item', { [styles.hidden]: !isSelected })}>
            <Icon name="bb-check" className="cui-dropdown-menu-selectedIcon" />
          </span>
          <span className="cui-flexbar-item cui-flexbar-item_grow">{option.label}</span>
        </span>
      </div>
    );
  }

  render() {
    const { node, index, selectedFilter } = this.props;
    const { items } = this.state;
    const label = get(this.props.config, 'label');
    const containerStyles = this.props.config?.styles;

    // Do not show dropdown if there is less than 2 item
    if (isEmpty(items)) return null;

    return (
      <div className={styles.dataDropdownWrapper} style={containerStyles}>
        {label && <Label value={label} />}
        <MultiSelect
          id={node.slug + index}
          value={get(selectedFilter, index)}
          className={styles.multiDropdown}
          options={items}
          onChange={this.onMultiSelectChange}
          onClearAll={this.onClearAll}
          optionRenderer={this.renderOptionItem}
          aria-label={toggleAriaLabel(this.props.node.name, label)}
          closeOnSelect={false}
          removeSelected={false}
        />
      </div>
    );
  }
}

DataMultiDropdown.propTypes = {
  config: PropTypes.object,
  index: PropTypes.number,
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  selectedFilter: PropTypes.object
};
