import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'cui';
import cx from 'classnames';

import styles from './ScoresBlock.module.scss';

const ScoresBlock = ({ title, subtitle, children, className }) => (
  <div className={styles.ScoreBlock}>
    <Heading level={2} className={styles.title}>{title}</Heading>
    {subtitle && <Heading level={3} weight="medium" className={styles.subtitle}>{subtitle}</Heading>}
    <div className={cx(styles.ScoreBlock_scores, className)}>
      {children}
    </div>
  </div>
);

ScoresBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string
};

export default ScoresBlock;
