import get from 'lodash/get';
import map from 'lodash/map';

const adapter = (options) => {
  const {  scores } = options;
  const chartData =  scores && map(scores, (value, key) => (
    {
      label: get(value, 'subject'),
      key,
      value: map(value.details, (scoreValue, scoreKey) => (
        {
          value: scoreValue.percent,
          colorIndex: scoreKey
        }
      ))
    }
  ));
  return chartData;
};

export default adapter;
