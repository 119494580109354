import React from 'react';
import PropTypes from 'prop-types';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { findNodeByNodePath } from './frameworkHelpers';
import orgCanAccessCollectorDataPoint from './orgCanAccessCollectorDataPoint';
import allDataPoints from './allDataPoints';
import { getOrgIdFromOrganization } from './portalDataServiceHelpers';
import { normalizeScoreValue } from './scoreHelpers';

export function getFieldPrompt(node) {
  return get(node, 'field_prompt', get(node, 'name', ''));
}

const VALIDATIONS = {
  currentDate: () => (new Date())
};

export function validationMeta(validations) {
  const validationProps = {};
  const validationErrors = {};

  forEach(validations, (rule, key) => {
    if (rule.value) {
      validationProps[key] = rule.value;
    }
    if (rule.valueFunc && VALIDATIONS[rule.valueFunc]) {
      validationProps[key] = VALIDATIONS[rule.valueFunc]();
    }
    validationErrors[key] = (() => rule.errorMessage);
  });

  return {
    props: validationProps,
    errors: validationErrors
  };
}

/**
 * Given a score, the framework tree, and the current organization, can convert a score to a set of meaningful data including the node it is
 * associated with, its normalized value (or null if it can be determined to not be reported), and whether it is "required" (i.e. the data point is
 * applicable to the given organization). Returns null if no score was provided or if its associated node cannot be found in the framework.
 */
export const extractScoreInfo = (scores, nodePath, framework, organization) => {
  const node = findNodeByNodePath(framework, nodePath);
  const orgId = getOrgIdFromOrganization(organization);
  const score = get(scores, [nodePath, orgId, 'scores', 0]);

  if (!node) {
    return null;
  }

  return {
    node,
    value: normalizeScoreValue(score, node),
    required: orgCanAccessCollectorDataPoint(node, organization)
  };
};

/**
 * Given a score info object (see extractScoreInfo), returns an array of radio options in the shape of
 * { label: string, value: string, selected: boolean }.
 */
export const getRadioOptions = ({ node, value: selectedOption }) => {
  const options = get(node, 'metadata.field_options.options', []);
  return options.map(({ label, value }) => ({ label, value, selected: value === selectedOption }));
};

/**
 * Given a score info object (see extractScoreInfo), returns an array of checkbox sections in the shape of
 * { slug: string, title: string|null, options: Option[] }, where an option is in the shape of
 * { label: string, value: node, selected: boolean }.
 */
export const getCheckboxOptions = ({ node, value: scoreValue }) => {
  const sections = get(node, 'scoring_options.format_options.sections', []);
  return sections.map(({ has_other: hasOther, label_map: labelMap, name = null, section_slug: sectionSlug }) => {
    const options = labelMap.map(({ label, value }) => ({
      label,
      value,
      selected: get(scoreValue, [sectionSlug, 'selected_options', value], false)
    }));

    if (hasOther) {
      const others = get(scoreValue, [sectionSlug, 'others']);
      options.push({
        label: isEmpty(others) ? 'Other' : <span><strong>Other</strong>: {others.join(', ')}</span>,
        value: JSON.stringify(others),
        selected: !isEmpty(others)
      });
    }

    return { slug: sectionSlug, title: name, options };
  });
};

/**
 * Given a score info object (see extractScoreInfo), returns an array of inputs in the shape of
 * { key: string, label: string, value: string }.
 */
export const getNumberSetData = ({ node, value: scoreValue }) => {
  const inputs = get(node, 'metadata.field_inputs', []);
  return inputs.map(({ key, label }) => ({
    key,
    label,
    value: scoreValue[key]
  }));
};

export const filterLeavesAndTheirAncestors = (framework, predicate) => {
  if (!framework || !framework.items) {
    return null;
  }

  if (get(framework, 'metadata.alwaysAccessible', false)) {
    return framework;
  }
  const items = framework.items
    .reduce((filteredItems, node) => {
      let item;
      if (!node.items || node.items.length === 0) {
        // Is a leaf node
        if (predicate(node)) {
          item = node;
        }
      } else {
        item = filterLeavesAndTheirAncestors(node, predicate);
      }

      if (item) {
        filteredItems.push(item);
      }
      return filteredItems;
    }, []);

  if (items.length > 0) {
    return {
      ...framework,
      items
    };
  } else {
    return null;
  }
};

// @param framework {object}, kde framework tree
// @return array of editable data points
export function allEditableDataPoint(framework) {
  return allDataPoints(framework, { editable: true });
}

export const scoreInfoPropType = PropTypes.shape({
  node: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  required: PropTypes.bool.isRequired
});

export default {
  getFieldPrompt
};
