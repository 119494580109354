/* eslint no-unused-vars: off */
import when from '../when';
import template from '../template';

import { getOrgIdFromOrganization } from '../portalDataServiceHelpers';
import { isApprovalSite } from '../approvalSite';
import { isPreviewSite } from '../previewSite';

const restrictionValidators = {
  organizations(currentOrganizationId, organizations) {
    return organizations
      .map(organizationId => String(organizationId))
      .includes(currentOrganizationId);
  },
  alwaysRestrict() { return true; },
  isPortal() { return !isApprovalSite(); },
  isPublicPortal() { return !isApprovalSite() && !isPreviewSite(); },
  isPublicSite() { return !isPreviewSite(); },
  isApprovalSite() { return isApprovalSite(); }
};

export function findRestrictionProps(currentOrganization, restrictAccess) {
  const currentOrganizationId = getOrgIdFromOrganization(currentOrganization);
  return restrictAccess.find(({ validatorName, organizations, ...restrictionProps }) => {
    const validator = restrictionValidators[validatorName];
    return validator && when(restrictionProps.when, { validator, currentOrganizationId, organizations });
  });
}

export function emptyStateProps(currentOrganization, { buttonHref, ...props }, history) {
  if (buttonHref) {
    props.buttonHref = history.createHref({
      pathname: template(buttonHref, { organization: currentOrganization })
    });
  }

  return props;
}
