import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import DataPointMenuAction from '../dataPointMenuAction';
import { currentChartConfig } from '../dataPointMenuAction/helpers';
import { getDropdownConfigs } from '../../helpers/data_dropdown_helper';
import styles from './styles.module.scss';

export class MenuActionBlock extends React.PureComponent {
  constructor(props) {
    super(props);

    const chartConfig = currentChartConfig(props.node, props.tab);
    this.state = {
      dropdownConfigs: getDropdownConfigs(props.node, chartConfig),
      scores: props.scores
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.node !== this.props.node || nextProps.tab !== this.props.tab) {
      const chartConfig = currentChartConfig(nextProps.node, nextProps.tab);
      this.setState({
        dropdownConfigs: getDropdownConfigs(nextProps.node, chartConfig)
      });
    }

    if (!isEqual(nextProps.scores, this.props.scores)) {
      this.setState({ scores: nextProps.scores });
    }
  }

  render() {
    const { onChange, node, organization, tab, currentItem, viewingDropdownFilters } = this.props;
    const { dropdownConfigs, scores } = this.state;

    if (dropdownConfigs.length === 0) return null;

    const selectedFilter = get(viewingDropdownFilters, tab.name);

    return (
      <div className={styles.menuActionContainer}>
        {dropdownConfigs.map((config, index) =>
          <DataPointMenuAction
            key={`${node.slug}-${tab.name}-${index}`}
            config={config}
            index={index}
            node={node}
            organization={organization}
            scores={scores}
            tab={tab}
            currentItem={currentItem}
            selectedFilter={selectedFilter}
            onChange={onChange}
          />
        )}
      </div>
    );
  }
}

MenuActionBlock.propTypes = {
  onChange: PropTypes.func.isRequired,
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  tab: PropTypes.object,
  currentItem: PropTypes.object,
  viewingDropdownFilters: PropTypes.object
};

export default MenuActionBlock;
