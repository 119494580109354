import { createSelector } from 'reselect';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { transformScoresFromPortalDataService } from '../../helpers/portalDataServiceHelpers';
import { allEditableDataPoint } from '../../helpers/collectorHelpers';

export const portalScoreSelector = state => get(state, 'module.scoresFromPortalDataService', {});

export const frameworkSelector = state => get(state, 'framework.kde');

export const editableVariableSelector = createSelector(
  frameworkSelector,
  framework => allEditableDataPoint(framework).map(item => (
    {
      node_path: item.node_path,
      portal_data: {
        slug: item.slug
      }
    }
  ))
);

export default function createPortalScoreSelector(orgSelector) {
  return createSelector(
    portalScoreSelector,
    editableVariableSelector,
    orgSelector,
    (scores, editableNodes, orgIdMap) => {
      if (!isEmpty(scores) && !isEmpty(editableNodes) && orgIdMap) {
        return transformScoresFromPortalDataService(scores, editableNodes, orgIdMap)
          .map(score => ({ ...score, value: get(score, 'value.score') }));
      }

      return [];
    }
  );
}
