import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'cui';

import styles from './HeaderBlock.module.scss';

const HeaderBlock = ({ title, children }) => (
  <div>
    <Heading level={3} weight="medium" className={styles.title}>{ title }:</Heading>
    <div className={styles.content}>
      { children }
    </div>
  </div>
);

HeaderBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default HeaderBlock;
