import numeral from 'numeral';
import repeat from 'lodash/repeat';

const numeralFormats = {
  number: '0,0',
  percent: '0'
};

const defaultPrecisions = {
  number: 0,
  percent: 1
};

const numberFormatter = (number, format, precision) => {
  let theNumber = Number(number);

  let numeralFormat = numeralFormats[format];

  if (!numeralFormat) {
    return String(number);
  }

  const thePrecision = precision === undefined ? defaultPrecisions[format] : precision;
  if (thePrecision != null) {
    const multiplier = 10 ** thePrecision;
    theNumber = Math.round(theNumber * multiplier) / multiplier;
    numeralFormat = `${numeralFormat}.[${repeat('0', thePrecision)}]`;
  } else {
    numeralFormat = `${numeralFormat}.[00000000]`;
  }

  return numeral(theNumber).format(numeralFormat);
};

export default numberFormatter;
