import forEach from 'lodash/forEach';
import get from 'lodash/get';
import reduce from 'lodash/reduce';
import map from 'lodash/map';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';

/*
 * Given a chart config and score,
 * Add a 'percent' row for each demographic in the columnset for another row.
 * params:
 *   percentRow.numerator: the key of the demographic data object to use as the numerator
 *   percentRow.denominator: the key of the source row object to use as the global denominator
 *   percentRow.sourceRow: the row to use as the source of the data.
 *   percentRow.destRow: the row to populate with the percent data.
 *
 * usage:
 *   currently this is only used by KDE for the `kde.school_safety.safety.safe_schools_data.behavior_events_by_type`
 *   node.
 */

export default function adapter({ currentOrgScore, chartConfig }) {
  const pctConfig = get(chartConfig, 'options.percentRow', {});

  // Create a container object to use as the accumulator for the updated rows
  // rows is a list, the values will be the keys of the final rows object
  const rowsContainer = reduce(chartConfig.options.rows, (accumulator, value) => {
    accumulator[value] = {};
    return accumulator;
  }, {});

  // reduce the scores into the rows.
  // note, the 'percent' row won't be present in the currentOrgScore, so we'll
  // have to populate it separately :|
  const rows = reduce(currentOrgScore, (accumulator, outerValue, outerKey) => {
    forEach(outerValue, (innerValue, innerKey) => {
      if (innerKey in accumulator) {
        accumulator[innerKey][outerKey] = innerValue;
      }
    });
    return accumulator;
  }, rowsContainer);

  // finally we populate the percent row
  const sourceRow = get(rows, pctConfig.sourceRow, {});
  const denominator = get(sourceRow, pctConfig.denominator);
  if (!sourceRow && denominator) {
    return rows;
  }

  // Get a list of all scoreKeys to be shown from the column configs
  // This is so we can populate the percent data object with formatted floats with correct precision `0.0`
  const scoreKeys = uniq(compact(map(chartConfig.options.columns, 'scoreKey')));

  // since the rows object already has the inner/outer keys flipped,
  // we can just apply the values to the new row.
  rows[pctConfig.destRow] = reduce(scoreKeys, (accumulator, scoreKey) => {
    const numerator = get(sourceRow, `${scoreKey}.${pctConfig.numerator}`, 0);
    accumulator[scoreKey] = {
      percent: (100 * (numerator / denominator)).toFixed(1)
    };
    return accumulator;
  }, {});

  return rows;
}
