import get from 'lodash/get';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import castArray from 'lodash/castArray';

import { currentOrgYearScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const { node, entities, entityScores, baseUrl, config = {}, scores, organization } = options;
  const { sortByProperty = 'label', addParentOrgScore = false } = config;

  const data = entities && reduce(entities, (acc, entity) => {
    const score = currentOrgYearScores(entityScores, entity, node) || {};
    if (score && score.value) {
      const colorIndex = get(score, 'value.performance', 0) - 1;
      acc.push({
        label: entity.name,
        key: entity.id,
        organization: entity,
        value: score.value,
        colorIndex,
        baseUrl
      });
    }
    return acc;
  }, []);

  const sortedData = data.length && sortBy(data, castArray(sortByProperty));

  if (addParentOrgScore) {
    const parentScore = currentOrgYearScores(scores, organization, node);
    if (parentScore && parentScore.value) {
      const colorIndex = get(parentScore, 'value.performance', 0) - 1;
      sortedData.unshift({
        label: organization.name,
        key: organization.id,
        organization,
        value: parentScore.value,
        colorIndex,
        baseUrl
      });
    }
  }

  return sortedData;
};

export default adapter;
