import React, { PureComponent } from 'react';

import QueryErrorBoundary from './QueryErrorBoundary';

const approvalUiWrapper = Component => {
  const WrappedComponent = class extends PureComponent {
    componentDidMount() {
      if (typeof window !== 'undefined') {
        this.mainStylesheet = document.querySelector(
          'link[rel="stylesheet"][href*="/main."][href$=".css"]'
        );
        if (this.mainStylesheet) {
          this.mainStylesheet.parentElement.removeChild(this.mainStylesheet);
        }

        if (!document.querySelector('link[href*="typekit"]')) {
          const link = document.createElement('link');
          link.rel = 'stylesheet';
          link.href = 'https://use.typekit.net/ktq5tjx.css';
          document.head.insertBefore(link, document.head.firstChild);
        }
      }
    }

    componentWillUnmount() {
      if (this.mainStylesheet) {
        document.head.appendChild(this.mainStylesheet);
      }
    }

    render() {
      return (
        <QueryErrorBoundary>
          <Component {...this.props} />
        </QueryErrorBoundary>
      );
    }
  };

  WrappedComponent.displayName = `approvalUi(${Component.displayName || Component.name})`;
  return WrappedComponent;
};

export default approvalUiWrapper;
