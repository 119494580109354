import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import ChartEmptyState from '../Chart/ChartEmptyState';
import applyAdapters, { checkForDataSuppression } from '../../helpers/adapterHelpers';
import { getParentData } from '../../helpers/portalDataServiceHelpers';

const DATA_SUPPRESSION_MESSAGE = 'This data is hidden to protect student privacy.';

// Chart wrapper HOC to encapsulate some common chart functionalities
// including adapter logics, data suppression checking, empty state checking and etc.
export default function KdeChartWrapper(props) {
  const {
    config,
    currentScore,
    node,
    allScores,
    additionalChartAdapters,
    organization
  } = props;

  const dataSuppressionChecks = get(config, 'options.dataSuppressionChecks');

  if (dataSuppressionChecks && checkForDataSuppression(currentScore, dataSuppressionChecks)) {
    // Show empty state when data is suppressed.
    return <ChartEmptyState emptyMessage={DATA_SUPPRESSION_MESSAGE} />;
  }

  const adapterName = get(config, 'adapter');

  // Apply adapter logics to tranform score
  const data = applyAdapters(adapterName, currentScore, {
    orginalScore: currentScore,
    currentOrganization: organization,
    chartConfig: config,
    additionalChartAdapters,
    node,
    config
  });

  if (isEmpty(data)) {
    return <ChartEmptyState />;
  }

  // Get the data for parent orgs only when includeParentData flag is configured for a node
  // When possible please wrap chart component using this HOC to get all common features needed
  // to render a chart including the parent data.
  // Alternatively you can call getParentData inside your chart, but this approach is less desired.
  // Important: to configure use of parent data in framework tree, set includeParentData to true
  // in a node's metadata
  const parentData = getParentData(allScores, organization, node);

  return props.children({ data, parentData, ...props });
}

KdeChartWrapper.propTypes = {
  config: PropTypes.object,
  organization: PropTypes.object,
  node: PropTypes.object,
  allScores: PropTypes.arrayOf(PropTypes.object),
  additionalChartAdapters: PropTypes.object,
  currentScore: PropTypes.object,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any
};
