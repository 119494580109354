import some from 'lodash/some';
import get from 'lodash/get';

import { KDE_APPROVAL_FG, KDE_APPROVAL_DOMAIN_FGS, KDE_COLLECTION_DOMAIN_FGS } from '../constants';

/*
 * The approval tab can be seen if:
 *   - The user is a state user (state administrator etc.),
 *   - The "everything" feature flag is turned on,
 *   - Or the feature flag for at least one domain is turned on.
 *
 * A domain within the approval tab can be seen if:
 *   - The user is a state user (state administrator etc.),
 *   - The "everything" feature flag is turned on,
 *   - Or the feature flag for that particular domain is turned on.
 *
 * The intent is to hide unfinished domains from schools and districts while
 * allowing state users to validate those domains as they're being built. Once
 * we finish building out charts for all domains, this logic can be removed and
 * all domains made visible all the time.
 */

export const canSeeApprovalTab = (userContext, featureFlags) =>
  userContext.currentOrganization.entityType === 'State' ||
  get(featureFlags, KDE_APPROVAL_FG) ||
  some(KDE_APPROVAL_DOMAIN_FGS, key => get(featureFlags, key));

export const canSeeDomain = (userContext, domainSlug, featureFlags) =>
  userContext.currentOrganization.entityType === 'State' ||
  get(featureFlags, KDE_APPROVAL_FG) ||
  get(featureFlags, get(KDE_APPROVAL_DOMAIN_FGS, domainSlug));

export const canSeeCollectionDomain = (userContext, domainSlug, featureFlags) =>
  userContext.currentOrganization.entityType === 'State' ||
  get(featureFlags, get(KDE_COLLECTION_DOMAIN_FGS, domainSlug));
