import React from 'react';
import PropTypes from 'prop-types';

import template from '../../../helpers/template';

const TruncateFormatter = (props) => {
  const { value, truncateLength, templateString } = props;
  const compiledValue = template(templateString, { value });
  const strlen = value.length;
  let truncatedValue;

  if (truncateLength < 0 || (strlen - truncateLength) < 0) {
    truncatedValue = compiledValue;
  } else {
    truncatedValue = compiledValue.substr(strlen - truncateLength);
  }

  return <span>{truncatedValue}</span>;
};

TruncateFormatter.propTypes = {
  templateString: PropTypes.string,
  truncateLength: PropTypes.number,
  value: PropTypes.string
};

TruncateFormatter.defaultProps = {
  templateString: '{{value}}',
  truncateLength: 0,
  value: ''
};

export default TruncateFormatter;
