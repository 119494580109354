import React from 'react';
import filter from 'lodash/filter';

import getWhyThisMatters from './getWhyThisMatters';
import DataPointBlock from '../DataPointBlock';

const DataPointBlocks = (props) => {
  const {
    items,
    organization,
    whyThisMatters,
    baseUrl,
    additionalChartAdapters,
    additionalChartTypes,
    entities,
    entityScores,
    loadingWhen,
    showScoreDates,
    dateTemplate,
    isLoading,
    additionalOrganizations,
    selectedTimeSlice,
    scores: allScores
  } = props;

  return items.map((dp) => {
    // props needed for eval(dp.when)
    if (organization.entity_type === undefined) props.organization.entity_type = '';
    // eslint-disable-next-line no-eval
    const showBlock = !dp.when || eval(dp.when);

    if (!showBlock) return null;

    const nodePathsForMax = dp?.scoring_options?.node_paths_for_max;
    const scores = nodePathsForMax ? allScores :
      (filter(allScores, { framework_tree_node: { node_path: dp.node_path } }) || []);

    return <DataPointBlock
      showScoreDates={showScoreDates}
      dateTemplate={dateTemplate}
      loading={isLoading}
      node={dp}
      organization={organization}
      additionalOrganizations={additionalOrganizations}
      selectedTimeSlice={selectedTimeSlice}
      scores={scores}
      allScores={allScores}
      tabs={dp.tabs || [
        { name: 'Chart' }
      ]}
      key={dp.slug}
      whyThisMatters={getWhyThisMatters(dp.node_path, whyThisMatters)}
      baseUrl={baseUrl}
      additionalChartAdapters={additionalChartAdapters}
      additionalChartTypes={additionalChartTypes}
      entities={entities}
      entityScores={entityScores}
      loadingWhen={loadingWhen}
    />;
  });
};

export default DataPointBlocks;
