import React from 'react';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import { Autocomplete } from 'cui';

import localeCompare from '../../../helpers/localeCompare';
import styles from './index.module.scss';

const buildAutocompleteOptions = (glossaryItems) => {
  if (!glossaryItems) return [];

  return flatten(Object.values(glossaryItems)).map(({ ELEMENT_NAME, ELEMENT_DESC }) => ({
    text: ELEMENT_NAME,
    description: ELEMENT_DESC,
    id: encodeURIComponent(ELEMENT_NAME)
  })).sort(({ label: a }, { label: b }) => localeCompare(a, b));
};

const buildAutocompleteAttrs = autocompleteAttrs => (
  {
    // Defaults
    className: 'cui-input_stroked',
    type: 'search',
    placeholder: 'Glossary search',
    'aria-label': 'Glossary search',
    // override with passed-in props
    ...autocompleteAttrs
  }
);

class GlossarySearch extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      autocompleteResetKey: new Date().toString(),
      autocompleteOptions: buildAutocompleteOptions(props.data),
      autocompleteAttrs: buildAutocompleteAttrs(props.autocompleteAttrs)
    };
  }

  componentWillReceiveProps(nextProps) {
    const autocompleteOptions = nextProps.data !== this.props.data ? buildAutocompleteOptions(nextProps.data) : this.state.autocompleteOptions;
    const autocompleteAttrs = nextProps.data !== this.props.data ? buildAutocompleteAttrs(nextProps.data) : this.state.autocompleteAttrs;

    this.setState({
      autocompleteOptions,
      autocompleteAttrs
    });
  }

  onItemSelect = (glossaryItem) => {
    // this hack is needed to reset autocomplete state.
    // we need to reset autocomplete state
    // because we don't need to keep clicked option as a autocomplete value
    this.setState({ autocompleteResetKey: new Date().toString() });
    this.props.onItemSelect(glossaryItem.id);
  }

  renderAutocompleteItem = ({ description, text, searchQuery }) => (
    <div className={styles.search}>
      <div className="cui-autocomplete-item-label cui-font-medium">
        <Autocomplete.HighlightedItem text={text} match={searchQuery} />
      </div>
      <div className="cui-autocomplete-item-details">
        {description}
      </div>
    </div>
  );

  render() {
    const { autocompleteOptions, autocompleteAttrs, autocompleteResetKey } = this.state;

    return (
      <Autocomplete
        key={autocompleteResetKey}
        data={autocompleteOptions}
        itemRenderer={this.renderAutocompleteItem}
        className={styles.autocomplete}
        inputAttrs={autocompleteAttrs}
        minQueryLength={3}
        onClick={this.onItemSelect}
        minQueryLengthMessage="Enter at least three characters"
      />
    );
  }
}

GlossarySearch.propTypes = {
  autocompleteAttrs: PropTypes.object,
  data: PropTypes.object,
  onItemSelect: PropTypes.func.isRequired
};

export default GlossarySearch;
