import get from 'lodash/get';

import schoolHasNoCollectors from '../schoolHasNoCollectors';
import orgCanAccessCollectorDataPoint from '../orgCanAccessCollectorDataPoint';
import { normalizeScoreValue } from '../scoreHelpers';
import when from '../when';

const emptyStateValidators = {
  isQuestionRequired(node, org) {
    return orgCanAccessCollectorDataPoint(node, org);
  },
  canAccessCollectorDataPoint(node, org) {
    return orgCanAccessCollectorDataPoint(node, org);
  },
  isValueEmpty(score, node) {
    return !normalizeScoreValue(score, node);
  },
  isNotEntityType(level, org) {
    return level !== org.entity_type;
  },
  alwaysRenderEmptyState() { // use when chart is not ready
    return true;
  }
};

export function showEmptyState(hasNoScores, hasCurrentOrgScore, node, score) {
  // Show empty state if no scores or no current score
  if (hasNoScores || !hasCurrentOrgScore) {
    return true;
  }

  const emptyStateExpression = get(node, 'emptyState.when');
  const validator = emptyStateValidators[get(node, 'emptyState.validatorName')];
  // When empty state config contain a when expression, return the result of the expression
  // Otherwise return false as not to show empty state.
  return validator ? when(emptyStateExpression, { score, validator, node }) : false;
}

export default function emptyStateMessage(node, org) {
  let emptyMessage;
  const emptyState = get(node, 'emptyState.messages');

  // while we want to display custom message without validators
  if (get(node, 'emptyStateMessage')) {
    return node.emptyStateMessage;
  }

  if (schoolHasNoCollectors(org) && node.editable) {
    emptyMessage = { title: 'N/A' };
  }

  if (emptyState) {
    // Loop over the emptyState messages array to find the first matched message by evaluating the when expression
    const matchedEmptyState = emptyState.find((meta) => {
      const validator = emptyStateValidators[meta.validatorName];
      return validator && when(meta.when, { validator, node, org });
    });

    if (matchedEmptyState) {
      emptyMessage = {
        buttonText: matchedEmptyState.buttonText,
        buttonHref: matchedEmptyState.buttonHref,
        buttonProps: matchedEmptyState.buttonProps,
        title: matchedEmptyState.message,
        subtitle: matchedEmptyState.description
      };
    }
  }

  return emptyMessage;
}
