import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import bounds from 'react-bounds';

import styles from './CondensedTable.module.scss';
import propTypes from './types.js';

// Example usage:
// <CondensedTable
//   columns={[
//     {
//       header: '',
//       accessor: 'position',
//       cell: ({ row }) => (
//         <div>
//         <div>{row.company}</div>
//         <div>{row.position}</div>
//       </div>
//       )
//     }, {
//       header: ({ accessor }) => <strong>{accessor}</strong>,
//       accessor: 'name'
//     }, {
//       header: 'Surname',
//       accessor: 'surname'
//     }, {
//       header: 'Age',
//       accessor: 'age',
//       cell: ({ row }) => (
//         <div>
//           <div>{row.age}</div>
//           <div>years</div>
//         </div>
//       )
//     }
//   ]}
//   data={[
//     {
//       company: 'Apple',
//       position: 'CEO',
//       name: 'John',
//       surname: 'Smith',
//       age: 12
//     }, {
//       company: 'IMB',
//       position: 'CFO',
//       surname: 'Doe',
//       name: 'Jenny',
//       age: 25
//     }, {
//       company: 'Microsoft',
//       position: 'CTO',
//       name: 'Bill',
//       surname: 'Walker',
//       age: 52
//     }
//   ]}
//   title="Important people"
// />

const TableTitle = ({ children }) => {
  if (!children) return null;

  if (typeof children === 'function') {
    const Title = children;
    return <Title />;
  }

  return (
    <div className={styles.table__title}>
      {children}
    </div>
  );
};

TableTitle.propTypes = {
  children: propTypes.title
};

const Head = ({ columns }) => (
  <thead>
    <tr className={`${styles.table__row} ${styles['table__row--head']}`}>
      {columns.map(column => <HeadCell {...column} key={column.accessor} />)}
    </tr>
  </thead>
);

Head.propTypes = {
  columns: propTypes.columns
};

const HeadCell = ({ accessor, header }) => {
  const Header = header;

  if (typeof Header === 'undefined') {
    return (
      <th className={styles.table__cell}>
        {accessor}
      </th>
    );
  }

  if (typeof Header === 'function') {
    return (
      <th className={styles.table__cell}>
        <Header accessor={accessor} />
      </th>
    );
  }

  return (
    <th className={styles.table__cell}>
      {Header}
    </th>
  );
};

HeadCell.propTypes = {
  accessor: PropTypes.string.isRequired,
  header: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ])
};

const Body = ({ data, columns }) => (
  <tbody className={styles.table__body}>
    {
      data
        .filter(({ hide }) => !hide)
        .map((dataRow, index) => (
          <tr className={styles.table__row} key={index}>
            {columns.map(column => <BodyCell {...column} data={data} dataRow={dataRow} key={column.accessor} />)}
          </tr>
        ))
    }
  </tbody>
);

Body.propTypes = {
  columns: propTypes.columns,
  data: propTypes.data
};

const BodyCell = ({ accessor, cell, data, dataRow }) => {
  const Cell = cell;
  const cellClassNames = cn({
    [styles.table__cell]: true,
    [styles[dataRow.theme]]: dataRow.theme
  });

  if (typeof Cell === 'function') {
    return (
      <Cell
        accessor={accessor}
        className={cellClassNames}
        data={data}
        row={dataRow}
      />
    );
  }

  if (typeof Cell === 'string') {
    return (
      <td className={cellClassNames}>
        {Cell}
      </td>
    );
  }

  return (
    <td className={cellClassNames}>
      {dataRow[accessor]}
    </td>
  );
};

BodyCell.propTypes = {
  accessor: PropTypes.string.isRequired,
  cell: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  data: propTypes.data,
  dataRow: PropTypes.object
};

export class CondensedTable extends React.PureComponent {
  static bounds() {
    return {
      tiny: {
        maxWidth: 400
      },
      small: {
        minWidth: 401,
        maxWidth: 700
      },
      medium: {
        minWidth: 701
      }
    };
  }

  render() {
    const classNames = cn({
      [styles.table]: true,
      [styles['table--small-up']]: this.props.isBound('small'),
      [styles['table--medium-up']]: this.props.isBound('medium')
    });
    const tableAriaLabelId = `table_${Math.random()}_aria_label`;

    return (
      <div data-test="condensed-table" className={styles.wrapper}>
        <TableTitle>{this.props.title}</TableTitle>
        <span id={tableAriaLabelId} hidden>{this.props.description}</span>
        <table width="100%" cellPadding="0" cellSpacing="0" className={classNames} aria-labelledby={tableAriaLabelId}>
          <Head columns={this.props.columns} />
          <Body columns={this.props.columns} data={this.props.data} />
        </table>
      </div>
    );
  }
}

CondensedTable.defaultProps = {
  isBound: () => {}
};

CondensedTable.propTypes = propTypes;

const BoundCondensedTable = bounds.wrap(CondensedTable);

export default BoundCondensedTable;
