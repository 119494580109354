import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import CommonPropTypes from '../../../helpers/propTypes';
import template from '../../../helpers/template';

export const CostFormatter = (props) => {
  const { value, templateString, valueKey } = props;

  const theValue = valueKey ? get(value, valueKey) : value;
  const number = (parseInt((theValue || 0)) / 100);

  const getDisplayValue = () => {
    if (number < 0) return 'No Data';
    if (number === 0) return 'Free';

    return template(templateString, { value: number.toFixed(2) });
  };

  return <span>{getDisplayValue()}</span>;
};

CostFormatter.propTypes = {
  value: PropTypes.oneOfType([
    CommonPropTypes.dataValue,
    PropTypes.objectOf(CommonPropTypes.dataValue)
  ]),
  templateString: PropTypes.string,
  valueKey: PropTypes.string
};

CostFormatter.defaultProps = {
  value: '',
  templateString: '{{value}}',
  valueKey: ''
};

export default CostFormatter;
