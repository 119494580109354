import React from 'react';
import PropTypes from 'prop-types';

Footer.propTypes = {
  children: PropTypes.node
};

function Footer({ children }) {
  return (
    <p className="gatewayGrid-footer">
      { children }
    </p>
  );
}

Footer.defaultProps = {
  'data-slotName': 'Footer'
};

export default Footer;
