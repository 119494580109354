import { getOrgType } from '../../portalDataServiceHelpers';

// @param currentOrg {object} organization
// @param statusTextForOrg {string} status text based on completion
// @return approve status
// Note: We only require district to review and approve financial data
// Therefore we always want to show 'Not Applicable' approval status for school
// A little bit hard coded here as quick dirty way to fix the bug.
// eslint-disable-next-line import/prefer-default-export
export function approvalStatus(domain, currentOrg, statusTextForOrg) {
  const isFin = (domain === 'kde.financial_transparency');
  return (isFin && getOrgType(currentOrg) === 'school') ? 'Not Applicable' : statusTextForOrg;
}
