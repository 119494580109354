import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import round from 'lodash/round';
import isUndefined from 'lodash/isUndefined';

import template from '../../helpers/template';

import legendRenderer from './legendRenderer';

const adapter = (props) => {
  const { currentOrgScore, config } = props;

  const LEGEND_THEME_DEFAULT = 'default';
  const LEGEND_PLACEMENT_DEFAULT = 'right';
  const WIDTH_DEFAULT = 200;
  const RADIUS_RATIO_DEFAULT = 0.55;
  const TITLE_DEFAULT = 'Chart';

  // Fetch the base scores from the currentOrgScore provided.
  // if a defaultScoreKey is provided (defaultScoreKey: 'value.some_sub_key')
  // we'll start at that level instead
  const baseValue = get(config, 'options.defaultScoreKey', 'value');
  const values = get(currentOrgScore, baseValue);

  // Fail quietly if there's no value?
  if (isUndefined(values)) return false;

  const nonRemainderKeys = Object.keys(config.data).filter(k => k !== 'remainder');

  // To calculate percentages, we need the total count of the things we're calculating a
  // percentage _of_.  For this adapter, this is the thing in the config for 'denominator',
  // the asssumption being it is different than the 'numerator
  const denominatorTotal = reduce(nonRemainderKeys, ((sum, k) => sum + get(values[k], config.options.denominator)), 0);
  const numeratorTotal = reduce(nonRemainderKeys, ((sum, k) => sum + get(values[k], config.options.numerator)), 0);
  const remainder = denominatorTotal - numeratorTotal;

  // convert our config object to an array of objects to pass to the cui renderer.
  // these are also passed to the legend data mapper below, so any values pulled from
  // the config object should be populated here.
  const chartData = map(Object.keys(config.data), (k) => {
    let percent;

    if (denominatorTotal === 0) {
      // nope.
      percent = undefined;
    } else if (k !== 'remainder') {
      percent = round((get(values[k], config.options.numerator, 0) / parseFloat(denominatorTotal)) * 100, 1);
    } else {
      percent = round((remainder / parseFloat(denominatorTotal)) * 100, 1);
    }

    return {
      key: k,
      percent,
      label: template(config.data[k].label, { percent, remainder, ...values[k] }),
      description: template(config.data[k].description, { percent, remainder, ...values[k] })
    };
  });

  return {
    legendPlacement: get(config, 'legendPlacement', LEGEND_PLACEMENT_DEFAULT),
    theme: get(config, 'theme', LEGEND_THEME_DEFAULT),
    title: get(config, 'options.title', TITLE_DEFAULT),
    chartProps: {
      data: chartData,
      width: get(config, 'width', WIDTH_DEFAULT),
      radiusRatio: get(config, 'radiusRatio', RADIUS_RATIO_DEFAULT),
      value: get(config, 'options.centerValue') === 'total' ? denominatorTotal : null,
      label: get(config, 'options.centerLabel', '')
    },
    legendProps: {
      data: chartData,
      renderLegendItem: legendRenderer
    },
    className: ['alchemy-donut', 'alchemy-donut-kde', get(config, 'chartClass', '')].join(' ')
  };
};

export default adapter;
