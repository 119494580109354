import React from 'react';
import PropTypes from 'prop-types';

import { Heading } from 'cui';

import Item from './Item';

import styles from './OverviewBlock.module.scss';

const OverviewBlock = ({ heading, children }) => (
  <div className={styles.overview}>
    { heading &&
      <Heading level={3} size="base">{heading}</Heading>
    }
    <div className={styles.items}>
      { children }
    </div>
  </div>
);

OverviewBlock.propTypes = {
  heading: PropTypes.string,
  children: PropTypes.node
};

OverviewBlock.Item = Item;

export default OverviewBlock;
