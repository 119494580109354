export const DATA_POINT_ERROR_TYPES = [
  { label: 'Missing Data', value: 'missing_data' },
  { label: 'Incorrect Data', value: 'incorrect_data' },
  { label: 'Other', value: 'other' }
];

export const DEFAULT_SECTION_STATUS = {
  errorType: DATA_POINT_ERROR_TYPES[0].value,
  comment: ''
};
