import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import addYearToUrl from './addYearToUrl';

const NavLinkWithYear = ({
  to,
  match,
  location,
  history,
  children,
  ...rest
}) => {
  const year = new URLSearchParams(location.search).get('year');

  return (
    <Link
      to={addYearToUrl(to, year)}
      {...rest}
    >
      {children}
    </Link>
  );
};

NavLinkWithYear.propTypes = {
  ...Link.propTypes,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default withRouter(NavLinkWithYear);
