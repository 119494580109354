import React from 'react';
import PropTypes from 'prop-types';

import OrgDataProvider from '../../helpers/OrgDataProvider';
import ApprovalHeader from '../ApprovalHeader';
import Footer from '../Footer';
import Masthead from '../Masthead';
import OrgHeaderUI from '../OrgHeaderUI';

import styles from './index.module.scss';

function PageShell({ children, className }) {
  return (
    <div className={className}>
      <ApprovalHeader />
      <Masthead withSearchBar />

      <div role="main">
        <OrgDataProvider>
          {orgData => <OrgHeaderUI {...orgData} />}
        </OrgDataProvider>

        <section className="container">
          {children}
        </section>
      </div>

      <Footer className={styles.footer} />
    </div>
  );
}

PageShell.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default PageShell;
