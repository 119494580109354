import React from 'react';
import {
  objectOf, shape, string, number, boolean
} from 'prop-types';

import get from 'lodash/get';
import isNil from 'lodash/isNil';

import {
  Grid, FlexBar, Heading, Donut, Trend
} from 'cui';
import EmptyState from 'cui/lib/components/EmptyState';

import styles from './statusAndChange.module.scss';

const perfDesc = ['N/A', 'Lowest Performance', 'Low Performance', 'Medium Performance', 'High Performance', 'Highest Performance'];
const trends = {
  'Increased Significantly': 'up',
  Increased: 'up',
  Maintained: 'same',
  Decreased: 'down',
  'Decreased Significantly': 'down',
  Declined: 'down',
  'Declined Significantly': 'down'
};

const distanceFrom = (value) => {
  const direction = value.status_value < 0 ? 'below' : 'above';
  return `${Math.abs(value.status_value)} points ${direction} Level 3`;
};

const formatStatusValue = (value) => `${value.status_value}%`;

const changeValueFormatter = (value, isDistanceFrom) => {
  if (isDistanceFrom) return `${value} points`;
  return `${value}%`;
};

export const StatusChange = ({ directScore, params, config }) => {
  const currentFilter = get(params, 'filters.demographic', 'All');
  const value = get(directScore, `value.${currentFilter}`);
  if (value) {
    const statusValue = config.isDistanceFrom ? distanceFrom(value) : formatStatusValue(value);
    const changeValue = changeValueFormatter(value.change_value, config.isDistanceFrom);
    const changeLabel = value.change_label ? value.change_label.replace('Decreased', 'Declined') : '';
    return (
      <Grid>
        <Grid.Cell>
          <Heading level={4} size="medium" caps className="cui-text_muted cui-margin-bottom-small">All Students Performance</Heading>
          {!isNil(value.performance) && (
            <div className="cui-flexCenter">
              <Donut
                data={{ percent: value.performance * 20, colorIndex: value.performance - 1 }}
                width={200}
                radiusRatio={0.75}
                theme="sbcssDashboard"
              >
                <span className="cui-text_base cui-font-bold">{perfDesc[value.performance]}</span>
              </Donut>
            </div>
          )}
        </Grid.Cell>
        <Grid.Cell>
          <Heading level={4} size="medium" caps className="cui-text_muted cui-margin-bottom-small">Status</Heading>
          <div className={`cui-text_xxLarge cui-font-heavy ${styles.changeLabel}`} data-test="status-label">{value.status_label}</div>
          <div className={`cui-text_large ${styles.changeValue}`} data-test="status-value">{statusValue}</div>
        </Grid.Cell>
        <Grid.Cell>
          <Heading level={4} size="medium" caps className="cui-text_muted cui-margin-bottom-small">Change</Heading>
          <FlexBar justify="left" align="top">
            <FlexBar.Item>
              <div data-test="change-label-trend"><Trend trend={trends[value.change_label] || 'same'} className={styles.trend} /></div>
            </FlexBar.Item>
            <FlexBar.Item className={`cui-text_xxLarge cui-font-heavy ${styles.changeLabel}`}>
              <div data-test="change-label-label">{changeLabel}</div>
            </FlexBar.Item>
          </FlexBar>
          <div className={`cui-text_large ${styles.changeValue}`} data-test="change-value">{changeValue}</div>
        </Grid.Cell>
      </Grid>
    );
  } else {
    return <EmptyState kind="text" title="There is no data available." />;
  }
};

StatusChange.propTypes = {
  params: shape({
    filters: shape({
      demographic: string
    })
  }),
  directScore: objectOf(
    shape({
      performance: number,
      status_label: string,
      status_value: number,
      change_label: string,
      change_value: number
    })
  ),
  config: shape({
    isDistanceFrom: boolean
  })
};

StatusChange.defaultProps = {
  config: {}
};

export default StatusChange;
