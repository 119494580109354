import PropTypes from 'prop-types';
import React from 'react';

import { Icon, Popover, Button } from 'cui';
import styles from './KdeCondensedTable.module.scss';

const KdeCondensedTableInfoIcon = ({ text, ariaLabel }) => (
  <Popover popoverPlacement="top" appendToBody={false}>
    <Popover.Toggle>
      <Button attributes={{ 'aria-label': ariaLabel }}>
        <Icon className={styles.info} name="bb-circle-information" />
      </Button>
    </Popover.Toggle>
    <Popover.Content>{text}</Popover.Content>
  </Popover>
);

KdeCondensedTableInfoIcon.propTypes = {
  text: PropTypes.string,
  ariaLabel: PropTypes.string
};

export default KdeCondensedTableInfoIcon;
