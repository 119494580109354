import { isA1School } from '../../orgCanAccessCollectorDataPoint';
import { getOrgType } from '../../portalDataServiceHelpers';

// Filter out scores that have been deleted (and thus manifest as empty strings).
// Some scores are stored as { value: { value: [score] } } some as { value: [score] } and
// other as { value: { some_name: 1 } }
export const getCollectedScores = (scores) => scores.filter((score) => {
  if (typeof score.value === 'string') return Boolean(score.value);
  return Boolean(score?.value?.value ?? score?.value);
});

// @param currentOrg {object} organization
// @param statusTextForOrg {string} status text based on completion
// @return approve status
// Note: Only A1 school collect data for Financial Domain
// Therefore we always want to show 'Not Applicable' collection status for Non A1 school
// A little bit hard coded here as quick dirty way to fix the bug.
export const collectionStatus = (domain, currentOrg, statusTextForOrg) => {
  const isFin = (domain === 'kde.financial_transparency');
  return isFin && !isA1School(currentOrg) ? 'Not Applicable' : statusTextForOrg;
};

function isOrgType(org, orgType) {
  return getOrgType(org) === orgType;
}

export function isSchool(org) {
  return isOrgType(org, 'school');
}

export function isDistrict(org) {
  return isOrgType(org, 'district');
}

export function isState(org) {
  return isOrgType(org, 'state');
}
