import each from 'lodash/each';
import isArray from 'lodash/isArray';

// connect like so: connected(Component, mapDispatchToProps)
// this will add a runActions function in your props and then you can pass whatever actions you want just like initActions on page load
const mapDispatchToProps = dispatch => ({
  runActions: (props = {}, actions = []) => (
    each(actions, action => dispatch({ ...props, ...action }))
  ),
  runAction: action => (isArray(action) ? each(action, a => dispatch(a)) : dispatch(action))
});

export default mapDispatchToProps;
