import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { CSVLink } from 'react-csv';
import identity from 'lodash/identity';
import get from 'lodash/get';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import groupBy from 'lodash/groupBy';
import startCase from 'lodash/startCase';
import assign from 'lodash/assign';
import some from 'lodash/some';
import compact from 'lodash/compact';
import find from 'lodash/find';
import memoizeOne from 'memoize-one';

import BlockTitle from 'cui/lib/components/BlockTitle';
import Button from 'cui/lib/components/Button';
import Grid from 'cui/lib/components/Grid';
import Heading from 'cui/lib/components/Heading';
import Panel from 'cui/lib/components/Panel';
import { currentOrgYearScores } from '../helpers/scoresHelpers';
import { kdeCurrentSchoolYear } from '../helpers/portals';

import approvalWrapper from '../components/ApprovalWrapper';
import ApprovalHeader from '../components/ApprovalHeader';
import { ProfileReport } from '../components/ProfileReport';
import MainContentAnchor from '../components/MainContentAnchor';
import WithPredicate from '../components/WithPredicate';
import PDFDownload from '../helpers/PDFDownload';
import { getIsSPRPDFDownloadEnabled } from '../helpers/featureFlags';
import LOAD_CURRENT_ORG from '../queries/load_current_org';
import allDataPoints from '../helpers/allDataPoints';
import buildLoadFrameworkAction from '../actions/framework';
import styles from './Status.module.scss';
import getMergedScores from '../helpers/scores';
import {
  getDimensionsForOrg,
  getOrgIdFromOrganization
} from '../helpers/portalDataServiceHelpers';
import { pluckNodes, shouldUsePortalDataService } from '../helpers/nodeHelpers';
import { isSchool } from '../helpers/completionStatusHelpers/collection/utils';
import { SUPPORTED_YEARS } from '../constants';

const STATUS_LABELS = {
  approved: 'Approved',
  flagged: 'Flagged',
  unreviewed: 'Unreviewed'
};

// Endpoint comes from the config/locals
/* eslint-disable no-undef */
const pdfDownloader = new PDFDownload(`${HOSTS.PDF_GENERATOR}/generate`);
/* eslint-enable no-undef */

const buildApprovalCSV = memoizeOne(({ framework, childOrgs, childOrgType, sectionStatuses }) => {
  const sectionStatusesByOrg = groupBy(sectionStatuses, 'clarity_organization_id');

  return {
    headers: [
      ...(childOrgType === 'District' ? [
        'District Name',
        'District Code'
      ] : [
        'District Name',
        'District Code',
        'School Name',
        'School Code'
      ]),
      ...map(framework.items, 'name')
    ],
    data: map(filter(childOrgs, { entity_type: childOrgType }), (childOrg) => {
      const sectionStatusesForOrg = get(sectionStatusesByOrg, childOrg.id, []);
      const sectionStatusesBySectionSlug = groupBy(sectionStatusesForOrg, 'section_slug');

      return {
        ...(childOrgType === 'District' ? {
          'District Name': childOrg.name,
          'District Code': childOrg.dist_number || ''
        } : {
          // TODO: inconsistent with org.name for districts - e.g. "Adair Co" and "Adair County"
          'District Name': childOrg.dist_name || '',
          'District Code': childOrg.dist_number || '',
          'School Name': childOrg.name,
          'School Code': childOrg.sch_cd || ''
        }),
        ...framework.items.reduce((memo, item) => {
          const hasFlaggedDatapoints = sectionStatusesForOrg
            .some(el => el.section_slug.startsWith(item.node_path) && el.status === 'flagged');

          const status = hasFlaggedDatapoints ? 'flagged' : get(sectionStatusesBySectionSlug[item.node_path], '[0].status', 'unreviewed');

          memo[item.name] = STATUS_LABELS[status];

          return memo;
        }, {})
      };
    })
  };
});

// TODO: find somewhere better for renderCollectionFieldHeader and
// renderCollectionField to live - maybe alongside the individual collector
// field type components?
// eslint-disable-next-line complexity
const renderCollectionFieldHeader = ({ dataPoint }) => {
  const { name, node_path: nodePath, field_type: fieldType, field_options: fieldOptions, field_inputs: fieldInputs } = dataPoint;

  if (fieldType === 'text' || fieldType === 'checkbox') {
    return { key: nodePath, label: name };
  } else if (fieldType === 'number') {
    if (fieldInputs) {
      return map(fieldInputs, ({ key, label }) => ({ key: `${nodePath}/${key}`, label: `${name} (${label})` }));
    } else {
      return { key: nodePath, label: name };
    }
  } else if (fieldType === 'radio') {
    return [
      { key: nodePath, label: name },
      ...(some(get(fieldOptions, 'options', []), 'hasDate') ? [{ key: `${nodePath}/date`, label: `${name} (Date)` }] : [])
    ];
  } else {
    // eslint-disable-next-line no-console
    console.warn(`Excluding dataPoint "${dataPoint.name}" with empty field_type`);
    return null;
  }
};

// eslint-disable-next-line complexity
const renderCollectionField = ({ dataPoint, currentScore = {} }) => {
  const { node_path: nodePath, field_type: fieldType, field_options: fieldOptions, field_inputs: fieldInputs } = dataPoint;
  const value = get(currentScore, 'value.score');

  if (fieldType === 'number') {
    if (fieldInputs) {
      return reduce(fieldInputs, (memo, { key }) => {
        memo[`${nodePath}/${key}`] = get(value, key, '');
        return memo;
      }, {});
    } else {
      return { [nodePath]: get(value, 'value', '') };
    }
  } else if (fieldType === 'text') {
    return { [nodePath]: value || '' };
  } else if (fieldType === 'checkbox') {
    const sections = get(dataPoint, 'scoring_options.format_options.sections', []);
    const sectionValues = get(value, 'value', {});

    return {
      [nodePath]: flatMap(sections, ({ name: sectionName, section_slug: sectionSlug, label_map: sectionLabelMap }) => {
        const sectionPrefix = sectionName ? `${sectionName}/` : '';
        const sectionValue = get(sectionValues, sectionSlug, {});
        const selectedValues = get(sectionValue, 'selected_options', {});
        const others = get(sectionValue, 'others', []);

        return [
          ...compact(map(sectionLabelMap, ({ label, value: labelValue }) => (selectedValues[labelValue] ? `${sectionPrefix}${label}` : null))),
          ...map(others, other => `${sectionPrefix}${other}`)
        ];
      }).join(', ')
    };
  } else if (fieldType === 'radio') {
    const options = get(fieldOptions, 'options', []);
    const anyLabelHasDate = some(options, 'hasDate');
    return {
      [nodePath]: get(find(options, ({ value: labelValue }) => get(value, 'value') === labelValue), 'label', ''),
      [`${nodePath}/date`]: (anyLabelHasDate ? get(value, 'date', '') : undefined)
    };
  } else {
    return null;
  }
};

const buildCollectionCSV = memoizeOne(({ framework, childOrgs, scores, schoolYear }) => {
  const dataPoints = allDataPoints(framework, { editable: true });
  const scoresByOrg = groupBy(scores, 'remote_organization_id');
  const isYear4 = schoolYear > 2020;

  const headers = [
    { key: 'districtName', label: 'District Name' },
    { key: 'districtCode', label: 'District Code' },
    { key: 'schoolName', label: 'School Name' },
    { key: 'schoolCode', label: 'School Code' }
  ];

  if (isYear4) headers.push({ key: 'schoolClassification', label: 'School Classification' });

  headers.push(...flatMap(dataPoints, dataPoint => renderCollectionFieldHeader({ dataPoint })).filter(identity));

  return {
    headers,
    data: map(filter(childOrgs, { entity_type: 'School' }), (childOrg) => {
      const scoresForOrg = get(scoresByOrg, childOrg.id, []);

      return {
        // TODO: inconsistent with org.name for districts - e.g. "Adair Co" and "Adair County"
        districtName: childOrg.dist_name || '',
        districtCode: childOrg.dist_number || '',
        schoolName: childOrg.name,
        schoolCode: childOrg.sch_cd || '',
        schoolClassification: childOrg.sch_type || '',
        ...dataPoints.reduce((memo, dataPoint) => {
          const currentScore = currentOrgYearScores(scoresForOrg, childOrg, dataPoint);
          assign(memo, renderCollectionField({ dataPoint, currentScore }));
          return memo;
        }, {})
      };
    })
  };
});

const replaceDoubleQuote = (str) => {
  if (str) {
    return str.replace(/"/ig, '""');
  }
  return str;
};

const updateProperties = (obj) => {
  if (obj) {
    obj.districtMessage = replaceDoubleQuote(obj.districtMessage);
    obj.narrative = replaceDoubleQuote(obj.narrative);
  }
  return obj;
};

const buildCollectionDistrictCSV = memoizeOne(({ childOrgs }) => {
  const headers = [
    { key: 'districtName', label: 'District Name' },
    { key: 'districtCode', label: 'District Code' },
    { key: 'narrative', label: 'Financial Transparency Narrative' },
    { key: 'narrativeAuthor', label: 'Financial Transparency Creator' },
    { key: 'districtMessage', label: 'District Message' },
    { key: 'districtMessageAuthor', label: 'District Message Author' },
    { key: 'districtMessageTitle', label: 'District Message Title' }
  ];

  return {
    headers,
    data: map(filter(childOrgs, { entity_type: 'District' }), (childOrg) => (updateProperties({
      districtName: childOrg.dist_name || '',
      districtCode: childOrg.dist_number || '',
      narrative: childOrg.narrative?.body,
      narrativeAuthor: childOrg.narrative?.author,
      districtMessage: childOrg.district_message?.body,
      districtMessageAuthor: childOrg.district_message?.author,
      districtMessageTitle: childOrg.district_message?.title
    })))
  };
});

export class StatusDownloadsPage extends React.Component {
  componentWillUnmount() {
    // Remove pdf hidden form before component is unmounted.
    pdfDownloader.removeForm();
  }

  // FIXME: Don't hardcode the org year!  Not sure how to hande just yet.
  generateDownloadFilename = (orgName, yearText) => (
    `${yearText} School Profile Report -- ${orgName}`
  )

  pdfDownloadClickHandler = (e) => {
    e.preventDefault();

    const { framework, sprScores, currentOrganization, jwtTokens, schoolYear } = this.props;
    const token = get(jwtTokens, 'pdf_generator');
    const yearText = find(SUPPORTED_YEARS, { value: schoolYear }).text;
    const filename = this.generateDownloadFilename(get(currentOrganization, 'name'), yearText);
    const report = (
      <ProfileReport
        framework={framework}
        scores={sprScores}
        currentOrganization={currentOrganization}
        schoolYear={schoolYear}
      />
    );

    pdfDownloader.download(report, null, token, filename, true);
  }

  /* eslint-disable complexity */
  render() {
    const {
      currentOrganization,
      framework,
      childOrgs,
      sectionStatuses,
      pageContext,
      isLoading,
      user,
      currentNodes,
      scoresFromPortalDataService,
      schoolYear
    } = this.props;

    if (!currentOrganization || isLoading) return null;

    const featureFlags = get(pageContext, 'featureFlags', {});

    const isSPRDownloadEnabled = getIsSPRPDFDownloadEnabled(featureFlags);
    const childOrganizations = currentOrganization.entity_type === 'School' ? [currentOrganization] : childOrgs;
    const scores = getMergedScores({
      scoresFromPortalDataService,
      currentOrganization,
      currentNodes,
      childOrganizations
    });

    const downloadLinks = [];
    const isApprovalPeriodOpen = !!featureFlags.KdeApprovalPeriodOpen;
    const isStateUser = user.currentOrganization.entityType === 'State';
    const shouldShowContent = isApprovalPeriodOpen || isStateUser;
    const isYear4 = schoolYear > 2020;

    /* eslint-disable no-fallthrough */
    switch (currentOrganization.entity_type) {
      case 'State':
        downloadLinks.push(
          <div>
            <Heading>Approval Data</Heading>
            <p>View current progress of district data approval.</p>
            <CSVLink
              className="cui-btn cui-btn_solid cui-btn_primary" filename={`${currentOrganization.name} - District Approval Status.csv`}
              {...buildApprovalCSV({
                framework,
                childOrgs: childOrganizations,
                childOrgType: 'District',
                sectionStatuses
              })}
            >
              Download CSV
            </CSVLink>
          </div>
        );

        if (isYear4) {
          downloadLinks.push(
            <div>
              <Heading>Collection Data</Heading>
              <p>View current submissions for district collected data.</p>
              <CSVLink
                className="cui-btn cui-btn_solid cui-btn_primary" filename={`${currentOrganization.name} - District Collected Data.csv`}
                {...buildCollectionDistrictCSV({ childOrgs: childOrganizations })}
              >
                Download CSV
              </CSVLink>
            </div>
          );
        }
      case 'District':
        downloadLinks.push(
          <div>
            <Heading>Approval Data</Heading>
            <p>View current progress of school data approval.</p>
            <CSVLink
              className="cui-btn cui-btn_solid cui-btn_primary" filename={`${currentOrganization.name} - School Approval Status.csv`}
              {...buildApprovalCSV({
                framework,
                childOrgs: childOrganizations,
                childOrgType: 'School',
                sectionStatuses
              })}
            >
              Download CSV
            </CSVLink>
          </div>
        );

      default:
        downloadLinks.push(
          <div>
            <Heading>Collection Data</Heading>
            <p>View current submissions for school-collected data.</p>
            <CSVLink
              className="cui-btn cui-btn_solid cui-btn_primary" filename={`${currentOrganization.name} - Collection Data.csv`}
              {...buildCollectionCSV({
                framework,
                childOrgs: childOrganizations,
                scores,
                schoolYear
              })}
            >
              Download CSV
            </CSVLink>
          </div>
        );

        if (isSPRDownloadEnabled && !isEmpty(framework) && !isEmpty(currentOrganization) && isSchool(currentOrganization)) {
          downloadLinks.push(
            <div>
              <Heading>School Profile Report</Heading>
              <p>Print this report to fulfill requirements of Senate Bill 1.</p>
              <Button kind="solid" theme="primary" title="Download School Profile Report" onClick={this.pdfDownloadClickHandler}>
                Download PDF
              </Button>
            </div>
          );
        }
    }
    /* eslint-enable no-fallthrough */

    return (
      <div className={styles.container}>
        <ApprovalHeader />
        <WithPredicate
          predicate={shouldShowContent}
          placeholder={
            <Panel>
              <Panel.Content>
                The Data Approval and Collection Period is not open at this time.
                Please contact the Kentucky State Department of Education for more information.
              </Panel.Content>
            </Panel>
          }
        >
          <div role="main">
            <MainContentAnchor />
            <BlockTitle title="Downloads" />
            <Grid align="stretch">
              {map(downloadLinks, (link, idx) =>
                <Grid.Cell width="1of3" key={`downloadLink-${idx}`}>
                  <Panel>
                    <Panel.Content>
                      {link}
                    </Panel.Content>
                  </Panel>
                </Grid.Cell>)}
            </Grid>
          </div>
        </WithPredicate>
      </div>
    );
  }
  /* eslint-enable complexity */
}

StatusDownloadsPage.propTypes = {
  currentNode: PropTypes.object,
  currentNodes: PropTypes.arrayOf(PropTypes.object),
  childOrgs: PropTypes.arrayOf(PropTypes.object),
  childScores: PropTypes.arrayOf(PropTypes.object),
  currentOrganization: PropTypes.object,
  framework: PropTypes.object,
  pageContext: PropTypes.object,
  sectionStatuses: PropTypes.arrayOf(PropTypes.object),
  selfScores: PropTypes.arrayOf(PropTypes.object),
  scoresFromPortalDataService: PropTypes.object,
  sprScores: PropTypes.object,
  schoolYear: PropTypes.number,
  jwtTokens: PropTypes.object,
  isLoading: PropTypes.bool,
  user: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.number
    })
  })
};

const mapStateToProps = state => ({
  currentNode: get(state, 'framework.currentNode'),
  currentNodes: get(state, 'module.currentNodes'),
  childOrgs: get(state, 'module.childOrgs', []),
  childScores: get(state, 'module.childScores'),
  currentOrganization: get(state, 'module.currentOrganization'),
  framework: get(state, 'framework.kde'),
  jwtTokens: get(state, 'module.jwtTokens'),
  pageContext: get(state, 'module.pageContext'),
  sectionStatuses: get(state, 'module.sectionStatuses'),
  selfScores: get(state, 'module.selfScores'),
  scoresFromPortalDataService: get(state, 'module.scoresFromPortalDataService'),
  sprScores: get(state, 'module.sprScores'),
  schoolYear: get(state, 'module.schoolYear'),
  isLoading: get(state, 'loading.isLoading'),
  user: get(state, 'module.userContext')
});

export default approvalWrapper(withRouter(connect(mapStateToProps)(StatusDownloadsPage)));

/* eslint-enable no-undef */
const scoreLoadFromPortalDataService = {
  type: 'PORTAL_DATA_LOAD',
  query: (state) => {
    const framework = get(state, 'framework.kde');
    const dataQueries = [];
    const currentOrganization = get(state, 'module.currentOrganization');
    const organizations = currentOrganization.entity_type === 'School' ? [currentOrganization] : get(state, 'module.childOrgs');
    const organizationIds = organizations.map(org => getOrgIdFromOrganization(org));
    const featureFlags = get(state, 'module.pageContext.featureFlags');

    let dataPoints = allDataPoints(framework, { editable: true });
    dataPoints = filter(dataPoints, dataPoint => shouldUsePortalDataService(dataPoint, featureFlags));

    const params = {
      organization_ids: organizationIds,
      data_points: dataPoints.map(node => ({
        slug: get(node, 'portal_data.slug', node.slug),
        key: node.node_path
      })),
      featureFlags,
      year: kdeCurrentSchoolYear()
    };
    dataQueries.push(params);

    return dataQueries;
  },
  stateKey: 'scoresFromPortalDataService',
  loadingIndicator: () => 'portalDataScores'
};

const sprScoreLoad = {
  type: 'PORTAL_DATA_LOAD',
  query: (state) => {
    const framework = get(state, 'framework.kde');
    const dataQueries = [];
    const currentOrganization = get(state, 'module.currentOrganization');
    const organizationIds = [getOrgIdFromOrganization(currentOrganization)];
    const featureFlags = get(state, 'module.pageContext.featureFlags');

    const dataPoints = allDataPoints(framework, { profileReport: true });

    const params = {
      organization_ids: organizationIds,
      data_points: dataPoints.map(node => ({
        slug: get(node, 'portal_data.slug', node.slug),
        key: node.node_path,
        dimensions: getDimensionsForOrg(node, currentOrganization)
      })),
      featureFlags,
      year: kdeCurrentSchoolYear()
    };
    dataQueries.push(params);

    return dataQueries;
  },
  stateKey: 'sprScores',
  loadingIndicator: () => 'sprScores'
};

export const config = {
  title: '{{name}} - Downloads - Kentucky School Report Card Approval',
  mapStateToProps: state => ({
    name: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase())
  }),
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        {
          type: 'FETCH_TOKEN',
          service: 'svcpd',
          slug: 'kde',
          actions: [
            {
              type: 'GRAPH_LOAD',
              service: 'svcpd',
              query: LOAD_CURRENT_ORG,
              loadingIndicator: 'currentOrganization',
              stateObjectMap: {
                currentOrganization: 'organization'
              },
              propMap: {
                id: 'params.id',
                year: 'module.schoolYear',
                child_organizations: 'params.childOrgs'
              },
              actions: [
                sprScoreLoad,
                {
                  type: 'GRAPH_LOAD',
                  service: 'svcpd',
                  query: `
                  query {
                    section_statuses(organization_id: [:organizationId], fiscal_year: :fiscalYear) {
                      id clarity_organization_id fiscal_year section_slug status flag_comments updated_by updated_at
                    }
                  }
                  `,
                  loadingIndicator: 'sectionStatuses',
                  propMap: {
                    fiscalYear: 'module.schoolYear',
                    organizationId: 'module.currentOrganization.children.@each.id'
                  },
                  stateObjectMap: {
                    sectionStatuses: 'section_statuses'
                  }
                },
                {
                  type: 'GRAPH_LOAD',
                  service: 'svcpd',
                  query: `
                  query {
                    organization(id: :organizationId) {
                      children(years: [:year]) {
                        id
                        name
                        entity_type
                        dist_number
                        dist_name
                        sch_number
                        sch_cd
                        sch_type
                        website_url
                        narrative(year: :year) {
                          body
                          author
                        }
                        district_message(year: :year) {
                          body
                          title
                          author
                        }
                      }
                    }
                  }
                  `,
                  loadingIndicator: 'childOrganizations',
                  propMap: {
                    organizationId: 'params.id',
                    year: 'module.schoolYear'
                  },
                  stateObjectMap: {
                    childOrgs: 'organization.children',
                    currentNodes: 'nodes',
                    currentNodePaths: 'nodePaths',
                    currentDataNodePaths: 'dataNodePaths'
                  },
                  actions: [
                    {
                      type: 'PLUCK_NODE_PATHS',
                      pluckNodes,
                      desiredNodeType: 'data_point',
                      propMap: {
                        domainSlug: 'params.domainSlug',
                        successIndicatorSlug: 'params.siSlug',
                        variableSlug: 'params.variableSlug',
                        framework: 'framework.kde',
                        featureFlags: 'module.pageContext.featureFlags',
                        currentOrganization: 'module.currentOrganization'
                      },
                      stateObjectMap: {
                        currentNodes: 'nodes',
                        currentNodePaths: 'nodePaths',
                        currentDataNodePaths: 'dataNodePaths'
                      },
                      actions: [
                        scoreLoadFromPortalDataService
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }),
    {
      type: 'FETCH_TOKEN',
      service: 'pdf_generator',
      slug: 'kde'
    }
  ]
};
