import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { chartScoreSelectorConfig } from '../../selectors/accountabilitySelector';
import Gauge from '../Gauge';
import styles from './GaugeGrid.module.scss';

const GaugeGrid = ({ gaugeScores, isLoading }) => {
  return (
    <div className={styles.grid}>
      {gaugeScores.scores.map(({ primary }) => {
        const hasData = !!primary.score;
        return (
          <>
            {
              hasData && <Gauge
                score={primary.colorIndex}
                title={primary.title}
                isLoading={isLoading}
              />
            }
          </>
        )
      })
      }
    </div >
  );
}

GaugeGrid.propTypes = {
  gaugeScores: PropTypes.arrayOf(PropTypes.object)
};


function mapStateToProps(state, props) {
  const scoreKey = get(props, 'node.charts.default.scoreKey');
  const chartScoreSelector = chartScoreSelectorConfig(get(props, 'node.node_path'));
  return {
    gaugeScores: chartScoreSelector(state).find(score => score.chartScoreKey === scoreKey)
  };
}

export default connect(mapStateToProps)(GaugeGrid);
