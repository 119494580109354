import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';

const defaultLoading = {
  sources: [],
  isLoading: false
};

export const selectLoading = state => get(state, 'loading', defaultLoading);

export const makeSelectIsLoading = () => createSelector(
  selectLoading,
  identity
);
