const dataPointStatusesLoad = {
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  query: `
    query DataPointStatuses(
      $organizationId: Int!
      $fiscalYear: Int!
      $nodePaths: [String]
    ) {
      section_statuses(
        organization_id: [$organizationId]
        fiscal_year: $fiscalYear
        node_paths: $nodePaths
      ) {
        sectionSlug: section_slug
        status
        dataIssues: data_issues {
          errorType: error_type
          comment
        }
      }
    }
  `,
  propMap: {
    fiscalYear: 'module.schoolYear',
    organizationId: 'module.currentOrganization.id',
    nodePaths: 'module.currentNodePathsSVCPD'
  },
  propVariablesToVariables: ['nodePaths', 'organizationId', 'fiscalYear'],
  stateObjectMap: {
    dataPointStatuses: 'section_statuses'
  }
};

export default dataPointStatusesLoad;
