import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';

import template from '../template';
import { currentOrgYearScores } from '../scoresHelpers';

const getYearFromData = (date) => {
  if (!date) return '';
  return (new Date(date)).getFullYear();
};

const formatDateRange = (score = {}) => {
  const { start_at: scoreStartAt, end_at: scoreEndAt } = score;
  const startAt = getYearFromData(scoreStartAt);
  const endAt = getYearFromData(scoreEndAt);
  return { startAt, endAt };
};

const getDateFromComparison = (entities, entityScores, node) => {
  const dates = reduce(entities, (acc, entity) => {
    const entityScore = currentOrgYearScores(entityScores, entity, node);
    const { startAt, endAt } = formatDateRange(entityScore);
    const [intStartAt, intEndAt] = [parseInt(startAt), parseInt(endAt)];
    if (!acc.includes(intStartAt)) acc.push(intStartAt);
    if (!acc.includes(intEndAt)) acc.push(intEndAt);
    return acc;
  }, []).sort();
  return { startAt: dates[0], endAt: dates[dates.length - 1] };
};

const getScoreDates = (options) => {
  const {
    scores,
    directScore,
    organization,
    node,
    entities,
    entityScores,
    comparison = {},
    dateTemplate = '{{startAt}} - {{endAt}}'
  } = options;

  const { useEntityScores } = comparison;

  let dateVariables;
  if (!isEmpty(entityScores) && useEntityScores) {
    dateVariables = getDateFromComparison(entities, entityScores, node);
  } else {
    const hasDirectScore = directScore && !isEmpty(directScore);

    const score = hasDirectScore ? directScore : currentOrgYearScores(scores, organization, node);
    dateVariables = formatDateRange(score);
  }

  const { startAt, endAt } = dateVariables;
  const datesAreInvalid = startAt === '' || endAt === '';
  if (datesAreInvalid) return '';
  return template(dateTemplate, { ...dateVariables, ...options });
};

export default getScoreDates;
