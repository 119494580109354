import React from 'react';
import PropTypes from 'prop-types';

const ImLink = ({ hasActiveIntervention, id, organizationId }) => {
  if (hasActiveIntervention) {
    const url = `/modules/intervention_management/referral_tool/schools/${organizationId}/students/${id}/intervention_items`;
    return (<div><a href={url}>View Plans</a></div>);
  } else {
    return (<div>No</div>);
  }
};

ImLink.propTypes = {
  hasActiveIntervention: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  organizationId: PropTypes.number.isRequired
};

export default ImLink;
