import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'cui';

import styles from './Score.module.scss';

const ARROW_UP = 'above';
const ARROW_EQUAL = 'same as';
const ARROW_DOWN = 'below';

const ARROW_IMAGES = {
  [ARROW_DOWN]: {
    positive: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/down-positive.png',
    negative: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/down-negative.png',
    alt: ''
  },
  [ARROW_UP]: {
    positive: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/up-positive.png',
    negative: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/up-negative.png',
    alt: ''
  },
  [ARROW_EQUAL]: {
    positive: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/equals.png',
    negative: 'https://bb-production-assets.s3.us-east-1.amazonaws.com/kde/src-pdf/equals.png',
    alt: ''
  }
};

// determine the proper lenth of the bar, i.e. "percentage", based on the score passed combined with the low/high range
function comparison(score, average) {
  if (!Number.isFinite(score) || !Number.isFinite(average)) {
    return 'none';
  }

  const difference = score - average;

  if (difference < 0) {
    return ARROW_DOWN;
  } else if (difference > 0) {
    return ARROW_UP;
  } else if (difference === 0) {
    return ARROW_EQUAL;
  }

  return difference;
}

function Arrow({ direction, upArrowIsPositive }) {
  const imageProps = ARROW_IMAGES[direction];
  let key;

  if (upArrowIsPositive) {
    key = direction === ARROW_UP ? 'positive' : 'negative';
  } else {
    key = direction === ARROW_DOWN ? 'positive' : 'negative';
  }

  // eslint-disable-next-line jsx-a11y/img-has-alt
  return imageProps ? (<img alt={imageProps.alt} src={imageProps[key]} />) : null;
}

function toFixed(value, precision) {
  return Number.isFinite(value) ? value.toFixed(precision) : value;
}

Arrow.propTypes = {
  direction: PropTypes.oneOf([ARROW_EQUAL, ARROW_DOWN, ARROW_UP]),
  upArrowIsPositive: PropTypes.bool
};

const Score = ({ title, score, average, percent, compareLabel, upArrowIsPositive }) => {
  const validScore = Number.isFinite(score);
  const validAverage = Number.isFinite(average);
  const direction = comparison(score, average);

  return (
    <div className={styles.Score}>
      { title && <Heading className={styles.Score_title} level={3}>{title}</Heading>}
      {
        validScore && (
          <div className={styles.Score_elements}>
            <div className={styles.Score_score}>
              {toFixed(score, 1)}{percent && <span>%</span>}
            </div>
            {
              validAverage && (
                <div className={styles.Score_direction}>
                  <Arrow direction={direction} upArrowIsPositive={upArrowIsPositive} />
                </div>
              )
            }
            {
              validAverage && (
                <div className={styles.Score_avg}>
                  {direction} {compareLabel} <span>{average}{toFixed(percent, 1) && <span>%</span>}</span>
                </div>
              )
            }
          </div>
        )
      }
      {
        !validScore && (
          <p className="cui-text_italic cui-font-bold">No Data Reported</p>
        )
      }
    </div>
  );
};

Score.propTypes = {
  title: PropTypes.string,
  score: PropTypes.number,
  average: PropTypes.number,
  percent: PropTypes.bool,
  compareLabel: PropTypes.string,
  upArrowIsPositive: PropTypes.bool
};

Score.defaultProps = {
  compareLabel: 'state average of',
  upArrowIsPositive: true
};

export default Score;
