import get from 'lodash/get';

export const defaultLabel = 'School Provided Resource';

const textLinkAdapter = (score) => {
  if (typeof score.value === 'object') {
    return {
      label: defaultLabel,
      ...score,
      ...score.value,
      value: get(score, 'value.score') || get(score, 'value.value')
    };
  }

  return {
    label: defaultLabel,
    ...score
  };
};

export default textLinkAdapter;
