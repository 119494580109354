import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Select from 'cui/lib/components/Form/Select';
import Icon from 'cui/lib/components/Icon';

import CustomValue from './CustomValue';
import styles from './index.module.scss';

class MultiSelect extends React.PureComponent {
  onClearKeyDown = (event) => {
    if (event.keyCode === 13 || event.keyCode === 32) {
      this.onClearAll(event);
    }
  };

  onClearAll = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClearAll();
  };

  renderClearButton = () => (
    <button
      className={cx('cui-btn', styles.clearButton)}
      onMouseDown={this.onClearAll}
      onKeyDown={this.onClearKeyDown}
      tabIndex="0"
      aria-label="Clear all organizations from Compare"
    >
      <Icon name="bb-circle-cross" />
    </button>
  )

  render() {
    const { options, ...rest } = this.props;

    if (!options) return null;

    return (
      <Select
        multi
        clearRenderer={this.renderClearButton}
        valueComponent={CustomValue}
        options={options}
        {...rest}
      />
    );
  }
}

MultiSelect.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClearAll: PropTypes.func.isRequired
};

export default MultiSelect;
