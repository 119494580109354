import { createSelector } from 'reselect';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';

import { findNodeByNodePath } from '../helpers/frameworkHelpers';
import getMergedScores from '../helpers/scores';
import { makeSelectIsLoading } from './loadingSelector';
import { makeSelectFramework } from './frameworkSelector';
import { transformScoresFromPortalDataService } from  '../helpers/portalDataServiceHelpers';
import { generateOrgIdMap } from './accountabilitySelector';

const defaultModule = {};

export const selectModule = state => get(state, 'module', defaultModule);
export const selectPageContext = createSelector(selectModule, module => get(module, 'pageContext', {}));
export const selectFeatureFlags = createSelector(selectPageContext, pageContext => get(pageContext, 'featureFlags', {}));
export const selectIsPreviewSite = createSelector(selectPageContext, pageContext => get(pageContext, 'isPreviewSite', false));

const defaultCurrentNodes = [];

export const makeSelectCurrentNodes = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'currentNodes', defaultCurrentNodes)
);

const defaultCurrentOrganization = {};

export const makeSelectCurrentOrganization = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'currentOrganization', defaultCurrentOrganization)
);

export const makeSelectCurrentDataNodePaths = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'currentDataNodePaths')
);

const defaultScoresFromGroot = [];

export const makeSelectScoresFromGroot = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'scoresFromGroot', defaultScoresFromGroot)
);

const defaultScoresFromPortalDataService = {};

export const makeSelectScoresFromPortalDataService = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'scoresFromPortalDataService', defaultScoresFromPortalDataService)
);

export const makeSelectScoresFromPreviousYearData = () => createSelector(
  selectModule,
  // `module` is a reserved word
  mod => get(mod, 'previousYearScores', defaultScoresFromPortalDataService)
);

const defaultScores = {
  scores: [],
  isLoading: true
};

export const isAnyScoresAwait = (isLoadingData = {}) => {
  const sources = isLoadingData.sources || [];
  const isLoading = isLoadingData.isLoading || false;
  const isAnySource = sources.find(source => source.includes('score-'));

  return isAnySource && isLoading;
};

const getOrganizationsWithScoresAwaiting = (loadingData = {}) => ([
  ...new Set(
    get(loadingData, 'sources', [])
      .map((source) => {
        const match = /\D*-(\d+)$/g.exec(source);
        return match ? parseInt(match[1]) : null;
      })
      .filter(id => !!id)
  )
]);

// not sure if this is the best place for that, but I want to have same array if needed to avoid rendering
export const makeSelectScoresData = () => createSelector(
  makeSelectIsLoading(),
  makeSelectScoresFromGroot(),
  makeSelectScoresFromPortalDataService(),
  makeSelectCurrentNodes(),
  makeSelectCurrentOrganization(),
  makeSelectCurrentDataNodePaths(),
  (isLoadingData, scoresFromGroot, scoresFromPortalDataService, currentNodes, currentOrganization, currentDataNodePaths) => {
    const isLoading = isAnyScoresAwait(isLoadingData);

    // relates to `when: '{{Array.isArray(nodePaths) && nodePaths.length}}',`
    const isScoresFromGrootAllowed = Array.isArray(currentDataNodePaths) && currentDataNodePaths.length;

    const isAnyDefault = (
      (scoresFromGroot === defaultScoresFromGroot && isScoresFromGrootAllowed)
      || scoresFromPortalDataService === defaultScoresFromPortalDataService
      || currentNodes === defaultCurrentNodes
    );

    return (isLoading || isAnyDefault) ?
      ({
        ...defaultScores,
        organizationsWithScoresAwaiting: getOrganizationsWithScoresAwaiting(isLoadingData)
      }) : (
        {
          scores: getMergedScores({
            scoresFromGroot,
            scoresFromPortalDataService,
            currentOrganization,
            currentNodes
          }),
          isLoading: false,
          organizationsWithScoresAwaiting: []
        }
      );
  }
);

export const makePreviousYearsDataSelector = () => createSelector(
  makeSelectScoresFromPreviousYearData(),
  makeSelectFramework(),
  makeSelectCurrentOrganization(),
  (scores, framework, org) => {
    if (isEmpty(scores) || isEmpty(framework) || isEmpty(org)) {
      return [];
    }

    const years = Object.keys(scores);

    const nodePaths = years.reduce((memo, year) => memo.concat(Object.keys(scores[year])), []);

    const nodes = uniq(nodePaths)
      .map(nodePath => findNodeByNodePath(framework, nodePath))
      .filter(n => !!n);

    return years.reduce((memo, year) => (
      memo.concat(transformScoresFromPortalDataService(scores[year], nodes, generateOrgIdMap(org), parseInt(year)))
    ), []);
  }
);

export const currentOrganizationSelector = state => get(state, 'module.currentOrganization');

export const currentNodesSelector = state => get(state, 'module.currentNodes');

export const currentYearSelector = state => get(state, 'params.year');

export const featureFlagsSelector = state => get(state, 'module.pageContext.featureFlags');

export const schoolLevelSelector = state => get(state, 'params.schoolLevel');
