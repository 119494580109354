import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Icon from 'cui/lib/components/Icon';

const DataDropdownItem = ({ item, selectedItem }) => {
  const className = cn({
    'cui-dropdown-menu-link cui-flexbar': true,
    'cui-dropdown-menu-link_selected': item === selectedItem
  });

  return (
    <li>
      <a
        className={className}
        href="#"
        onClick={(event) => {
          event.preventDefault();
          item.onClick(event);
        }}
      >
        <span className="cui-flexbar-item">
          <Icon name="bb-check" className="cui-dropdown-menu-selectedIcon" />
        </span>
        <span className="cui-flexbar-item cui-flexbar-item_grow">
          {item.text}
        </span>
      </a>
    </li>
  );
};

DataDropdownItem.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.object
};

DataDropdownItem.defaultProps = {
  item: {},
  selectedItem: {}
};

export default DataDropdownItem;
