import React from 'react';
import PropTypes from 'prop-types';

import Table from 'cui/lib/components/Table';
import Progress from 'cui/lib/components/Progress';
import FlexBar from 'cui/lib/components/FlexBar';
import EmptyState from 'cui/lib/components/EmptyState';

import conditional from '../../helpers/conditional';
import template from '../../helpers/template';

import chartDataAdapter from '../../helpers/chartDataAdapters';
import styles from './statusAndChangeComparison.module.scss';

const StatusAndChangeCompare = (props) => {
  const { config = {}, chartType } = props;
  const data = chartDataAdapter(config.adapter || chartType, props);

  const performanceMap = {
    0: 'N/A',
    1: 'Lowest',
    2: 'Low',
    3: 'Medium',
    4: 'High',
    5: 'Highest'
  };

  const changeValueFormatter = value => (value > 0 ? `+${value} Points` : `${value} Points`);

  const rowRenderer = (item, i) => {
    const {
      status_label: statusLable,
      status_value: statusValue,
      change_label: changeLabel,
      change_value: changeValue,
      performance
    } = item.value || {};

    const valueItems = { statusLable, statusValue, changeLabel, changeValue };

    const shape = conditional(config.shape, {}, valueItems);
    const statusTemplate = template(shape.status_template || '{{statusValue}}', valueItems);

    return (
      <tr key={i}>
        <td>
          <p className="cui-text_heavy">
            {item.label}
          </p>
        </td>
        <td>
          <FlexBar>
            <FlexBar.Item grow>
              <Progress data={[{ percent: (performance * 20), colorIndex: item.colorIndex }]} theme="sbcssDashboard" />
            </FlexBar.Item>
            <FlexBar.Item>
              {performanceMap[performance]}
            </FlexBar.Item>
          </FlexBar>
        </td>
        <td>
          <div className="cui-text_bold">{statusLable}</div>
          <div className="cui-text_small">{statusTemplate}</div>
        </td>
        <td>
          <div className="cui-text_bold">{changeLabel}</div>
          <div className="cui-text_small">{changeValueFormatter(changeValue)}</div>
        </td>
      </tr>
    );
  };
  return (
    <div>
      {
        !data && <EmptyState kind="text" title="There is no data available." />
      }
      {data && <Table
        kind="simple"
        data={data}
        renderRow={rowRenderer}
        className={styles.statusTable}
      >
        <Table.ColumnHeader />
        <Table.ColumnHeader><h5 className="cui-text_bold cui-text_muted cui-text_small">ALL STUDENTS PERFORMANCE</h5></Table.ColumnHeader>
        <Table.ColumnHeader><h5 className="cui-text_bold cui-text_muted cui-text_small">STATUS</h5></Table.ColumnHeader>
        <Table.ColumnHeader><h5 className="cui-text_bold cui-text_muted cui-text_small">CHANGE</h5></Table.ColumnHeader>
      </Table>}
    </div>
  );
};

StatusAndChangeCompare.propTypes = {
  config: PropTypes.shape({
    adapter: PropTypes.string
  }),
  chartType: PropTypes.string
};

export default StatusAndChangeCompare;
