import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';

import { canSeeApprovalTab } from '../helpers/approvalVisibilityHelpers';
import Redirect from '../components/Link/Redirect';

const Landing = ({ pageContext, user, featureFlags }) => {
  if (!pageContext) return <div />;

  if (user) {
    if (user.currentOrganization.id === 20) return <Redirect to="/status/20/children" />;
    if (!canSeeApprovalTab(user, featureFlags)) return <Redirect to={`/status/${user.currentOrganization.id}/downloads`} />;
    return <Redirect to={`/status/${user.currentOrganization.id}`} />;
  } else {
    return <Redirect to="/home" />;
  }
};

Landing.propTypes = {
  user: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.int
    })
  }),
  pageContext: PropTypes.object,
  featureFlags: PropTypes.object
};

const mapStateToProps = state => ({
  pageContext: get(state, 'module.pageContext'),
  user: get(state, 'module.userContext'),
  featureFlags: get(state, 'module.pageContext.featureFlags')
});

export default withRouter(connect(mapStateToProps)(Landing));

export const config = {
  initActions: []
};
