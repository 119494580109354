import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import get from 'lodash/get';

import Divider from 'cui/lib/components/Divider';
import Heading from 'cui/lib/components/Heading';
import Icon from 'cui/lib/components/Icon';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import buildLoadFrameworkAction from '../actions/framework';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../helpers/scoreLoadActions';
import { getYearSpecificCopy } from '../helpers/frameworkTreeTraversalHelper';
import Footer from '../components/Footer';
import Masthead from '../components/Masthead';
import StaticPageContent from '../components/StaticPageContent';
import MainContentAnchor from '../components/MainContentAnchor';
import pageTitle from '../helpers/pageHelpers';

const OtherData = ({ framework }) => {
  const otherDataShowAbout = get(framework, 'metadata.otherDataShowAbout', true);

  return (
    <div>
      <ScrollToTopOnMount />
      <Masthead withSearchBar />
      <MainContentAnchor />
      <StaticPageContent>
        <div role="main">
          <Heading size="xxLarge" level={1}>Other Education Data</Heading>
          <div className="cui-textBlock">
            <Divider />
            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/CommOfEd/Pages/KDE-Strategic-Dashboard.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
              KDE Strategic Dashboard <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
              Key measures on goals put in place towards advancing student learning across the Commonwealth. More detail is available on the
              <a href="https://education.ky.gov/CommOfEd/Pages/KDE-Strategic-Dashboard.aspx" target="_blank" rel="noopener noreferrer"> KDE Strategic Dashboard webpage.</a>
            </p>
            <Divider />
            {otherDataShowAbout && (
              <>
                <Heading size="base" level={2}>
                  <a
                    href={getYearSpecificCopy(framework, 'accountabilitySystemLink')}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                  About Kentucky’s Accountability System <Icon name="bb-arrow-box-top" />
                  </a>
                </Heading>
                <p>
                An overview of Kentucky’s accountability system is available that includes how schools,&nbsp;
                districts and the state are being evaluated and more information about how the system is structured.
                </p>
                <Divider />
              </>
            )}

            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/school/prischedrecov/Documents/CSI%20School%20Roster.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
              Comprehensive Support and Improvement (CSI) Schools <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
            Schools in the lowest performing 5% of all schools by level (elementary, middle or high school)&nbsp;
            and/or high schools with a graduation rate less than 80%.
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/school/focschls/Documents/ATSI%20School%20Roster.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
              Additional Targeted Support and Improvement (ATSI) Schools <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>{getYearSpecificCopy(framework, 'otherData.ATSISchools') || (
              <>Schools that were identified as ATSI, which previously was referred to as Targeted Support and&nbsp;
              Improvement Tier II – Low-performing Subgroup(s), and failed to meet the exit criteria outlined in 703 KAR 5:280, Section 15.</>
            )}</p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/school/focschls/Documents/Targeted%20Support%20and%20Improvement%20School%20Roster.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
              Targeted Support and Improvement (TSI) <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
            Schools that have one or more student subgroups, as defined by the Every Student Succeeds Act (ESSA),
            whose performance in the state accountability system is at or below the summative performance of all
            students in any of the lowest-performing five percent (5%) of all schools.
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href={getYearSpecificCopy(framework, 'schoolReportCardDataSetsLink')}
                target="_blank"
                rel="noopener noreferrer"
              >
              School Report Card Data Sets <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
            Data files are available to assist with analyzing school, district and statewide data from the School Report Card.
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://openhouse.education.ky.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
              Kentucky’s Open House Website <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
            Supplemental and prior year data is available on Kentucky’s Open House website.
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://kystats.ky.gov/"
                target="_blank"
                rel="noopener noreferrer"
              >
              Kentucky Center for Statistics <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
            The Kentucky Center for Statistics (KYStats) collects and integrates education and workforce&nbsp;
            data so that policymakers, practitioners and the public can make informed decisions.&nbsp;
            KYStats produces a High School Feedback Report annually for all public high schools.
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://openhouse.education.ky.gov/Home/SRCData"
                target="_blank"
                rel="noopener noreferrer"
              >
                2011-2019 Kentucky School Report Card Data Sets <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
              School Report Card historical data sets
            </p>
            <Divider />

            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/federal/progs/sigi/Pages/default.aspx"
                target="_blank"
                rel="noopener noreferrer"
              >
              School Improvement Fund and Allocation Use <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
              {getYearSpecificCopy(framework, 'otherData.schoolImprovementFundAndAllocationUse') || (
                <>Schools identified as receiving School Improvement Funds (ESSA Section 1003), the amount of&nbsp;
                each sub-grant and the interventions or strategies implemented using the funds.</>
              )}
            </p>
            <Divider />
            <Heading size="base" level={2}>
              <a
                href="https://education.ky.gov/districts/tech/sis/Pages/Historical_CRDC_Data.aspx"
                rel="noopener noreferrer"
                target="_blank"
              >
              Civil Rights Data Collection <Icon name="bb-arrow-box-top" />
              </a>
            </Heading>
            <p>
              {getYearSpecificCopy(framework, 'otherData.civilRightsDataCollection')}
            </p>
          </div>
        </div>
      </StaticPageContent>
      <Footer />
    </div>
  );
};

export const config = {
  title: pageTitle('Other Education Data'),
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION
      ]
    })
  ]
};

const mapStateToProps = state => ({
  framework: get(state, 'framework.kde')
});

OtherData.propTypes = {
  framework: PropTypes.object
};

export default withRouter(connect(mapStateToProps)(OtherData));
