/**
 * @document
 *   @category JS
 *   @subcategory Common
 *   @title DonutChart
 *   @description
 *   Shows Donut Chart
 * @example
 *   @title Basic example
 *   @type jsx
 *   @code
 *     <script>
 *        const props = {
 *     scores: [
 *       {
 *         remote_organization_id: 57,
 *         value: {
 *           chocolate: 60000,
 *           jelly: 40000
 *         }
 *       }
 *     ],
 *     node: {
 *       scoring_options: {
 *         shape: {
 *           chocolate: { label: 'Chocolate', colorIndex: 0 },
 *           jelly: { label: 'Jelly', colorIndex: 1 }
 *         }
 *       }
 *     },
 *     organization: {
 *       id: 57
 *     }
 *   };
 *     </script>
 *    <commons.DonutChart {...props} />
 */
import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { DonutWithLegend } from 'cui/lib/components/LegendWrapper';
import Heading from 'cui/lib/components/Heading';

import EmptyState from 'cui/lib/components/EmptyState';

import dynamicFormatter from '../../helpers/dynamicFormatter';
import rightCompactWithTrendLegend from './customLegendRenderings/rightCompactWithTrendLegend';
import rightWithTrendLegend from './customLegendRenderings/rightWithTrendLegend';

import conditional from '../../helpers/conditional';
import currentOrgScores from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';

export const DonutChart = (props) => {
  const { chartType, directShape, node, organization, scores, theme, additionalChartAdapters, config = {} } = props;
  const { options = {} } = config;

  const scoringOptions = get(node, 'scoring_options', {});
  const shape = directShape || conditional(scoringOptions.shape, {}, props);
  const score = currentOrgScores(scores, organization);
  const customLegendRenderers = {
    rightCompactWithTrendLegend,
    rightWithTrendLegend
  };

  const dataOptions = {
    ...props,
    shape,
    customLegendRenderers,
    currentOrgScore: score,
    center: scoringOptions.center,
    additionalChartAdapters
  };

  const data = chartDataAdapter(config.adapter || chartType, dataOptions);
  const title = data.title || node.title;

  // existing chart configs use
  //   scoring_options:
  //     center: true (should render center at all)
  //     totalLabel: {text} (label for the value)
  // to indecate that we should render the total in the center.
  // the value is ALWAYS the total.
  let total = null;
  // Checks to see if adapter type is deifferent than chartType
  if (scoringOptions.center && config.adapter) {
    total = dataMaxHelper[config.adapter]({ ...props, currentOrgScore: score });
  } else if (scoringOptions.center) {
    total = dataMaxHelper[chartType]({ ...props, currentOrgScore: score });
  }

  const chartProps = { ...data.chartProps, ...options };

  const renderChart = () => (
    <div>
      {title && (
        <Heading level={3} className="cui-margin-bottom-large theme-global-neutral-fg-7" size="small" caps>{title}</Heading>
      )}
      <DonutWithLegend
        theme={theme}
        {...data}
        chartProps={chartProps}
      >
        { scoringOptions.center  &&
          <div data-test="donut-center">
            <h2 className="cui-margin-bottom-none align-center">
              { dynamicFormatter('roundNumber', { value: total }) }
            </h2>
            <h5>{ scoringOptions.totalLabel || 'Total' }</h5>
          </div>
        }
        { !isUndefined(data.centerValue) &&
          <div data-test="donut-center">
            <h2 className="cui-margin-bottom-none align-center">{ data.centerValue }</h2>
            <h5>{ data.centerLabel }</h5>
          </div>
        }
      </DonutWithLegend>
    </div>
  );

  return (
    <div>
      {!data && <EmptyState data-test="donut-chart-empty-state" kind="text" title="There is no data available."  />}
      {data && renderChart()}
    </div>
  );
};

DonutChart.propTypes = {
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  node: PropTypes.object,
  organization: PropTypes.object,
  chartType: PropTypes.string,
  config: PropTypes.object,
  theme: PropTypes.string,
  directShape: PropTypes.object,
  additionalChartAdapters: PropTypes.object
};

DonutChart.defaultProps = {
  scores: [],
  node: {},
  organization: {},
  chartType: '',
  config: {},
  theme: '',
  directShape: null
};

export default DonutChart;
