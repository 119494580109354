import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { highlightItem } from 'cui/lib/components/Autocomplete/HighlightedItem';
import InfoPopover from 'cui/lib/components/InfoPopover';
import template from '../../../helpers/template';

export const HighlightFormatter = (props) => {
  const { value, templateString, valueKey, formatterClasses, params, labelMap, infoIconMap } = props;
  let infoIcon = false;
  let theValue = value;
  if (valueKey) {
    theValue = get(value, valueKey);
  }
  if (labelMap) {
    theValue = get(labelMap, theValue, theValue);
  }
  const compiledValue = template(templateString, { value: theValue });

  if (infoIconMap && infoIconMap[value]) {
    infoIcon = infoIconMap[value];
  }
  return (
    <span className={formatterClasses}>
      {highlightItem(compiledValue, params.search, false)}
      {infoIcon && <InfoPopover content={infoIcon} />}
    </span>
  );
};

HighlightFormatter.propTypes = {
  value: PropTypes.string,
  templateString: PropTypes.string,
  valueKey: PropTypes.string,
  formatterClasses: PropTypes.string,
  params: PropTypes.object,
  labelMap: PropTypes.object,
  infoIconMap: PropTypes.object
};

HighlightFormatter.defaultProps = {
  value: '',
  templateString: '{{value}}',
  valueKey: '',
  formatterClasses: ''
};

export default HighlightFormatter;
