import get from 'lodash/get';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import unset from 'lodash/unset';

import { transformData } from '../../../graphHelpers';

const adapter = (options) => {
  const { node, scores, showConfidenceIntervals, organization } = options;

  const index = findIndex(scores, { remote_organization_id: organization.id });
  const score = scores[index];

  if (scores && scores[0] && !showConfidenceIntervals) {
    Object.keys(score.value).forEach(key => unset(score.value, `${key}.confidenceInterval`));
  }

  const data = score && map(score.value, (value, key) => (
    {
      label: get(node, `scoring_options.shape.${key}.label`, key),
      key,
      value: get(value, 'value', value), // gets nested values, as in { value: { value: 3 } }, used to include additional data per key
      colorIndex: get(node, `scoring_options.shape.${key}.colorIndex`, 0),
      confidenceInterval: get(value, 'confidenceInterval', undefined),
      index: get(node, `scoring_options.shape.${key}.index`, null)
    }
  ));

  return transformData(data, node.scoring_options);
};

export default adapter;
