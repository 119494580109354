import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

const LabelCell = ({ labels = {}, item = {} }) => (
  <div>{get(labels, item.key, item.key)}</div>
);

LabelCell.propTypes = {
  labels: PropTypes.objectOf(PropTypes.string).isRequired,
  item: PropTypes.object
};

LabelCell.defaultProps = {
  item: {}
};

export default LabelCell;
