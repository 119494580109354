import React from 'react';
import get from 'lodash/get';
import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import { sortByExactOrders } from 'chartDataAdapters/sortBy';
import { getParentData, getDataForOrganization } from 'helpers/portalDataServiceHelpers';
import template from 'helpers/template';
import FILTERS_OPTIONS from 'helpers/data_dropdown_helper/filtersOptions';

const DEFAULT_ORDER = ['school', 'district', 'state'];

const EMPTY_DATA_PLACEHOLDER = 'No Data Reported';

const byLevelAndStudentGroup = (chartDataByOrgType, scoreKey, filters = []) => {
  const studentGroups = flatten([filters[1]]);
  const level = filters[0];

  return studentGroups.map(studentGroup => {
    const dimensionDataByOrgType = chartDataByOrgType.map(organizationData => ({
      ...get(
        organizationData,
        [scoreKey, 'dimensions', level],
        organizationData[scoreKey]
      ),
      key: organizationData.key
    }));

    return dimensionDataByOrgType.map(dimension => ({
      ...get(dimension, ['dimensions', studentGroup, 'score'], dimension.score),
      key: dimension.key,
      filters: [level, studentGroup]
    }));
  });
};

const byStudentGroupAndPriorSetting = (chartDataByOrgType, scoreKey, filters) => {
  const studentGroups = flatten([filters[1]]);
  const priorSetting = filters[0];

  return studentGroups.map(studentGroup => {
    const dimensionDataByOrgType = chartDataByOrgType.map(organizationData => ({
      ...get(
        organizationData,
        [scoreKey, 'dimensions', studentGroup],
        organizationData[scoreKey]
      ),
      key: organizationData.key
    }));

    return dimensionDataByOrgType.map(dimensionData => ({
      ...get(dimensionData, ['score', priorSetting], dimensionData.score),
      key: dimensionData.key,
      filters: [priorSetting, studentGroup]
    }));
  });
};

const FILTER_TYPES = {
  byLevelAndStudentGroup,
  byStudentGroupAndPriorSetting
};

const filterByDropdownSelection = (chartData, filters, options) => {
  const scoreKey = get(options, 'node.slug');
  const { filterType } = get(options, 'chartConfig.options.compareChart', {});

  const chartDataByOrgType = Object.entries(chartData).map(([key, value]) => ({
    key: String(key).toLowerCase(),
    ...value
  }));

  return FILTER_TYPES[filterType](chartDataByOrgType, scoreKey, filters);
};

const getChartData = options => {
  const { currentOrganization, additionalOrganizations = [], node, allScores } = options;

  const isCompare = additionalOrganizations.length > 0;

  if (isCompare) {
    return [currentOrganization, ...additionalOrganizations].reduce((acc, org) => {
      acc[org.name] = getDataForOrganization(allScores, node, org);
      return acc;
    }, {});
  } else {
    return {
      ...getParentData(allScores, currentOrganization, node),
      [currentOrganization.entity_type]: getDataForOrganization(
        allScores,
        node,
        currentOrganization
      )
    };
  }
};

export const getTopLabel = (value, denominator) => {
  if (value === undefined) return EMPTY_DATA_PLACEHOLDER;
  if ([denominator, value].includes('*')) return '*';
  return '';
};

export const getPopoverItem = (
  value,
  denominator,
  popoverLegendLabel,
  popoverLegendValue
) => {
  const topLabel = getTopLabel(value, denominator);
  if (topLabel) return <span className="legend-label">{topLabel}</span>;
  if (denominator === '0') return <span className="legend-label">0 students</span>;

  return (
    <div>
      <span className="legend-value">
        {popoverLegendValue
          ? template(popoverLegendValue, { value, denominator })
          : `${value}%`}
      </span>
      {popoverLegendLabel && (
        <span className="legend-label">
          <em className="u-indent">
            {template(popoverLegendLabel, { value, denominator })}
          </em>
        </span>
      )}
    </div>
  );
};

const barValue = value => (isFinite(value) ? Math.max(Number(value), 0.5) : 0.5);

const getValue = (score, index, options, filters) => {
  const {
    valueKey: valueKeyOption,
    denominatorKey,
    keyPresentation,
    popoverLegendLabel,
    popoverLegendValue
  } = options;
  const valueKey = template(valueKeyOption, { filters }).split(',');

  if (valueKey.length > 1) {
    const values = valueKey.map(key => ({
      key,
      value: score[key]
    }));

    const allSuppressed = values.reduce(
      (acc, el) => acc && ['', '*'].includes(String(el.value)),
      true
    );

    if (allSuppressed) {
      return values.map((el, idx) => ({
        value: idx === 0 ? 0.5 : 0,
        suppressed: true,
        denominator: score[denominatorKey],
        label: keyPresentation[valueKey[idx]],
        popoverItem: getPopoverItem(
          el.value,
          score[denominatorKey],
          popoverLegendLabel,
          popoverLegendValue
        ),
        topLabel: idx === 0 ? '*' : '',
        colorIndex: idx === 0 ? `0${index}` : `0${index}00${idx}`
      }));
    } else {
      return values.map((el, idx) => ({
        value: Number(el.value),
        denominator: score[denominatorKey],
        label: keyPresentation[el.key],
        popoverItem: getPopoverItem(
          el.value,
          score[denominatorKey],
          popoverLegendLabel,
          popoverLegendValue
        ),
        type: idx === 3 ? 'striped' : 'solid',
        colorIndex: idx === 0 ? `0${index}` : `0${index}00${idx}`
      }));
    }
  }

  if (score[valueKey[0]] === '*') {
    return [
      {
        value: barValue(score[valueKey[0]]),
        suppressed: true,
        denominator: score[denominatorKey],
        label: keyPresentation[valueKey[0]],
        popoverItem: getPopoverItem(
          '*',
          score[denominatorKey],
          popoverLegendLabel,
          popoverLegendValue
        ),
        topLabel: '*',
        colorIndex: index
      }
    ];
  } else {
    return [
      {
        value: barValue(score[valueKey[0]]),
        label: keyPresentation[valueKey[0]],
        colorIndex: index
      }
    ];
  }
};

const groupChartData = chartPoints => {
  const organizations = Array.from(
    new Set(chartPoints.map(point => point.organizationName))
  );
  const dataGroups = Array.from(new Set(chartPoints.map(point => point.dataGroup)));

  if (organizations.length > 1 && dataGroups.length > 1) {
    return organizations.map(orgName => ({
      chartTitle: orgName,
      data: chartPoints.filter(point => point.organizationName === orgName)
    }));
  }

  return [{ chartTitle: '', data: chartPoints }];
};

const adapter = (currentOrgScore, options) => {
  const chartData = getChartData(options);
  const filters = options.viewingDropdownFilter;
  const scoreDataByStudentGroup = filterByDropdownSelection(chartData, filters, options);

  const chartPoints = scoreDataByStudentGroup
    .map((scores, studentGroupIdx, dataGroups) => {
      const sortedData = sortByExactOrders(scores, DEFAULT_ORDER, 'key');
      return sortedData.map((scoreData, orgIdx) => {
        const colorIndex = dataGroups.length === 1 ? orgIdx : studentGroupIdx;
        const value = getValue(
          scoreData,
          colorIndex,
          get(options, 'chartConfig.options.compareChart', {}),
          filters
        );
        const dataGroup =
          FILTERS_OPTIONS[scoreData.filters[scoreData.filters.length - 1]]?.text;

        return {
          value,
          label:
            dataGroups.length === 1 ? startCase(scoreData.key) : startCase(dataGroup),
          organizationName: startCase(scoreData.key),
          dataGroup,
          colorIndex
        };
      });
    })
    .flat();

  return groupChartData(chartPoints);
};

export default adapter;
