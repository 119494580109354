import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { scroller, Element } from 'react-scroll';

import Heading from 'cui/lib/components/Heading';

import styles from './index.module.scss';

class GlossaryList extends React.PureComponent {
  componentDidUpdate(prevProps) {
    const prevItemId = prevProps.selectedGlossaryItemId;
    const currentItemId = this.props.selectedGlossaryItemId;
    if (currentItemId && prevItemId !== currentItemId) {
      scroller.scrollTo(this.props.selectedGlossaryItemId, {
        duration: 500,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
    }
  }

  render() {
    return (
      <div role="main">
        {Object.keys(this.props.data).map((key, itemIndex) =>
          <section className="glossarySection" key={itemIndex} id={key}>
            <div className="glossarySection-letter" tabIndex="-1">
              <Heading level={2} size="xLarge">{key}</Heading>
            </div>

            <div className="glossarySection-entries">
              {this.props.data[key].map((glossaryWord, index) => {
                const id = encodeURIComponent(glossaryWord.ELEMENT_NAME);
                return (
                  <Element name={id} key={index} className="glossarySection-entry">
                    <Heading
                      level={3}
                      size="small"
                      className={cx({ [styles.headingHighlighted]: id === this.props.selectedGlossaryItemId })}
                    >
                      {glossaryWord.ELEMENT_NAME}
                    </Heading>
                    <p className="glossarySection-definition" dangerouslySetInnerHTML={{ __html: glossaryWord.ELEMENT_DESC }} />
                  </Element>
                );
              })}
            </div>
          </section>)}
      </div>
    );
  }
}

GlossaryList.propTypes = {
  data: PropTypes.object.isRequired,
  selectedGlossaryItemId: PropTypes.string
};

export default GlossaryList;
