import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import template from '../../../template';
import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, scores, params } = options;
  const scoringOptions = node.scoring_options || {};
  const trendLines = conditional(scoringOptions.trend_lines, {}, options);
  const valueMap = {
    H: { value: 7.5, color: 8 },
    M: { value: 4.5, color: 5 },
    L: { value: 1.5, color: 2 },
    NA: { value: 0, color: 0 }
  };
  const isDomain = params.node_type && params.node_type === 'domain';
  /* eslint-disable no-useless-escape */
  const data = trendLines && sortBy(map(trendLines, value => ({
    risk: template(get(value, 'risk'), options),
    label: template(get(value, 'risk'), options),
    showLine: get(value, 'show_line'),
    showPoints: get(value, 'show_points'),
    colorIndex: get(value, 'colorIndex'),
    symbol: 'circle',
    category: template(get(value, 'risk'), options),
    values: map(scores, (score) => {
      const theScore = (!isDomain && !get(score, 'bucket')) ? get(score, 'bucket') : get(score, 'bucket') - 0.5;
      return {
        x: new Date(get(score, 'date').replace(/-/g, '\/')),
        y: !isDomain ? theScore  : valueMap[get(score, 'risk')].value,
        colorIndex: !isDomain ? get(score, 'bucket') : valueMap[get(score, 'risk')].color,
        score
      };
    }),
    index: get(value, 'index')
  })
  ), ['index']);

  return data.length && scores.length ? data : undefined;
  /* eslint-enable no-useless-escape */
};

export default adapter;
