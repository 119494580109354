import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import get from 'lodash/get';
import Heading from 'cui/lib/components/Heading';

import ScrollToTopOnMount from '../../components/ScrollToTopOnMount';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../../helpers/scoreLoadActions';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import Footer from '../../components/Footer';
import Masthead from '../../components/Masthead';
import StaticPageContent from '../../components/StaticPageContent';
import GlossaryWrapper from '../../components/Glossary';
import MainContentAnchor from '../../components/MainContentAnchor';
import pageTitle from '../../helpers/pageHelpers';
import GlossaryData2018 from './GlossaryData2018.json';
import GlossaryData2019 from './GlossaryData2019.json';
import GlossaryData2020 from './GlossaryData2020.json';
import GlossaryData2021 from './GlossaryData2021.json';
import GlossaryData2022 from './GlossaryData2022.json';
import GlossaryData2023 from './GlossaryData2023.json';

const GLOSSARY_DATA = {
  2018: GlossaryData2018,
  2019: GlossaryData2019,
  2020: GlossaryData2020,
  2021: GlossaryData2021,
  2022: GlossaryData2022,
  2023: GlossaryData2023
};

const Glossary = ({ schoolYear }) => (
  <div>
    <ScrollToTopOnMount />
    <Masthead withSearchBar />
    <MainContentAnchor />
    <StaticPageContent>
      <Heading size="xxLarge" level={1}>Glossary</Heading>
      <GlossaryWrapper data={GLOSSARY_DATA[schoolYear]} />
    </StaticPageContent>
    <Footer />
  </div>
);

export const config = {
  title: pageTitle('Glossary'),
  initActions: [
    LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
    LOAD_FEATURE_FLAGS_ACTION
  ]
};

const mapStateToProps = state => ({
  schoolYear: get(state, 'module.schoolYear', '')
});

Glossary.propTypes = {
  schoolYear: PropTypes.number.isRequired
};

export default withRouter(connect(mapStateToProps)(Glossary));
