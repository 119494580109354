/* eslint-disable complexity  */
/* eslint-disable import/no-named-as-default */
import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import filter from 'lodash/filter';
import merge from 'lodash/merge';
import CuiLoadingSpinner from 'cui/lib/components/LoadingSpinner';

import ChartEmptyStateComponent from './ChartEmptyState';
import BarChartWithTitle from '../BarChartWithTitle';
import ProgressBarChart from '../ProgressBarChart';

import conditional from '../../helpers/conditional';
import template from '../../helpers/template';
import currentOrgYearScores from '../../helpers/scoresHelpers';
import when from '../../helpers/when';
import emptyStateMessage, { showEmptyState } from '../../helpers/emptyState';
import conditionalLoader from '../../helpers/conditionalLoader';
import getScoreDates from '../../helpers/getScoreDates';

import EntityBarChart from '../EntityBarChart';
import BarChart from '../BarChart';
import PercentBarChart from '../PercentBarChart';
import DateBarChart from '../DateBarChart';
import DonutChart from '../DonutChart';
import IconChart from '../IconChart';
import ConfidenceIntervalsBarChart from '../ConfidenceIntervalsBarChart';
import GroupedBarChart from '../GroupedBarChart';
import TableChart from '../TableChart';
import LcapTableChart from '../LcapTableChart';
import TrendsChart from '../TrendsChart';
import ProgressChart from '../ProgressChart';

import StatusChange from '../StatusChange';
import FilteredChart from '../FilteredChart';
import FilteredIconChart from '../FilteredIconChart';
import ComparisonFilteredChart from '../ComparisonFilteredChart';
import FilteredTrendsChart from '../FilteredTrendsChart';
import TableComparison from '../TableComparison';
import FilteredTableComparison from '../FilteredTableComparison';
import StatusAndChangeComparison from '../StatusAndChangeComparison';
import MultiValueListChart from '../MultiValueListChart';
import SurveyQuestions from '../KdeSurveyQuestions';

const componentMap = {
  barChart: BarChart,
  BarChartWithTitle,
  ProgressBarChart,
  SurveyQuestions,
  demographicBarChart: BarChart,
  entityBarChart: EntityBarChart,
  percentBarChart: PercentBarChart,
  demographicPercentBarChart: PercentBarChart,
  dateBarChart: DateBarChart,
  donutChart: DonutChart,
  demographicDonutChart: DonutChart,
  iconChart: IconChart,
  demographicIconChart: IconChart,
  confidenceIntervalsBarChart: ConfidenceIntervalsBarChart,
  groupedBarChart: GroupedBarChart,
  trendsChart: TrendsChart,
  stackedBarChart: BarChart,
  multiScoresDonutChart: DonutChart,
  multiFrameworkBarChart: BarChart,
  scoresBarChart: BarChart,
  newTableChart: TableChart, // FIXME: temporary designation. This will eventually be the only `tableChart`
  tableChart: LcapTableChart, // FIXME: remove this after LCAP framework tree is updated
  groupedScoreProgressChart: ProgressChart,
  entityComparisonBarChart: BarChart,
  demographicEntityComparisonBarChart: BarChart,
  yearlyTrendsChart: TrendsChart,
  demographicYearlyTrendsChart: TrendsChart,
  FilteredChart,
  FilteredIconChart,
  StatusChange,
  demographicEntityComparisonFilteredBarChart: ComparisonFilteredChart,
  filteredTrendsChart: FilteredTrendsChart,
  TableComparison,
  FilteredTableComparison,
  StatusChangeComparison: StatusAndChangeComparison,
  multiValueListChart: MultiValueListChart
};

export const Chart = (props) => {
  const {
    node,
    chart,
    scores,
    loading,
    comparison,
    organization,
    frameworkKey,
    paddedChart,
    showScoreDates,
    additionalChartAdapters,
    additionalChartTypes,
    entityScores
  } = props;
  const loadingOptions = props.loadingOptions || get(node, 'metadata.loadingOptions');
  const loadingWhen = props.loadingWhen || node.loading_when;
  let loadingProps,
    showLoading;

  if (loadingOptions !== undefined) {
    loadingProps = { loadingObject: loading, ...loadingOptions };
    showLoading = conditionalLoader(loadingProps);
  } else if (loadingWhen && loading.sources.length === 1 && loading.sources[0] !== 'scores') {
    showLoading = when(loadingWhen, { ...props, ...loading });
  } else {
    showLoading = loading.isLoading;
  }

  let currentOrgScore;

  if (has(node, 'scoring_options.multiScore')) {
    if (frameworkKey) {
      currentOrgScore = find(scores, { node_path: node.node_path, framework_type: frameworkKey });
    } else if (has(node, 'scoring_options.filteredByOrg')) {
      // there are multiple scores for this node
      currentOrgScore = {};
    } else {
      currentOrgScore = find(scores, { node_path: node.node_path });
    }
  } else {
    currentOrgScore = currentOrgYearScores(scores, organization, node);
  }

  const nodeCharts = has(node, 'scoring_options.multiChartNode') && filter(node.charts, c => c[chart]);

  const charts = conditional(nodeCharts || node.charts, {}, props);
  let config = merge(get(charts, chart, {}), { options: { animate: false } });
  let chartType = get(config, 'type', '');

  if (!isEmpty(comparison)) {
    currentOrgScore = {};
    config = merge(comparison.chart, { options: { animate: false } });
    chartType = config.type;
  }

  const { ignoreCurrentOrgScore = false } = config;

  // Make a copy of componentMap and merge in whatever local types are passed in.  Default is {}
  const localizedComponentMap = { ...componentMap, ...additionalChartTypes };

  // Assign the chart component
  const TheChart = localizedComponentMap[chartType];
  const score = scores[0];

  if (score && has(node, 'scoring_options.hasDataWhen')) {
    if (!when(node.scoring_options.hasDataWhen, score)) {
      scores.pop();
    }
  }

  let renderedChart = false;

  let hasNoScores = isEmpty(scores);

  if (hasNoScores) {
    hasNoScores = isEmpty(entityScores);
  }

  // If this chart should always render (no empty states), but with default zeroes. Bypass empty score checking
  const alwaysRender = get(config, 'alwaysRender', false);

  const hasCurrentOrgScore = !!currentOrgScore || ignoreCurrentOrgScore;
  if (showLoading) {
    renderedChart = <CuiLoadingSpinner request={new Promise((() => {}))} />;
  } else if (!TheChart || (!alwaysRender && showEmptyState(hasNoScores, hasCurrentOrgScore, node, currentOrgScore))) {
    renderedChart = (
      <ChartEmptyState
        emptyMessage={emptyStateMessage(node, organization)}
        emptySubtitle={get(node, 'emptySubtitle')}
      />
    );
  } else {
    renderedChart = (
      <div>
        <TheChart
          {...props}
          node={node}
          config={config}
          scores={scores}
          directScore={currentOrgScore}
          organization={organization}
          chartType={chartType}
          additionalChartAdapters={additionalChartAdapters}
        />
        {
          node && node.footnote && (
            <div className="cui-text_center cui-margin-small cui-margin-bottom-large">
              <strong>{node.footnote}</strong>
            </div>
          )
        }
      </div>);
  }

  const padChart = config.paddedChart || paddedChart;

  const renderScoreDates = () => {
    if (!showScoreDates) return '';
    let dateString;
    if (currentOrgScore) {
      dateString = getScoreDates({ ...props, directScore: currentOrgScore });
    } else {
      dateString = template(props.dateTemplate, { ...props });
    }
    return (
      <div className={currentOrgScore ? 'cui-chartDate' : 'cui-chartDate cui-chartDate_left'}>
        {dateString}
      </div>
    );
  };

  return (
    <div className={padChart ? 'cui-chart_padded' : ''}>
      {renderedChart}
      {!!renderedChart && renderScoreDates()}
    </div>
  );
};

Chart.propTypes = {
  node: PropTypes.object,
  organization: PropTypes.object,
  chart: PropTypes.string,
  loading: PropTypes.object,
  comparison: PropTypes.object,
  frameworkKey: PropTypes.string,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  paddedChart: PropTypes.bool,
  loadingOptions: PropTypes.object,
  showScoreDates: PropTypes.bool,
  nodeHasScores: PropTypes.bool,
  additionalChartAdapters: PropTypes.object,
  additionalChartTypes: PropTypes.object,
  entityScores: PropTypes.arrayOf(
    PropTypes.object
  ),
  loadingWhen: PropTypes.string,
  dateTemplate: PropTypes.string,
  featureFlags: PropTypes.object
};

Chart.defaultProps = {
  node: {},
  chart: 'default',
  scores: [],
  paddedChart: true,
  organization: {
    entity_type: ''
  },
  loading: {},
  frameworkKey: '',
  loadingOptions: undefined,
  comparison: {},
  additionalChartAdapters: {},
  additionalChartTypes: {},
  entityScores: []
};

export const chartPropTypes = {
  node: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
  scores: PropTypes.arrayOf(PropTypes.object),
  directScore: PropTypes.object,
  organization: PropTypes.object.isRequired,
  chartType: PropTypes.string,
  additionalChartAdapters: PropTypes.object
};

export const ChartEmptyState = ChartEmptyStateComponent;

export default Chart;
