import React from 'react';
import PropTypes from 'prop-types';

class WithYearAvailableForOrganization extends React.PureComponent {
  isYearAvailable() {
    return this.props.organization.years.includes(this.props.year);
  }

  render() {
    return this.props.children({ isYearAvailable: this.isYearAvailable() });
  }
}

WithYearAvailableForOrganization.propTypes = {
  organization: PropTypes.object,
  year: PropTypes.number,
  children: PropTypes.func.isRequired
};

export default WithYearAvailableForOrganization;
