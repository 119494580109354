import React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import pageTitle from '../../helpers/pageHelpers';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../../helpers/scoreLoadActions';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import Masthead from '../../components/Masthead';
import StaticPageContent from '../../components/StaticPageContent';
import Footer from '../../components/Footer';
import DataSetsList from '../../components/DataSetsList';
import DataSets2020 from './DataSetsIndex2020.json';
import DataSets2021 from './DataSetsIndex2021.json';
import DataSets2022 from './DataSetsIndex2022.json';
import DataSets2023 from './DataSetsIndex2023.json';

const dataSetsByYear = {
  2020: DataSets2020,
  2021: DataSets2021,
  2022: DataSets2022,
  2023: DataSets2023
};

const DataSets = ({ schoolYear }) => {
  const { lastUpdatedAtUrl, dataSets } = dataSetsByYear[schoolYear] || {};

  return (
    <div>
      <Masthead withSearchBar />

      <StaticPageContent>
        <h1>Data Sets</h1>
        <p className="cui-text cui-text_small theme-global-neutral-fg-3">
          Datasets are available to assist with analyzing school, district,
          regional and state data from the School Report Card. Each file contains
          consistent identifiers in the first 12 columns to assist with matching
          datasets. Datasets for school years 2011-2012 through 2018-2019 are
          accessible in the <a href="https://openhouse.education.ky.gov/Home/SRCData" target="_blank" rel="noopener noreferrer">Open House website</a>.
        </p>

        <DataSetsList dataSets={dataSets} lastUpdatedAtUrl={lastUpdatedAtUrl} />
      </StaticPageContent>

      <Footer />
    </div>
  );
};

export const config = {
  title: pageTitle('Data Sets'),
  initActions: [
    LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
    LOAD_FEATURE_FLAGS_ACTION
  ]
};

const mapStateToProps = state => ({
  schoolYear: get(state, 'module.schoolYear', '')
});

DataSets.propTypes = {
  schoolYear: PropTypes.number.isRequired
};

export default withRouter(connect(mapStateToProps)(DataSets));
