// extract each key value pair and place them in an array
// For example
// Case 1:
// const score = {
//   grade_1: { name: 'Music', count: 1 },
//   grade_2: { name: 'Music', count: 4 },
//   grade_3: { name: 'Music', count: 7 }
// }
//
// const result = [
//   { key: 'grade_1', value: { name: 'Music', count: 1 } },
//   { key: 'grade_2', value: { name: 'Music', count: 4 } },
//   { key: 'grade_3', value: { name: 'Music', count: 7 } }
// ]
//
// Case 2: merge nested object
// const score = {
//   grade_1: { name: 'Music', count: 1 },
//   grade_2: { name: 'Music', count: 4 },
//   grade_3: { name: 'Music', count: 7 }
// }
//
// const chartConfig = {
//   options: {
//     objectToArray: {
//        mergeNestedObject: true
//     }
//   }
// }
//
// const result = [
//   { key: 'grade_1', name: 'Music', count: 1 },
//   { key: 'grade_2', name: 'Music', count: 4 },
//   { key: 'grade_3', name: 'Music', count: 7 }
// ]

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import isObject from 'lodash/isObject';

export default function adapter(currentOrgScore, { chartConfig }) {
  if (!isEmpty(currentOrgScore)) {
    const mergeNestedObject = get(chartConfig, 'options.objectToArray.mergeNestedObject', false);

    return reduce(currentOrgScore, (memo, value, key) => {
      let score;
      // Merge nested object when mergeNestedObject is true
      if (mergeNestedObject && isObject(value)) {
        score = { key, ...value };
      } else {
        // Otherwise set the key value pair as key and value prop
        score = { key, value };
      }
      memo.push(score);

      return memo;
    }, []);
  }

  return currentOrgScore;
}
