import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import isPlainObject from 'lodash/isPlainObject';

const defaultFilterOptions = {
  All: {
    text: 'All',
    index: 0
  },
  K: {
    text: 'Kindergarten',
    index: 1
  },
  1: {
    text: '1st Grade',
    index: 2
  },
  2: {
    text: '2nd Grade',
    index: 3
  },
  3: {
    text: '3rd Grade',
    index: 4
  },
  4: {
    text: '4th Grade',
    index: 5
  },
  5: {
    text: '5th Grade',
    index: 6
  },
  6: {
    text: '6th Grade',
    index: 7
  },
  7: {
    text: '7th Grade',
    index: 8
  },
  8: {
    text: '8th Grade',
    index: 9
  },
  9: {
    text: '9th Grade',
    index: 10
  },
  10: {
    text: '10th Grade',
    index: 11
  },
  11: {
    text: '11th Grades',
    index: 12
  },
  12: {
    text: '12th Grade',
    index: 13
  }
};

const buildDropdownOptions = (keys, filterOptions = defaultFilterOptions, options = {}) => {
  const { sortKeys = ['index'], sortOptions = true } = options;

  const dropdownOptions = map(keys, (key) => {
    const selectedFilterOption = get(filterOptions, `${key}`, key);
    const filterOptionIsObject = isPlainObject(selectedFilterOption);
    const coercedKey = isNaN(key) ? key : Number(key);
    return filterOptionIsObject ? { ...selectedFilterOption, key } : { text: selectedFilterOption, key, index: coercedKey };
  });

  return sortOptions ? sortBy(dropdownOptions, sortKeys) : dropdownOptions;
};

export default buildDropdownOptions;
