import cn from 'classnames';
import React from 'react';
import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';

import styles from './ScrollToTopButton.module.scss';

export class ScrollToTopButton extends React.Component {
  state = { scrollY: 0 };

  componentDidMount() {
    window.addEventListener('scroll', () => this.setState({ scrollY: window.scrollY }));
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  render() {
    const className = cn({
      [styles.scrollToTopButton]: true,
      [styles.hidden]: this.state.scrollY <= window.innerHeight / 2
    });

    return (
      <Button
        size="large"
        className={className}
        onClick={this.scrollToTop}
        title="Back to Top"
      >
        <Icon name="bb-circle-up-arrow" />
      </Button>
    );
  }
}

export default ScrollToTopButton;
