import PropTypes from 'prop-types';

const propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    accessor: PropTypes.string.isRequired,
    cell: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string
    ]),
    header: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.string
    ])
  })),
  data: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string
  ]),
  description: PropTypes.string
};

export default propTypes;
