import React from 'react';
import Helmet from 'react-helmet';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import BlockTitle from 'cui/lib/components/BlockTitle';

import { kdeCurrentSchoolYear } from 'helpers/portals';
import useOrganizationQuery from 'store/backend/queries/organization';
import useSectionStatusesQuery from 'store/backend/queries/sectionStatuses';
import useEditableScoresQuery from 'store/backend/queries/editableScores';
import useFrameworkQuery from 'store/backend/queries/framework';
import useFeatureFlagsQuery from 'store/backend/queries/featureFlags';
import ApprovalHeader from '../../components/ApprovalHeader';
import ApprovalWrapper from '../../components/ApprovalWrapper';
import MainContentAnchor from '../../components/MainContentAnchor';
import BreakdownStatus from './BreakdownStatus';
import styles from '../Status.module.scss';

export const StatusPage = ({ match }) => {
  const orgId = Number(match.params.id);
  const year = kdeCurrentSchoolYear();
  const { data: currentOrganization, ...organizationQuery } = useOrganizationQuery(
    orgId,
    {
      includeChildren: true,
      year
    }
  );
  const childrenOrganizations = currentOrganization?.children ?? [];

  const { data: sectionStatuses, ...sectionStatusesQuery } = useSectionStatusesQuery(
    childrenOrganizations.map(org => org.id),
    year
  );

  const { data: scores = [] } = useEditableScoresQuery(childrenOrganizations, year);
  const { data: framework, ...frameworkQuery } = useFrameworkQuery(year);
  const { data: featureFlags, ...featureFlagsQuery } = useFeatureFlagsQuery({
    select: data => Object.fromEntries(data.map(el => [el.key, el.enabled]))
  });

  const isLoading =
    featureFlagsQuery.isLoading ||
    organizationQuery.isLoading ||
    frameworkQuery.isLoading ||
    sectionStatusesQuery.isLoading;

  if (isLoading) return null;

  return (
    <div className={styles.container}>
      <Helmet>
        <title>
          {startCase((currentOrganization.name ?? '').toLowerCase())}
          &nbsp;- Breakdown - Kentucky School Report Card Approval
        </title>
      </Helmet>

      <ApprovalHeader currentOrganization={currentOrganization} />
      <MainContentAnchor />
      <BlockTitle title="Breakdown" />
      <BreakdownStatus
        currentOrganization={currentOrganization}
        framework={framework}
        scores={scores}
        sectionStatuses={sectionStatuses}
        featureFlags={featureFlags}
      />
    </div>
  );
};

StatusPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

/* eslint-disable-next-line new-cap */
export default ApprovalWrapper(withRouter(StatusPage));
