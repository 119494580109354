import React from 'react';
import PropTypes from 'prop-types';

import FlexBar from 'cui/lib/components/FlexBar';
import Divider from 'cui/lib/components/Divider';

import GlossaryNav from './GlossaryNav';
import GlossaryList from './GlossaryList';
import GlossarySearch from './GlossarySearch';
import styles from './index.module.scss';

class GlossaryWrapper extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedGlossaryItemId: null
    };
  }

  onItemSelect = (selectedGlossaryItemId) => {
    this.setState({ selectedGlossaryItemId });
  }

  render() {
    return (
      <section>
        <FlexBar className={styles.glossaryFiltersBar}>
          <FlexBar.Item>
            <GlossaryNav data={this.props.data} className={styles.glossaryNav} />
          </FlexBar.Item>
          <FlexBar.Item>
            <GlossarySearch data={this.props.data} onItemSelect={this.onItemSelect} />
          </FlexBar.Item>
        </FlexBar>
        <Divider className="cui-margin-bottom-none" />
        <GlossaryList data={this.props.data} selectedGlossaryItemId={this.state.selectedGlossaryItemId} />
      </section>
    );
  }
}

GlossaryWrapper.propTypes = {
  data: PropTypes.object.isRequired
};

export default GlossaryWrapper;
