/**
 * @document
 *   @category Common
 *   @subcategory Components
 *   @title SliceFormatter
 *   @description
 *   Text formatter to slice text
 * @property
 *   @name value
 *   @type string
 *   @description
 *   Variable to be interpolated with templateString
 * @property
 *   @name templateString
 *   @type string
 *   @description
 *   String with handlebars to be combined with value  "Super cool string'{{value}}'"
 * @property
 *   @name start
 *   @type number
 *   @description
 *   Start of splice. Defaults to 0.
 * @property
 *   @name end
 *   @type number
 *   @description
 *   End of splice. Required.
 */

import React from 'react';
import PropTypes from 'prop-types';

import template from '../../../helpers/template';

const SliceFormatter = (props) => {
  const { value, start, end, templateString } = props;
  const compiledValue = template(templateString, { value });
  let slicedValue = compiledValue.slice(start, end);

  if (slicedValue === '') {
    slicedValue = compiledValue;
  }

  return <span>{slicedValue}</span>;
};

SliceFormatter.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number.isRequired,
  templateString: PropTypes.string,
  value: PropTypes.string
};

SliceFormatter.defaultProps = {
  templateString: '{{value}}',
  start: 0,
  value: ''
};

export default SliceFormatter;
