import get from 'lodash/get';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import castArray from 'lodash/castArray';

import { currentOrgYearScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const { node, scores, organization, config = {} } = options;
  const { selfDescribedDataShape = {}, sortByProperty = 'index' } = config;
  const score = currentOrgYearScores(scores, organization, node) || {};

  const data = reduce(Object.keys(score.value), (acc, studentGroup) => {
    const studentGroupShape = get(selfDescribedDataShape, studentGroup, { label: studentGroup, index: 100 });
    const colorIndex = get(score, `value.${studentGroup}.performance`, 0) - 1;
    if (score.value) {
      acc.push({
        label: studentGroupShape.label,
        key: studentGroup,
        organization,
        value: get(score, `value.${studentGroup}`, 0),
        colorIndex,
        index: studentGroupShape.index
      });
    }
    return acc;
  }, []);
  return sortBy(data, castArray(sortByProperty));
};

export default adapter;
