import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { calculatePercentage } from '../../helpers/portalDataServiceHelpers';

const getDefaultScore = (currentOrgScore, chartConfig, currentOrganization) => {
  // defaultScoreKey is usually an aggregated(rollup) group (all_grades, all_levels, ...)
  // Get score under the defaultScoreKey if possible
  // example defaultScoreKey { school: 'all_grades', district: 'all_levels', state: 'all_levels' }
  if (chartConfig.defaultScoreKey && currentOrganization) {
    const defaultScoreKey = get(chartConfig.defaultScoreKey, get(currentOrganization, 'entity_type', '').toLowerCase());
    if (defaultScoreKey) {
      return get(currentOrgScore, defaultScoreKey);
    }
  }
  return null;
};

const getViewingBlockScore = (currentOrgScore, chartConfig, currentOrganization, viewingDropdownFilter) => {
  const scoreKey = get(viewingDropdownFilter, '0');
  return get(currentOrgScore, scoreKey);
};

const getFirstInSetFallbackScore = (currentOrgScore) => {
  // If no defaultScoreKey and data is nesting under groups, return the very first group
  const scoreKeys = Object.keys(currentOrgScore || {});
  if (!isEmpty(scoreKeys)) {
    return get(currentOrgScore, scoreKeys[0], currentOrgScore);
  }
  return null;
};

export default function adapter({ currentOrgScore, chartConfig, currentOrganization, viewingDropdownFilter, node }) {
  if (chartConfig.noGroupNesting) {
    return currentOrgScore;
  }

  if (!isEmpty(viewingDropdownFilter)) {
    return getViewingBlockScore(currentOrgScore, chartConfig, currentOrganization, viewingDropdownFilter, node);
  }

  let score = getDefaultScore(currentOrgScore, chartConfig, currentOrganization);

  if (!score) {
    score = getFirstInSetFallbackScore(currentOrgScore);
  }

  if (score) {
    // Compute percentage based on percentageNumeratorKey and percentageDenumeratorKey if they are defined
    // and add percentage to each value for the score object
    // Otherwise return the score object
    score = calculatePercentage(chartConfig, score);
  }

  return score || currentOrgScore;
}
