/* eslint-disable no-case-declarations */
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import get from 'lodash/get';
import pickBy from 'lodash/pickBy';

import framework from './frameworkReducer';
import loading from './loadingReducer';
import params from './paramsReducer';
import charts from './chartsReducer';

import {
  SET_CURRENT_SITE_DATA,
  SET_CURRENT_ORG_DATA,
  SET_ORGANIZATIONS_DATA,
  SET_CHILD_ORGS_DATA,
  SET_SCORES_DATA,
  SET_PUBLIC_SCORES_DATA,
  SET_GRAPH_DATA,
  SET_PAGE_CONTEXT,
  SET_USER_CONTEXT,
  SET_TOKEN,
  RESET_ORGANIZATION,
  SET_ORGANIZATION,
  SET_MODULE_DATA,
  UNSET_MODULE_DATA,
  SET_GRAPH_ERROR,
  SET_CURRENT_SCHOOL_YEAR,
  SET_PORTAL_DATA
} from '../actions';

import userContextReducer from './userContextReducer';
import currentOrgReducer from './currentOrgReducer';
import scoresReducer from './scoresReducer';
import errorsReducer from './errorsReducer';
import dataReducer from './dataReducer';
import { currentSchoolYearReducer, portalDataReducer } from './portals';
import { kdeCurrentSchoolYear } from '../../helpers/portals';

const DEFAULT_STATE = {
  scores: [],
  frameworks: {},
  jwtTokens: {},
  schoolYear: kdeCurrentSchoolYear()
};

/* eslint-disable complexity */
function module(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case SET_MODULE_DATA:
      return { ...state, ...action.data };
    case UNSET_MODULE_DATA:
      return pickBy(state, (_val, key) => !action.keys.includes(key));
    case SET_ORGANIZATION:
      return { ...state, organization: action.organization };
    case RESET_ORGANIZATION:
      return { ...state, organization: state.defaultOrganization || state.organization };
    case SET_PAGE_CONTEXT:
      return {
        ...state,
        pageContext: {
          ...get(state, 'pageContext', {}),
          ...action.data
        }
      };
    case SET_USER_CONTEXT:
      return {
        ...state,
        userContext: userContextReducer(state, action),
        organization: state.organization || action.data.currentOrganization
      };
    case SET_TOKEN:
      return {
        ...state,
        jwtTokens: { ...state.jwtTokens, [action.service]: action.token }
      };
    case SET_PORTAL_DATA:
      return portalDataReducer(state, action);
    case SET_CURRENT_SITE_DATA:
      return { ...state, siteConfig: action.data.config, routes: action.data.routes };
    case SET_CURRENT_ORG_DATA:
      return currentOrgReducer(state, action);
    case SET_CURRENT_SCHOOL_YEAR:
      return currentSchoolYearReducer(state, action);
    case SET_ORGANIZATIONS_DATA:
      return {
        ...state,
        organizations: action.data.data.organization.children
      };
    case SET_CHILD_ORGS_DATA: {
      return {
        ...state,
        organizations: action.data.data.organization.children
      };
    }
    case SET_GRAPH_DATA:
      return dataReducer(state, action);
    case SET_GRAPH_ERROR:
      return {
        ...state,
        errors: errorsReducer(state.errors, action.error, action.nodePath)
      };
    case SET_SCORES_DATA:
      return {
        ...state,
        scores: scoresReducer(state.scores, action.data.scores)
      };
    case SET_PUBLIC_SCORES_DATA:
      return {
        ...state,
        scores: scoresReducer(state.scores, action.data.data.scores)
      };
    default:
      return state;
  }
}

export default (history) => combineReducers({
  router: connectRouter(history),
  module,
  params,
  framework,
  charts,
  loading
});
