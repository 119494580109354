/* eslint-disable import/no-unresolved,import/extensions */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import isEmpty from 'lodash/isEmpty';
import difference from 'lodash/difference';
import memoize from 'lodash/memoize';
import mapValues from 'lodash/mapValues';
import cx from 'classnames';
import queryString from 'query-string';

import Heading from 'cui/lib/components/Heading';
import EmptyState from 'cui/lib/components/EmptyState';

import { unsetModuleData } from '../../store/actions';
import Link from '../../components/Link';
import connected from '../../components/connected';
import template from '../../helpers/template';
import { getUserContext } from '../../helpers/backend_context';
import DataPointsPage from '../../components/DataPointsPage';
import { filterLeavesAndTheirAncestors } from '../../helpers/collectorHelpers';
import pageTitle from '../../helpers/pageHelpers';
import getMergedScores from '../../helpers/scores';
import { transformScoresFromPortalDataService } from '../../helpers/portalDataServiceHelpers';
import { pluckNodes } from '../../helpers/nodeHelpers';
import {
  publicScoreLoad,
  scoreLoad,
  scoreLoadFromPortalDataService,
  LOAD_CHILDREN_ORGS_ACTION
} from '../../helpers/scoreLoadActions';
import previousYearScoreLoad, { previousYearScoreLoadAction } from '../../actions/previousYearScores';
import dataPointStatusesLoad from '../../helpers/dataPointStatusesLoadActions';
import { getDomainBySlug } from '../../helpers/frameworkTreeTraversalHelper';
import orgCanAccessCollectorDataPoint from '../../helpers/orgCanAccessCollectorDataPoint';
import { findRestrictionProps } from '../../helpers/restrictionHelpers';
import { LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS, scoreLoadAction } from '../OrgReportCardPage/actions';
import LOAD_ORGANIZATIONS from '../../queries/load_organizations';
import ApprovalHeader from '../../components/ApprovalHeader';
import Footer from '../../components/Footer';
import Masthead from '../../components/Masthead';
import SuccessIndicatorHeader from '../../components/SuccessIndicatorHeader';
import SuccessIndicatorNav from '../../components/SuccessIndicatorNav';
import MainContentAnchor from '../../components/MainContentAnchor';
import WithDomain from '../../components/WithDomain';
import WithYearAvailableForOrganization from '../../components/WithYearAvailableForOrganization';
import NoDataForYearInfo from '../../components/NoDataForYearInfo';
import NodeTabs from '../../components/NodeTabs';
import CompareMultiSelectForm from '../../components/CompareMultiSelectForm';
import Narrative from './Narrative';

import {
  KDE_CHART_DATA_ADAPTERS,
  KDE_CHART_TYPES,
  DEFAULT_SUPPRESSION_COPY,
  DEFAULT_NOT_AUTHORIZED_COPY
} from '../../constants';
import LOAD_FEATURE_FLAGS_ACTION from '../../actions/feature_flag';
import buildLoadFrameworkAction from '../../actions/framework';
import unsetCurrentNode from './actions';
import styles from './index.module.scss';

import { supportsNarrative } from '../../helpers/approvalHelpers';

const compareButtonAttrs = memoize(variableName => ({ 'aria-label': `Compare ${variableName}` }));

const a11yAttrs = {
  role: 'main'
};

const orgIdsToCompare = (search) => {
  const { orgIds = [] } = queryString.parse(search, { arrayFormat: 'bracket' });
  return orgIds.map(Number);
};

const loadScoresActions = () => [
  scoreLoad,
  publicScoreLoad,
  scoreLoadFromPortalDataService,
  {
    ...previousYearScoreLoad,
    propMap: {
      ...previousYearScoreLoad.propMap,
      dataPoints: 'module.currentNodes'
    },
    loadingIndicator: action => `previous-year-scores-for-org-${action.currentOrganizationId}`
  },
  ...orgIdsToCompare(window.location.search).map(orgId => scoreLoadAction({
    currentOrganization: { id: orgId },
    mergePortalData: true,
    skipParentOrgsLoading: true,
    additionalActions: [
      previousYearScoreLoadAction({
        propMap: {
          ...previousYearScoreLoad.propMap,
          dataPoints: 'module.currentNodes'
        },
        skipParentOrgsLoading: true,
        mergePortalData: true
      })
    ]
  }))
];

/**
 * This page selects a `SuccessIndicator` as the "framework.currentNode" (stored in the redux store)
 *
 * `SuccessIndicatorNav` shows the domain and all of the child nodes (success_indicators)
 * `NodeTabs` break down the success_indicator child nodes (variables)
 */
class SuccessIndicatorPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      additionalOrganizationsIds: orgIdsToCompare(props.location.search)
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    [
      'https://use.typekit.net/ktq5tjx.css',
      'https://use.typekit.net/poy3cpw.css'
    ].forEach((stylesheet) => {
      if (!document.querySelector(`link[href="${stylesheet}"]`)) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = stylesheet;
        document.head.insertBefore(link, document.head.firstChild);
      }
    });
  }

  componentWillUnmount() {
    // When we add the redirect, current node isn't being changed after redirect, so we have to unset it before unmount.
    this.props.unsetCurrentNode();
    this.props.unsetModuleData(['scoresFromPortalDataService']);
  }

  onCompareClearAll = () => {
    const { location } = this.props;
    const query = queryString.parse(location.search, { arrayFormat: 'bracket' });
    const newQuery = queryString.stringify(
      { ...query, orgIds: undefined },
      { arrayFormat: 'bracket' }
    );
    history.pushState(null, null, `${location.pathname}?${newQuery}`);
    this.setState({ additionalOrganizationsIds: [] });
  }

  onCompareFormSubmit = (newOrgIds, oldOrgIds) => {
    const { organizations, loadScoreForOrg, location } = this.props;

    difference(newOrgIds, oldOrgIds)
      .map(orgId => organizations.find(org => org.id === orgId))
      .filter(Boolean)
      .forEach(loadScoreForOrg);

    const currentQuery = queryString.parse(location.search, { arrayFormat: 'bracket' });
    const newQuery = queryString.stringify(
      { ...currentQuery, orgIds: newOrgIds },
      { arrayFormat: 'bracket' }
    );

    history.pushState(null, null, `${location.pathname}?${newQuery}`);
    this.setState({ additionalOrganizationsIds: newOrgIds });
  }

  getOrganizationsForCompare() {
    return this.state.additionalOrganizationsIds
      .map(id => this.props.organizations.find(org => org.id === id))
      .filter(Boolean);
  }

  shouldShowAccountabilityLink = () => {
    const { currentOrganization, domainSlug, siSlug, userContext, year } = this.props;
    const userCanAccess = !userContext || get(userContext, 'currentModulePlatformAccessLevel') === 'admin';
    return (
      currentOrganization &&
      domainSlug === 'school_safety' &&
      siSlug === 'safety' &&
      year === 2018 &&
      userCanAccess
    );
  }

  contentIsAccessible = () => {
    // Data within the accountability domain
    // is restricted for users within the admin tool who do not
    // have 'admin' module access. Public users can see content,
    // as can logged-in users with admin privileges.
    const { currentOrganization, domainSlug, framework, userContext } = this.props;
    const userCanAccess = !userContext || get(userContext, 'currentModulePlatformAccessLevel') === 'admin';

    if (currentOrganization && domainSlug === 'school_accountability') {
      return userCanAccess;
    }

    // Data within the financial transparency domain
    // is restricted for school ids defined in the framework tree.
    const { restrictAccess = [] } = getDomainBySlug(framework, domainSlug);
    const restrictionProps = findRestrictionProps(currentOrganization, restrictAccess);

    return !restrictionProps;
  }

  renderCompareForm() {
    const {
      currentVariable,
      isCompareEnabled,
      year
    } = this.props;

    return isCompareEnabled && (
      <CompareMultiSelectForm
        year={year}
        currentVariable={currentVariable}
        onSubmit={this.onCompareFormSubmit}
        onClearAll={this.onCompareClearAll}
        buttonAttrs={compareButtonAttrs(currentVariable.name)}
      />
    );
  }

  renderMainContent() {
    const {
      currentOrganization,
      domainSlug,
      siSlug,
      framework,
      currentVariable,
      currentNode,
      currentNodes,
      scoresFromGroot,
      scoresFromPortalDataService,
      previousYearScores: previousYearScoresFromPortalDataService,
      year
    } = this.props;
    const additionalOrganizations = this.getOrganizationsForCompare();

    const placeholder = get(currentVariable || currentNode, ['metadata', 'placeholder']);

    const filterLeaves = node => orgCanAccessCollectorDataPoint(node, currentOrganization);
    const filteredFramework = filterLeavesAndTheirAncestors(framework, filterLeaves);

    const scores = getMergedScores({
      scoresFromGroot,
      scoresFromPortalDataService,
      currentOrganization,
      currentNodes,
      childOrganizations: additionalOrganizations
    });

    const previousYearScores = mapValues(
      previousYearScoresFromPortalDataService,
      scoreRecords => transformScoresFromPortalDataService(scoreRecords, currentNodes, currentOrganization, year)
    );

    return (
      <div className={cx('container', styles.dataPointWrap)}>
        <div className="container-constrained">
          <div className="l-dataPointPage">
            <SuccessIndicatorNav
              awaitProps={['framework', 'currentOrganization']}
              framework={filteredFramework}
              propMap={{
                currentOrganization: 'module.currentOrganization',
                featureFlags: 'module.pageContext.featureFlags',
                isPreviewSite: 'module.pageContext.isPreviewSite',
                userRole: 'module.userContext.currentModulePlatformAccessLevel',
                domainSlug: 'params.domainSlug',
                variableSlug: 'params.variableSlug',
                successIndicatorSlug: 'params.siSlug'
              }}
            />

            <div className="l-dataPointPage-content">
              {
                placeholder ? (
                  <EmptyState
                    kind="text"
                    title={placeholder.title}
                    buttonText={placeholder.linkText}
                    buttonHref={template(placeholder.linkHref, { organization: currentOrganization, year })}
                  />
                ) : (
                  <div>
                    <MainContentAnchor />
                    <section className="section section_tight">
                      <Heading level={3} size="xLarge">
                        <strong aria-label={`${get(this.props, 'currentNode.name')}: ${get(this.props, 'currentVariable.name')}`}>
                          {get(this.props, 'currentNode.name')}
                        </strong>
                      </Heading>
                      { this.shouldShowAccountabilityLink() &&
                        <Link to={`/organization/${currentOrganization.id}/school_accountability/opportunity_access/school_quality`}>
                          View Additional Behavior Data (Accountability)
                        </Link>
                      }
                      {(currentOrganization && domainSlug === 'school_accountability' && siSlug === 'opportunity_access') &&
                        <Link to={`/organization/${currentOrganization.id}/school_safety/safety/safe_schools_data`}>
                          View Additional Behavior Data (School Safety)
                        </Link>
                      }
                      <WithDomain slug="financial_transparency">
                        {({ isActive }) => (isActive ? (
                          <div>
                            <p className="l-dataPointPage-suppression">
                              Financial data is complex and varies from district to district.
                              Conclusions should not be drawn solely from the data below.
                              Please engage local school district leaders with questions to better understand local district revenue and spending.
                            </p>
                            { this.contentIsAccessible() && supportsNarrative(get(currentNode, 'metadata'), currentOrganization) && <Narrative /> }
                          </div>
                        ) : (
                          <p className="l-dataPointPage-suppression">
                            { get(currentNode, 'suppression_copy', DEFAULT_SUPPRESSION_COPY) }
                          </p>
                        ))}
                      </WithDomain>
                    </section>
                    { !this.contentIsAccessible() &&
                      <section className="section section_tight">
                        { get(currentNode, 'not_authorized_copy', DEFAULT_NOT_AUTHORIZED_COPY) }
                      </section>
                    }
                    { this.contentIsAccessible() &&
                      <section className="section section_tight">
                        <NodeTabs
                          awaitProps={['framework', 'currentOrganization']}
                          development={process.env.NODE_ENV === 'development'}
                          type="folders"
                          routeTemplate="/organization/{{currentOrganization.id}}/{{domainSlug}}/{{successIndicatorSlug}}/{{node.slug}}"
                          nestedNodeType="variable"
                          framework={currentNode}
                          propMap={{
                            currentOrganization: 'module.currentOrganization',
                            domainSlug: 'params.domainSlug',
                            featureFlags: 'module.pageContext.featureFlags',
                            isPreviewSite: 'module.pageContext.isPreviewSite',
                            userRole: 'module.userContext.currentModulePlatformAccessLevel',
                            successIndicatorSlug: 'params.siSlug',
                            selected: 'params.variableSlug'
                          }}
                        />
                        {this.renderCompareForm()}
                        <DataPointsPage
                          attrs={a11yAttrs}
                          additionalChartAdapters={KDE_CHART_DATA_ADAPTERS}
                          awaitProps={['framework', 'organization', 'scores']}
                          additionalChartTypes={KDE_CHART_TYPES}
                          framework={filteredFramework}
                          currentNode={currentNode}
                          scores={scores}
                          previousYearScores={previousYearScores}
                          additionalOrganizations={additionalOrganizations}
                          propMap={{
                            domainSlug: 'params.domainSlug',
                            featureFlags: 'module.pageContext.featureFlags',
                            isPreviewSite: 'module.pageContext.isPreviewSite',
                            userRole: 'module.userContext.currentModulePlatformAccessLevel',
                            successIndicatorSlug: 'params.siSlug',
                            variableSlug: 'params.variableSlug',
                            organization: 'module.currentOrganization'
                          }}
                        />
                      </section>
                    }
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderNoDataForYearInfo() {
    return (
      <div className="container">
        <div className="container-constrained">
          <NoDataForYearInfo year={this.props.year} />
        </div>
      </div>
    );
  }

  render() {
    const { currentOrganization, userContext, currentVariable, currentNode, year, covidImpactBanner } = this.props;

    if (!currentOrganization) return null;

    const redirect = get(currentVariable || currentNode, ['metadata', 'redirect']);
    if (redirect) {
      return <Redirect to={`/organization/${currentOrganization.id}/${redirect}?year=${year}`} />;
    }

    return (
      <div className={styles.page}>
        <ApprovalHeader />
        {/* `userContext` is available only in Clarity view (aka Admin view). no userContext means "public view" */}
        {!userContext &&
          <Masthead withSearchBar />
        }
        {!userContext &&
          <SuccessIndicatorHeader
            covidImpactBanner={covidImpactBanner}
            awaitProps={['currentOrganization']}
            propMap={{
              currentOrganization: 'module.currentOrganization'
            }}
          />
        }

        <WithYearAvailableForOrganization organization={currentOrganization} year={year}>
          {
            ({ isYearAvailable }) => (
              isYearAvailable ? this.renderMainContent() : this.renderNoDataForYearInfo()
            )
          }
        </WithYearAvailableForOrganization>
        <Footer />
      </div>
    );
  }
}

SuccessIndicatorPage.propTypes = {
  currentVariable: PropTypes.object,
  currentNode: PropTypes.object,
  currentNodes: PropTypes.arrayOf(PropTypes.object),
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  domainSlug: PropTypes.string,
  isCompareEnabled: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
  loadScoreForOrg: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
  scoresFromGroot: PropTypes.arrayOf(PropTypes.object),
  scoresFromPortalDataService: PropTypes.object,
  previousYearScores: PropTypes.object,
  siSlug: PropTypes.string,
  covidImpactBanner: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  }),
  userContext: PropTypes.object,
  variableSlug: PropTypes.string,
  year: PropTypes.number,
  unsetCurrentNode: PropTypes.func,
  unsetModuleData: PropTypes.func
};

SuccessIndicatorPage.defaultProps = {
  userContext: null
};

// FIXME: move this the the config propMap
const mapStateToProps = (state) => {
  const variableSlug = get(state, 'params.variableSlug');
  const currentNode = get(state, 'framework.currentNode');
  const currentNodes = get(state, 'module.currentNodes', []);
  const currentVariable = get(currentNode, 'items', []).find(node => node.slug === variableSlug);
  const isCompareEnabled = get(currentVariable, ['metadata', 'compare', 'enabled'], false);
  const organizations = get(state, 'module.organizations', []);

  return {
    variableSlug,
    currentVariable,
    currentNode,
    currentNodes,
    framework: get(state, 'framework.kde'),
    currentOrganization: get(state, 'module.currentOrganization'),
    domainSlug: get(state, 'params.domainSlug'),
    isCompareEnabled,
    organizations,
    scoresFromGroot: get(state, 'module.scoresFromGroot'),
    scoresFromPortalDataService: get(state, 'module.scoresFromPortalDataService'),
    previousYearScores: get(state, 'module.previousYearScores', {}),
    siSlug: get(state, 'params.siSlug'),
    userContext: get(state, 'module.userContext'),
    year: get(state, 'params.year'),
    covidImpactBanner: get(state, 'framework.kde.metadata.covidImpactBanner')
  };
};

const mapDispatchToProps = dispatch => ({
  unsetCurrentNode: () => dispatch(unsetCurrentNode()),
  unsetModuleData: keys => dispatch(unsetModuleData(keys)),
  loadScoreForOrg: memoize(
    organization => dispatch(
      scoreLoadAction({
        currentOrganization: organization,
        mergePortalData: true,
        skipParentOrgsLoading: true,
        additionalActions: [
          previousYearScoreLoadAction({
            propMap: {
              ...previousYearScoreLoad.propMap,
              dataPoints: 'module.currentNodes'
            },
            skipParentOrgsLoading: true,
            mergePortalData: true
          })
        ]
      })
    ),
    ({ id }) => id
  )
});

// TODO some sort of loading so that

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(connected(SuccessIndicatorPage)));

const isPublicSite = isEmpty(getUserContext());

export const config = {
  title: pageTitle('{{currentOrgName}} - {{currentSuccessIndicatorName}}'),

  mapStateToProps: state => ({
    currentOrgName: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase()),
    currentSuccessIndicatorName: startCase(get(state, 'params.siSlug'), '')
  }),

  propMap: {
    currentNode: 'framework.currentNode'
  },

  actions: [
    buildLoadFrameworkAction({
      actions: [
        {
          ...LOAD_FEATURE_FLAGS_ACTION,
          actions: [
            {
              type: 'SET_CURRENT_NODE',
              framework: 'kde',
              nodeType: 'success_indicator',
              parentNodeType: 'domain',
              propMap: {
                domain: 'params.siSlug',
                parentSlug: 'params.domainSlug'
              }
            },
            {
              ...LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS,
              actions: [
                {
                  type: 'GRAPH_LOAD',
                  service: 'svcpd',
                  query: `
                    query {
                      narrative(organization_id: :organizationId, fiscal_year: :fiscalYear) {
                        id
                        body
                      }
                    }
                  `,
                  propMap: {
                    fiscalYear: 'module.schoolYear',
                    organizationId: 'module.currentOrganization.id'
                  },
                  stateObjectMap: {
                    narrative: 'narrative'
                  }
                },

                {
                  type: 'PLUCK_NODE_PATHS',
                  pluckNodes,
                  desiredNodeType: 'data_point',
                  propMap: {
                    domainSlug: 'params.domainSlug',
                    successIndicatorSlug: 'params.siSlug',
                    variableSlug: 'params.variableSlug',
                    framework: 'framework.kde',
                    featureFlags: 'module.pageContext.featureFlags',
                    currentOrganization: 'module.currentOrganization'
                  },
                  stateObjectMap: {
                    currentNodes: 'nodes',
                    currentNodePaths: 'nodePaths',
                    currentDataNodePaths: 'dataNodePaths',
                    currentNodePathsSVCPD: 'nodePathsSVCPD'
                  },
                  actions: [
                    {
                      type: 'GRAPH_LOAD',
                      service: 'svcpd',
                      isPublic: isPublicSite,
                      query: LOAD_ORGANIZATIONS,
                      stateObjectMap: {
                        organizations: isPublicSite ? 'data.organizations' : 'organizations'
                      },
                      propMap: {
                        year: 'params.year'
                      }
                    },
                    LOAD_CHILDREN_ORGS_ACTION,
                    {
                      type: 'FETCH_TOKEN',
                      service: 'svcpd',
                      slug: 'kde',
                      actions: [
                        ...loadScoresActions(),
                        dataPointStatusesLoad
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    })
  ],

  // Route/Query params to watch for changes
  actionParams: [
    'domainSlug',
    'siSlug',
    'variableSlug'
  ]
};
