import { useQuery } from 'react-query';

import { getJwtTokenFromClarity } from 'store/backend';

export const tokenQuery = {
  queryKey: ['tokens', 'svcpd'],
  queryFn: getJwtTokenFromClarity('svcpd')
};

const useTokenQuery = () => useQuery(tokenQuery);

export default useTokenQuery;
