import { useQuery, useQueryClient } from 'react-query';
import request from 'helpers/request';
import { ENDPOINTS } from 'store/backend';
import { tokenQuery } from 'store/backend/queries/token';

const requestParams = token => ({
  headers: {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  credentials: 'omit'
});

const getSectionStatuses = (orgIds, year, token) => {
  const query = `query SectionStatuses($orgIds: [Int]!, $year: Int!) {
    section_statuses(organization_id: $orgIds, fiscal_year: $year) {
      id
      clarity_organization_id
      fiscal_year
      section_slug
      status
      flag_comments
      updated_by
      updated_at
    }
  }`;

  const variables = { orgIds, year };

  return request(ENDPOINTS.svcpd, {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
    ...requestParams(token)
  });
};

const queryKey = (orgIds, year) => ['sectionStatuses', orgIds, Number(year)];

const buildQueryFn = queryClient => async ctx => {
  const [_key, orgIds, year] = ctx.queryKey;
  const token = await queryClient.fetchQuery(tokenQuery);
  const response = await getSectionStatuses(orgIds, year, token);

  if (response.errors) throw new Error(`GraphQL Error: ${JSON.stringify(response.errors)}`);

  return response.data.section_statuses;
};

const useSectionStatusesQuery = (orgIds = [], year) => {
  const queryClient = useQueryClient();

  return useQuery({
    queryKey: queryKey(orgIds, year),
    queryFn: buildQueryFn(queryClient),
    enabled: orgIds.length > 0
  });
};

export default useSectionStatusesQuery;
