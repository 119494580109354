import React from 'react';
import PropTypes from 'prop-types';

import ScoreDot from 'cui/lib/components/ScoreDot';

import template from '../../../helpers/template';

const EXPANSIONS = {
  H: 'High',
  M: 'Medium',
  L: 'Low'
};

const COLORS = {
  H: 0,
  M: 1,
  L: 2
};

export const ColorDotFormatter = ({ value, templateString }) => {
  const compiledValue = template(templateString, { value: EXPANSIONS[value.toUpperCase()] });
  return (
    <span>
      <ScoreDot value={COLORS[value.toUpperCase()]} theme="socialImpactAchievement" /> {compiledValue}
    </span>
  );
};

ColorDotFormatter.propTypes = {
  cellText: PropTypes.string,
  value: PropTypes.string,
  templateString: PropTypes.string
};

ColorDotFormatter.defaultProps = {
  cellText: '',
  value: 'L',
  templateString: '{{value}}'
};

export default ColorDotFormatter;
