import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';
import { filterByWhen } from '../../helpers/portalDataServiceHelpers';

export default function adapter(currentOrgScore, { chartConfig, featureFlags }) {
  if (isEmpty(currentOrgScore)) {
    return currentOrgScore;
  }

  get(chartConfig, 'options.combineScores', [])
    .filter(config => filterByWhen(config, { featureFlags }))
    .forEach((config) => {
      set(currentOrgScore, config.to, get(currentOrgScore, config.from));
    });

  return currentOrgScore;
}
