import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';

import styles from './index.module.scss';

export const IconChart = props => (
  <div className={styles.iconChart}>
    <div className={styles.icon}>
      <Icon name={props.icon} className={styles.iconInner} />
    </div>
    <div className={styles.name}>
      <div className={styles.nameInner}>
        {props.name}
      </div>
    </div>
    <div className={styles.value}>
      {props.children}
    </div>
  </div>
);

IconChart.propTypes = {
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default IconChart;
