import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';

import template from '../../helpers/template';
import Markdown from '../Markdown';

const TextCell = (props) => {
  const { item, textAlign, emptyState, alternateTextProp } = props;
  const text = alternateTextProp ? props[alternateTextProp] : props.text;

  if (Array.isArray(text)) {
    return (
      <div>
        {map(text, (t, i) => {
          const body = template(t, item, false) || emptyState;
          return (
            <div key={i} style={{ textAlign }}>
              <Markdown body={body} inline />
            </div>
          );
        })}
      </div>
    );
  }

  const body = template(text, item, false) || emptyState;

  return <div style={{ textAlign }}><Markdown body={body} inline /></div>;
};

TextCell.propTypes = {
  item: PropTypes.object,
  text: PropTypes.string,
  textAlign: PropTypes.oneOf(['right', 'left', 'center']),
  emptyState: PropTypes.string,
  alternateTextProp: PropTypes.string
};

TextCell.defaultProps = {
  text: '',
  textAlign: 'left',
  emptyState: ''
};

export default TextCell;
