export default [
  {
    colorIndex: 0,
    label: 'Industry Certification',
    topLabel: '23.1%',
    total_students: 195,
    value: 45
  },
  {
    colorIndex: 1,
    label: 'CTE End-of-Program Assessment',
    topLabel: '31.8%',
    total_students: 195,
    value: 62
  },
  {
    colorIndex: 2,
    label: 'Apprenticeship',
    topLabel: '0.5%',
    total_students: 195,
    value: 1
  },
  {
    colorIndex: 3,
    label: 'Dual Credit',
    topLabel: '26%',
    total_students: 195,
    value: 50
  },
  {
    colorIndex: 4,
    label: 'Exceptional Work Experience',
    topLabel: '12.8%',
    total_students: 195,
    value: 25
  },
  {
    colorIndex: 5,
    label: 'More than One Indicator',
    topLabel: '5.1%',
    total_students: 195,
    value: 10
  },
  {
    colorIndex: 6,
    label: 'No Indicator',
    topLabel: '1%',
    total_students: 195,
    value: 2
  }
];
