import React from 'react';
import PropTypes from 'prop-types';

import { DonutWithLegend } from 'cui/lib/components/LegendWrapper';
import ScoreDot from 'cui/lib/components/ScoreDot';
import Icon from 'cui/lib/components/Icon';
import EmptyState from 'cui/lib/components/EmptyState';
import currentOrgScores from '../../helpers/scoresHelpers';
import { valueToLocaleString } from '../../helpers/template/format';

import chartDataAdapter from './kdeDonutChartAdapter';

import styles from './KdeDonutChart.module.scss';

const KdeDonutChart = (props) => {
  const { organization, scores, config = {} } = props;
  const { options = {} } = config;

  const score = currentOrgScores(scores, organization);

  const dataOptions = {
    ...props,
    currentOrgScore: score
  };

  const data = chartDataAdapter(dataOptions);
  const chartProps = { ...data.chartProps, ...options };
  const { isSuppressed } = data;

  if (isSuppressed) {
    return <EmptyState data-test="donut-chart-empty-state" kind="text" title="This data is hidden to protect student privacy." />;
  }

  const renderLegendItem = (theme, item, i) => (
    <li key={i} className={styles.donutLegendList}>
      <ScoreDot
        theme={theme}
        value={i}
        className="cui-list-icon"
      />
      <span className="cui-list-text">
        <strong className={styles.donutLegendLabel}>{item.label}</strong>
        {item.description}
      </span>
    </li>
  );

  return (
    <div>
      {
        data ?
          <div className={styles.donutContainer} key={data.key}>
            <DonutWithLegend
              {...data}
              chartProps={{
                ...chartProps,
                animate: false,
                data: chartProps.data
              }}
              legendProps={{
                data: data.legendData,
                theme: chartProps.theme,
                renderLegendItem
              }}
            >
              <div className={styles.donutCenter}>
                {chartProps.iconName ?
                  <div className={styles.donutIcon}>
                    <Icon name={chartProps.iconName} size="xLarge" />
                  </div>
                  :
                  <div>
                    <h3 className={styles.donutCenterNumber}>
                      {valueToLocaleString(chartProps.total)}
                    </h3>
                    {!data.hideCenterText && <h4 className={styles.donutCenterTotal}>Total</h4>}
                  </div>
                }
              </div>
            </DonutWithLegend>
          </div>
          :
          <EmptyState data-test="donut-chart-empty-state" kind="text" title="There is no data available." />
      }
    </div>
  );
};

KdeDonutChart.propTypes = {
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  config: PropTypes.object
};

export default KdeDonutChart;
