import React from 'react';
import PropTypes from 'prop-types';

import ScoreDot from 'cui/lib/components/ScoreDot';

export const DDDColorDotFormatter = ({ value, theme, statusMap }) => (
  <span>
    <ScoreDot value={statusMap[value].colorIndex} theme={theme} /> {statusMap[value].text}
  </span>
);

DDDColorDotFormatter.propTypes = {
  theme: PropTypes.string,
  value: PropTypes.string,
  statusMap: PropTypes.object
};

DDDColorDotFormatter.defaultProps = {
  theme: 'imm',
  value: 'default',
  statusMap: {
    default: {
      colorIndex: 0,
      text: 'Default'
    },
    default2: {
      colorIndex: 1,
      text: 'Default 2'
    }
  }
};

export default DDDColorDotFormatter;
