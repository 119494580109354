import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import cx from 'classnames';
import BarChart from 'cui/lib/components/BarChart';
import ScoreDot from 'cui/lib/components/ScoreDot';
import List from 'cui/lib/components/List';
import ChartEmptyState from '../Chart/ChartEmptyState';
import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import applyAdapters, { computeProps, isValidBarChartData, isDataSuppressed } from '../../helpers/adapterHelpers';
import valueToPrecision, { valueToFixedPoint } from '../../helpers/mathHelpers';
import { getParentData } from '../../helpers/portalDataServiceHelpers';
import { handlePopoverPlacement } from '../../helpers/dashboardChartHelpers';

const defaultChartOptions = {
  xAxis: {
    showGrid: true,
    showAxis: true,
    showLabel: false,
    position: 'end',
    labelAlignment: 'start'
  },
  yAxis: {
    showLabel: false,
    showGrid: false,
    showAxis: false
  },
  low: 0,
  barWidthRatio: 0.95,
  animate: false
};

function legendValue(item, fixedLength) {
  if (item.legendValue) {
    return item.legendValue;
  }

  return `${isFinite(parseFloat(item.value)) ? parseFloat(item.value).toFixed(fixedLength) : item.value}%`;
}

function ChartLegend({ legendItems, theme, fixedLength }) {
  return (
    <List className="legend">
      {
        legendItems.map((d, i) => (
          <List.Item
            key={i}
            className="legend-item"
            icon={<ScoreDot className="legend-icon" type="solid" theme={theme} value={d.colorIndex !== undefined ? d.colorIndex : i} />}
          >
            <div className="legend-label">{d.label}</div>
            <div>
              <span className="legend-value">{legendValue(d, fixedLength)}</span>
              {
                d.denominator && (
                  <span className="legend-label">
                    <em className="u-indent">
                      of {d.denominator} {d.denominatorText ? d.denominatorText : 'students'}
                    </em>
                  </span>
                )
              }
            </div>
          </List.Item>
        ))
      }
    </List>
  );
}

ChartLegend.propTypes = {
  legendItems: PropTypes.arrayOf(PropTypes.object),
  theme: PropTypes.string,
  fixedLength: PropTypes.number
};

ChartLegend.defaultProps = {
  theme: 'kde-multi',
  fixedLength: 1
};

/* eslint-disable complexity */
function KdeDashboardSingleBarChart(props) {
  const {
    config,
    node,
    additionalChartAdapters,
    organization,
    allScores,
    scores,
    className
  } = props;

  const currentScore = props.currentScore || currentOrgYearScores(scores, organization);
  const alwaysRender = get(config, 'alwaysRender', false);
  if (!alwaysRender && isEmpty(currentScore)) {
    return <ChartEmptyState />;
  }

  const optionsFromConfig = get(config, 'options.chartOptions', {});
  const chartOptions = merge({}, defaultChartOptions, optionsFromConfig);
  const adapterName = get(config, 'adapter');

  if (isEmpty(adapterName)) {
    return <ChartEmptyState />;
  }

  const dataFromAdapter = applyAdapters(adapterName, currentScore, {
    currentOrganization: organization,
    chartConfig: config,
    parentData: getParentData(allScores, organization, node),
    additionalChartAdapters,
    node,
    config,
    allScores
  });

  let data = get(dataFromAdapter, 'chartData', dataFromAdapter);

  // Currently Cui BarChart can not deal with bad data(not number)
  // Add this validate step here to prevent page failure
  // TODO we could add this validate logics to the CUI BarChart
  if (isDataSuppressed(currentScore, data)) {
    return <ChartEmptyState emptyMessage="This data is hidden to protect student privacy." />;
  }

  if (isEmpty(data) || !isValidBarChartData(data)) {
    return <ChartEmptyState />;
  }

  const fixedLength = get(config, 'options.fixedLength', 1);

  let legendItems = get(dataFromAdapter, 'legendData');

  if (isEmpty(legendItems)) {
    let legendData = get(data, '[0].value');
    legendData = Array.isArray(legendData) ? legendData : data;
    legendItems = computeProps(legendData, get(config, 'options.computedProps'), { valueToPrecision, valueToFixedPoint });
  }

  const legendPlacement = get(config, 'options.legendPlacement', 'bottom');
  const colorTheme = get(config, 'options.theme', 'default');
  data = handlePopoverPlacement(data, config);

  return legendPlacement === 'bottom' ? (
    <div>
      <BarChart
        theme={colorTheme}
        data={data}
        chartOptions={chartOptions}
      />
      <br />
      <ChartLegend legendItems={legendItems} fixedLength={fixedLength} />
    </div>
  ) : (
    <div className={cx('cui-media cui-media_center cui-media_chart', className)}>
      <div className="cui-media-image">
        <BarChart
          theme={colorTheme}
          data={data}
          chartOptions={chartOptions}
        />
      </div>
      <div className="cui-media-content">
        <ChartLegend legendItems={legendItems} theme={colorTheme} fixedLength={fixedLength} />
      </div>
    </div>
  );
}

KdeDashboardSingleBarChart.propTypes = {
  config: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  allScores: PropTypes.arrayOf(PropTypes.object),
  node: PropTypes.object,
  additionalChartAdapters: PropTypes.object,
  currentScore: PropTypes.object,
  className: PropTypes.string
};

export default KdeDashboardSingleBarChart;
/* eslint-enable complexity */
