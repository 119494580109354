export const reduxActionPrefix = 'KDE.';

export const SET_MODULE_DATA = `${reduxActionPrefix}SET_MODULE_DATA`;
export const UNSET_MODULE_DATA = `${reduxActionPrefix}UNSET_MODULE_DATA`;
export const SET_CURRENT_SITE_DATA = `${reduxActionPrefix}SET_CURRENT_SITE_DATA`;
export const SET_CURRENT_ORG_DATA = `${reduxActionPrefix}SET_CURRENT_ORG_DATA`;
export const SET_ORGANIZATIONS_DATA = `${reduxActionPrefix}SET_ORGANIZATIONS_DATA`;
export const SET_CHILD_ORGS_DATA = `${reduxActionPrefix}SET_CHILD_ORGS_DATA`;
export const SET_GRAPH_DATA = `${reduxActionPrefix}SET_GRAPH_DATA`;
export const SET_PAGE_CONTEXT = `${reduxActionPrefix}SET_PAGE_CONTEXT`;
export const SET_USER_CONTEXT = `${reduxActionPrefix}SET_USER_CONTEXT`;
export const SET_TOKEN = `${reduxActionPrefix}SET_TOKEN`;
export const RESET_ORGANIZATION = `${reduxActionPrefix}RESET_ORGANIZATION`;
export const SET_ORGANIZATION = `${reduxActionPrefix}SET_ORGANIZATION`;
export const SET_GRAPH_ERROR = `${reduxActionPrefix}SET_GRAPH_ERROR`;
export const SET_CURRENT_SCHOOL_YEAR = `${reduxActionPrefix}SET_CURRENT_SCHOOL_YEAR`;
export const SET_PORTAL_DATA = `${reduxActionPrefix}SET_PORTAL_DATA`;
export const SET_SCORES_DATA = `${reduxActionPrefix}SET_SCORES_DATA`;
export const SET_PUBLIC_SCORES_DATA = `${reduxActionPrefix}SET_PUBLIC_SCORES_DATA`;

export const setModuleData = data => ({
  type: SET_MODULE_DATA,
  data
});

export const unsetModuleData = keys => ({
  type: UNSET_MODULE_DATA,
  keys
});

export const setPageContext = data => ({
  type: SET_PAGE_CONTEXT,
  data
});

export const setUserContext = data => ({
  type: SET_USER_CONTEXT,
  data
});

export const setToken = (service, token) => ({
  type: SET_TOKEN,
  service,
  token
});

export const setCurrentSiteData = data => ({
  type: SET_CURRENT_SITE_DATA,
  data
});

export const setCurrentOrgData = data => ({
  type: SET_CURRENT_ORG_DATA,
  data
});

export const setOrganizationsData = data => ({
  type: SET_ORGANIZATIONS_DATA,
  data
});

export const setChildOrgsData = data => ({
  type: SET_CHILD_ORGS_DATA,
  data
});

export const resetOrganization = () => ({
  type: RESET_ORGANIZATION
});

export const setOrganization = organization => ({
  type: SET_ORGANIZATION,
  organization
});

export const setGraphData = (data, stateObjectMap, actionData) => ({
  type: SET_GRAPH_DATA,
  stateObjectMap,
  data,
  actionData
});

export const setGraphError = (error, nodePath) => ({
  type: SET_GRAPH_ERROR,
  error,
  nodePath
});

export const setPortalData = (data, props) => ({
  ...props,
  type: SET_PORTAL_DATA,
  data
});

export const setCurrentSchoolYear = year => ({
  type: SET_CURRENT_SCHOOL_YEAR,
  payload: {
    year
  }
});
