import {
  mergeScores,
  getOrgIdFromOrganization,
  getOrgType
} from './portalDataServiceHelpers';
import resolveScoreDependencies from './resolveScoreDependencies';
import { KENTUCKY_ORG_ID } from '../constants';

const getMergedScores = ({
  scoresFromGroot = [],
  scoresFromPortalDataService = [],
  currentOrganization,
  currentNodes = [],
  childOrganizations = []
}) => {
  const orgMap = {
    [getOrgIdFromOrganization(currentOrganization)]: currentOrganization.id
  };
  // Include the parent org id in case we need them for referencing when combining scores
  // from Groot and the portal service
  const entityType = getOrgType(currentOrganization);
  if (entityType === 'school') {
    const parentDistrict = currentOrganization.parentDistrict;
    if (parentDistrict) {
      orgMap[getOrgIdFromOrganization(parentDistrict)] = parentDistrict.id;
      orgMap.State = KENTUCKY_ORG_ID;
    }
  } else if (entityType === 'district') {
    orgMap.State = KENTUCKY_ORG_ID;
  }
  childOrganizations.forEach((org) => {
    orgMap[getOrgIdFromOrganization(org)] = org.id;
  });

  return mergeScores(
    resolveScoreDependencies(scoresFromGroot, currentNodes),
    scoresFromPortalDataService,
    currentNodes,
    orgMap,
    currentOrganization
  );
};

export default getMergedScores;
