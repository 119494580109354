import flat, { unflatten } from 'flat';

/**
 * Invert dimensions and disaggregations
*/
export default ({ currentOrgScore = { dimensions: {} }, chartConfig = {} }) => {
  if (!currentOrgScore.dimensions) return null;

  const defaultDisaggregations = chartConfig.options.rows
    .reduce((accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: {
        events: 0,
        students: 0,
        students_with_events: 0,
        unique_students: 0
      }
    }), {});

  const defaultAccumulator = chartConfig.options.columns
    .filter(column => column.scoreKey)
    .map(column => column.scoreKey)
    .reduce((accumulator, currentValue) => ({
      ...accumulator,
      [currentValue]: {
        score: defaultDisaggregations
      }
    }), {});

  return unflatten(
    Object.entries({ ...flat(defaultAccumulator || {}), ...flat(currentOrgScore.dimensions || {}) })
      .reduce((accumulator, [currentKey, currentValue]) => {
        if (typeof currentValue === 'object') return accumulator;
        const [disaggregation, dimension, ...rest] = currentKey.replace(/score./gi, '')
          .split('.');
        const key = [dimension, disaggregation, ...rest].join('.');

        return {
          ...accumulator,
          [key]: isNaN(parseFloat(currentValue)) ? currentValue : parseFloat(currentValue)
        };
      }, {})
  );
};
