import React from 'react';
import PropTypes from 'prop-types';

import { Heading, InfoPopover } from 'cui';

import Primary from './Primary';
import Secondary from './Secondary';

import styles from './ScoreBlock.module.scss';

const ScoreBlock = ({ children, title, infoContent, label }) => (
  <div className={styles.scoreBlock}>
    <div className={styles.header}>
      <Heading className={styles.title} level={4} size="medium" weight="heavy">
        {title}
        <InfoPopover content={infoContent} label={label} />
      </Heading>
    </div>
    { children }
  </div>
);

ScoreBlock.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  infoContent: PropTypes.string,
  label: PropTypes.string
};

ScoreBlock.Primary = Primary;
ScoreBlock.Secondary = Secondary;

export default ScoreBlock;
