import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import stubTrue from 'lodash/stubTrue';

const filterItems = (nodeData, predicate) => filter(get(nodeData, 'items'), predicate);

const collectVariables = (framework, predicate = stubTrue) => {
  let variables = flattenDeep(
    map(filterItems(framework, predicate), domain => (
      map(filterItems(domain, predicate), successIndicator => (
        map(filterItems(successIndicator, predicate), variable => ({
          domain,
          successIndicator,
          variable
        }))
      ))
    ))
  );

  if (Array.isArray(variables)) {
    variables = sortBy(variables, v => get(v, 'variable.collector_navigation_tab.index'));
  }

  return variables;
};
export default collectVariables;
