import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import styles from './index.module.scss';

const DEFAULT_PAGE_TITLE = 'Kentucky School Report Card';

function pageTitle() {
  const title = document.querySelector('title');

  return `${title ? title.textContent.trim() : DEFAULT_PAGE_TITLE} Page`;
}

// Invisible element at the very top of a page.
// Needed in order to reset focus position for a11y purposes when route changes.
// https://github.com/ReactTraining/react-router/issues/5210
class NavigationFocusReset extends React.PureComponent {
  constructor(props) {
    super(props);
    this.bindRef = this.bindRef.bind(this);
  }

  componentDidMount() {
    if (this.anchorEl) {
      this.anchorEl.focus();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname && this.anchorEl) {
      this.anchorEl.focus();
    }
  }

  bindRef(ref) {
    this.anchorEl = ref;
  }

  render() {
    return (
      <div
        tabIndex="-1"
        className={styles.anchor}
        ref={this.bindRef}
      >
        <div className={styles.hidden} role="document">{pageTitle()}</div>
      </div>
    );
  }
}

NavigationFocusReset.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(NavigationFocusReset);
