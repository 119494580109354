import unionBy from 'lodash/unionBy';

const scoreKey = score =>
  score.framework_tree_node.node_path + score.start_at + score.end_at + score.remote_organization_id.toString() + score.aggregate_key;

function scoresReducer(scores = [], newScores) {
  return unionBy(scores, newScores, scoreKey);
}

export default scoresReducer;
