import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import identity from 'lodash/identity';
import omit from 'lodash/omit';
import defaultsDeep from 'lodash/defaultsDeep';
import { Route } from 'react-router';

import awaitPropsHoc from '../../helpers/awaitProps';
import Page from '../Page';

const AlchemyRoute = (props) => {
  const {
    config: {
      mapStateToProps,
      mapDispatchToProps,
      awaitProps,
      mergeProps,
      connectOptions,
      ...config
    } = {},
    component,
    render,
    ...rest
  } = defaultsDeep({}, omit(props.module, 'default'), omit(props, 'module'));

  const Component = component || render || get(props, 'module.default');

  const InnerWrapper = awaitPropsHoc(Component);
  const connector = mapStateToProps || mapDispatchToProps
    ? connect(mapStateToProps, mapDispatchToProps || {}, mergeProps, connectOptions)
    : identity;

  const OuterWrapper = connector(theProps => (
    <Page {...config} {...theProps}>
      <InnerWrapper awaitProps={awaitProps} {...theProps} />
    </Page>
  ));

  return (
    <Route {...rest} component={OuterWrapper} />
  );
};

AlchemyRoute.propTypes = {
  mapStateToProps: PropTypes.func,
  mapDispatchToProps: PropTypes.func,
  mergeProps: PropTypes.func,
  connectOptions: PropTypes.object,
  config: PropTypes.shape({
    awaitProps: PropTypes.arrayOf(PropTypes.string)
  }),
  module: PropTypes.object,
  ...Route.propTypes
};

export default AlchemyRoute;
