import { store } from '../store';

export const importFns = {
  // Each import needs to be listed separately
  // in order for webpack to be able to identify it
  2018: () => import('../../framework-trees/2018/kde.yml'),
  2019: () => import('../../framework-trees/2019/kde.yml'),
  2020: () => import('../../framework-trees/2020/kde.yml'),
  2021: () => import('../../framework-trees/2021/kde.yml'),
  2022: () => import('../../framework-trees/2022/kde.yml'),
  2023: () => import('../../framework-trees/2023/kde.yml')
};

// Triggers action which
// - Sets `framework` loading indicator
// - Lazy-loads framework tree for given school year and stores it in redux
// - Unsets loading indicator
// - Runs subsequent actions if there are any
const buildLoadFrameworkAction = ({ actions } = {}) => {
  const selectedSchoolYear = store.getState().module.schoolYear;

  if (typeof importFns[selectedSchoolYear] === 'function') {
    return {
      type: 'LOAD_WEBPACK_FRAMEWORK',
      loadingIndicator: 'framework',
      importFn: importFns[selectedSchoolYear],
      actions
    };
  } else {
    return {
      type: 'LOAD_WEBPACK_FRAMEWORK_FAILURE'
    };
  }
};

export default buildLoadFrameworkAction;
