import React from 'react';
import PropTypes from 'prop-types';

import Field from 'cui/lib/components/Form/Field';

import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import { getFieldPrompt } from '../../helpers/collectorHelpers';
import orgCanAccessCollectorDataPoint from '../../helpers/orgCanAccessCollectorDataPoint';
import CollectionCheckboxesField from '../CollectionCheckboxesField';
import CollectionNumberField from '../CollectionNumberField';
import CollectionRadioField from '../CollectionRadioField';
import CollectionTextField from '../CollectionTextField';

import './DataPointField.module.scss';

class CollectionDataPointField extends React.PureComponent {
  static NOT_APPLICABLE_TEXT = 'Not Applicable to this School';

  renderNotApplicable() {
    const { dp } = this.props;

    return (
      <Field label={getFieldPrompt(dp)}>
        {this.constructor.NOT_APPLICABLE_TEXT}
      </Field>
    );
  }

  render() {
    const { dp, currentOrganization, scores, previousYearScores, submissionValueGenerators } = this.props;
    const {
      name = '',
      field_type: fieldType = '',
      field_prompt: fieldPrompt = name,
      field_options: fieldOptions = {}
    } = dp;
    const nodePath = dp.node_path;

    if (!orgCanAccessCollectorDataPoint(dp, currentOrganization)) {
      return this.renderNotApplicable();
    }

    const currentScore = currentOrgYearScores(scores, currentOrganization, dp);
    const previousYearScore = currentOrgYearScores(previousYearScores, currentOrganization, dp);

    if (fieldType === 'number') {
      return (
        <CollectionNumberField
          dp={dp}
          currentScore={currentScore}
          previousYearScore={previousYearScore}
          submissionValueGenerators={submissionValueGenerators}
        />
      );
    } else if (fieldType === 'text') {
      return (
        <CollectionTextField
          type={fieldOptions.type}
          dp={dp}
          currentScore={currentScore}
          submissionValueGenerators={submissionValueGenerators}
        />
      );
    } else if (fieldType === 'checkbox') {
      return (
        <CollectionCheckboxesField
          dp={dp}
          currentScore={currentScore}
          submissionValueGenerators={submissionValueGenerators}
        />
      );
    } else if (fieldType === 'radio') {
      return (
        <CollectionRadioField
          currentScore={currentScore}
          dp={dp}
          fieldPrompt={fieldPrompt}
          nodePath={nodePath}
          submissionValueGenerators={submissionValueGenerators}
        />
      );
    }

    return (<div />);
  }
}

CollectionDataPointField.propTypes = {
  dp: PropTypes.object,
  currentOrganization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  previousYearScores: PropTypes.arrayOf(PropTypes.object),
  showRequired: PropTypes.bool,
  submissionValueGenerators: PropTypes.object
};

export default CollectionDataPointField;
