import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Heading } from 'cui';

import Link from '../Link';

GatewayHeader.propTypes = {
  heading: PropTypes.string.isRequired,
  subheading: PropTypes.string,
  iconUrl: PropTypes.string,
  linkTo: PropTypes.string,
  headerSize: PropTypes.string
};

GatewayHeader.defaultProps = {
  headerSize: 'xxLarge'
};

function GatewayHeader({ heading, subheading, iconUrl, linkTo, headerSize }) {
  return (
    <div className="gatewayHeader">
      <img className="gatewayHeader-icon" src={iconUrl} role="presentation" alt="" />
      <div className="gatewayHeader-title">
        <Heading level={3} size={headerSize} className="gatewayHeader-heading">{heading}</Heading>
        <p className="gatewayHeader-subheading">{subheading}</p>
      </div>
      { linkTo && <Link to={linkTo} className="kde-button gatewayHeader-link">Explore All Data <Icon name="bb-caret-right" /></Link> }
    </div>
  );
}

export default GatewayHeader;
