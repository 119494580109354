import { put, call, select, takeEvery, all } from 'redux-saga/effects';
import get from 'lodash/get';
import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import flatMap from 'lodash/flatMap';
import compact from 'lodash/compact';
import each from 'lodash/each';

import { updateQueryString } from '../../helpers/queryStringHelpers';
import when from '../../helpers/when';
import template from '../../helpers/template';
import { setFrameworkData, setLoading, setLoaded } from '../../common_actions';
import { mapStateToProps as propMapper } from '../../helpers/propMapper';
import { findAnyDimensionNodes, findDimensionNodes, getAllDataPoints } from '../../helpers/frameworkHelpers';
import buildGraphqlQuery from '../../helpers/build_graphql_query';
import { getPageContext, getUserContext } from '../../helpers/backend_context';

import {
  setCurrentSiteData,
  setCurrentOrgData,
  setOrganizationsData,
  setPageContext,
  setUserContext,
  setToken,
  setGraphData,
  setGraphError,
  setPortalData
} from '../actions';

import {
  orgDataFetch,
  fetchOrganizations,
  getJwtTokenFromClarity,
  getPublicJwtTokenFromClarity,
  graphDataFetch,
  portalDataFetch
} from '../backend';

import { TOKEN_REFRESH_GAP } from '../constants';

import { pluckNodes } from '../../helpers/nodeHelpers';

let finishFetchingInitialData;

export const initialDataFetched = new Promise((resolve) => {
  finishFetchingInitialData = resolve;
});

function isJWTTokenInvalid(token) {
  if (typeof token === 'string') {
    const tokenData = JSON.parse(atob(token.split('.')[1])) || {};
    return tokenData.exp && new Date(tokenData.exp * 1000 - TOKEN_REFRESH_GAP) < new Date();
  }

  return true;
}

function* fetchInitialData(config) {
  const userContext = getUserContext(get(config, 'userContext'));
  let jwtToken;
  if (!isEmpty(userContext)) {
    jwtToken = yield call(getJwtTokenFromClarity());
    yield put(setUserContext(userContext));
  } else {
    jwtToken = yield call(getPublicJwtTokenFromClarity(['groot', 'insights', 'svcpd', 'pdf_generator']));
  }
  const pageContext = getPageContext(get(config, 'pageContext'));

  yield put(setPageContext({ ...pageContext, jwtToken }));

  yield put(setCurrentSiteData({ config: config.site }));

  finishFetchingInitialData();
}

function* runActions(a) {
  const variables = yield select(state => propMapper(state, a));
  yield put({ ...a, ...variables });
}

function* fetchOrganizationData(action) {
  const orgData = yield call(orgDataFetch(action.id, action.add_on_slug));
  yield put(setCurrentOrgData(orgData));
  yield all((action.actions || []).map(runActions));
}

function* pluckFrameworkNodePaths(action) {
  const {
    domainSlug,
    successIndicatorSlug,
    variableSlug,
    framework,
    desiredNodeType,
    stateObjectMap,
    currentOrganization,
    featureFlags
  } = action;
  const propVariables = yield select(state => propMapper({ ...action, ...state }, action));
  let parentNode = framework || propVariables.framework;
  const domainSlugToUse = domainSlug || propVariables.domainSlug;

  // digs down to a node depth depending on the slugs passed to the action
  if (domainSlugToUse) {
    parentNode = find(findDimensionNodes(parentNode, 'domain'), { slug: domainSlugToUse });

    if (successIndicatorSlug) {
      parentNode = find(findDimensionNodes(parentNode, 'success_indicator'), { slug: successIndicatorSlug });

      if (variableSlug) {
        parentNode = find(findDimensionNodes(parentNode, 'variable'), { slug: variableSlug });
      }
    }
  }
  const nodes = findAnyDimensionNodes(parentNode, desiredNodeType);
  const nodePaths = map(nodes, 'node_path');
  const dataNodePaths = map(nodes, ({ data_node_path: dataNodePath, node_path: nodePath }) => dataNodePath || nodePath);
  const additionalNodes = action.pluckNodes ? action.pluckNodes(nodes, framework, currentOrganization, featureFlags) : {};
  yield put(setGraphData({ nodes, nodePaths, dataNodePaths, ...additionalNodes }, stateObjectMap));
  yield all((action.actions || []).map(runActions));
}

const getDataPointsByPaths = (framework, defaultPaths = []) => {
  const paths = [...defaultPaths];
  const dataPoints = getAllDataPoints(framework);

  return dataPoints.filter(dataPoint => paths.includes(dataPoint.node_path));
};

function schoolReportNodes(framework) {
  return get(framework, 'metadata.schoolReportDataPoints.node_paths', [])
    .map(n => n.node_path);
}

function* pluckDashboardNodePaths(action) {
  const { currentOrganization, framework, stateObjectMap, featureFlags } = action;
  const dashboardNodePaths = compact(uniq(
    framework.items
      .filter(item => !!get(item, 'metadata.dashboard', []))
      .reduce((paths, node) => {
        const newPaths = flatMap(get(node, 'metadata.dashboard.charts', []), chart => chart.metadata.data_node_path);

        return [
          ...paths,
          ...newPaths
        ];
      }, [])
      .concat(schoolReportNodes(framework))
  ));
  // Make sure we dont query the same data points from both Groot and DataPortal
  const {
    nodes,
    nodePaths,
    dataNodePaths
  } = pluckNodes(getDataPointsByPaths(framework, dashboardNodePaths), framework, currentOrganization, featureFlags);

  const additionalNodes = action.pluckNodes ? action.pluckNodes(nodes, framework, currentOrganization, featureFlags) : {};

  yield put(setGraphData({ nodes, nodePaths, dataNodePaths, additionalNodes }, stateObjectMap));
  yield all((action.actions || []).map(runActions));
}

function* fetchOrganizationsData(action) {
  const orgData = yield call(fetchOrganizations(action.parentId));
  yield put(setOrganizationsData(orgData));
  yield all((action.actions || []).map(runActions));
}

function* getJWTToken() {
  let token = yield select(state => get(state.module, 'pageContext.jwtToken'));

  if (isJWTTokenInvalid(token)) {
    const userContext = yield select(state => get(state.module, 'userContext'));

    if (!isEmpty(userContext)) {
      token = yield call(getJwtTokenFromClarity());
    } else {
      token = yield call(getPublicJwtTokenFromClarity(['groot', 'insights', 'svcpd', 'pdf_generator']));
    }

    yield put(setPageContext({ jwtToken: token }));
  }

  return token;
}

function* getServiceJWTToken(service) {
  if (!service) return null;

  let token = yield select(state => get(state.module, 'jwtTokens', service));

  if (isJWTTokenInvalid(token)) {
    token = yield call(getJwtTokenFromClarity(service));
    yield put(setToken(service, token));
  }

  return token;
}

function* fetchGraphData(action) {
  const indicator = typeof action.loadingIndicator === 'function' ?
    action.loadingIndicator(action) :
    (template(action.loadingIndicator, action) || 'graph');
  const { propsForNextActions = [], isPublic = false } = action;
  const jwtToken = yield call(getJWTToken);
  const propVariables = yield select(state => propMapper({ ...action, ...state }, action));
  if (yield select(state => !when(action.when, { ...state, ...action, ...propVariables }))) {
    // `when` evaluated to false, so don't run this action, but do kick off any
    // actions that depend on it. (if there's ever a time we need to run some
    // dependent actions only when this action actually fires, we could
    // introduce a new `actionsWhenRun` key and add:
    //   yield all((action.actionsWhenRun || []).map(runActions));
    // to the bottom of this method, then pass said actions in via
    // `actionsWhenRun` instead of `actions`.)
    yield all((action.actions || []).map(runActions));
    return;
  }
  yield put(setLoading(indicator));
  let queryParams = { ...action, ...propVariables };
  if (action.includeUrlParamsInPropVariables) {
    const { params: urlParams = {} } = propVariables;
    queryParams = { ...action, ...propVariables, ...urlParams };
  }
  const query = buildGraphqlQuery(action.query, queryParams);
  const { propVariablesToVariables = [] } = action;
  const graphVariables = reduce(propVariablesToVariables, (acc, variable) => {
    const variableInPropVariables = propVariables[variable];
    if (variableInPropVariables) {
      acc[variable] = variableInPropVariables;
    }
    return acc;
  }, {});

  const { variables = {} } = action;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(variables)) {
    if (typeof variables[key] === 'function') {
      variables[key] = yield select(variables[key]);
    }
  }

  try {
    const graphData = yield call(graphDataFetch(action.service, query, { ...graphVariables, ...variables }, { isPublic, jwtToken }));
    const nextActionPropsContext = {
      ...action,
      ...propVariables,
      currentGraphData: graphData
    };
    // Add data that are fetched from previous query in case the nested actions need those data
    const nextActionProps = reduce(propsForNextActions, (acc, dataKey, propKey) => {
      const prop = get(nextActionPropsContext, dataKey);
      if (prop) {
        acc[propKey] = prop;
      }
      return acc;
    }, {});
    yield put(setGraphData(graphData, action.stateObjectMap, action));
    yield put(setLoaded(indicator));
    yield all((action.actions || []).map(nextAction => runActions({ ...nextActionProps, ...nextAction })));
  } catch (error) {
    const nodePath = get(queryParams, 'variables.nodePath');
    yield put(setLoaded(indicator));
    yield put(setGraphError(error, nodePath));
  }
}

function* updateUrlParam(action) {
  const { loadingIndicator = 'urlParams', paramValues = {}, paramsToRemove = [], location, history } = action;
  yield put(setLoading(loadingIndicator));
  if (when(action.when, action)) {
    const paramsToAdd = reduce(paramValues, (acc, key, value) => {
      if (action[value]) acc[key] = action[value];
      return acc;
    }, {});
    const updateQueryStringOptions = {
      paramsToAdd,
      paramToRemove: paramsToRemove,
      queryString: location.search,
      pathname: location.pathname
    };
    const newQueryString = updateQueryString(updateQueryStringOptions);
    history.push(newQueryString);
  } else {
    const updateQueryStringOptions = {
      paramToRemove: Object.keys(paramValues).concat(paramsToRemove),
      queryString: location.search,
      pathname: location.pathname
    };
    const newQueryString = updateQueryString(updateQueryStringOptions);
    history.push(newQueryString);
  }
  yield put(setLoaded(loadingIndicator));
}

function* changeUrl(action) {
  const { loadingIndicator = 'urlParams', location, history } = action;
  yield put(setLoading(loadingIndicator));
  if (when(action.when, action)) {
    if (action.url && location && history) {
      history.push(action.url);
    }
  }
  yield put(setLoaded(loadingIndicator));
}

function* fetchToken(action) {
  yield call(getJWTToken);
  yield call(getServiceJWTToken, get(action, 'service'));
  yield all((action.actions || []).map(runActions));
}

function* loadWebpackFramework(action) {
  yield put(setLoading(action.loadingIndicator));

  const framework = (yield action.importFn()).default;

  yield put(setFrameworkData(framework));
  yield put(setLoaded(action.loadingIndicator));
  yield all((action.actions || []).map(runActions));
}

function* fetchPortalData(action) {
  const { loadingIndicator = 'scoresFromPortalDataService' } = action;
  const indicator = typeof loadingIndicator === 'function' ? loadingIndicator(action) : loadingIndicator;
  yield put(setLoading(indicator));

  // query can be either a raw JSON object to send along to the portal data
  // sevice...
  let query = action.query;
  // ...or a function that takes the current redux state and returns a raw JSON
  // object to send along to the portal data service.
  if (typeof query === 'function') {
    query = yield select(state => query(state, action));
  }

  const jwtToken = yield call(getServiceJWTToken, 'svcpd');
  let data;

  // query is array when we also want to fetch data for current org parent
  if (Array.isArray(query)) {
    const queryData = [];
    for (let i = 0; i < query.length; i += 1) {
      queryData.push(yield call(portalDataFetch(query[i], jwtToken)));
    }

    // combine scores for queries for different orgs
    // It only shallowly merge the first level
    data = action.customReducer ? action.customReducer(queryData) : queryData.reduce((memo, d = {}) => {
      each(d, (scoreForOrg, nodePath) => {
        if (memo[nodePath]) {
          // merge data for same node path from different queries
          memo[nodePath] = { ...scoreForOrg, ...memo[nodePath] };
        } else {
          // add it when it does not exist yet
          memo[nodePath] = scoreForOrg;
        }
      });

      return memo;
    }, {});
  } else {
    data = yield call(portalDataFetch(query, jwtToken));
  }
  yield put(setPortalData(data, action));
  yield put(setLoaded(indicator));
}

function* watchForPortalDataRequests() {
  yield takeEvery('PORTAL_DATA_LOAD', fetchPortalData);
}

function* watchForLoadOrganization() {
  yield takeEvery('LOAD_ORGANIZATION', fetchOrganizationData);
}

function* watchForLoadOrganizations() {
  yield takeEvery('LOAD_ORGANIZATIONS', fetchOrganizationsData);
}

function* watchForPluckNodePaths() {
  yield takeEvery('PLUCK_NODE_PATHS', pluckFrameworkNodePaths);
}

function* watchForPluckDashboardNodePaths() {
  yield takeEvery('PLUCK_DASHBOARD_NODE_PATHS', pluckDashboardNodePaths);
}

function* watchForGraphRequests() {
  yield takeEvery('GRAPH_LOAD', fetchGraphData);
}

function* watchForUpdateUrlParam() {
  yield takeEvery('UPDATE_URL_PARAM', updateUrlParam);
}

function* watchForChangeUrl() {
  yield takeEvery('CHANGE_URL', changeUrl);
}

function* watchForFetchToken() {
  yield takeEvery('FETCH_TOKEN', fetchToken);
}

function* watchForLoadWebpackFramework() {
  yield takeEvery('LOAD_WEBPACK_FRAMEWORK', loadWebpackFramework);
}

export default function* rootSaga(config) {
  yield all([
    fetchInitialData(config),
    watchForLoadOrganization(),
    watchForLoadOrganizations(),
    watchForGraphRequests(),
    watchForPluckNodePaths(),
    watchForPluckDashboardNodePaths(),
    watchForUpdateUrlParam(),
    watchForChangeUrl(),
    watchForFetchToken(),
    watchForPortalDataRequests(),
    watchForLoadWebpackFramework()
  ]);
}
