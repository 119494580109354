/**
 * Commonly used propTypes patterns to use in any component
 */
import PropTypes from 'prop-types';

// Data values that could be ambiguously string or number
const dataValue = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

export default {
  dataValue
};
