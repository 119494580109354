import isEmpty from 'lodash/isEmpty';
import unset from 'lodash/unset';
import castArray from 'lodash/castArray';

import qs from 'qs';
import deepMap from 'deep-map';

export const convertFiltersToQueryString = (filters) => {
  if (isEmpty(filters)) {
    return '';
  }
  return qs.stringify(deepMap(filters, value => JSON.stringify(value)), { addQueryPrefix: true });
};

export const convertUrlParamsToObject = (params = '') => {
  if (isEmpty(params)) {
    return {};
  }
  try {
    const paramsObject = deepMap(qs.parse(params, { ignoreQueryPrefix: true }), value => JSON.parse(value));
    return paramsObject;
  } catch (e) {
    return {};
  }
};

export const updateQueryString = (options) => {
  const {
    queryString = '',
    pathname = '',
    topLevelParamKey,
    paramsToAdd = {},
    paramToRemove = ''
  } = options;

  let parsedParams = convertUrlParamsToObject(queryString);
  if (topLevelParamKey) {
    parsedParams[topLevelParamKey] = paramsToAdd;
  } else {
    parsedParams = { ...parsedParams, ...paramsToAdd };
  }

  if (!isEmpty(paramToRemove)) {
    castArray(paramToRemove).forEach(key => unset(parsedParams, key));
  }

  return `${pathname}${convertFiltersToQueryString(parsedParams)}`;
};

export default updateQueryString;
