import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cx from 'classnames';

import Icon from 'cui/lib/components/Icon';
import Heading from 'cui/lib/components/Heading';

import FlexBar from 'cui/lib/components/FlexBar';
import Link from '../Link';
import Breadcrumbs from '../Breadcrumbs';
import MainContentAnchor from '../MainContentAnchor';
import OrgPickerLinkContainer from '../OrgPickerLinkContainer';
import WithFeature from '../WithFeature';
import { getPreviewSiteFeatureFlagOverrides } from '../../helpers/previewSite';
import Sticky from '../Sticky';
import CovidBanner from '../CovidBanner';
import SchoolReportDropdown from './SchoolReportDropdown';
import addYearToUrl from '../Link/addYearToUrl';

import styles from './index.module.scss';

function OrgHeaderUI({
  crumbs,
  orgName,
  schoolYear,
  headTitle,
  headName,
  headEmail,
  organization,
  allOrganizations,
  showDownloadDropdown,
  covidImpactBanner,
  location
}) {
  const year = new URLSearchParams(location.search).get('year');
  const crumbsWithYear = crumbs.map(crumb => ({
    ...crumb,
    href: addYearToUrl(crumb.href, year)
  }));

  return (
    <Sticky className={styles.stickyHeader}>
      <div>
        {covidImpactBanner && <CovidBanner link={covidImpactBanner?.link} />}

        <div className={cx('orgHeader container', styles.orgHeader)}>
          <div className="container-constrained">
            <div className="cui-breadcrumb">
              <Breadcrumbs crumbs={crumbsWithYear} />
              <OrgPickerLinkContainer org={organization} allOrgs={allOrganizations} />
            </div>

            <MainContentAnchor />

            <Heading level={2} size="xxLarge">
              <Link to={`/organization/${organization.id}`}>{orgName}</Link>
            </Heading>

            <FlexBar className={styles.orgHeader}>
              <FlexBar.Item>
                <dl className="orgHeader-metaItem">
                  <dt className="orgHeader-metaLabel">
                    <Heading level={2} size="small">
                      {headTitle}
                    </Heading>
                  </dt>
                  <dd className="orgHeader-metaValue">
                    <strong>{headName}</strong>
                    <small>
                      <Icon name="bb-envelope" /> {headEmail}
                    </small>
                  </dd>
                </dl>
              </FlexBar.Item>
              <FlexBar.Item className={styles.headerButtons}>
                {showDownloadDropdown && (
                  <WithFeature
                    name="KDESchoolReportDownload"
                    featureFlagsOverwrite={getPreviewSiteFeatureFlagOverrides()}
                  >
                    {({ isActive }) =>
                      isActive && (
                        <SchoolReportDropdown
                          className={styles.downloadDropdown}
                          currentOrganization={organization}
                          schoolYear={schoolYear}
                        />
                      )
                    }
                  </WithFeature>
                )}
                <a
                  href={`/organization/${organization.id}/compare?year=${schoolYear}`}
                  className="orgHeader-btn orgHeader-compare-btn cui-btn cui-btn_secondary"
                >
                  Compare Key Topics
                </a>
              </FlexBar.Item>
            </FlexBar>
          </div>
        </div>
      </div>
    </Sticky>
  );
}

OrgHeaderUI.propTypes = {
  location: PropTypes.object.isRequired,
  crumbs: PropTypes.arrayOf(PropTypes.object),
  orgName: PropTypes.string,
  schoolYear: PropTypes.number,
  headTitle: PropTypes.string,
  headName: PropTypes.string,
  headEmail: PropTypes.string,
  organization: PropTypes.object,
  allOrganizations: PropTypes.arrayOf(PropTypes.object),
  showDownloadDropdown: PropTypes.bool,
  covidImpactBanner: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  }).isRequired
};

export default withRouter(OrgHeaderUI);
