import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const KdeGroupedBarChartDetailsPopover = ({ theme, data }) => (
  <div className={styles.details}>
    {
      data.value.map(({ colorIndex, label, value }, index) => (
        <div key={index} className={styles.detailsItem}>
          <div className={styles.detailsLabel}>
            {label}
          </div>
          <Icon
            name="bb-circle-check"
            size="large"
            className={`theme-${theme}-fill-${colorIndex}`}
          />
          <span className={styles.detailsValue}>
            {value}%
          </span>
        </div>
      ))
    }
  </div>
);

KdeGroupedBarChartDetailsPopover.propTypes = {
  data: PropTypes.object,
  theme: PropTypes.string
};

KdeGroupedBarChartDetailsPopover.defaultProps = {
  data: {},
  theme: null
};

export default KdeGroupedBarChartDetailsPopover;
