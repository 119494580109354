import React from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';

import CuiTable from 'cui/lib/components/Table';
import CuiLabel from 'cui/lib/components/Label';

import get from 'lodash/get';

import conditional from '../../helpers/conditional';

import numberFormatter from '../../helpers/numberFormatter';

import chartDataAdapter from '../../helpers/chartDataAdapters';

export const TableComparison = (props) => {
  const { node, hasFilter, config, chartType, params = {} } = props;
  const { adapter = 'defaultTableComparisonAdapter', linkableShape } = config;
  const { scoring_options: scoringOptions } = node;
  let currentFilter;
  if (hasFilter) {
    currentFilter = get(params, 'filters.demographic', 'All');
  }

  const FORMATTER = {
    comma_delimited: v => numberFormatter(v, 'number'),
    percent: v => numberFormatter(v, 'percent'),
    identity: v => v,
    boolean: v => Boolean(v),
    twoDigits: v => v.toFixed(2)
  };

  const formatterTypes = Object.keys(FORMATTER);

  const chartOptions = {
    ...props,
    ...params,
    formatterTypes,
    currentFilter,
    formatter: FORMATTER
  };

  const data = chartDataAdapter(adapter || chartType, chartOptions);
  const shape = conditional(scoringOptions.shape, {}, props);
  const label = shape.label;
  const tableValueLabel = get(shape, 'table_value_label', 'value');
  const linkable = !!linkableShape;

  return (
    <div>
      <CuiLabel className="cui-margin-bottom-xLarge" value={label} />
      <CuiTable
        data={data}
        striped
        linkable={linkable}
      >
        <CuiTable.ColumnHeader property="name">name</CuiTable.ColumnHeader>
        <CuiTable.ColumnHeader property="value">{tableValueLabel}</CuiTable.ColumnHeader>
      </CuiTable>
    </div>
  );
};

TableComparison.propTypes = {
  organization: PropTypes.object,
  entityScores: PropTypes.arrayOf(
    PropTypes.object
  ),
  entities: PropTypes.arrayOf(
    PropTypes.object
  ),
  node: PropTypes.object,
  params: PropTypes.object,
  hasFilter: PropTypes.bool,
  config: PropTypes.object,
  chartType: PropTypes.string
};

TableComparison.defaultProps = {
  hasFilter: true,
  config: {}
};

export default withRouter(TableComparison);
