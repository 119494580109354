export default `
  query {
    organizations(years: [:year]) {
      id
      name
      entity_type
      dist_number
      address
      city
      zipcode
    }
  }
`;
