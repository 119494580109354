import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import sortBy from 'lodash/sortBy';

import template from '../../../template';
import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, scores, directScore } = options;
  const scoreValues = directScore || find(scores, { node_path: node.node_path });
  const scoringOptions = node.scoring_options;
  const shape = conditional(node.scoring_options.shape, {}, options);
  const data = scoreValues && map(scoreValues.values, (score) => {
    const key = score[scoringOptions.scoreKey];
    const subLabelTemplate = get(shape, `${key}.subLabelTemplate`, get(scoringOptions, 'subLabelTemplate', '{{value}}'));
    return ({
      label: get(shape, `${key}.label`, key),
      key,
      value: get(score, (scoringOptions.valueKey || 'value'), 0),
      colorIndex: get(shape, `${key}.colorIndex`, 0),
      confidenceInterval: score.confidenceInterval,
      index: get(shape, `${key}.index`, null),
      subLabel: template(subLabelTemplate, { value: '', ...options, ...score })
    });
  });

  return sortBy(data, ['index']);
};

export default adapter;
