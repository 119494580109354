import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import memoize from 'lodash/memoize';
import get from 'lodash/get';

import Autocomplete from 'cui/lib/components/Autocomplete';

import { getAllVisibleDataPoints } from '../../helpers/nodeHelpers';
import addYearToUrl from '../Link/addYearToUrl';
import styles from './index.module.scss';

const localeCompare = Intl && Intl.Collator ? new Intl.Collator('en').compare : (a, b) => (a > b ? 1 : -1);

const buildUrl = (dataPoint, organization, schoolYear) =>
  `/organization/${organization.id}/${dataPoint.domain.slug}/` +
    `${dataPoint.successIndicator.slug}/${dataPoint.variable.slug}` +
    `?year=${schoolYear}#${dataPoint.slug}`;

const buildAutocompleteOptions = memoize((dataPoints, organization, schoolYear) => {
  if (!dataPoints) return [];

  return dataPoints
    .map(dataPoint => ({
      text: `${dataPoint.name} (${dataPoint.domain.name} > ${dataPoint.successIndicator.name} > ${dataPoint.variable.name})`,
      name: dataPoint.name,
      details: `${dataPoint.domain.name} > ${dataPoint.successIndicator.name}`,
      url: buildUrl(dataPoint, organization, schoolYear)
    }))
    .sort(({ name: a }, { name: b }) => localeCompare(a, b));
});

// eslint-disable-next-line react/prop-types
const renderAutocompleteItem = ({ name, details, searchQuery }) => (
  <div>
    <div className="cui-autocomplete-item-label">
      <Autocomplete.HighlightedItem text={name} match={searchQuery} />
    </div>
    <div className="cui-autocomplete-item-details">
      <Autocomplete.HighlightedItem text={details} match={searchQuery} />
    </div>
  </div>
);

const DataPointSearch = (props) => {
  const dataPoints = getAllVisibleDataPoints(props.framework, props);

  const autocompleteOptions = buildAutocompleteOptions(
    dataPoints,
    props.currentOrganization,
    props.schoolYear
  );

  const navigateToDataPoint = ({ url }) => {
    const { location, history } = props;
    const year = new URLSearchParams(location.search).get('year');

    history.push(decodeURIComponent(addYearToUrl(url, year)));
  };

  return (
    <Autocomplete
      className={styles.search}
      data={autocompleteOptions}
      itemRenderer={renderAutocompleteItem}
      inputAttrs={{
        className: 'cui-input_stroked',
        type: 'search',
        placeholder: 'Search by data indicator',
        'aria-label': 'Search by data indicator'
      }}
      onClick={navigateToDataPoint}
      minQueryLength={2}
      minQueryLengthMessage="Enter at least two characters"
    />
  );
};

DataPointSearch.propTypes = {
  framework: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object)
  }),
  currentOrganization: PropTypes.object,
  schoolYear: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  currentOrganization: get(state, 'module.currentOrganization'),
  schoolYear: get(state, 'module.schoolYear'),
  framework: get(state, 'framework.kde')
});

export default withRouter(connect(mapStateToProps)(DataPointSearch));
