import React from 'react';
import PropTypes from 'prop-types';
import { get, concat } from 'lodash';
import ScoreDot from 'cui/lib/components/ScoreDot';
import List from 'cui/lib/components/List';
import { valueToLocaleString } from '../../helpers/template/format';
import { computeProps } from '../../helpers/adapterHelpers';

export default function KdeDashboardBarChartPopover({ config, data }) {
  const skipComputedProps = get(config, 'options.chartOptions.detailsPopover.skipComputedProps', false);
  let items = get(data, 'value', []);

  const removeStudentCount = get(config, 'options.removeStudentCount', false);

  if (!skipComputedProps) {
    items = computeProps(items, get(config, 'options.computedProps'));
  }
  /* eslint-disable max-len */
  return (
    <div className="cui-popover-body">
      <List className="legend">
        {
          items.map((d, i) => (
            <List.Item
              key={i}
              className="legend-item"
              icon={<ScoreDot className="legend-icon" type="solid" theme="kde-multi" value={d.colorIndex || i} />}
            >
              <div className="legend-label">{d.label}</div>
              {d.popoverItem ? d.popoverItem : (
                <div>
                  <span className="legend-value">{d.originalValue === '*' ? '*' : `${d.value}%`}</span>
                  <span className="legend-label">
                    <em className="u-indent">
                      of {removeStudentCount ? ' ' : concat(valueToLocaleString(d.denominator) ? valueToLocaleString(d.denominator).toString() : '', ' ')} students
                    </em>
                  </span>
                </div>
              )}
            </List.Item>
          ))
        }
      </List>
    </div>
  );
/* eslint-enable max-len */
}

KdeDashboardBarChartPopover.propTypes = {
  config: PropTypes.object,
  data: PropTypes.object
};
