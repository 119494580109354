import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import get from 'lodash/get';

import conditional from '../../helpers/conditional';
import dataPointHasScores from '../../helpers/dataPointHasScores';

const defaultProps = {
  node: {},
  organization: {},
  scores: [],
  tabs: [],
  whyThisMatters: undefined
};

export default function propMapper(props) {
  const mergedProps = merge({}, defaultProps, props);

  const {
    node,
    viewingOptions,
    selectedViewingItem,
    selectViewingOptions
  } = mergedProps;

  let viewingDropdown;

  if (!isEmpty(viewingOptions)) {
    viewingDropdown = {
      type: viewingOptions.type,
      items: Object.keys(viewingOptions.items).map(key => (
        { text: viewingOptions.items[key], onClick: () => selectViewingOptions(key) }
      )),
      initiallySelectedItem: Object.keys(viewingOptions.items).indexOf(selectedViewingItem)
    };
  }

  const nodeHasScores = dataPointHasScores(mergedProps);

  const className = cn({
    [`${node.slug}-download`]: true,
    'dataPoint--hide-title': get(node, 'metadata.hideTitle', false),
    'dataPoint--hide-tabs': get(node, 'metadata.hideTabs', false)
  });

  return {
    className,
    headerTitle: node.name,
    headerIcon: node.metadata && node.metadata.header_icon,
    showPopover: !!node.info_icon,
    popoverText: conditional(node.info_icon, {}, mergedProps).text,
    chartCssStyle: node.charts && node.charts.customPadding,
    viewingDropdown,
    nodeHasScores
  };
}
