/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';

export const isAwaitingProps = ({ awaitProps = [], ...otherProps }) => (
  !isEmpty(awaitProps) && some(awaitProps, prop => !get(otherProps, prop))
);

export default function awaitPropsWrapper(WrappedComponent) {
  return class extends React.Component {
    static displayName = WrappedComponent.displayName || WrappedComponent.name;

    static propTypes = {
      awaitProps: PropTypes.arrayOf(PropTypes.string)
    }

    static defaultProps = {
      awaitProps: []
    }

    render() {
      // eslint-disable-next-line no-unused-vars
      const { awaitProps, ...otherProps } = this.props;
      if (isAwaitingProps(this.props)) return null;
      return <WrappedComponent {...otherProps} />;
    }
  };
}
