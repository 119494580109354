import React from 'react';
import PropTypes from 'prop-types';

import List from 'cui/lib/components/List';

import styles from './skeleton.module.scss';

const TextList = ({ size, listItemClass }) => (
  <div className={styles.skeletonScreenTestList}>
    <List>
      {
        Array(size || 0).fill(null).map((v, i) => (
          <List.Item key={i} className={listItemClass || 'cui-text_small'}>&nbsp;</List.Item>
        ))
      }
    </List>
  </div>

);

TextList.propTypes = {
  size: PropTypes.number,
  listItemClass: PropTypes.string
};

export default TextList;
