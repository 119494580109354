import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import multiSlot from 'cui/lib/components/shared/MultiSlot';

import EmptyState from '../Chart/ChartEmptyState';
import styles from './index.module.scss';

function ChartWithLegend({ data, slots, legendPlacement, className, chartAlignment }) {
  if (!data) {
    return (<EmptyState emptyMessage="No Data Reported" />);
  }

  const bottomLegend = legendPlacement === 'bottom';
  const alignTop = chartAlignment === 'top';
  const containerClasses = {
    [styles.container_bottom]: bottomLegend,
    [styles.container_alignTop]: alignTop
  };

  return (
    <div className={cx(styles.container, className, containerClasses)}>
      <div className={styles.chart}>{slots.chart}</div>
      <div className={bottomLegend ? styles.bottomLegend : styles.legend}>{slots.legend}</div>
    </div>
  );
}

ChartWithLegend.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  slots: PropTypes.shape({
    chart: PropTypes.node,
    legend: PropTypes.node
  }),
  legendPlacement: PropTypes.oneOf(['bottom', 'right']),
  chartAlignment: PropTypes.oneOf(['center', 'top']),
  className: PropTypes.string
};

ChartWithLegend.defaultProps = {
  legendPlacement: 'right',
  chartAlignment: 'center'
};

export default multiSlot(ChartWithLegend);
