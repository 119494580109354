/**
 * @document
 *   @category JS
 *   @subcategory Components
 *   @title DataPointBlock
 *   @description
 *   DataPointBlock React Component
 * @property
 *   @name node
 *   @type object
 *   @description
 *   Data point node object
 * @property
 *   @name organization
 *   @type object
 *   @description
 *   Organization object
 * @property
 *   @name scores
 *   @type array
 *   @description
 *   Array of score objects
 * @property
 *   @name tabs
 *   @type object
 *   @description
 *   Array of tab objects
 * @property
 *   @name whyThisMatters
 *   @type object
 *   @description
 *   WhyThisMatters object that is forwarded to WhyThisMatters component
 * @property
 *   @name headerTitle
 *   @type string
 *   @description
 *   Data point block title
 * @property
 *   @name className
 *   @type string
 *   @description
 *   Class name that is attached to the parent container
 * @property
 *   @name popoverText
 *   @type string
 *   @description
 *   Info popover content
 * @property
 *   @name showPopover
 *   @type boolean
 *   @description
 *   When true, render info popover
 * @property
 *   @name viewingDropdown
 *   @type object
 *   @description
 *   Object that is forwarded to the CUIX ViewingBlock component
 * @property
 *   @name chartCssStyle
 *   @type object
 *   @description
 *   Css styles for the chart container
 * @example
 *   @title DataPointBlock
 *   @description
 *   DataPointBlock
 *   @type jsx
 *   @code
 *   <script>
 *     const whyThisMatters = {
 *       citation: 'from Brightbytes',
 *       content: 'The total cost for the app, as well as the cost per license based on total number of licenses'
 *     };
 *     const tabs = [{ name: 'Chart' }];
 *     const scores = [
 *       {
 *         value: {
 *           per_license_cost: '14',
 *           total_cost: '57688975'
 *         },
 *         framework_tree_node: {
 *           node_path: 'learning_outcomes.investment.cost.student_perception.total_cost_product'
 *         }
 *       }
 *     ];
 *     const node = {
 *        node_path: 'learning_outcomes.investment.cost.student_perception.total_cost_product',
 *        node_type: 'data_point',
 *        scoring_options: {
 *          shape: [
 *            {
 *              label: 'cost per license',
 *              template: '{{value}}',
 *              type: 'money',
 *              valueKey: 'per_license_cost'
 *            },
 *           {
 *              label: 'total cost',
 *              template: '{{value}}',
 *              type: 'money',
 *              valueKey: 'total_cost'
 *            }
 *          ]
 *        }
 *     };
 *   </script>
 *   <div style={{ backgroundColor: '#e8eeef', padding: '1rem' }}>
 *     <dashboards.DataPointBlock
 *       node={node}
 *       headerTitle="Total Cost of App"
 *       showPopover
 *       popoverText="total cost of app"
 *       whyThisMatters={whyThisMatters}
 *       tabs={tabs}
 *       scores={scores}
 *       loading={{ isLoading: false }}
 *     />
 *   </div>
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { connect } from 'react-redux';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import DataPointPanel from 'cui/lib/components/DataPointPanel';
import ViewingBlock from 'cui/lib/components/ViewingBlock';
import CuiLabel from 'cui/lib/components/Label';

import connected from '../connected';
import when from '../../helpers/when';
import { filterByMetadata } from '../../helpers/nodeHelpers';
import propMapper from './propMapper';
import Title from './Title';
import DataPoint from './DataPoint';

export class DataPointBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentlySelectedTab: 0
    };
  }

  componentDidMount() {
    if (window.location.hash === `#${this.props.node.slug}`) {
      this.titleRef.scrollIntoView();
    }
  }

  handleTableChange = (tabIndex) => {
    this.setState({
      currentlySelectedTab: tabIndex
    });
  };

  renderViewingBlock() {
    const { viewingDropdown } = this.props;

    if (!viewingDropdown) return null;

    return (
      <div>
        <ViewingBlock {...viewingDropdown} />
        <hr className="margin-bottom" />
      </div>
    );
  }

  renderTabs() {
    const {
      tabs,
      node,
      organization,
      isPreviewSite,
      ...rest
    } = this.props;
    const currentEntity = organization.entity_type || organization.entityType || '';
    const currentSchoolLevel = organization.school_level || organization.schoolLevel || '';

    return reduce(
      tabs.filter(tab => filterByMetadata(tab, { currentEntity, currentSchoolLevel, isPreviewSite })),
      (acc, tab) => {
        const development = process.env.NODE_ENV === 'development';

        if (when(tab.when, { ...this.props, development })) {
          acc.push(
            <DataPointPanel.Pane title={tab.name} key={node.node_path}>
              {tab.title && <CuiLabel className="cui-margin-bottom-xLarge" value={tab.title} />}
              {this.renderViewingBlock()}
              <DataPoint
                organization={organization}
                tab={tab}
                node={node}
                {...rest}
              />
            </DataPointPanel.Pane>
          );
        }
        return acc;
      },
      []
    );
  }

  render() {
    const {
      node,
      showPopover,
      popoverText,
      headerTitle,
      headerIcon,
      whyThisMatters,
      className,
      schoolYear
    } = this.props;
    const { node_path: nodePath, slug } = node;

    const whyThisMattersProps = whyThisMatters ? { popoverProps: { appendToBody: false }, ...whyThisMatters } : undefined;
    const title = <Title
      {...{
        slug,
        nodePath,
        showPopover,
        popoverText,
        headerTitle,
        headerIcon,
        schoolYear
      }}
      headerRef={ref => { this.titleRef = ref; }}
    />;

    return (
      <DataPointPanel
        title={title}
        wtm={whyThisMattersProps}
        key={headerTitle + this.currentlySelectedTab}
        className={className}
        tabbedContentProps={{ activeTab: this.state.currentlySelectedTab, onChange: this.handleTableChange }}
        panelProps={{ padding: 'small' }}
      >
        {this.renderTabs()}
      </DataPointPanel>
    );
  }
}

DataPointBlock.propTypes = {
  node: PropTypes.object,
  nodePath: PropTypes.string,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  tabs: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
  whyThisMatters: PropTypes.object,
  headerIcon: PropTypes.string,
  headerTitle: PropTypes.string,
  popoverText: PropTypes.string,
  showPopover: PropTypes.bool,
  loading: PropTypes.object,
  params: PropTypes.object,
  schoolYear: PropTypes.number,
  additionalChartAdapters: PropTypes.object,
  additionalChartTypes: PropTypes.object,
  entityScores: PropTypes.arrayOf(PropTypes.object),
  showViewOtherData: PropTypes.bool,
  isPreviewSite: PropTypes.bool,
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  viewingDropdown: PropTypes.shape({
    type: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array,
    initiallySelectedItem: PropTypes.number
  })
};

DataPointBlock.defaultProps = {
  node: {},
  nodePath: '',
  organization: {
    entity_type: ''
  },
  scores: [],
  previousYearScores: {},
  tabs: [],
  whyThisMatters: undefined,
  showScoreDates: false,
  additionalChartAdapters: {},
  additionalChartTypes: {},
  showViewOtherData: false
};

const mapStateToProps = state => ({
  params: get(state, 'params', {}),
  loading: get(state, 'loading'),
  framework: get(state, 'framework'),
  schoolYear: get(state, 'module.schoolYear'),
  isPreviewSite: get(state, 'module.pageContext.isPreviewSite'),
  featureFlags: get(state, 'module.pageContext.featureFlags', {})
});

export default withRouter(connect(mapStateToProps)(connected(DataPointBlock, undefined, propMapper)));
/* eslint-enable complexity */
