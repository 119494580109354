const buildGraphqlQuery = (query = '', params) => {
  const result = query.replace(/:(\w+)/g, (_, match) => {
    if (params.stringifyPropMapValue && params.stringifyPropMapValue[match]) {
      return JSON.stringify(params[match]);
    }
    return params[match];
  });
  if (params.ignoreUndefinedArguments) {
    // Note: the second replace in the following code remove trailing commas from the argument list
    // Ideally it should be handle by a single regex but can't figure it out at this moment
    return result.replace(/(\w+):\s*undefined(,\s*)?/g, '')
      .replace(/,\s*\)/g, ')');
  }
  return result;
};

export default buildGraphqlQuery;
