import React from 'react';
import PropTypes from 'prop-types';
import { omit, map, maxBy, compact } from 'lodash';
import ColorDotWithProgressFormatter from '../ColorDotWithProgressFormatter';

export const MostImpactFormatter = ({ value }) => {
  const options = {
    maxValue: 3
  };

  const levelMap = {
    low_usage: 'L',
    medium_usage: 'M',
    high_usage: 'H'
  };

  const values = map(omit(value, ['overall']), (score = {}, key) => ({ ...score, level: levelMap[score.key || key] }));

  const noValue = !compact(map(values, val => val.value)).length;

  const mostImpact = maxBy(values, score => score && score.value);

  if (mostImpact) {
    mostImpact.value = (mostImpact.value / options.maxValue) * 100;
  }

  const impactHasPositiveData = (!noValue && mostImpact && mostImpact.value > 0);

  return (
    <div>
      { noValue && <i>{'No Data'}</i> }
      { !noValue && !impactHasPositiveData && 'No positive impact observed' }
      { impactHasPositiveData && <ColorDotWithProgressFormatter value={mostImpact} /> }
    </div>
  );
};

MostImpactFormatter.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]).isRequired
};

export default MostImpactFormatter;
