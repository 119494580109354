import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import Tooltip from 'cui/lib/components/Tooltip';

import template from '../../helpers/template';
import Markdown from '../../components/Markdown';

import { Icon, Label } from 'cui/lib/components';
import { get } from 'lodash';

import styles from './index.module.scss';

const listRisk = [{ label: 'Very Low' }, { label: 'Low' }, { label: 'Medium' }, { label: 'High' }, { label: 'Very High' }];
const listStatus = [
  { label: 'Declined Significantly' },
  { label: 'Declined' },
  { label: 'Maintained' },
  { label: 'Increased' },
  { label: 'Increased Significantly' }
];
const listColor = [{ label: 'Red' }, { label: 'Orange' }, { label: 'Yellow' }, { label: 'Green' }, { label: 'Blue' }];
const icons = [
  { icon: 'bb-down-arrow', class: '' },
  { icon: 'bb-down-right-arrow', class: '' },
  { icon: 'bb-right-arrow', class: '' },
  { icon: 'bb-up-right-arrow', class: '' },
  { icon: 'bb-down-arrow', class: styles.revert }
];
const indexes = [0, 1, 2, 3, 4];

const renderUi = (index, labelList, iconName = 'bb-score-circle', iconClass = '') => {
  if(index) index = index - 1;
  return (
    <span className={styles.dis_label}>
      <Icon
        name={indexes.includes(index) ? iconName : ''}
        className={`${styles.mr5} theme-kde-accountability2-fill-${index}
        ${iconClass}`}
      />
      <Label className={styles.label} value={indexes.includes(index) ? labelList[index].label : "No Data available"} position="inline" />
    </span>
  )
}

const CELL_MODIFIERS = {
  circleMetIcon: ({}, {}, index) => {
    return renderUi(index, listRisk);
  },
  circleColorMetIcon: ({}, {}, index) => {
    return renderUi(index, listColor);
  },
  arrowMetIcon: ({}, {}, index) => {
    let lindex = (index) ? index - 1 : 0;
    return renderUi(index, listStatus, indexes.includes(lindex) ? icons[lindex].icon : '', indexes.includes(lindex) ? icons[lindex].class : '');
  }
};

const TextCell = (props) => {
  const { item, textAlign, emptyState, alternateTextProp, className, cellModifier } = props;
  const text = alternateTextProp ? props[alternateTextProp] : props.text;
  const supportedModifier = CELL_MODIFIERS[cellModifier];

  if (Array.isArray(text)) {
    return (
      <div>
        {map(text, (t, i) => {
          const body = template(t, item, false) || emptyState;
          return (
            <div key={i} style={{ textAlign }}>
              <Markdown body={body} inline />
            </div>
          );
        })}
      </div>
    );
  }

  const body = template(text, item, false) || emptyState;

  return (
    <div style={{ textAlign }} className={className}>
      {supportedModifier && <>{supportedModifier(item, props, body)} </>}
    </div>
  );
};

TextCell.propTypes = {
  item: PropTypes.object,
  text: PropTypes.string,
  textAlign: PropTypes.oneOf(['right', 'left', 'center']),
  emptyState: PropTypes.string,
  alternateTextProp: PropTypes.string,
  className: PropTypes.string,
  cellModifier: PropTypes.string
};

TextCell.defaultProps = {
  text: '',
  textAlign: 'left',
  emptyState: ''
};

export default TextCell;
