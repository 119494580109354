import { KENTUCKY_ORG_ID } from '../../constants';

// Return direct child organizations for current organization
// Which orgs are we interested in?  For state, show districts.  For district show schools.
// eslint-disable-next-line import/prefer-default-export
export function getChildOrganizations(currentOrganization) {
  if (currentOrganization.id && currentOrganization.children) {
    const orgTypeFilter = currentOrganization.id === KENTUCKY_ORG_ID ? 'District' : 'School';
    return currentOrganization.children.filter(childOrg => childOrg.entity_type === orgTypeFilter);
  }

  return [];
}
