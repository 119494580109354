import React from 'react';
import cx from 'classnames';

import Icon from 'cui/lib/components/Icon';
import Divider from 'cui/lib/components/Divider';
import Popover from 'cui/lib/components/Popover';

import styles from './index.module.scss';

function getPopoverElement(title, items) {
  const emptyItems = items.filter(item => item.y === undefined);
  const itemWithValues = items.filter(item => item.y !== undefined);

  return [
    [
      <Popover.Title key="title">School Year { title }</Popover.Title>,
      <Popover.Body key="body">
        <ul className="cui-list legend">
          {itemWithValues.map(item => (
            <li className="legend-item">
              <span className="cui-scoreDot cui-list-icon">
                <span className="cui-scoreDot-icon">
                  <Icon
                    name="bb-score-circle"
                    className={`theme-state-goals-fill-${item.colorIndex}`}
                  />
                </span>
              </span>
              <span className="cui-list-text">
                <div className="legend-label">
                  {item.line.name}
                </div>
                <div>
                  {item.label}
                </div>
              </span>
            </li>
          ))}
          {Boolean(emptyItems.length) && [
            <li className="legend-item">
              <Divider className="cui-margin-none" />
            </li>,
            <li className="legend-item">
              <span className="cui-list-text">
                <span className="legend-label">
                  Organizations with insufficient (*) or unavailable data for the year and student group selected.
                </span>
              </span>
            </li>
          ]}
          {emptyItems.map(item => (
            <li className={cx('legend-item', styles.emptyLegendItem)}>
              <span className="cui-list-text">
                <div className="legend-label">
                  {item.line.name}
                </div>
                <div>
                  {item.label}
                </div>
              </span>
            </li>
          ))}
        </ul>
      </Popover.Body>
    ]
  ];
}

const popoverRender = (item) => {
  if (Array.isArray(item)) {
    return getPopoverElement(item[0].x, item);
  }

  return getPopoverElement(item.x, [item]);
};

export default popoverRender;
