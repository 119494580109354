import flat, { unflatten } from 'flat';

const filterObjectKeysByMetadataEntity = (obj, entity) => {
  const flattenedArrays = Object.entries(flat(obj));
  const regex = /entities.\d$/;

  const pathsWithFailedEntities = Object.entries(
    flattenedArrays
      .filter(([path]) => regex.exec(path) !== null)
      .reduce((accumulator, [path, value]) => {
        const key = path.replace(regex, 'entities');
        return {
          ...accumulator,
          [key]: accumulator[key] ? [
            ...accumulator[key],
            value
          ] : [value]
        };
      }, {})
  ).filter(([, entries]) => !entries.includes(entity)).map(([path]) => path.replace('.entities', '.'));

  // Return `null` if topmost level in the object is invalid;
  if (pathsWithFailedEntities.includes('entities')) return null;

  const filteredObj = unflatten(
    flattenedArrays
      .filter(([path]) => pathsWithFailedEntities
        .map(failedPath => path.substring(0, failedPath.length) === failedPath)
        .filter(a => !!a)
        .length === 0)
      .reduce((accumulator, [path, value]) => ({
        ...accumulator,
        [path]: value
      }), {})
  );

  return filteredObj;
};

export default filterObjectKeysByMetadataEntity;
