import React from 'react';
import merge from 'lodash/merge';
import get from 'lodash/get';
import cx from 'classnames';
import template from '../../helpers/template';

import KdeBarChart from '../KdeBarChart';
import styles from './index.module.scss';

const defaultChartOptions = {
  xAxis: {
    showGrid: false,
    showAxis: true,
    showLabel: true,
    position: 'start',
    labelAlignment: 'start'
  },
  yAxis: {
    offset: 80,
    showLabel: true,
    labelDirection: 'ttb',
    labelAlignment: 'center',
    showGrid: true,
    showAxis: true
  },
  valueLabel: true,
  high: 100,
  low: 0,
  animate: false,
  detailsPopoverPlacement: 'left'
};

function KdeSidewayBarChart(props) {
  const labelTemplate = get(props.config.options.chartOptions, 'xAxis.labelTemplate', '{{l}}');
  const chartOptions = merge({
    xAxis: {
      labelInterpolationFunc: l => (template(labelTemplate, { l }))
    }
  }, defaultChartOptions);

  return (
    <KdeBarChart
      {...props}
      horizontal
      defaultChartOptions={chartOptions}
      className={cx(`barChart-${chartOptions.yAxis.labelDirection}`, styles.chart)}
    />
  );
}

KdeSidewayBarChart.propTypes = KdeBarChart.commonProps;

export default KdeSidewayBarChart;
