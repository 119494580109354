import lodashTemplate from 'lodash/template';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import isEmpty from 'lodash/isEmpty';
import { valueToLocaleString, valueToPercentage } from './format';
import { computePercent } from '../mathHelpers';

const INTERPOLATE_REGEX = /{{([\s\S]+?)}}/g;

/**
 * Note: in order to work with `this` context, it must be a regular, non-arrow function
 * @example
 *   const obj = { foo: 'my_value' };
 *   const props = { value: 'my_value' };
 *   template.call(obj, "'{{this.foo}}' === '{{value}}'", props); // => true
 */
function template(attr = '{{value}}', props = { value: '' }, logError = true) {
  try {
    return lodashTemplate(attr,
      {
        interpolate: INTERPOLATE_REGEX,
        imports: { capitalize, get, isEmpty, valueToLocaleString, valueToPercentage, computePercent }
      }
    ).call(this, { value: '', ...props });
  } catch (error) {
    if (logError) {
      console.warn(error);
    }
  } // eslint-disable-line no-console
  return '';
}

export default template;
