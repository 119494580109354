import sortBy from 'lodash/sortBy';
import get from 'lodash/get';
import map from 'lodash/map';

/**
 * Extract keys of each array items
 {
   score: {
     programs: [
      {
        accounting: {
          school: { enrolled: 100 },
          grade_9: { enrolled: 120 },
          grade_10: { enrolled: 130 }
        },
        music: {
          school: { enrolled: 101 },
          grade_9: { enrolled: 121 },
          grade_10: { enrolled: 131 }
        }
      },
      {
        pe: {
          school: { enrolled: 102 },
          grade_9: { enrolled: 140 },
          grade_10: { enrolled: 135 }
        },
        art: {
          school: { enrolled: 100 },
          grade_9: { enrolled: 191 },
          grade_10: { enrolled: 101 }
        }
      }
    ]
   }
 }
 scoreKey = 'score.programs'
 nestedScoreKey = 'school'
 return sorted array:
 [
   { name: 'accounting', enrolled: 100 },
   { name: 'art', enrolled: 100 },
   { name: 'music', enrolled: 101 },
   { name: 'pe', enrolled: 102 }
 ]

**/

export default function adapter({ currentOrgScore, chartConfig }) {
  const scoreKey = get(chartConfig, 'scoreKey');
  const nestedScoreKey = get(chartConfig, 'nestedScoreKey');

  if (scoreKey && nestedScoreKey) {
    /* eslint-disable arrow-body-style */
    const scores = get(currentOrgScore, scoreKey, []).reduce((memo, score) => {
      return memo.concat(map(score, (value, key) => ({ name: key, ...value[nestedScoreKey] })));
    }, []);
    /* eslint-enable arrow-body-style */
    // sort by name field
    return sortBy(scores, ['name']);
  }

  return currentOrgScore;
}
