import React from 'react';
import PropTypes from 'prop-types';

import Field from 'cui/lib/components/Form/Field';
import List from 'cui/lib/components/List';

import PanelHeader from '../../ui/PanelHeader';

const Summary = ({ summary, headingLevel }) => (
  <Field label="">
    <PanelHeader
      label="More information organization summary"
      title="Summary"
      headingLevel={headingLevel}
      popoverContent={
        `"Summary" highlights the total student membership and grades served by the school, district, or state.
        On district dashboards, this section includes the total number of schools in the district. On the state dashboard,
        the total number of schools and districts in the state are reported.`
      }
    />

    <List className="cui-list_spread kde-list_blue">
      {summary.map(({ icon, label }, index) => <List.Item icon={icon} key={index}>{label}</List.Item>)}
    </List>
  </Field>
);

Summary.propTypes = {
  headingLevel: PropTypes.number,
  summary: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    icon: PropTypes.string
  })).isRequired
};

Summary.defaultProps = {
  headingLevel: 3
};

export default Summary;
