import castArray from 'lodash/castArray';
import every from 'lodash/every';
import get from 'lodash/get';

import { findNodeByNodePath } from './frameworkHelpers';
import { isPublicOrWithClarityUserPermissions } from './nodeHelpers';

// eslint-disable-next-line import/prefer-default-export
export const filterPublicOrWithPermissionsChart = (chart, userRole, framework) => {
  const dataNodePaths = castArray(get(chart, 'metadata.data_node_path'));
  const dataNodes = dataNodePaths.map(nodePath => findNodeByNodePath(framework, nodePath));

  return every(dataNodes, node => isPublicOrWithClarityUserPermissions(node, userRole));
};

export function handlePopoverPlacement(data, chartConfig) {
  if (data.length) {
    if (chartConfig.isFirstColumn) {
      data[0].detailsPopoverPlacement = 'right';
    }

    if (chartConfig.isLastColumn) {
      data[data.length - 1].detailsPopoverPlacement = 'left';
    }
  }

  return data;
}
