import React from 'react';
import PropTypes from 'prop-types';

import CuiField from 'cui/lib/components/Form/Field';

const popoverProps = { appendToBody: false };

const Item = ({ children, label, popoverContent, description }) => (
  <CuiField label={label} popoverContent={popoverContent} description={description} popoverProps={popoverProps}>
    { children }
  </CuiField>
);

Item.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
  popoverContent: PropTypes.string,
  description: PropTypes.string
};

export default Item;
