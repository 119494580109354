import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, groupedScores, organization, scoreKeyLabelMap, scoreKey, subScoreShapeKey, scoreShapeKey, directShape } = options;
  const shape = directShape || conditional(get(node, 'scoring_options.shape', []), {}, options);

  const data = map(groupedScores, score => (
    {
      data: sortBy(map(score.scores, subScore => (
        {
          percent: subScore.percent,
          colorIndex: get(shape, `${score[scoreShapeKey]}.scoreShape.${subScore[subScoreShapeKey]}.colorIndex`),
          index: get(shape, `${score[scoreShapeKey]}.scoreShape.${subScore[subScoreShapeKey]}.index`)
        }
      )), ['index']),
      label: shape[score[scoreShapeKey]].label,
      orgName: organization.name,
      title: scoreKeyLabelMap[scoreKey.toLowerCase()],
      detailItems: sortBy(map(score.scores, (detailSubScore) => {
        const subScoreShape = get(shape, `${score[scoreShapeKey]}.scoreShape.${detailSubScore[subScoreShapeKey]}`);
        return (
          {
            percent: Math.round(detailSubScore.percent),
            count: detailSubScore.student_count,
            colorIndex: get(subScoreShape, 'colorIndex'),
            index: get(subScoreShape, 'index'),
            label: get(subScoreShape, 'detailLabel'),
            showOverall: get(subScoreShape, 'showOverall', false),
            // TODO impliment `items` when scores are further broken down
            // ie: High-High, Medium-High, Low-High
            items: []
          }
        );
      }), '[index]')
    }
  ));

  return data;
};

export default adapter;
