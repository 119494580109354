import get from 'lodash/get';
import map from 'lodash/map';

import template from '../../../template';
import conditional from '../../../conditional';
import { filterZeroValues } from '../../../chartHelpers';

const adapter = (options) => {
  const { node, currentOrgScore } = options;
  const shape = conditional(node.scoring_options.shape, {}, options);
  const scoringOptions = get(node, 'scoring_options', {});
  const entities = get(options, shape.entities, []);

  const data = map(entities, (entity) => {
    let entityLabel = template(shape.template.label, entity);
    const trunc = shape.template.truncate || 0;
    if (trunc && entityLabel.length > trunc) entityLabel = `${entityLabel.substring(0, trunc)}...`;
    return {
      value: get(currentOrgScore.value, entity.id, 0),
      label: entityLabel,
      colorIndex: shape.template.colorIndex
    };
  });

  return (scoringOptions && scoringOptions.hideZeroValues) ? filterZeroValues(data) : data;
};

export default adapter;
