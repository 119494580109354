import React from 'react';
import get from 'lodash/get';
import { scoreInfoPropType } from '../../../helpers/collectorHelpers';
import EmptyWrapper from '../EmptyWrapper';
import sectionHoc from './sectionHoc';

export const StaffingSection = ({ score }) => (
  <EmptyWrapper scoreInfo={score}>
    {({ scoreInfo, notReported }) => {
      const metricOptions = get(scoreInfo.node, 'metadata.metric_options', []);
      return (
        <section className="section" aria-labelledby="staffing_section_aria_label">
          <h2 id="staffing_section_aria_label">Staffing</h2>
          {notReported || (
            <div className="grid two">
              {metricOptions.filter(({ key }) => get(scoreInfo.value, key) != null).map(({ key, label }) => (
                <div className="grid-cell" key={key}>
                  <h3 className="fieldLabel">{label}</h3>
                  <div className="field">
                    <span className="fieldValue">{get(scoreInfo.value, key)}</span>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
      );
    }}
  </EmptyWrapper>
);

StaffingSection.propTypes = {
  score: scoreInfoPropType
};

export default sectionHoc(StaffingSection);
