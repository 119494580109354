import { createSelector } from 'reselect';
import identity from 'lodash/identity';
import get from 'lodash/get';

const defaultFrameWork = {};

export const selectFramework = state => get(state, 'framework.kde', defaultFrameWork);

export const makeSelectFramework = () => createSelector(
  selectFramework,
  identity
);
