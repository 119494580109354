import React from 'react';
import merge from 'lodash/merge';
import get from 'lodash/get';
import cx from 'classnames';
import FlexBar from 'cui/lib/components/FlexBar';
import Legend from 'cui/lib/components/Legend';
import Label from 'cui/lib/components/Label';

import KdeBarChart from 'components/KdeBarChart';
import DetailsPopover from 'components/KdeBarChart/DetailsPopover';
import template from 'helpers/template';
import applyAdapters from 'helpers/adapterHelpers';
import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';
import styles from './index.module.scss';

const defaultChartOptions = {
  xAxis: {
    showGrid: false,
    showAxis: true,
    showLabel: true,
    position: 'start',
    labelAlignment: 'start'
  },
  yAxis: {
    offset: 80,
    showLabel: true,
    labelDirection: 'ttb',
    labelAlignment: 'center',
    showGrid: true,
    showAxis: true
  },
  valueLabel: true,
  high: 100,
  low: 0,
  animate: false,
  detailsPopoverPlacement: 'left'
};

const getLegendItems = data => {
  if (!Array.isArray(data)) return [];

  const legendLabels = ['Dark:', 'Medium:', 'Light:', 'Striped:'];
  const sampleBars = data[0]?.value ?? [];

  return sampleBars?.map(
    ({ colorIndex, label: description }, itemIndex, values) => ({
      colorIndex,
      description,
      label: values.length > 1 ? legendLabels[itemIndex] : ''
    })
  );
};

function KdeStackedCompareChart(props) {
  const {
    config,
    scores,
    allScores,
    node,
    additionalChartAdapters,
    viewingDropdownFilter,
    organization,
    className,
    ...restProps
  } = props;

  const score = scores[0]?.value ?? {};

  const optionsFromConfig = get(config, 'options.chartOptions', {});
  const labelTemplate = get(
    props.config.options.chartOptions,
    'xAxis.labelTemplate',
    '{{l}}'
  );

  const baseConfig = {
    horizontal: true,
    arrange: 'stack',
    xAxis: {
      labelInterpolationFunc: l => template(labelTemplate, { l })
    },
    detailsPopoverRenderer(currentItem) {
      return (
        <DetailsPopover
          name={get(optionsFromConfig, 'detailsPopover.name')}
          config={config}
          data={get(currentItem, 'data')}
        />
      );
    },
    viewDetailsAriaLabel: d => d.ariaLabel
  };

  const chartOptions = merge(baseConfig, defaultChartOptions, optionsFromConfig);

  const adapterName = get(config, 'adapter');
  const charts = applyAdapters(adapterName, score, {
    ...restProps,
    currentOrganization: organization,
    chartConfig: config,
    additionalChartAdapters,
    node,
    config,
    viewingDropdownFilter,
    allScores
  });

  return charts.map(({ data, chartTitle }, idx) => (
    <div key={chartTitle} className={styles.container}>
      <FlexBar className="cui-margin-bottom-medium">
        <FlexBar.Item>
          {idx === 0 && (
            <Legend
              data={getLegendItems(data)}
              kind="inline"
              theme="kde-multi"
              className="cui-text_small"
            />
          )}
        </FlexBar.Item>
        {chartTitle && (
          <FlexBar.Item>
            <Label value={chartTitle} />
          </FlexBar.Item>
        )}
      </FlexBar>

      <BarChartWithLegend
        theme={get(config, 'options.theme', 'kde-multi')}
        chartProps={{
          data,
          className: cx(styles.chart, className),
          name: chartTitle,
          chartOptions
        }}
      />
    </div>
  ));
}

KdeStackedCompareChart.propTypes = KdeBarChart.commonProps;

export default KdeStackedCompareChart;
