import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import currentOrgYearScores from '../../helpers/scoresHelpers';

export const currentScore = ({ scores, node, organization, chartConfig }) => {
  // Use the first score for now
  // TODO when we deal with multi year data, we should implement a way to select score for year
  // There will be year filtering possibly.
  const { ignoreCurrentOrgScore = false } = chartConfig;

  if (isEmpty(scores)) {
    return undefined;
  }

  const score = ignoreCurrentOrgScore ? scores[0] : currentOrgYearScores(scores, organization, node);

  return get(score, 'value');
};

export const currentChartConfig = (node, tab) => {
  const chartType = get(tab, 'chartType');
  if (!chartType) {
    return {};
  }

  return get(node, ['metadata', 'charts', chartType], {});
};

export const findItemIndexByFilterKey = (items, value) => {
  if (!isEmpty(items)) {
    const index = items.findIndex(item => get(item, 'filterKey') === value);
    return index > -1 ? index : 0;
  }
  return 0;
};

export default currentScore;
