import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isObject from 'lodash/isObject';
import when from '../../helpers/when';
import getName from '../../helpers/nameHelper';

/**
 * It takes an nested object and return data array that is ready for bar chart to use
 * Example:
 * score = {
 *   iep: {
 *     kentucky: { count: 1 },
 *     nation: { count: 3 }
 *   },
 *   lep: {
 *     kentucky: { count: 2 },
 *     nation: { count: 4 }
 *   }
 * }
 * firstOrders = ['lep', 'iep']
 * secondOrders = ['kentucky', 'nation']
 * nestedScoreKey = 'count'
 * labelMap = { iep: 'Student with disabilities', lep: 'English Learner' }
 * It requires chart config options firstOrders and secondOrders to specify ordering and nesting for the data array.
 * Optional nestedScoreKey, it is used to extract the value when nested value is an object
 * Optional labelMap to lookup the label for firstOrders keys
 * It returns:
 * [
 *   {
 *     label: 'English Learner',
 *     value: [
 *       { value: 2, colorIndex: 0, key: 'kentucky' },
 *       { value: 4, colorIndex: 1, key: 'nation' }
 *     ]
 *   },
 *   {
 *     label: 'Student with disabilities',
 *     value: [
 *       { value: 1, colorIndex: 0, key: 'kentucky' },
 *       { value: 3, colorIndex: 1, key: 'nation' }
 *     ]
 *   }
 *]
 **/

function getValue(score, path, fixedLength) {
  const value = get(score, path, 0);
  return value === '*' ? Number(0).toFixed(fixedLength) : parseFloat(value).toFixed(fixedLength);
}

function getTopLabel(score) {
  if (score.suppressed) return '*%';
  if (score.notAvailable) return 'N/A';

  return `${score.value}%`;
}

function scorePath(score, dataPath, nestedScoreKey) {
  let path = dataPath;
  if (isObject(score) && nestedScoreKey) {
    path = `${dataPath}.${nestedScoreKey}`;
  }
  return path;
}

// Handle groups with one item only
function singleItemGroups(allGroups, score, nestedScoreKey, labelMap) {
  return allGroups.map((group, i) => ({
    ...get(score, group, {}),
    label: getName(labelMap, group, group),
    colorIndex: i,
    value: nestedScoreKey ? get(score, [group, nestedScoreKey]) : 0
  }));
}

export default function adapter(currentOrgScore, { chartConfig }) {
  let chartData = currentOrgScore;
  const firstOrders = get(chartConfig, 'firstOrders', []);
  const secondOrders = get(chartConfig, 'secondOrders', []);
  const labelMap = get(chartConfig, 'labelMap');

  if (!isEmpty(currentOrgScore)) {
    const nestedScoreKey = get(chartConfig, 'nestedScoreKey');
    const filterBy = get(chartConfig, 'options.filterBy');

    if (!isEmpty(firstOrders)) {
      if (isEmpty(secondOrders)) {
        return singleItemGroups(firstOrders, currentOrgScore, nestedScoreKey, labelMap);
      }

      const showTopLabels = get(chartConfig, 'options.showTopLabels');
      const allGroups = firstOrders.filter(group => (filterBy ? when(filterBy, { value: get(currentOrgScore, group), isEmpty }) : true));
      chartData = allGroups.map(group => ({
        label: getName(labelMap, group, group),
        value: secondOrders.map((bar, i) => {
          const path = scorePath(currentOrgScore, `${group}.${bar}`, nestedScoreKey);
          const d = {
            ...get(currentOrgScore, `${group}.${bar}`, {}),
            label: getName(labelMap, bar, bar),
            value: getValue(currentOrgScore, path, get(chartConfig, 'options.fixedLength', 1)),
            colorIndex: i,
            key: bar,
            notAvailable: get(currentOrgScore, path) === 'N/A',
            suppressed: get(currentOrgScore, path) === '*'
          };
          if (showTopLabels) {
            d.topLabel = getTopLabel(d);
          }
          return d;
        })
      }));
    }
  }

  // When showLegend is true, return chartData and legendData as well
  // Currently use secondOrders keys as labels, we may need to implement label
  // lookup similar to the logics implemented for firstOrders
  if (get(chartConfig, 'options.showLegend')) {
    return {
      chartData,
      legendData: secondOrders.map(bar => ({ label: getName(labelMap, bar, bar) }))
    };
  }

  return chartData;
}
