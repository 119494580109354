/* eslint-disable no-unused-vars */
/* eslint-disable no-eval */

import find from 'lodash/find';
import omit from 'lodash/omit';

import template from './template';

// this will add a when: 'true' if there is no when. as a rule, no when should be considered to always render
const addDefaultWhen = element => (
  element.map((obj) => {
    if (obj.when === undefined) {
      obj.when = 'true';
    }
    return obj;
  })
);

const conditional = (element, fallback, props) => {
  /*
   * Conditionally selects items from a list using the "when" property
   */
  if (Array.isArray(element)) {
    addDefaultWhen(element);
    const newElement = find(element, o => eval(template(o.when, props))) || {};
    return omit(newElement, ['when']);
  }
  return element || fallback;
};

export default conditional;
