const FILTERS_OPTIONS = {
  all_grades: {
    text: 'All Students',
    gradeIndex: -1
  },
  all_levels: {
    text: 'All Students'
  },
  total_enrolled_students: {
    text: 'All Students'
  },
  all_demographics: {
    text: 'All Students'
  },
  gen: {
    text: 'Gender'
  },
  gen_male: {
    text: 'Male'
  },
  gen_female: {
    text: 'Female'
  },
  eth: {
    text: 'Ethnicity (all students)'
  },
  eth_black: {
    text: 'African American'
  },
  eth_alaskan: {
    text: 'American Indian / Alaska Native '
  },
  eth_asian: {
    text: 'Asian'
  },
  eth_hispanic: {
    text: 'Hispanic / Latino'
  },
  eth_pacific: {
    text: 'Native Hawaiian / Pacific Islander '
  },
  eth_multi: {
    text: 'Two or More Races'
  },
  eth_white: {
    text: 'White'
  },
  consolidated_student_group_all: {
    text: 'Consolidated Student Group (all students)'
  },
  consolidated_student_group: {
    text: 'Consolidated Student Group'
  },
  frl_all: {
    text: 'Economically Disadvantaged (all students)'
  },
  frl: {
    text: 'Economically Disadvantaged'
  },
  non_frl: {
    text: 'Non-Economically Disadvantaged'
  },
  iep_all: {
    text: 'Students with Disabilities (all students)'
  },
  iep: {
    text: 'Students with Disabilities (IEP)'
  },
  non_iep: {
    text: 'Students without IEP'
  },
  lep_all: {
    text: 'English Learner (all students)'
  },
  lep: {
    text: 'English Learner'
  },
  non_lep: {
    text: 'Non-English Learner'
  },
  lep_monitored: {
    text: 'English Learner including Monitored'
  },
  non_lep_monitored: {
    text: 'Non-English Learner or Monitored'
  },
  foster_all: {
    text: 'Foster Care (all students)'
  },
  foster: {
    text: 'Foster Care'
  },
  non_foster: {
    text: 'Non Foster Care'
  },
  gifted_all: {
    text: 'Gifted and Talented (all students)'
  },
  gifted: {
    text: 'Gifted and Talented'
  },
  homeless_all: {
    text: 'Homeless (all students)'
  },
  homeless: {
    text: 'Homeless'
  },
  non_homeless: {
    text: 'Not Homeless'
  },
  migrant_all: {
    text: 'Migrant (all students)'
  },
  migrant: {
    text: 'Migrant'
  },
  non_migrant: {
    text: 'Non Migrant'
  },
  military_all: {
    text: 'Military Dependent (all students)'
  },
  military: {
    text: 'Military Dependent'
  },
  non_military: {
    text: 'Non Military Dependent'
  },
  iep_alternate_assessment_all: {
    text: 'Alternate Assessment (all students)'
  },
  iep_alternate_assessment: {
    text: 'Alternate Assessment'
  },
  preschool: {
    text: 'Preschool',
    gradeIndex: 0
  },
  k: {
    text: 'Kindergarten',
    gradeIndex: 1
  },
  kindergarten: {
    text: 'Kindergarten',
    gradeIndex: 1
  },
  grade_1: {
    text: 'Grade 1',
    gradeIndex: 2
  },
  grade_2: {
    text: 'Grade 2',
    gradeIndex: 3
  },
  grade_3: {
    text: 'Grade 3',
    gradeIndex: 4
  },
  grade_4: {
    text: 'Grade 4',
    gradeIndex: 5
  },
  grade_5: {
    text: 'Grade 5',
    gradeIndex: 6
  },
  grade_6: {
    text: 'Grade 6',
    gradeIndex: 7
  },
  grade_7: {
    text: 'Grade 7',
    gradeIndex: 8
  },
  grade_8: {
    text: 'Grade 8',
    gradeIndex: 9
  },
  grade_9: {
    text: 'Grade 9',
    gradeIndex: 10
  },
  grade_10: {
    text: 'Grade 10',
    gradeIndex: 11
  },
  grade_11: {
    text: 'Grade 11',
    gradeIndex: 12
  },
  grade_12: {
    text: 'Grade 12',
    gradeIndex: 13
  },
  grade_13: {
    text: 'Grade 13',
    gradeIndex: 14
  },
  grade_14: {
    text: 'Grade 14',
    gradeIndex: 15
  },
  elementary_school: {
    text: 'Elementary School'
  },
  middle_school: {
    text: 'Middle School'
  },
  high_school: {
    text: 'High School'
  },
  reading: {
    text: 'Reading'
  },
  math: {
    text: 'Math'
  },
  Kentucky: {
    text: 'Kentucky'
  },
  Nation: {
    text: 'Nation'
  },
  'All Students': {
    text: 'All Students'
  },
  'State Funded': {
    text: 'State Funded'
  },
  'Head Start': {
    text: 'Head Start'
  },
  'Child Care': {
    text: 'Child Care'
  },
  Home: {
    text: 'Home'
  },
  sb_english: {
    text: 'English'
  },
  sb_reading: {
    text: 'Reading'
  },
  sb_mathematics: {
    text: 'Mathematics'
  },
  sb_science: {
    text: 'Science'
  },
  sb_composite: {
    text: 'Composite ACT Score'
  },
  Other: {
    text: 'Other'
  },
  Unknown: {
    text: 'Unknown'
  }
};

export default FILTERS_OPTIONS;
