import orderBy from 'lodash/orderBy';
import get from 'lodash/get';

// sort array according to the sortKey property
// @param currentOrgScore array of object
// @return sorted array of object
function valueToSort(score, key, numeric) {
  if (!numeric) {
    // return value if it is not numeric
    return score[key];
  }
  // parse value as float and use -1 for NAN for sorting
  return !isNaN(score[key]) ? parseFloat(score[key]) : -1;
}

// sort array according to the exact orders given in the orders param
// @param scores {array} array of scores to sort
// @param orders {array} array of values that specified the orders
// @param key {string} key of score object whose value is used in sort
// @return sorted array of scores with not-found items append at the end
export function sortByExactOrders(scores, orders, key) {
  const orderMap = {};
  const itemNotFoundIndex = orders.length + 1;
  // Assign each order item an integer value so that we can sort by the numeric value
  for (let i = 0; i < orders.length; i += 1) {
    orderMap[orders[i]] = (i + 1);
  }
  // Sort scores by looking up the numeric value assigned in the previous step
  return orderBy(scores, [score => (orderMap[score[key]] || itemNotFoundIndex)], ['asc']);
}

export default function adapter(currentOrgScore, { chartConfig }) {
  if (Array.isArray(currentOrgScore)) {
    const sortOptions = get(chartConfig, 'options.sort') || get(chartConfig, 'options.sortBy');
    if (sortOptions) {
      // directions must be either asc or desc
      const { key, numeric, valuesToExclude, prependExcludedValues, orders, direction = 'desc' } = sortOptions;
      let scoresToSort = currentOrgScore;
      let scoresToExcludeFromSort = [];

      if (valuesToExclude) {
        scoresToSort = currentOrgScore.filter(score => !valuesToExclude.includes(score[key]));
        scoresToExcludeFromSort = currentOrgScore.filter(score => valuesToExclude.includes(score[key]));
      }
      let sortedScores;
      // sort the scores according to the configuration
      if (orders) {
        sortedScores = sortByExactOrders(scoresToSort, orders, key);
      } else {
        sortedScores = orderBy(scoresToSort, [score => valueToSort(score, key, numeric)], [direction]);
      }
      // prepend or append the excluded scores from sorting based on prependExcludedValues option
      return prependExcludedValues ? scoresToExcludeFromSort.concat(sortedScores) : sortedScores.concat(scoresToExcludeFromSort);
    }
  }

  return currentOrgScore;
}
