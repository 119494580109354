import { FORMATTERS } from '../../helpers/formatter';
import numberFormatter from '../../helpers/numberFormatter';

const valueFormatters = {
  appendPercentSign: (value) => {
    if (!value) {
      throw new Error('Please provide the `value` attribute');
    }

    if (!parseFloat(value)) {
      return value;
    }

    return `${value}%`;
  },
  moneyFormatter: (value) => {
    if (!value) {
      throw new Error('Please provide the `value` attribute');
    }

    if (!parseFloat(value)) {
      return value;
    }

    const sign = `${value}`.substr(0, 1) === '-' ? '-' : '';

    return `${sign}${FORMATTERS.money(Math.abs(value), { divider: 1, withZero: true, fraction: 0 })}`;
  },
  addThousandsSeparator: (value) => {
    if (!value) {
      throw new Error('Please provide the `value` attribute');
    }

    if (!parseFloat(value)) {
      return value;
    }

    return numberFormatter(value, 'number', 0);
  },
  addCurrency: (value) => {
    if (!value) {
      throw new Error('Please provide the `value` attribute');
    }

    return numberFormatter(value, 'number', 2);
  }
};

export default valueFormatters;
