import get from 'lodash/get';
import reduce from 'lodash/reduce';

export default function adapter({ currentOrgScore, chartConfig, viewingDropdownFilter }) {
  const dimensionKey = get(chartConfig, 'dimensionKey', 'elementary_school');
  const result = reduce(
    get(currentOrgScore, `dimensions.${dimensionKey}.dimensions`, {}),
    (acc, rowScore, rowKey) => {
      const questionTypeFilter = viewingDropdownFilter[0];
      return reduce(
        get(rowScore, 'score', {}),
        (source2Acc, quest) => {
          if (!questionTypeFilter || questionTypeFilter === quest.question_type) {
            const qindex = quest.question ? Number(quest.question) : 0;
            if (!source2Acc[qindex]) {
              source2Acc[qindex] = {};
            }
            let agreeStronglyAgree = '';
            if (quest.agree && quest.strongly_agree) {
              if (quest.agree === '*' ||  quest.strongly_agree === '*') {
                agreeStronglyAgree = '*';
              } else {
                agreeStronglyAgree = Number(quest.agree) + Number(quest.strongly_agree);
                agreeStronglyAgree = agreeStronglyAgree.toLocaleString() + (agreeStronglyAgree % 1 > 0 ? '' : '.0');
              }
            }
            source2Acc[qindex][rowKey] = { ...quest, agree_strongly_agree: agreeStronglyAgree };
          }
          return source2Acc;
        }, acc);
    },
    {}
  );

  return result;
}
