import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';

class Toggle extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.onClick(this.props.itemIndex);
  }

  render() {
    const { title, isOpen, label } = this.props;

    return (
      <button type="button" aria-expanded={isOpen} className="cui-accordionForm-step-header" onClick={this.onClick} aria-label={label}>
        <div className="cui-accordionForm-step-number">
          <Icon name={isOpen ? 'kde-collapse' : 'kde-expand'} className="cui-accordionForm-step-icon" />
        </div>
        <div className="cui-accordionForm-step-title">{title}</div>
      </button>
    );
  }
}

Toggle.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  itemIndex: PropTypes.number,
  isOpen: PropTypes.bool
};

export default Toggle;
