import cn from 'classnames';
import FlexBar from 'cui/lib/components/FlexBar';
import Icon from 'cui/lib/components/Icon';
import EmptyState from 'cui/lib/components/EmptyState';
import Progress from 'cui/lib/components/Progress';
import PropTypes from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import styles from './index.module.scss';
import kdeProgressTextNumber from '../../chartDataAdapters/kdeProgressTextNumber';
import { valueToLocaleString } from '../../helpers/template/format';

const KdeProgressTextNumber = (props) => {
  const {
    node,
    scores,
    config,
    viewingDropdownFilter
  } = props;

  const values = kdeProgressTextNumber(scores, node, config, viewingDropdownFilter) || [];

  if (!values.length) return <EmptyState kind="text" title="Data not reported" />;

  return (
    <div className={styles.wrapper}>
      <div>
        {
          values.filter((value) => !isEmpty(value.scores)).map((value, index) => {
            const item = value.item;
            const labelClassNames = cn({
              [styles.label]: true,
              [styles.labelWithoutIcon]: !item.icon
            });

            return (
              <div className={styles.container} key={`${value.input}-${index}`}>
                <FlexBar justify="left">
                  {
                    item.icon && (
                      <FlexBar.Item>
                        <div className={styles.icon}>
                          <Icon name={item.icon} size="xLarge" />
                        </div>
                      </FlexBar.Item>
                    )
                  }
                  <FlexBar.Item grow>
                    <div className={labelClassNames}>
                      {item.label}
                    </div>
                  </FlexBar.Item>
                  <FlexBar.Item className={styles.progressBar}>
                    <Progress data={[value.scores.fed_part_rate]} theme="rainbow" />
                  </FlexBar.Item>
                  <FlexBar.Item className={styles.numbers}>
                    <div className={styles.input}>
                      {value.input}
                    </div>
                    {value.scores.fed_part_pop &&
                    <div className={styles.description}>
                      {valueToLocaleString(value.scores.fed_part_pop)} of Students Tested
                    </div>}
                  </FlexBar.Item>
                </FlexBar>
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

KdeProgressTextNumber.propTypes = {
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  viewingDropdownFilter: PropTypes.object,
  allScores: PropTypes.arrayOf(
    PropTypes.object
  ),
  config: PropTypes.object,
  additionalChartAdapters: PropTypes.object,
  featureFlags: PropTypes.object
};

KdeProgressTextNumber.defaultProps = {
  node: {},
  organization: {},
  scores: [],
  viewingDropdownFilter: {},
  config: {}
};

export default KdeProgressTextNumber;
