import reduce from 'lodash/reduce';

export default function adapter({ currentOrgScore }) {
  const { dimensions } = currentOrgScore;

  const formattedData = reduce(dimensions, (res, dimension, dimensionKey) => {
    const { score = {} } = dimension;

    res[dimensionKey] = {
      ...score,
      attainment: (score.suppressed === 'Y' && !score.attainment) ? '*' : score.attainment,
      rate: (score.suppressed === 'Y' && !score.rate) ? '*' : score.rate
    };

    return res;
  }, {});

  return formattedData;
}
