import get from 'lodash/get';

export const getDomainBySlug = (frameworkTree = { items: [] }, slug) => {
  if (!slug) console.warn('Please provide domain slug');

  return frameworkTree.items.find(domain => domain.slug === slug);
};

export function getYearSpecificCopy(framework, key) {
  return get(framework, `metadata.copies.${key}`);
}
