import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const WithDomain = ({ children, domain, slug }) => {
  if (!domain) return null;

  const isDomainActive = domain === slug;

  if (typeof children === 'function') {
    return children({ isActive: isDomainActive });
  }

  if (!isDomainActive) return null;

  return children;
};

WithDomain.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func
  ]).isRequired,
  domain: PropTypes.string,
  slug: PropTypes.string.isRequired
};

WithDomain.defaultProps = {
  domain: ''
};

const mapStateToProps = state => ({
  domain: get(state, 'framework.currentNode.address.domain.slug', '')
});

export default connect(mapStateToProps)(WithDomain);
