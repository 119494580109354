/* eslint-disable import/no-unresolved */
import React from 'react';
import PropTypes from 'prop-types';

import GaugeRatingPanel from '../../components/GaugeRatingPanel';
import PublicOrWithPermissions from '../../components/PublicOrWithPermissions';
import { ACCOUNTABILITY_DOMAIN_NODE_PATH } from '../../constants';

const OrgPanels2022Top = ({
  featureFlags,
  federalClassification,
  framework,
  schoolStat,
  organization,
  schoolYear,
  isPreviewSite,
  showViewAccountability,
  isLoading
}) => (
  <div>
    <PublicOrWithPermissions nodePath={ACCOUNTABILITY_DOMAIN_NODE_PATH}>
      <GaugeRatingPanel
        featureFlags={featureFlags}
        federalClassification={federalClassification}
        framework={framework}
        schoolStat={schoolStat(organization)}
        organization={organization}
        year={schoolYear}
        headingLevel={3}
        showViewAccountability={showViewAccountability}
        isPreviewSite={isPreviewSite}
        isLoading={isLoading}
      />
    </PublicOrWithPermissions>
  </div>
);

OrgPanels2022Top.propTypes = {
  address: PropTypes.object,
  classification: PropTypes.string,
  boardMembers: PropTypes.arrayOf(PropTypes.string),
  federalClassification: PropTypes.object,
  organization: PropTypes.object,
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  orgType: PropTypes.string,
  orgName: PropTypes.string,
  summary: PropTypes.arrayOf(PropTypes.object),
  schoolYear: PropTypes.number,
  title1: PropTypes.string,
  websiteUrl: PropTypes.string,
  schoolStat: PropTypes.func,
  isPreviewSite: PropTypes.bool,
  showViewAccountability: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default OrgPanels2022Top;
