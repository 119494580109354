import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import List from 'cui/lib/components/List';
import Modal from 'cui/lib/components/Modal';
import Icon from 'cui/lib/components/Icon';
import Button from 'cui/lib/components/Button';

import { ORG_TYPES } from '../../constants';

import styles from './OrgReportCardPage.module.scss';

class BoardMembers extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      boardMembersVisible: false
    };
  }

  toggleModal = () =>
    this.setState(state => ({ boardMembersVisible: !state.boardMembersVisible }));

  handleKeyPress = e => {
    e.preventDefault();
    if (e.keyCode === 9) {
      const closeButtons = document.getElementsByClassName('cui-modal-close');
      if (closeButtons && closeButtons.length > 0) {
        const closeButton = closeButtons[0];
        if (closeButton) {
          closeButton.focus();
        }
      }
      window.removeEventListener('keydown', this.handleKeyPress);
    }
  };

  render() {
    const { boardMembers, orgType } = this.props;

    if (isEmpty(boardMembers)) {
      return null;
    }

    // INFO: Wacky way of checking whether `boardMembers` is an actual list of people or generic info for a school
    const shouldDisplayGernericBoardMembersInfo =
      boardMembers[0].indexOf('Pursuant to KRS') > -1;
    const TITLES = {
      [ORG_TYPES.SCHOOL]: 'View School-Based Decision Making Council Members',
      [ORG_TYPES.DISTRICT]: 'View District Board Members',
      [ORG_TYPES.STATE]: 'View State Board Members'
    };

    if (shouldDisplayGernericBoardMembersInfo) {
      return <div className="cui-field">{boardMembers.join('')}</div>;
    }

    const title = TITLES[orgType];

    const sortByLastName = array =>
      array.sort((a, b) => {
        const getLastName = name => name.substr(name.lastIndexOf(' ') + 1);
        return getLastName(a) > getLastName(b) ? 1 : -1;
      });

    if (this.state.boardMembersVisible) {
      window.addEventListener('keydown', this.handleKeyPress);
    } else {
      window.removeEventListener('keydown', this.handleKeyPress);
    }

    return (
      <div className="cui-margin-top-none">
        <li className="u-list-item">
          <Icon
            className="u-color-kde-text-tint-2 cui-list-icon"
            name="kde-board-members"
          />
          <span className="cui-list-text">
            <Button className={styles.button} onClick={this.toggleModal}>
              {title}
            </Button>
          </span>
        </li>
        <Modal isOpen={this.state.boardMembersVisible} onHide={this.toggleModal}>
          <Modal.Header title={title} />
          <Modal.Body>
            <List>
              {sortByLastName(boardMembers).map((member, index) => (
                <List.Item key={index}>{member}</List.Item>
              ))}
            </List>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

BoardMembers.propTypes = {
  boardMembers: PropTypes.arrayOf(PropTypes.string),
  orgType: PropTypes.string
};

export default BoardMembers;
