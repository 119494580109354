import { SET_CURRENT_MATCH } from '../../common_actions';

import paramsParser from '../../helpers/paramsParser';

const DEFAULT_STATE = {};

function paramsReducer(state = DEFAULT_STATE, action = {}) {
  if (action.type === SET_CURRENT_MATCH) {
    const search = action.search || '';
    return paramsParser(search, action.match.params);
  } else {
    return state;
  }
}

export default paramsReducer;
