import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Icon from 'cui/lib/components/Icon';
import Button from 'cui/lib/components/Button';
import cx from 'classnames';
import OrgDataProvider from '../../helpers/OrgDataProvider';
import OrgPanels from './OrgPanels';
import DashboardBanner from '../../components/DashboardBanner';
import FamilyInfoBanner from '../../components/FamilyInfoBanner';
import styles from '../OrgReportCardPage.module.scss';
import OrgReportCard from '../../components/OrgReportCard';
import { filterLeavesAndTheirAncestors } from '../../helpers/collectorHelpers';
import orgCanAccessCollectorDataPoint from '../../helpers/orgCanAccessCollectorDataPoint';
import { isApprovalSite } from '../../helpers/approvalSite';
import useDistrictMessageQuery from '../../store/backend/queries/district_messages';
import SuperIntendentMessageModal from './SuperIntendentMessageModal';

const OrgReportCardContent = ({
  framework,
  currentOrganization,
  scoresData,
  featureFlags,
  isPreviewSite,
  schoolStat,
  year
}) => {
  const showResearchData = !(year === 2021 && isApprovalSite());
  const districtId = currentOrganization?.parentDistrict?.id ?? currentOrganization?.id;
  const { data: districtMessage } = useDistrictMessageQuery(districtId, year);
  const [isSuperIntendentMessageModalOpen, setSuperIntendentMessageModalOpen] = useState(
    false
  );
  const researchLink = framework?.metadata?.researchLink ?? `/datasets?year=${year}`;
  const isProficiencyDashboardAvailable = year < 2021;
  const isDistrictMessageAvailable = year >= 2021 && Boolean(districtMessage);
  const isBannersSmall = isProficiencyDashboardAvailable || isDistrictMessageAvailable;
  return (
    <div>
      <section
        className="section section_tight"
        aria-labelledby="summary_section_aria_label"
      >
        <span id="summary_section_aria_label" hidden>
          Summary
        </span>
        <div className="container-constrained">
          <OrgDataProvider
            scores={scoresData.scores}
            isScoresLoading={scoresData.isLoading}
            schoolStat={schoolStat}
          >
            {/* Scores are considered only for schools*/}
            {orgData => <OrgPanels {...orgData} />}
          </OrgDataProvider>
          <div className="flexRow">
            {isDistrictMessageAvailable && (
              <DashboardBanner additionalClass={styles.dashboardBannerLarge}>
                <div className="cui-media cui-media_center">
                  <div className="cui-media-image">
                    <Icon
                      className="u-color-kde-text-tint-2"
                      size="xLarge"
                      name="ig-comforttalking"
                    />
                  </div>
                  <div className="cui-media-content">
                    <h3>
                      <Button
                        kind="link"
                        onClick={() => setSuperIntendentMessageModalOpen(true)}
                      >
                        A Message from the Superintendent
                      </Button>
                    </h3>
                  </div>
                </div>
              </DashboardBanner>
            )}
            <DashboardBanner
              additionalClass={cx({
                'dashboardBanner--small': isDistrictMessageAvailable,
                [styles.dashboardBannerLarge]: !isDistrictMessageAvailable
              })}
            >
              <FamilyInfoBanner />
            </DashboardBanner>
            {isProficiencyDashboardAvailable && (
              <DashboardBanner additionalClass="dashboardBanner--small">
                <div className="cui-media cui-media_center">
                  <div className="cui-media-image">
                    <Icon
                      className="u-color-kde-text-tint-2"
                      size="xLarge"
                      name="kde-115"
                    />
                  </div>
                  <div className="cui-media-content">
                    <h3>
                      <a
                        href="https://kystats.ky.gov/Reports/Tableau/APP_2018"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Proficiency Dashboard <Icon name="bb-arrow-box-top" />
                      </a>
                    </h3>
                    <div className="cui-margin-top-xxSmall">
                      View and compare subject-level measures
                    </div>
                  </div>
                </div>
              </DashboardBanner>
            )}
            {showResearchData && (
              <DashboardBanner
                additionalClass={cx({
                  'dashboardBanner--small': isBannersSmall,
                  [styles.dashboardBannerLarge]: !isBannersSmall
                })}
              >
                <div className="cui-media cui-media_center">
                  <div className="cui-media-image">
                    <Icon
                      className="u-color-kde-text-tint-2"
                      size="xLarge"
                      name="kde-academic-readiness"
                    />
                  </div>
                  <div className="cui-media-content">
                    <h3>
                      <a href={researchLink} target="_blank" rel="noopener noreferrer">
                        Research Data <Icon name="bb-arrow-box-top" />
                      </a>
                    </h3>
                    <div className="cui-margin-top-xxSmall">
                      Data sets for analyzing Kentucky education data
                    </div>
                  </div>
                </div>
              </DashboardBanner>
            )}
          </div>
        </div>
      </section>
      <section
        className="section section_tight"
        aria-labelledby="key_topics_section_aria_label"
      >
        <span id="key_topics_section_aria_label" hidden>
          Key topics
        </span>
        <OrgReportCard
          awaitProps={['framework', 'currentOrganization']}
          featureFlags={featureFlags}
          isPreviewSite={isPreviewSite}
          framework={filterLeavesAndTheirAncestors(framework, node =>
            orgCanAccessCollectorDataPoint(node, currentOrganization)
          )}
          propMap={{
            currentOrganization: 'module.currentOrganization',
            isLoading: 'loading.isLoading',
            organizations: 'module.organizations',
            userRole: 'module.userContext.currentModulePlatformAccessLevel'
          }}
          scores={scoresData.scores}
        />
      </section>
      <SuperIntendentMessageModal
        isOpen={isSuperIntendentMessageModalOpen}
        onHide={() => setSuperIntendentMessageModalOpen(false)}
        districtMessage={districtMessage}
      />
    </div>
  );
};

OrgReportCardContent.propTypes = {
  framework: PropTypes.object,
  currentOrganization: PropTypes.object,
  featureFlags: PropTypes.object,
  isPreviewSite: PropTypes.bool,
  scoresData: PropTypes.shape({
    isLoading: PropTypes.bool,
    scores: PropTypes.arrayOf(PropTypes.object)
  }),
  schoolStat: PropTypes.func,
  year: PropTypes.number
};

export default OrgReportCardContent;
