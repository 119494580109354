import isObject from 'lodash/isObject';

const addYearToSearch = (search, year) => {
  const searchParams = new URLSearchParams(search);

  if (!searchParams.get('year') && year) searchParams.set('year', year);

  return `?${searchParams.toString()}`;
};

const addYearToUrl = (to, year) => {
  if (!year) return to;

  if (isObject(to)) {
    return {
      ...to,
      search: addYearToSearch(to.search, year)
    };
  }

  const [path, search] = to.split('?');
  return `${path}${addYearToSearch(search, year)}`;
};

export default addYearToUrl;
