import React from 'react';
import PropTypes from 'prop-types';
import FlexBar from 'cui/lib/components/FlexBar';
import cx from 'classnames';

import SuccessIndicatorDataSets from './SuccessIndicatorDataSets';
import SuccessIndicatorLink from './SuccessIndicatorLink';
import DownloadAllButton from './DownloadAllButton';
import DataSetsLastUpdatedAt from './DataSetsLastUpdatedAt';
import styles from './index.module.scss';

const DataSetsList = ({ dataSets, lastUpdatedAtUrl }) => {
  if (!dataSets) return null;

  return (
    <div className="cui-margin-top-xLarge">
      <DataSetsLastUpdatedAt lastUpdatedAtUrl={lastUpdatedAtUrl} />
      <DownloadAllButton dataSets={dataSets} />
      {dataSets.map(domain => (
        <div key={domain.name}>
          <FlexBar justify="left">
            <img className={styles.headerIcon} src={domain.icon} alt="" role="presentation" />
            <h2 className={cx([styles.header, 'cui-text_large'])}>{domain.name}</h2>
          </FlexBar>

          <div className="cui-margin-bottom-xLarge">
            {domain.items.map(successIndicator => (
              successIndicator.type === 'LINK'
                ? <SuccessIndicatorLink key={successIndicator.name} successIndicatorData={successIndicator} />
                : <SuccessIndicatorDataSets key={successIndicator.name} successIndicatorData={successIndicator} />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

DataSetsList.propTypes = {
  lastUpdatedAtUrl: PropTypes.string,
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['GROUP', 'ITEM']).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      items: PropTypes.array
    })
  )
};

export default DataSetsList;
