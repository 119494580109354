export default `
  query {
    organization(id: :id) {
      id
      name
      entity_type
      dist_number
      sch_number
      sch_cd
      sch_type
      low_grade
      high_grade
      address
      city
      state
      zipcode
      latitude
      longitude
      years
      website_url
      parentDistrict: parent_district {
        id
        name
        entity_type
        dist_number
        sch_number
        sch_cd
        children(years: [:year]) {
          id
          name
          entity_type
        }
      }
      organization_data(years: [:year]) {
        year
        title1_status
        contact_name
        email
        phone
        fax
        board_members
        portal_url
        fed_class
        district_count
        school_count
        school_level
        student_count
        star_rating
        gap_reduction
        gap_demo
        tsi_csi
        tsi_csi_reason
        no_star_rating_assessment
      }
    }
  }
`;
