import isEmpty from 'lodash/isEmpty';

// Extracts scores under `dimensions` key
//
// Example:
// score = {
//   score: {
//     value: "00",
//     total_count: "99"
//   }
//   dimensions: {
//     high_school: {
//       dimensions: {
//         frl: {
//           dimensions: {},
//           score: {
//             value: "11",
//             total_count: "22"
//           }
//         },
//         non_frl: {
//           dimensions: {},
//           score: {
//             value: "33",
//             total_count: "44"
//           }
//         }
//       },
//       score: {
//         value: "123",
//         total_count: "456"
//       }
//     },
//     middle_school: {
//       dimensions: {
//         frl: {
//           dimensions: {},
//           score: {
//             value: "55",
//             total_count: "66"
//           }
//         },
//         non_frl: {
//           dimensions: {},
//           score: {
//             value: "77",
//             total_count: "88"
//           }
//         }
//       },
//       score: {
//         value: "456",
//         total_count: "789"
//       }
//     },
//     elementary_school: {
//       dimensions: {
//         frl: {
//           dimensions: {},
//           score: undefined
//         },
//         non_frl: {
//           dimensions: {},
//           score: undefined
//         }
//       },
//       score: {
//         value: "123",
//         total_count: "456"
//       }
//     }
//   }
// }
//
// const chartConfig = {
//   options: {
//     totalKey: 'all_demographics'
//   }
// }
//
// it returns: {
//   all_demographics: {
//     value: "00",
//     total_count: "99"
//   },
//   high_school: {
//     frl: {
//       value: "11",
//       total_count: "22"
//     },
//     non_frl: {
//       value: "33",
//       total_count: "44"
//     }
//   },
//   middle_school: {
//     frl: {
//       value: "55",
//       total_count: "66"
//     },
//     non_frl: {
//       value: "77",
//       total_count: "88"
//     }
//   }
// }

export default function extractDimensions(score, { chartConfig }) {
  const dimensions = score.dimensions;

  if (isEmpty(dimensions)) {
    return score.score;
  }

  if (chartConfig.totalKey) {
    const totalScore = score.score;
    dimensions[chartConfig.totalKey] = { score: totalScore };
  }

  return Object.keys(dimensions).reduce((accumulator, dimensionSlug) => {
    const subDimensions = extractDimensions(dimensions[dimensionSlug], { chartConfig });

    return isEmpty(subDimensions)
      ? accumulator
      : { ...accumulator, [dimensionSlug]: subDimensions };
  }, {});
}
