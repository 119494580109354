import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { removeScript, addScript } from '../../helpers/domHelpers';

// Yandex use https only
const TRANSLATION_WIDGET_SRC = 'https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=en&widgetTheme=light&autoMode=false';

class YandexLanguageToggle extends PureComponent {
  componentDidMount() {
    // Add a script element to load Yandex translation widget
    addScript(TRANSLATION_WIDGET_SRC);
  }

  componentWillUnmount() {
    // Cleanup DOM elements created by translate widget
    removeScript(TRANSLATION_WIDGET_SRC);
  }

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div id="ytWidget" />
      </div>
    );
  }
}

YandexLanguageToggle.propTypes = {
  className: PropTypes.string
};

export default YandexLanguageToggle;
