import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import get from 'lodash/get';
import stubTrue from 'lodash/stubTrue';

import collectVariables from './collectVariables';

export default function allDataPoints(framework, predicate = stubTrue) {
  return flattenDeep(
    map(collectVariables(framework, predicate), variableNode => (
      filter(get(variableNode, 'variable.items'), predicate)
    ))
  );
}
