import React from 'react';
import PropTypes from 'prop-types';
import Heading from 'cui/lib/components/Heading';
import List from 'cui/lib/components/List';
import Columns from 'cui/lib/components/Columns';

export default function MultiValueList(props) {
  const { title, items } = props;

  return (
    <section>
      { title && <Heading size="small" level={3} className="cui-label cui-margin-top-large">{title}</Heading> }
      <Columns>
        <List className="cui-list_bottomDivider">
          {
            items.map(item => (
              <List.Item className="cui-list-item" key={item}>
                {item}
              </List.Item>
            ))
          }
        </List>
      </Columns>
    </section>
  );
}

MultiValueList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.string)
};

MultiValueList.defaultProps = {
  items: []
};
