import React from 'react';
import PropTypes from 'prop-types';

import BarChart from 'cui/lib/components/BarChart';
import BlockTitle from 'cui/lib/components/BlockTitle';
import Legend from 'cui/lib/components/Legend';
import Panel from 'cui/lib/components/Panel';

import DetailsPopover from 'components/KdeBarChart/DetailsPopover';
import FILTERS_OPTIONS from 'helpers/data_dropdown_helper/filtersOptions';

import styles from './index.module.scss';

const PopoverLine = ({ value, description }) => (
  <div>
    <span className="legend-value">{value}</span>

    <span className="legend-label">
      <em className="u-indent">{description}</em>
    </span>
  </div>
);

const processValue = (value, suppressed) => (suppressed === 'Y' ? '*' : `${value}%`);

const stackChartOptionsWithPopover = {
  xAxis: {
    showGrid: true,
    showAxis: true,
    showLabel: true,
    position: 'start',
    labelCount: 6,
    labelAlignment: 'start',
    title: '% of Students'
  },
  yAxis: {
    offset: 80,
    showLabel: true,
    labelDirection: 'ttb',
    labelAlignment: 'center',
    showGrid: true,
    showAxis: true
  },
  barThickness: 55,
  valueLabel: true,
  horizontal: true,
  arrange: 'stack',
  high: 100,
  low: 0,
  animate: false,
  detailsPopoverPlacement: 'left',
  detailsPopoverRenderer(currentItem) {
    const description =
      currentItem.data.suppressed === 'N' &&
      `of ${
        currentItem.data.responses
          ? (+currentItem.data.responses).toLocaleString()
          : ''
      } student responses`;
    const data = {
      value: currentItem.data.value.map(item => ({
        colorIndex: item.colorIndex,
        label: item.label,
        popoverItem: (
          <PopoverLine
            value={processValue(item.value, currentItem.data.suppressed)}
            description={description}
          />
        )
      }))
    };

    if (
      currentItem.data.suppressed === 'N' &&
      currentItem.data.removeResponsePercent !== true
    ) {
      data.value.push({
        label: 'Response Percent',
        popoverItem: (
          <PopoverLine
            value={`${currentItem.data.pct_resp}%`}
            description={description}
          />
        )
      });
    }

    return <DetailsPopover name="KdeDashboardBarChartPopover" config={{}} data={data} />;
  }
};

const labels = [
  { label: 'Strongly Disagree' },
  { label: 'Disagree' },
  { label: 'Agree' },
  { label: 'Strongly Agree' }
];

const buildData = (scores, includeDoesNotApply, removeResponsePercent) =>
  scores.map(score => {
    let value;
    if (score.suppressed === 'Y') {
      value = [{ label: 'Data is suppressed', topLabel: '*', value: '1', colorIndex: 5 }];
    } else {
      value = [
        { label: 'Strongly Disagree', value: score.strongly_disagree, colorIndex: 0 },
        { label: 'Disagree', value: score.disagree, colorIndex: 1 },
        { label: 'Agree', value: score.agree, colorIndex: 2 },
        { label: 'Strongly Agree', value: score.strongly_agree, colorIndex: 3 }
      ];

      if (includeDoesNotApply) {
        value.push({
          label: 'Does Not Apply to Me',
          value: score.does_not_apply_to_me,
          colorIndex: 4
        });
      }
    }

    return {
      value,
      label: FILTERS_OPTIONS[score.dimension].text,
      responses: score.responses,
      pct_resp: score.pct_resp,
      enrollment: score.enrollment,
      suppressed: score.suppressed,
      removeResponsePercent
    };
  });

const Question = props => {
  const { questionText, scores, includeDoesNotApply, removeResponsePercent } = props;
  const stackBarChartItemsWithPopover = buildData(
    scores,
    includeDoesNotApply,
    removeResponsePercent
  );

  let newLabels = [];
  if (includeDoesNotApply) {
    newLabels = [...labels, { label: 'Does Not Apply to Me' }];
  } else {
    newLabels = [...labels];
  }

  return (
    <Panel>
      <Panel.Header>
        <BlockTitle title={questionText} className={styles.title} />
      </Panel.Header>
      <Panel.Content>
        <Legend
          data={newLabels}
          theme="kde-multi"
          kind="inline"
          className={styles.legend}
        />
        <BarChart
          data={stackBarChartItemsWithPopover}
          chartOptions={stackChartOptionsWithPopover}
          theme="kde-multi"
          className={styles.chart}
        />
      </Panel.Content>
    </Panel>
  );
};

Question.propTypes = {
  questionText: PropTypes.string,
  scores: PropTypes.arrayOf(PropTypes.object)
};

export default Question;
