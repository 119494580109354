import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';

import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';

import currentOrgYearScores from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';

export const EntityBarChart = (props) => {
  const { config, scores, organization, chartType } = props;
  const options = config.options || {};
  const score = currentOrgYearScores(scores, organization);

  const dataOptions = {
    ...props,
    currentOrgScore: score
  };

  const data = chartDataAdapter(chartType, dataOptions);
  const max = dataMaxHelper[chartType](dataOptions);
  const count = Math.ceil(max / 25);
  const ceil = count * 25;

  const chartOptions = merge({
    xAxis: {
      showGrid: false,
      showLabel: true,
      showAxis: true,
      offset: 20,
      position: 'start',
      labelCount: count
    },
    yAxis: {
      offset: 170,
      labelCount: count,
      showLabel: true,
      showGrid: true,
      showAxis: true,
      labelDirection: 'ttb',
      labelAlignment: 'center'
    },
    valueLabel: true,
    horizontal: true,
    low: 0,
    high: ceil,
    padding: { right: 50 }
  }, options);

  return (
    <BarChartWithLegend
      chartProps={{
        data,
        chartOptions
      }}
    />
  );
};

EntityBarChart.propTypes = {
  config: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  organization: PropTypes.object,
  chartType: PropTypes.string
};

export default EntityBarChart;
