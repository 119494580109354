import React from 'react';
import { NavLink } from 'react-router-dom';

function StaticNav() {
  return (
    <ul className="staticNav">
      <li><NavLink activeClassName="is-active" to="/datasets">Data Sets</NavLink></li>
      <li><NavLink activeClassName="is-active" to="/glossary">Glossary</NavLink></li>
      <li><NavLink activeClassName="is-active" to="/other-data">Other Education Data</NavLink></li>
      <li><NavLink activeClassName="is-active" to="/privacy">Privacy</NavLink></li>
      <li><NavLink activeClassName="is-active" to="/disclaimer">Disclaimer</NavLink></li>
      <li><NavLink activeClassName="is-active" to="/help">Help</NavLink></li>
    </ul>
  );
}

export default StaticNav;
