import React from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';
import { format, addDays, addYears } from 'date-fns';

import LineChart from 'cui/lib/components/LineChart';
import Legend from 'cui/lib/components/Legend';
import Popover from 'cui/lib/components/Popover';

import EmptyState from 'cui/lib/components/EmptyState';

import DefaultPopover from './popovers/DefaultPopover';
import MultiSectionPopover from './popovers/MultSectionPopover';

import template from '../../helpers/template';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';
import formatFunc from '../../helpers/formatter';

// Factory for creating y axis label interpolation function.
function yAxisLabel(options, l) {
  const { map, formatter } = options;
  if (map) {
    const val = map[`${l}`] === undefined ? l : map[`${l}`];
    return template(val, { ...options, value: l, l });
  }
  if (formatter) {
    const { name, args } = formatter;
    return formatFunc(name, l, args);
  }
  return template(options.labelTemplate || '{{l}}', { l, value: l });
}

const popoverMap = {
  default: DefaultPopover,
  multiSection: MultiSectionPopover
};

const buildPopover = (type = 'default', props) => {
  const ThePopover = popoverMap[type];
  return <ThePopover {...props} />;
};

/* eslint-disable complexity */
export const TrendsChart = (props) => {
  const { config, scores, organization, chartType } = props;
  const { theme = 'rainbow', showLegend = true, adapter } = config;
  const options = config.options || {};

  const dataOptions = {
    ...props,
    scores,
    organization
  };

  const data = chartDataAdapter(adapter || chartType, dataOptions) || [];

  const maxHelper = dataMaxHelper[chartType] || dataMaxHelper['trendsChart']; // eslint-disable-line dot-notation
  const { min, max } = maxHelper({ ...dataOptions, data });
  const chartOptions = options && merge(options, {
    padding: { top: 15, bottom: 15, right: 60, left: 30 },
    yAxis: {
      showGrid: true,
      high: max,
      low: min || options.yAxis.low,
      labelInterpolationFunc: item => yAxisLabel(options.yAxis, item)
    },
    xAxis: {
      showAxis: true,
      showLabel: true,
      showGrid: true,
      labelInterpolationFunc(l, i, allDates) {
        // add two days because:
        // 1) timezone differences
        // 2) ticks aren't calculated w/ ticks(d3.time.months), but instead by adding
        //    an equal amount of time between the min date and the max date. this is
        //    a problem for a score set: [january, february, march], since february
        //    only has 28 days.
        //    note: two ticks is a special case, else [january, february] would still
        //          be a problem, unless three days were added.
        return chartType === 'trendsChart' ?
          format(addDays(new Date(l), 2), i === 0 || i === allDates.length - 1 ? 'MMM yyyy' : 'MMM') :
          format(new Date(l), 'yyyy-') + format(addYears(new Date(l), 1), 'yy');
      },
      labelCount: data[0] ? data[0].values.length : 0
    },
    usePopover: true,
    popoverRender: (item) => {
      let title;
      if (chartType === 'trendsChart') {
        title = format(new Date(item.x), 'MMM dd, yyyy');
      } else {
        title = format(new Date(item.x), 'yyyy-') + format(addYears(new Date(item.x), 1), 'yy');
      }
      return [
        (<Popover.Title key="title">{title}</Popover.Title>),
        (buildPopover(options.popoverType, { item, options }))
      ];
    }
  });

  const showLegendElement = showLegend && data;

  return (
    <div>
      {showLegendElement && <Legend
        data={data}
        kind="inline"
        theme={theme}
      />}
      {data && <LineChart
        data={data}
        chartOptions={chartOptions}
        theme={theme}
      />}
      {
        !data && <EmptyState kind="text" title="There is no data available." />
      }
    </div>
  );
};

TrendsChart.propTypes = {
  config: PropTypes.shape({
    options: PropTypes.object,
    theme: PropTypes.string,
    showLegend: PropTypes.bool
  }),
  organization: PropTypes.object,
  chartType: PropTypes.string.isRequired,
  chart: PropTypes.string.isRequired,
  scores: PropTypes.arrayOf(
    PropTypes.object
  )
};

TrendsChart.defaultProps = {
  scores: [],
  organization: {},
  config: {
    options: {},
    theme: 'default'
  }
};

export default TrendsChart;
