import flat from 'flat';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import isEmpty from 'lodash/isEmpty';
import pickBy from 'lodash/pickBy';
import isNumber from 'lodash/isNumber';
import reduce from 'lodash/reduce';
import merge from 'lodash/merge';

import { getSchoolLevels } from '../orgHelper';
import FILTERS_OPTIONS from './filtersOptions';

// Currently We only have group key mapping for grades and grade ranges
// TODO: please add more mapping as we discover the data shapes
// Here's a list of items we need to add:
// - Reading
// - Mathematics
// - State Funded
// - Head Start
// - Child Care
// - Home
// - Other
// - Unknown
// - School
// - District
// - State
// - Kentucky
// - Nation
// see this doc for more details: https://docs.google.com/document/d/14rXDO-2n8PtS9C7-e4p-b3yLgF-YQat7C9OC5_agz-8/edit

const DROPDOWN_CONFIG_FOR_ALL_ORG_TYPES = 'all';

const ALL_GRADES = {
  preschool: 0,
  kindergarten: 1,
  k: 1,
  '1st': 2,
  '2nd': 3,
  '3rd': 4,
  '4th': 5,
  '5th': 6,
  '6th': 7,
  '7th': 8,
  '8th': 9,
  '9th': 10,
  '10th': 11,
  '11th': 12,
  '12th': 13,
  '13th': 14,
  '14th': 15
};

// num is between 1 and 14
export function numToOrdinal(num) {
  if (num === '1') {
    return '1st';
  } else if (num === '2') {
    return '2nd';
  } else if (num === '3') {
    return '3rd';
  }

  return `${num}th`;
}

export function normalizeGrade(grade) {
  return /^\d+$/.test(grade) ? numToOrdinal(grade) : grade;
}

// Return viewing dropdown grades that falls within the low and high grade range of a school
export function gradesForSchool(organization, gradesToTest) {
  const schoolData = get(organization, 'organization_add_on.full_data', {});
  // KDE stores `low_grade` and `high_grade` in `organization`
  // The rest of modules store them in `organization_add_on.full_data`
  let lowGrade = organization.low_grade || schoolData.LOW_GRADE;
  let highGrade = organization.high_grade || schoolData.HIGH_GRADE;

  if (lowGrade && highGrade) {
    lowGrade = normalizeGrade(lowGrade);
    highGrade = normalizeGrade(highGrade);
    const low = get(ALL_GRADES, lowGrade.toLowerCase(), -1);
    const high = get(ALL_GRADES, highGrade.toLowerCase(), -1);

    if (low >= 0 && high >= 0) {
      return gradesToTest.filter((g) => {
        const index = get(FILTERS_OPTIONS, [g, 'gradeIndex']);
        if (index) {
          if (index >= 0) {
            return index >= low && index <= high;
          } else {
            return true;
          }
        } else {
          return false;
        }
      });
    }
  }

  return [];
}

// Return list of levels that has score for a school
export function levelsForSchool(score, levelsToTest) {
  // Data from svc-portaldata has dimensions key
  const isDataFromPortal = !!get(score, 'dimensions');
  const currentScore = isDataFromPortal ? get(score, 'dimensions') : score;

  return levelsToTest.filter(l => !isEmpty(get(currentScore, (isDataFromPortal ? [l, 'score'] : l))));
}

export function getDropdownConfigs(node, chartConfig) {
  // dataDropdown in each chart config take precedence over dataDropdown defined in scoring_options
  // dataDropdown defined in scoring_options serves as a common config for viewing dropdown while
  // we can overwrite it by specifying the config at chart level
  return flatten([get(chartConfig, 'dataDropdown') || get(node, 'scoring_options.dataDropdown', [])]);
}

// Return a list of dropdown options
export function getItems(config, node, organization, chartConfig, score) {
  const entityType = get(organization, 'entity_type', '').toLowerCase();
  let allItemsForOrg = [];
  let itemsForOrg = [];

  if (!isEmpty(config) && entityType) {
    // If itemsForOrg is an array, use it as dropdown options
    allItemsForOrg = get(config, entityType, []);
    itemsForOrg = [...allItemsForOrg];

    if (entityType === 'school') {
      // if organization is a school and itemsForOrg is an object with grades key
      // get grades that fall within the low and high grade range of the school only
      if (get(allItemsForOrg, 'grades')) {
        itemsForOrg = gradesForSchool(organization, allItemsForOrg.grades);
      }

      if (get(allItemsForOrg, 'levels')) {
        // Some data points always group under school levels for all org type.
        // For schools, we only want the relevent school levels.\
        const useSchoolLevelsFromOrg = get(chartConfig, 'useSchoolLevelsFromOrg', false);
        const schoolLevels = useSchoolLevelsFromOrg ? getSchoolLevels(organization) : levelsForSchool(score, allItemsForOrg.levels);
        itemsForOrg = [...schoolLevels, ...itemsForOrg];
      }
    }

    if (isEmpty(itemsForOrg)) {
      // Try the common dropdown config for all org types
      itemsForOrg = get(config, DROPDOWN_CONFIG_FOR_ALL_ORG_TYPES, []);
    }
  }

  return itemsForOrg;
}

// All keys that appear in `scores` object
const getScoreOptions = score => uniq(
  flatten(Object.keys(pickBy(flat(score), value => isNumber(value) || !isEmpty(value))).map(item => item.split('.')))
);

function getTextForKey(key, dropdownLabels) {
  return {
    text: get(dropdownLabels, [key, 'text'], typeof key === 'string' ? key : ''),
    filterKey: key
  };
}

function getTextForDropdownItems(items, dropdownLabels) {
  return items.map((item) => {
    if (typeof item === 'object' && item !== null) {
      if ('text' in item) return item;

      return {
        ...getTextForKey(item.key, dropdownLabels),
        subList: (item.subList || []).map(option => getTextForKey(option, dropdownLabels))
      };
    }

    return getTextForKey(item, dropdownLabels);
  });
}

function filterEmptyDropdownItems(items, score, chartConfig) {
  const extractToKey = get(chartConfig, 'extractTo.to');
  const scoreOptions = getScoreOptions(score);

  return items.reduce((res, item) => {
    if (item.subList) {
      const filledSubListItems = item.subList
        .filter(subitem => scoreOptions.indexOf(subitem.filterKey) > -1 || subitem.filterKey === extractToKey);

      if (filledSubListItems.length) {
        res.push({
          ...item,
          subList: filledSubListItems
        });
      }

      return res;
    }

    if (scoreOptions.indexOf(item.filterKey) > -1 || item.filterKey === extractToKey) {
      res.push(item);
    }

    return res;
  }, []);
}

// Return a list of dropdown options for the DropDown Component.
export default function dropdownItems(config, node, organization, chartConfig, score = {}) {
  const normalizedLabelMap = reduce(get(chartConfig, 'options.labelMap'), (memo, text, label) => {
    memo[label] = { text };
    return memo;
  }, {});
  const dropdownLabels = merge(FILTERS_OPTIONS, normalizedLabelMap);
  const dropdownItemsWithText = getTextForDropdownItems(getItems(config, node, organization, chartConfig, score), dropdownLabels);

  if (config?.sliceSelect) return dropdownItemsWithText;

  return filterEmptyDropdownItems(dropdownItemsWithText, score, chartConfig);
}
