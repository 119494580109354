import React from 'react';
import PropTypes from 'prop-types';

import { Icon, Heading } from 'cui';

import Link from '../../components/Link';
import styles from './AccountabilityHeader.module.scss';

const AccountabilityHeader = ({ title, dataLink, icon }) => (
  <header className={styles.header}>
    { icon &&
      <Icon name={icon} className={styles.icon} size="xLarge" />
    }
    <Heading level={1} size="large" className={styles.title}>
      {title}
    </Heading>
    { dataLink &&
      <Link to={dataLink} className={styles.link}>
        Explore Data <Icon name="bb-caret-right" />
      </Link>
    }
  </header>
);

AccountabilityHeader.propTypes = {
  title: PropTypes.string.isRequired,
  dataLink: PropTypes.string,
  icon: PropTypes.string
};

export default AccountabilityHeader;
