import get from 'lodash/get';
import { computeProps } from '../../helpers/adapterHelpers';
import valueToPrecision, { computePercent, computeRatio, valueToFixedPoint } from '../../helpers/mathHelpers';
import getName from '../../helpers/nameHelper';

// iterate the currentOrgScore array and compute properties given by options.computedProps
// For example:
// const currentOrgScores = [
//   { name: 'music', ready_count: 2, total_count: 10 },
//   { name: 'math', ready_count: 3, total_count: 20 }
// ]
//
// const chartConfig = {
//   options: {
//     computedProps: [
//       { key: 'label', value: '{{name}}' },
//       { key: 'percentage', value: '{{ready_count / total_count * 100}}' }
//     ]
//   }
// }
//
// return
// [
//   { name: 'music', ready_count: 2, total_count: 10, label: 'music', percentage: 20 },
//   { name: 'math', ready_count: 3, total_count: 20, label: 'math', percentage: 15 }
// ]
export default function adapter(currentOrgScores, { currentOrganization, chartConfig, orginalScore, viewingDropdownFilter }) {
  return computeProps(currentOrgScores, get(chartConfig, 'options.computedProps'), {
    computePercent,
    valueToPrecision,
    valueToFixedPoint,
    getName,
    orginalScore,
    computeRatio,
    currentOrganization,
    get,
    viewingDropdownFilter
  });
}
