/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';

import { Heading, List, Media, BarChart, Donut } from 'cui';

import ScoresBlock from '../ScoresBlock';
import Score from '../Score';
import WhyImportant from '../WhyImportant';
import ReportLegend from '../../../components/ReportLegend';
import ChartWithLegend from '../../../components/ChartWithLegend';
import Footer from '../Footer';

import styles from './Page2.module.scss';

const barChartProps = {
  xAxis: {
    showLabel: false,
    showGrid: true,
    showAxis: true
  },
  yAxis: {
    showGrid: false,
    showLabel: false,
    showAxis: false,
    labelCount: 5
  },
  height: 300
};

function barChartOptions(data) {
  if (Array.isArray(data)) {
    return {
      ...barChartProps,
      high: Math.max(...data.map(d => d.value))
    };
  }

  return barChartProps;
}

const Page2 = ({
  year,
  schoolName,
  address,
  phone,
  studentCount,
  grades,
  studentTeacherRatio,
  studentDemographics,
  attendanceRate,
  disadvantaged,
  noviceScores,
  totalSpending,
  showHeader,
  isLoading
}) => (
  <div className={styles.Page}>
    {
      showHeader && (
        <div className={styles.CoverHeader}>
          <Heading level={1} weight="medium" className={styles.CoverHeader_title}>
            {year} Kentucky School Report Card Summary
          </Heading>
          <Heading level={2} weight="bold" className={styles.CoverHeader_school}>
            {schoolName}
          </Heading>
          <div className={styles.Contact}>
            <address>{address} • {phone}</address>
          </div>
        </div>
      )
    }
    {
      !isLoading && <div className={styles.Page2Grid}>
        <div className={styles.Page2Grid_sidebar}>
          <Heading level={2} className={styles.Heading_sidebar1}>About This School</Heading>
          <Heading level={3} className={styles.Heading_sidebar3}>General Information</Heading>
          <List className={styles.infoList}>
            <List.Item
              icon="kde-student"
              className={styles.infoList_item}
            ><strong>{studentCount}</strong> Students</List.Item>
            <List.Item
              icon="kde-grades"
              className={styles.infoList_item}
            ><strong>{grades}</strong> Grades</List.Item>
            <List.Item
              icon="kde-00b"
              className={styles.infoList_item}
            ><strong>{studentTeacherRatio}</strong> Student
            to Teacher Ratio</List.Item>
          </List>

          <section className="cui-margin-bottom-xLarge">
            <Heading level={3} className={styles.Heading_sidebar3}>Student Demographics</Heading>
            <ChartWithLegend data={studentDemographics.data} legendPlacement="bottom">
              <Donut
                data={studentDemographics.data} theme="kde-multi" width={150} radiusRatio={0.4}
                data-slotName="chart"
              />
              <ReportLegend
                data={studentDemographics.legendData} theme="kde-multi"
                data-slotName="legend" inline
              />
            </ChartWithLegend>
          </section>

          <section className="cui-margin-bottom-xLarge">
            <Heading level={3} size="small" className={styles.Heading_sidebar3}>attendance
            rate</Heading>
            <ChartWithLegend data={attendanceRate}>
              <Donut
                theme="kde-duo" width={150} radiusRatio={0.4} data={attendanceRate}
                data-slotName="chart"
              />
              <strong
                className={styles.donutText}
                data-slotName="legend"
              >{attendanceRate.percent}%</strong>
            </ChartWithLegend>
          </section>

          <section>
            <Heading level={3} size="small" className={styles.Heading_sidebar3}>economically
            disadvantaged</Heading>
            <ChartWithLegend data={disadvantaged.data} chartAlignment="top">
              <Donut
                theme="kde-duo" width={150} radiusRatio={0.4} data={disadvantaged.data}
                data-slotName="chart"
              />
              <ReportLegend data={disadvantaged.legendData} theme="kde-duo" data-slotName="legend" />
            </ChartWithLegend>
          </section>

        </div>
        <div className={styles.Page2Grid_main}>
          <ScoresBlock title="Novice Level">
            {noviceScores.map((score, i) => (
              <Score
                key={i}
                title={score.title}
                score={score.score}
                average={score.average}
                percent={score.percent}
                compareLabel="prior year rate"
                upArrowIsPositive={false}
              />
            ))}
          </ScoresBlock>
          <WhyImportant showHeader>
            <p>Students performing at the novice level have minimal understanding
            of grade level content and are in a state of “academic emergency.”
            The novice rate represents students performing at the lowest level in
            your school. The goal is to reduce the percentage of novice
            performance each year.</p>
          </WhyImportant>

          <ScoresBlock
            className={styles.barChart} title="Total Spending Per Student"
            subtitle="State and local funds: Total spending divided by student membership. Data reflects the 2017-2018 school year."
          >
            <ChartWithLegend data={totalSpending.data}>
              <BarChart
                data={totalSpending.data} theme="kde-multi" data-slotName="chart"
                chartOptions={barChartOptions(totalSpending.data)}
              />
              <ReportLegend data={totalSpending.legendData} theme="kde-multi" data-slotName="legend" />
            </ChartWithLegend>
          </ScoresBlock>
          <WhyImportant>
            <p>Financial data is complex and varies from district to district.
            Conclusions should not be drawn solely from this data. Please
            engage local district leaders to better understand local district
            revenue and spending.</p>
          </WhyImportant>

          <Media align="center">
            <Media.Image
              src="https://bb-production-assets.s3.amazonaws.com/kde/src-pdf/infinite-campus.png"
              className={styles.page2_image}
            />
            <Media.Content className={styles.page2_imageText}>
              <p>For up-to-date, detailed information on your child&apos;s education, attendance,
              behavior, grades, and more - log onto the Campus Parent website at <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.infinitecampus.com/audience/parents-students"
              >www.infinitecampus.com/audience/parents-students</a> or
              download the Campus Student Mobile app from the Google Play Store or the Apple Store.
              </p>
            </Media.Content>
          </Media>
        </div>
      </div>
    }
    <Footer />
  </div>
);

Page2.propTypes = {
  showHeader: PropTypes.bool,
  isLoading: PropTypes.bool,
  year: PropTypes.number,
  schoolName: PropTypes.string,
  address: PropTypes.string,
  phone: PropTypes.string,
  studentCount: PropTypes.string,
  grades: PropTypes.string,
  studentTeacherRatio: PropTypes.string,
  studentDemographics: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      percent: PropTypes.number,
      colorIndex: PropTypes.number
    })),
    legendData: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string
    }))
  }),
  attendanceRate: PropTypes.shape({
    percent: PropTypes.number,
    colorIndex: PropTypes.number
  }),
  disadvantaged: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      percent: PropTypes.number,
      colorIndex: PropTypes.number
    })),
    legendData: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string
    }))
  }),
  noviceScores: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      score: PropTypes.number,
      average: PropTypes.number,
      percent: PropTypes.bool,
      compareLabel: PropTypes.string
    })
  ),
  totalSpending: PropTypes.shape({
    data: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number,
      description: PropTypes.string,
      colorIndex: PropTypes.number
    })),
    legendData: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      description: PropTypes.string,
      colorIndex: PropTypes.number
    }))
  })
};

Page2.defaultProps = {
  showHeader: true
};

export default Page2;
