import React from 'react';
import PropTypes from 'prop-types';

import StaticNav from '../StaticNav';

StaticPageContent.propTypes = {
  children: PropTypes.node
};

function StaticPageContent({ children }) {
  return (
    <section className="section section_tight">
      <div className="container-constrained">
        <div className="staticGrid">
          <div className="staticGrid-sidebar">
            <StaticNav />
          </div>
          <div className="staticGrid-main">
            { children }
          </div>
        </div>
      </div>
    </section>
  );
}

export default StaticPageContent;
