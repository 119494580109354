import CuiSticky from 'cui/lib/components/Sticky';

class Sticky extends CuiSticky {
  state = {
    sticky: false,
    elementStyle: {
      width: '100%',
      top: '0px'
    },
    childStyle: {
      marginLeft: '0px',
      width: '100%'
    }
  };

  componentDidMount() {
    super.componentDidMount();

    clearInterval(this.resizeInterval);
  }
}

export default Sticky;
