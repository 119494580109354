import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../components/Link';
import NavLink from '../../components/Link/NavLink';
import styles from './DomainNav.module.scss';

const DomainNav = ({ menu }) => (
  <ol className={styles.DomainNav}>
    {menu.length > 0 &&
      menu.map((section, i) => (
        <li key={i} className={`${styles.section} ${section.isOpen ? styles.isOpen : ''}`}>
          <Link to={section.linkTo} className={styles.heading}>
            <div>
              <img src={section.icon} role="presentation" className={styles.icon} />
            </div>
            <div>
              <div className={styles.domain}>{section.domain}</div>
            </div>
          </Link>
          {section.isOpen && (
            <div>
              <div className={styles.desc}>{section.desc}</div>
              <ol className={styles.itemList}>
                {section.items.length > 0 &&
                  section.items.map((item, k) => (
                    <li key={k} className={styles.item}>
                      <NavLink
                        to={item.linkTo}
                        className={item.active ? 'is-active' : ''}
                        ariaCurrent={item.active ? 'page' : 'false'}
                      >
                        {item.name}
                      </NavLink>
                    </li>
                  ))
                }
              </ol>
            </div>
          )}
        </li>
      )
      )}
  </ol>
);

DomainNav.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      domain: PropTypes.string,
      icon: PropTypes.string,
      linkTo: PropTypes.string,
      desc: PropTypes.string,
      isOpen: PropTypes.bool,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          linkTo: PropTypes.string.isRequired,
          active: PropTypes.bool.isRequired
        })
      )
    })
  )
};

export default DomainNav;
