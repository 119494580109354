import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router';
import { connect } from 'react-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Heading from 'cui/lib/components/Heading';
import Icon from 'cui/lib/components/Icon';
import MainHeader from 'cui/lib/components/MainHeader';

import addYearToUrl from '../Link/addYearToUrl';
import WithFeature from '../WithFeature';
import Breadcrumbs from '../Breadcrumbs';
import { KENTUCKY_ORG_ID, KDE_COLLECTOR_FG } from '../../constants';
import { canUserView } from '../../helpers/auth';
import { canSeeApprovalTab } from '../../helpers/approvalVisibilityHelpers';
import { basename } from '../../store';

import styles from './approvalHeader.module.scss';

const HELP_CENTER_URL = 'https://kyschoolreportcard.zendesk.com/hc/en-us';

class ApprovalHeader extends React.PureComponent {
  getTabs() {
    const { currentOrganization, featureFlags, userContext } = this.props;
    const tabs = [];

    if (canSeeApprovalTab(userContext, featureFlags)) {
      if (currentOrganization.entity_type === 'State') {
        tabs.push({ label: 'Approval', href: `${basename}/status/${currentOrganization.id}/children` });
      } else {
        tabs.push({ label: 'Approval', href: `${basename}/status/${currentOrganization.id}` });
      }
    }

    if (currentOrganization.entity_type === 'State') {
      tabs.push({ label: 'Downloads', href: `${basename}/status/${currentOrganization.id}/downloads` });
    } else if (currentOrganization.entity_type === 'District') {
      tabs.push({ label: 'Schools', href: `${basename}/status/${currentOrganization.id}/children` });
      tabs.push({ label: 'Downloads', href: `${basename}/status/${currentOrganization.id}/downloads` });
      tabs.push({ label: 'District Message', href: `${basename}/status/${currentOrganization.id}/district_message` });
    } else if (currentOrganization.entity_type === 'School') {
      tabs.push({ label: 'Downloads', href: `${basename}/status/${currentOrganization.id}/downloads` });

      if (get(featureFlags, KDE_COLLECTOR_FG)) {
        tabs.push({ label: 'Collection', href: `${basename}/collection/${currentOrganization.id}` });
      }
    }

    return tabs;
  }

  render() {
    const { currentOrganization, location, userContext, pageContext, canView, schoolYear } = this.props;
    const year = new URLSearchParams(location.search).get('year');

    // Check for if auth user view in Clarity context (i.e. has userContext)
    // FIXME: this can be extracted into a High-order component so we can wrap Pages with "auth" logic
    const authUserView = !isEmpty(userContext) && !isEmpty(pageContext);
    if (!authUserView || isEmpty(currentOrganization)) return <div />;
    // Redirect to user's default organization page
    if (authUserView && !canView) return <Redirect to={addYearToUrl(`/status/${get(userContext, 'currentOrganization.id')}`, year)} />;

    const tabs = this.getTabs();

    const tabAttrs = {
      // TODO: this breaks when a tab whose path is a substring of another tab's
      // path comes after that tab in the list. We should fix that.
      activeTab: Math.max(tabs.findIndex(tab => tab.href.includes(location.pathname)), 0)
    };

    const dashboardLink = `${basename}/organization/${currentOrganization.id}?year=${schoolYear}`;

    return (
      <div className={styles.approvalHeader}>
        <div className={styles.approvalHeaderContent}>
          <div className={styles.approvalHeaderTopBar}>
            <Breadcrumbs
              crumbs={[
                {
                  // State breadcrumb if viewing as a state user
                  when: '{{user.currentOrganization.id === KENTUCKY_ORG_ID}}',
                  caption: 'Kentucky',
                  linkRoute: addYearToUrl('/status/{{KENTUCKY_ORG_ID}}/children', year)
                },
                {
                  // District breadcrumb if viewing district or school as a state or district user
                  when: `{{
                    !!organization.parentDistrict &&
                    [KENTUCKY_ORG_ID, organization.parentDistrict.id].includes(user.currentOrganization.id)
                  }}`,
                  caption: '{{organization.parentDistrict.name}}',
                  linkRoute: addYearToUrl('/status/{{organization.parentDistrict.id}}/children', year)
                },
                {
                  // School breadcrumb if viewing school as a state, district, or school user
                  when: `{{
                    organization.id !== KENTUCKY_ORG_ID && (
                      user.currentOrganization.id === KENTUCKY_ORG_ID ||
                      (user.currentOrganization.id === organization.id && organization.entity_type === 'District') ||
                      (!!organization.parentDistrict && user.currentOrganization.id === organization.parentDistrict.id)
                    ) ||
                    (organization.entity_type === "School" && organization.id === user.currentOrganization.id)
                  }}`,
                  caption: '{{organization.name}}',
                  linkRoute: addYearToUrl('/status/{{organization.id}}', year)
                }
              ]}
              awaitProps={['organization', 'user']}
              KENTUCKY_ORG_ID={KENTUCKY_ORG_ID}
              organization={currentOrganization}
              user={userContext}
            />
            <div className="cui-margin-bottom-medium">
              <ul className="cui-list cui-list_inline" data-slotName="views">
                <li className="cui-margin-bottom-none">
                  <a className="cui-text_small cui-margin-bottom-none" href={HELP_CENTER_URL} target="_blank" rel="noopener noreferrer">Help</a>
                </li>
                <li className="cui-margin-bottom-none">
                  <a className="cui-text_small cui-margin-bottom-none" href="/users/sign_out">Log Out</a>
                </li>
              </ul>
            </div>
          </div>
          <MainHeader
            title={(
              <Heading
                size="xxLarge"
                level={1}
                className="cui-mainHeader-title"
              >
                Kentucky School Report Card Approval
              </Heading>
            )}
            subtitle={(
              <Heading
                size="large"
                level={2}
                className="cui-mainHeader-subtitle"
              >
                {currentOrganization?.name}
              </Heading>
            )}
            tabs={tabs}
            tabAttrs={tabAttrs}
          >
            <ul className="cui-list cui-list_inline" data-slotName="views">
              <WithFeature name="KdeApprovalDashboardLink">
                <li>
                  <a
                    href={dashboardLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Preview Dashboard
                    <Icon name="bb-arrow-box-top" className={styles.approvalHeaderDashboardLink} />
                  </a>
                </li>
              </WithFeature>
            </ul>
          </MainHeader>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  userContext: get(state, 'module.userContext'),
  pageContext: get(state, 'module.pageContext'),
  currentOrganization: ownProps?.currentOrganization ?? get(state, 'module.currentOrganization'),
  featureFlags: get(state, 'module.pageContext.featureFlags'),
  canView: canUserView(state),
  schoolYear: get(state, 'module.schoolYear')
});

ApprovalHeader.propTypes = {
  currentOrganization: PropTypes.shape({
    entity_type: PropTypes.oneOf([
      'School',
      'District',
      'State'
    ])
  }),
  userContext: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  pageContext: PropTypes.object,
  canView: PropTypes.bool,
  featureFlags: PropTypes.objectOf(PropTypes.bool),
  location: PropTypes.object,
  schoolYear: PropTypes.number
};

export default withRouter(connect(mapStateToProps)(ApprovalHeader));
