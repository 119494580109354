import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Panel from 'cui/lib/components/Panel';
import Table from 'cui/lib/components/Table';
import ScoreDot from 'cui/lib/components/ScoreDot';

import { KENTUCKY_ORG_ID } from '../../constants';
import approvalStatusByOrgDomains from '../../helpers/completionStatusHelpers/approval/approvalStatusByOrgDomains';
import collectionStatusByOrgDomains from '../../helpers/completionStatusHelpers/collection/collectionStatusByOrgDomains';
import TableFilter from '../../components/TableFilter';
import { getChildOrganizations } from './utils';
import { THEME_INDEXES } from './constants';
import styles from './BreakdownStatus.module.scss';
import { basename } from '../../store';

const statusTypeFilterOptions = [
  {
    label: 'Approval',
    value: 'approval'
  },
  {
    label: 'Collection',
    value: 'collection'
  }
];

const tableFilters = [
  {
    key: 'statusType',
    label: 'VIEW STATUS OF',
    items: statusTypeFilterOptions
  }
];

const supportedDomains = [
  {
    nodePath: 'kde.school_safety',
    featureFlagKeys: {
      approval: 'KdeApprovalSchoolSafety',
      collection: 'KdeCollectionSchoolSafety'
    }
  },
  {
    nodePath: 'kde.school_overview',
    featureFlagKeys: {
      approval: 'KdeApprovalSchoolOverview',
      collection: 'KdeCollectionSchoolOverview'
    }
  },
  {
    nodePath: 'kde.educational_opportunity',
    featureFlagKeys: {
      approval: 'KdeApprovalEducationalOpportunity',
      collection: 'KdeCollectionEducationalOpportunity'
    }
  },
  {
    nodePath: 'kde.transition_readiness',
    featureFlagKeys: {
      approval: 'KdeApprovalTransitionReadiness',
      collection: 'KdeCollectionTransitionReadiness'
    }
  },
  {
    nodePath: 'kde.academic_performance',
    featureFlagKeys: {
      approval: 'KdeApprovalAcademicPerformance',
      collection: 'KdeCollectionAcademicPerformance'
    }
  },
  {
    nodePath: 'kde.school_accountability',
    featureFlagKeys: {
      approval: 'KdeApprovalSchoolAccountability',
      collection: 'KdeCollectionSchoolAccountability'
    }
  },
  {
    nodePath: 'kde.financial_transparency',
    featureFlagKeys: {
      approval: 'KdeApprovalFinancialTransparency',
      collection: 'KdeCollectionFinancialTransparency'
    }
  }
];

const NOT_APPLICABLE = 'Not Applicable';

const STATUS_LABEL_MAP_APPROVAL = {
  [NOT_APPLICABLE]: NOT_APPLICABLE,
  'Not Started': 'Unreviewed',
  Started: 'Flagged',
  Done: 'Approved'
};

const STATUS_LABEL_MAP = {
  [NOT_APPLICABLE]: NOT_APPLICABLE,
  'Not Started': 'Not Started',
  Started: 'In Progress',
  Done: 'Complete'
};

const renderOrgRow = (domains, selectedStatusType) => (row, i) => (
  <tr key={i}>
    <td>{row.name}</td>
    {domains.map(domain => {
      const status = row[domain.node_path];
      const isNotApplicable = status === NOT_APPLICABLE;

      const statusLabel =
        selectedStatusType === 'collection'
          ? STATUS_LABEL_MAP[status]
          : STATUS_LABEL_MAP_APPROVAL[status];

      return (
        <td className={cx({ [styles.notApplicableCell]: isNotApplicable })} key={domain.node_path}>
          {!isNotApplicable && (
            <ScoreDot theme="kdeBreakdownStatus" value={THEME_INDEXES[status]} />
          )}{' '}
          {statusLabel}
        </td>
      );
    })}
  </tr>
);

export const BreakdownStatus = ({
  currentOrganization,
  featureFlags = {},
  framework,
  scores = [],
  sectionStatuses
}) => {
  const [selectedStatusType, setSelectedStatusType] = useState(statusTypeFilterOptions[0].value);
  const [searchQuery, setSearchQuery] = useState('');

  const domains = useMemo(() => supportedDomains.reduce((res, {
    nodePath,
    featureFlagKeys
  }) => {
    if (!featureFlags[featureFlagKeys[selectedStatusType]]) return res;

    const domain = framework?.items?.find((item) => item.node_path === nodePath);
    // eslint-disable-next-line camelcase
    if (domain) {
      res.push({
        name: domain.name,
        node_path: domain.node_path
      });
    }
    return res;
  }, []),
  [framework?.items, selectedStatusType, featureFlags]
  );

  const handleFilterChange = useCallback(({ query, filters }) => {
    if (query !== undefined) setSearchQuery(query);
    if (filters !== undefined) setSelectedStatusType(filters?.statusType);
  }, [setSearchQuery, setSelectedStatusType]);

  // Which orgs are we interested in?  For state, show districts.  For district show schools.
  const orgTypeFilter = currentOrganization.id === KENTUCKY_ORG_ID ? 'District' : 'School';
  const childOrgs = getChildOrganizations(currentOrganization);
  const orgDataByStatus = selectedStatusType === 'collection'
    ? collectionStatusByOrgDomains(currentOrganization, framework, scores, domains)
    : approvalStatusByOrgDomains(childOrgs, framework, sectionStatuses, domains);

  const searchRegex = searchQuery ? new RegExp(searchQuery, 'i') : '';
  const renderableOrgs = childOrgs
    .filter(org => org.name?.search(searchRegex) !== -1)
    .map(org => (
      {
        id: org.id,
        name: org.name,
        href: `${basename}/status/${org.id}`,
        linkableText: `${org.name} Approval Dashboard`,
        ...domains.reduce((res, domain) => ({
          ...res,
          [domain.node_path]: orgDataByStatus?.[org.id]?.[domain.node_path]?.status
        }), {})
      }
    ))
    .sort((a, b) => a.name.localeCompare(b.name));

  const tableFilterValues = useMemo(() => ({
    filters: {
      statusType: selectedStatusType
    }
  }), [selectedStatusType]);

  return (
    <div role="main">
      <Panel>
        <Panel.Header>
          <TableFilter
            total={{
              label: `Total ${orgTypeFilter}s`,
              value: renderableOrgs.length
            }}
            values={tableFilterValues}
            filters={tableFilters}
            onFilterChange={handleFilterChange}
          />
        </Panel.Header>
        <Panel.Content className={styles.panelContent}>
          <Table
            linkable
            className={styles.table}
            initialSort={{ property: 'name', direction: Table.ASC }}
            data={renderableOrgs}
            renderRow={renderOrgRow(domains, selectedStatusType)}
          >
            <Table.ColumnHeader property="name" sortable>
              Name
            </Table.ColumnHeader>
            {domains.map(domain => (
              <Table.ColumnHeader
                key={domain.node_path}
                property={domain.node_path}
                sortable
              >
                {domain.name}
              </Table.ColumnHeader>
            ))}
          </Table>
        </Panel.Content>
      </Panel>
    </div>
  );
};

BreakdownStatus.propTypes = {
  currentOrganization: PropTypes.object,
  framework: PropTypes.object,
  featureFlags: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  sectionStatuses: PropTypes.arrayOf(PropTypes.object)
};

export default BreakdownStatus;
