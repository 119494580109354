import React from 'react';
import PropTypes from 'prop-types';

import renderMarkdownContent from '../../helpers/markdownHelpers';

export const Markdown = ({ body, className, customClass, inline }) => {
  if (inline) {
    return (
      <span
        className={className || customClass}
        dangerouslySetInnerHTML={renderMarkdownContent(body, { inline })}
      />
    );
  }

  return (
    <div
      className={className || customClass}
      dangerouslySetInnerHTML={renderMarkdownContent(body)}
    />
  );
};

Markdown.propTypes = {
  body: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  className: PropTypes.string,
  inline: PropTypes.bool
};

Markdown.defaultProps = {
  customClass: 'txt',
  className: '',
  inline: false
};

export default Markdown;
