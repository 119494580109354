import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import Modal from 'cui/lib/components/Modal';
import Text from 'cui/lib/components/Form/Text';
import Field from 'cui/lib/components/Form/Field';
import Spacer from 'cui/lib/components/Spacer';
import Button from 'cui/lib/components/Button';

import updateNarrativeAction from './updateNarrativeAction';
import { MAX_NARRATIVE_LENGTH, isNarrativeValid } from './validationHelpers';

export class NarrativeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, narrativeBody: get(props, 'narrative.body') };
    this.show = () => this.setState({ isOpen: true });
    this.hide = () => this.setState({ isOpen: false });
    this.save = () => {
      if (!isNarrativeValid(this.state.narrativeBody)) {
        return;
      }
      this.props.updateNarrative({ organizationId: this.props.organizationId, year: this.props.year, body: this.state.narrativeBody });
      this.hide();
    };
    this.updateNarrativeBody = narrativeBody => this.setState({ narrativeBody });
  }

  componentWillReceiveProps(nextProps) {
    if (get(this.props, 'narrative.body') !== get(nextProps, 'narrative.body')) {
      this.setState({ narrativeBody: get(nextProps, 'narrative.body') });
    }
  }

  render() {
    const { narrative } = this.props;

    if (isUndefined(narrative)) {
      return null;
    }

    return (
      <div>
        <Button title="Edit District Narrative" onClick={this.show}>Edit District Narrative</Button>
        <Modal size="large" isOpen={this.state.isOpen} onHide={this.hide}>
          <Modal.Header title="Narrative" />
          <Modal.Body>
            <div className="cui-flexbar cui-flexbar_top">
              <div style={{ width: '60%' }}>
                <small className="cui-caps">What is a Narrative?</small>
                <p>
                  Each district has a unique financial base.
                  Looking at the data alone on the School Report Card does not explain the financial situation of the district.
                  Ensure that parents, community members and researchers have a more complete picture of the financial situation
                  in your district by providing a brief financial overview.
                  Narratives should be concise and easily understood.
                  Consider noting large-scale projects, initiatives or shifts in funding from prior years.
                </p>
              </div>
              <div style={{ width: '40%' }}>
                <small className="cui-caps">Publishing your Narrative</small>
                <p>
                  <ul>
                    <li>To view your narrative, go to the Financial Summary screen in the Financial Transparency domain.</li>
                    <li>Narrative text is limited to {MAX_NARRATIVE_LENGTH} characters.</li>
                  </ul>
                </p>
              </div>
            </div>
            <Spacer />
            <div>
              <Field label="Your Narrative">
                <Text
                  type="textarea"
                  id="narrative-body"
                  defaultValue={get(narrative, 'body')}
                  maxLength={MAX_NARRATIVE_LENGTH}
                  onChange={this.updateNarrativeBody}
                />
              </Field>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="cui-flexbar cui-flexbar_right">
              <Button title="Cancel" kind="stroked" theme="secondary" onClick={this.hide}>Cancel</Button>
              <Button
                attributes={{ disabled: !isNarrativeValid(this.state.narrativeBody) }}
                onClick={this.save}
                title="Save"
                kind="solid"
                theme="primary"
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  narrative: get(state, 'module.narrative'),
  organizationId: get(state, 'module.currentOrganization.id'),
  year: get(state, 'module.schoolYear')
});

const mapDispatchToProps = dispatch => ({
  updateNarrative: ({ organizationId, body, year }) => dispatch(updateNarrativeAction({ organizationId, body, year }))
});

NarrativeModal.propTypes = {
  narrative: PropTypes.shape({
    body: PropTypes.string.isRequired
  }),
  organizationId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired,
  updateNarrative: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(NarrativeModal);
