/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { EmptyState } from 'cui';

import GatewayHeader from '../../components/GatewayHeader';
import GatewayLink from '../../components/GatewayLink';
import GatewayPanel from '../../components/GatewayPanel';
import { DEFAULT_SUPPRESSION_COPY } from '../../constants';

import { findRestrictionProps, emptyStateProps } from '../../helpers/restrictionHelpers';
import styles from './index.module.css';

export class OrgReportCardDomainGatewayPanel extends React.Component {
  render() {
    const { currentOrganization, restrictAccess } = this.props;

    const restrictionProps = findRestrictionProps(currentOrganization, restrictAccess);

    if (restrictionProps) {
      return (
        <div className="container-constrained">
          <GatewayPanel>
            <GatewayPanel.Header>
              <GatewayHeader
                heading={this.props.heading}
                subheading={this.props.subheading}
                linkTo={this.props.allDataLink}
                iconUrl={this.props.iconUrl}
              />
            </GatewayPanel.Header>
          </GatewayPanel>
          <EmptyState
            className={styles.emptyState}
            kind="text"
            {...emptyStateProps(currentOrganization, restrictionProps, this.props.history)}
          />
        </div>
      );
    }

    return (
      <div className="container-constrained">
        <GatewayPanel>
          <GatewayPanel.Header>
            <GatewayHeader
              heading={this.props.heading}
              subheading={this.props.subheading}
              linkTo={this.props.allDataLink}
              iconUrl={this.props.iconUrl}
            />
          </GatewayPanel.Header>
          {
            this.props.successIndicatorLinks.length && (
              <GatewayPanel.Links>
                {
                  this.props.successIndicatorLinks.map(({ description, linkTo, title, ariaLabel }, index) => (
                    <GatewayLink
                      key={index}
                      title={title}
                      linkTo={linkTo}
                      description={description}
                      ariaLabel={ariaLabel}
                    />
                  ))
                }
              </GatewayPanel.Links>
            )
          }
          <GatewayPanel.Charts>
            {this.props.children}
          </GatewayPanel.Charts>
          <GatewayPanel.Footer>
            {DEFAULT_SUPPRESSION_COPY}
          </GatewayPanel.Footer>
        </GatewayPanel>
      </div>
    );
  }
}

OrgReportCardDomainGatewayPanel.propTypes = {
  currentOrganization: PropTypes.object,
  restrictAccess: PropTypes.arrayOf(PropTypes.shape({
    organizations: PropTypes.array,
    subtitle: PropTypes.string,
    title: PropTypes.string
  }))
};

OrgReportCardDomainGatewayPanel.defaultProps = {
  restrictAccess: []
};

export default withRouter(OrgReportCardDomainGatewayPanel);
