import React from 'react';
import PropTypes from 'prop-types';

import { Rating } from 'cui';

import styles from './StarRating.module.scss';

const StarRating = ({ score, message, total, level }) => (
  <div className={styles.starRating}>
    { score &&
      <div>
        <div className={styles.text}>{level}</div>
        <div className={styles.vizGroup}>
          <Rating total={total} filled={score} className={styles.stars} color="yellow-star" />
          <div className={styles.inlineText}>{score} of {total} stars</div>
        </div>
        {
          message && (
            <div className={styles.message}>
              {message}
            </div>
          )
        }
      </div>
    }
    { !score &&
      <div className={styles.noRating}>No Star Rating</div>
    }
  </div>
);

StarRating.propTypes = {
  score: PropTypes.number,
  message: PropTypes.string,
  total: PropTypes.number,
  level: PropTypes.string
};

StarRating.defaultProps = {
  total: 5
};

export default StarRating;
