import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const KdeInfoNote = ({ children }) => {
  if (!children) return null;

  return (
    <div className={styles.info}>
      <div className={styles.icon}>
        <Icon name="bb-circle-information" size="medium" />
      </div>
      <div className={styles.label}>
        {children}
      </div>
    </div>
  );
};

KdeInfoNote.propTypes = {
  children: PropTypes.string
};

KdeInfoNote.defaultProps = {
  children: null
};

export default KdeInfoNote;
