import React from 'react';
import { Redirect, withRouter } from 'react-router';
import PropTypes from 'prop-types';

import addYearToUrl from './addYearToUrl';

const RedirectWithYear = ({
  to,
  match,
  location,
  history,
  children,
  ...rest
}) => {
  const year = new URLSearchParams(location.search).get('year');

  return (
    <Redirect
      to={addYearToUrl(to, year)}
      {...rest}
    >
      {children}
    </Redirect>
  );
};

RedirectWithYear.propTypes = {
  ...Redirect.propTypes,
  location: PropTypes.object.isRequired
};

export default withRouter(RedirectWithYear);
