import React from 'react';
import PropTypes from 'prop-types';
import { getNumberSetData, scoreInfoPropType } from '../../../helpers/collectorHelpers';
import EmptyWrapper from '../EmptyWrapper';
import sectionHoc from './sectionHoc';

export const ResourcesSection = ({
  domainTitle,
  minutesPerWeekProgramName,
  scores: { budgetAllocationPercent, minutesPerWeek, professionalDevelopmentHours, studentInstructionalMinutesPerWeek }
}) => (
  <EmptyWrapper scoreInfos={{ budgetAllocationPercent, minutesPerWeek, professionalDevelopmentHours, studentInstructionalMinutesPerWeek }}>
    {() => (
      <section className="section" aria-labelledby="resources_section_aria_label">
        <h2 id="resources_section_aria_label">Resources</h2>
        <div className="grid two">
          <EmptyWrapper scoreInfo={budgetAllocationPercent}>
            {({ scoreInfo, notReported }) => (
              <div className="grid-cell">
                <h3 className="fieldLabel">School Budget Allocation to the {domainTitle} Program</h3>
                <div className="field">
                  {notReported || (
                    <span className="fieldValue" data-test="budgetAllocationPercent">{scoreInfo.value}%</span>
                  )}
                </div>
              </div>
            )}
          </EmptyWrapper>
          <EmptyWrapper scoreInfo={professionalDevelopmentHours}>
            {({ scoreInfo, notReported }) => (
              <div className="grid-cell">
                <h3 className="fieldLabel">Professional Development Hours Offered to Teachers Instructing {domainTitle} Courses</h3>
                <div className="field">
                  {notReported || (
                    <span className="fieldValue" data-test="professionalDevelopmentHours">{scoreInfo.value}</span>
                  )}
                </div>
              </div>
            )}
          </EmptyWrapper>
          {minutesPerWeekProgramName && (
            <EmptyWrapper scoreInfo={minutesPerWeek}>
              {({ scoreInfo, notReported }) => (
                <div className="grid-cell">
                  <h3 className="fieldLabel">
                    Number of Instructional Minutes Students Receive per Week in {domainTitle}
                  </h3>
                  <div className="field">
                    {notReported || (
                      <span className="fieldValue" data-test="minutesPerWeek">{scoreInfo.value}</span>
                    )}
                  </div>
                </div>
              )}
            </EmptyWrapper>
          )}
        </div>
        <EmptyWrapper scoreInfo={studentInstructionalMinutesPerWeek}>
          {({ scoreInfo, notReported }) => (
            <div>
              <h3 className="fieldLabel">
                {minutesPerWeekProgramName
                  ? `Weekly Instructional Minutes per Content Area through the ${minutesPerWeekProgramName} Program`
                  : `Number of Instructional Minutes Students Receive per Week in ${domainTitle}`
                }
              </h3>
              {notReported
                ? <div className="field">{notReported}</div>
                : (
                  <div className="grid three">
                    {getNumberSetData(scoreInfo).map(({ key, label, value }) => (
                      <div className="grid-cell" key={key}>
                        <div className="field" data-test="studentInstructionalMinutesPerWeek">
                          <span className="fieldName">{label}</span>
                          <span className="fieldValue">{value} minutes</span>
                        </div>
                      </div>
                    ))}
                  </div>
                )
              }
            </div>
          )}
        </EmptyWrapper>
      </section>
    )}
  </EmptyWrapper>
);

ResourcesSection.propTypes = {
  domainTitle: PropTypes.string,
  minutesPerWeekProgramName: PropTypes.string,
  scores: PropTypes.shape({
    budgetAllocationPercent: scoreInfoPropType,
    professionalDevelopmentHours: scoreInfoPropType,
    minutesPerWeek: scoreInfoPropType,
    studentInstructionalMinutesPerWeek: scoreInfoPropType
  })
};

export default sectionHoc(ResourcesSection);
