import React from 'react';
import PropTypes from 'prop-types';
import uniqueId from 'lodash/uniqueId';
import get from 'lodash/get';

import Chart from '../Chart';
import MenuActionBlock from './MenuActionBlock';

export class DataPoint extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.idVariableMeta = uniqueId('variable-meta-');
  }

  handleFilterChange = (newValue, index, tabName) => {
    this.setState(state => ({
      viewingDropdownFilters: {
        ...state.viewingDropdownFilters,
        [tabName]: {
          ...get(state.viewingDropdownFilters, tabName),
          [index]: newValue
        }
      }
    }));
  };

  renderChart() {
    const {
      tab,
      node,
      scores,
      organization,
      previousYearScores,
      schoolYear,
      params,
      additionalOrganizations,
      loading,
      dateTemplate,
      dateTemplateDates,
      baseUrl,
      additionalChartAdapters,
      selectedTimeSlice,
      additionalChartTypes,
      entities,
      entityScores,
      loadingWhen,
      viewOtherData,
      showViewOtherData,
      allScores,
      framework,
      featureFlags
    } = this.props;

    return (
      <Chart
        featureFlags={featureFlags}
        node={node}
        framework={framework}
        dateTemplate={dateTemplate}
        dateTemplateDates={dateTemplateDates}
        allScores={allScores}
        scores={scores}
        previousYearScores={previousYearScores}
        schoolYear={schoolYear}
        params={params}
        organization={organization}
        additionalOrganizations={additionalOrganizations}
        selectedTimeSlice={selectedTimeSlice}
        loading={loading}
        chart={tab.chartType}
        baseUrl={baseUrl}
        additionalChartAdapters={additionalChartAdapters}
        additionalChartTypes={additionalChartTypes}
        entities={entities}
        entityScores={entityScores}
        loadingWhen={loadingWhen}
        showViewOtherData={showViewOtherData}
        viewOtherData={viewOtherData}
        viewingDropdownFilter={get(this.state, ['viewingDropdownFilters', tab.name])}
        {...tab}
      />
    );
  }

  render() {
    const { tab, organization, chartCssStyle, node, scores, currentItem } = this.props;
    const { viewingDropdownFilters } = this.state;

    return (
      <div>
        <MenuActionBlock
          node={node}
          organization={organization}
          scores={scores}
          tab={tab}
          onChange={this.handleFilterChange}
          currentItem={currentItem}
          viewingDropdownFilters={viewingDropdownFilters}
        />
        <div style={chartCssStyle}>
          <div id={this.idVariableMeta} />
          {this.renderChart()}
        </div>
      </div>
    );
  }
}

DataPoint.propTypes = {
  tab: PropTypes.object,
  chartCssStyle: PropTypes.object,
  node: PropTypes.object,
  currentItem: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  organization: PropTypes.object,
  additionalOrganizations: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object,
  schoolYear: PropTypes.number,
  additionalChartAdapters: PropTypes.object,
  additionalChartTypes: PropTypes.object,
  entityScores: PropTypes.arrayOf(PropTypes.object),
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  previousYearScores: PropTypes.object,
  allScores: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.object,
  dateTemplate: PropTypes.string,
  dateTemplateDates: PropTypes.object,
  baseUrl: PropTypes.string,
  loadingWhen: PropTypes.string,
  showViewOtherData: PropTypes.bool,
  viewOtherData: PropTypes.arrayOf(PropTypes.object),
  entities: PropTypes.arrayOf(PropTypes.object),
  selectedTimeSlice: PropTypes.object
};

export default DataPoint;
