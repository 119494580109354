import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import { get } from '../../../wrappers';
import { filterZeroValues } from '../../../chartHelpers';
import { roundPercentages } from '../../../scoresHelpers';
import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, currentOrgScore } = options;
  const scoringOptions = get(node, 'scoring_options', {});
  const shape = conditional(get(scoringOptions, 'shape'), {}, options);
  const isProportion = scoringOptions.type === 'proportion';
  const score = scoringOptions.type === 'percent' || scoringOptions.type === 'twoDigits' ?
    currentOrgScore : roundPercentages(currentOrgScore, shape, isProportion);

  const data = sortBy(map(shape, (opts, key) => {
    let value = get(score, `value.${key}`, 0);
    if (isProportion) {
      value *= 100;
    }
    return {
      ...opts,
      value
    };
  }), ['index']);

  return (scoringOptions && scoringOptions.hideZeroValues) ? filterZeroValues(data) : data;
};

export default adapter;
