import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import reduce from 'lodash/reduce';

import ScoreDot from 'cui/lib/components/ScoreDot';
import List from 'cui/lib/components/List';

import connected from '../connected';

export const BarChartWithTitleDetailsPopover = (props) => {
  const { data, theme, subtitle } = props;

  return (
    <div style={{ padding: '0.5rem' }}>
      { subtitle ? <p><strong>{subtitle}</strong></p> : '' }
      <List>
        { reduce(data.value, (acc, datum) => {
          if (datum.hidden === undefined || !datum.hidden) {
            acc.push(
              <List.Item
                key={datum.name}
                className="cui-margin-bottom-small"
                icon={
                  <ScoreDot type="solid" theme={theme} value={datum.colorIndex} />
                }
              >
                <div>
                  <div className="cui-text_bold">{datum.label}</div>
                  <div className="cui-text_bold">{datum.value}% of Students</div>
                </div>
                <em className="cui-text_small">
                  {datum.student_count}
                </em>
              </List.Item>
            );
          }
          return acc;
        }, [])
        }
      </List>
    </div>
  );
};

BarChartWithTitleDetailsPopover.propTypes = {
  data: PropTypes.object,
  subtitle: PropTypes.string,
  theme: PropTypes.string
};

BarChartWithTitleDetailsPopover.defaultProps = {
  theme: 'rainbow'
};

export default withRouter(connected(BarChartWithTitleDetailsPopover));
