import React from 'react';
import PropTypes from 'prop-types';

import { format, startOfYear, endOfYear } from 'date-fns';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isUndefined from 'lodash/isUndefined';
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';

import Field from 'cui/lib/components/Form/Field';
import Label from 'cui/lib/components/Label';
import Text from 'cui/lib/components/Form/Text';

import styles from './CollectionNumberField.module.scss';

class CollectionNumberField extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: get(props, 'currentScore.value', {}),
      errors: {}
    };

    this.isMultiValue = get(this.props, 'dp.field_inputs');
    this.fieldKey = `field_${get(this.props, 'dp.node_path')}`;
    if (this.props.submissionValueGenerators) {
      this.props.submissionValueGenerators[get(this.props, 'dp.node_path')] = this.generateSubmissionValue;
    }
  }

  componentWillReceiveProps = (nextProps) => {
    // module.state initializes to a non-undefined value, so there's no
    // good way to await the return of the api call (it could legitimately be an empty array)
    // instead, we initialize the existing values on the return of the score
    // (if/when it goes from not having a value to having one).
    const currentScore = this.props.currentScore;
    const incomingScore = nextProps.currentScore;

    if (isUndefined(currentScore) && !isUndefined(incomingScore)) {
      this.setState({
        value: get(nextProps, 'currentScore.value', {})
      });
    }
  }

  onSingleValueFieldChange = (newValue) => {
    this.setValues('value', newValue);
  }

  onMultiValueFieldChange = (fieldId, newValue) => {
    const subFieldKey = fieldId.replace(`${this.fieldKey}_`, '');
    this.setValues(subFieldKey, newValue);
  }

  setValues = (fieldKey, value) => {
    const newValues = Object.assign({}, this.state.value);
    newValues[fieldKey] = value;
    this.setState({
      value: newValues
    });
  }

  setFieldError = (fieldKey, error) => {
    const newErrors = Object.assign({}, this.state.errors);
    if (isNil(error) && (fieldKey in newErrors)) {
      delete newErrors[fieldKey];
    } else if (!isNil(error)) {
      newErrors[fieldKey] = error;
    }

    this.setState({
      errors: newErrors
    });
  }

  generateSubmissionValue = () => {
    if (!isEmpty(this.state.errors)) { return null; }

    const currentValue = get(this.props.currentScore, 'value', {});
    if (isEqual(currentValue, this.state.value)) { return null; }

    const startAt = get(this.props.currentScore, 'start_at', format(startOfYear(new Date()), 'yyyy-MM-dd'));
    const endAt = get(this.props.currentScore, 'end_at', format(endOfYear(new Date()), 'yyyy-MM-dd'));

    return {
      startAt,
      endAt,
      value: this.state.value
    };
  }

  subLabel = () => {
    const { dp, previousYearScore } = this.props;

    return  dp.metadata?.showPreviousYearData && previousYearScore?.value
      ? `In ${new Date(previousYearScore.end_at).getFullYear()} the amount of ${previousYearScore.value.value} was entered.`
      : '';
  }

  multiInputFields = () => {
    const dp = get(this.props, 'dp');
    const fieldInputs = get(dp, 'field_inputs', {});
    const nodePath = get(dp, 'node_path');
    const groupLabel = get(dp, 'field_prompt', get(dp, 'name', ''));

    return (
      <Field
        htmlFor={this.fieldKey}
        label={groupLabel}
        subLabel={this.subLabel()}
        onChange={this.onMultiValueFieldChange}
        className={styles.multiInputField}
      >
        {map(fieldInputs, (f) => {
          const { key, label } = f;
          const fieldId = `field_${nodePath}_${key}`;
          return (
            <div key={fieldId} className={styles['multiInputField-field']}>
              <Label value={label} className={styles['multiInputField-field-label']} />
              <Text
                className={styles['multiInputField-field-input']}
                type="number"
                id={fieldId}
                htmlInputValidation
                value={get(this.state, `value.${key}`)}
                min={get(dp, 'min')}
                max={get(dp, 'max')}
                step={get(dp, 'isInteger') ? 1 : 'any'}
                stroked
                onError={error => this.setFieldError(fieldId, error)}
                errors={{
                  pattern: () => ('Each field must contain a valid number or be left blank.')
                }}
              />
            </div>
          );
        })}
      </Field>
    );
  };

  singleInputField = () => {
    const dp = get(this.props, 'dp');
    const nodePath = get(dp, 'node_path');
    const label = get(dp, 'field_prompt', get(dp, 'name', ''));
    const fieldId = `field_${nodePath}`;

    return (
      <div>
        <Field key={nodePath} label={label} subLabel={this.subLabel()} htmlFor={fieldId}>
          <Text
            type="number"
            htmlInputValidation
            id={this.fieldKey}
            onChange={this.onSingleValueFieldChange}
            value={get(this.state, 'value.value')}
            min={get(dp, 'min')}
            max={get(dp, 'max')}
            step={get(dp, 'isInteger') ? 1 : 'any'}
            stroked
            onError={error => this.setFieldError('value', error)}
          />
        </Field>
      </div>
    );
  };

  render() {
    return this.isMultiValue ? this.multiInputFields() : this.singleInputField();
  }
}

CollectionNumberField.propTypes = {
  currentScore: PropTypes.object,
  dp: PropTypes.object,
  submissionValueGenerators: PropTypes.object
};

export default CollectionNumberField;
