import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import multiSlot from 'cui/lib/components/shared/MultiSlot';

import First from './First';
import Second from './Second';
import Sidebar from './Sidebar';
import Footer from './Footer';

OrgPanelUI.propTypes = {
  slots: PropTypes.shape({
    First: PropTypes.node,
    Second: PropTypes.node,
    Sidebar: PropTypes.node,
    Footer: PropTypes.node
  }),
  position: PropTypes.oneOf(['', 'lift'])
};

function OrgPanelUI({ slots, position }) {
  return (
    <div className={cx('orgPanel', { orgPanel_four: slots.Footer }, { orgPanel_lift: position === 'lift' })}>
      {slots.First}
      {slots.Second}
      {slots.Sidebar}
      {slots.Footer}
    </div>
  );
}

const OrgPanelUIComponent = multiSlot(OrgPanelUI);
OrgPanelUIComponent.First = First;
OrgPanelUIComponent.Second = Second;
OrgPanelUIComponent.Sidebar = Sidebar;
OrgPanelUIComponent.Footer = Footer;

export default OrgPanelUIComponent;
