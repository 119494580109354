import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';

import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../helpers/scoreLoadActions';
import LOAD_FEATURE_FLAGS_ACTION from '../actions/feature_flag';
import DomainIntro from '../components/DomainIntro/index';
import Footer from '../components/Footer/index';
import Intro from '../components/Intro/index';
import LanguageToggle from '../components/LanguageToggle';
import pageTitle from '../helpers/pageHelpers';

import styles from './Home.module.scss';

const Home = ({ featureFlags, schoolYear }) => (
  <div>

    <LanguageToggle
      className={styles.languageToggle}
      featureFlags={featureFlags}
    />

    <Intro stateOrgId={20} />

    <DomainIntro schoolYear={schoolYear} />

    <Footer />

  </div>
);

Home.propTypes = {
  featureFlags: PropTypes.object,
  schoolYear: PropTypes.number
};

function mapStateToProps(state) {
  return {
    featureFlags: get(state, 'module.pageContext.featureFlags', {}),
    schoolYear: get(state, 'module.schoolYear')
  };
}

export default connect(mapStateToProps)(Home);

export const config = {
  title: pageTitle('Home'),
  initActions: [
    LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
    LOAD_FEATURE_FLAGS_ACTION
  ]
};
