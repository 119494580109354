import { useQuery } from 'react-query';

import { ENDPOINTS } from 'store/backend';
import request from 'helpers/request';

const queryKey = (id, options) => ['organizations', id, options];

const validateResponseStatus = response => {
  if (response.errors) throw new Error(`GraphQL Error: ${JSON.stringify(response.errors)}`);
};

const performRequest = ({ query, variables }) => request(ENDPOINTS.svcpd, {
  method: 'POST',
  body: JSON.stringify({ query, variables }),
  credentials: 'omit'
});

const getOrganization = async id => {
  const query = `query Organization($id: Int!) {
    organization(id: $id) {
      id
      organization_id
      name
      entity_type
      dist_number
      sch_number
      sch_cd
      sch_type
    }
  }`;

  const response = await performRequest({ query, variables: { id } });
  validateResponseStatus(response);

  return response.data.organization;
};

const getOrganizationChildren = async (id, year) => {
  const query = `query Organization($id: Int!, $year: Int) {
    organization(id: $id) {
      children(years: [$year]) {
        id
        organization_id
        name
        entity_type
        dist_number
        sch_number
        sch_type
        parentDistrict: parent_district {
          id
        }
      }
    }
  }`;

  const response = await performRequest({ query, variables: { id, year } });
  validateResponseStatus(response);

  return response.data.organization.children;
};

const queryFn = async ctx => {
  const [_key, id, options] = ctx.queryKey;

  const organization = await getOrganization(id);
  if (options.includeChildren) {
    const children = await getOrganizationChildren(id, options.year);
    Object.assign(organization, { children });
  }

  return organization;
};

const useOrganizationQuery = (id, options = {}) =>
  useQuery({
    queryKey: queryKey(id, options),
    queryFn
  });

export default useOrganizationQuery;
