import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';
import Tag from 'cui/lib/components/Tag';

import Markdown from '../Markdown';
import template from '../../helpers/template';
import { findWhen } from '../../helpers/when';

const TagCell = ({ item, tags }) => {
  const { icon = '', theme = '', text = '' } = findWhen(tags, item);

  return (
    <Tag className="cui-text_upper" theme={theme}>
      <Icon name={icon} /> <Markdown body={template(text, item)} inline />
    </Tag>
  );
};

TagCell.propTypes = {
  item: PropTypes.object,
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      when: PropTypes.string,
      icon: PropTypes.string,
      theme: PropTypes.string,
      text: PropTypes.string
    })
  )
};

TagCell.defaultProps = {
  tags: []
};

export default TagCell;
