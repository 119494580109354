import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import memoizeOne from 'memoize-one';
import bowser from 'bowser';

import Alert from 'cui/lib/components/Alert';
import CuiButton from 'cui/lib/components/Button';
import BlockTitle from 'cui/lib/components/BlockTitle';
import Panel from 'cui/lib/components/Panel';
import TabbedContent from 'cui/lib/components/TabbedContent';

import { findNodeByNodePath } from '../helpers/frameworkHelpers';
import { kdeCurrentSchoolYear } from '../helpers/portals';
import LOAD_CURRENT_ORG from '../queries/load_current_org';
import allDataPoints from '../helpers/allDataPoints';
import { canSeeCollectionDomain } from '../helpers/approvalVisibilityHelpers';
import collectVariables from '../helpers/collectVariables';
import withApprovalWrapper from '../components/ApprovalWrapper';
import ApprovalHeader from '../components/ApprovalHeader';
import CollectionVariableSection from '../components/CollectionVariableSection';
import MainContentAnchor from '../components/MainContentAnchor';
import WithPredicate from '../components/WithPredicate';
import Redirect from '../components/Link/Redirect';
import { currentOrganizationSelector } from '../selectors/moduleSelector';
import { editableVariableSelector, frameworkSelector } from '../selectors/collector/portalScoreSelector';

// Redux Actions
import buildLoadFrameworkAction from '../actions/framework';
import {
  getOrgIdFromOrganization,
  transformScoresFromPortalDataService
} from '../helpers/portalDataServiceHelpers';
import styles from './Status.module.scss';

/* eslint-disable max-len */
const BROWSER_NOT_SUPPORTED_ALERT = 'We apologize for the inconvenience, but we are experiencing technical issues with this webpage in the FireFox browser. Please use another browser to access this webpage.';
/* eslint-enable max-len */

class CollectionPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 0,
      submissionCount: 0,
      successful: false
    };

    this.nodePathSlugMap = {};

    this.submissionValueGenerators = {};
  }

  componentWillMount() {
    if (bowser.firefox) {
      // Note parsing version using parseFloat is not accurate because some browser
      // use semantic versioning(30.20.10.1), but in our case here it should be fine as we only
      // want to detect firefox which does not seem to use semantic versioning.
      const browserVersion = parseFloat(bowser.version);
      if (Number.isFinite(browserVersion) && browserVersion < 64) {
        // Currently Collection form is not working for Firefox 63 and below
        // eslint-disable-next-line no-alert
        alert(BROWSER_NOT_SUPPORTED_ALERT);
        const history = this.props.history;
        // Redirect user back to previous page
        if (history) {
          history.goBack();
        }
      }
    }
  }

  componentDidMount = () => {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  };

  componentWillReceiveProps = (nextProps) => {
    if (this.state.formSubmitted) {
      const errorCount = Object.keys(get(nextProps, 'errors', {})).length;
      this.setState({ successful: errorCount === 0 });
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
  };

  updateTab = (currentTab) => {
    this.setState({ currentTab, formSubmitted: false });
  };

  // cache editable variables for better performance
  collectEditableVariables = memoizeOne(framework => collectVariables(framework, { editable: true }));

  // get data slug by node path
  dataSlugByNodePath = (nodePath) => {
    if (!this.nodePathSlugMap[nodePath]) {
      const targetNode = findNodeByNodePath(this.props.framework, nodePath);
      // Note: some data points have the same slugs, use metadata.collectorSource.slug to give
      // them a uniq slug to fetch from svc-data-portal service. We should make sure all data points
      // use different slugs.
      // TODO, update those slugs and also need to update scores that belong to them
      const dataSlug = get(targetNode, 'metadata.collectorSource.slug') || get(targetNode, 'slug');

      if (dataSlug) {
        this.nodePathSlugMap[nodePath] = dataSlug;
      }
    }

    return this.nodePathSlugMap[nodePath];
  }

  generateScoreChanges = () => {
    const changes = [];
    // data points register a submit value generator on init
    // they are called on submit.
    // generators should return null or empty object if the datapoint
    // should not be persisted in its current state.
    forEach(this.submissionValueGenerators, (generator, nodePath) => {
      const generatedValue = generator();

      if (!isEmpty(get(generatedValue, 'value'))) {
        const orgId = get(this.props, 'currentOrganization.id');
        const { startAt, endAt, value } = generatedValue;
        changes.push({ startAt, endAt, nodePath, orgId, value });
      }
    });

    this.setState({ submissionCount: this.state.submissionCount + changes.length });

    return changes;
  };

  handleBeforeUnload = (event) => {
    const changes = this.generateScoreChanges();
    if (changes.length > 0) {
      // note that many browsers show a canned "changes may not be saved"
      // message instead of the copy we pass here
      event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
    }
  };

  /* eslint-disable no-undef */
  submitToSvcDataPortal = () => {
    const scores = this.generateScoreChanges()
      .map(({ nodePath, value }) => ({
        year: kdeCurrentSchoolYear(), // only allow to update score for current school year
        organization_id: getOrgIdFromOrganization(get(this.props, 'currentOrganization')),
        data_point_slug: this.dataSlugByNodePath(nodePath),
        data_point_path: nodePath,
        value
      }));

    this.props.onPortalScoresUpdate(scores);
    this.setState({ formSubmitted: true });
  }

  /* eslint-enable no-undef */
  renderSubmitAlert = ({ successful, errors }) => {
    if (successful && isEmpty(errors)) {
      return (
        <Alert kind={Alert.SUCCESS_TYPE} closable autoclose>
          Submitted!
        </Alert>
      );
    }
    if (successful && !isEmpty(errors)) {
      return (
        <Alert kind={Alert.CAUTION_TYPE} closable autoclose={false}>
          Some responses did not successfully submit. Please review the flagged questions for correction before submitting again.
        </Alert>
      );
    }
    if (!successful && !isEmpty(errors)) {
      return (
        <Alert kind={Alert.ERROR_TYPE} closable autoclose={false}>
          Error while submitting the form. Please review all changes and submit again or try again later.
        </Alert>
      );
    }
    return null;
  };

  render() {
    const { currentOrganization, framework, scores, previousYearScores, user, errors, featureFlags } = this.props;
    // FIXME: Replace with awaitProp
    if (!framework || !currentOrganization) return null;

    // auth check
    if (!user) return <Redirect to={'/'} />;

    // org check
    if (currentOrganization.entity_type.toLowerCase() !== 'school') {
      return (
        <Alert kind={Alert.ERROR_TYPE} closable={false} autoclose={false}>
          The selected organization does not have access to the collector.
        </Alert>
      );
    }
    const isApprovalPeriodOpen = !!featureFlags.KdeApprovalPeriodOpen;
    const isStateUser = user.currentOrganization.entityType === 'State';
    const shouldShowContent = isApprovalPeriodOpen || isStateUser;
    const editableNodes = this.collectEditableVariables(framework);

    return (
      <div className={styles.container}>
        <ApprovalHeader />
        <WithPredicate
          predicate={shouldShowContent}
          placeholder={
            <Panel>
              <Panel.Content>
                The Data Approval and Collection Period is not open at this time.
                Please contact the Kentucky State Department of Education for more information.
              </Panel.Content>
            </Panel>
          }
        >
          <div className="content-container" role="main">
            <MainContentAnchor />
            <BlockTitle title="Data Collection" />
            <Panel>
              <Panel.Content>
                <TabbedContent hideContentOnTabChange activeTab={this.state.currentTab} onChange={this.updateTab}>
                  {
                    editableNodes.map(({ domain, successIndicator, variable }) => {
                      if (!canSeeCollectionDomain(user, domain.slug, featureFlags)) return null;
                      return (
                        <TabbedContent.Pane title={get(variable, 'collector_navigation_tab.name', variable.name)} key={variable.node_path}>
                          <CollectionVariableSection
                            key={variable.node_path}
                            domain={domain}
                            si={successIndicator}
                            currentOrganization={currentOrganization}
                            variable={variable}
                            submissionValueGenerators={this.submissionValueGenerators}
                            scores={scores}
                            previousYearScores={previousYearScores}
                            errors={errors}
                            userRole={user.currentModulePlatformAccessLevel}
                          />
                        </TabbedContent.Pane>
                      );
                    })
                  }
                </TabbedContent>
                <section className="cui-margin-top-medium">
                  {this.renderSubmitAlert({
                    successful: this.state.successful,
                    errors: this.props.errors
                  })}
                  <CuiButton
                    className="cui-margin-top-medium"
                    title="Submit"
                    kind="stroked"
                    onClick={this.submitToSvcDataPortal}
                    theme="primary"
                  >
                    Submit
                  </CuiButton>
                </section>
              </Panel.Content>
            </Panel>
          </div>
        </WithPredicate>
      </div>
    );
  }
}

CollectionPage.propTypes = {
  user: PropTypes.shape({
    currentOrganization: PropTypes.shape({
      id: PropTypes.number
    })
  }),
  onPortalScoresUpdate: PropTypes.func,
  currentOrganization: PropTypes.object,
  featureFlags: PropTypes.object,
  framework: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.object,
  history: PropTypes.object,
  pageContext: PropTypes.object
};
/* eslint-disable camelcase */
const portalScoresUpdate = scores => (
  {
    type: 'GRAPH_LOAD',
    service: 'svcpd',
    query: `
      mutation updateScores($scores: [ScoreInputType]) {
        updateScores(scores: $scores) {
          success
        }
      }
    `,
    scoresToUpdate: scores,
    variables: {
      scores: scores.map(({ value, year, organization_id, data_point_slug }) => ({
        value: JSON.stringify(value),
        year,
        organization_id,
        data_point_slug
      }))
    },
    stateObjectMap: {
      scoresFromPortalDataService: {
        dataKey: 'updateScores',
        method(currentScores, returnedScores, currentAction) {
          // Update scores in the frontend after form submission
          // so that when we navigate away from collection page
          // the Data Not Saving warning won't pop up.
          const updateSuceeded = get(returnedScores, 'success', false);
          const scoresToUpdate = get(currentAction, 'actionData.scoresToUpdate', []);
          // Only update if scores are saved in our backend, svcdataportal
          if (updateSuceeded && !isEmpty(scoresToUpdate)) {
            const updatedScores = { ...currentScores };
            scoresToUpdate.forEach((score) => {
              const scoreForDataPoint = get(updatedScores, [score.data_point_path, score.organization_id]);
              if (scoreForDataPoint) {
                updatedScores[score.data_point_path][score.organization_id] = { ...scoreForDataPoint };
                updatedScores[score.data_point_path][score.organization_id].scores = [
                  {
                    year: score.year,
                    end_at: new Date(score.year, 6, 1).toISOString(),
                    organization_id: score.organization_id,
                    start_at: new Date(score.year - 1, 6, 1).toISOString(),
                    value: score.value
                  }
                ];
              }
            });

            return updatedScores;
          }

          return currentScores;
        }
      }
    }
  }
);
/* eslint-enable camelcase */

const createTransformedScoresSelector = scoresSelector => createSelector(
  scoresSelector,
  editableVariableSelector,
  currentOrganizationSelector,
  (scores, editableNodes, currentOrg) => {
    if (!isEmpty(scores) && !isEmpty(editableNodes) && currentOrg) {
      const orgIdMap = {
        [getOrgIdFromOrganization(currentOrg)]: currentOrg.id
      };
      return transformScoresFromPortalDataService(scores, editableNodes, orgIdMap)
        .map(score => ({ ...score, value: get(score, 'value.score') }));
    }
    return null;
  }
);

const mapStateToProps = state => ({
  user: get(state, 'module.userContext'),
  featureFlags: get(state, 'module.pageContext.featureFlags'),
  framework: frameworkSelector(state),
  currentOrganization: currentOrganizationSelector(state),
  scores: createTransformedScoresSelector(st => get(st, 'module.scoresFromPortalDataService'))(state),
  previousYearScores: createTransformedScoresSelector(st => get(st, 'module.previousYearScores'))(state),
  errors: get(state, 'module.errors', {}),
  pageContext: get(state, 'module.pageContext')
});

export const mapDispatchToProps = dispatch => ({
  onPortalScoresUpdate: (scores) => {
    dispatch(portalScoresUpdate(scores));
  }
});

export default withApprovalWrapper(connect(mapStateToProps, mapDispatchToProps)(CollectionPage));

const buildLoadScoresAction = (year, stateKey, dataPointsFilter = Boolean) => ({
  type: 'FETCH_TOKEN',
  service: 'svcpd',
  slug: 'kde',
  actions: [
    {
      type: 'PORTAL_DATA_LOAD',
      query: (state) => {
        const currentOrganization = currentOrganizationSelector(state);
        const dataPoints =
          allDataPoints(state?.framework?.kde, { editable: true })
            .filter(dataPointsFilter);

        return {
          year,
          organization_ids: [getOrgIdFromOrganization(currentOrganization)],
          data_points: dataPoints.map(dp => ({
            slug: get(dp, 'metadata.collectorSource.slug') || get(dp, 'slug'),
            key: dp.node_path,
            dimensions: []
          }))
        };
      },
      stateKey
    }
  ]
});

export const config = {
  title: '{{name}} - Collection - Kentucky School Report Card Approval',
  mapStateToProps: state => ({
    name: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase())
  }),
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        {
          type: 'GRAPH_LOAD',
          service: 'svcpd',
          query: LOAD_CURRENT_ORG,
          stateObjectMap: {
            currentOrganization: 'organization'
          },
          propMap: {
            id: 'params.id',
            year: 'module.schoolYear',
            framework: 'framework'
          },
          actions: [
            buildLoadScoresAction(
              kdeCurrentSchoolYear(),
              'scoresFromPortalDataService'
            ),
            buildLoadScoresAction(
              kdeCurrentSchoolYear() - 1,
              'previousYearScores',
              dp => dp.metadata?.showPreviousYearData
            )]
        }
      ]
    })
  ]
};
/* eslint-enable max-len */
