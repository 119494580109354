import filter from 'lodash/filter';
import flattenDeep from 'lodash/flattenDeep';
import map from 'lodash/map';
import startsWith from 'lodash/startsWith';
import uniq from 'lodash/uniq';

import allDataPoints from '../allDataPoints';
import orgCanAccessCollector from '../orgCanAccessCollectorDataPoint';

const editableDataPoints = (framework, organization, domains) => {
  // gather all the editable data points
  const allEditableDataPoints = allDataPoints(framework, { metadata: { editable: true } });

  // filter by the selected domains
  let editableDataPointsForDomains;
  if (domains) {
    editableDataPointsForDomains = uniq(
      flattenDeep(
        map(domains, d =>
          filter(allEditableDataPoints, dp =>
            startsWith(dp.node_path, d.node_path)
          )
        )
      )
    );
  } else {
    editableDataPointsForDomains = allEditableDataPoints;
  }

  // filter by the org's access to them
  return filter(editableDataPointsForDomains, dp => orgCanAccessCollector(dp, organization));
};

export default editableDataPoints;
