import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

const adapter = adaptee => (options) => {
  const currentFilter = get(options.params, 'filters.demographic', 'All');
  const scoresForNode = filter(options.scores, { framework_tree_node: { node_path: options.node.node_path } });
  const entityScoresForNode = filter((options.entityScores || []), { framework_tree_node: { node_path: options.node.node_path } });
  const scores = scoresForNode.map(score => ({
    ...score,
    value: get(score, `value.${currentFilter}`, {})
  }));
  const entityScores = entityScoresForNode.map(score => ({
    ...score,
    value: get(score, `value.${currentFilter}`, {})
  }));

  if (options.currentOrgScore && !isEmpty(options.currentOrgScore)) {
    const currentOrgScore = { ...options.currentOrgScore, value: get(options, ['currentOrgScore', 'value', currentFilter]) };
    return adaptee({ ...options, scores, currentOrgScore, entityScores });
  } else {
    return adaptee({ ...options, scores, entityScores });
  }
};

export default adapter;
