import React from 'react';
import PropTypes from 'prop-types';
import { Heading } from 'cui';
import Link from '../Link';

import styles from './index.module.scss';

DomainIntroItem.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  linkTo: PropTypes.string,
  children: PropTypes.node
};

DomainIntroItem.defaultProps = {
  linkTo: null
};

function DomainIntroItem({ title, image, linkTo, children }) {
  const OuterNode = linkTo ? Link : 'div';

  return (
    <OuterNode to={linkTo} className={styles.item}>
      <div className={styles.image}>
        <img src={image} alt="" role="presentation" />
      </div>
      <div>
        <Heading level={5} size="base">{title}</Heading>
        {children}
      </div>
    </OuterNode>
  );
}

export default DomainIntroItem;
