import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import scrollToElement from 'scroll-to-element';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import { ViewingBlock, EmptyState } from 'cui';

import conditional from '../../helpers/conditional';
import emptyStateMessage from '../../helpers/emptyState';
import DataPoints from './DataPoints';
import DownloadLink from '../DownloadLink';

import useDataSetQuery from '../../store/localQueries/datasets';

const getDownloadItems = (schoolYear, nodePath) => {
  const { data } = useDataSetQuery(schoolYear, nodePath);
  return data;
};

const defaultSelectedViewingItem = (subjectView, innerChartViewingBlock) => {
  if (!isEmpty(subjectView)) {
    return subjectView.items[0].node_path;
  } else if (!isEmpty(innerChartViewingBlock)) {
    return Object.keys(innerChartViewingBlock.items)[0];
  }

  return undefined;
};

const DomainDataPoints = (props) => {
  const {
    additionalChartAdapters,
    additionalChartTypes,
    additionalOrganizations,
    baseUrl,
    currentNode,
    dataPoints,
    dateTemplate,
    dateTemplateDates,
    domainSlug,
    entities,
    entityScores,
    framework,
    isLoading,
    isPreviewSite,
    loadingWhen,
    organization,
    previousYearScores,
    scores,
    selectedTimeSlice,
    successIndicatorSlug,
    showScoreDates,
    showViewOtherData,
    userRole,
    variableSlug,
    viewOtherData,
    whyThisMatters
  } = props;
  let { innerChartViewingBlock } = props;
  const location = useLocation();

  useEffect(() => {
    if (!location.search) return;
    const { scrollTo } = qs.parse(location.search, { ignoreQueryPrefix: true });
    if (scrollTo) {
      scrollToElement(`.${scrollTo}-download`, { offset: -75 });
    }
  });

  const year = new URLSearchParams(location.search).get('year');
  const downloadItems = getDownloadItems(year, `kde.${domainSlug}.${successIndicatorSlug}.${variableSlug}`);
  const subjectView = props.subjectView && conditional(props.subjectView, {}, props);

  innerChartViewingBlock = props.innerChartViewingBlock && conditional(
    props.innerChartViewingBlock, {}, props
  );

  const [selectedViewingItem, setSelectedViewingItem] = useState(
    defaultSelectedViewingItem(subjectView, innerChartViewingBlock)
  );

  const getViewingItems = () => {
    if (isEmpty(subjectView)) {
      return [];
    }

    return subjectView.items.map(item => ({
      text: item.text,
      onClick: () => setSelectedViewingItem(item.node_path)
    }));
  };

  const viewingItems = getViewingItems();

  let activeTab = findIndex(subjectView.items, { node_path: selectedViewingItem });
  if (activeTab === -1) { activeTab = 0; }

  let emptyMessage;
  const variable = currentNode?.items?.find(el => el.slug === variableSlug);
  if (variable) {
    emptyMessage = emptyStateMessage(variable, organization);
  }

  const node = find(framework.items, { slug: domainSlug });

  return (
    <div>
      {!isEmpty(subjectView) &&
        <ViewingBlock
          type="tabs"
          items={viewingItems}
          activeTab={activeTab}
        />
      }

      <DownloadLink
        downloadItems={downloadItems}
        name={variable?.singleDatasetForVariable ? 'Download' : 'Download All'}
        className="cui-text_right"
      />

      {emptyMessage ? (
        <EmptyState
          kind="text"
          {...emptyMessage}
        />
      ) : <DataPoints
        additionalChartAdapters={additionalChartAdapters}
        additionalChartTypes={additionalChartTypes}
        additionalOrganizations={additionalOrganizations}
        baseUrl={baseUrl}
        dataPoints={dataPoints}
        dateTemplate={dateTemplate}
        dateTemplateDates={dateTemplateDates}
        entities={entities}
        entityScores={entityScores}
        organization={organization}
        innerChartViewingOptions={innerChartViewingBlock}
        node={node}
        scores={scores}
        showScoreDates={showScoreDates}
        selectedTimeSlice={selectedTimeSlice}
        showViewOtherData={showViewOtherData}
        subjectView={subjectView}
        selectedViewingItem={selectedViewingItem}
        isLoading={isLoading}
        isPreviewSite={isPreviewSite}
        loadingWhen={loadingWhen}
        previousYearScores={previousYearScores}
        userRole={userRole}
        whyThisMatters={whyThisMatters}
        viewOtherData={viewOtherData}
      />}
    </div>
  );
};

DomainDataPoints.defaultProps = {
  framework: {
    items: []
  },
  organization: {
    entity_type: ''
  },
  innerChartViewingBlock: {},
  subjectView: {},
  scores: [],
  previousYearScores: {},
  domainSlug: '',
  whyThisMatters: {},
  showScoreDates: false,
  additionalChartAdapters: {},
  additionalChartTypes: {},
  entityScores: [],
  entities: [],
  showViewOtherData: false,
  userRole: ''
};

DomainDataPoints.propTypes = {
  additionalChartAdapters: PropTypes.object,
  additionalChartTypes: PropTypes.object,
  csrfToken: PropTypes.string,
  dataPoints: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.object,
  framework: PropTypes.object,
  currentNode: PropTypes.object,
  variableSlug: PropTypes.string,
  organization: PropTypes.object,
  additionalOrganizations: PropTypes.arrayOf(PropTypes.object),
  selectedTimeSlice: PropTypes.object,
  innerChartViewingBlock: PropTypes.object,
  subjectView: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  previousYearScores: PropTypes.object,
  allScores: PropTypes.arrayOf(PropTypes.object),
  domainSlug: PropTypes.string,
  whyThisMatters: PropTypes.arrayOf(
    PropTypes.object
  ),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  baseUrl: PropTypes.string,
  showScoreDates: PropTypes.bool,
  dateTemplate: PropTypes.string,
  entities: PropTypes.arrayOf(
    PropTypes.object
  ),
  entityScores: PropTypes.arrayOf(
    PropTypes.object
  ),
  loadingWhen: PropTypes.string,
  dateTemplateDates: PropTypes.object,
  viewOtherData: PropTypes.arrayOf(
    PropTypes.object
  ),
  showViewOtherData: PropTypes.bool,
  successIndicatorSlug: PropTypes.string,
  isPreviewSite: PropTypes.bool,
  userRole: PropTypes.string,
  variable: PropTypes.object
};

export default DomainDataPoints;
