import React from 'react';
import PropTypes from 'prop-types';

Links.propTypes = {
  children: PropTypes.node
};

function Links({ children }) {
  return (
    <div className="gatewayGrid-item">
      { children }
    </div>
  );
}

Links.defaultProps = {
  'data-slotName': 'Links'
};

export default Links;
