import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';

import { Button, Field, InfoPopover } from 'cui';

import FederalClassificationModal from '../FederalClassificationModal';
import StarRating from '../../ui/StarRating';
import starRatingMessage from '../../helpers/schoolStatHelpers';

class SchoolLevelExplanation extends React.PureComponent {
  state = { isOpen: false };

  showModal = () => this.setState({ isOpen: true });

  hideModal = () => this.setState({ isOpen: false });

  render() {
    const { stat, popoverContent } = this.props;
    const federalClassification = stat.fedClass;

    return (
      <Field label={get(stat, 'heading')}>
        <div className="flexRow flexRow--nowrap">
          <StarRating
            score={get(stat, 'rating.star')}
            message={starRatingMessage(get(stat, 'rating.gap_reduction'))}
          />
        </div>
        {federalClassification.classifications.length > 0 ? (
          <div>
            <small>
              {federalClassification.text}&nbsp;
              <InfoPopover content={popoverContent} />
            </small>
            <div>
              <Button
                className="tinyCaps cui-text_left cui-margin-top-xSmall"
                onClick={this.showModal}
              >
                Learn more
              </Button>
            </div>
            <FederalClassificationModal
              isOpen={this.state.isOpen}
              onHide={this.hideModal}
              federalClassification={federalClassification}
            />
          </div>
        ) : ''}
      </Field>
    );
  }
}

SchoolLevelExplanation.propTypes = {
  stat: PropTypes.shape({
    rating: PropTypes.shape({
      star: PropTypes.number,
      gap_reduction: PropTypes.string
    })
  }),
  popoverContent: PropTypes.string
};

export default SchoolLevelExplanation;
