import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/merge';
import get from 'lodash/get';
import set from 'lodash/set';
import clone from 'lodash/clone';

import { BarChartWithLegend } from 'cui/lib/components/LegendWrapper';
import EmptyState from 'cui/lib/components/EmptyState';

import EntityComparisonDetailsPopover from '../EntityComparisonDetailsPopover';
import EntityComparisonDetailsPopoverWithoutPercentSign from '../EntityComparisonDetailsPopoverWithoutPercentSign';

import template from '../../helpers/template';
import ChartOptionHelpers from '../../helpers/chartOptionHelpers';
import currentOrgYearScores from '../../helpers/scoresHelpers';
import chartDataAdapter from '../../helpers/chartDataAdapters';
import dataMaxHelper from '../../helpers/dataMaxHelper';
import numberFormatter from '../../helpers/numberFormatter';

const popoverMap = {
  entityComparisonDetailsPopover: EntityComparisonDetailsPopover,
  entityComparisonDetailsPopoverWithoutPercentSign: EntityComparisonDetailsPopoverWithoutPercentSign
};

export const BarChart = (props) => {
  const { config, scores, organization, chartType, directScore } = props;
  const options = config.options || {};
  const score = directScore || currentOrgYearScores(scores, organization);

  const dataOptions = {
    ...props,
    currentOrgScore: score
  };

  const data = chartDataAdapter(config.adapter || chartType, dataOptions);
  const { min = 0, max, customLabelCount = 6 } = dataMaxHelper[config.maxDataAdapter || chartType]({ ...dataOptions, data });
  const hasData = data && data.length;
  const lowAndHigh = { high: max };
  if (min !== undefined) {
    lowAndHigh.low = min;
  }
  let index = -1;
  let chartOptions = options && merge(options, {
    ...lowAndHigh,
    yAxis: {
      labelInterpolationFunc: l => template(options.yAxis.labelTemplate || '{{l}}', { l })
    },
    xAxis: {
      labelCount: get(options, 'xAxis.labelCount', customLabelCount)
    },
    valueLabelFormatter: () => {
      index += 1;
      if (index >= data.length) index = 0;

      let { value } = data[index];
      const { subLabelTemplate = '{{value}}', valueFormat, precision } = options.yAxis;

      if (valueFormat === 'number' || valueFormat === 'percent') {
        value = numberFormatter(value, valueFormat, precision);
      }

      return template(subLabelTemplate, { l: data[index].label, value });
    },
    detailsPopoverRenderer: popoverMap[options.detailsPopoverRenderer]
  });

  chartOptions = ChartOptionHelpers.gridFilter(chartOptions);
  set(chartOptions, 'xAxis.forceOrdinalScale', true);
  return (
    <div>
      { !hasData && <EmptyState kind="text" title="There is no data available." /> }
      { !!hasData && (
        <BarChartWithLegend
          chartProps={{
            data,
            chartOptions: clone(chartOptions)
          }}
          theme={config.theme}
        />
      )}
    </div>
  );
};

BarChart.propTypes = {
  node: PropTypes.object,
  config: PropTypes.shape({
    options: PropTypes.object
  }),
  scores: PropTypes.arrayOf(
    PropTypes.object
  ),
  organization: PropTypes.object,
  chartType: PropTypes.string,
  directScore: PropTypes.object
};

export default BarChart;
