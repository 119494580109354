import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import find from 'lodash/find';
import startCase from 'lodash/startCase';
import { format } from 'date-fns';

// eslint-disable-next-line import/no-webpack-loader-syntax,import/no-unresolved
import css from '!!raw-loader!../../styles/report/index.css';
import template from '../../helpers/template';
import connected from '../connected';
import CoursesOfferedSection from './sections/CoursesOfferedSection';
import FacilitiesSection from './sections/FacilitiesSection';
import ProgramOverviewSection from './sections/ProgramOverviewSection';
import ResourcesSection from './sections/ResourcesSection';
import StaffingSection from './sections/StaffingSection';
import WorkplaceEthics from './sections/WorkplaceEthics';

import { SUPPORTED_YEARS } from '../../constants';

/* eslint max-len: ["error", { "code": 150, "ignoreStrings": true }] */
export const ProfileReport = (props) => {
  const currentOrganization = {
    ...props.currentOrganization,
    name: startCase(get(props.currentOrganization, 'name', '').toLowerCase())
  };

  const { schoolYear, scores } = props;

  const year = find(SUPPORTED_YEARS, { value: schoolYear });

  const sectionProps = {
    framework: props.framework,
    organization: currentOrganization,
    scores
  };
  const careerStudiesPath = (schoolYear > 2018) ? 'kde.educational_opportunity.general_education_coursework.career_studies.' : 'kde.educational_opportunity.career_and_technical_education.career_studies.';

  const handleSectionClick = (event) => {
    event.preventDefault();
    const newUrlHash = event.target.getAttribute('href').replace('#', '');

    window.location.hash = newUrlHash;
  };

  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: template(css, { currentOrganization, year }) }} />
      <section className="page page_cover" role="banner" aria-labelledby="page_cover_section_aria_label">
        <span id="page_cover_section_aria_label" hidden>Cover Page</span>
        <h1>School Profile Report</h1>
        <h2>{currentOrganization.name}</h2>
        <h3>Academic Year: {year.text}</h3>
      </section>
      <section className="page" id="first">
        <section className="section" aria-labelledby="introduction_section_aria_label">
          <h2 id="introduction_section_aria_label">Introduction</h2>
          <p>
            The annual School Profile Report highlights programs offered in your child’s school.  It includes how schools address and implement the
            adopted Kentucky Academic Standards for the programs of Health and Physical Education (Practical Living), Career and Technical Education
            (Career Studies), Visual and Performing Arts, and World Languages (Foreign Language).</p>
          <p>
            Reporting is in accordance with KRS 158.6453 Section 3 (20) as amended in Senate Bill 1 (2017).  An advisory committee made up of
            certified teachers and specialists from Kentucky’s public schools and postsecondary institutions in these subject areas identified the
            process and method for collecting the information necessary to demonstrate program length and time, courses offered, staffing, resources
            and facilities for each of the subject areas.
          </p>
          <p>
            Data is electronically transmitted through the annual School Report Card collection tool to the Kentucky Department of Education.  By Oct.
            1 of each year, school principals shall complete the school profile report, which shall be signed by the members of the school-based
            decision making council, or the principal if no school council exists, and the superintendent. The signed original is maintained on file
            at the local board office and made available to the public upon request. A link to the School Profile Report is made available to the
            Kentucky Department of Education and is accessible on the department’s website through the School Report Card.
          </p>
        </section>
        <nav className="section" aria-labelledby="report_section_links_nav_aria_label">
          <span id="report_section_links_nav_aria_label" hidden>Report Section Links</span>
          <h2>Contents of the School Profile Report include</h2>
          <ol>
            <li><a href="#section1" onClick={handleSectionClick}>Visual and Performing Arts</a></li>
            <li><a href="#section2" onClick={handleSectionClick}>Health Education and Physical Education (Practical Living)</a></li>
            <li><a href="#section3" onClick={handleSectionClick}>World Languages (Foreign Language)</a></li>
            <li><a href="#section4" onClick={handleSectionClick}>Career and Technical Education (Career Studies)</a></li>
            <li><a href="#section5" onClick={handleSectionClick}>Signature Page</a></li>
          </ol>
        </nav>
      </section>
      <section className="page" id="section1" aria-labelledby="visual_and_performing_arts_section_aria_label">
        <h1 id="visual_and_performing_arts_section_aria_label">Visual and Performing Arts</h1>
        <ProgramOverviewSection
          {...sectionProps}
          nodePaths={{
            programAreaOfferings: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.which_of_the_following_does_your_school_offer_in_this_program_select_all_that_apply_program_overview',
            link: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.link_to_your_school_website_showcasing_your_arts_program_and_other_arts_extra_curricular_opportunities'
          }}
          linkLabel="Link to more information for the arts program and other extracurricular opportunities."
        />
        <CoursesOfferedSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.courses_offered"
        />
        <StaffingSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.staffing"
        />
        <ResourcesSection
          domainTitle="Visual and Performing Arts"
          {...sectionProps}
          nodePaths={{
            budgetAllocationPercent: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.what_percentage_of_the_school_budget_is_allocated_to_the_arts_program',
            professionalDevelopmentHours: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.professional_development_hours_offered_to_teachers_instructing_arts_courses',
            studentInstructionalMinutesPerWeek: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.number_of_instructional_minutes_students_recieve_per_week_in_arts'
          }}
        />
        <FacilitiesSection
          {...sectionProps}
          nodePaths={{
            equitableAccess: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.a_classroominstructional_space_for_the_school_arts_program_is_equitable_to_other_content_area_programs_facilities',
            programSpecificFacilities: 'kde.educational_opportunity.general_education_coursework.visual_and_performing_arts.b_our_school_wide_arts_program_has_access_to_the_following_facilitiesspace_facilities'
          }}
        />
      </section>
      <section className="page" id="section2" aria-labelledby="health_an_physical_education_section_aria_label">
        <h1 id="health_an_physical_education_section_aria_label">Health Education and Physical Education (Practical Living)</h1>
        <ProgramOverviewSection
          {...sectionProps}
          nodePaths={{
            programAreaOfferings: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.which_of_the_following_does_your_school_offer_in_this_program_select_all_that_apply_program_overview',
            link: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.link_to_your_school_website_showcasing_your_health_and_physical_education_program_and_other_practical_living_extra_curricular_opportunities'
          }}
          linkLabel="Link to more information for the health and physical education program and other extracurricular opportunities."
        />
        <CoursesOfferedSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.courses_offered"
        />
        <StaffingSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.staffing"
        />
        <ResourcesSection
          domainTitle="Health and Physical Education"
          {...sectionProps}
          nodePaths={{
            budgetAllocationPercent: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.school_budget_allocation_to_health_and_physical_education_program',
            professionalDevelopmentHours: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.professional_development_hours_offered_to_teachers_instructing_health_and_physical_education_courses',
            studentInstructionalMinutesPerWeek: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.number_of_instructional_minutes_students_receive_per_week_in_health_and_physical_education'
          }}
        />
        <FacilitiesSection
          {...sectionProps}
          nodePaths={{
            equitableAccess: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.a_classroominstructional_space_for_the_school_practical_living_program_is_equitable_to_other_content_area_programs_facilities',
            programSpecificFacilities: 'kde.educational_opportunity.general_education_coursework.practical_living_health_education_and_physical_education.b_our_school_wide_practical_living_program_has_access_to_the_following_facilitiesspace_facilities'
          }}
        />
      </section>

      <section className="page" id="section3" aria-labelledby="world_languages_section_aria_label">
        <h1 id="world_languages_section_aria_label">World Languages (Foreign Language)</h1>
        <ProgramOverviewSection
          {...sectionProps}
          nodePaths={{
            programAreaOfferings: 'kde.educational_opportunity.general_education_coursework.world_languages.which_of_the_following_does_your_school_offer_in_this_program_select_all_that_apply_program_overview',
            link: 'kde.educational_opportunity.general_education_coursework.world_languages.link_to_your_school_website_showcasing_your_language_programs_related_to_world_languages'
          }}
          linkLabel="Link to more information for the World Languages program and other extracurricular opportunities."
        />
        <CoursesOfferedSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.world_languages.courses_offered"
        />
        <StaffingSection
          {...sectionProps}
          nodePath="kde.educational_opportunity.general_education_coursework.world_languages.staffing"
        />
        <ResourcesSection
          domainTitle="World Languages"
          minutesPerWeekProgramName="Language Immersion"
          {...sectionProps}
          nodePaths={{
            budgetAllocationPercent: 'kde.educational_opportunity.general_education_coursework.world_languages.school_budget_allocation_to_the_world_languages_program',
            professionalDevelopmentHours: 'kde.educational_opportunity.general_education_coursework.world_languages.professional_development_hours_offered_to_teachers_instructing_world_languages_courses',
            minutesPerWeek: 'kde.educational_opportunity.general_education_coursework.world_languages.number_of_instructional_minutes_students_receive_per_week_in_world_languages',
            studentInstructionalMinutesPerWeek: 'kde.educational_opportunity.general_education_coursework.world_languages.weekly_instructional_minutes_per_content_area_in_language_of_choice'
          }}
        />
        <FacilitiesSection
          {...sectionProps}
          nodePaths={{
            equitableAccess: 'kde.educational_opportunity.general_education_coursework.world_languages.a_classroominstructional_space_for_the_school_world_languageslanguage_immersion_program_is_equitable_to_other_content_area_programs_facilities',
            programSpecificFacilities: 'kde.educational_opportunity.general_education_coursework.world_languages.b_school_wide_world_language_immersion_program_has_access_to_following_facilities'
          }}
        />
      </section>

      <section className="page" id="section4" aria-labelledby="career_and_technical_education_section_aria_label">
        <h1 id="career_and_technical_education_section_aria_label">Career and Technical Education (Career Studies)</h1>
        <ProgramOverviewSection
          {...sectionProps}
          nodePaths={{
            programAreaOfferings:
              `${careerStudiesPath}which_of_the_following_does_your_school_offer_in_this_program_select_all_that_apply_program_overview`,
            link: `${careerStudiesPath}link_to_your_school_website_showcasing_your_career_studies_program`
          }}
          linkLabel="Link to more information for the Career Studies program and other extracurricular opportunities."
        />
        <CoursesOfferedSection
          {...sectionProps}
          nodePath={`${careerStudiesPath}courses_offered`}
        />
        <StaffingSection
          {...sectionProps}
          nodePath={`${careerStudiesPath}staffing`}
        />
        <ResourcesSection
          domainTitle="Career and Technical Education"
          {...sectionProps}
          nodePaths={{
            budgetAllocationPercent: `${careerStudiesPath}school_budget_allocation_to_the_career_studies_program`,
            professionalDevelopmentHours: `${careerStudiesPath}professional_development_hours_offered_to_teachers_instructing_career_studies_courses`,
            studentInstructionalMinutesPerWeek: `${careerStudiesPath}number_of_instructional_minutes_students_receive_per_week_in_career_studies`
          }}
        />
        <FacilitiesSection
          {...sectionProps}
          nodePaths={{
            equitableAccess:
              `${careerStudiesPath}a_classroominstructional_space_for_the_cte_programs_is_equitable_to_other_content_area_programs_facilities`,
            programSpecificFacilities: `${careerStudiesPath}b_our_school_cte_programs_have_access_to_the_following_facilitiesspace_facilities`
          }}
        />
        <WorkplaceEthics
          {...sectionProps}
          nodePaths={{
            ethicsProgram: `${careerStudiesPath}school_workplace_ethics_program`,
            ethicsProgramWebsiteUrl: `${careerStudiesPath}workplace_ethics_program_website_url`,
            ethicsRequirements: `${careerStudiesPath}essential_workplace_ethics_requirements`
          }}
        />
      </section>

      <section className="page" id="section5" aria-labelledby="signatures_section_aria_label">
        <span id="signatures_section_aria_label" hidden>Signatures</span>
        <div className="grid two">
          <div className="grid-cell">
            <h2>SBDM Council</h2>
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
            <hr className="signature" />
          </div>
          <div className="grid-cell">
            <h2>Leadership</h2>
            <hr className="signature" />
            <div className="signatureLabel">Principal</div>
            <hr className="signature" />
            <div className="signatureLabel">Superintendent</div>
          </div>
        </div>

        <p className="exportDate">Data exported {format(new Date(), 'MMMM d, yyyy')}</p>
      </section>
    </div>
  );
};

ProfileReport.propTypes = {
  currentOrganization: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  scores: PropTypes.object,
  framework: PropTypes.object,
  schoolYear: PropTypes.number
};

export default connected(ProfileReport);
