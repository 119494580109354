import React from 'react';
import get from 'lodash/get';
import PropTypes from 'prop-types';

import EmptyState from 'cui/lib/components/EmptyState';
import Spinner from 'cui/lib/components/LoadingSpinner';

import template from '../../helpers/template';
import { KDE_CHART_TYPES, KDE_CHART_DATA_ADAPTERS } from '../../constants';
import GatewayChart from '../GatewayChart';
import ShowComponentWhen from '../ShowComponentWhen';

export class OrgReportCardChart extends React.Component {
  shouldComponentUpdate(nextProps) {
    return nextProps.isLoading !== this.props.isLoading ||
      nextProps.scores.length > this.props.scores.length;
  }

  render() {
    const {
      chartConfig,
      organization = {},
      scores = [],
      currentScore,
      isLoading,
      emptyText,
      additionalProps = {},
      popoverPlacement
    } = this.props;

    const { metadata, type } = chartConfig;
    const Chart = KDE_CHART_TYPES[type];
    const wideChart = get(metadata, 'wideChart', null);
    const hideBorder = get(metadata, 'hideBorder', null);
    const hideTopMargin = get(metadata, 'hideTopMargin', null);
    const size = get(metadata, 'size', null);
    const shouldDisplayChart = !isLoading && type && scores.length > 0;
    const shouldDisplayEmptyState = !isLoading && type && scores.length === 0;
    const infoText = get(metadata.info_icon, 'text', null);
    const { link } = metadata;
    const linkUrl = shouldDisplayChart && get(link, 'url') ? template(get(link, 'url'), { organization }) : null;
    const linkLabel = get(link, 'label');

    return (
      <ShowComponentWhen
        when={chartConfig.when}
        organization={organization}
        config={chartConfig}
        scores={scores}
      >
        <GatewayChart
          description={metadata.description}
          wideChart={wideChart}
          hideBorder={hideBorder}
          hideTopMargin={hideTopMargin}
          size={size}
          title={metadata.name}
          linkUrl={linkUrl}
          linkLabel={linkLabel}
          popoverText={infoText}
          popoverPlacement={popoverPlacement}
          label={`More information ${metadata.name}`}
        >
          {
            isLoading && (
              <Spinner request={new Promise(() => { })} />
            )
          }
          {
            shouldDisplayChart && (
              <Chart
                {...additionalProps}
                config={{ ...chartConfig, size }}
                organization={organization}
                scores={scores}
                currentScore={currentScore}
                additionalChartAdapters={KDE_CHART_DATA_ADAPTERS}
              />
            )
          }
          {
            shouldDisplayEmptyState && (
              <EmptyState kind="text" {...emptyText} />
            )
          }
        </GatewayChart>
      </ShowComponentWhen>
    );
  }
}

OrgReportCardChart.propTypes = {
  additionalProps: PropTypes.object,
  chartConfig: PropTypes.object,
  currentScore: PropTypes.object,
  isLoading: PropTypes.bool,
  emptyText: PropTypes.string,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  popoverPlacement: PropTypes.string
};

OrgReportCardChart.defaultProps = {
  additionalProps: {},
  chartConfig: {},
  currentScore: {},
  isLoading: null,
  emptyText: 'There is no data available.',
  organization: {},
  scores: []
};

export default OrgReportCardChart;
