import React from 'react';

import Icon from 'cui/lib/components/Icon';

const legendRenderer = (theme, item, i) => (
  <li key={i} className={`theme-${theme}`}>
    <Icon
      name="bb-chart-circle"
      className={`cui-list-icon theme-fg-${(item.colorIndex != null ? item.colorIndex : i)}`}
    />
    <span className="cui-list-text">
      <strong>
        { item.label }
      </strong>
      {item.description && <div className="legendText">{item.description}</div>}
    </span>
  </li>
);

export default legendRenderer;
