import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import cx from 'classnames';

import get from 'lodash/get';

import Heading from 'cui/lib/components/Heading';

import connected from '../connected';
import OrgContactPerson from '../OrgContactPerson';
import Sticky from '../Sticky';
import CovidBanner from '../CovidBanner';
import styles from './SuccessIndicatorHeader.module.scss';
import IndicatorBreadcrumbs from './SuccessIndicatorBreadcrumbs';

const HeaderContainer = ({ sticky, invisible, children }) => (
  <div className={cx({ container: true, [styles.componentWrap]: true, [styles.sticky]: sticky, [styles.invisible]: invisible })}>
    <div className={cx('container-constrained', styles.component)}>
      { children }
    </div>
  </div>
);

HeaderContainer.propTypes = {
  sticky: PropTypes.bool,
  invisible: PropTypes.bool,
  children: PropTypes.node
};

export const SuccessIndicatorHeader = ({ currentOrganization, covidImpactBanner }) => (
  <Sticky className={styles.stickyHeader}>
    <div>
      { covidImpactBanner  && <CovidBanner link={covidImpactBanner?.link} />}

      <HeaderContainer>
        <div className={styles.left}>
          <IndicatorBreadcrumbs />
          <Heading level={2} size="xLarge">
            {get(currentOrganization, 'name')}
          </Heading>
        </div>
        <div className={styles.right}>
          <OrgContactPerson
            showEmail={false}
            propMap={{
              currentOrganization: 'module.currentOrganization'
            }}
          />
        </div>
      </HeaderContainer>
    </div>
  </Sticky>
);

SuccessIndicatorHeader.propTypes = {
  currentOrganization: PropTypes.object,
  covidImpactBanner: PropTypes.shape({
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired
    }).isRequired
  })
};

export default withRouter(connected(SuccessIndicatorHeader));
