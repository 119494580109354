import React from 'react';

import RangeSlider from '../RangeSlider';

import KdeRangeSliderProvider from './provider';

const KdeRangeSliderChart = props => (
  <KdeRangeSliderProvider {...props}>
    {({ max, min, segmentCount, theme, title, value }) => (
      <RangeSlider
        max={max}
        min={min}
        segmentCount={segmentCount}
        theme={theme}
        title={title}
        value={value}
      />
    )}
  </KdeRangeSliderProvider>
);

export default KdeRangeSliderChart;
