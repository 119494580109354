import React from 'react';

import FlexBar from 'cui/lib/components/FlexBar';
import ScoreDot from 'cui/lib/components/ScoreDot';
import Trend from 'cui/lib/components/Trend';

import template from '../../../../helpers/template';

const legendWrapper = (legendOptions) => {
  const { boldedLabelTemplate, countLabelTemplate, labelTemplate } = legendOptions;

  const renderTrends = ({ trend }) => trend && <Trend trend={trend} />;

  const renderLabel = item => <span className="custom-label">{template(labelTemplate, item)}</span>;

  return (theme, item, i) => (
    <li key={i} className="cui-margin-top-small cui-text_base">
      <FlexBar align="top" justify="left">
        <FlexBar.Item>
          <ScoreDot className="cui-list-icon" theme={theme} value={item.colorIndex} />
        </FlexBar.Item>
        <FlexBar.Item>
          <strong>{template(boldedLabelTemplate, item)}</strong> {renderLabel(item)}  {renderTrends(item)}
          <div><em>{template(countLabelTemplate, item)}</em></div>
        </FlexBar.Item>
      </FlexBar>
    </li>
  );
};

export default legendWrapper;
