import find from 'lodash/find';

import scoresAdapter from '../scoresAdapter';

const adapter = (options) => {
  const { node, scores, frameworkKey, directScore } = options;
  const scoreValues = directScore || find(scores, { node_path: node.node_path, framework_type: frameworkKey });

  return scoresAdapter({ ...options, directScore: scoreValues });
};

export default adapter;
