import React from 'react';
import PropTypes from 'prop-types';

import { ORG_TYPES } from '../../constants';
import SocialIcons from './SocialIcons';
import styles from './OrgReportCardPage.module.scss';

const Address2022 = ({ orgType, address, orgName, websiteUrl }) => (
  <div>
    <address>
      <div className={styles.grid}>
        <div>
          {address.street}
          <br />
          {address.city}, {address.state} {address.zip}
        </div>
        <div>
            Phone: {address.phone}
          <br />
          Fax: {address.fax}
        </div>
        <div className={styles.mt1}>
          {orgType === ORG_TYPES.SCHOOL && <div>School Code: {address.schoolCode}</div>}
          {orgType === ORG_TYPES.DISTRICT && <div>District Code: {address.districtCode}</div>}
        </div>
        <div className={styles.mt1}><SocialIcons currentOrganization={orgName} websiteUrl={websiteUrl} /></div>
      </div>
    </address>
  </div>
);

Address2022.propTypes = {
  orgType: PropTypes.string,
  orgName: PropTypes.string,
  websiteUrl: PropTypes.string,
  address: PropTypes.shape({
    street: PropTypes.string,
    city: PropTypes.string,
    zip: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    schoolCode: PropTypes.string,
    districtCode: PropTypes.string
  }).isRequired
};

Address2022.defaultProps = {
  address: {}
};

export default Address2022;
