import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getUserContext } from '../../helpers/backend_context';
import buildGraphqlQuery from '../../helpers/build_graphql_query';
import getScoreLoadingIndicator from '../../helpers/getScoreLoadingIndicator';
import {
  publicScoreLoad,
  scoreLoad,
  scoreLoadFromPortalDataService,
  LOAD_CHILDREN_ORGS_ACTION,
  LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION
} from '../../helpers/scoreLoadActions';
import previousYearScoreLoad from '../../actions/previousYearScores';
import mergeScoreArrays from '../../helpers/scoreHelpers';
import { pluckNodes } from '../../helpers/nodeHelpers';
import LOAD_CURRENT_ORG_WITHOUT_CHILDREN from '../../queries/load_org_without_children';
import buildLoadFrameworkAction from '../../actions/framework';

const isPublicSite = isEmpty(getUserContext());

// eslint-disable-next-line import/prefer-default-export
export const LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS = {
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  isPublic: isPublicSite,
  query: LOAD_CURRENT_ORG_WITHOUT_CHILDREN,
  loadingIndicator: 'currentOrganization',
  propMap: {
    id: 'params.id',
    year: 'params.year'
  },
  stateObjectMap: {
    currentOrganization: {
      dataKey: isPublicSite ? 'data.organization' : 'organization',
      method(currentOrg, returnedOrgData, currentAction) {
        // Merge current year organization_data into the top level
        // so that we don't have to perform the same logics in UI components and other places
        // that use organization
        const currentYear = get(currentAction, 'actionData.year');
        const currentYearData = get(returnedOrgData, 'organization_data', [])
          .find(org => org.year === currentYear) || {};
        return {
          ...returnedOrgData,
          ...currentYearData
        };
      }
    }
  }
};

export function routeInitActions(scoreActions = []) {
  return [
    buildLoadFrameworkAction({
      actions: [
        {
          ...LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION,
          loadingIndicator: 'organizations'
        },
        {
          ...LOAD_CURRENT_ORG_WITHOUT_CHILDREN_ACTIONS,
          actions: [
            {
              type: 'PLUCK_DASHBOARD_NODE_PATHS',
              pluckNodes,
              desiredNodeType: 'data_point',
              loadingIndicator: 'currentNodes',
              propMap: {
                framework: 'framework.kde',
                featureFlags: 'module.pageContext.featureFlags',
                currentOrganization: 'module.currentOrganization'
              },
              stateObjectMap: {
                currentNodes: 'nodes',
                currentDataNodePaths: 'dataNodePaths'
              },
              actions: [
                LOAD_CHILDREN_ORGS_ACTION,
                {
                  type: 'FETCH_TOKEN',
                  service: 'svcpd',
                  slug: 'kde',
                  actions: [
                    {
                      mergePortalData: true,
                      ...scoreLoadFromPortalDataService
                    },
                    // Do we need both `scoreLoad` and `publicScoreLoad`?
                    // What difference does `isPublic` in action definition make?
                    scoreLoad,
                    publicScoreLoad,
                    previousYearScoreLoad,
                    ...scoreActions
                  ]
                }
              ]
            }
          ]
        }
      ]
    })
  ];
}

const publicScoreLoadAction = (props = {}) => {
  const action = {
    ...publicScoreLoad,
    stateObjectMap: {
      scoresFromGroot: {
        dataKey: 'data.scores',
        method(currentScores, newScores) {
          return mergeScoreArrays(currentScores, newScores);
        }
      }
    }
  };

  action.query = buildGraphqlQuery(publicScoreLoad.query, { currentOrganizationId: get(props, 'currentOrganization.id') });
  return action;
};

export const scoreLoadAction = ({ currentOrganization, additionalActions = [], ...props }) => ({
  ...props,
  type: 'GRAPH_LOAD',
  service: 'svcpd',
  isPublic: isPublicSite,
  query: LOAD_CURRENT_ORG_WITHOUT_CHILDREN,
  loadingIndicator: 'currentOrganization',
  stateObjectMap: {
    organizations: {
      dataKey: isPublicSite ? 'data.organization' : 'organization',
      method(organizations = [], org, currentAction) {
        // Replace organization with the one just fetched with full data
        const year = get(currentAction, 'actionData.year');
        const currentYearData = get(org, 'organization_data', []).find(data => data.year === year);
        const filteredOrgs = organizations.filter(organization => organization.id !== org.id);
        filteredOrgs.push({ ...org, ...currentYearData, isFullOrg: true, orgIsLoaded: true });

        return filteredOrgs;
      }
    }
  },
  propsForNextActions: {
    currentOrganization: 'currentGraphData.data.organization'
  },
  id: get(currentOrganization, 'id'),
  propMap: {
    year: 'params.year'
  },
  actions: [
    {
      ...props,
      ...publicScoreLoadAction({ currentOrganization, ...props }),
      loadingIndicator: getScoreLoadingIndicator('groot', currentOrganization.id)
    },
    {
      ...props,
      ...scoreLoadFromPortalDataService,
      loadingIndicator: props.loadingIndicator || getScoreLoadingIndicator('svcpd', currentOrganization.id)
    },
    ...additionalActions
  ]
});
