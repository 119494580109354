export default `
  query {
    organizations(years: [:year], data_points: :data_points) {
      id
      name
      address
      city
      zipcode
    }
  }
`;
