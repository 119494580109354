import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames';
import get from 'lodash/get';
import { isApprovalSite } from '../../helpers/approvalSite';

import Link from '../Link';
import styles from './index.module.scss';

function Footer({ schoolYear, className }) {
  const showDataset = schoolYear >= 2020 && !(([2021, 2022, 2023].includes(schoolYear)) && isApprovalSite());
  return (
    <div className={cx('container', styles.footer, className)}>
      <div className="container-constrained">
        <footer className={styles.footerInner}>
          <div>
            <a
              href="https://education.ky.gov/Pages/default.aspx"
              className={styles.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.logo}
                src="//bb-production-assets.s3.amazonaws.com/kde/kde-logo-footer-white.svg"
                alt="Kentucky Department of Education Logo"
              />
            </a>
          </div>
          <nav>
            <ul className={styles.linkList}>
              {showDataset && <li><Link to="/datasets" target="_blank" rel="noopener">Data Sets</Link></li>}
              <li><Link to="/glossary" target="_blank" rel="noopener">Glossary</Link></li>
              <li><Link to="/other-data" target="_blank" rel="noopener">Other Education Data</Link></li>
              <li><Link to="/privacy" target="_blank" rel="noopener">Privacy</Link></li>
              <li><Link to="/disclaimer" target="_blank" rel="noopener">Disclaimer</Link></li>
              <li><Link to="/help" target="_blank" rel="noopener">Help</Link></li>
              {schoolYear >= 2019 && <li><a href="https://www.surveymonkey.com/r/KYSchoolReportCardSurvey" target="_blank" rel="noopener noreferrer">Provide Feedback</a></li>}
            </ul>
          </nav>
        </footer>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  schoolYear: get(state, 'module.schoolYear')
});

Footer.propTypes = {
  schoolYear: PropTypes.number,
  className: PropTypes.string
};

export default connect(mapStateToProps)(Footer);
