import cn from 'classnames';
import Icon from 'cui/lib/components/Icon';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';

const RangeSlider = ({ max, min, segmentCount, theme, title, value, precision }) => {
  if (value < min || value > max) {
    // eslint-disable-next-line no-console
    console.warn(`Value must be less then ${max} and more then ${min}. "${value}" does not meet these requirements.`);
    return null;
  }

  const thumbPercentagePosition = (value / max) * 100;
  const thumbColorIndex = Math.ceil(thumbPercentagePosition / (100 / segmentCount)) - 1;
  const thumbClassNames = cn({
    [styles.thumb]: true,
    [styles[`thumb-fg-${thumbColorIndex}`]]: true,
    [`theme-${theme}-fg-${thumbColorIndex}`]: theme
  });

  return (
    <div className={styles.rangeSlider}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={styles.wrapper}>
        <div className={styles.slider}>
          <div className={styles.thumbRail}>
            <div
              className={thumbClassNames}
              style={{
                left: `${thumbPercentagePosition}%`
              }}
            >
              <Icon name="bb-chart-triangle" size="small" />
            </div>
          </div>
          <div className={styles.segments}>
            {
              Array.from({ length: segmentCount }).map((item, index) => {
                const classNames = cn({
                  [styles.segment]: true,
                  [styles[`segment-bg-${index}`]]: true,
                  [`theme-${theme}-bg-${index}`]: theme
                });
                return (
                  <div key={index} className={classNames} />
                );
              })
            }
          </div>
        </div>
        <div className={styles.label}>
          <span className={styles.value}>{value.toFixed(precision)}</span> / {max}
        </div>
      </div>
    </div>
  );
};

RangeSlider.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  segmentCount: PropTypes.number,
  theme: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.number,
  precision: PropTypes.number.isRequired
};

RangeSlider.defaultProps = {
  min: 1,
  max: 100,
  segmentCount: 5,
  theme: null,
  title: null,
  value: null,
  precision: 1
};

export default RangeSlider;
