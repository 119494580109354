import get from 'lodash/get';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import castArray from 'lodash/castArray';

import { currentOrgYearScores } from '../../../scoresHelpers';

const adapter = (options) => {
  const { node, entities, entityScores, activeItems, baseUrl, config = {}, organization, scores } = options;
  const { convertToPercent = true, sortByProperty, addParentOrgScore = false } = config;
  const visibleItems = activeItems || Object.keys(node.scoring_options.shape);
  const data = entities && reduce(entities, (acc, entity) => {
    const score = currentOrgYearScores(entityScores, entity, node);
    const total = reduce(Object.keys(node.scoring_options.shape), (sum, key) => (
      sum + get(score, `value.${key}`, 0)
    ), 0);
    if (total !== 0) {
      const visibleTotal = reduce(visibleItems, (sum, key) => (
        sum + get(score, `value.${key}`, 0)
      ), 0);

      const unselected = {
        colorIndex: 4,
        hidden: true,
        value: ((total - visibleTotal) / total) * 100
      };

      const value = sortBy(map(visibleItems, key => ({
        ...node.scoring_options.shape[key],
        value: convertToPercent ? ((get(score, `value.${key}`, 0) / total) * 100) : get(score, `value.${key}`, 0)
      })), ['index']);

      if (visibleTotal < total) {
        value.push(unselected);
      }

      acc.push({
        label: entity.name,
        key: entity.id,
        value,
        organization: entity,
        baseUrl
      });
    }

    return acc;
  }, []);

  const sortedData = sortByProperty ? sortBy(data, castArray(sortByProperty)) : data;

  if (addParentOrgScore) {
    const parentScore = currentOrgYearScores(scores, organization, node);

    const total = reduce(Object.keys(node.scoring_options.shape), (sum, key) => (
      sum + get(parentScore, `value.${key}`, 0)
    ), 0);

    const visibleTotal = reduce(visibleItems, (sum, key) => (
      sum + get(parentScore, `value.${key}`, 0)
    ), 0);

    const unselected = {
      colorIndex: 4,
      hidden: true,
      value: ((total - visibleTotal) / total) * 100
    };

    const parentScoreValue = sortBy(map(visibleItems, key => ({
      ...node.scoring_options.shape[key],
      value: convertToPercent ? ((get(parentScore, `value.${key}`, 0) / total) * 100) : get(parentScore, `value.${key}`, 0)
    })), ['index']);

    if (visibleTotal < total) {
      parentScoreValue.push(unselected);
    }

    sortedData.unshift(
      {
        label: organization.name,
        key: organization.id,
        value: parentScoreValue,
        organization
      }
    );
  }

  return sortedData;
};

export default adapter;
