import map from 'lodash/map';

import template from '../../../template';

const adapter = (options) => {
  const { config, legendPlacement, shape, scores, customLegendRenderers, customLegendRenderOptions, theme } = options;
  // There should be only one object in the array.
  const score = scores[0];
  const objKeys = Object.keys(score).filter(item => item !== 'student_count');
  const chartData = objKeys.map(key => (
    {
      ...(shape[key]),
      value: Math.round(score.student_count * score[key]),
      percent: Math.round(score[key] * 100),
      count: score.student_count,
      colorIndex: score.colorIndex || shape[key].colorIndex || shape[key].index,
      label: score.label || shape[key].label,
      description: score.description || shape[key].description
    }
  ));
  const LEGEND_THEME_DEFAULT = 'default';
  const LEGEND_PLACEMENT_DEFAULT = 'right';
  const WIDTH_DEFAULT = 200;
  const legendData = map(chartData, datum => (
    {
      ...datum,
      colorIndex: datum.colorIndex,
      label: template(datum.label, { ...datum, ...options }),
      description: template(datum.description, { ...datum, ...options })
    }
  ));

  const legendProps = {
    data: legendData,
    transparent: true
  };

  if (customLegendRenderOptions) {
    const lengendRenderer = customLegendRenderers[customLegendRenderOptions.type];
    if (lengendRenderer) {
      legendProps.renderLegendItem = lengendRenderer(customLegendRenderOptions);
    }
  }

  const chartDatum = {
    legendPlacement: legendPlacement || LEGEND_PLACEMENT_DEFAULT,
    theme: theme || config.theme || LEGEND_THEME_DEFAULT,
    chartProps: {
      data: chartData,
      width: config.width || WIDTH_DEFAULT
    },
    legendProps
  };

  return chartDatum;
};

export default adapter;
