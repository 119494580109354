import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { createStructuredSelector } from 'reselect';

import Dropdown from 'cui/lib/components/Dropdown';
import Button from 'cui/lib/components/Button';
import Icon from 'cui/lib/components/Icon';

import { levelsForSchool, levelsToScoreKeys } from '../../helpers/orgHelper';
import { reportData } from '../../helpers/reportHelpers';
import { isA1School } from '../../helpers/orgCanAccessCollectorDataPoint';
import {
  makeSelectScoresData,
  makePreviousYearsDataSelector,
  selectPageContext
} from '../../selectors/moduleSelector';
import { makeSelectFramework } from '../../selectors/frameworkSelector';
import PDFDownload from '../../helpers/PDFDownload';
import SchoolReport from '../../pages/SchoolReport';

import styles from './index.module.scss';

const pdfDownloader = new PDFDownload(`${HOSTS.PDF_GENERATOR}/generate`);

const REPORT_NAMES = {
  elementary_school: 'Elementary School',
  middle_school: 'Middle School',
  high_school: 'High School'
};

function reportTemplate(pdfContent, cssContent) {
  return `
    <html lang="en">
      <head>
        <meta charset="utf-8" />
        <style>
          ${cssContent}
          @media print {
            .page {
              min-height: 9.4in;
              page-break-before: always;
              break-before: page;
            }
          }
          
          @page {
            size: A4;
            margin: 0;
          }
        </style>
        <title>KDE School Profile Report</title>
      </head>
      <body>
        ${pdfContent}
      </body>
    </html>
  `;
}

function ReportButton({ onClick, text }) {
  return (
    <Button className="cui-dropdown-menu-link cui-flexbar" onClick={onClick}>
      <span className="cui-flexbar-item">
        <Icon name="bb-download" />
      </span>
      <span className="cui-flexbar-item cui-flexbar-item_grow">
        {text}
      </span>
    </Button>
  );
}

ReportButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string
};

function ViewPageLink({ href, text }) {
  return (
    <a className="cui-dropdown-menu-link cui-flexbar" href={href} target="_blank" rel="noopener noreferrer">
      <span className="cui-flexbar-item">
        <Icon name="bb-arrow-box-top" />
      </span>
      <span className="cui-flexbar-item cui-flexbar-item_grow">
        {text}
      </span>
    </a>
  );
}

ViewPageLink.propTypes = {
  href: PropTypes.string,
  text: PropTypes.string
};

function dropdownItem(item) {
  return item.onClick ? <ReportButton {...item} /> : <ViewPageLink {...item} />;
}

function menuItem({ item }) {
  if (item.heading) {
    return (
      <li className="cui-dropdown-menu-group">
        <span className="cui-dropdown-menu-group-label">{item.heading}</span>
        <ul>
          {
            item.items.map(dropdownItem)
          }
        </ul>
      </li>
    );
  }

  return (
    <li>
      {dropdownItem(item)}
    </li>
  );
}

menuItem.propTypes = {
  item: PropTypes.shape({
    onClick: PropTypes.func,
    text: PropTypes.string
  })
};

class SchoolReportDropdown extends PureComponent {
  downloadReport(level) {
    const { currentOrganization, scoreData: { scores }, framework, schoolYear, pageContext, previousYearScore } = this.props;
    const reportEl = (
      <SchoolReport
        {...reportData(currentOrganization, scores, previousYearScore, framework, schoolYear, level)}
      />
    );

    pdfDownloader.downloadWithSvg(
      reportEl,
      null,
      get(pageContext, 'jwtToken'),
      `${schoolYear} School Report -- ${currentOrganization.name}`, // Question, what filename to use ?
      true,
      reportTemplate
    );
  }

  render() {
    const { currentOrganization, schoolYear, className, previousYearScore, scoreData } = this.props;

    // Hide the download button when an org doesn't have star rating/accountability data.
    // Currently only A1 schools have those data
    // Revisit this logics if things change
    if (!isA1School(currentOrganization)) {
      return null;
    }

    const attributes = {
      disabled: isEmpty(currentOrganization) || isEmpty(previousYearScore) || isEmpty(scoreData.scores)
    };
    /* eslint-disable react/jsx-no-bind */
    const schoolLevels = levelsToScoreKeys(levelsForSchool(currentOrganization, schoolYear));

    if (schoolLevels.length === 0) {
      return null;
    }

    let items;

    if (schoolLevels.length === 1) {
      const level = schoolLevels[0];

      items = [
        {
          text: 'Download PDF',
          level,
          onClick: this.downloadReport.bind(this, level)
        },
        {
          text: 'View Web Version (accessible)',
          level,
          href: `/organization/${currentOrganization.id}/school_summary_report/${level}?year=${schoolYear}`
        }
      ];
    } else {
      items = schoolLevels.map(level => ({
        heading: REPORT_NAMES[level],
        items: [
          {
            text: 'Download PDF',
            level,
            onClick: this.downloadReport.bind(this, level)
          },
          {
            text: 'View Web Version (accessible)',
            level,
            href: `/organization/${currentOrganization.id}/school_summary_report/${level}?year=${schoolYear}`
          }
        ]
      }));
    }
    /* eslint-enable react/jsx-no-bind */

    return (
      <Dropdown
        items={items}
        trigger="click"
        appendToBody={false}
        size="small"
        className={className}
        menuItem={menuItem}
      >
        <Button type="button" className={styles.downloadButton} attributes={attributes}>Download Summary Report</Button>
      </Dropdown>
    );
  }
}

SchoolReportDropdown.propTypes = {
  loadPreviousYearScores: PropTypes.func,
  currentOrganization: PropTypes.object,
  schoolYear: PropTypes.number,
  className: PropTypes.string,
  scoreData: PropTypes.object,
  framework: PropTypes.object,
  previousYearScore: PropTypes.arrayOf(PropTypes.object),
  pageContext: PropTypes.object
};

const mapStateToProps = createStructuredSelector({
  scoreData: makeSelectScoresData(),
  previousYearScore: makePreviousYearsDataSelector(),
  framework: makeSelectFramework(),
  pageContext: selectPageContext
});

export default connect(mapStateToProps)(SchoolReportDropdown);
