/* eslint no-undef: off */
/* eslint no-unused-vars: off */
import JWTAuthTransport from 'lokka-transport-jwt-auth';
import get from 'lodash/get';

import { getPageContext, getUserContext } from '../../helpers/backend_context';
import request from '../../helpers/request';
import { FEATURE_FLAGS } from './queries/featureFlags';

export const ENDPOINTS = {
  clarity: `${HOSTS.CLARITY}/api/graphql`,
  publicClarity: `${HOSTS.PUBLIC_CLARITY}/api/graphql`,
  groot: `${HOSTS.GROOT}/graphql`,
  fin: `${HOSTS.FINAPI}/graphql`,
  insights: `${HOSTS.INSIGHTS}/graphql`,
  bender: `${HOSTS.BENDER}/api/v1/graphql`,
  svcpd: `${HOSTS.PORTAL_DATA}/graphql`
};

function parseData(data) {
  return data.token;
}

// Return either HOSTS.CLARITY or, if the current browser hostname is a
// subdomain of the one in HOSTS.CLARITY, then that one.
function getSameOriginClarityHostname() {
  const currentProtocol = window.location.protocol;
  const currentHost = window.location.host;
  const clarityHost = HOSTS.CLARITY.split('/')[2];
  // Use lastIndexOf shenanigans instead of endsWith because we want to
  // maintain some semblance of IE compatibility
  if (
    currentHost === clarityHost ||
    (
      currentHost.lastIndexOf(`.${clarityHost}`) >= 0 &&
      currentHost.lastIndexOf(`.${clarityHost}`) === currentHost.length - `.${clarityHost}`.length
    )
  ) {
    return `${currentProtocol}//${currentHost}`;
  } else {
    return HOSTS.CLARITY;
  }
}

const userContext = getUserContext();

const gitHashParam = `git_hash=${get(TOKEN_DEBUG_PARAMS, 'git_hash')}`;
const lokkaVersionParam = `lokka_version=${get(TOKEN_DEBUG_PARAMS, 'lokka_version')}`;
const currentUserIdParam = `user_id=${encodeURIComponent(btoa(JSON.stringify(get(userContext, 'currentUserId'))))}`;
const currentOrgIdParam = `organization_id=${encodeURIComponent(btoa(JSON.stringify(get(userContext, 'currentOrganization.id'))))}`;
const currentModuleParam = `module_slug=${encodeURIComponent(btoa(JSON.stringify(get(userContext, 'currentModule.slug'))))}`;

const TOKEN_TYPES = ['bender', 'clarity', 'groot', 'insights', 'fin', 'pdf_generator', 'svcpd'];
// token types for which we fetch the token from the clarity host we're being
// served from instead of HOSTS.CLARITY. ideally this would be all of them, but
// for now this is limited to ones that only affect KDE until testing can
// happen on other modules.
const SAME_ORIGIN_TOKEN_TYPES = ['fin', 'svcpd'];
const FRAMEWORKS = ['cradle_to_career', 'sbcss_odp', 'kde'];
const INCLUDE_CSRF = ['clarity'];

export function getJwtTokenFromClarity(tokenType = '') {
  let clarityHostname = HOSTS.CLARITY;
  if (SAME_ORIGIN_TOKEN_TYPES.includes(tokenType)) {
    clarityHostname = getSameOriginClarityHostname();
  }
  return () => {
    let params = '';
    if (TOKEN_TYPES.includes(tokenType)) {
      params = `?token_type=${tokenType}&${gitHashParam}&${lokkaVersionParam}&${currentUserIdParam}&${currentOrgIdParam}&${currentModuleParam}`;
    }
    const url = `${clarityHostname}/api/v1/user/token${params}`;
    const tokenFetchPromise = request(url, { parseData });
    return tokenFetchPromise;
  };
}

export function getPublicJwtTokenFromClarity(tokenTypes = [], frameworks = FRAMEWORKS) {
  return () => {
    const tokenTypeParam = tokenTypes.map(t => (TOKEN_TYPES.includes(t) ? `token_type[]=${t}` : ''));
    const frameworksParam = frameworks.map(f => `frameworks[]=${f}`);
    const params = [
      ...tokenTypeParam,
      ...frameworksParam,
      gitHashParam,
      lokkaVersionParam,
      currentUserIdParam,
      currentOrgIdParam,
      currentModuleParam].join('&');

    return fetchClarityAuth()
      .then(({ access_token: token }) => request(`${HOSTS.PUBLIC_CLARITY}/api/v1/token?${params}`, {
        headers: { Authorization: `Bearer ${token}` },
        parseData: data => data.token
      }));
  };
}

function fetchClarityAuth() {
  const oauthPayload = JSON.stringify({
    client_id: OAUTH.CLIENT_ID,
    client_secret: OAUTH.CLIENT_SECRET,
    grant_type: 'client_credentials',
    redirect_uri: window.location.origin
  });

  return request(`${HOSTS.PUBLIC_CLARITY}/oauth/token`, {
    method: 'POST',
    body: oauthPayload
  });
}

const transports = {};

function transport(service, isPublic) {
  let headers = {};
  if (INCLUDE_CSRF.includes(service)) {
    headers = { 'X-CSRF-Token': getPageContext().csrfToken };
  }
  if (!transports[service]) {
    if (service === 'publicClarity' || !!isPublic) {
      transports[service] = new JWTAuthTransport(
        ENDPOINTS[service],
        { headers },
        getPublicJwtTokenFromClarity(service)
      );
    } else {
      transports[service] = new JWTAuthTransport(
        ENDPOINTS[service],
        { headers },
        getJwtTokenFromClarity(service)
      );
    }
  }
  return transports[service];
}

export function graphDataFetch(service, query, variables = {}, options = {}) {
  if (service === 'feature_flags') {
    return () => Promise.resolve({
      data: {
        feature_flags: FEATURE_FLAGS
      }
    });
  }

  const { isPublic, jwtToken } = options;
  const headers = {
    'content-type': 'application/json'
  };

  if (!['clarity', 'publicClarity'].includes(service)) {
    headers.authorization = `Bearer ${jwtToken}`;
  }

  const credentials = service === 'svcpd' ? 'omit' : 'include';

  if (isPublic) {
    return () => request(ENDPOINTS[service], {
      method: 'POST',
      headers,
      body: JSON.stringify({ query, variables }),
      credentials
    });
  } else {
    return () => transport(service).send(query, variables);
  }
}

export const portalDataFetch = (query, jwtToken) => () => request(`${HOSTS.PORTAL_DATA}/scores`, {
  method: 'POST',
  credentials: 'omit',
  headers: {
    // TODO: jwtToken is an object like { error: ... } when the user isn't
    // authenticated. Detect this in the saga where we fetch tokens instead of
    // here and do something more intelligent, like maybe set the token to
    // null in the redux store.
    ...((jwtToken && (typeof jwtToken === 'string')) ? { authorization: `Bearer ${jwtToken}` } : undefined),
    'content-type': 'application/json'
  },
  body: JSON.stringify(query)
});

export function orgDataFetch(id, addOnSlug = 'c2c') {
  const query = `{
    organization(id: ${id}) {
      id
      name
      entity_type
      organization_add_on(add_on_slug: "${addOnSlug}") {
        full_data
      }
      entity {
        ... on School {
          name
          address
          city
          state
          zip
          lat_centroid
          long_centroid
          student_count
          lowest_grade
          highest_grade
          flag_charter
          setting
          free_lunch_count
          reduced_count
        }
        ... on District {
          name
          address
          city
          state
          zip
          lat_centroid
          long_centroid
          student_count
          enabled_school_count
          setting
          free_lunch_count
          reduced_count
        }
        ... on ESA {
          name
          address
          city
          state
          zip
          lat_centroid
          long_centroid
        }
      }
      children {
        id
        name
        entity_type
        entity {
          ... on School {
            name
            address
            city
            state
            zip
            lat_centroid
            long_centroid
            flag_charter
            student_count
          }
          ... on District {
            student_count
            lat_centroid
            long_centroid
          }
        }
      }
      default_district_organization {
        id
        name
      }
    }
  }`;

  return () => request(`${HOSTS.PUBLIC_CLARITY}/api/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query })
  });
}

export function fetchOrganizations(parentId) {
  const query = `{
    organization(id: ${parentId}) {
      id
      name
      entity_type
      entity {
        ... on ESA {
          id
          address
          city
          state
          zip
          lat_centroid
          long_centroid
        }
      }
      children {
        id name entity_type
        entity {
          ... on School {
            id
            address
            city
            state
            zip
            lat_centroid
            long_centroid
            student_count
            lat_centroid
            long_centroid
            address
            city
            state
            zip
          }
          ... on District {
            id
            address
            city
            state
            zip
            lat_centroid
            long_centroid
            student_count
            lat_centroid
            long_centroid
            address
            city
            state
            zip
          }
        }
      }
    }
  }`;

  return () => request(`${HOSTS.PUBLIC_CLARITY}/api/graphql`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ query })
  });
}
