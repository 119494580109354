import get from 'lodash/get';
import reduce from 'lodash/reduce';
import forOwn from 'lodash/forOwn';

export default function adapter({ currentOrgScore, chartConfig }) {
  return reduce(get(currentOrgScore, 'dimensions', {}), (acc, rowScore, rowKey) => {
    const extractKeys = get(chartConfig, 'extractKeys');
    let score = rowScore;

    if (extractKeys) {
      forOwn(extractKeys, (value, key) => {
        if (value.includes(rowKey)) score = get(score, `dimensions.${key}`);
      });
    }

    acc[rowKey] = chartConfig.columns_are_dimensions ? reduce(get(score, 'dimensions', {}), (memo, colValue, colKey) => {
      memo[colKey] = get(colValue, 'score');
      return memo;
    }, {}) : get(score, 'score');
    return acc;
  }, {});
}
