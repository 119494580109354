import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import memoize from 'lodash/memoize';
import startCase from 'lodash/startCase';
import filter from 'lodash/filter';

import OrganizationSelect from './OrganizationSelect';
import { compareScoresLoadFromPortalDataService } from '../../helpers/scoreLoadActions';
import { isDistrict, isSchool } from '../../helpers/completionStatusHelpers/collection/utils';
import { getOrgIdFromOrganization } from '../../helpers/portalDataServiceHelpers';
import connected from '../connected';

class CompareChart extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      comparisonOrgs: []
    };
  }

  setComparisonOrgs = (comparisonOrgs) => {
    this.setState({ comparisonOrgs }, this.fetchScores);
  }

  comparableOrgs(organization, organizations) {
    let organizationsToCompare = [];
    if (isDistrict(organization)) {
      organizationsToCompare = organizations.filter(org => isDistrict(org));
    } else if (isSchool(organization)) {
      organizationsToCompare = organizations.filter(org => (isSchool(org) && org.dist_number === organization.dist_number));
    }
    return organizationsToCompare;
  }

  scoresForOrg = organization => this.props.compareScores.filter(score => (score.portal_org_id === getOrgIdFromOrganization(organization)))

  fetchScores = () => {
    const { comparisonOrgs: orgs } = this.state;

    const orgsWithoutScores = filter(orgs, org => isEmpty(this.scoresForOrg(org)));
    orgsWithoutScores.forEach(org => this.props.loadScoreForOrg(org, [this.props.node]));
  }

  render() {
    const {
      organizations,
      organization,
      currentOrgName
    } = this.props;

    const comparableOrganizations = this.comparableOrgs(organization, organizations);
    return (
      <div>
        <OrganizationSelect
          organizations={comparableOrganizations}
          selectedOrganizations={this.state.comparisonOrgs}
          onChange={this.setComparisonOrgs}
        />
        { currentOrgName }
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  loadScoreForOrg: memoize(
    (currentOrganization, currentNodes) => dispatch(
      {
        ...compareScoresLoadFromPortalDataService,
        currentOrganization,
        currentNodes,
        skipParentOrgsLoading: true
      }
    ),
    ({ id }) => id
  )
});

const mapStateToProps = (state, { scores, node }) => {
  const compareScores = filter(
    get(state, 'module.compareScores', {}), {
      framework_tree_node: { node_path: node.node_path }
    });

  return ({
    currentOrgName: startCase(get(state, 'module.currentOrganization.name', '').toLowerCase()),
    organizations: get(state, 'module.organizations', []),
    compareScores: [...scores, ...compareScores]
  });
};

const organizationShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  entity_type: PropTypes.string,
  entityType: PropTypes.string
});
const scoreShape = PropTypes.shape({
  remote_organization_id: PropTypes.number.isRequired,
  value: PropTypes.object.isRequired
});
CompareChart.propTypes = {
  organization: organizationShape,
  organizations: PropTypes.arrayOf(organizationShape),
  currentOrgName: PropTypes.string,
  // Score data
  scores: PropTypes.arrayOf(scoreShape),
  compareScores: PropTypes.arrayOf(scoreShape),
  node: PropTypes.object, // Framework Tree Node
  config: PropTypes.object, // Chart Config object
  loadScoreForOrg: PropTypes.func
};

CompareChart.defaultProps = {
  organization: {
    id: null
  },
  scores: [],
  config: {}
};

export default connect(mapStateToProps, mapDispatchToProps)(connected(CompareChart));
