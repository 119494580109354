import get from 'lodash/get';
import map from 'lodash/map';

import { transformData } from '../../../graphHelpers';

const adapter = (options) => {
  const { node, currentOrgScore } = options;
  const data = currentOrgScore && map(currentOrgScore.value, (value, key) => (
    {
      label: get(node, `scoring_options.shape.${key}.label`, key),
      key,
      value: get(value, 'value', value), // gets nested values, as in { value: { value: 3 } }, used to include additional data per key
      colorIndex: get(node, `scoring_options.shape.${key}.colorIndex`, 0),
      confidenceInterval: get(value, 'confidenceInterval', undefined),
      index: get(node, `scoring_options.shape.${key}.index`, null)
    }
  ));

  return transformData(data, node.scoring_options);
};

export default adapter;
