import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import uniq from 'lodash/uniq';

const conditionalLoader = (options) => {
  const { loadingObject = {}, doNotShowLoadingFor = [], showLoadingFor = [] } = options;
  const noSourceMatchingArguments = isEmpty(showLoadingFor) && isEmpty(doNotShowLoadingFor);
  const { sources = [], isLoading } = loadingObject;
  const uniqSources = uniq(sources);

  // no sources or inclusions/exceptions means that loading is most likely done
  // but to be safe we return isLoading incase the source was never set
  if (noSourceMatchingArguments || isEmpty(sources)) {
    return isLoading;
  }

  // if these contain the same items, a mistake was made and default to isLoading
  if (isEqual(doNotShowLoadingFor, showLoadingFor)) {
    return isLoading;
  }

  // dont show loading icon if doNotShowLoaderWhen is === true
  const doNotShowLoaderWhen = isEqual(doNotShowLoadingFor, uniqSources);

  // show loading icon if showLoaderWhen is === true
  const showLoaderWhen = isEqual(showLoadingFor, uniqSources);

  // if doNotShowLoadingFor is equal to sources, don't show the loading icons
  if (doNotShowLoaderWhen) { return !doNotShowLoaderWhen; }

  // if showLoadingFor is equal to sources, show the loading icon
  if (showLoaderWhen) { return showLoaderWhen; }

  // if nothing matches default to isLoading
  return isLoading;
};

export default conditionalLoader;
