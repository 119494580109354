import React from 'react';
import PropTypes from 'prop-types';
import memoize from 'lodash/memoize';
import get from 'lodash/get';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import queryString from 'query-string';
import cx from 'classnames';

import FlexBar from 'cui/lib/components/FlexBar';
import Button from 'cui/lib/components/Button';
import Label from 'cui/lib/components/Label';

import MultiSelect from '../MultiSelect';
import buildGraphqlQuery from '../../helpers/build_graphql_query';
import localeCompare from '../../helpers/localeCompare';
import { graphDataFetch } from '../../store/backend';
import styles from './index.module.scss';
import loadOrganizationsQuery from './loadOrganizationsQuery';

const buildAutocompleteOptions = memoize((organizations, organizationId) => {
  if (!organizations) return [];

  return organizations
    .filter(({ id }) => organizationId !== id)
    .map(({ id, name, address = '', zipcode = '', city = '' }) => ({
      label: name,
      value: id,
      address,
      city,
      zipcode
    }))
    .sort(({ label: a }, { label: b }) => localeCompare(a, b));
});

const parseOrgIdsFromSearch = (search) => {
  const { orgIds } = queryString.parse(search, { arrayFormat: 'bracket' });
  return (orgIds || []).map(orgId => Number(orgId));
};

class CompareMultiSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedOrgIds: parseOrgIdsFromSearch(props.location.search),
      organizations: []
    };
  }

  componentDidMount() {
    const { currentVariable, year, organizationId, jwtToken } = this.props;
    const slugs = currentVariable.items.map(item => get(item, 'portal_data.slug')).filter(slug => slug);

    const query = buildGraphqlQuery(loadOrganizationsQuery, {
      year,
      data_points: slugs,
      stringifyPropMapValue: { data_points: true }
    });
    graphDataFetch('svcpd', query, {}, { isPublic: true, jwtToken })().then(({ data }) => {
      this.setState({
        organizations: buildAutocompleteOptions(get(data, 'organizations'), organizationId)
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.setState({ selectedOrgIds: parseOrgIdsFromSearch(nextProps.location.search) });
    }
  }

  onSubmit = () => {
    this.props.onSubmit(this.state.selectedOrgIds, parseOrgIdsFromSearch(this.props.location.search));
  };

  onClearAll = () => {
    this.props.onClearAll();
    this.setState({ selectedOrgIds: [] });
  }

  handleChange = (selectedOrgIds) => {
    this.setState({ selectedOrgIds });
  };

  renderOptionItem({ label, address, city, zipcode }) {
    return (
      <div>
        <div className="cui-autocomplete-item-label">{label}</div>
        <div className="cui-autocomplete-item-details">{address}, {city}, KY {zipcode}</div>
      </div>
    );
  }

  render() {
    const { buttonAttrs } = this.props;
    const { selectedOrgIds, organizations } = this.state;

    if (!organizations.length) return null;

    const isMaxValueCountReached = selectedOrgIds.length === 4;

    return (
      <div>
        <FlexBar className={cx('cui-margin-bottom-small', styles.compareMultiSelectForm)}>
          <FlexBar.Item grow>
            <Label
              value="Compare Schools and Districts"
              sublabel="Select up to 4 max, then click Compare. Applies ONLY to Overview and Trends, not Tables."
            />
            <MultiSelect
              id="compareMultiSelect"
              value={selectedOrgIds}
              options={organizations}
              onChange={this.handleChange}
              placeholder="Select a School or District to Compare."
              noResultsText={isMaxValueCountReached ? 'Max of 4 reached' : undefined}
              filterOptions={isMaxValueCountReached ? () => [] : true}
              optionRenderer={this.renderOptionItem}
              aria-label="Select a School or District to Compare."
              onClearAll={this.onClearAll}
              className={cx('cui-input cui-input_stroked', styles.multiSelect)}
            />
          </FlexBar.Item>
          <FlexBar.Item>
            <Button
              title="Compare"
              kind="solid"
              onClick={this.onSubmit}
              className={cx('cui-margin-none', styles.compareBtn)}
              attributes={buttonAttrs}
            >
              Compare
            </Button>
          </FlexBar.Item>
        </FlexBar>
      </div>
    );
  }
}

CompareMultiSelect.propTypes = {
  currentVariable: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.shape({
      portal_data: PropTypes.shape({
        slug: PropTypes.string
      })
    })).isRequired
  }),
  year: PropTypes.number.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onClearAll: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  buttonAttrs: PropTypes.shape({
    'aria-label': PropTypes.string
  }),
  jwtToken: PropTypes.string.isRequired
};

const mapStateToProps = createStructuredSelector({
  organizationId: state => Number(get(state, 'params.id')),
  jwtToken: state => get(state, 'module.pageContext.jwtToken')
});

export default withRouter(connect(mapStateToProps)(CompareMultiSelect));
