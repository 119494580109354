import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';

import template from '../../../template';

const adapter = (options) => {
  const { node, scores } = options;
  const scoringOptions = get(node, 'scoring_options', {});
  const shape = get(scoringOptions, 'shape', []);
  const data = scores && map(scores, (score, index) => {
    const key = index;
    const bucket = score[scoringOptions.shapeKey];
    const values = map(score.details, item => (
      {
        value: item.percent,
        colorIndex: scoringOptions.labelMap[item.name].colorIndex,
        student_count: `${item.student_count.toLocaleString()} Students`,
        name: item.name,
        label: scoringOptions.labelMap[item.name].label,
        description: '{{student_count.toLocaleString()}} Students',
        legendLabel: `${startCase(item.name)}`
      }
    ));
    return ({
      label: template(scoringOptions.labelTemplate, { value: '', ...score }),
      key,
      value: values,
      colorIndex: get(shape, `${key}.colorIndex`, 0),
      confidenceInterval: score.confidenceInterval,
      index: get(shape[bucket], 'index', null)
    });
  });
  return sortBy(data, ['index']);
};

export default adapter;
