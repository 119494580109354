import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../components/Link';

const DetailsLink = ({ to: url, organizationId, year, children, ...props }) => {
  const isExternal = url.startsWith('http');

  return isExternal ? (
    <a href={url} {...props}>
      {children}
    </a>
  ) : (
    <Link to={`/organizations/${organizationId}/${url}?year=${year}`} {...props}>
      {children}
    </Link>
  );
};

DetailsLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  year: PropTypes.number.isRequired
};

export default DetailsLink;
