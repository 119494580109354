import get from 'lodash/get';
import isObject from 'lodash/isObject';
import flatten from 'lodash/flatten';
import sortBy from 'lodash/sortBy';
import { format } from 'date-fns';

import compileTemplate from '../../helpers/template';
import formatters from '../../helpers/formatter';

export const ICONS = {
  budget_allocation: 'fin-funds-duo',
  certified_teacher_count: 'bb-ribbon',
  home: 'os-house',
  laptop: 'bb-laptop',
  people: 'bb-group',
  school: 'bb-school-big',
  space_access: 'bb-referral',
  tablet: 'bb-tablet-2',
  teacher_count: 'bb-group',
  time_allocation: 'bb-clock2',
  time: 'bb-clock2',
  wifi: 'bb-access'
};

const INPUT = {
  yes: 'Yes',
  no: 'No'
};

const getIcon = (key = '', node = {}) => {
  const { field_inputs: fieldInputs = [{}], metric_options: metricOptions = [{}] } = node;
  const iconKey = key ||
    fieldInputs.find(option => option.key === key).key ||
    metricOptions.find(option => option.key === key).key;

  return ICONS[iconKey] || ICONS[metricOptions[0].key] || null;
};

/**
 * Get icon, label and template for specific metric option
 * @param {String} key - Kay for the specific metric option
 * @param {Object} node - Node data object
 * @return {Object}
 */
const getMetricOptions = (key = '', node = {}) => {
  const { field_inputs: fieldInputs = [{}], metric_options: metricOptions = [{}] } = node;

  const defaultMetricOptions = {
    id: metricOptions.findIndex(option => option.key === key),
    icon: getIcon(key, node),
    label: null,
    template: '{{value}}',
    formatter: null
  };

  if (fieldInputs.length > 1 || metricOptions.length > 1) {
    return {
      ...defaultMetricOptions,
      ...fieldInputs.find(option => option.key === key),
      ...metricOptions.find(option => option.key === key)
    };
  }

  if (metricOptions.length === 1) {
    return {
      ...defaultMetricOptions,
      ...metricOptions[0]
    };
  }

  return defaultMetricOptions;
};

const normalize = value => value[0].toUpperCase() + value.replace(/_/gi, ' ').substr(1);

const getInput = (value = '', template = '{{value}}', formatter = null) => {
  switch (true) {
    case (`${value}`.indexOf('_') > -1):
      return normalize(value);
    case (!!value):
      return normalize(compileTemplate(template, { value: formatter ? formatters(formatter, value) : value }));
    default:
      return 'Not reported';
  }
};

/**
 * Take a score object and returns data expectd by the KdeTextNumberComponent
 * @param {Object} score - Score data object; `Value` in a score object can be either Object or a String
 * @param {Object} node - Node data object
 * @return {Object[]}
 *
 * Score can either be:
 *
 * score = {
 *   value: {
 *     value: '18'
 *   }
 * }

 * score = {
 *   value: {
 *     value: 'only_some_grade_levels'
 *   }
 * }

 * score = {
 *   value: {
 *     lorem: '24',
 *     ipsum: '12'
 *   }
 * }

 * score = {
 *   value: {
 *     dimensions: 'Lorem',
 *     score: {
 *       lorem: '18'
 *     }
 *   }
 * }
 *
 * score = {
 *   value: {
 *     dimensions: 'Lorem',
 *     score: {
 *       lorem: {
 *         ipsum: '18'
 *       }
 *     }
 *   }
 * }

 */

const buildTextNumberScore = (key, value, node) => {
  const notReportedValue = 'Not reported';
  const { template, formatter, ...options } = getMetricOptions(key, node);
  const inputValue = value || INPUT[key];
  let input = inputValue ? getInput(inputValue, template, formatter) : notReportedValue;
  if (input !== notReportedValue && key === 'date') {
    input = format(new Date(input), 'MMMM dd, yyyy');
  }

  return {
    input,
    ...options
  };
};

const getScoreFromValue = (key, value, node) => {
  if (isObject(value) && node.metric_options) {
    const arrayOfKeys = node.metric_options.map(option => option.key);
    if (arrayOfKeys.includes(`${key}`)) {
      return buildTextNumberScore(key, value, node);
    } else {
      return Object.entries(value).map((subItem) => {
        const [subKey, subValue] = subItem;
        if (!arrayOfKeys.includes(`${key}.${subKey}`)) return false;
        return buildTextNumberScore(`${key}.${subKey}`, subValue, node);
      });
    }
  }

  return buildTextNumberScore(key, value, node);
};

/* eslint-disable no-unused-vars */
const kdeTextNumberAdapter = (score = { value: {} }, node = {}, config, viewingDropdownFilter) => {
  const { field_inputs: fieldInputs = [{}], metric_options: metricOptions = [{}] } = node;
  const scoreKey = get(config, 'scoreKey', null);

  if (!score.value || (!fieldInputs[0].key && !metricOptions[0].key)) return [];

  const getScore = scoreKey ?
    get(score, `value.dimensions[${scoreKey}].dimensions[${get(viewingDropdownFilter, '0')}].score`, null) :
    get(score, 'value.score', null) || get(score, 'value', null) || {};

  if (!getScore) return {};

  const { score: unusedScore, dimensions: unusedDimensions, ...values } = getScore;

  const dimensions = Object.entries(get(score || {}, 'value.dimensions', {}))
    .map(([key, value]) => [key, Object.values(get(value, 'score', {}))[0]]);

  const results = sortBy(flatten(
    Object.entries(values)
      .filter(([key, value]) => (key !== 'date' || (key === 'date' && !!value)))
      .map(([key, value]) => getScoreFromValue(key, value, node))
  ).filter(item => !!item), ['id']);

  return [
    ...results,
    ...dimensions
      .filter(([key, value]) => !!value)
      .map(([key, value]) => getScoreFromValue(key, value, node))
  ];
};
/* eslint-enable no-unused-vars */

export default kdeTextNumberAdapter;
