import get from 'lodash/get';

// extract the score under extractScoreUnderKey option
// For example
// const currentOrgScores = {
//   all_demographics: {
//     music: { count: 5 }
//   },
//   gen_male: {
//     music: { count: 2 }
//   },
//   gen_female: {
//     music: { count: 3 }
//   }
// }
//
// const chartConfig = {
//   options: {
//     extractScoreUnderKey: 'all_demographics'
//   }
// }
//
// return
// {
//   music: { count: 5 }
// }
// TODO this logics may be duplicate somewhere, DRY it up if possible
export default function adapter(currentOrgScore, { chartConfig }) {
  const scoreKey = get(chartConfig, 'options.extractScoreUnderKey');
  if (scoreKey) {
    return get(currentOrgScore, scoreKey);
  }

  return currentOrgScore;
}
