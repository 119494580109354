import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import union from 'lodash/union';
import flatMap from 'lodash/flatMap';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';

import ViewingBlock from 'cui/lib/components/ViewingBlock';
import EmptyState from 'cui/lib/components/EmptyState';

import { currentOrgYearScores } from '../../helpers/scoresHelpers';
import buildDropdownOptions from '../../helpers/buildDropdownOptions';

import BarChart from '../BarChart';
import { PercentBarChart } from '../PercentBarChart';
import styles from './filteredChart.module.scss';

export class FilteredChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selected: get(props.config, 'defaultSelection', '3') };
  }

  selectionChanged(item) {
    this.setState({ selected: item.key });
  }

  /* eslint-disable complexity */
  render() {
    const { useFilter = true, chartType = 'percent' } = this.props.config;

    const currentFilter = useFilter && get(this.props.params, 'filters.demographic', 'All');
    const value = useFilter && get(this.props.directScore, `value.${currentFilter}`, {});

    const currentScore = !useFilter && currentOrgYearScores(this.props.scores, this.props.organization, this.props.node);
    const keys = useFilter ? Object.keys(value) : union(flatMap(currentScore.value, v => map(v, (vv, key) => key)));
    const options = buildDropdownOptions(keys, get(this.props.config, 'filterOptions'));
    let selectedIndex = findIndex(options, o => o.key === this.state.selected);
    if (selectedIndex === -1) {
      selectedIndex = 0;
    }

    const selectedKey = options[selectedIndex] && options[selectedIndex].key;

    const dataPointHasOptions = !isEmpty(options);

    const scores = useFilter ? reduce(this.props.scores, (acc, score) => {
      const scoreValue = (get(score, `value.${currentFilter}.${selectedKey}`));
      if (scoreValue) {
        acc.push(
          {
            ...score,
            value: scoreValue
          });
      }
      return acc;
    }, []) : this.props.scores;

    return (
      <div>
        {
          !dataPointHasOptions && <EmptyState kind="text" title="There is no data available." />
        }
        {
          dataPointHasOptions && <div>
            <ViewingBlock
              type="dropdown"
              items={options}
              initiallySelectedItem={selectedIndex}
              onSelectionChange={item => this.selectionChanged(item)}
              className={styles.viewingDropdown}
            />
            {chartType === 'percent' && <PercentBarChart {...this.props} scores={scores} selected={selectedKey} />}
            {chartType === 'bar' && <BarChart {...this.props} scores={scores} selected={selectedKey} />}
          </div>
        }
      </div>
    );
  }
  /* eslint-enable complexity */
}

FilteredChart.propTypes = {
  scores: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.shape({
    filterOptions: PropTypes.object,
    useFilter: PropTypes.bool,
    chartType: PropTypes.string
  }),
  directScore: PropTypes.shape({
    value: PropTypes.object
  }),
  params: PropTypes.shape({
    filters: PropTypes.shape({
      demographic: PropTypes.string
    })
  }),
  organization: PropTypes.object,
  node: PropTypes.object
};

FilteredChart.defaultProps = {
  config: {}
};

export default FilteredChart;
