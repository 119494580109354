const statusText = (progressCount, totalCount) => {
  if (totalCount === 0) {
    return 'Not Applicable';
  } else if (progressCount === 0) {
    return 'Not Started';
  } else if (progressCount < totalCount) {
    return 'Started';
  } else {
    return 'Done';
  }
};

export const newStatusText = (inProgressCount, completedCount, totalCount) => {
  if (totalCount === 0 || (inProgressCount === 0 && completedCount === 0)) {
    return 'Not Started';
  } else if (inProgressCount > 0) {
    return 'Started';
  } else {
    return 'Done';
  }
};

export default statusText;
