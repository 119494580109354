import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { getCheckboxOptions, scoreInfoPropType } from '../../../helpers/collectorHelpers';

const MIN_COLUMN_LENGTH = 3;

// Note: currently weasyprint only generate pdf correctly when there are at least 3 items in columns
// This function will add dummy items to the columns when there are less than 3 items.
function selectedCheckboxes(selectedOptions) {
  const checkboxes = selectedOptions.map(({ label, value }) => (
    <li key={value}>
      <img src="https://bb-production-assets.s3.amazonaws.com/kde/report/circle-check.svg" className="checkmark" alt="" />
      <span className="value">{label}</span>
    </li>
  ));

  let currentLength = checkboxes.length;

  // Handle single line (still seems buggy sometimes, if the only option is an "Other")
  if (currentLength === 1) {
    return checkboxes;
  }

  while (currentLength < MIN_COLUMN_LENGTH) {
    checkboxes.push(<li key={currentLength}>&nbsp;</li>);
    currentLength += 1;
  }

  return checkboxes;
}

const CheckboxSet = ({ fieldLabel, notReported, scoreInfo, columnCount }) => (
  <div>
    <h3 className="fieldLabel">{fieldLabel}</h3>
    <div className="field">
      {notReported || (
        getCheckboxOptions(scoreInfo).map(({ slug, title, options }) => {
          const selectedOptions = options.filter(option => option.selected);
          const checkboxes = selectedCheckboxes(selectedOptions);
          return isEmpty(selectedOptions) ? null : (
            <div key={slug}>
              {title && <h4>{title}</h4>}
              <ol className={cx('checkboxList', { columns: columnCount > 1 && checkboxes.length > 1 })}>
                {checkboxes}
              </ol>
            </div>
          );
        })
      )}
    </div>
  </div>
);

CheckboxSet.propTypes = {
  fieldLabel: PropTypes.string.isRequired,
  notReported: PropTypes.node,
  scoreInfo: scoreInfoPropType.isRequired,
  columnCount: PropTypes.oneOf([1, 2])
};

CheckboxSet.defaultProps = {
  columnCount: 2
};

export default CheckboxSet;
