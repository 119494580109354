import React from 'react';
import PropTypes from 'prop-types';

import Overview from './Overview';
import ScoreHeader from './ScoreHeader';
import Scores from './Scores';

const AccountabilityDashboard = ({ children }) => (
  <div className="container-constrained">
    { children }
  </div>
);

AccountabilityDashboard.propTypes = {
  children: PropTypes.node
};

AccountabilityDashboard.Overview = Overview;
AccountabilityDashboard.ScoreHeader = ScoreHeader;
AccountabilityDashboard.Scores = Scores;

export default AccountabilityDashboard;
