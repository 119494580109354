import get from 'lodash/get';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';

import template from '../../../template';

const adapter = (options) => {
  const { config, legendPlacement, shape, scores, node, customLegendRenderers, customLegendRenderOptions, theme } = options;
  const scoringOptions = get(node, 'scoring_options', {});
  const combinedScoreShape = map(scores, score => (
    {
      ...score,
      ...(shape[score[scoringOptions.shapeKey]])
    }
  ));
  const LEGEND_THEME_DEFAULT = 'default';
  const LEGEND_PLACEMENT_DEFAULT = 'right';
  const WIDTH_DEFAULT = 200;

  const chartData = shape && sortBy(map(combinedScoreShape, score => ({
    ...score,
    percent: Math.round(score.percent),
    value: score.percent,
    key: score[scoringOptions.shapeKey],
    label: score.label,
    count: 1,
    colorIndex: score.colorIndex
  })), ['index']);

  const legendData = map(chartData, datum => (
    {
      colorIndex: datum.colorIndex,
      label: template(datum.label, { ...datum, ...options }),
      description: template(config.description, { ...datum, ...options }),
      ...datum
    }
  ));

  const legendProps = {
    data: legendData,
    transparent: true
  };

  if (customLegendRenderOptions) {
    const lengendRenderer = customLegendRenderers[customLegendRenderOptions.type];
    if (lengendRenderer) {
      legendProps.renderLegendItem = lengendRenderer(customLegendRenderOptions);
    }
  }

  const chartDatum = {
    legendPlacement: legendPlacement || LEGEND_PLACEMENT_DEFAULT,
    theme: theme || config.theme || LEGEND_THEME_DEFAULT,
    chartProps: {
      data: chartData,
      width: config.width || WIDTH_DEFAULT
    },
    legendProps
  };

  return chartDatum;
};

export default adapter;
