import merge from 'lodash/merge';

import LOAD_FEATURE_FLAGS_QUERY from '../queries/load_feature_flags';

const LOAD_FEATURE_FLAG = {
  type: 'GRAPH_LOAD',
  service: 'feature_flags',
  isPublic: true,
  // Only load feature flags in public portal via graphql and when they haven't loaded yet
  // Feature flags are loaded automatically in approval tool.
  when: '{{!userContext && !featureFlagsForPublicPortal}}',
  query: LOAD_FEATURE_FLAGS_QUERY,
  stateObjectMap: {
    pageContext: {
      method: (stateData = {}, featureFlags = []) => ({
        ...stateData,
        featureFlags: merge(featureFlags.reduce((memo, fg) => {
          memo[fg.key] = fg.enabled;
          return memo;
        }, {}), stateData.featureFlagOverrides || {})
      }),
      dataKey: 'data.feature_flags',
      key: 'pageContext'
    }
  },
  propMap: {
    userContext: 'module.userContext',
    featureFlagsForPublicPortal: 'module.featureFlagsForPublicPortal'
  }
};

export default LOAD_FEATURE_FLAG;
