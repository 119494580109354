import React from 'react';
import PropTypes from 'prop-types';

import Button from 'cui/lib/components/Button';
import Popover from 'cui/lib/components/Popover';

import Markdown from '../Markdown';
import template from '../../helpers/template';

const PopoverCell = ({ item, toggleText, title, contents }) => (
  <Popover popoverPlacement="right" appendToBody={false}>
    <Popover.Toggle>
      <Button>{template(toggleText, item)}</Button>
    </Popover.Toggle>
    <Popover.Content>
      {title && <Popover.Title>{template(title, item)}</Popover.Title>}
      <Popover.Body>
        <Markdown body={template(contents, item)} />
      </Popover.Body>
    </Popover.Content>
  </Popover>
);

PopoverCell.propTypes = {
  item: PropTypes.object,
  toggleText: PropTypes.string,
  title: PropTypes.string,
  contents: PropTypes.string
};

PopoverCell.defaultProps = {
  toggleText: 'View',
  title: '',
  contents: ''
};

export default PopoverCell;
