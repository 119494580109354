import React from 'react';
import PropTypes from 'prop-types';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import { extractScoreInfo } from '../../../helpers/collectorHelpers';

const sectionHoc = (SectionComponent) => {
  const Wrapper = ({ organization, framework, scores, nodePaths, nodePath, ...props }) => {
    if (nodePaths) {
      props.scores = mapValues(nodePaths, path => extractScoreInfo(scores, path, framework, organization));
    } else {
      props.score = extractScoreInfo(scores, nodePath, framework, organization);
    }

    return <SectionComponent {...props} />;
  };

  Wrapper.displayName = `sectionHoc(${SectionComponent.displayName || SectionComponent.name})`;
  Wrapper.propTypes = {
    ...omit(SectionComponent.propTypes, 'scores', 'score'),
    organization: PropTypes.shape({
      entity_type: PropTypes.string.isRequired
    }).isRequired,
    framework: PropTypes.object.isRequired,
    scores: PropTypes.objectOf(PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
    })).isRequired,
    nodePaths: PropTypes.objectOf(PropTypes.string),
    nodePath: PropTypes.string
  };

  return Wrapper;
};

export default sectionHoc;
