import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import uniq from 'lodash/uniq';

// the data from groot has format
// {
//   grade_1: { course1: { enrolled_students: 100 }, course2: { enrolled_students: 100 } },
//   grade_2: { course1: { enrolled_students: 100 }, course2: { enrolled_students: 100 } },
//   grade_3: { course1: { enrolled_students: 100 }, course2: { enrolled_students: 100 } }
// }
// The rows object are nested under column keys.
// To make it easier to table to render, we need the invert the order and have column keys nested under row keys.
// {
//   course1: { grade_1: { enrolled_students: 100 }, grade_2: { enrolled_students: 100 }, grade_3: { enrolled_students: 100 } },
//   course2: { grade_1: { enrolled_students: 100 }, grade_2: { enrolled_students: 100 }, grade_3: { enrolled_students: 100 } }
// }

export default function adapter({ currentOrgScore, chartConfig }) {
  const data = get(currentOrgScore, 'value') || currentOrgScore;
  let rows = chartConfig.options.rows;
  rows = !isEmpty(rows) ? rows : uniq(reduce(data, (memo, value) => memo.concat(Object.keys(value)), []));
  if (chartConfig.sort) {
    rows.sort();
    if (chartConfig.sort === 'desc') {
      rows.reverse();
    }
  }
  const columns = chartConfig.options.columns;
  const rowHeaderKey = Array.isArray(columns) && columns[0] ? columns[0].scoreKey : null;
  const rowObj = reduce(rows, (memo, value) => {
    memo[value] = chartConfig.includeRowKeyAsHeader && rowHeaderKey ? { [rowHeaderKey]: { text: value } } : {};
    return memo;
  }, {});

  return reduce(data, (memo, typeValue, demographicKey) => {
    forEach(typeValue, (value, key) => {
      if (key in memo) {
        memo[key][demographicKey] = value;
      }
    });
    return memo;
  }, rowObj);
}
