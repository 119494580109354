import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import template from '../../../helpers/template';

export const NumberFormatter = (props) => {
  const { value, templateString, valueKey } = props;
  let theValue = value;
  if (valueKey) {
    theValue = get(value, valueKey);
  }
  const number = (parseInt((theValue || 0)) / 100).toFixed(2);
  const compiledValue = template(templateString, { value: number });
  if (number > 0) {
    return (
      <span>
        {compiledValue}
      </span>
    );
  } else {
    return <i>No Data</i>;
  }
};

NumberFormatter.propTypes = {
  value: PropTypes.string,
  templateString: PropTypes.string,
  valueKey: PropTypes.string
};

NumberFormatter.defaultProps = {
  value: '',
  templateString: '{{value}}',
  valueKey: ''
};

export default NumberFormatter;
