import React from 'react';
import PropTypes from 'prop-types';

import get from 'lodash/get';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';

import ViewingBlock from 'cui/lib/components/ViewingBlock';
import EmptyState from 'cui/lib/components/EmptyState';

import buildDropdownOptions from '../../helpers/buildDropdownOptions';

import BarChart from '../BarChart';
import styles from './filteredChart.module.scss';

export class ComparisonFilteredChart extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { selected: get(props.config, 'defaultSelection', '3') };
  }

  selectionChanged(item) {
    this.setState({ selected: item.key });
  }

  render() {
    const currentFilter = get(this.props.params, 'filters.demographic', 'All');

    const values = reduce(this.props.entityScores, (acc, entityScore) => {
      const scoreNodePath = get(entityScore, 'framework_tree_node.node_path', '');
      const propsNodePath = get(this.props, 'node.node_path');
      if (scoreNodePath === propsNodePath) {
        const scoreKeys = Object.keys(get(entityScore, `value.${currentFilter}`, {}));
        const filterOptionsKeys = Object.keys(get(this.props.config, 'filterOptions', []));
        scoreKeys.forEach((k) => {
          const keyIsInFilterOptions = filterOptionsKeys.includes(k);
          const keyIsNotInAcc = !(acc.includes(k));
          if (keyIsInFilterOptions && keyIsNotInAcc) {
            acc.push(k);
          }
        });
      }
      return acc;
    }, []);

    const options = buildDropdownOptions(values, get(this.props.config, 'filterOptions'));

    const optionKeys = options.map(o => `${o.key}`);
    const selectionIsInOptions = optionKeys.includes(this.state.selected);
    const selectedKey = selectionIsInOptions ? this.state.selected : optionKeys[0];

    const dataPointHasOptions = !isEmpty(options);

    const scores = reduce(this.props.scores, (acc, score) => {
      const scoreValue = get(score, `value.${currentFilter}.${selectedKey}`);
      if (scoreValue !== undefined) {
        acc.push(
          {
            ...score,
            value: scoreValue
          });
      }
      return acc;
    }, []);

    const entityScores = reduce(this.props.entityScores, (acc, score) => {
      const scoreValue = get(score, `value.${currentFilter}.${selectedKey}`);
      if (scoreValue !== undefined) {
        acc.push(
          {
            ...score,
            value: scoreValue
          });
      }
      return acc;
    }, []);

    let initiallySelectedItem = findIndex(options, o => o.key === selectedKey);
    if (initiallySelectedItem === -1) {
      initiallySelectedItem = 0;
    }

    return (
      <div>
        {
          !dataPointHasOptions && <EmptyState kind="text" title="There is no data available." />
        }
        {
          dataPointHasOptions && <div>
            <ViewingBlock
              type="dropdown"
              items={options}
              initiallySelectedItem={initiallySelectedItem}
              onSelectionChange={item => this.selectionChanged(item)}
              className={styles.viewingDropdown}
            />
            <BarChart {...this.props} scores={scores} entityScores={entityScores} />
          </div>
        }
      </div>
    );
  }
}

ComparisonFilteredChart.propTypes = {
  node: PropTypes.shape({
    node_path: PropTypes.string
  }),
  entityScores: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.object
    })
  ),
  scores: PropTypes.arrayOf(PropTypes.object),
  config: PropTypes.shape({
    filterOptions: PropTypes.object
  }),
  directScore: PropTypes.shape({
    value: PropTypes.object
  }),
  params: PropTypes.shape({
    filters: PropTypes.shape({
      demographic: PropTypes.string
    })
  })
};

export default ComparisonFilteredChart;
