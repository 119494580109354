import { useQuery } from 'react-query';
import getDownloadItems from './getDownloadItems';

const fetchDatasets = (year) => new Promise((resolve) => {
  resolve(getDownloadItems(year));
});

const useDataSetsQuery = (year, options) => useQuery(
  ['datasets', year],
  () => fetchDatasets(year),
  {
    ...options,
    cacheTime: Infinity,
    staleTime: Infinity
  }
);

const useDataSetQuery = (year, nodePath) => useDataSetsQuery(
  year,
  { select: data => data.filter(element => element.nodePaths?.includes(nodePath)) }
);

export default useDataSetQuery;
