import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

function label(section, optionSlug) {
  return get(get(section, 'label_map', []).find(option => option.value === optionSlug), 'label');
}

export default function adapter({ node, score }) {
  const sections = get(node, 'scoring_options.format_options.sections', []);
  const scoreValue = get(score, 'value.value') || get(score, 'value.score.value', {});

  return reduce(scoreValue, (memo, value, key) => {
    if (!isEmpty(value)) {
      const section = sections.find(meta => meta.section_slug === key) || {};
      // return options with true value only
      const selectedOptions = Object.keys(value.selected_options || {})
        .filter(optionKey => get(value, ['selected_options', optionKey], false))
        .map(option => label(section, option));

      memo.push({
        title: section.name,
        items: (value.others || []).concat(selectedOptions).sort()
      });
    }
    return memo;
  }, []);
}
