import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Text from 'cui/lib/components/Form/Text';
import FlexBar from 'cui/lib/components/FlexBar';
import Button from 'cui/lib/components/Button';

import FieldErrorMessage from '../FieldErrorMessage';

export default class OtherField extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  setFieldError = (error) => {
    this.setState({ error });
  }

  render() {
    const { fieldId, value, onChange, onDelete, validationProps, validationErrors } = this.props;

    return (
      <div>
        <FlexBar className="cui-margin-small">
          <FlexBar.Item grow>
            <Text
              id={fieldId}
              type="text"
              value={value}
              onChange={onChange}
              stroked
              onError={this.setFieldError}
              {...validationProps}
              errors={validationErrors}
            />
          </FlexBar.Item>
          <FlexBar.Item>
            <Button
              type="button"
              kind="stroked"
              theme="caution"
              onClick={onDelete}
            >
              Remove
            </Button>
          </FlexBar.Item>
        </FlexBar>
        {this.state.error && (<FieldErrorMessage error={this.state.error} />)}
      </div>
    );
  }
}

OtherField.propTypes = {
  value: PropTypes.string,
  fieldId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  validationProps: PropTypes.object,
  validationErrors: PropTypes.object
};
