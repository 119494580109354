import React from 'react';
import PropTypes from 'prop-types';

Header.propTypes = {
  children: PropTypes.node
};

function Header({ children }) {
  return (
    <div>
      { children }
    </div>
  );
}

Header.defaultProps = {
  'data-slotName': 'Header'
};

export default Header;
