import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { Heading } from 'cui';

import DomainIntroItem from '../DomainIntroItem/index';

import styles from './index.module.scss';

function DomainIntro({ schoolYear }) {
  // the end_of_year_enrollment variable was renamed to enrollment for 2019
  const enrollmentLink = schoolYear === 2018 ?
    '/organization/20/school_overview/students/end_of_year_enrollment' :
    '/organization/20/school_overview/students/enrollment';

  return (
    <section className={cx('section', styles.domainIntro)} aria-labelledby="domains_introduction_section_aria_label">
      <span id="domains_introduction_section_aria_label" hidden>Domains introduction</span>
      <div className="section-container">
        <Heading className={styles.heading} level={4} size="large">
          The Kentucky School Report Card provides information about each school and district.
        </Heading>
        <ul className={styles.domainList}>
          <li>
            <DomainIntroItem
              title="Overview"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/school_overview_icon.svg"
              linkTo={enrollmentLink}
            >
              <p>What are the characteristics of the schools in Kentucky?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="Academic Performance"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/academic_performance_icon.svg"
              linkTo="/organization/20/academic_performance/assessment_performance/state_assessments_accountability"
            >
              <p>How are students performing academically?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="Educational Opportunity"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/educational_opportunity_icon.svg"
              linkTo="/organization/20/educational_opportunity/advanced_coursework/advanced_course_participation"
            >
              <p>What educational opportunities are available to students?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="Transition to Adult Life"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/transition_readiness_icon.svg"
              linkTo="/organization/20/transition_readiness/academic_readiness/academic_readiness_score"
            >
              <p>How prepared are students for what comes next?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="School Accountability"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/school_accountability_icon.svg"
              linkTo="/organization/20/school_accountability/proficiency/indicator_score"
            >
              <p>What is the overall school performance based on state accountability?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="School Safety"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/school_safety_icon.svg"
              linkTo="/organization/20/school_safety/safety/safe_schools_data"
            >
              <p>How safe are schools in Kentucky?</p>
            </DomainIntroItem>
          </li>
          <li>
            <DomainIntroItem
              title="Financial Transparency"
              image="//bb-production-assets.s3.amazonaws.com/kde/domainIcons/financial_transparency_icon.svg"
              linkTo="/organization/20/financial_transparency/financial_summary/financial_summary"
            >
              <p>How equitable is spending and funding across districts?</p>
            </DomainIntroItem>
          </li>
        </ul>

      </div>
    </section>
  );
}

DomainIntro.propTypes = {
  schoolYear: PropTypes.number
};

export default DomainIntro;
