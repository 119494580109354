/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import get from 'lodash/get';

import Divider from 'cui/lib/components/Divider';
import Field from 'cui/lib/components/Form/Field';
import Heading from 'cui/lib/components/Heading';
import Icon from 'cui/lib/components/Icon';
import List from 'cui/lib/components/List';
import Spacer from 'cui/lib/components/Spacer';
import StickyNote from 'cui/lib/components/StickyNote';
import Table from 'cui/lib/components/Table';

import ScrollToTopOnMount from '../components/ScrollToTopOnMount';
import buildLoadFrameworkAction from '../actions/framework';
import { LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION } from '../helpers/scoreLoadActions';

import Footer from '../components/Footer';
import Masthead from '../components/Masthead';
import StaticPageContent from '../components/StaticPageContent';
import MainContentAnchor from '../components/MainContentAnchor';
import Accordion from '../components/Accordion';

import pageTitle from '../helpers/pageHelpers';
import styles from './Help.module.scss';

const SCREENSHOTS_BY_YEAR = {
  2018: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs.jpg',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools.jpg'
  },
  2019: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs_2019.png',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools_2019.png'
  },
  2020: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs_2019.png',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools_2019.png'
  },
  2021: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs_2019.png',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools_2019.png'
  },
  2022: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs_2019.png',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools_2019.png'
  },
  2023: {
    BREADCRUMBS: '//bb-production-assets.s3.amazonaws.com/kde/faq/breadcrumbs_2019.png',
    VIEW_SCHOOLS: '//bb-production-assets.s3.amazonaws.com/kde/faq/view-schools_2019.png'
  }
};

const Help = ({ schoolYear }) => {
  const questions = [
    {
      title: 'How do I access the Kentucky School Report Card?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            You can access the Kentucky School Report card by navigating to <a href="https://www.kyschoolreportcard.com" rel="noreferrer" target="_blank">https://www.kyschoolreportcard.com</a> in a web browser on a computer or mobile device using the latest edition of your browser type (e.g., Chrome, Edge, Firefox).
          </p>
          <StickyNote title="Note">
            Older browsers that are no longer updated/maintained by their developing company (such as Internet Explorer 8 or older) may not render the
            website correctly.
          </StickyNote>
        </div>
      )
    },
    {
      title: 'How do I find a specific school or district?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            You can use the search bar (available on any page of the School Report Card) to locate districts or schools by name. You also can click on
            the Explore Statewide Education Data link to view state-level data for Kentucky schools. Selecting the state, district or school from the
            search bar will take you to the corresponding organization’s landing page.
          </p>
        </div>
      )
    },
    {
      title: 'What kinds of information does the Kentucky School Report Card provide?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            Kentucky’s School Report Card is designed to help parents quickly find data about their child’s school, school district and the state
            education system at a glance. On this website, you can find information about test performance, teacher qualifications, student safety,
            parental involvement and much more. The Kentucky Department of Education hopes that by strengthening the communication between schools,
            parents and the community, we all can help ensure Kentucky has a strong education system that benefits all of the Commonwealth’s children.
          </p>
        </div>
      )
    },
    {
      title: 'When I select a school, what information will I see?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            The primary landing page for any organization in the School Report Card will provide you with some high level details about the district
            or school you are viewing, as well as a high-level view of some data points within each of the primary domains of data.  View this
            document to learn more about the <a
              href="https://docs.google.com/document/d/1JDIMI2vFg3n7vju6iy_YJKne6aBCNpgUzCnkXQ4xo6Y/edit"
              rel="noreferrer"
              target="_blank"
            >
              Details of the District and School Landing Pages
            </a>.
          </p>
        </div>
      )
    },
    {
      title: 'Is there a way I can see more specific information about my child?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            Yes, the Campus Student (for students) and Campus Parent (for parents) provides real-time access to announcements, assignments, attendance, grades, schedules and much more. You can download the app from the App Store or Google Play or access online through a web browser.
          </p>
          <StickyNote title="Note">
            This is only available if your child’s school utilizes the Campus portal. Please contact your child’s school for credentials and additional information.
          </StickyNote>
        </div>
      )
    },
    {
      title: 'Once I am at a school, how do I get back to the district or state level?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            From a school dashboard you can navigate back to the district or state levels using the “navigation breadcrumbs” at the top of the page and click on the district.
          </p>
          <img src={SCREENSHOTS_BY_YEAR[schoolYear].BREADCRUMBS} alt="Breadcrumbs screenshot" />
          <p className="cui-text cui-text_small">
            Selecting the link “View All Schools in This District” will allow you to choose
            a different school within the same district to view or you can use the “Search by school or district”
            feature to navigate to a new organization.
          </p>
          <img src={SCREENSHOTS_BY_YEAR[schoolYear].VIEW_SCHOOLS} alt="Select a Different School Screenshot" />
        </div>
      )
    },
    {
      title: 'What are the Key Topics?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            The data within the School Report Card is divided into several categories called Key Topics. Each Key Topic has been designed to make it
            easier to view and understand the data by grouping it with similar data focused around a guiding question.
          </p>
          <p className="cui-text cui-text_small">
            The Key Topics and their guiding questions are as follows:
          </p>
          <Table
            className="cui-text cui-text_small"
            data={[
              { keyTopics: 'Overview', guidingQuestion: 'What are the overall characteristics of this district/school?' },
              { keyTopics: 'Academic Performance', guidingQuestion: 'How are students performing academically in this district/school?' },
              {
                keyTopics: 'Educational Opportunity',
                guidingQuestion: 'What educational opportunities are available to students at this district/school?'
              },
              { keyTopics: 'Transition to Adult Life', guidingQuestion: 'How prepared are students for what comes next?' },
              { keyTopics: 'School Safety', guidingQuestion: 'How safe are schools in Kentucky?' },
              { keyTopics: 'Financial Transparency', guidingQuestion: 'How equitable is spending and funding across districts?' }
            ]}
          >
            <Table.ColumnHeader property="keyTopics">Key Topics</Table.ColumnHeader>
            <Table.ColumnHeader property="guidingQuestion">Guiding Question</Table.ColumnHeader>
          </Table>
          <p className="cui-text cui-text_small">
            The data within each of the Key Topics has also been separated into a set of subcategories to help make it easier to understand and absorb
            the information. These subcategories, along with some additional guiding questions, will help you better understand what data you’ll want
            to explore and the types of data you can expect to see as you begin to drill down into the data.  You can find more information on the
            subtopics in this document: <a
              href="https://docs.google.com/document/d/1Txk70W4Nle0pJWUvcBwrXgd__6T_hlGfVp9eSSVi7C0/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Key Topics, Data Subcategories, and Guiding Questions
            </a>
          </p>
        </div>
      )
    },
    {
      title: 'How do I print the compare screen using the Chrome browser print feature?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            When printing the Comparison screen, use these Chrome browser and print settings for the best outcome.
          </p>
          <List>
            <List.Item icon="bb-score-circle" className="cui-list_small">Set your browser screen zoom to 80% or lower.</List.Item>
            <List.Item icon="bb-score-circle" className="cui-list_small">
              { "Choose 'Print' from the browser menu and select the following print settings:" }
              <List>
                <List.Item icon="bb-score-circle-empty" className="cui-list_small">Layout: Landscape</List.Item>
                <List.Item icon="bb-score-circle-empty" className="cui-list_small">Margins: Minimum</List.Item>
                <List.Item icon="bb-score-circle-empty" className="cui-list_small">Scale: Custom @95</List.Item>
                <List.Item icon="bb-score-circle-empty" className="cui-list_small">Options: Select Headers and footers</List.Item>
                <List.Item icon="bb-score-circle-empty" className="cui-list_small">Options: Select Background graphics</List.Item>
              </List>
            </List.Item>
          </List>
          <img
            src="//bb-production-assets.s3.amazonaws.com/kde/faq/how_to_print_compare_screen_using_chrome.png"
            alt="How to print compare screen using Chrome screenshot"
          />
        </div>
      )
    },
    {
      title: 'How can I get additional help?',
      content: (
        <div>
          <p className="cui-text cui-text_small">
            For additional help, you can:
          </p>
          <List>
            <List.Item icon="bb-score-circle-empty" className="cui-list_small">
              Access the <a
                href="https://docs.google.com/document/d/1mkrN-SDpoA_8Kp625YKqxZVA5buBA5GxjL8A1U8VOWs/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >User&#39;s Guide</a>
            </List.Item>
            <List.Item icon="bb-score-circle-empty" className="cui-list_small">
              Contact Customer Support: If you find what you believe to be errors or inaccuracies in the data,
              or if you notice the public website isn’t working as expected, please contact customer support at&nbsp;
              <a href="mailto:srcsupport@brightbytes.net" rel="noreferrer" target="_blank">srcsupport@brightbytes.net</a>.
              BrightBytes is the vendor that built the Kentucky School Report Card website and they will
              respond within one business day (except for traditional business holidays).
            </List.Item>
          </List>
        </div>
      )
    }
  ];

  const resources = [
    {
      title: 'Kentucky School Report Card: Public Website User\'s Guide',
      link: 'https://docs.google.com/document/d/1511QhjvdFxTKllwuWwAGPitwVVt-VEI1IHh8_pGZ7Ec/edit?usp=sharing'
    },
    {
      title: 'Details of the District and School Landing Page',
      link: 'https://docs.google.com/document/d/1o94Ws8hx-ctV3av0Yia75eyMFnLQLYdtCGA0AidFoKs/edit?usp=share_link'
    },
    {
      title: 'Key Topics, Data Subcategories and Guiding Questions',
      link: 'https://docs.google.com/document/d/1fO92mBNr7RTiyKfeno_8Dw0vmtSSAoa_UUn0h5UQwQ0/edit?usp=sharing'
    },
    {
      title: 'Drilling Down Into the Data',
      link: 'https://docs.google.com/document/d/14Sj6Ve5v42GGTBHyeKxqp-qAUioptY1MN4fsUNrZuzc/edit?usp=sharing'
    }
  ];

  return (
    <div>
      <ScrollToTopOnMount />
      <Masthead withSearchBar />
      <MainContentAnchor />
      <div className={styles.helpContainer}>
        <StaticPageContent>
          <div className="cui-textBlock" role="main">

            <Heading size="xxLarge" level={1}>Help</Heading>

            <Heading size="xLarge" level={2}>FAQs</Heading>
            <Accordion items={questions} />

            <Spacer size="large" />

            <Heading size="xLarge" level={2}>Resources</Heading>
            <div className="cui-accordionForm">
              {
                resources.map((resource, index) => (
                  <div key={index} className="cui-accordionForm-step">
                    <a
                      href={resource.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="cui-accordionForm-step-header"
                    >
                      <div className="cui-accordionForm-step-title">{resource.title}</div>
                      <Icon name="bb-download" className="cui-accordionForm-step-icon" />
                    </a>
                  </div>
                ))
              }
            </div>

            <Spacer size="large" />

            <Heading size="xLarge" level={2}>Contact</Heading>
            <p>Please direct your correspondence to the proper email below.</p>
            <Heading size="base" level={2} className="cui-margin-bottom-large">System Support</Heading>
            <Field label="BrightBytes">
              <a href="mailto:srcsupport@brightbytes.net" rel="noreferrer" target="_blank">srcsupport@brightbytes.net</a>
            </Field>
            <Divider />
            <Heading size="base" level={2} className="cui-margin-bottom-large">Assessment and Accountability Data</Heading>
            <Field label="Kentucky Department of Education">
              <a href="mailto:kdeassessment@education.ky.gov" rel="noreferrer" target="_blank">kdeassessment@education.ky.gov</a>
            </Field>
            <Divider />
            <Heading size="base" level={2} className="cui-margin-bottom-large">Other Data</Heading>
            <Field label="Kentucky Department of Education">
              <a href="mailto:kdedatarequest@education.ky.gov" rel="noreferrer" target="_blank">kdedatarequest@education.ky.gov</a>
            </Field>
          </div>
        </StaticPageContent>
      </div>
      <Footer />
    </div>
  );
};

Help.propTypes = {
  framework: PropTypes.object,
  schoolYear: PropTypes.number
};

const mapStateToProps = state => ({
  framework: get(state, 'framework.kde'),
  schoolYear: get(state, 'module.schoolYear')
});

export const config = {
  title: pageTitle('Help'),
  initActions: [
    buildLoadFrameworkAction({
      actions: [
        LOAD_ORGANIZATIONS_FROM_DATA_PORTAL_ACTION
      ]
    })
  ]
};

export default withRouter(connect(mapStateToProps)(Help));
