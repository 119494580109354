import BarChart from 'cui/lib/components/BarChart';
import Legend from 'cui/lib/components/Legend';
import EmptyState from 'cui/lib/components/EmptyState';
import get from 'lodash/get';
import merge from 'lodash/merge';
import uniqBy from 'lodash/uniqBy';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './index.module.scss';
import KdeGroupedBarChartDetailsPopover from '../KdeGroupedBarChartDetailsPopover';
import KdeGroupedBarChartLegendItem from '../KdeGroupedBarChartLegendItem';
import currentOrgYearScores from '../../helpers/scoresHelpers';
import {
  getChartDataObject,
  getGroupedScores,
  getRoundChartHighFromValues
} from '../../helpers/chartAdapterUtils';

class KdeGroupedBarChart extends React.PureComponent {
  state = {
    hiddenBars: []
  }

  handleOnLegendItemClick = ({ slug }) => {
    this.setState(prevState => ({
      hiddenBars: prevState.hiddenBars.includes(slug) ?
        prevState.hiddenBars.filter(item => item !== slug) :
        [...prevState.hiddenBars, slug]
    }));
  }

  render() {
    const { config, node, organization, scores } = this.props;
    const score = currentOrgYearScores(scores, organization, node);
    const {
      cuiChartOptions = {},
      groupingFunctionName,
      groupingTemplate,
      includeBars = [],
      labelsMap = {},
      omitBars = [],
      omitGroups = [],
      viewingBlocks = []
    } = merge({}, node.scoring_options, config.options);

    const allGroupedScores = getGroupedScores(score, groupingFunctionName, groupingTemplate);

    // TODO: This value should be stored in state of `ViewingBlock` component
    const currentViewingBlockIndex = 0;
    const blocks = viewingBlocks.length ? viewingBlocks : Object.keys(allGroupedScores);
    const viewingBlockGroupedScores = allGroupedScores[blocks[currentViewingBlockIndex]];

    if (!viewingBlockGroupedScores || !Object.keys(viewingBlockGroupedScores).length) {
      return (<EmptyState kind="text" title="There is no data available." />);
    }

    const values = Object.entries(viewingBlockGroupedScores)
      .filter(group => !omitGroups.includes(group[0]))
      .map(
        getChartDataObject(
          labelsMap,
          (value, context) => {
            const denominator = get(context, 'total_students.value') ||
              get(context, 'all_demographics.value') ||
              get(organization, 'student_count') || 1;
            return Math.round((value / denominator) * 100) || 0;
          },
          omitBars,
          includeBars
        )
      );

    const chartOptions = {
      barWidthRatio: 0.97,
      high: getRoundChartHighFromValues(values),
      xAxis: {
        showGrid: true,
        showAxis: true,
        showLabel: true,
        labelSpacing: 15,
        valueLabelSpacing: 15
      },
      yAxis: {
        labelCount: 6,
        labelInterpolationFunc: label => `${label}%`,
        showLabel: true,
        showGrid: false,
        showAxis: true,
        titleSpacing: 0
      },
      valueLabel: true,
      valueLabelFormatter: v =>  `${v}%`,
      detailsPopoverTitle: 'Details',
      detailsPopoverRenderer: props => (
        <KdeGroupedBarChartDetailsPopover theme={props.theme} data={props.data} />
      )
    };

    const legendLabels = uniqBy(
      values.reduce((accumulator, group) => [
        ...accumulator,
        ...group.value
      ], []),
      'label'
    );

    const chartTheme = legendLabels.length === 2 ? 'kde-duo' : 'kde-scale';

    const visibleValues = values.map(item => ({
      ...item,
      value: item.value.filter(({ slug }) => !this.state.hiddenBars.includes(slug))
    }));

    return (
      <div>
        <div className={styles.legend}>
          <Legend
            data={legendLabels}
            kind="inline"
            renderLegendItem={(theme, legendProps) => (
              <KdeGroupedBarChartLegendItem
                {...legendProps}
                onClick={this.handleOnLegendItemClick}
                hidden={this.state.hiddenBars.includes(legendProps.slug)}
                theme={theme}
              />
            )}
            theme={chartTheme}
          />
        </div>
        <BarChart
          chartOptions={merge(chartOptions, cuiChartOptions)}
          className="kde-grouped-bar-chart"
          data={visibleValues}
          theme={chartTheme}
        />
      </div>
    );
  }
}

KdeGroupedBarChart.propTypes = {
  config: PropTypes.object,
  node: PropTypes.object,
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(
    PropTypes.object
  )
};

KdeGroupedBarChart.defaultProps = {
  config: {},
  node: {},
  organization: {},
  scores: []
};

export default KdeGroupedBarChart;
