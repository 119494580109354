import React from 'react';
import PropTypes from 'prop-types';

import ProgressBarList from 'cui/lib/components/ProgressBarList';

import ProgressBar from './ProgressBar';

import chartDataAdapter from '../../helpers/chartDataAdapters';

const ProgressChart = (props) => {
  const { chartType, theme, config } = props;

  const data = chartDataAdapter(chartType, { ...props, ...config });

  const progressRenderer = (item, progressProps) => <ProgressBar {...props} {...config} progressProps={progressProps} {...item} />;

  return (
    <ProgressBarList
      data={data}
      theme={theme}
      renderer={progressRenderer}
    />
  );
};

ProgressChart.defaultProps = {
  config: {},
  theme: ''
};

ProgressChart.propTypes = {
  chartType: PropTypes.string.isRequired,
  config: PropTypes.object,
  theme: PropTypes.string
};

export default ProgressChart;
