import React from 'react';
import PropTypes from 'prop-types';

import map from 'lodash/map';
import Tooltip from 'cui/lib/components/Tooltip';

import template from '../../helpers/template';
import Markdown from '../../components/Markdown';

import styles from './index.module.scss';

import Icon from 'cui/lib/components/Icon';

const CELL_MODIFIERS = {
  addMetIcon: (item, { xValue }) => {
    if (!item.met?.[xValue] || (xValue !== 2022 && item.met?.[xValue] === 'No')) return '';

    const notMetdescription = `Performance Goal of ${item.goal?.[xValue]}% was not met in ${xValue}`;

    if (!item.met?.[xValue] || (xValue === 2022 && item.met?.[xValue] === 'No'))  {
      return (
        <Tooltip placement="bottom" text={notMetdescription}>
          <span>
            <Icon name="bb-circle-cross" className={styles.metxIcon} />
          </span>
        </Tooltip>
      );
    }

    const metDescription = `Performance Goal of ${item.goal?.[xValue]}% was met in ${xValue}`;

    return (
      <Tooltip placement="bottom" text={metDescription}>
        <img
          src="https://bb-production-assets.s3.amazonaws.com/kde/report/circle-check.svg"
          className={styles.metIcon}
          alt="Goal Met"
          role="img"
          aria-label={metDescription}
        />
      </Tooltip>
    );
  }
};

const TextCell = (props) => {
  const { item, textAlign, emptyState, alternateTextProp, className, cellModifier } = props;
  const text = alternateTextProp ? props[alternateTextProp] : props.text;
  const supportedModifier = CELL_MODIFIERS[cellModifier];

  if (Array.isArray(text)) {
    return (
      <div>
        {map(text, (t, i) => {
          const body = template(t, item, false) || emptyState;
          return (
            <div key={i} style={{ textAlign }}>
              <Markdown body={body} inline />
            </div>
          );
        })}
      </div>
    );
  }

  const body = template(text, item, false) || emptyState;

  return (
    <div style={{ textAlign }} className={className}>
      {supportedModifier && <>{supportedModifier(item, props, body)} </>}
      <Markdown body={body} inline />
    </div>
  );
};

TextCell.propTypes = {
  item: PropTypes.object,
  text: PropTypes.string,
  textAlign: PropTypes.oneOf(['right', 'left', 'center']),
  emptyState: PropTypes.string,
  alternateTextProp: PropTypes.string,
  className: PropTypes.string,
  cellModifier: PropTypes.string
};

TextCell.defaultProps = {
  text: '',
  textAlign: 'left',
  emptyState: ''
};

export default TextCell;
