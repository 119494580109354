/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import filter from 'lodash/filter';
import get from 'lodash/get';
import set from 'lodash/set';
import reduce from 'lodash/reduce';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import { format, startOfYear, endOfYear } from 'date-fns';

import Field from 'cui/lib/components/Form/Field';
import Checkbox from 'cui/lib/components/Form/Checkbox';
import List from 'cui/lib/components/List';

import OptionalDynamicList from './OptionalDynamicList';

import styles from './index.module.scss';

class CollectionCheckboxesField extends React.PureComponent {
  constructor(props) {
    super(props);

    this.setSubmissionValueGenerator(props);

    this.state = {
      ...this.computeState(props)
    };

    this.checkboxes = {};
  }

  componentWillReceiveProps(nextProps) {
    this.setSubmissionValueGenerator(nextProps);

    // module.state initializes to a non-undefined value, so there's no
    // good way to await the return of the api call (it could legitimately be an empty array)
    // instead, we initialize the existing values on the return of the score
    // (if/when it goes from not having a value to having one).
    const currentScore = this.props.currentScore;
    const incomingScore = nextProps.currentScore;

    if (isUndefined(currentScore) && !isUndefined(incomingScore)) {
      this.setState(this.computeState(nextProps));
    }
  }

  setSubmissionValueGenerator({ dp, submissionValueGenerators }) {
    if (dp && submissionValueGenerators) {
      submissionValueGenerators[dp.node_path] = this.generateSubmissionValue;
    }
  }

  computeState(props) {
    const scoreValues = get(props, 'currentScore.value.value', []);
    const sections = get(props, 'dp.scoring_options.format_options.sections');

    const selectionState = reduce(sections, (acc, { section_slug, label_map }) => {
      const selectedOptions = reduce(
        get(scoreValues[section_slug], 'selected_options'),
        (selectedValues, enabled, key) => {
          if (enabled) {
            selectedValues.push(key);
          }
          return selectedValues;
        },
        []
      );

      return {
        ...acc,
        [section_slug]: {
          selected_options: this.buildSelectionState(selectedOptions, label_map),
          others: get(scoreValues[section_slug], 'others', [])
        }
      };
    }, {});

    return {
      selected: selectionState
    };
  }

  generateSubmissionValue = () => {
    const { selected } = this.state;

    const currentSelected = get(this.props.currentScore, 'value.selected', this.computeState(this.props).selected);
    if (isEqual(currentSelected, selected)) {
      return null;
    }

    const startAt = get(this.props.currentScore, 'start_at', format(startOfYear(new Date()), 'yyyy-MM-dd'));
    const endAt = get(this.props.currentScore, 'end_at', format(endOfYear(new Date()), 'yyyy-MM-dd'));

    return {
      startAt,
      endAt,
      value: {
        value: selected
      }
    };
  }

  generateId = ({ nodePath, section_slug, value }) => `field:${nodePath}:${section_slug}:${value}`;

  parseId = (id) => {
    const [, nodePath, section_slug, value] = id.match(/^field:(.*?):(.*?):(.*?)$/);
    return { nodePath, section_slug, value };
  };

  stringifyTruthyKeys = value => (
    filter(Object.keys(value), key => value[key]).join(',')
  )

  bindCheckboxRef = (ref) => {
    if (ref) {
      const { nodePath, section_slug, value } = this.parseId(ref.props.id);
      set(this.checkboxes, [nodePath, section_slug, value], ref);
    }
  };

  // Special logic for checkbox/multiselect field
  handleCheckboxChange(nodePath, section_slug, value, checked) {
    // Collect the current field state.
    // If the checkbox is the one that just changed, use the new state passed to this handler
    let selectedOptions = reduce(this.checkboxes[nodePath][section_slug], (acc, checkbox) => {
      const { value: checkboxValue } = this.parseId(checkbox.props.id);
      const isSelected = checkboxValue === value ? checked : checkbox.state.value;
      if (isSelected) {
        acc.push(checkboxValue);
      }
      return acc;
    }, []);

    // If the none_available checkbox is the one that was just changed and it was toggled on,
    // deselect the other options. Or if another option was toggled on, deselect none_available.
    if (value === 'none_available' && checked) {
      selectedOptions = ['none_available'];
    } else if (selectedOptions.includes('none_available') && checked) {
      selectedOptions = without(selectedOptions, 'none_available');
    }

    // unfortunately, the state and the score changelog are shaped differently.
    const selectionOptions = find(this.props.dp.scoring_options.format_options.sections, { section_slug });
    const newSelectionState = this.buildSelectionState(selectedOptions, get(selectionOptions, 'label_map'));

    const selected = {
      ...this.state.selected,
      [section_slug]: {
        ...this.state.selected[section_slug],
        selected_options: newSelectionState,
        others: isEqual(selectedOptions, ['none_available'])
          ? []
          : this.state.selected[section_slug].others
      }
    };
    this.setState({ selected });
  }

  handleDynamicListChange = (nodePath, section_slug, value) => {
    this.setState({
      selected: {
        ...this.state.selected,
        [section_slug]: {
          ...this.state.selected[section_slug],
          others: value
        }
      }
    }, () => {
      this.handleCheckboxChange(nodePath, section_slug, 'none_available', false);
    });
  }

  buildSelectionState(selectedOptions = [], allOptions = []) {
    return reduce(allOptions, (acc, { value: val }) => {
      acc[val] = selectedOptions.includes(val);
      return acc;
    }, {});
  }

  render() {
    const { dp } = this.props;
    const name = get(dp, 'name');
    const nodePath = get(dp, 'node_path');
    const sections = get(this.props.dp.scoring_options, 'format_options.sections', []);
    const fieldPrompt = get(dp, 'field_prompt', name);

    return (
      <div>
        <Field key={nodePath} label="">
          <fieldset>
            <legend className={styles.preserveLineBreaks}>{fieldPrompt}</legend>
            <List>
              {sections.map(({ name: sectionName, section_slug, has_other, label_map, other_input_validations: validations }, j) => (
                <List.Item key={j}>
                  {sectionName ? (<legend>{sectionName}</legend>) : (<div />)}
                  <List>
                    {label_map.map(({ label, value }, i) => (
                      <List.Item key={`${j}.${i}.${section_slug}`}>
                        <Checkbox
                          id={this.generateId({ nodePath, section_slug, value })}
                          label={label}
                          onChange={this.handleCheckboxChange.bind(this, nodePath, section_slug, value)} // eslint-disable-line react/jsx-no-bind
                          value={get(this.state.selected, `${section_slug}.selected_options.${value}`)}
                          ref={this.bindCheckboxRef}
                        />
                      </List.Item>
                    ))}
                  </List>
                  <List>
                    {has_other ?
                      (<List.Item key={label_map.length}>
                        <OptionalDynamicList
                          nodePath={nodePath}
                          sectionSlug={section_slug}
                          values={get(this.state.selected, `${section_slug}.others`)}
                          listChangeHandler={this.handleDynamicListChange}
                          validations={validations}
                        />
                      </List.Item>) : (<div />)
                    }
                  </List>
                </List.Item>
              ))}
            </List>
          </fieldset>
        </Field>
      </div>
    );
  }
}

CollectionCheckboxesField.propTypes = {
  dp: PropTypes.object,
  currentScore: PropTypes.object
};

CollectionCheckboxesField.defaultProps = {
  dp: {
    scoring_options: {}
  }
};

export default CollectionCheckboxesField;
