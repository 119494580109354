import get from 'lodash/get';

/**
 * Extract the data nested under current viewing dataDropdown filter for score returned from the svc-portaldata service
 * Case 1: Dimensionless score
 * score = {
 *   dimensions: {},
 *   score: {
 *     reading: { nation: { count: 1 }, kentucky: { count: 2 } },
 *     math: { nation: { count: 3 }, kentucky: { count: 4 } }
 *   }
 * }
 * viewingDropdownFilter = { 0: 'reading' }
 * it returns:
 * { nation: { count: 1 }, kentucky: { count: 2 } }
 *
 * Case 2: With dimensions
 * score = {
 *   dimensions: {
 *     elementary_school: { dimensions: {}, score: { count: 1 } },
 *     middle_school: { dimensions: {}, score: { count: 2 } },
 *     high_school: { dimensions: {}, score: { count: 3 } }
 *   },
 *   score: {}
 * }
 * viewingDropdownFilter = { 0: 'middle_school' }
 * it returns: { count: 2 }
 **/

function dimensionPath(dimensions, scoreKey) {
  let currentPath = `score.${scoreKey}`;

  if (Array.isArray(dimensions) && dimensions.length > 0) {
    if (dimensions.length === 1) {
      // return nested score for single dimension
      currentPath = `dimensions.${scoreKey}.score`;
    } else {
      // TODO implement the logics for score with multiple dimension
      currentPath = `dimensions.${scoreKey}`;
    }
  } else {
    // return top level score for dimensionless score
    currentPath = `score.${scoreKey}`;
  }

  return currentPath;
}

export default function adapter(currentOrgScore, { node, viewingDropdownFilter }) {
  // Get the default viewing dimension from the FT node config
  // Note the viewing block is in place, we should show data for the current selected dimension
  // instead of the default
  // TODO: remove the following when viewing block is in place.
  const scoreKey = get(viewingDropdownFilter, '0');

  if (scoreKey) {
    const dimensions = get(node, 'metadata.portal_data.dimensions');

    const value = get(currentOrgScore, dimensionPath(dimensions, scoreKey));
    const scoreKey1 = get(viewingDropdownFilter, '1');
    if (scoreKey1) {
      return get(value, scoreKey1);
    } else {
      return value;
    }
  }

  return currentOrgScore;
}
