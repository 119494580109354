import get from 'lodash/get';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';
import castArray from 'lodash/castArray';

import { currentOrgYearScores } from '../../../scoresHelpers';

import conditional from '../../../conditional';

const adapter = (options) => {
  const { node, entities, entityScores = [], baseUrl, config = {}, organization, scores } = options;
  const { sortByProperty, addParentOrgScore = false } = config;
  const shape = conditional(node.scoring_options.shape, {}, options);
  const data = entities && reduce(entities, (acc, entity) => {
    const score = currentOrgYearScores(entityScores, entity, node);
    const value = score && get(score, 'value.value', get(score, 'value', 0));
    const label = entity.name;
    if (score) {
      acc.push(
        {
          label,
          organization: entity,
          baseUrl,
          value: [{
            label: shape.label,
            key: entity.id,
            colorIndex: shape.colorIndex || 0,
            value
          }]
        }
      );
    }
    return acc;
  }, []);

  const sortedData = sortByProperty ? sortBy(data, castArray(sortByProperty)) : data;

  if (addParentOrgScore) {
    const parentScore = currentOrgYearScores(scores, organization, node);
    if (parentScore) {
      const parentValue = get(parentScore, 'value.value', get(parentScore, 'value', 0));
      sortedData.unshift({
        label: organization.name,
        organization,
        value: [{
          label: shape.label,
          key: organization.id,
          colorIndex: shape.colorIndex || 0,
          value: parentValue
        }]
      });
    }
  }

  return sortedData;
};

export default adapter;
