import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import List from 'cui/lib/components/List';

import currentOrgScores from '../../helpers/scoresHelpers';
import applyAdapters from '../../helpers/adapterHelpers';

export default function KdeListChart(props) {
  const {
    organization,
    scores,
    additionalChartAdapters,
    config = {}
  } = props;
  const score = get(currentOrgScores(scores, organization), 'value');
  const adapterName = get(config, 'adapter');

  if (isEmpty(adapterName)) {
    return null;
  }

  const data = applyAdapters(adapterName, score, {
    chartConfig: config,
    additionalChartAdapters
  });

  return (
    <List divider>
      {
        data.map(item => (
          <List.Item key={item.name}>{item.name}</List.Item>
        ))
      }
    </List>
  );
}

KdeListChart.propTypes = {
  organization: PropTypes.object,
  scores: PropTypes.arrayOf(PropTypes.object),
  additionalChartAdapters: PropTypes.object,
  config: PropTypes.object
};
