import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import ChartEmptyState from '../Chart/ChartEmptyState';
import emptyStateMessage from '../../helpers/emptyState';
import Gauge from '../../ui/Gauge';

function AcctGaugeStudentGroupDetails(props) {
  const {
    config,
    node,
    organization,
    directScore,
    viewingDropdownFilter,
    isLoading
  } = props;

  const filter = viewingDropdownFilter[0];
  const scores = get(directScore, `value.dimensions.${filter}`, null);

  if (!scores) {
    return <ChartEmptyState emptyMessage={emptyStateMessage(node, organization)} />;
  }

  const items = [{ level: 1, list: [] }, { level: 2, list: [] }, { level: 3, list: [] }, { level: 4, list: [] }, { level: 5, list: [] }];
  let noScoreValues = true;

  config.dimensions.map(lel => {
    if (get(scores, `dimensions.${lel}.score.${config.scoreKey}`, null) != null) {
      if (+get(scores, `dimensions.${lel}.score.${config.scoreKey}`, null) > 0) {
        items[+get(scores, `dimensions.${lel}.score.${config.scoreKey}`, null) - 1].list.push(config.labels[lel]);
        noScoreValues = false;
      }
    }
    return lel;
  });

  if (noScoreValues) {
    return <ChartEmptyState emptyMessage={emptyStateMessage(node, organization)} />;
  }

  return (
    <div>
      <div className="gatewayGrid-5charts">
        {
          items.map(value => (
            <Gauge
              score={value.level}
              isLoading={isLoading}
            />
          ))
        }
      </div>
      <div className="gatewayGrid-5charts">
        {
          items.map(value => (
            <div className="gaugeChildren">
              {
                value.list.length > 0 ?
                  value.list.map(item => (<div>{item}</div>)) :
                  <div>No Students</div>
              }
            </div>
          ))
        }
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    scores: [],
    framework: get(state, 'framework.kde')
  };
}

export default connect(mapStateToProps)(AcctGaugeStudentGroupDetails);

AcctGaugeStudentGroupDetails.propTypes = {
  config: PropTypes.object,
  framework: PropTypes.object,
  node: PropTypes.object,
  // Score data
  scores: PropTypes.arrayOf(
    PropTypes.shape({
      remote_organization_id: PropTypes.number.isRequired,
      value: PropTypes.object.isRequired
    })
  ),
  organization: PropTypes.object,
  isLoading: PropTypes.bool
};
