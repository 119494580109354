import React from 'react';
import PropTypes from 'prop-types';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import flattenDeep from 'lodash/flattenDeep';
import cx from 'classnames';

import { requestBlob } from '../../helpers/request';
import styles from './index.module.scss';

class DownloadAllButton extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      downloadedCount: 0,
      filesCount: 0,
      failedDownloads: []
    };
    this.onDownloadAll = this.onDownloadAll.bind(this);
    this.addDataItemToArchive = this.addDataItemToArchive.bind(this);
  }

  onDownloadAll() {
    const { dataSets } = this.props;

    const archive = new JSZip();
    const loadingFilesPromises = flattenDeep(dataSets.map(dataSet => this.addDataItemToArchive(dataSet, archive)))
      .filter(Boolean);
    this.setState({ isLoading: true, filesCount: loadingFilesPromises.length, failedDownloads: [] });

    Promise.all(loadingFilesPromises).then(() => archive.generateAsync({ type: 'blob' }).then((content) => {
      FileSaver.saveAs(content, 'all_datasets.zip');
    })).finally(() => this.setState({
      isLoading: false,
      downloadedCount: 0,
      filesCount: 0
    }));
  }

  addDataItemToArchive(dataItem, archive) {
    if (dataItem.type === 'LINK') return null;

    if (dataItem.type === 'GROUP') {
      const groupFolder = archive.folder(dataItem.name);
      return dataItem.items.map(dataSubItem => this.addDataItemToArchive(dataSubItem, groupFolder));
    }

    const originalFileName = dataItem.url.match(/[^/]*$/);
    const fileName = originalFileName ? originalFileName[0] : `${dataItem.name}.csv`;

    return requestBlob(dataItem.url)
      .then((text) => {
        archive.file(fileName, text);
        this.setState(({ downloadedCount }) => ({ downloadedCount: downloadedCount + 1 }));
        return Promise.resolve();
      }).catch(() => {
        this.setState(({ failedDownloads }) => ({
          failedDownloads: [...failedDownloads, dataItem.name]
        }));
        return Promise.resolve();
      });
  }

  render() {
    const { isLoading, downloadedCount, filesCount, failedDownloads } = this.state;

    return (
      <div className="cui-margin-bottom-xLarge">
        <button
          className={cx('cui-btn cui-btn_stroked cui-btn_secondary', styles.rounded)}
          onClick={this.onDownloadAll}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Download all'}
        </button>
        {isLoading && (
          <div className="cui-margin-top-xSmall">
            Download {downloadedCount} out of {filesCount} files is in progress. Do not leave the page.
          </div>
        )}
        {!!failedDownloads.length && (
          <div className={cx('cui-text_small cui-margin-top-xSmall', styles.error)}>
            Failed to download:
            <ul>
              {failedDownloads.map(failedDownload => <li>{failedDownload}</li>)}
            </ul>
            <div>Please, download them separately or try again later.</div>
          </div>
        )}
      </div>
    );
  }
}

DownloadAllButton.propTypes = {
  dataSets: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      type: PropTypes.oneOf(['GROUP', 'ITEM']).isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      items: PropTypes.array
    })
  ).isRequired
};

export default DownloadAllButton;
