/* eslint-disable no-param-reassign */

import find from 'lodash/find';
import filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import compact from 'lodash/compact';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import flattenDeep from 'lodash/flattenDeep';
import get from 'lodash/get';

export const findNode = (framework, dimensionSlugs, nodeSlugs) => {
  dimensionSlugs = compact(flatten([dimensionSlugs]));
  nodeSlugs = compact(flatten([nodeSlugs]));
  const dimensionSlug = dimensionSlugs.shift();
  const nodeSlug = nodeSlugs.shift();
  const node = find(framework.items, {
    node_type: dimensionSlug,
    slug: nodeSlug });
  if (nodeSlugs.length > 0) {
    return findNode(node, dimensionSlugs, nodeSlugs);
  } else {
    return node;
  }
};

export const findDimensionNodes = (framework, dimensionSlug) => {
  const filterItem = framework ? framework.items : [];
  return sortBy(filter(filterItem, { node_type: dimensionSlug }), ['index']);
};

function* processTree(items) {
  if (!items) { return; }

  for (let i = 0; i < items.length; i += 1) {
    const val = items[i];
    yield val;

    if (val.items) {
      yield* processTree(val.items);
    }
  }
}

export const findNodeBySlug = (node, slug) => {
  let i,
    item,
    result;

  if (slug === node.slug) return node;

  for (i = 0; i < (node.items || []).length; i += 1) {
    item = node.items[i];

    result = findNodeBySlug(item, slug);

    if (result) return result;
  }
  return false;
};

export const findNodeOfTypeBySlug = (node, slug, nodeType = 'domain') => {
  if (!slug) return null;

  let i,
    item,
    result;

  if (slug === node.slug && nodeType === node.node_type) {
    return node;
  }

  for (i = 0; i < (node.items || []).length; i += 1) {
    item = node.items[i];

    result = findNodeOfTypeBySlug(item, slug, nodeType);

    if (result) return result;
  }
  return false;
};

/* eslint-disable consistent-return */
export const findNodeByNodePath = (node, nodePath, options = {}) => {
  try {
    if (!node || !nodePath) return;
    if (node.node_path === nodePath) {
      return options.keepChildren ? node : omit(node, 'items');
    }
    let searchedNode;

    const it = processTree(node.items);
    let res = it.next();

    while (!res.done) {
      if (res.value.node_path === nodePath) {
        searchedNode = options.keepChildren ? res.value : omit(res.value, 'items');
      }
      res = it.next();
    }

    return searchedNode;
  } catch (e) {
    console.warn(`error in findNodeByNodePath: ${e}`);
    return {};
  }
};
/* eslint-enable consistent-return */

export const findAnyDimensionNodes = (framework, dimensionSlug, nodes = []) => {
  const filterItem = framework ? framework.items : [];

  (filterItem || []).forEach((item) => {
    if (item && item.node_type === dimensionSlug) {
      nodes.push(item);
    }
    if (item && !isEmpty(item.items)) {
      findAnyDimensionNodes(item, dimensionSlug, nodes);
    }
  });
  return nodes;
};

export const findNodeChildren = (framework, dimensionSlugs, nodeSlugs) => {
  if (nodeSlugs) {
    return sortBy(findNode(framework, dimensionSlugs, nodeSlugs).items, ['index']);
  } else {
    return findDimensionNodes(framework, dimensionSlugs);
  }
};

/**
 * Traverses tree down to node path, stopping at the dimension
 */
export const digToNode = (node, nodePath, dimensionSlug) => {
  if (isEmpty(node)) return null;
  if (node.node_type === dimensionSlug) return node;

  const nextNode = find(node.items, childNode => nodePath.startsWith(childNode.node_path));
  return digToNode(nextNode, nodePath, dimensionSlug);
};

/**
 * Starting at a node, traverses up to the root node building an array of all ancestor nodes
 * Given:
 *   currentNode.node_path === 'foo.bar.baz.qux'
 * Returns:
 *   [
 *     { node_path: 'foo.bar.baz.qux', ... },
 *     { node_path: 'foo.bar.baz', ... },
 *     { node_path: 'foo.bar', ... },
 *     { node_path: 'foo', ... }
 *   ]
 */
export const nodeWithAncestors = (framework, currentNode) => {
  let index = 0;
  const nodes = [];

  const getAncestors = (node) => {
    if (isEmpty(node)) return nodes;

    const parentNode = findNodeByNodePath(framework, node.parentNodePath);
    node.index = index;
    index += 1;
    nodes.push(node);

    return getAncestors(parentNode);
  };

  return getAncestors(currentNode);
};

const processNode = (node, parent) => {
  node.address = {
    ...parent.address,
    [node.node_type]: {
      slug: node.slug,
      name: node.name,
      node_path: node.node_path
    }
  };
  return {
    ...node,
    ...node.metadata,
    parentNodePath: parent.node_path,
    index: parseInt((node.metadata && node.metadata.index) || 0),
    items: sortBy(get(node, 'items', []).map(item => processNode(item, node)), ['index'])
  };
};

export const processFramework = (framework) => {
  framework.address = {
    framework: { slug: framework.slug, name: framework.name, node_path: framework.node_path }
  };
  return {
    ...framework,
    ...framework.metadata,
    items: sortBy(framework.items && framework.items.map(item => processNode(item, framework)), ['index'])
  };
};

const getItems = data => get(data, 'items', []);

export const getAllDomains = framework => getItems(framework);

const getAllSuccessIndicators = framework => flattenDeep(getAllDomains(framework).map(getItems));

const getAllVariables = framework => flattenDeep(getAllSuccessIndicators(framework).map(getItems));

export const getAllDataPoints = (framework) => {
  // pull in generic data points defined at the top level framework that don't belong to any variable
  const dataPointsAtFrameworkLevel = get(framework, 'metadata.dataPoints', []).map(node => ({
    ...node,
    ...node.metadata
  }));

  return dataPointsAtFrameworkLevel.concat(flattenDeep(getAllVariables(framework).map(getItems)));
};
