import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'cui';
import { scoreInfoPropType } from '../../../helpers/collectorHelpers';
import EmptyWrapper from '../EmptyWrapper';
import sectionHoc from './sectionHoc';

export const WorkplaceEthics = ({ scores: { ethicsProgram, ethicsProgramWebsiteUrl, ethicsRequirements } }) => (
  <EmptyWrapper scoreInfos={{ ethicsProgram, ethicsProgramWebsiteUrl, ethicsRequirements }}>
    {() => (
      <section className="section" aria-labelledby="workplace_ethics_section_aria_label">
        <h2 id="workplace_ethics_section_aria_label">Workplace Ethics</h2>
        <EmptyWrapper scoreInfo={ethicsProgram}>
          {({ scoreInfo, notReported }) => (
            <div className="grid-cell">
              <h3 className="fieldLabel">Describe your schools Workplace Ethics Program.</h3>
              <div className="field">
                {notReported || (
                  <span className="fieldValue" data-test="ethicsProgram">{scoreInfo.value}</span>
                )}
              </div>
            </div>
          )}
        </EmptyWrapper>
        <EmptyWrapper scoreInfo={ethicsProgramWebsiteUrl}>
          {({ scoreInfo, notReported }) => (
            <div>
              <h3 className="fieldLabel">Workplace Ethics Program Website URL.</h3>
              <div className="field">
                {notReported || (scoreInfo.value && (
                  <a href={scoreInfo.value}>
                    <span className="cui-label_inline">{scoreInfo.value || scoreInfo.value.replace(/^(http|https):\/\//, '')}</span>
                    <Icon name="bb-arrow-box-top" />
                  </a>
                ))}
              </div>
            </div>
          )}
        </EmptyWrapper>
        <EmptyWrapper scoreInfo={ethicsRequirements}>
          {({ scoreInfo, notReported }) => (
            <div>
              <h3 className="fieldLabel">
                  What percent of students at your school have attained Local Board approved essential workplace ethics requirements?
              </h3>
              <div className="field">
                {notReported || (
                  <span className="fieldValue" data-test="ethicsRequirements">{scoreInfo.value}%</span>
                )}
              </div>
            </div>
          )}
        </EmptyWrapper>
      </section>
    )}
  </EmptyWrapper>
);

WorkplaceEthics.propTypes = {
  scores: PropTypes.shape({
    ethicsProgram: scoreInfoPropType,
    ethicsProgramWebsiteUrl: scoreInfoPropType,
    ethicsRequirements: scoreInfoPropType
  })
};

export default sectionHoc(WorkplaceEthics);
