import React from 'react';
import PropTypes from 'prop-types';
import pbkdf2 from 'pbkdf2';
import cookie from 'js-cookie';

import styles from './index.module.scss';

// Hashed password is hardcoded in the frontend for now. This isn't great, but
// it's no worse than how the preview SRC site works (since enterprising users
// can patch isPreviewSite to true on the non-preview SRC to get it to act like
// the preview SRC).
/* eslint-disable react/static-property-placement */
const ENCRYPTED_PASSWORD = '46f86c3057bf19428970e34cde63bc10d95a374c87e82996b0281a79e932df6b';
const SALT = '7e7bff3295e2f595e9d4a413000d51349c9d8ba2f890e23988b0d7a0c1011a66d9df0fffd68b2ec7c72fa0013b00c09b127a781d9d77261f9bf7853f463ec615';
const ITERATIONS = 10000;
const KEY_LENGTH = 32;
const ALGORITHM = 'sha256';

const COOKIE_NAME = 'kdePasswordGateCookie';

const encryptPassword = password => pbkdf2.pbkdf2Sync(password, SALT, ITERATIONS, KEY_LENGTH, ALGORITHM).toString('hex');

export class RealPasswordGate extends React.Component {
  static propTypes = {
    children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
    cookie: PropTypes.object,
    correctEncryptedPassword: PropTypes.string,
    location: PropTypes.object
  };

  state = {
    password: '',
    wrongPassword: false,
    authenticating: true,
    authenticated: false
  };

  componentDidMount() {
    // It would be better to store the plaintext password so that the user
    // can't extract the hashed password from the code and set it into the
    // cookie by hand. But, then we would have to rehash the password on every
    // page load and that takes time. And besides, it's still frontend
    // authentication which is smoke and mirrors no matter how you dice it.
    if (this.props.cookie.get(COOKIE_NAME) === this.props.correctEncryptedPassword) {
      this.setState({ authenticating: false, authenticated: true }); // eslint-disable-line react/no-did-mount-set-state
    } else {
      this.setState({ authenticating: false, authenticated: false }); // eslint-disable-line react/no-did-mount-set-state
    }
  }

  passwordFieldChanged = (event) => {
    this.setState({
      password: event.target.value,
      wrongPassword: false // hide the message when the user starts typing a new password
    });
  }

  formSubmitted = (event) => {
    event.preventDefault();

    const encryptedUserEnteredPassword = encryptPassword(this.state.password);
    if (encryptedUserEnteredPassword === this.props.correctEncryptedPassword) {
      // Password was right; set it into a cookie, then reload. We use cookies
      // instead of localstorage so that this works in Safari's private
      // browsing mode which blocks localstorage.
      this.props.cookie.set(COOKIE_NAME, encryptedUserEnteredPassword);
      this.props.location.reload();
    } else {
      // Nope, wrong password. Clear the field and show the wrong password
      // message.
      this.setState({
        password: '',
        wrongPassword: true
      });
    }
  }

  render() {
    if (this.state.authenticating) {
      return null;
    } else if (this.state.authenticated) {
      return this.props.children;
    } else {
      let wrongPasswordMessage = null;
      if (this.state.wrongPassword) {
        wrongPasswordMessage = (
          <div className={styles.passwordError}>
            <p className={styles.alertBox}>Wrong password, please try again.</p>
          </div>
        );
      }

      return (
        <div className={styles.passwordGate}>
          <div className={styles.main}>
            <div className={styles.card}>
              <div className={styles.header}>
                <h1>Password protected site</h1>
              </div>
              <div className={styles.body}>
                <p className={styles.lead}>Please enter your password to get access.</p>
                {wrongPasswordMessage}
                <form onSubmit={this.formSubmitted}>
                  <input type="password" name="password" placeholder="Password" onChange={this.passwordFieldChanged} value={this.state.password} />
                  <button type="submit" className={styles.button}>Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

const PasswordGate = ({ children }) => (
  <RealPasswordGate
    cookie={cookie}
    correctEncryptedPassword={ENCRYPTED_PASSWORD}
    location={window.location}
  >
    {children}
  </RealPasswordGate>
);

PasswordGate.propTypes = {
  children: PropTypes.any // eslint-disable-line react/forbid-prop-types
};

export default PasswordGate;
