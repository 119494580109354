import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import get from 'lodash/get';
import merge from 'lodash/merge';
import some from 'lodash/some';
import isEqual from 'lodash/isEqual';

import connected from '../connected';
import { setCurrentMatch } from '../../common_actions';
import template from '../../helpers/template';
import paramsParser from '../../helpers/paramsParser';
import { initialDataFetched } from '../../store/sagas';

export class Page extends React.Component {
  constructor(props) {
    super(props);
    let actions = props.initActions;
    if (get(props, 'orgFilter.organization.id') || get(props, 'match.params.id')) {
      actions = actions.concat(props.actions);
    }
    props.initPage(actions, props.match.params);
    props.setCurrentMatch(props.match, props.location.search);

    this.setPageTitle(props);
  }

  componentWillReceiveProps(nextProps) {
    this.setPageTitle(nextProps);

    if (this.props.location !== nextProps.location) {
      this.props.setCurrentMatch(nextProps.match, nextProps.location.search);

      const oldParams = paramsParser(this.props.location.search, this.props.match.params);
      const newParams = paramsParser(nextProps.location.search, nextProps.match.params);
      const paramsChanges = some(nextProps.actionParams, param => !isEqual(newParams[param], oldParams[param]));
      const actionsToRun = [...this.props.actions, ...this.props.actionParamActions];
      if (paramsChanges) {
        this.props.initPage(actionsToRun, nextProps.match.params);
      }
      this.props.initPage(this.props.actions, nextProps.match.params);
    }
    if (this.shouldReload(nextProps)) {
      this.props.initPage(this.props.actions, nextProps.match.params);
    }
  }

  setPageTitle(props = this.props) {
    try {
      props.setDocumentTitle(template(props.title, props));
    } catch (e) {
      console.error(`Error setting page title ${e}`); // eslint-disable-line no-console
    }
  }

  // Reload data when either organization or time slice change
  shouldReload(nextProps) {
    return (get(nextProps, 'orgFilter.organization.id') !== get(this.props, 'orgFilter.organization.id')) ||
      (get(nextProps, 'orgFilter.timeSlice.id') !== get(this.props, 'orgFilter.timeSlice.id'));
  }

  render() {
    const { children, route, routes, propMap } = this.props;
    if (children) {
      return children;
    } else {
      const Layout = routes[route];
      return <Layout propMap={propMap} />;
    }
  }
}

const mapDispatchToProps = dispatch => ({
  initPage: async (actions, params = {}) => {
    await initialDataFetched;
    (actions || []).forEach(a => dispatch(merge(a, params)));
  },
  setCurrentMatch: (match, search) => {
    dispatch(setCurrentMatch(match, search));
  },
  setDocumentTitle: (title) => {
    document.title = title;
  }
});

Page.propTypes = {
  title: PropTypes.string,
  initActions: PropTypes.arrayOf(PropTypes.object),
  actions: PropTypes.arrayOf(PropTypes.object),
  initPage: PropTypes.func.isRequired,
  setCurrentMatch: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }),
  location: PropTypes.shape({
    search: PropTypes.string
  }),
  actionParams: PropTypes.arrayOf(
    PropTypes.string
  ),
  route: PropTypes.string,
  routes: PropTypes.object,
  children: PropTypes.node,
  actionParamActions: PropTypes.arrayOf(
    PropTypes.object
  ),
  propMap: PropTypes.objectOf(PropTypes.string)
};

Page.defaultProps = {
  actions: [],
  initActions: [],
  title: '',
  actionParams: [],
  actionParamActions: []
};

export default withRouter(connected(connect(null, mapDispatchToProps)(Page)));
