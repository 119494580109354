import get from 'lodash/get';
import React from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import find from 'lodash/find';
import last from 'lodash/last';

import connected from '../components/connected';

import {
  BOARD_MEMBERS_INFO_MAP,
  FEDERAL_CLASSIFICATION_MAPPED_REASONS,
  SCHOOL_LEVELS,
  KENTUCKY_ORG_ID,
  ORG_TYPES,
  FRAMEWORK_NODE_PATHS
} from '../constants';
import { numberWithCommas } from './numbers';
import { getOrgIdFromOrganization } from './portalDataServiceHelpers';
import { gapsOutBoundLink, achievementGaps } from './acctHelpers';
import { getYearSpecificCopy } from './frameworkTreeTraversalHelper';

export const isOrgSchool = (org = {}) => ORG_TYPES[org.entity_type.toUpperCase()] === ORG_TYPES.SCHOOL;
export const isOrgState = (org = {}) => ORG_TYPES[org.entity_type.toUpperCase()] === ORG_TYPES.STATE;
export const isOrgDistrict = (org = {}) => ORG_TYPES[org.entity_type.toUpperCase()] === ORG_TYPES.DISTRICT;

export const getClassification = props => get(props, 'currentOrganization.sch_type', null);

const getBoardMembers = (props) => {
  const boardMembers = get(props, 'currentOrganization.board_members');

  // INFO: Schools should display generic info if board members are not (or don't need to be) defined
  const shouldReturnGenericInfo = BOARD_MEMBERS_INFO_MAP[boardMembers];

  if (shouldReturnGenericInfo) {
    return [BOARD_MEMBERS_INFO_MAP[boardMembers]];
  }

  return boardMembers ? boardMembers.split('|').filter(a => !!a) : [];
};

const getCrumbs = ({ orgType, orgName, orgId, parentOrgId, parentOrgName }) => {
  if (orgType === ORG_TYPES.STATE) return [];

  if (orgType === ORG_TYPES.DISTRICT) {
    return [
      { caption: 'Kentucky', href: `/organization/${KENTUCKY_ORG_ID}` },
      { caption: orgName, href: `/organization/${orgId}` }
    ];
  }

  return [
    { caption: 'Kentucky', href: `/organization/${KENTUCKY_ORG_ID}` },
    { caption: parentOrgName, href: `/organization/${parentOrgId}` },
    { caption: orgName, href: `/organization/${orgId}` }
  ];
};

const getHeadTitle = (orgType) => {
  switch (orgType) {
    case ORG_TYPES.SCHOOL:
      return 'Principal';
    case ORG_TYPES.DISTRICT:
      return 'Superintendent';
    case ORG_TYPES.STATE:
      return 'Commissioner';
    default:
      return '';
  }
};

const getStudentMembership = ({ scoresFromPortalDataService, currentOrganization, schoolYear }) => (
  get(scoresFromPortalDataService, [
    FRAMEWORK_NODE_PATHS[schoolYear].studentMembership,
    getOrgIdFromOrganization(currentOrganization),
    'scores',
    '0',
    'value',
    'count'
  ], '')
);

export const getSummary = (props, orgType) => {
  const schoolYear = props.schoolYear;
  const districtCount = get(props, 'currentOrganization.district_count', '');
  const highGrade = get(props, 'currentOrganization.high_grade', '');
  const lowGrade = get(props, 'currentOrganization.low_grade', '');
  const schoolCount = get(props, 'currentOrganization.school_count', '');
  const studentMembershipCount = numberWithCommas(getStudentMembership(props));

  let summary = [
    {
      icon: 'kde-student',
      label: studentMembershipCount ? `Students: ${studentMembershipCount}` : 'Information Not Available'
    }, {
      icon: 'kde-grades',
      label: (lowGrade && highGrade) ? `Grades ${lowGrade}-${highGrade}` : 'Grade Range Not Available'
    }
  ];

  if (orgType === ORG_TYPES.DISTRICT || orgType === ORG_TYPES.STATE) {
    summary = [
      {
        icon: 'kde-school',
        label: schoolCount ? `School Count: ${schoolCount}` : 'School Count Not Available'
      },
      ...summary
    ];
  }

  const yearsDistrictCount = {
    2018: 173,
    2019: 173,
    2020: 172,
    2021: 171
  };

  if (orgType === ORG_TYPES.STATE) {
    summary = [
      {
        icon: 'kde-school',
        label: districtCount ?
          `District Count: ${get(yearsDistrictCount, schoolYear, last(Object.values(yearsDistrictCount)))}` : 'District Count Not Available'
      },
      ...summary
    ];
  }

  return summary;
};

const getAddress = props => ({
  districtCode: get(props, 'currentOrganization.dist_number', ''),
  city: get(props, 'currentOrganization.city', ''),
  fax: get(props, 'currentOrganization.fax', ''),
  phone: get(props, 'currentOrganization.phone', ''),
  schoolCode: get(props, 'currentOrganization.sch_cd', ''),
  state: get(props, 'currentOrganization.state', ''),
  street: get(props, 'currentOrganization.address', ''),
  zip: get(props, 'currentOrganization.zipcode', '')
});

// Note: this is a quick way to find federal classification information, ideally we should read from the framework
const MULTIPLE_CLASSIFICATION = 'This school has multiple Federal Classifications';
const CLASSIFICATION_NOT_APPLICABLE = 'Not Applicable';
const YEARS_WITHOUT_ACHIEVEMENT_GAP_DATA = 2018;

function achievementGapData(organization, level, year) {
  // Year 2018 doesn't have achievement gap data
  if (year > YEARS_WITHOUT_ACHIEVEMENT_GAP_DATA) {
    const orgDatum = find(organization.organization_data, { year });

    const gaps = achievementGaps(get(orgDatum, ['gap_demo', level]));
    return isEmpty(gaps) ? null : ({
      data: gaps,
      linkTo: gapsOutBoundLink(organization.id, year)
    });
  }

  return null;
}

// @todo: this is the solution for now (as the first part of improving performance - including skeleton screens)
// we need to pass the information that scores is evaluating (it's component(s))
export const federalClassificationInfo = (organization, year, levels, framework) => {
  const FED_CLASS = get(organization, 'fed_class') || CLASSIFICATION_NOT_APPLICABLE;
  let federalClassificationText = '';

  // Some school has multiple federal classifications seperated by commas
  // NOTE: maybe we should update score data to use 'Not Applicable' instead of '#N/A'
  // when federal classification is not applicable.
  if (FED_CLASS === CLASSIFICATION_NOT_APPLICABLE) {
    federalClassificationText = CLASSIFICATION_NOT_APPLICABLE;
  }

  // Quick exit when we do not have any data
  const orgDatum = find(organization.organization_data, { year });
  if (isUndefined(orgDatum)) {
    return {
      text: federalClassificationText,
      classifications: [],
      isLoading: true
    };
  }

  const schoolLevels = levels || Object.keys(orgDatum.tsi_csi);
  const classifications = schoolLevels
    .filter(level => !!get(orgDatum, ['tsi_csi', level]))
    .map((level) => {
      const tsiCsi = get(orgDatum, ['tsi_csi', level]);
      const reason = get(orgDatum, ['tsi_csi_reason', level], '');

      return {
        gaps: achievementGapData(organization, level, year),
        reasons: reason
          .split(';')
          .filter(val => !!val)
          .map(r => FEDERAL_CLASSIFICATION_MAPPED_REASONS[r.trim()]),
        name: getYearSpecificCopy(framework, `fedClassification.name.${tsiCsi}`),
        description: getYearSpecificCopy(framework, `fedClassification.description.${tsiCsi}`),
        level: SCHOOL_LEVELS[level],
        tsiCsi
      };
    });

  return {
    text: classifications.length > 1 ? MULTIPLE_CLASSIFICATION : get(classifications, '0.name', federalClassificationText),
    classifications
  };
};

const ConnectedOrgDataProvider = connected((props) => {
  if (!props.currentOrganization || typeof props.children !== 'function') return null;

  const orgId = props.currentOrganization.id;
  const orgType = ORG_TYPES[props.currentOrganization.entity_type.toUpperCase()];

  const schoolYear = props.schoolYear;
  const address = getAddress(props);
  const allOrganizations = props.organizations;
  const boardMembers = getBoardMembers(props);
  const classification = getClassification(props);
  const isScoresLoading = props.isScoresLoading;
  const featureFlags = props.featureFlags;
  const federalClassification = federalClassificationInfo(
    props.currentOrganization,
    schoolYear,
    null,
    props.framework
  );
  const framework = props.framework;
  const headEmail = get(props, 'currentOrganization.email', null);
  const headName = get(props, 'currentOrganization.contact_name', null);
  const headTitle = getHeadTitle(orgType);
  const organization = props.currentOrganization;
  const orgName = props.currentOrganization.name;
  const parentOrgId = get(props, 'currentOrganization.parentDistrict.id', null);
  const parentOrgName = get(props, 'currentOrganization.parentDistrict.name', null);
  const summary = getSummary(props, orgType);
  const websiteUrl = props.currentOrganization.website_url;
  const title1 = get(props, 'currentOrganization.title1_status', null);

  const crumbs = getCrumbs({ orgType, orgName, orgId, parentOrgId, parentOrgName });

  const schoolStat = get(props, 'schoolStat');

  return props.children({
    address,
    allOrganizations,
    boardMembers,
    classification,
    crumbs,
    featureFlags,
    federalClassification,
    framework,
    isScoresLoading,
    headEmail,
    headName,
    headTitle,
    organization,
    orgName,
    orgType,
    schoolYear,
    summary,
    title1,
    schoolStat,
    websiteUrl
  });
});

const OrgDataProvider = props => (
  <ConnectedOrgDataProvider
    {...props}
    awaitProps={['currentOrganization', 'organizations']}
    propMap={{
      currentOrganization: 'module.currentOrganization',
      organizations: 'module.organizations',
      scoresFromPortalDataService: 'module.scoresFromPortalDataService',
      schoolYear: 'module.schoolYear',
      featureFlags: 'module.pageContext.featureFlags',
      framework: 'framework.kde'
    }}
  />
);

export default OrgDataProvider;
