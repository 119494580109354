import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import Icon from 'cui/lib/components/Icon';

import ListItem from './ListItem';
import styles from './index.module.scss';

class SuccessIndicatorDataSets extends React.Component {
  state = {
    open: false
  };

  render() {
    const {
      successIndicatorData: { name, items }
    } = this.props;

    const { open } = this.state;

    return (
      <div>
        <div className={styles.successIndicatorHeader}>
          <button
            className={cx({ 'cui-btn cui-toggleLink cui-btn_icon': true, 'is-closed': !open })}
            type="button"
            title="toggle child rows"
            onClick={() => this.setState({ open: !open })}
          >
            <div className="cui-toggleLink-icon">
              <Icon name={open ? 'bb-minus' : 'bb-plus2'} />
            </div>
            <div className="cui-toggleLink-text cui-text_base">{name}</div>
          </button>
        </div>

        <div className={cx({ [styles.successIndicatorBody]: true, 'cui-indent': true, 'is-closed': !open })}>
          {items.map(item => <ListItem key={item.name} item={item} />)}
        </div>
      </div>
    );
  }
}

SuccessIndicatorDataSets.propTypes = {
  successIndicatorData: PropTypes.shape({
    name: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    items: PropTypes.array
  })
};

export default SuccessIndicatorDataSets;
