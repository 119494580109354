import React from 'react';
import PropTypes from 'prop-types';

First.propTypes = {
  children: PropTypes.node
};

function First({ children }) {
  return (
    <div className="orgPanel-first">
      { children }
    </div>
  );
}

First.defaultProps = {
  'data-slotName': 'First'
};

export default First;
