/* eslint-disable import/prefer-default-export */
import merge from 'lodash/merge';

export function portalDataReducer(state, action) {
  // Since we make a new request to fetch data for each organization
  // Merge scores instead of overwrite them
  // so that we can display data for multiple organizations at the same time
  // For example, the organization comparisons
  return {
    ...state,
    [action.stateKey]: action.mergePortalData ? merge({}, state[action.stateKey], action.data) : action.data
  };
}

export function currentSchoolYearReducer(state, action) {
  if (!action.payload.year) return state;
  return {
    ...state,
    schoolYear: Number(action.payload.year)
  };
}
