/*
* validScoreValue returns true if the given value is truthy or 0.
*   example of 0 being a valid score value: FREE apps have cost 0.
*
* the following values are falsy in javascript:
* [
*   false // false is a valid score (ie, kde bool collected scores),
*   0, // remember: 0 is falsy in javascript, but a valid score value!
*   '',
*   "",
*   null,
*   undefined,
*   NaN
* ]
*/
const validScoreValue = value => !!value || value === 0 || value === false;

export default validScoreValue;
