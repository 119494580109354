import get from 'lodash/get';
import { processFramework, findNodeOfTypeBySlug } from '../../helpers/frameworkHelpers';
import { SET_FRAMEWORK_DATA } from '../../common_actions';

const DEFAULT_STATE = {};

function frameworkReducer(state = DEFAULT_STATE, action = {}) {
  if (action.type === SET_FRAMEWORK_DATA) {
    const data = get(action.data, 'framework_tree', action.data);

    return {
      ...state,
      [data.slug]: processFramework(data)
    };
  } else if (action.type === 'SET_CURRENT_NODE') {
    let parentNode = get(state, action.framework, {});
    const { parentSlug, parentNodeType } = action;
    if (parentSlug && parentNodeType) {
      parentNode = findNodeOfTypeBySlug(parentNode, parentSlug, parentNodeType);
    }
    return {
      ...state,
      currentNode: findNodeOfTypeBySlug(parentNode, action.domain, get(action, 'nodeType'))
    };
  } else if (action.type === 'UNSET_CURRENT_NODE') {
    return {
      ...state,
      currentNode: undefined
    };
  } else {
    return state;
  }
}

export default frameworkReducer;
