import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cookie from 'js-cookie';
import { throttle } from 'lodash';

import { removeNode, removeScript, addScript } from '../../helpers/domHelpers';

const TRANSLATION_WIDGET_SRC = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
const langTogglerID = 'google_translate_element';

/* eslint-disable no-undef,no-new */
function googleTranslateElementInit() {
  const langTogglerElement = document.getElementById(langTogglerID);
  if (langTogglerElement && !langTogglerElement.childElementCount) {
    new google.translate.TranslateElement({
      includedLanguages: 'en,es'
    }, langTogglerID);
  }
}
/* eslint-enable no-undef,no-new */

const getCookieLanguage = () => {
  const cookieValue = cookie.get('googtrans');
  return cookieValue ? cookieValue.split('/')[2] : 'en';
};

class GoogleLanguageToggle extends PureComponent {
  componentDidMount() {
    // Add global callback for google widget
    window.googleTranslateElementInit = googleTranslateElementInit;
    // Add a script element to load google translation widget
    addScript(TRANSLATION_WIDGET_SRC);

    // INFO: Google Translate Widget behaves erratically with SPAs and translations are crooked…
    // It's most noticeable on changing language, navigating to some other page and switching back to
    // the first language. I couldn't find any other way to get translations consistent then to
    // reload the page after language change (which itself is detected when DOM changes, since there's
    // no simple API available to do that).
    this.currentLanguage = getCookieLanguage();
    this.footer = document.querySelectorAll('footer')[0];
    this.footer.addEventListener('DOMSubtreeModified', this.languageChangeListener);
  }

  componentWillUnmount() {
    delete window.googleTranslateElementInit;
    // Cleanup DOM elements created by translate widget
    removeScript(TRANSLATION_WIDGET_SRC);
    document.querySelectorAll('body > .skiptranslate, body > .goog-te-spinner-pos').forEach(el => removeNode(el));
    this.footer.removeEventListener('DOMSubtreeModified', this.languageChangeListener);
  }

  languageChangeListener = throttle(() => {
    const newLanguage = getCookieLanguage();
    if (this.currentLanguage !== newLanguage) {
      window.location.reload();
    }
  }, 2000)

  render() {
    const { className } = this.props;
    return (
      <div className={className}>
        <div id="google_translate_element" />
      </div>
    );
  }
}

GoogleLanguageToggle.propTypes = {
  className: PropTypes.string
};

export default GoogleLanguageToggle;
