import { createSelector } from 'reselect';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import get from 'lodash/get';
import find from 'lodash/find';
import memoize from 'lodash/memoize';
import startCase from 'lodash/startCase';

import { getSchoolLevels } from '../helpers/orgHelper';
import { KENTUCKY_ORG_ID } from '../constants';
import {
  getOrgType,
  getOrgIdFromOrganization,
  transformScoresFromPortalDataService
} from '../helpers/portalDataServiceHelpers';
import { federalClassificationInfo } from '../helpers/OrgDataProvider';
import {
  getIndicatorsForOrg,
  getAcctCut,
  getColorIndex,
  schoolLevelsForIndicator,
  indicatorOutBoundLink,
  achievementGaps
} from '../helpers/acctHelpers';
import { currentOrganizationSelector, currentNodesSelector, currentYearSelector } from './moduleSelector';
import { selectFramework } from './frameworkSelector';
import { portalScoreSelector } from './collector/portalScoreSelector';

// @param scores {array}, array of scores
// @param filters {object}, object of filter options
// @return first score filtered by filters
function currentScoreForOrg(scores, filters) {
  const filteredScores = filter(scores, filters);
  return isEmpty(filteredScores) ? null : filteredScores[0];
}

// @param org {object}, current organization
// @return a svcdataportal org id map for current organization including its parent organizations
export function generateOrgIdMap(org) {
  const orgIdMap = {
    [getOrgIdFromOrganization(org)]: org.id,
    State: KENTUCKY_ORG_ID
  };

  if (org.parentDistrict) {
    orgIdMap[getOrgIdFromOrganization(org.parentDistrict)] = org.parentDistrict.id;
  }

  return orgIdMap;
}

// @param score {object}, score object
// @param schoolLevel {string}, one of ['elementary_school', 'middle_school', 'high_school']
// @param scoreKey {string}, one of the indicator name
// @return the parsed value for an indicator at schoolLevel, return null if value is not a number.
function indicatorRate(score, schoolLevel, scoreKey) {
  const value = get(score, `value.dimensions.${schoolLevel}.score.${scoreKey}_rate`, null);
  return Number.isNaN(parseFloat(value)) ? null : parseFloat(value);
}

// @param score {object}, score object
// @param schoolLevel {string}, one of ['elementary_school', 'middle_school', 'high_school']
// @param scoreKey {string}, one of the indicator name
// @return the rating bucket name, which is one of ['Very Low', 'Low', 'Medium', 'High', 'Very High']
function indicatorLabel(score, schoolLevel, scoreKey) {
  return get(score, `value.dimensions.${schoolLevel}.score.${scoreKey}_rating`, '');
}

// @param scores {array}, array of scores
// @param currentOrganization {object}, organization object
// @param level {string}, one of ['elementary_school', 'middle_school', 'high_school']
// @param indicator {object}, indicator meta object { scoreKey: 'proficiency', title: 'Proficiency', link: 'proficiency' }
// @param schoolYear {number}, current school year
// @return an array of parent organizations achievement scores
function getParentData(scores, currentOrganization, level, indicator, schoolYear, scoreNodePath) {
  const chartScores = [];
  const orgType = getOrgType(currentOrganization);
  const { scoreKey, link: indicatorLink } = indicator;
  let achievementScore;
  let scoreLabel;

  if (orgType === 'school') {
    // Add district score if current org is a school
    const parentDistrictId = get(currentOrganization, 'parentDistrict.id');

    achievementScore = currentScoreForOrg(scores, {
      framework_tree_node: {
        node_path: scoreNodePath
      },
      remote_organization_id: parentDistrictId
    });

    scoreLabel = indicatorLabel(achievementScore, level, scoreKey);

    chartScores.push({
      label: `District${scoreLabel ? ' - ' : ''}${scoreLabel}`,
      score: indicatorRate(achievementScore, level, scoreKey),
      colorIndex: getColorIndex(scoreLabel),
      outboundLink: indicatorOutBoundLink(parentDistrictId, schoolYear, indicatorLink)
    });
  }

  if (orgType === 'district' || orgType === 'school') {
    // Add state score if current org is school or district
    achievementScore = currentScoreForOrg(scores, {
      framework_tree_node: {
        node_path: scoreNodePath
      },
      remote_organization_id: KENTUCKY_ORG_ID
    });

    scoreLabel = indicatorLabel(achievementScore, level, scoreKey);

    chartScores.push({
      label: `State${scoreLabel ? ' - ' : ''}${scoreLabel}`,
      score: indicatorRate(achievementScore, level, scoreKey),
      colorIndex: getColorIndex(scoreLabel),
      outboundLink: indicatorOutBoundLink(KENTUCKY_ORG_ID, schoolYear, indicatorLink)
    });
  }

  return chartScores;
}

const transformedScores = (scores, currentOrg, currentNodes) => {
  if (!isEmpty(scores) && !isEmpty(currentNodes) && currentOrg) {
    return transformScoresFromPortalDataService(scores, currentNodes, generateOrgIdMap(currentOrg));
  }
  return null;
};

export const transformedScoreSelector = createSelector(
  portalScoreSelector,
  currentOrganizationSelector,
  currentNodesSelector,
  (scores, currentOrg, currentNodes) => transformedScores(scores, currentOrg, currentNodes)
);

export const schoolStatSelector = createSelector(
  currentYearSelector,
  selectFramework,
  (currentYear, framework) => memoize((organization) => {
    if (!organization) {
      return [];
    }

    const organizationData = find(organization.organization_data, { year: currentYear });
    if (!organizationData) {
      return [];
    }

    return getSchoolLevels(organization, currentYear).map(level => ({
      level,
      heading: startCase(level),
      rating: {
        star: get(organizationData, ['star_rating', level]),
        gap_reduction: get(organizationData, ['gap_reduction', level])
      },
      fedClass: federalClassificationInfo(organization, currentYear, [level], framework),
      gaps: achievementGaps(get(organizationData, ['gap_demo', level]))
    }));
  })
);

export const chartScoreSelectorConfig = function configureChartScoreSelector(scoreNodePath) {
  return createSelector(
    transformedScoreSelector,
    currentOrganizationSelector,
    currentYearSelector,
    (scores, currentOrganization, currentYear) => {
      if (isEmpty(scores) || !currentOrganization) {
        return [];
      }

      const achievementScore = currentScoreForOrg(scores, {
        framework_tree_node: {
          node_path: scoreNodePath
        },
        remote_organization_id: currentOrganization.id
      });

      return getIndicatorsForOrg(currentOrganization).map(indicator => ({
        title: indicator.title,
        chartScoreKey: indicator.link,
        scores: schoolLevelsForIndicator(currentOrganization, indicator.scoreKey).map((level) => {
          let cuts;

          try {
            cuts = getAcctCut(currentYear, level, indicator.scoreKey);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log(e);
            cuts = {};
          }

          return {
            primary: {
              score: indicatorRate(achievementScore, level, indicator.scoreKey),
              title: startCase(level),
              label: indicatorLabel(achievementScore, level, indicator.scoreKey),
              colorIndex: getColorIndex(indicatorLabel(achievementScore, level, indicator.scoreKey)),
              outboundLink: indicatorOutBoundLink(currentOrganization.id, currentYear, indicator.link)
            },
            secondary: getParentData(scores, currentOrganization, level, indicator, currentYear, scoreNodePath),
            cuts
          };
        })
      }));
    }
  );
};

export const gaugeScoreSelectorConfig = function configureGaugeScoreSelector(scoreNodePath) {
  return createSelector(
    transformedScoreSelector,
    currentYearSelector,
    currentOrganizationSelector,
    selectFramework,
    (scores, currentYear, currentOrganization, framework) => {
      if (!currentOrganization) {
        return [];
      }

      const achievementScore = currentScoreForOrg(scores, {
        framework_tree_node: {
          node_path: scoreNodePath
        },
        remote_organization_id: currentOrganization.id
      });

      const organizationData = find(currentOrganization.organization_data, { year: currentYear });
      if (!organizationData) {
        return [];
      }

      return getSchoolLevels(currentOrganization, currentYear).map(level => ({
        level,
        heading: startCase(level),
        rating: {
          proficiency: indicatorLabel(achievementScore, level, 'proficiency'),
          seperate: indicatorLabel(achievementScore, level, 'seperate'),
          el: indicatorLabel(achievementScore, level, 'el'),
          qscs: indicatorLabel(achievementScore, level, 'qscs'),
          grad: indicatorLabel(achievementScore, level, 'grad'),
          psr: indicatorLabel(achievementScore, level, 'psr')
        },
        changes: {
          proficiency: indicatorLabel(achievementScore, level, 'rdma_change'),
          seperate: indicatorLabel(achievementScore, level, 'scsswr_change'),
          el: indicatorLabel(achievementScore, level, 'el_change'),
          qscs: indicatorLabel(achievementScore, level, 'qscs_change'),
          grad: indicatorLabel(achievementScore, level, 'grad_change'),
          psr: indicatorLabel(achievementScore, level, 'psr_change')
        },
        cmb: {
          proficiency: indicatorLabel(achievementScore, level, 'rdma_cmb'),
          seperate: indicatorLabel(achievementScore, level, 'scsswr_cmb'),
          el: indicatorLabel(achievementScore, level, 'el_cmb'),
          qscs: indicatorLabel(achievementScore, level, 'qscs_cmb'),
          grad: indicatorLabel(achievementScore, level, 'grad_cmb'),
          psr: indicatorLabel(achievementScore, level, 'psr_cmb')
        },
        fedClass: federalClassificationInfo(currentOrganization, currentYear, [level], framework),
        gaps: achievementGaps(get(organizationData, ['gap_demo', level])),
        isLoading: achievementScore == null
      }));
    });
};
