import get from 'lodash/get';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import when from '../when';
import { kdeCurrentSchoolYear } from '../portals';
import { shouldUsePortalDataService } from '../nodeHelpers';
import { getDimensionsForOrg, getOrgIdFromOrganization, getOrgType } from '../portalDataServiceHelpers';

const portalScoreQueryParams = (nodes, org) => {
  const params = {
    organization_ids: [getOrgIdFromOrganization(org)],
    data_points: nodes.filter(node => (node.when ? when(node.when, { currentOrganization: org }) : true)).map(node => ({
      ...node.portal_data,
      slug: node.portal_data.slug || node.node_path,
      dimensions: getDimensionsForOrg(node, org),
      key: node.node_path
    }))
  };

  // use requested data point year if specified
  // otherwise use KDE_CURRENT_SCHOOL_YEAR or KDE_PREVIEW_SCHOOL_YEAR from
  // the project configuration
  const year = get(params.data_points, '[0].year');
  params.year = year || kdeCurrentSchoolYear();

  return params;
};

const parentOrgDataQueries = ({ organization, dataPoints, featureFlags }) => {
  const dataQueries = [];

  const entityType = getOrgType(organization);
  if (entityType === 'school' || entityType === 'district') {
    const dataPointsForParentOrgs = dataPoints.filter(d => get(d, 'includeParentData'));
    if (!isEmpty(dataPointsForParentOrgs)) {
      // data fetch query for parent district
      if (entityType === 'school' && organization.parentDistrict) {
        dataQueries.push(
          portalScoreQueryParams(
            dataPointsForParentOrgs,
            organization.parentDistrict,
            featureFlags
          )
        );
      }
      // data fetch query for state
      dataQueries.push(
        portalScoreQueryParams(
          dataPointsForParentOrgs,
          { entity_type: 'State' },
          featureFlags
        )
      );
    }
  }

  return dataQueries;
};

const getDataPointsByYear = (dataPoints, selectedSchoolYears) => {
  const dataPointsWithYears = dataPoints
    .map((dataPoint) => {
      // Needed in case if points on the same page fetch data from different years
      // see: sites/kde/framework-trees/2019/kde/school_overview/faculty_staff_community/faculty_profile/teacher_working_conditions.yml
      const predefinedYear = get(dataPoint, 'portal_data.year');
      if (predefinedYear) {
        return {
          ...dataPoint,
          portal_data: {
            ...dataPoint.portal_data,
            year: Number(predefinedYear)
          }
        };
      }

      return {
        ...dataPoint,
        portal_data: {
          ...(dataPoint.portal_data || {}),
          year: selectedSchoolYears
        }
      };
    });

  return groupBy(dataPointsWithYears, dataPoint => dataPoint.portal_data.year);
};

export default (state, action) => {
  const currentOrganization = action.currentOrganization || get(state, 'module.currentOrganization');
  const { selectedSchoolYear, skipParentOrgsLoading = false } = action;
  const nodes = action.currentNodes || state.module.currentNodes;
  const featureFlags = get(state, 'module.pageContext.featureFlags');
  const dataPoints = filter(nodes, dataNode => shouldUsePortalDataService(dataNode, featureFlags));

  const dataPointsByYear = getDataPointsByYear(dataPoints, selectedSchoolYear);

  const dataQueries = Object.values(dataPointsByYear).map(dataPointsGroup =>
    portalScoreQueryParams(dataPointsGroup, currentOrganization, featureFlags)
  );

  if (skipParentOrgsLoading) {
    return dataQueries;
  }

  const parentDataQueries = Object.values(dataPointsByYear).map(dataPointsGroup =>
    parentOrgDataQueries({
      organization: currentOrganization,
      dataPoints: dataPointsGroup,
      featureFlags
    })
  );

  return dataQueries.concat(...parentDataQueries);
};
