import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

DashboardBanner.propTypes = {
  children: PropTypes.node,
  additionalClass: PropTypes.string
};

function DashboardBanner({ children, additionalClass }) {
  return (
    <div className={cx('dashboardBanner', additionalClass)}>
      { children }
    </div>
  );
}

export default DashboardBanner;
