import React, { memo } from 'react';
import PropTypes from 'prop-types';

import FlexBar from 'cui/lib/components/FlexBar';
import Label from 'cui/lib/components/Label';
import Dropdown from 'cui/lib/components/Dropdown';

const transformItems = (items, group) => items.map((item) => {
  if (item.items) {
    return {
      ...item,
      items: transformItems(item.items, group)
    };
  } else {
    return {
      group,
      text: item.label,
      value: item.value
    };
  }
});

const findIndex = (items, value) => {
  let childIndex = -1;

  const parentIndex = items.findIndex((item) => {
    if (item.value === value) {
      return true;
    } else if (item.items) {
      childIndex = item.items.findIndex(el => el.value === value);
      if (childIndex !== -1) {
        return true;
      }
    }
    return false;
  });

  if (parentIndex === -1) {
    return undefined;
  } else if (childIndex === -1) {
    return parentIndex;
  } else {
    return [parentIndex, childIndex];
  }
};

const FilterDropdown = ({
  filter,
  handleFilterChange,
  filterValues
}) => {
  const { key, items, label } = filter;

  return (
    <FlexBar.Item>
      <Label position="inline" value={`${label}:`} />
      <Dropdown
        selectable
        items={transformItems(items, key)}
        onSelectionChange={handleFilterChange}
        initiallySelectedItem={findIndex(items, filterValues?.[key])}
      />
    </FlexBar.Item>
  );
};

export const filterItemShape = PropTypes.shape({
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
});

FilterDropdown.propTypes = {
  filter: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    label: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(filterItemShape)
  }),
  handleFilterChange: PropTypes.func.isRequired,
  filterValues: PropTypes.objectOf(PropTypes.any)
};

export default memo(FilterDropdown);
